import InformationTooltip from './InformationTooltip'
import { defaultNodeAttribute, defaultMinYearsCoached, defaultMaxYearsCoached, defaultMinDegrees, defaultMaxDegrees, defaultCoachedPositions, defaultMinXpa, defaultMaxXpa, defaultIndividualCoach, DEBOUNCE_TIME } from '../lib/defaultHelper'
import { getCentralityTooltip } from '../lib/tableHelper'
import { useState, useEffect, useRef } from 'react'
import { coachPerformanceList } from '../data/coachPerformanceList';
import { getRelatedCoaches, coachEdges } from '../data/coachEdges'


const NetworkFilters = ({
  graphNodeOption,
  setGraphNodeOption,
  graphLoading,
  minYearsCoached, 
  setMinYearsCoached,
  maxYearsCoached, 
  setMaxYearsCoached,
  minCoachConnections, 
  setMinCoachConnections,
  maxCoachConnections, 
  setMaxCoachConnections,
  minCoachXpa,
  setMinCoachXpa,
  maxCoachXpa,
  setMaxCoachXpa,
  setIndividualCoachNetwork,
  coachedPositions,
  setCoachedPositions
}) => {

  //States for debounce
  const [minCoached, setMinCoached] = useState(minYearsCoached)
  const [maxCoached, setMaxCoached] = useState(maxYearsCoached)
  const [minDegrees, setMinDegrees] = useState(minCoachConnections)
  const [maxDegrees, setMaxDegrees] = useState(maxCoachConnections)
  const [minXpa, setMinXpa] = useState(minCoachXpa)
  const [maxXpa, setMaxXpa] = useState(maxCoachXpa)
  const [positionsCoached, setPositionsCoached] = useState(coachedPositions)
  const [individualCoach, setIndividualCoach] = useState(defaultIndividualCoach)

  //Select Element ref
  const individualCoachRef = useRef(null)

  //Node size
  const changeNodeSize = (e) => {
    if(graphNodeOption !== e.target.value) {
      setGraphNodeOption(e.target.value)
      //Set Loading
      graphLoading()
    }
  }

  const changeIndividualCoach = (e) => {
    setIndividualCoach(e.target.value === '' ?  '' : Number(e.target.value))
  }

  const resetIndividualCoach = (e) => {
    e.preventDefault()
    //Change select value
    individualCoachRef.current.value = defaultIndividualCoach
    setIndividualCoach('')
  }

  const changeCoachPositions = (e) => {
    //Update the array
    const position = e.target.value
    const positionsClone = [... positionsCoached]
    //Check if to be added or removed
    if(positionsClone.includes(position)) { //Remove positions
      positionsClone.splice(positionsClone.indexOf(position),1)
    }
    else { //Add position
      positionsClone.push(position)
    }
    //Update state
    setPositionsCoached(positionsClone)
  }

  const resetNodeSize = (e) => {
    e.preventDefault()
    //Reset if not already equal to value
    if( graphNodeOption !== defaultNodeAttribute) {
      setGraphNodeOption(defaultNodeAttribute)
      //Set Loading
      graphLoading()
    }
  }

  const resetCoachedYears = (e) => {
    e.preventDefault()
    //Reset if values are not equal to defaults
    if(minCoached !== defaultMinYearsCoached || maxCoached !== defaultMaxYearsCoached) {
      setMinCoached(defaultMinYearsCoached)
      setMaxCoached(defaultMaxYearsCoached)
      //Set loading
      graphLoading()
    }
  }

  const resetCoachConnections = (e) => {
    e.preventDefault()
    //Reset if values are not equal to defaults
    if(minDegrees !== defaultMinDegrees || maxDegrees !== defaultMaxDegrees) {
      setMinDegrees(defaultMinDegrees)
      setMaxDegrees(defaultMaxDegrees)
      //Set loading
      graphLoading()
    }
  }

  const resetCoachXpa = (e) => {
    e.preventDefault()
    //Reset if values are not equal to defaults
    if(minXpa !== defaultMinXpa || maxXpa !== defaultMaxXpa) {
      setMinXpa(defaultMinXpa)
      setMaxXpa(defaultMaxXpa)
      //Set loading
      graphLoading()
    }
  }

  const resetPositionsCoached = (e) => {
    e.preventDefault()
    if(!defaultCoachedPositions.every(pos => positionsCoached.includes(pos))) {
      setPositionsCoached(defaultCoachedPositions)
      //Set Loading
      graphLoading()
    }
  }

  //Debounce for min coached
  useEffect(() => {
    //Set loading
    graphLoading()
    //Start debounce
    const debounceTimer = setTimeout(() => {
      setMinYearsCoached(minCoached)
    }, DEBOUNCE_TIME)

    //Clear debounce
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [minCoached])

  //Debounce for max coached
  useEffect(() => {
    //Set loading
    graphLoading()
    //Start debounce
    const debounceTimer = setTimeout(() => {
      setMaxYearsCoached(maxCoached)
    }, DEBOUNCE_TIME)

    //Clear debounce
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [maxCoached])

  //Debounce for min coach connections
  useEffect(() => {
    //Set loading
    graphLoading()
    //Start debounce
    const debounceTimer = setTimeout(() => {
      setMinCoachConnections(minDegrees)
    }, DEBOUNCE_TIME)

    //Clear debounce
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [minDegrees])

  //Debounce for max coach connections
  useEffect(() => {
    //Set loading
    graphLoading()
    //Start debounce
    const debounceTimer = setTimeout(() => {
      setMaxCoachConnections(maxDegrees)
    }, DEBOUNCE_TIME)

    //Clear debounce
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [maxDegrees])

  //Debounce for min coach xpa
  useEffect(() => {
    //Set loading
    graphLoading()
    //Start debounce
    const debounceTimer = setTimeout(() => {
      setMinCoachXpa(minXpa)
    }, DEBOUNCE_TIME)

    //Clear debounce
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [minXpa])

  //Debounce for max coach xpa
  useEffect(() => {
    //Set loading
    graphLoading()
    //Start debounce
    const debounceTimer = setTimeout(() => {
      setMaxCoachXpa(maxXpa)
    }, DEBOUNCE_TIME)

    //Clear debounce
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [maxXpa])

   //Debounce for positions coached
   useEffect(() => {
    //Set loading
    graphLoading()
    //Start debounce
    const debounceTimer = setTimeout(() => {
      setCoachedPositions(positionsCoached)
    }, DEBOUNCE_TIME)

    //Clear debounce
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [positionsCoached])

  useEffect(() => {
    //Set Loading
    graphLoading()
    //Update the master state
    if(individualCoach === "") { //Clear the state
      setIndividualCoachNetwork([])
    }
    else { //Get related coaches
      setIndividualCoachNetwork(getRelatedCoaches(individualCoach, coachEdges))
    }
  }, [individualCoach])

  return (
    <div className="filter-container">
      <div className="filter-wrapper individual-coach-filter">
        <div className="filter-heading">
          <h3>Individual Coach Network</h3>
          <InformationTooltip tooltipContent={`Filter the network so only direct connections/edges of the selected coach are visible.`} size={`16`} />
        </div>
        <select name="individual-coach" onChange={e => changeIndividualCoach(e)} ref={individualCoachRef} >
          <option value=""></option>
          {
            coachPerformanceList.map(coach => {
              return (
                <option key={`coach-option-${coach['id']}`} value={`${coach['id']}`}>{coach['name']}</option>
              )
            })
          }
        </select>
        <button onClick={e => resetIndividualCoach(e)}>Reset Individual Coach</button>
      </div>
      <div className="filter-wrapper">
        <div className="filter-heading">
          <h3>Network Display Options</h3>
        </div>
        <div className="filter-input-wrapper">
          <div className="filter-sub-heading">
            <div className="filter-information">
              <p><strong>Node Size</strong></p>
              <InformationTooltip 
               tooltipContent={`Altering network nodes based on centrality metrics for each coach.`}
              />
            </div>
            <button className="filter-reset" onClick={e => resetNodeSize(e)}>Reset</button>
          </div>
          <div className="radio-input-wrapper">
            <input type="radio" id="degree" name="node-size" value="degree" onChange={e => changeNodeSize(e)} checked={graphNodeOption === "degree"} />
            <label htmlFor="degree">
              <span>Degree</span>
              <InformationTooltip tooltipContent={getCentralityTooltip('degree')} size={`16`} />
            </label>
          </div>
          <div className="radio-input-wrapper">
            <input type="radio" id="closeness" name="node-size" value="closeness" onChange={e => changeNodeSize(e)} checked={graphNodeOption === "closeness"} />
            <label htmlFor="closeness">
              <span>Closeness</span>
              <InformationTooltip tooltipContent={getCentralityTooltip('closeness')} size={`16`} />
            </label>
          </div>
          <div className="radio-input-wrapper">
            <input type="radio" id="eigen" name="node-size" value="eigen" onChange={e => changeNodeSize(e)} checked={graphNodeOption === "eigen"} />
            <label htmlFor="eigen">
              <span>Eigen</span>
              <InformationTooltip tooltipContent={getCentralityTooltip('eigen')} size={`16`} />
            </label>
          </div>
        </div>
      </div>
      <div className="filter-wrapper">
        <div className="filter-heading">
          <h3>Network Graph Filters</h3>
          <InformationTooltip tooltipContent={`This will filter the number of nodes displayed on the network graph.`} size={`16`} />
        </div>
        <div className="filter-input-wrapper">
          <div className="filter-sub-heading">
            <div className="filter-information">
              <p><strong>Years Coached</strong></p>
              <InformationTooltip 
               tooltipContent={`Filter the nodes based on the number of years coached at a collegiate level.`}
              />
            </div>
            <button className="filter-reset" onClick={e => resetCoachedYears(e)}>Reset</button>
          </div>
          <div className="min-max-filter-wrapper">
              <div className="input-wrapper">
                <label>Min</label>
                <input className="minWrapper" value={minCoached} type="number" name="min-year-coached" onChange={e => setMinCoached(e.target.value)} min={defaultMinYearsCoached} max={maxCoached} />
              </div>
              <div className="input-wrapper">
                <label>Max</label>
                <input className="minWrapper" value={maxCoached} type="number" name="max-year-coached" onChange={e => setMaxCoached(e.target.value)} max={defaultMaxYearsCoached} min={minCoached}  />
              </div>
          </div>
        </div>
        <div className="filter-input-wrapper">
          <div className="filter-sub-heading">
            <div className="filter-information">
              <p><strong>Coaching Connections</strong></p>
              <InformationTooltip 
               tooltipContent={`Filter the nodes based on the minimum number of edges for a given coach`}
              />
            </div>
            <button className="filter-reset" onClick={e => resetCoachConnections(e)}>Reset</button>
          </div>
          <div className="min-max-filter-wrapper">
              <div className="input-wrapper">
                <label>Min</label>
                <input className="minWrapper" value={minDegrees} type="number" name="min-year-coached" onChange={e => setMinDegrees(e.target.value)} min={defaultMinDegrees} max={maxDegrees} />
              </div>
              <div className="input-wrapper">
                <label>Max</label>
                <input className="minWrapper" value={maxDegrees} type="number" name="max-year-coached" onChange={e => setMaxDegrees(e.target.value)} max={defaultMaxDegrees} min={minDegrees}  />
              </div>
          </div>
        </div>
        <div className="filter-input-wrapper">
          <div className="filter-sub-heading">
            <div className="filter-information">
              <p><strong>Coach EPA</strong></p>
              <InformationTooltip 
               tooltipContent={`Filter the nodes based on the aggregated values of Expected Points Added (EPA) over their career`}
              />
            </div>
            <button className="filter-reset" onClick={e => resetCoachXpa(e)}>Reset</button>
          </div>
          <div className="min-max-filter-wrapper xpa-filter-wrapper">
              <div className="input-wrapper">
                <label>Min</label>
                <input className="minWrapper" value={minXpa} type="number" name="min-coach-xpa" step="0.027266283453709482" onChange={e => setMinXpa(e.target.value)} min={defaultMinXpa} max={maxXpa} />
              </div>
              <div className="input-wrapper">
                <label>Max</label>
                <input className="minWrapper" value={maxXpa} type="number" name="max-coach-xpa" step="0.027266283453709482" onChange={e => setMaxXpa(e.target.value)} max={defaultMaxXpa} min={minXpa}  />
              </div>
          </div>
        </div>
        <div className="filter-input-wrapper">
          <div className="filter-sub-heading">
            <div className="filter-information">
              <p><strong>Coaching Roles</strong></p>
              <InformationTooltip 
               tooltipContent={`Filter the nodes based on the roles that a coach was appointed during their coaching career.`}
              />
            </div>
            <button className="filter-reset" onClick={e => resetPositionsCoached(e)}>Reset</button>
          </div>
          <div className="checkbox-wrapper">
            <div className="input-wrapper">
              <input type="checkbox" id="HC" name="HC" value="HC" checked={positionsCoached.includes('HC')} onChange={e => changeCoachPositions(e)} />
              <label htmlFor="HC">Head Coach</label>
            </div>
            <div className="input-wrapper">
              <input type="checkbox" id="OC" name="OC" value="OC" checked={positionsCoached.includes('OC')} onChange={e => changeCoachPositions(e)} />
              <label htmlFor="OC">Offensive Coordinator</label>
            </div>
            <div className="input-wrapper">
              <input type="checkbox" id="DC" name="DC" value="DC" checked={positionsCoached.includes('DC')} onChange={e => changeCoachPositions(e)} />
              <label htmlFor="DC">Defensive Coordinator</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NetworkFilters