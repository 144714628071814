import { motion } from 'framer-motion'
import { expandPerformanceHeading } from '../lib/translatorHelper'
import { trimValueStat } from '../lib/formatHelper'
import { getPerformanceTipString } from '../lib/tableHelper'
import { getPerformanceStatColor, getStatColorLegend } from '../data/summaryData'
import { getPerformanceSquare } from '../lib/iconHelper'
import { useState, Fragment } from 'react'
import InformationTooltip from './InformationTooltip'

const CoachPerformance = ({coachObject}) => {

  const [showHC, setHC] = useState(false)

  const handleToggleChange = (e) => {
    setHC(e.target.checked)
  }
  
  return (
    <motion.div
     inherit={{opacity:0}}
     animate={{opacity:1}}
     exit={{opacity:0}}
    >
      { coachObject['positionsCoached'].includes('HC') &&
        <div className="toggle-wrapper">
          <input type="checkbox" id="head-coach-stats" onChange={e => handleToggleChange(e)}/>
          <label htmlFor="head-coach-stat" className="show-head-coach-only-stats">
            Show total value added as Head Coach?
            <InformationTooltip tooltipContent={`This toggles the net stats calculated for head coaches only.`} size={16} />
          </label>
        </div>
      }
      <div className="stat-table performance-table">
        <div className="stat-heading">
        { Object.keys(coachObject['performance'][0]).map((heading,index) => {
            if ( heading === "net_fpi") {
              return (
                <Fragment key={`performance-heading-${index}`}></Fragment>
              )
            }
            else if( !showHC && (heading === "college" || heading === "role" || heading === "net_ppa" || heading === "net_sr" || heading === "net_stuff" || heading === "net_pass_sr" )) {
              return (
                <span key={`performance-heading-${index}`}>
                  { expandPerformanceHeading(heading) }
                  <InformationTooltip tooltipContent={getPerformanceTipString(heading)} />
                </span>
              )
            }
            else if( !showHC && heading !== "college" && heading !== "role" && heading !== "net_ppa" && heading !== "net_sr" && heading !== "net_stuff" && heading !== "net_pass_sr" ) {
              return (
                <Fragment key={`performance-heading-${index}`}></Fragment>
              )
            }
            else if( showHC ) {
              return (
                <span key={`performance-heading-${index}`}>
                  { expandPerformanceHeading(heading) }
                  <InformationTooltip tooltipContent={getPerformanceTipString(heading)} size={12} />
                </span>
              )
            }
            else {
              return (
                <Fragment key={`performance-heading-${index}`}></Fragment>
              )
            }
          })
        }
        </div>
        <div className="stat-values">
          {
            coachObject['performance'].map((item, performanceIndex) => {
              return (
                <div className="stat-row" key={`performance-${performanceIndex}`}>
                  {
                    Object.keys(item).map((attr) => {
                      if ( attr === "net_fpi") {
                        return (
                          <Fragment key={`performance-value-${performanceIndex}-${attr}`}></Fragment>
                        )
                      }
                      if( !showHC && (attr === "college" || attr === "role" || attr === "net_ppa" || attr === "net_sr" || attr === "net_stuff" || attr === "net_pass_sr" )) {
                        return (
                          <span key={`performance-value-${performanceIndex}-${attr}`} style={{background:getPerformanceStatColor(attr, Number(item[attr]))}}>{ attr !== 'college' ? trimValueStat(item[attr]) : item[attr] }</span>
                        )
                      }
                      else if( !showHC && attr !== "college" && attr !== "role" && attr !== "net_ppa" && attr !== "net_sr" && attr !== "net_stuff" && attr !== "net_pass_sr" ) {
                        return (
                          <Fragment key={`performance-value-${performanceIndex}-${attr}`}></Fragment>
                        )
                      }
                      else if(showHC) {
                        if( trimValueStat(item[attr]) !== false ) {
                          return (
                            <span key={`performance-value-${performanceIndex}-${attr}`} style={{background:getPerformanceStatColor(attr, Number(item[attr]))}}>{ attr !== 'college' ? trimValueStat(item[attr]) : item[attr] }</span>
                          )
                        }
                        else {
                          return (
                            <span key={`performance-value-${performanceIndex}-${attr}`}>N/A</span>
                          )
                        }
                      }
                      else {
                        return (
                          <Fragment key={`performance-value-${performanceIndex}-${attr}`}></Fragment>
                        )
                      }
                    })
                  }
                </div>
              )
            })
          }
        </div>
        <div className="stat-colors">
          <h6>The colors of the values represent the related quartile of all performance stats.</h6>
          <div className="colors">
            <div className="item">
              <i>{getPerformanceSquare(getStatColorLegend('bottom'))}</i>
              <span>
                Bottom Quartile
                <InformationTooltip tooltipContent={`Bottom 25%`} size={`12`}/>
              </span>
            </div>
            <div className="item">
              <i>{getPerformanceSquare(getStatColorLegend('midBottom'))}</i>
              <span>
                Lower Middle Quartile
                <InformationTooltip tooltipContent={`Middle 25% and 50%`} size={`12`}/>
              </span>
            </div>
            <div className="item">
              <i>{getPerformanceSquare(getStatColorLegend('midTop'))}</i>
              <span>
                Upper Middle Quartile
                <InformationTooltip tooltipContent={`Middle 50% and 75%`}  size={`12`}/>
                </span>
            </div>
            <div className="item">
                <i>{getPerformanceSquare(getStatColorLegend('top'))}</i>
                <span>
                  Top Quartile
                  <InformationTooltip tooltipContent={`Top 25%`} size={`12`}/>
                </span>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default CoachPerformance