import { motion } from 'framer-motion'
import { expandPosition } from '../lib/translatorHelper'
import { getPositionTooltip } from '../lib/tableHelper'
import InformationTooltip from './InformationTooltip'
import { Fragment } from 'react'

const CoachPosition = ({coachObject}) => {
  return (
    <motion.div
     inherit={{opacity:0}}
     animate={{opacity:1}}
     exit={{opacity:0}}
    >
      <div className="stat-table position-table">
        <div className="stat-heading">
          <span>Year</span>
          <span>
            College
            <InformationTooltip tooltipContent={getPositionTooltip('college')} size={12} />
          </span>
          <span>
            Position
            <InformationTooltip tooltipContent={getPositionTooltip('college')} size={12} />
          </span>
        </div>
        <div className="stat-values">
          {
            Object.keys(coachObject['positions']).map(year => {
              if( coachObject['positions'][year] === false) { //No coaching position
                return (
                  <Fragment key={`position-${year}`}></Fragment>
                )
              }
              else { //Valid position
                return (
                  <div className="stat-row" key={`position-${year}`}>
                    <span>{year}</span>
                    { 
                      <>
                        <span>{ coachObject['positions'][year]['college'] }</span>
                        <span>{ expandPosition(coachObject['positions'][year]['position']) }</span>
                      </>
                    }
                  </div>
                )
              }
            })
          }
        </div>
      </div>
    </motion.div>
  )
}

export default CoachPosition