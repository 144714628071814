export const getNodeColor = (color) => {
  switch(color) {
    case "White":
      return "#66C2A5"
    case "Black":
      return "#FC8D62"
    case "Other":
      return "#8DA0CB"
    default:
      return '#FFFFFF'
  }
}

export const getEdgeColor = (race1, race2) => {
  if(race1 === "White" && race2 === "White") {  //White to white
    return "#B2DF8A"
  }
  else if( (race1 === "White" && (race2 === "Black" || race2 === "Other")) || ((race1 === "Black" || race1 === "Other") && race2 === "White") ) { //White to minority
    return "#A6CEE3"
  }
  else { //Minority to Minority
    return '#1F78B4'
  }

}