export const getPerformanceTipString = (attr) => {
    switch(attr) {
        case "college":
            return "The name of the college institution."
        case "role":
            return "The role performed by the coach. This is either Head Coach (HC), Offensive Coordinator (OC) or Defensive Coordinator (DC)."
        case "net_ppa":
            return "The difference in the Expected Points Added (PPA) during the coaches tenure, and the PPA of the team prior to the coaches tenure."
        case "net_sr":
            return "The difference between the Success Rate (SR) of plays during the coaches tenure, and the teams SR prior to the coaches tenure."
        case "net_stuff":
            return "The difference in the percentage of run plays with no yards gained or tackle for loss during the coaches tenure, and prior to the coaches tenure."
        case "net_pass_sr":
            return "The difference in the Passing Success Rate (PSR) for pass plays only during the coaches tenure, and prior to the coaches tenure."
        case "net_fpi":
            return "TBC"
        case "total_sr":
            return "The Net SR metric, multiplied by the coaches tenure at the insitution."
        case "total_stuffs":
            return "The Net Stuff metric, multiplied by the coaches tenure at the institution."
        case "total_pass_sr":
            return "The Net PSR metric, multiplied by the coaches tenure at the institution."
        case "total_ppa":
            return "The Net EPA metric, multiplied by the coaches tenure at the institution."
        default:
            return "Unknown metric."
    }
}

export const getCentralityTooltip = (attr) => {
    switch(attr){
        case "betweenness":
            return "A measure of importance of a single node in the graph."
        case "closeness":
            return "A measure of how many edges can be traced back to a particular node."
        case "degree":
            return "Number of direct connections for a node."
        case "eigen":
            return "Measure of influence, emphasising connections to other highly connected nodes."
        default:
            return "Unknown attribute."
    }
}

export const getPositionTooltip = (attr) => {
    switch(attr) {
        case "college":
            return "The collegiate institution that the coach was hired at."
        case "position":
            return "The role that the coach performed at the insitution."
        default:
            return "Unknown"
    }
}