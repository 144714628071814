import './App.scss';
import NetworkGraph from './components/NetworkGraph';
import NetworkFilters from './components/NetworkFilters';
import CoachStats from './components/CoachStats';
import ProjectContent from './components/ProjectContent'
import { useState, Fragment } from 'react'
import { isDebugMode } from './lib/debugHelper.js'
import { defaultMinYearsCoached, defaultMaxYearsCoached, defaultMinDegrees, defaultMaxDegrees, defaultNodeAttribute, defaultCoachedPositions, defaultMinXpa, defaultMaxXpa } from './lib/defaultHelper'
import { AnimatePresence } from 'framer-motion'
import { coachPerformanceList } from './data/coachPerformanceList';


function App() {

  //Individual Coach Options
  const [ individualCoachNetwork, setIndividualCoachNetwork] = useState([])

  //Display Options
  //Graph View Options
  const [graphNodeOption, setGraphNodeOption] = useState(defaultNodeAttribute)

  //Node filters
  //Years coached
  const [minYearsCoached, setMinYearsCoached] = useState(defaultMinYearsCoached)
  const [maxYearsCoached, setMaxYearsCoached] = useState(defaultMaxYearsCoached)

  //Coaching connections
  const [minCoachConnections, setMinCoachConnections] = useState(defaultMinDegrees)
  const [maxCoachConnections, setMaxCoachConnections] = useState(defaultMaxDegrees)

  //Coaching XPA
  const [minCoachXpa, setMinCoachXpa] = useState(defaultMinXpa)
  const [maxCoachXpa, setMaxCoachXpa] = useState(defaultMaxXpa)

  //Coached Positions
  const [coachedPositions, setCoachedPositions] = useState(defaultCoachedPositions)

  //Graph Loading
  //Loading
  const [isGraphLoading, setGraphLoading] = useState(true)

  const graphLoading = () => {
    if(!isGraphLoading) setGraphLoading(true)
  }

  const graphLoaded = () =>  {
    if(isGraphLoading) setGraphLoading(false)
  }

  //Network State
  const [network, setNetwork] = useState(null)

  //Individual Coach
  const [individualCoaches, setindividualCoaches] = useState([])

  //Event handler for individual coaches
  const handleCoachEvent = (coachID, action) => {
    if(action === 'add') {
      //Check if currently active
      const currentlyActive = individualCoaches.includes(coachID)
      if(currentlyActive) return null
      //Action add
      const coachClone = [... individualCoaches]
      coachClone.push(coachID)
      setindividualCoaches(coachClone)
    }
    else if(action === 'remove') {
      //Check if currently inactive
      const currentlyActive = individualCoaches.includes(coachID)
      if(!currentlyActive) return null
      //Action removal
      const coachClone = [... individualCoaches]
      coachClone.splice(coachClone.indexOf(coachID), 1)
      setindividualCoaches(coachClone)
    }
    else {
      if(isDebugMode()) console.error(`Coach ID: Invalid action (${action}) when attempting to update coach IDs.`)
    }
  }

  return (
    <div className="App">
      <div className="container">
       <ProjectContent />
       <section className="network-graph-section">
        <NetworkGraph
         minYearsCoached={minYearsCoached}
         maxYearsCoached={maxYearsCoached}
         minCoachConnections={minCoachConnections}
         maxCoachConnections={maxCoachConnections}
         minCoachXpa={minCoachXpa}
         maxCoachXpa={maxCoachXpa}
         individualCoachNetwork={individualCoachNetwork}
         coachedPositions={coachedPositions}
         handleCoachEvent={handleCoachEvent}
         graphNodeOption={graphNodeOption}
         network={network}
         setNetwork={setNetwork}
         isGraphLoading={isGraphLoading}
         graphLoaded={graphLoaded}
        />
        <NetworkFilters 
         graphNodeOption={graphNodeOption}
         setGraphNodeOption={setGraphNodeOption}
         minYearsCoached={minYearsCoached}
         setMinYearsCoached={setMinYearsCoached}
         maxYearsCoached={maxYearsCoached}
         setMaxYearsCoached={setMaxYearsCoached}
         minCoachConnections={minCoachConnections}
         setMinCoachConnections={setMinCoachConnections}
         maxCoachConnections={maxCoachConnections}
         setMaxCoachConnections={setMaxCoachConnections}
         minCoachXpa={minCoachXpa}
         setMinCoachXpa={setMinCoachXpa}
         maxCoachXpa={maxCoachXpa}
         setMaxCoachXpa={setMaxCoachXpa}
         setIndividualCoachNetwork={setIndividualCoachNetwork}
         coachedPositions={coachedPositions}
         setCoachedPositions={setCoachedPositions}
         graphLoading={graphLoading}
        />
       </section>
       <AnimatePresence>
        { [... individualCoaches].length > 0 &&
          individualCoaches.map( (id) => {
            return (
              <Fragment key={`coach-stat-${id}`}>
                <CoachStats 
                 coachID={id}
                 coachObject={ coachPerformanceList.filter(coach => coach['id'] === id)[0] }
                 handleCoachEvent={handleCoachEvent}
                />
              </Fragment>
            )
          })
        }
        </AnimatePresence>
      </div>
    </div>
  );
}

export default App;
