import { yearsCoachedMetrics, centralityMeasureAttributes, coachXpaMetrics } from '../data/summaryData'

export const DEBOUNCE_TIME = 1000

//Display option default
export const defaultNodeAttribute = "degree"

//Filter option default
export const defaultMinYearsCoached = yearsCoachedMetrics['min']
export const defaultMaxYearsCoached = yearsCoachedMetrics['max']

export const defaultMinDegrees = centralityMeasureAttributes['degree']['min']
export const defaultMaxDegrees = centralityMeasureAttributes['degree']['max']

export const defaultMinXpa = coachXpaMetrics['min']
export const defaultMaxXpa = coachXpaMetrics['max']

export const defaultIndividualCoach = ''

export const defaultCoachedPositions = ['HC', 'OC', 'DC']
 
