import { asc, dsc, q25, median, q75 } from '../lib/mathHelpers'

export const coachPerformanceList = [
    {
        "id": 2,
        "name": "Troy Calhoun",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 10,
            "closeness": 4.92110484709143e-7,
            "degree": 7,
            "eigen": 0.0004724827432389
        },
        "positions": {
            "2000": {
                "college": "Ohio",
                "position": "OC"
            },
            "2001": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2002": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Air Force",
                "position": "HC"
            },
            "2008": {
                "college": "Air Force",
                "position": "HC"
            },
            "2009": {
                "college": "Air Force",
                "position": "HC"
            },
            "2010": {
                "college": "Air Force",
                "position": "HC"
            },
            "2011": {
                "college": "Air Force",
                "position": "HC"
            },
            "2012": {
                "college": "Air Force",
                "position": "HC"
            },
            "2013": {
                "college": "Air Force",
                "position": "HC"
            },
            "2014": {
                "college": "Air Force",
                "position": "HC"
            },
            "2015": {
                "college": "Air Force",
                "position": "HC"
            },
            "2016": {
                "college": "Air Force",
                "position": "HC"
            },
            "2017": {
                "college": "Air Force",
                "position": "HC"
            },
            "2018": {
                "college": "Air Force",
                "position": "HC"
            },
            "2019": {
                "college": "Air Force",
                "position": "HC"
            },
            "2020": {
                "college": "Air Force",
                "position": "HC"
            },
            "2021": {
                "college": "Air Force",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Air Force",
                "role": "HC",
                "net_ppa": "0.086483222",
                "net_sr": "0.07716642",
                "net_stuff": "-0.002742651",
                "net_pass_sr": "0.041194386",
                "net_fpi": "1.283333333",
                "total_sr": "1.157496298",
                "total_stuffs": "-0.041139759",
                "total_pass_sr": "0.617915786",
                "total_ppa": "1.297248327"
            }
        ],
        "yearsCoached": 18,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.086483222
    },
    {
        "id": 5,
        "name": "Rob Ianello",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.897061322471e-7,
            "degree": 3,
            "eigen": 0.0000606761589588097
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Akron",
                "position": "HC"
            },
            "2011": {
                "college": "Akron",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Akron",
                "role": "HC",
                "net_ppa": "-0.078356784",
                "net_sr": "-0.075868564",
                "net_stuff": "0.051650087",
                "net_pass_sr": "-0.088408152",
                "net_fpi": "-14.25",
                "total_sr": "-0.151737129",
                "total_stuffs": "0.103300173",
                "total_pass_sr": "-0.176816304",
                "total_ppa": "-0.156713568"
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.078356784
    },
    {
        "id": 6,
        "name": "Terry Bowden",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.9418468175742e-7,
            "degree": 3,
            "eigen": 0.0000241254906227087
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Akron",
                "position": "HC"
            },
            "2013": {
                "college": "Akron",
                "position": "HC"
            },
            "2014": {
                "college": "Akron",
                "position": "HC"
            },
            "2015": {
                "college": "Akron",
                "position": "HC"
            },
            "2016": {
                "college": "Akron",
                "position": "HC"
            },
            "2017": {
                "college": "Akron",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Louisiana Monroe",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Akron",
                "role": "HC",
                "net_ppa": "0.082321117",
                "net_sr": "0.041427085",
                "net_stuff": "-0.012549824",
                "net_pass_sr": "0.101407861",
                "net_fpi": "9.2",
                "total_sr": "0.248562513",
                "total_stuffs": "-0.075298942",
                "total_pass_sr": "0.608447165",
                "total_ppa": "0.4939267"
            },
            {
                "college": "Louisiana Monroe",
                "role": "HC",
                "net_ppa": "-0.044364908",
                "net_sr": "-0.056503143",
                "net_stuff": "0.088241966",
                "net_pass_sr": "-0.080073968",
                "net_fpi": "N/A",
                "total_sr": "-0.056503143",
                "total_stuffs": "0.088241966",
                "total_pass_sr": "-0.080073968",
                "total_ppa": "-0.044364908"
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.037956209
    },
    {
        "id": 7,
        "name": "Tom Arth",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90392475810165e-7,
            "degree": 5,
            "eigen": 0.00000445167191645486
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Akron",
                "position": "HC"
            },
            "2019": {
                "college": "Akron",
                "position": "HC"
            },
            "2020": {
                "college": "Akron",
                "position": "HC"
            },
            "2021": {
                "college": "Akron",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Akron",
                "role": "HC",
                "net_ppa": "-0.205532192",
                "net_sr": "-0.080623988",
                "net_stuff": "0.020477384",
                "net_pass_sr": "-0.089525912",
                "net_fpi": "-12.99166667",
                "total_sr": "-0.322495953",
                "total_stuffs": "0.081909538",
                "total_pass_sr": "-0.35810365",
                "total_ppa": "-0.822128767"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.205532192
    },
    {
        "id": 9,
        "name": "Dennis Franchione",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.34361301982e-7,
            "degree": 7,
            "eigen": 0.000720512525183263
        },
        "positions": {
            "2000": {
                "college": "TCU",
                "position": "HC"
            },
            "2001": {
                "college": "Alabama",
                "position": "HC"
            },
            "2002": {
                "college": "Alabama",
                "position": "HC"
            },
            "2003": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2004": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2005": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2006": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2007": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Texas State",
                "position": "HC"
            },
            "2013": {
                "college": "Texas State",
                "position": "HC"
            },
            "2014": {
                "college": "Texas State",
                "position": "HC"
            },
            "2015": {
                "college": "Texas State",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas State",
                "role": "HC",
                "net_ppa": "0.133500579",
                "net_sr": "0.093161574",
                "net_stuff": "-0.005919846",
                "net_pass_sr": "0.098501386",
                "net_fpi": "N/A",
                "total_sr": "0.372646296",
                "total_stuffs": "-0.023679384",
                "total_pass_sr": "0.394005543",
                "total_ppa": "0.534002315"
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.133500579
    },
    {
        "id": 11,
        "name": "Nick Saban",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 6.14778916280022e-7,
            "degree": 20,
            "eigen": 0.00297286650702892
        },
        "positions": {
            "2000": {
                "college": "LSU",
                "position": "HC"
            },
            "2001": {
                "college": "LSU",
                "position": "HC"
            },
            "2002": {
                "college": "LSU",
                "position": "HC"
            },
            "2003": {
                "college": "LSU",
                "position": "HC"
            },
            "2004": {
                "college": "LSU",
                "position": "HC"
            },
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Alabama",
                "position": "HC"
            },
            "2008": {
                "college": "Alabama",
                "position": "HC"
            },
            "2009": {
                "college": "Alabama",
                "position": "HC"
            },
            "2010": {
                "college": "Alabama",
                "position": "HC"
            },
            "2011": {
                "college": "Alabama",
                "position": "HC"
            },
            "2012": {
                "college": "Alabama",
                "position": "HC"
            },
            "2013": {
                "college": "Alabama",
                "position": "HC"
            },
            "2014": {
                "college": "Alabama",
                "position": "HC"
            },
            "2015": {
                "college": "Alabama",
                "position": "HC"
            },
            "2016": {
                "college": "Alabama",
                "position": "HC"
            },
            "2017": {
                "college": "Alabama",
                "position": "HC"
            },
            "2018": {
                "college": "Alabama",
                "position": "HC"
            },
            "2019": {
                "college": "Alabama",
                "position": "HC"
            },
            "2020": {
                "college": "Alabama",
                "position": "HC"
            },
            "2021": {
                "college": "Alabama",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Alabama",
                "role": "HC",
                "net_ppa": "0.186332313",
                "net_sr": "0.081484333",
                "net_stuff": "-0.010714502",
                "net_pass_sr": "0.077293562",
                "net_fpi": "15.12666667",
                "total_sr": "1.222264992",
                "total_stuffs": "-0.160717529",
                "total_pass_sr": "1.159403436",
                "total_ppa": "2.794984701"
            }
        ],
        "yearsCoached": 20,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.186332313
    },
    {
        "id": 12,
        "name": "Scott Satterfield",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 18,
            "closeness": 4.91768048747983e-7,
            "degree": 9,
            "eigen": 0.0000735365330773245
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Florida International",
                "position": "OC"
            },
            "2011": {
                "college": "Florida International",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Appalachian State",
                "position": "HC"
            },
            "2015": {
                "college": "Appalachian State",
                "position": "HC"
            },
            "2016": {
                "college": "Appalachian State",
                "position": "HC"
            },
            "2017": {
                "college": "Appalachian State",
                "position": "HC"
            },
            "2018": {
                "college": "Appalachian State",
                "position": "HC"
            },
            "2019": {
                "college": "Louisville",
                "position": "HC"
            },
            "2020": {
                "college": "Louisville",
                "position": "HC"
            },
            "2021": {
                "college": "Louisville",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "HC",
                "net_ppa": "0.335357961",
                "net_sr": "0.122440094",
                "net_stuff": "-0.08305897",
                "net_pass_sr": "0.10780226",
                "net_fpi": "N/A",
                "total_sr": "0.612200469",
                "total_stuffs": "-0.415294852",
                "total_pass_sr": "0.539011301",
                "total_ppa": "1.676789805"
            },
            {
                "college": "Louisville",
                "role": "HC",
                "net_ppa": "-0.002765358",
                "net_sr": "-0.012306698",
                "net_stuff": "0.052392644",
                "net_pass_sr": "0.042999613",
                "net_fpi": "-3.033333333",
                "total_sr": "-0.036920093",
                "total_stuffs": "0.157177933",
                "total_pass_sr": "0.128998838",
                "total_ppa": "-0.008296073"
            },
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.0815529556941425",
                "net_sr": "0.0751838818239728",
                "net_stuff": "-0.0154659067292585",
                "net_pass_sr": "0.064177460007524",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.4141455586941425
    },
    {
        "id": 13,
        "name": "Eliah Drinkwitz",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 29,
            "closeness": 4.91769257930025e-7,
            "degree": 5,
            "eigen": 0.00157242641778055
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2014": false,
            "2015": {
                "college": "Boise State",
                "position": "OC"
            },
            "2016": {
                "college": "NC State",
                "position": "OC"
            },
            "2017": {
                "college": "NC State",
                "position": "OC"
            },
            "2018": {
                "college": "NC State",
                "position": "OC"
            },
            "2019": {
                "college": "Appalachian State",
                "position": "HC"
            },
            "2020": {
                "college": "Missouri",
                "position": "HC"
            },
            "2021": {
                "college": "Missouri",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "HC",
                "net_ppa": "-0.008286274",
                "net_sr": "0.003507238",
                "net_stuff": "-0.013628403",
                "net_pass_sr": "0.004967051",
                "net_fpi": "N/A",
                "total_sr": "0.003507238",
                "total_stuffs": "-0.013628403",
                "total_pass_sr": "0.004967051",
                "total_ppa": "-0.008286274"
            },
            {
                "college": "Missouri",
                "role": "HC",
                "net_ppa": "-0.195506863",
                "net_sr": "-0.086855069",
                "net_stuff": "0.079476168",
                "net_pass_sr": "-0.062693559",
                "net_fpi": "-8.566666667",
                "total_sr": "-0.173710138",
                "total_stuffs": "0.158952337",
                "total_pass_sr": "-0.125387118",
                "total_ppa": "-0.391013725"
            },
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "-0.0111847991187055",
                "net_sr": "-0.0172296823679722",
                "net_stuff": "-0.00644078144078145",
                "net_pass_sr": "-0.00771308351853095",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "0.0403405340606429",
                "net_sr": "0.0142394635527726",
                "net_stuff": "-0.0101975931572614",
                "net_pass_sr": "-0.0222253467828222",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "0.0512182922404404",
                "net_sr": "0.0175723586540084",
                "net_stuff": "0.0338324302401595",
                "net_pass_sr": "0.0522054200698485",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.12341910981762223
    },
    {
        "id": 14,
        "name": "Shawn Clark",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 6,
            "closeness": 4.897061322471e-7,
            "degree": 4,
            "eigen": 0.0000192757242447598
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2017": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2018": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2019": false,
            "2020": {
                "college": "Appalachian State",
                "position": "HC"
            },
            "2021": {
                "college": "Appalachian State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "HC",
                "net_ppa": "-0.025848792",
                "net_sr": "0.026231622",
                "net_stuff": "-0.047550162",
                "net_pass_sr": "0.022242661",
                "net_fpi": "N/A",
                "total_sr": "0.052463245",
                "total_stuffs": "-0.095100323",
                "total_pass_sr": "0.044485322",
                "total_ppa": "-0.051697583"
            },
            {
                "college": "Appalachian State",
                "role": "OC",
                "net_ppa": "0.0547645311025155",
                "net_sr": "0.03786466341404",
                "net_stuff": "-0.0678425014344434",
                "net_pass_sr": "0.0123202738647046",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.028915739102515502
    },
    {
        "id": 17,
        "name": "Mike Stoops",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 240,
            "closeness": 5.08134212473272e-7,
            "degree": 10,
            "eigen": 0.015364266789731
        },
        "positions": {
            "2000": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2001": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2002": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2003": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2004": {
                "college": "Arizona",
                "position": "HC"
            },
            "2005": {
                "college": "Arizona",
                "position": "HC"
            },
            "2006": {
                "college": "Arizona",
                "position": "HC"
            },
            "2007": {
                "college": "Arizona",
                "position": "HC"
            },
            "2008": {
                "college": "Arizona",
                "position": "HC"
            },
            "2009": {
                "college": "Arizona",
                "position": "HC"
            },
            "2010": {
                "college": "Arizona",
                "position": "HC"
            },
            "2011": {
                "college": "Arizona",
                "position": "HC"
            },
            "2012": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2013": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2014": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2015": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2016": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2017": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2018": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Florida Atlantic",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "-0.0501674310245591",
                "net_sr": "0.00541572595478518",
                "net_stuff": "0.0442671604238514",
                "net_pass_sr": "0.00284560389283978",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma",
                "role": "OC",
                "net_ppa": "-0.116830143466428",
                "net_sr": "-0.0569697025333764",
                "net_stuff": "0.023625880550438",
                "net_pass_sr": "-0.076815277042539",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 20,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.16699757449098712
    },
    {
        "id": 18,
        "name": "Rich Rodriguez",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 708,
            "closeness": 5.42714331457351e-7,
            "degree": 10,
            "eigen": 0.00321034665622331
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2002": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2003": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2004": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2005": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2006": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2007": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2008": {
                "college": "Michigan",
                "position": "HC"
            },
            "2009": {
                "college": "Michigan",
                "position": "HC"
            },
            "2010": {
                "college": "Michigan",
                "position": "HC"
            },
            "2011": false,
            "2012": {
                "college": "Arizona",
                "position": "HC"
            },
            "2013": {
                "college": "Arizona",
                "position": "HC"
            },
            "2014": {
                "college": "Arizona",
                "position": "HC"
            },
            "2015": {
                "college": "Arizona",
                "position": "HC"
            },
            "2016": {
                "college": "Arizona",
                "position": "HC"
            },
            "2017": {
                "college": "Arizona",
                "position": "HC"
            },
            "2018": false,
            "2019": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "HC",
                "net_ppa": "-0.025227984",
                "net_sr": "-0.038351911",
                "net_stuff": "-0.041326836",
                "net_pass_sr": "-0.093741853",
                "net_fpi": "-1.066666667",
                "total_sr": "-0.230111466",
                "total_stuffs": "-0.247961015",
                "total_pass_sr": "-0.562451118",
                "total_ppa": "-0.151367904"
            },
            {
                "college": "Michigan",
                "role": "HC",
                "net_ppa": "-0.022584963",
                "net_sr": "-0.012563422",
                "net_stuff": "0.022195899",
                "net_pass_sr": "-0.063473947",
                "net_fpi": "-13.46666667",
                "total_sr": "-0.037690265",
                "total_stuffs": "0.066587697",
                "total_pass_sr": "-0.190421841",
                "total_ppa": "-0.067754888"
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0625499135431982",
                "net_sr": "-0.0512858486828143",
                "net_stuff": "0.00836086869330444",
                "net_pass_sr": "-0.0709470095719114",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.11036286054319819
    },
    {
        "id": 19,
        "name": "Kevin Sumlin",
        "community": 15,
        "isWhite": false,
        "centrality": {
            "betweenness": 90,
            "closeness": 5.25649321465573e-7,
            "degree": 13,
            "eigen": 0.00168320971919195
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Houston",
                "position": "HC"
            },
            "2009": {
                "college": "Houston",
                "position": "HC"
            },
            "2010": {
                "college": "Houston",
                "position": "HC"
            },
            "2011": {
                "college": "Houston",
                "position": "HC"
            },
            "2012": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2013": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2014": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2015": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2016": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2017": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2018": {
                "college": "Arizona",
                "position": "HC"
            },
            "2019": {
                "college": "Arizona",
                "position": "HC"
            },
            "2020": {
                "college": "Arizona",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "HC",
                "net_ppa": "-0.072551626",
                "net_sr": "-0.030248203",
                "net_stuff": "-0.006623237",
                "net_pass_sr": "-0.015115119",
                "net_fpi": "-5.333333333",
                "total_sr": "-0.090744609",
                "total_stuffs": "-0.019869712",
                "total_pass_sr": "-0.045345358",
                "total_ppa": "-0.217654877"
            },
            {
                "college": "Houston",
                "role": "HC",
                "net_ppa": "0.063319986",
                "net_sr": "0.013140575",
                "net_stuff": "-0.01507621",
                "net_pass_sr": "0.029337352",
                "net_fpi": "6.191666667",
                "total_sr": "0.052562301",
                "total_stuffs": "-0.060304842",
                "total_pass_sr": "0.117349407",
                "total_ppa": "0.253279943"
            },
            {
                "college": "Texas A&M",
                "role": "HC",
                "net_ppa": "-0.004220501",
                "net_sr": "-0.021928538",
                "net_stuff": "-0.011370517",
                "net_pass_sr": "0.013309197",
                "net_fpi": "3.633333333",
                "total_sr": "-0.131571227",
                "total_stuffs": "-0.068223101",
                "total_pass_sr": "0.079855182",
                "total_ppa": "-0.025323008"
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.013452140999999999
    },
    {
        "id": 20,
        "name": "Jedd Fisch",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 8,
            "closeness": 4.89363680406368e-7,
            "degree": 4,
            "eigen": 0.0000478372644613982
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2010": false,
            "2011": {
                "college": "Miami",
                "position": "OC"
            },
            "2012": {
                "college": "Miami",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Arizona",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "HC",
                "net_ppa": "-0.114033035",
                "net_sr": "0.001880605",
                "net_stuff": "0.017933957",
                "net_pass_sr": "0.016917625",
                "net_fpi": "-8.766666667",
                "total_sr": "0.001880605",
                "total_stuffs": "0.017933957",
                "total_pass_sr": "0.016917625",
                "total_ppa": "-0.114033035"
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "0.0397288338607894",
                "net_sr": "0.0158806235480189",
                "net_stuff": "-0.00952566927336609",
                "net_pass_sr": "0.0255490081201165",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "-0.0471035175134362",
                "net_sr": "-0.0510313873722955",
                "net_stuff": "0.00551084642700359",
                "net_pass_sr": "-0.0459624947979209",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1214077186526468
    },
    {
        "id": 22,
        "name": "Dirk Koetter",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.92103946132334e-7,
            "degree": 3,
            "eigen": 0.0000490066625691582
        },
        "positions": {
            "2000": {
                "college": "Boise State",
                "position": "HC"
            },
            "2001": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2002": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2003": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2004": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2005": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2006": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "HC",
                "net_ppa": "-0.043975854",
                "net_sr": "0.025549187",
                "net_stuff": "-0.026364233",
                "net_pass_sr": "0.023737206",
                "net_fpi": "-5.5",
                "total_sr": "0.025549187",
                "total_stuffs": "-0.026364233",
                "total_pass_sr": "0.023737206",
                "total_ppa": "-0.043975854"
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.043975854
    },
    {
        "id": 23,
        "name": "Dennis Erickson",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 12,
            "closeness": 4.90046662243179e-7,
            "degree": 5,
            "eigen": 0.000250802593689483
        },
        "positions": {
            "2000": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2001": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2002": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2008": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2009": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2010": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2011": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2012": false,
            "2013": {
                "college": "Utah",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "HC",
                "net_ppa": "-0.037976361",
                "net_sr": "-0.024278868",
                "net_stuff": "0.001745316",
                "net_pass_sr": "-0.046802088",
                "net_fpi": "-2.53",
                "total_sr": "-0.121394338",
                "total_stuffs": "0.00872658",
                "total_pass_sr": "-0.234010439",
                "total_ppa": "-0.189881803"
            },
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "0.0502278384388648",
                "net_sr": "-0.000718842085355775",
                "net_stuff": "0.041183715508218",
                "net_pass_sr": "0.0114947117420039",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.012251477438864802
    },
    {
        "id": 24,
        "name": "Todd Graham",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 957.5,
            "closeness": 5.3813985501436e-7,
            "degree": 21,
            "eigen": 0.00133538107123135
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2003": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Rice",
                "position": "HC"
            },
            "2007": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2008": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2009": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2010": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2011": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2012": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2013": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2014": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2015": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2016": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2017": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2021": {
                "college": "Hawai'i",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "HC",
                "net_ppa": "-0.033796258",
                "net_sr": "0.009406472",
                "net_stuff": "0.003627054",
                "net_pass_sr": "0.039644205",
                "net_fpi": "2.216666667",
                "total_sr": "0.056438829",
                "total_stuffs": "0.021762324",
                "total_pass_sr": "0.23786523",
                "total_ppa": "-0.202777547"
            },
            {
                "college": "Hawai'i",
                "role": "HC",
                "net_ppa": "0.060324136",
                "net_sr": "-0.023970687",
                "net_stuff": "-0.031958262",
                "net_pass_sr": "-0.027220407",
                "net_fpi": "3.416666667",
                "total_sr": "-0.047941374",
                "total_stuffs": "-0.063916523",
                "total_pass_sr": "-0.054440815",
                "total_ppa": "0.120648272"
            },
            {
                "college": "Pittsburgh",
                "role": "HC",
                "net_ppa": "0.001806606",
                "net_sr": "-0.025741741",
                "net_stuff": "-0.025610638",
                "net_pass_sr": "-0.046282683",
                "net_fpi": "N/A",
                "total_sr": "-0.025741741",
                "total_stuffs": "-0.025610638",
                "total_pass_sr": "-0.046282683",
                "total_ppa": "0.001806606"
            },
            {
                "college": "Rice",
                "role": "HC",
                "net_ppa": "0.029051931",
                "net_sr": "0.039246225",
                "net_stuff": "0.031832118",
                "net_pass_sr": "0.186016609",
                "net_fpi": "7.7",
                "total_sr": "0.039246225",
                "total_stuffs": "0.031832118",
                "total_pass_sr": "0.186016609",
                "total_ppa": "0.029051931"
            },
            {
                "college": "Tulsa",
                "role": "HC",
                "net_ppa": "-0.018876794",
                "net_sr": "0.009953458",
                "net_stuff": "-0.019178264",
                "net_pass_sr": "-0.026657678",
                "net_fpi": "-0.875",
                "total_sr": "0.03981383",
                "total_stuffs": "-0.076713056",
                "total_pass_sr": "-0.106630714",
                "total_ppa": "-0.075507176"
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.038509620999999994
    },
    {
        "id": 25,
        "name": "Herm Edwards",
        "community": 19,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.99813819352291e-7,
            "degree": 4,
            "eigen": 0.000242344230943562
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2019": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2020": {
                "college": "Arizona State",
                "position": "HC"
            },
            "2021": {
                "college": "Arizona State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "HC",
                "net_ppa": "0.151665138",
                "net_sr": "0.042235309",
                "net_stuff": "0.035341671",
                "net_pass_sr": "0.014699438",
                "net_fpi": "3.041666667",
                "total_sr": "0.168941236",
                "total_stuffs": "0.141366683",
                "total_pass_sr": "0.058797752",
                "total_ppa": "0.606660551"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.151665138
    },
    {
        "id": 26,
        "name": "Houston Nutt",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.27627916793078e-7,
            "degree": 10,
            "eigen": 0.000170686229017089
        },
        "positions": {
            "2000": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2001": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2002": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2003": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2004": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2005": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2006": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2007": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2008": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2009": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2010": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2011": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "HC",
                "net_ppa": "0.115772959",
                "net_sr": "0.064753127",
                "net_stuff": "-0.031476513",
                "net_pass_sr": "0.123089121",
                "net_fpi": "6.2",
                "total_sr": "0.129506253",
                "total_stuffs": "-0.062953026",
                "total_pass_sr": "0.246178241",
                "total_ppa": "0.231545919"
            },
            {
                "college": "Ole Miss",
                "role": "HC",
                "net_ppa": "0.060077226",
                "net_sr": "0.068867094",
                "net_stuff": "-0.030300926",
                "net_pass_sr": "0.089197494",
                "net_fpi": "9.566666667",
                "total_sr": "0.275468377",
                "total_stuffs": "-0.121203705",
                "total_pass_sr": "0.356789976",
                "total_ppa": "0.240308905"
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.175850185
    },
    {
        "id": 27,
        "name": "Bobby Petrino",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 44,
            "closeness": 5.04485379509216e-7,
            "degree": 14,
            "eigen": 0.000343530274295791
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Auburn",
                "position": "OC"
            },
            "2003": {
                "college": "Louisville",
                "position": "HC"
            },
            "2004": {
                "college": "Louisville",
                "position": "HC"
            },
            "2005": {
                "college": "Louisville",
                "position": "HC"
            },
            "2006": {
                "college": "Louisville",
                "position": "HC"
            },
            "2007": false,
            "2008": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2009": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2010": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2011": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2012": false,
            "2013": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2014": {
                "college": "Louisville",
                "position": "HC"
            },
            "2015": {
                "college": "Louisville",
                "position": "HC"
            },
            "2016": {
                "college": "Louisville",
                "position": "HC"
            },
            "2017": {
                "college": "Louisville",
                "position": "HC"
            },
            "2018": {
                "college": "Louisville",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "HC",
                "net_ppa": "0.073074495",
                "net_sr": "-0.009586835",
                "net_stuff": "0.056740287",
                "net_pass_sr": "0.030707767",
                "net_fpi": "2.341666667",
                "total_sr": "-0.038347339",
                "total_stuffs": "0.226961148",
                "total_pass_sr": "0.122831068",
                "total_ppa": "0.29229798"
            },
            {
                "college": "Western Kentucky",
                "role": "HC",
                "net_ppa": "0.070857608",
                "net_sr": "0.088385882",
                "net_stuff": "0.037790766",
                "net_pass_sr": "0.128266959",
                "net_fpi": "N/A",
                "total_sr": "0.088385882",
                "total_stuffs": "0.037790766",
                "total_pass_sr": "0.128266959",
                "total_ppa": "0.070857608"
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.143932103
    },
    {
        "id": 28,
        "name": "John L. Smith",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.9454321021845e-7,
            "degree": 6,
            "eigen": 0.000163963906640472
        },
        "positions": {
            "2000": {
                "college": "Louisville",
                "position": "HC"
            },
            "2001": {
                "college": "Louisville",
                "position": "HC"
            },
            "2002": {
                "college": "Louisville",
                "position": "HC"
            },
            "2003": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2004": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2005": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2006": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "HC",
                "net_ppa": "-0.085906771",
                "net_sr": "0.013838978",
                "net_stuff": "-0.064786156",
                "net_pass_sr": "-0.043704525",
                "net_fpi": "-13.76666667",
                "total_sr": "0.013838978",
                "total_stuffs": "-0.064786156",
                "total_pass_sr": "-0.043704525",
                "total_ppa": "-0.085906771"
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.085906771
    },
    {
        "id": 29,
        "name": "Bret Bielema",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 259,
            "closeness": 5.64178713762801e-7,
            "degree": 14,
            "eigen": 0.00131875343321997
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2003": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2004": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2005": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2006": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2007": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2008": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2009": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2010": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2011": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2012": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2013": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2014": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2015": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2016": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2017": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Illinois",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "HC",
                "net_ppa": "-0.043388924",
                "net_sr": "-0.042093551",
                "net_stuff": "0.000801132",
                "net_pass_sr": "-0.054221353",
                "net_fpi": "-5.246666667",
                "total_sr": "-0.210467757",
                "total_stuffs": "0.004005662",
                "total_pass_sr": "-0.271106765",
                "total_ppa": "-0.216944621"
            },
            {
                "college": "Illinois",
                "role": "HC",
                "net_ppa": "0.056111515",
                "net_sr": "0.030249544",
                "net_stuff": "0.004900968",
                "net_pass_sr": "0.035133237",
                "net_fpi": "4.3",
                "total_sr": "0.030249544",
                "total_stuffs": "0.004900968",
                "total_pass_sr": "0.035133237",
                "total_ppa": "0.056111515"
            },
            {
                "college": "Wisconsin",
                "role": "HC",
                "net_ppa": "0.136670373",
                "net_sr": "0.091065583",
                "net_stuff": "-0.128909931",
                "net_pass_sr": "0.04499967",
                "net_fpi": "2.185714286",
                "total_sr": "0.637459081",
                "total_stuffs": "-0.902369515",
                "total_pass_sr": "0.31499769",
                "total_ppa": "0.956692612"
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.14939296400000002
    },
    {
        "id": 30,
        "name": "Chad Morris",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 115,
            "closeness": 4.89704693378752e-7,
            "degree": 6,
            "eigen": 0.00240908689861947
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2011": {
                "college": "Clemson",
                "position": "OC"
            },
            "2012": {
                "college": "Clemson",
                "position": "OC"
            },
            "2013": {
                "college": "Clemson",
                "position": "OC"
            },
            "2014": {
                "college": "Clemson",
                "position": "OC"
            },
            "2015": {
                "college": "SMU",
                "position": "HC"
            },
            "2016": {
                "college": "SMU",
                "position": "HC"
            },
            "2017": {
                "college": "SMU",
                "position": "HC"
            },
            "2018": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2019": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2020": {
                "college": "Auburn",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "HC",
                "net_ppa": "-0.183944345",
                "net_sr": "-0.07526475",
                "net_stuff": "-0.004587472",
                "net_pass_sr": "-0.116477092",
                "net_fpi": "-13.53333333",
                "total_sr": "-0.1505295",
                "total_stuffs": "-0.009174944",
                "total_pass_sr": "-0.232954183",
                "total_ppa": "-0.36788869"
            },
            {
                "college": "SMU",
                "role": "HC",
                "net_ppa": "0.022985213",
                "net_sr": "0.011571046",
                "net_stuff": "-0.004692664",
                "net_pass_sr": "0.046823381",
                "net_fpi": "0.866666667",
                "total_sr": "0.034713137",
                "total_stuffs": "-0.014077993",
                "total_pass_sr": "0.140470144",
                "total_ppa": "0.068955639"
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.0112681468697886",
                "net_sr": "-0.0112357199314795",
                "net_stuff": "-0.00401901493838883",
                "net_pass_sr": "-0.0176795937057788",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Clemson",
                "role": "OC",
                "net_ppa": "0.114657358423686",
                "net_sr": "0.0633654822991933",
                "net_stuff": "-0.0308895961693338",
                "net_pass_sr": "0.0735853137735973",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "0.0148959006885293",
                "net_sr": "0.00162166254904067",
                "net_stuff": "-0.0112037310063047",
                "net_pass_sr": "-0.0191952441425748",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.04267401975757331
    },
    {
        "id": 31,
        "name": "Sam Pittman",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.99458836350814e-7,
            "degree": 2,
            "eigen": 0.0000488460216803387
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Arkansas",
                "position": "HC"
            },
            "2021": {
                "college": "Arkansas",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "HC",
                "net_ppa": "0.22687015",
                "net_sr": "0.08218546",
                "net_stuff": "-0.032315547",
                "net_pass_sr": "0.123026589",
                "net_fpi": "10.83333333",
                "total_sr": "0.164370921",
                "total_stuffs": "-0.064631094",
                "total_pass_sr": "0.246053178",
                "total_ppa": "0.4537403"
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.22687015
    },
    {
        "id": 34,
        "name": "Hugh Freeze",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 168,
            "closeness": 5.53142930473806e-7,
            "degree": 9,
            "eigen": 0.0004054300083315
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2011": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2012": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2013": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2014": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2015": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2016": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Liberty",
                "position": "HC"
            },
            "2020": {
                "college": "Liberty",
                "position": "HC"
            },
            "2021": {
                "college": "Liberty",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "HC",
                "net_ppa": "0.140694299",
                "net_sr": "0.083019546",
                "net_stuff": "-0.058867222",
                "net_pass_sr": "0.113230896",
                "net_fpi": "6.7",
                "total_sr": "0.083019546",
                "total_stuffs": "-0.058867222",
                "total_pass_sr": "0.113230896",
                "total_ppa": "0.140694299"
            },
            {
                "college": "Liberty",
                "role": "HC",
                "net_ppa": "0.290700937",
                "net_sr": "0.10648858",
                "net_stuff": "-0.063398381",
                "net_pass_sr": "0.050610862",
                "net_fpi": "N/A",
                "total_sr": "0.319465741",
                "total_stuffs": "-0.190195142",
                "total_pass_sr": "0.151832586",
                "total_ppa": "0.872102812"
            },
            {
                "college": "Ole Miss",
                "role": "HC",
                "net_ppa": "0.129849064",
                "net_sr": "0.049976893",
                "net_stuff": "-0.025859771",
                "net_pass_sr": "0.032395345",
                "net_fpi": "12.84",
                "total_sr": "0.249884464",
                "total_stuffs": "-0.129298855",
                "total_pass_sr": "0.161976724",
                "total_ppa": "0.649245321"
            },
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "0.0500953640169264",
                "net_sr": "0.0352517449619406",
                "net_stuff": "-0.0309687729096136",
                "net_pass_sr": "0.0908394476052241",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.6113396640169263
    },
    {
        "id": 35,
        "name": "Gus Malzahn",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 2853.5,
            "closeness": 5.2448973704707e-7,
            "degree": 18,
            "eigen": 0.00134784630016459
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2007": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2008": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2009": {
                "college": "Auburn",
                "position": "OC"
            },
            "2010": {
                "college": "Auburn",
                "position": "OC"
            },
            "2011": {
                "college": "Auburn",
                "position": "OC"
            },
            "2012": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2013": {
                "college": "Auburn",
                "position": "HC"
            },
            "2014": {
                "college": "Auburn",
                "position": "HC"
            },
            "2015": {
                "college": "Auburn",
                "position": "HC"
            },
            "2016": {
                "college": "Auburn",
                "position": "HC"
            },
            "2017": {
                "college": "Auburn",
                "position": "HC"
            },
            "2018": {
                "college": "Auburn",
                "position": "HC"
            },
            "2019": {
                "college": "Auburn",
                "position": "HC"
            },
            "2020": {
                "college": "Auburn",
                "position": "HC"
            },
            "2021": {
                "college": "UCF",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "HC",
                "net_ppa": "0.019676024",
                "net_sr": "0.033525046",
                "net_stuff": "-0.010740452",
                "net_pass_sr": "-0.031874704",
                "net_fpi": "7.733333333",
                "total_sr": "0.033525046",
                "total_stuffs": "-0.010740452",
                "total_pass_sr": "-0.031874704",
                "total_ppa": "0.019676024"
            },
            {
                "college": "Auburn",
                "role": "HC",
                "net_ppa": "0.11015524",
                "net_sr": "0.064549977",
                "net_stuff": "-0.030034137",
                "net_pass_sr": "0.07871733",
                "net_fpi": "9.254166667",
                "total_sr": "0.51639982",
                "total_stuffs": "-0.240273095",
                "total_pass_sr": "0.629738643",
                "total_ppa": "0.881241917"
            },
            {
                "college": "UCF",
                "role": "HC",
                "net_ppa": "-0.098211839",
                "net_sr": "-0.016023936",
                "net_stuff": "0.028880487",
                "net_pass_sr": "-0.021418849",
                "net_fpi": "-9.733333333",
                "total_sr": "-0.016023936",
                "total_stuffs": "0.028880487",
                "total_pass_sr": "-0.021418849",
                "total_ppa": "-0.098211839"
            },
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "-0.0138210553357889",
                "net_sr": "0.00280748232136002",
                "net_stuff": "-0.0156655020849769",
                "net_pass_sr": "0.0227017262143717",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "0.0712014311899427",
                "net_sr": "0.0435408319318874",
                "net_stuff": "-0.0523224994649328",
                "net_pass_sr": "0.0130248502067555",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "0.0488038009193826",
                "net_sr": "0.0591396104687906",
                "net_stuff": "-0.0343866613112327",
                "net_pass_sr": "0.0411380532308823",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1378036017735364
    },
    {
        "id": 36,
        "name": "Bryan Harsin",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 211,
            "closeness": 5.14502805069293e-7,
            "degree": 14,
            "eigen": 0.0136549497792982
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Boise State",
                "position": "OC"
            },
            "2007": {
                "college": "Boise State",
                "position": "OC"
            },
            "2008": {
                "college": "Boise State",
                "position": "OC"
            },
            "2009": {
                "college": "Boise State",
                "position": "OC"
            },
            "2010": {
                "college": "Boise State",
                "position": "OC"
            },
            "2011": {
                "college": "Texas",
                "position": "OC"
            },
            "2012": {
                "college": "Texas",
                "position": "OC"
            },
            "2013": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2014": {
                "college": "Boise State",
                "position": "HC"
            },
            "2015": {
                "college": "Boise State",
                "position": "HC"
            },
            "2016": {
                "college": "Boise State",
                "position": "HC"
            },
            "2017": {
                "college": "Boise State",
                "position": "HC"
            },
            "2018": {
                "college": "Boise State",
                "position": "HC"
            },
            "2019": {
                "college": "Boise State",
                "position": "HC"
            },
            "2020": {
                "college": "Boise State",
                "position": "HC"
            },
            "2021": {
                "college": "Auburn",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "HC",
                "net_ppa": "-0.075650804",
                "net_sr": "-0.035829937",
                "net_stuff": "0.030304578",
                "net_pass_sr": "-0.029684971",
                "net_fpi": "-3.366666667",
                "total_sr": "-0.035829937",
                "total_stuffs": "0.030304578",
                "total_pass_sr": "-0.029684971",
                "total_ppa": "-0.075650804"
            },
            {
                "college": "Auburn",
                "role": "HC",
                "net_ppa": "0.034785661",
                "net_sr": "-0.016233568",
                "net_stuff": "-0.02076933",
                "net_pass_sr": "-0.056511426",
                "net_fpi": "-6.966666667",
                "total_sr": "-0.016233568",
                "total_stuffs": "-0.02076933",
                "total_pass_sr": "-0.056511426",
                "total_ppa": "0.034785661"
            },
            {
                "college": "Boise State",
                "role": "HC",
                "net_ppa": "0.062296419",
                "net_sr": "0.010879755",
                "net_stuff": "-0.031557674",
                "net_pass_sr": "-0.033629051",
                "net_fpi": "-4.347619048",
                "total_sr": "0.076158287",
                "total_stuffs": "-0.220903721",
                "total_pass_sr": "-0.23540336",
                "total_ppa": "0.43607493"
            },
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "0.0526114762087131",
                "net_sr": "0.0561740847192935",
                "net_stuff": "-0.00143749850340519",
                "net_pass_sr": "0.0962186028556964",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "-0.0125279243738622",
                "net_sr": "-0.0159226273758408",
                "net_stuff": "-0.0123958219205582",
                "net_pass_sr": "-0.0172268033252987",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.06151482783485089
    },
    {
        "id": 37,
        "name": "Blake Anderson",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 203.5,
            "closeness": 4.92461158357287e-7,
            "degree": 11,
            "eigen": 0.000502319444699485
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2004": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2011": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2012": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2013": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2014": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2015": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2016": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2017": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2018": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2019": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2020": {
                "college": "Arkansas State",
                "position": "HC"
            },
            "2021": {
                "college": "Utah State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "HC",
                "net_ppa": "-0.020928986",
                "net_sr": "-0.021525657",
                "net_stuff": "0.001876864",
                "net_pass_sr": "-0.028180851",
                "net_fpi": "-2.747619048",
                "total_sr": "-0.150679596",
                "total_stuffs": "0.013138049",
                "total_pass_sr": "-0.197265955",
                "total_ppa": "-0.1465029"
            },
            {
                "college": "Utah State",
                "role": "HC",
                "net_ppa": "0.048807251",
                "net_sr": "-0.00978922",
                "net_stuff": "-0.047380463",
                "net_pass_sr": "0.024612411",
                "net_fpi": "2.133333333",
                "total_sr": "-0.00978922",
                "total_stuffs": "-0.047380463",
                "total_pass_sr": "0.024612411",
                "total_ppa": "0.048807251"
            },
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.0285375685212438",
                "net_sr": "0.0333028577761155",
                "net_stuff": "-0.0403337757867375",
                "net_pass_sr": "-0.0128671097675722",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "0.0557643574015833",
                "net_sr": "0.0399139908285679",
                "net_stuff": "-0.0279545277131049",
                "net_pass_sr": "0.0452736619517663",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "8.3125860718597e-05",
                "net_sr": "0.0178629561398416",
                "net_stuff": "0.00247285451418122",
                "net_pass_sr": "0.0330462658475675",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0551881797410581
    },
    {
        "id": 38,
        "name": "Butch Jones",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 23,
            "closeness": 4.91766839571887e-7,
            "degree": 8,
            "eigen": 0.000337616036706764
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2002": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2003": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2008": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2009": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2010": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2011": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2012": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2013": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2014": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2015": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2016": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2017": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Arkansas State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "HC",
                "net_ppa": "-0.251806543",
                "net_sr": "-0.091545435",
                "net_stuff": "0.036625645",
                "net_pass_sr": "-0.078151316",
                "net_fpi": "-10",
                "total_sr": "-0.091545435",
                "total_stuffs": "0.036625645",
                "total_pass_sr": "-0.078151316",
                "total_ppa": "-0.251806543"
            },
            {
                "college": "Central Michigan",
                "role": "HC",
                "net_ppa": "-0.010603141",
                "net_sr": "0.063308061",
                "net_stuff": "-0.031839889",
                "net_pass_sr": "0.044349854",
                "net_fpi": "N/A",
                "total_sr": "0.189924184",
                "total_stuffs": "-0.095519668",
                "total_pass_sr": "0.133049563",
                "total_ppa": "-0.031809422"
            },
            {
                "college": "Cincinnati",
                "role": "HC",
                "net_ppa": "-0.040810642",
                "net_sr": "-0.01564375",
                "net_stuff": "-0.008688933",
                "net_pass_sr": "-0.061719943",
                "net_fpi": "-4.033333333",
                "total_sr": "-0.04693125",
                "total_stuffs": "-0.0260668",
                "total_pass_sr": "-0.18515983",
                "total_ppa": "-0.122431925"
            },
            {
                "college": "Tennessee",
                "role": "HC",
                "net_ppa": "-0.054066355",
                "net_sr": "0.010999107",
                "net_stuff": "-0.015148397",
                "net_pass_sr": "0.017945644",
                "net_fpi": "2.806666667",
                "total_sr": "0.054995537",
                "total_stuffs": "-0.075741983",
                "total_pass_sr": "0.089728218",
                "total_ppa": "-0.270331776"
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.357286681
    },
    {
        "id": 39,
        "name": "Todd Berry",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 30,
            "closeness": 4.90389830491851e-7,
            "degree": 7,
            "eigen": 0.000373035764239212
        },
        "positions": {
            "2000": {
                "college": "Army",
                "position": "HC"
            },
            "2001": {
                "college": "Army",
                "position": "HC"
            },
            "2002": {
                "college": "Army",
                "position": "HC"
            },
            "2003": {
                "college": "Army",
                "position": "HC"
            },
            "2004": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2005": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2006": false,
            "2007": {
                "college": "UNLV",
                "position": "OC"
            },
            "2008": {
                "college": "UNLV",
                "position": "OC"
            },
            "2009": {
                "college": "UNLV",
                "position": "OC"
            },
            "2010": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2011": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2012": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2013": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2014": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2015": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "HC",
                "net_ppa": "-0.007924257",
                "net_sr": "0.002536689",
                "net_stuff": "-0.019120048",
                "net_pass_sr": "0.008879005",
                "net_fpi": "N/A",
                "total_sr": "0.015220133",
                "total_stuffs": "-0.114720286",
                "total_pass_sr": "0.053274031",
                "total_ppa": "-0.047545539"
            },
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "0.0214136933635301",
                "net_sr": "0.0230977758876396",
                "net_stuff": "-0.0563809289381929",
                "net_pass_sr": "0.0377082200994712",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.013489436363530099
    },
    {
        "id": 41,
        "name": "Stan Brock",
        "community": 26,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89363201453213e-7,
            "degree": 2,
            "eigen": 0
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Army",
                "position": "HC"
            },
            "2008": {
                "college": "Army",
                "position": "HC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Army",
                "role": "HC",
                "net_ppa": "-0.034237933",
                "net_sr": "-0.036061723",
                "net_stuff": "-0.046627594",
                "net_pass_sr": "-0.130343411",
                "net_fpi": "-5.05",
                "total_sr": "-0.072123445",
                "total_stuffs": "-0.093255188",
                "total_pass_sr": "-0.260686822",
                "total_ppa": "-0.068475865"
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.034237933
    },
    {
        "id": 42,
        "name": "Rich Ellerson",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 3,
            "closeness": 4.89703734137884e-7,
            "degree": 4,
            "eigen": 0.0000163028610532067
        },
        "positions": {
            "2000": {
                "college": "Arizona",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Army",
                "position": "HC"
            },
            "2010": {
                "college": "Army",
                "position": "HC"
            },
            "2011": {
                "college": "Army",
                "position": "HC"
            },
            "2012": {
                "college": "Army",
                "position": "HC"
            },
            "2013": {
                "college": "Army",
                "position": "HC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Army",
                "role": "HC",
                "net_ppa": "0.151222383",
                "net_sr": "0.080185716",
                "net_stuff": "-0.092926505",
                "net_pass_sr": "0.065178395",
                "net_fpi": "3.306666667",
                "total_sr": "0.400928582",
                "total_stuffs": "-0.464632525",
                "total_pass_sr": "0.325891975",
                "total_ppa": "0.756111915"
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.151222383
    },
    {
        "id": 43,
        "name": "Jeff Monken",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90734442982056e-7,
            "degree": 6,
            "eigen": 0.000132686316942419
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Army",
                "position": "HC"
            },
            "2015": {
                "college": "Army",
                "position": "HC"
            },
            "2016": {
                "college": "Army",
                "position": "HC"
            },
            "2017": {
                "college": "Army",
                "position": "HC"
            },
            "2018": {
                "college": "Army",
                "position": "HC"
            },
            "2019": {
                "college": "Army",
                "position": "HC"
            },
            "2020": {
                "college": "Army",
                "position": "HC"
            },
            "2021": {
                "college": "Army",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Army",
                "role": "HC",
                "net_ppa": "0.052009756",
                "net_sr": "0.034255779",
                "net_stuff": "-0.001356149",
                "net_pass_sr": "0.015391993",
                "net_fpi": "7.220833333",
                "total_sr": "0.27404623",
                "total_stuffs": "-0.010849193",
                "total_pass_sr": "0.123135948",
                "total_ppa": "0.416078046"
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.052009756
    },
    {
        "id": 44,
        "name": "Tommy Tuberville",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.77446554434154e-7,
            "degree": 20,
            "eigen": 0.00105960945185831
        },
        "positions": {
            "2000": {
                "college": "Auburn",
                "position": "HC"
            },
            "2001": {
                "college": "Auburn",
                "position": "HC"
            },
            "2002": {
                "college": "Auburn",
                "position": "HC"
            },
            "2003": {
                "college": "Auburn",
                "position": "HC"
            },
            "2004": {
                "college": "Auburn",
                "position": "HC"
            },
            "2005": {
                "college": "Auburn",
                "position": "HC"
            },
            "2006": {
                "college": "Auburn",
                "position": "HC"
            },
            "2007": {
                "college": "Auburn",
                "position": "HC"
            },
            "2008": {
                "college": "Auburn",
                "position": "HC"
            },
            "2009": false,
            "2010": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2011": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2012": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2013": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2014": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2015": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2016": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "HC",
                "net_ppa": "0.011659942",
                "net_sr": "0.007598656",
                "net_stuff": "0.02060876",
                "net_pass_sr": "0.063345054",
                "net_fpi": "-4.458333333",
                "total_sr": "0.030394623",
                "total_stuffs": "0.08243504",
                "total_pass_sr": "0.253380216",
                "total_ppa": "0.04663977"
            },
            {
                "college": "Texas Tech",
                "role": "HC",
                "net_ppa": "-0.069697763",
                "net_sr": "-0.023031986",
                "net_stuff": "0.033744616",
                "net_pass_sr": "-0.0364195",
                "net_fpi": "-9.633333333",
                "total_sr": "-0.069095959",
                "total_stuffs": "0.101233849",
                "total_pass_sr": "-0.109258499",
                "total_ppa": "-0.209093289"
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.058037820999999996
    },
    {
        "id": 45,
        "name": "Gene Chizik",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 2282,
            "closeness": 5.29563967620341e-7,
            "degree": 10,
            "eigen": 0.0010372799661025
        },
        "positions": {
            "2000": {
                "college": "UCF",
                "position": "DC"
            },
            "2001": {
                "college": "UCF",
                "position": "DC"
            },
            "2002": {
                "college": "Auburn",
                "position": "DC"
            },
            "2003": {
                "college": "Auburn",
                "position": "DC"
            },
            "2004": {
                "college": "Auburn",
                "position": "DC"
            },
            "2005": {
                "college": "Texas",
                "position": "DC"
            },
            "2006": {
                "college": "Texas",
                "position": "DC"
            },
            "2007": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2008": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2009": {
                "college": "Auburn",
                "position": "HC"
            },
            "2010": {
                "college": "Auburn",
                "position": "HC"
            },
            "2011": {
                "college": "Auburn",
                "position": "HC"
            },
            "2012": {
                "college": "Auburn",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2016": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Auburn",
                "role": "HC",
                "net_ppa": "-0.027152603",
                "net_sr": "-0.015253928",
                "net_stuff": "-0.018476317",
                "net_pass_sr": "-0.055588742",
                "net_fpi": "-0.983333333",
                "total_sr": "-0.061015712",
                "total_stuffs": "-0.073905268",
                "total_pass_sr": "-0.222354968",
                "total_ppa": "-0.108610411"
            },
            {
                "college": "Iowa State",
                "role": "HC",
                "net_ppa": "-0.014006823",
                "net_sr": "-0.04937678",
                "net_stuff": "0.03208593",
                "net_pass_sr": "-0.033808909",
                "net_fpi": "-9.35",
                "total_sr": "-0.098753561",
                "total_stuffs": "0.064171861",
                "total_pass_sr": "-0.067617819",
                "total_ppa": "-0.028013646"
            },
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "-0.0596697821069093",
                "net_sr": "-0.0378198456487145",
                "net_stuff": "0.0651499690458202",
                "net_pass_sr": "-0.00609164166671933",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.10082920810690929
    },
    {
        "id": 46,
        "name": "Bill Lynch",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 3,
            "closeness": 4.91767081406631e-7,
            "degree": 7,
            "eigen": 0.000169110183937648
        },
        "positions": {
            "2000": {
                "college": "Ball State",
                "position": "HC"
            },
            "2001": {
                "college": "Ball State",
                "position": "HC"
            },
            "2002": {
                "college": "Ball State",
                "position": "HC"
            },
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Indiana",
                "position": "OC"
            },
            "2006": false,
            "2007": {
                "college": "Indiana",
                "position": "HC"
            },
            "2008": {
                "college": "Indiana",
                "position": "HC"
            },
            "2009": {
                "college": "Indiana",
                "position": "HC"
            },
            "2010": {
                "college": "Indiana",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "HC",
                "net_ppa": "0.000148697",
                "net_sr": "-0.009018835",
                "net_stuff": "0.031192672",
                "net_pass_sr": "0.014236626",
                "net_fpi": "-0.6",
                "total_sr": "-0.03607534",
                "total_stuffs": "0.124770687",
                "total_pass_sr": "0.056946505",
                "total_ppa": "0.000594789"
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.000148697
    },
    {
        "id": 47,
        "name": "Brady Hoke",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 165,
            "closeness": 5.03774784459959e-7,
            "degree": 12,
            "eigen": 0.0016894547086319
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Ball State",
                "position": "HC"
            },
            "2004": {
                "college": "Ball State",
                "position": "HC"
            },
            "2005": {
                "college": "Ball State",
                "position": "HC"
            },
            "2006": {
                "college": "Ball State",
                "position": "HC"
            },
            "2007": {
                "college": "Ball State",
                "position": "HC"
            },
            "2008": {
                "college": "Ball State",
                "position": "HC"
            },
            "2009": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2010": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2011": {
                "college": "Michigan",
                "position": "HC"
            },
            "2012": {
                "college": "Michigan",
                "position": "HC"
            },
            "2013": {
                "college": "Michigan",
                "position": "HC"
            },
            "2014": {
                "college": "Michigan",
                "position": "HC"
            },
            "2015": false,
            "2016": {
                "college": "Oregon",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2021": {
                "college": "San Diego State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Michigan",
                "role": "HC",
                "net_ppa": "0.043188689",
                "net_sr": "-0.001905876",
                "net_stuff": "0.050230323",
                "net_pass_sr": "0.032613878",
                "net_fpi": "10.90833333",
                "total_sr": "-0.007623503",
                "total_stuffs": "0.200921291",
                "total_pass_sr": "0.130455511",
                "total_ppa": "0.172754757"
            },
            {
                "college": "San Diego State",
                "role": "HC",
                "net_ppa": "0.142250066",
                "net_sr": "0.098967555",
                "net_stuff": "-0.088194933",
                "net_pass_sr": "0.085207832",
                "net_fpi": "11.22051282",
                "total_sr": "1.286578218",
                "total_stuffs": "-1.146534134",
                "total_pass_sr": "1.107701812",
                "total_ppa": "1.849250855"
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "-0.201023101805111",
                "net_sr": "-0.0939680661996804",
                "net_stuff": "-0.00728321000146787",
                "net_pass_sr": "-0.0871229389721717",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.015584346805110977
    },
    {
        "id": 48,
        "name": "Stan Parrish",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 43,
            "closeness": 4.897061322471e-7,
            "degree": 6,
            "eigen": 0.000246275764077161
        },
        "positions": {
            "2000": {
                "college": "Michigan",
                "position": "OC"
            },
            "2001": {
                "college": "Michigan",
                "position": "OC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Ball State",
                "position": "OC"
            },
            "2007": {
                "college": "Ball State",
                "position": "OC"
            },
            "2008": {
                "college": "Ball State",
                "position": "OC"
            },
            "2009": {
                "college": "Ball State",
                "position": "HC"
            },
            "2010": {
                "college": "Ball State",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "HC",
                "net_ppa": "-0.069588421",
                "net_sr": "-0.06240506",
                "net_stuff": "0.004127165",
                "net_pass_sr": "-0.108909083",
                "net_fpi": "-13.7",
                "total_sr": "-0.12481012",
                "total_stuffs": "0.008254331",
                "total_pass_sr": "-0.217818166",
                "total_ppa": "-0.139176842"
            },
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "0.107726073759637",
                "net_sr": "0.0993736265798965",
                "net_stuff": "0.0116675033234725",
                "net_pass_sr": "0.127586499182529",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Eastern Michigan",
                "role": "OC",
                "net_ppa": "0.0364820038882568",
                "net_sr": "0.0130744426097251",
                "net_stuff": "-0.0470343825368993",
                "net_pass_sr": "0.0167539748184909",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0746196566478938
    },
    {
        "id": 49,
        "name": "Pete Lembo",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90048103121802e-7,
            "degree": 4,
            "eigen": 0.0000792603073948628
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Ball State",
                "position": "HC"
            },
            "2012": {
                "college": "Ball State",
                "position": "HC"
            },
            "2013": {
                "college": "Ball State",
                "position": "HC"
            },
            "2014": {
                "college": "Ball State",
                "position": "HC"
            },
            "2015": {
                "college": "Ball State",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "HC",
                "net_ppa": "-0.057692016",
                "net_sr": "-0.001810191",
                "net_stuff": "0.007685692",
                "net_pass_sr": "0.020323183",
                "net_fpi": "1.173333333",
                "total_sr": "-0.009050954",
                "total_stuffs": "0.038428461",
                "total_pass_sr": "0.101615916",
                "total_ppa": "-0.288460082"
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.057692016
    },
    {
        "id": 50,
        "name": "Mike Neu",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.91422949550029e-7,
            "degree": 5,
            "eigen": 0.0000142597374915277
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Ball State",
                "position": "HC"
            },
            "2017": {
                "college": "Ball State",
                "position": "HC"
            },
            "2018": {
                "college": "Ball State",
                "position": "HC"
            },
            "2019": {
                "college": "Ball State",
                "position": "HC"
            },
            "2020": {
                "college": "Ball State",
                "position": "HC"
            },
            "2021": {
                "college": "Ball State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "HC",
                "net_ppa": "0.02019391",
                "net_sr": "-0.002614782",
                "net_stuff": "-0.059258961",
                "net_pass_sr": "-0.030927711",
                "net_fpi": "-4.6",
                "total_sr": "-0.01568869",
                "total_stuffs": "-0.355553764",
                "total_pass_sr": "-0.185566267",
                "total_ppa": "0.121163458"
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.02019391
    },
    {
        "id": 51,
        "name": "Kevin Steele",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 129,
            "closeness": 4.91080745951653e-7,
            "degree": 8,
            "eigen": 0.00244253864510175
        },
        "positions": {
            "2000": {
                "college": "Baylor",
                "position": "HC"
            },
            "2001": {
                "college": "Baylor",
                "position": "HC"
            },
            "2002": {
                "college": "Baylor",
                "position": "HC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Alabama",
                "position": "DC"
            },
            "2008": {
                "college": "Alabama",
                "position": "DC"
            },
            "2009": {
                "college": "Clemson",
                "position": "DC"
            },
            "2010": {
                "college": "Clemson",
                "position": "DC"
            },
            "2011": {
                "college": "Clemson",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "LSU",
                "position": "DC"
            },
            "2016": {
                "college": "Auburn",
                "position": "DC"
            },
            "2017": {
                "college": "Auburn",
                "position": "DC"
            },
            "2018": {
                "college": "Auburn",
                "position": "DC"
            },
            "2019": {
                "college": "Auburn",
                "position": "DC"
            },
            "2020": {
                "college": "Auburn",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.0154595738219216",
                "net_sr": "-0.0184149760472773",
                "net_stuff": "0.00665361820589874",
                "net_pass_sr": "-0.00763348492647153",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "0.0449603333864458",
                "net_sr": "0.0424172211926477",
                "net_stuff": "-0.000754015005314329",
                "net_pass_sr": "0.0456997366283589",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Clemson",
                "role": "OC",
                "net_ppa": "-0.0121370815430028",
                "net_sr": "0.00549939063201871",
                "net_stuff": "0.00788691522454463",
                "net_pass_sr": "0.0190678378739469",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.090377746359871",
                "net_sr": "-0.0270530300504595",
                "net_stuff": "0.0358814342264613",
                "net_pass_sr": "-0.0348970924112951",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.042094920694506405
    },
    {
        "id": 52,
        "name": "Guy Morriss",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90391273389212e-7,
            "degree": 5,
            "eigen": 0.000530412833877849
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2002": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2003": {
                "college": "Baylor",
                "position": "HC"
            },
            "2004": {
                "college": "Baylor",
                "position": "HC"
            },
            "2005": {
                "college": "Baylor",
                "position": "HC"
            },
            "2006": {
                "college": "Baylor",
                "position": "HC"
            },
            "2007": {
                "college": "Baylor",
                "position": "HC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "HC",
                "net_ppa": "-0.006260462",
                "net_sr": "-0.03217721",
                "net_stuff": "0.047227202",
                "net_pass_sr": "0.013335521",
                "net_fpi": "-5.2",
                "total_sr": "-0.06435442",
                "total_stuffs": "0.094454405",
                "total_pass_sr": "0.026671042",
                "total_ppa": "-0.012520923"
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.006260462
    },
    {
        "id": 53,
        "name": "Art Briles",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.93488176763517e-7,
            "degree": 7,
            "eigen": 0.000446713685943767
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Houston",
                "position": "HC"
            },
            "2004": {
                "college": "Houston",
                "position": "HC"
            },
            "2005": {
                "college": "Houston",
                "position": "HC"
            },
            "2006": {
                "college": "Houston",
                "position": "HC"
            },
            "2007": {
                "college": "Houston",
                "position": "HC"
            },
            "2008": {
                "college": "Baylor",
                "position": "HC"
            },
            "2009": {
                "college": "Baylor",
                "position": "HC"
            },
            "2010": {
                "college": "Baylor",
                "position": "HC"
            },
            "2011": {
                "college": "Baylor",
                "position": "HC"
            },
            "2012": {
                "college": "Baylor",
                "position": "HC"
            },
            "2013": {
                "college": "Baylor",
                "position": "HC"
            },
            "2014": {
                "college": "Baylor",
                "position": "HC"
            },
            "2015": {
                "college": "Baylor",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "HC",
                "net_ppa": "0.21471906",
                "net_sr": "0.105170215",
                "net_stuff": "-0.099578531",
                "net_pass_sr": "0.050082825",
                "net_fpi": "19.56666667",
                "total_sr": "0.841361719",
                "total_stuffs": "-0.796628247",
                "total_pass_sr": "0.400662597",
                "total_ppa": "1.717752484"
            },
            {
                "college": "Houston",
                "role": "HC",
                "net_ppa": "-0.046847753",
                "net_sr": "-0.001675557",
                "net_stuff": "0.069100972",
                "net_pass_sr": "0.029289738",
                "net_fpi": "4.4",
                "total_sr": "-0.003351115",
                "total_stuffs": "0.138201944",
                "total_pass_sr": "0.058579475",
                "total_ppa": "-0.093695506"
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.167871307
    },
    {
        "id": 54,
        "name": "Jim Grobe",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.994653223724e-7,
            "degree": 8,
            "eigen": 0.000742992712644425
        },
        "positions": {
            "2000": {
                "college": "Ohio",
                "position": "HC"
            },
            "2001": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2002": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2003": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2004": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2005": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2006": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2007": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2008": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2009": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2010": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2011": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2012": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2013": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Baylor",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "HC",
                "net_ppa": "-0.117214252",
                "net_sr": "-0.064873046",
                "net_stuff": "0.03815717",
                "net_pass_sr": "-0.036646471",
                "net_fpi": "-14.83333333",
                "total_sr": "-0.064873046",
                "total_stuffs": "0.03815717",
                "total_pass_sr": "-0.036646471",
                "total_ppa": "-0.117214252"
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.117214252
    },
    {
        "id": 55,
        "name": "Matt Rhule",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 12,
            "closeness": 4.90046422097565e-7,
            "degree": 5,
            "eigen": 0.000189068476644839
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Temple",
                "position": "OC"
            },
            "2009": {
                "college": "Temple",
                "position": "OC"
            },
            "2010": {
                "college": "Temple",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Temple",
                "position": "HC"
            },
            "2014": {
                "college": "Temple",
                "position": "HC"
            },
            "2015": {
                "college": "Temple",
                "position": "HC"
            },
            "2016": {
                "college": "Temple",
                "position": "HC"
            },
            "2017": {
                "college": "Baylor",
                "position": "HC"
            },
            "2018": {
                "college": "Baylor",
                "position": "HC"
            },
            "2019": {
                "college": "Baylor",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "HC",
                "net_ppa": "-0.16469663",
                "net_sr": "-0.084896469",
                "net_stuff": "0.091198302",
                "net_pass_sr": "-0.108799705",
                "net_fpi": "-14.1",
                "total_sr": "-0.254689408",
                "total_stuffs": "0.273594906",
                "total_pass_sr": "-0.326399114",
                "total_ppa": "-0.494089891"
            },
            {
                "college": "Temple",
                "role": "HC",
                "net_ppa": "0.060762239",
                "net_sr": "0.032564139",
                "net_stuff": "-0.036672798",
                "net_pass_sr": "0.01533719",
                "net_fpi": "2.075",
                "total_sr": "0.130256557",
                "total_stuffs": "-0.146691194",
                "total_pass_sr": "0.06134876",
                "total_ppa": "0.243048956"
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "0.0402587078773059",
                "net_sr": "0.045902798493854",
                "net_stuff": "-0.0383657013844043",
                "net_pass_sr": "-0.00167246866193671",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.06367568312269412
    },
    {
        "id": 56,
        "name": "Dave Aranda",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 1563.5,
            "closeness": 5.35956231670297e-7,
            "degree": 8,
            "eigen": 0.00125993590999931
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2011": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2012": {
                "college": "Utah State",
                "position": "DC"
            },
            "2013": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2014": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2015": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2016": {
                "college": "LSU",
                "position": "DC"
            },
            "2017": {
                "college": "LSU",
                "position": "DC"
            },
            "2018": {
                "college": "LSU",
                "position": "DC"
            },
            "2019": {
                "college": "LSU",
                "position": "DC"
            },
            "2020": {
                "college": "Baylor",
                "position": "HC"
            },
            "2021": {
                "college": "Baylor",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "HC",
                "net_ppa": "0.004803744",
                "net_sr": "0.013223438",
                "net_stuff": "0.019936293",
                "net_pass_sr": "0.03086556",
                "net_fpi": "4.45",
                "total_sr": "0.026446877",
                "total_stuffs": "0.039872585",
                "total_pass_sr": "0.061731119",
                "total_ppa": "0.009607487"
            },
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.013931885340843",
                "net_sr": "0.0127168455803292",
                "net_stuff": "0.0487623367433906",
                "net_pass_sr": "-0.00955311131002123",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.0126506670611919",
                "net_sr": "0.0195872083153711",
                "net_stuff": "0.0155922220806498",
                "net_pass_sr": "0.0265963221257207",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.0842430133587885",
                "net_sr": "0.0451862532295318",
                "net_stuff": "0.00292390063889855",
                "net_pass_sr": "0.0555323933144333",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "-0.00276512279379093",
                "net_sr": "0.0211765218467584",
                "net_stuff": "-0.000751746818148186",
                "net_pass_sr": "0.0411904499495415",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Other",
        "aggregate_xpa": 0.05969908216296267
    },
    {
        "id": 57,
        "name": "Dan Hawkins",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.42070057134184e-7,
            "degree": 5,
            "eigen": 0.117656668131433
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Boise State",
                "position": "HC"
            },
            "2002": {
                "college": "Boise State",
                "position": "HC"
            },
            "2003": {
                "college": "Boise State",
                "position": "HC"
            },
            "2004": {
                "college": "Boise State",
                "position": "HC"
            },
            "2005": {
                "college": "Boise State",
                "position": "HC"
            },
            "2006": {
                "college": "Colorado",
                "position": "HC"
            },
            "2007": {
                "college": "Colorado",
                "position": "HC"
            },
            "2008": {
                "college": "Colorado",
                "position": "HC"
            },
            "2009": {
                "college": "Colorado",
                "position": "HC"
            },
            "2010": {
                "college": "Colorado",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "HC",
                "net_ppa": "-0.002417281",
                "net_sr": "-0.055214863",
                "net_stuff": "-0.021178429",
                "net_pass_sr": "-0.10896974",
                "net_fpi": "-7.14",
                "total_sr": "-0.276074315",
                "total_stuffs": "-0.105892147",
                "total_pass_sr": "-0.544848701",
                "total_ppa": "-0.012086406"
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.002417281
    },
    {
        "id": 58,
        "name": "Chris Petersen",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 87,
            "closeness": 5.22063445326384e-7,
            "degree": 10,
            "eigen": 0.0449974001944667
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Boise State",
                "position": "OC"
            },
            "2002": {
                "college": "Boise State",
                "position": "OC"
            },
            "2003": {
                "college": "Boise State",
                "position": "OC"
            },
            "2004": {
                "college": "Boise State",
                "position": "OC"
            },
            "2005": {
                "college": "Boise State",
                "position": "OC"
            },
            "2006": {
                "college": "Boise State",
                "position": "HC"
            },
            "2007": {
                "college": "Boise State",
                "position": "HC"
            },
            "2008": {
                "college": "Boise State",
                "position": "HC"
            },
            "2009": {
                "college": "Boise State",
                "position": "HC"
            },
            "2010": {
                "college": "Boise State",
                "position": "HC"
            },
            "2011": {
                "college": "Boise State",
                "position": "HC"
            },
            "2012": {
                "college": "Boise State",
                "position": "HC"
            },
            "2013": {
                "college": "Boise State",
                "position": "HC"
            },
            "2014": {
                "college": "Washington",
                "position": "HC"
            },
            "2015": {
                "college": "Washington",
                "position": "HC"
            },
            "2016": {
                "college": "Washington",
                "position": "HC"
            },
            "2017": {
                "college": "Washington",
                "position": "HC"
            },
            "2018": {
                "college": "Washington",
                "position": "HC"
            },
            "2019": {
                "college": "Washington",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "HC",
                "net_ppa": "0.063513577",
                "net_sr": "0.049683372",
                "net_stuff": "-0.041031114",
                "net_pass_sr": "0.120250402",
                "net_fpi": "7.5875",
                "total_sr": "0.397466975",
                "total_stuffs": "-0.328248912",
                "total_pass_sr": "0.962003212",
                "total_ppa": "0.508108612"
            },
            {
                "college": "Washington",
                "role": "HC",
                "net_ppa": "0.136995333",
                "net_sr": "0.05666416",
                "net_stuff": "-0.001700493",
                "net_pass_sr": "0.024452413",
                "net_fpi": "7.216666667",
                "total_sr": "0.339984963",
                "total_stuffs": "-0.010202956",
                "total_pass_sr": "0.14671448",
                "total_ppa": "0.821971999"
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.20050890999999998
    },
    {
        "id": 59,
        "name": "Andy Avalos",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 14,
            "closeness": 4.89706372059313e-7,
            "degree": 5,
            "eigen": 0.00195409630776212
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Boise State",
                "position": "DC"
            },
            "2017": {
                "college": "Boise State",
                "position": "DC"
            },
            "2018": {
                "college": "Boise State",
                "position": "DC"
            },
            "2019": {
                "college": "Oregon",
                "position": "DC"
            },
            "2020": {
                "college": "Oregon",
                "position": "DC"
            },
            "2021": {
                "college": "Boise State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "HC",
                "net_ppa": "-0.015317653",
                "net_sr": "-0.012438059",
                "net_stuff": "0.0313064",
                "net_pass_sr": "-0.004312119",
                "net_fpi": "-1.3",
                "total_sr": "-0.012438059",
                "total_stuffs": "0.0313064",
                "total_pass_sr": "-0.004312119",
                "total_ppa": "-0.015317653"
            },
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "-0.0633131548607161",
                "net_sr": "-0.0408395515132752",
                "net_stuff": "0.0492973205090417",
                "net_pass_sr": "-0.00503368531053078",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "0.0524246308700965",
                "net_sr": "0.0185063148863145",
                "net_stuff": "-0.0067377566212872",
                "net_pass_sr": "0.0324605946236569",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.026206176990619605
    },
    {
        "id": 60,
        "name": "Tom O'Brien",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.95563468052262e-7,
            "degree": 3,
            "eigen": 0.0000352263291135102
        },
        "positions": {
            "2000": {
                "college": "Boston College",
                "position": "HC"
            },
            "2001": {
                "college": "Boston College",
                "position": "HC"
            },
            "2002": {
                "college": "Boston College",
                "position": "HC"
            },
            "2003": {
                "college": "Boston College",
                "position": "HC"
            },
            "2004": {
                "college": "Boston College",
                "position": "HC"
            },
            "2005": {
                "college": "Boston College",
                "position": "HC"
            },
            "2006": {
                "college": "Boston College",
                "position": "HC"
            },
            "2007": {
                "college": "NC State",
                "position": "HC"
            },
            "2008": {
                "college": "NC State",
                "position": "HC"
            },
            "2009": {
                "college": "NC State",
                "position": "HC"
            },
            "2010": {
                "college": "NC State",
                "position": "HC"
            },
            "2011": {
                "college": "NC State",
                "position": "HC"
            },
            "2012": {
                "college": "NC State",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "NC State",
                "role": "HC",
                "net_ppa": "0.014211528",
                "net_sr": "0.003132629",
                "net_stuff": "0.021945555",
                "net_pass_sr": "0.012156149",
                "net_fpi": "-0.866666667",
                "total_sr": "0.018795776",
                "total_stuffs": "0.131673331",
                "total_pass_sr": "0.072936892",
                "total_ppa": "0.085269165"
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.014211528
    },
    {
        "id": 61,
        "name": "Jeff Jagodzinski",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 20,
            "closeness": 4.95588518799903e-7,
            "degree": 3,
            "eigen": 0.00000428406697872213
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Boston College",
                "position": "HC"
            },
            "2008": {
                "college": "Boston College",
                "position": "HC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Georgia State",
                "position": "OC"
            },
            "2014": {
                "college": "Georgia State",
                "position": "OC"
            },
            "2015": {
                "college": "Georgia State",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "HC",
                "net_ppa": "0.002817229",
                "net_sr": "-0.009028559",
                "net_stuff": "-0.022804485",
                "net_pass_sr": "-0.06140383",
                "net_fpi": "-3.45",
                "total_sr": "-0.018057119",
                "total_stuffs": "-0.04560897",
                "total_pass_sr": "-0.12280766",
                "total_ppa": "0.005634457"
            },
            {
                "college": "Georgia State",
                "role": "OC",
                "net_ppa": "0.156355077433369",
                "net_sr": "0.100215741126029",
                "net_stuff": "-0.0258039945831945",
                "net_pass_sr": "0.152563269974839",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.159172306433369
    },
    {
        "id": 62,
        "name": "Frank Spaziani",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 90,
            "closeness": 4.94546878840466e-7,
            "degree": 7,
            "eigen": 0.0000368659895208888
        },
        "positions": {
            "2000": {
                "college": "Boston College",
                "position": "DC"
            },
            "2001": {
                "college": "Boston College",
                "position": "DC"
            },
            "2002": {
                "college": "Boston College",
                "position": "DC"
            },
            "2003": {
                "college": "Boston College",
                "position": "DC"
            },
            "2004": {
                "college": "Boston College",
                "position": "DC"
            },
            "2005": {
                "college": "Boston College",
                "position": "DC"
            },
            "2006": {
                "college": "Boston College",
                "position": "DC"
            },
            "2007": {
                "college": "Boston College",
                "position": "DC"
            },
            "2008": {
                "college": "Boston College",
                "position": "DC"
            },
            "2009": {
                "college": "Boston College",
                "position": "HC"
            },
            "2010": {
                "college": "Boston College",
                "position": "HC"
            },
            "2011": {
                "college": "Boston College",
                "position": "HC"
            },
            "2012": {
                "college": "Boston College",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2017": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2018": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2019": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2020": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2021": {
                "college": "New Mexico State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "HC",
                "net_ppa": "-0.113095496",
                "net_sr": "-0.104491409",
                "net_stuff": "0.117772652",
                "net_pass_sr": "-0.112383765",
                "net_fpi": "-7.733333333",
                "total_sr": "-0.417965634",
                "total_stuffs": "0.471090606",
                "total_pass_sr": "-0.44953506",
                "total_ppa": "-0.452381983"
            },
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "0.0141012592703721",
                "net_sr": "0.0353978056172525",
                "net_stuff": "-0.0290550680039145",
                "net_pass_sr": "0.0362657644328019",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0989942367296279
    },
    {
        "id": 63,
        "name": "Steve Addazio",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 55,
            "closeness": 5.04469346172414e-7,
            "degree": 12,
            "eigen": 0.00123027438492483
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Indiana",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Florida",
                "position": "OC"
            },
            "2010": {
                "college": "Florida",
                "position": "OC"
            },
            "2011": {
                "college": "Temple",
                "position": "HC"
            },
            "2012": {
                "college": "Temple",
                "position": "HC"
            },
            "2013": {
                "college": "Boston College",
                "position": "HC"
            },
            "2014": {
                "college": "Boston College",
                "position": "HC"
            },
            "2015": {
                "college": "Boston College",
                "position": "HC"
            },
            "2016": {
                "college": "Boston College",
                "position": "HC"
            },
            "2017": {
                "college": "Boston College",
                "position": "HC"
            },
            "2018": {
                "college": "Boston College",
                "position": "HC"
            },
            "2019": {
                "college": "Boston College",
                "position": "HC"
            },
            "2020": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2021": {
                "college": "Colorado State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "HC",
                "net_ppa": "0.056932527",
                "net_sr": "0.056339794",
                "net_stuff": "-0.072815485",
                "net_pass_sr": "0.054318568",
                "net_fpi": "3.147619048",
                "total_sr": "0.394378557",
                "total_stuffs": "-0.509708398",
                "total_pass_sr": "0.380229978",
                "total_ppa": "0.39852769"
            },
            {
                "college": "Colorado State",
                "role": "HC",
                "net_ppa": "-0.0426919",
                "net_sr": "-0.005685515",
                "net_stuff": "-0.05746857",
                "net_pass_sr": "-0.047652715",
                "net_fpi": "0.466666667",
                "total_sr": "-0.011371029",
                "total_stuffs": "-0.11493714",
                "total_pass_sr": "-0.09530543",
                "total_ppa": "-0.085383799"
            },
            {
                "college": "Temple",
                "role": "HC",
                "net_ppa": "-0.002863189",
                "net_sr": "0.023534618",
                "net_stuff": "-0.041119326",
                "net_pass_sr": "0.056704873",
                "net_fpi": "1.55",
                "total_sr": "0.047069236",
                "total_stuffs": "-0.082238651",
                "total_pass_sr": "0.113409746",
                "total_ppa": "-0.005726378"
            },
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "-0.0674619593667855",
                "net_sr": "-0.0281016378603136",
                "net_stuff": "0.0155045463702549",
                "net_pass_sr": "-0.0505958859379808",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.05608452136678551
    },
    {
        "id": 64,
        "name": "Jeff Hafley",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 7,
            "closeness": 4.89363201453213e-7,
            "degree": 3,
            "eigen": 0.000166338363306535
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2020": {
                "college": "Boston College",
                "position": "HC"
            },
            "2021": {
                "college": "Boston College",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "HC",
                "net_ppa": "-0.019700682",
                "net_sr": "-0.008754612",
                "net_stuff": "0.027785387",
                "net_pass_sr": "0.036371849",
                "net_fpi": "-3.083333333",
                "total_sr": "-0.017509224",
                "total_stuffs": "0.055570774",
                "total_pass_sr": "0.072743697",
                "total_ppa": "-0.039401364"
            },
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "0.0392867287322201",
                "net_sr": "0.0320884262548473",
                "net_stuff": "-0.0104416695063603",
                "net_pass_sr": "0.0656335936100839",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.019586046732220096
    },
    {
        "id": 66,
        "name": "Urban Meyer",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 6.30574674229359e-7,
            "degree": 19,
            "eigen": 0.00613533988718029
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2002": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2003": {
                "college": "Utah",
                "position": "HC"
            },
            "2004": {
                "college": "Utah",
                "position": "HC"
            },
            "2005": {
                "college": "Florida",
                "position": "HC"
            },
            "2006": {
                "college": "Florida",
                "position": "HC"
            },
            "2007": {
                "college": "Florida",
                "position": "HC"
            },
            "2008": {
                "college": "Florida",
                "position": "HC"
            },
            "2009": {
                "college": "Florida",
                "position": "HC"
            },
            "2010": {
                "college": "Florida",
                "position": "HC"
            },
            "2011": false,
            "2012": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2013": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2014": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2015": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2016": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2017": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2018": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ohio State",
                "role": "HC",
                "net_ppa": "0.121457028",
                "net_sr": "0.075196152",
                "net_stuff": "-0.016481257",
                "net_pass_sr": "0.099821533",
                "net_fpi": "N/A",
                "total_sr": "0.526373062",
                "total_stuffs": "-0.1153688",
                "total_pass_sr": "0.698750733",
                "total_ppa": "0.850199198"
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.121457028
    },
    {
        "id": 67,
        "name": "Gregg Brandon",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 95,
            "closeness": 4.9004858341656e-7,
            "degree": 7,
            "eigen": 0.000225386216547918
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2004": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2005": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2006": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2007": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2008": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2009": {
                "college": "Virginia",
                "position": "OC"
            },
            "2010": false,
            "2011": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2012": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2013": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2014": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "0.0840461339550855",
                "net_sr": "0.0358694391586717",
                "net_stuff": "-0.00986740844277292",
                "net_pass_sr": "0.0582920571353338",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "-0.0196576075401069",
                "net_sr": "0.00580272579191915",
                "net_stuff": "-0.0164655232021268",
                "net_pass_sr": "-0.0230114027883971",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "0.0736401733304613",
                "net_sr": "0.0357240418722699",
                "net_stuff": "-0.0234110262653205",
                "net_pass_sr": "0.0367887283193197",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1380286997454399
    },
    {
        "id": 68,
        "name": "Dave Clawson",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 5,
            "closeness": 4.90387906641912e-7,
            "degree": 6,
            "eigen": 0.00303244859389571
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2009": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2010": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2011": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2012": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2013": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2014": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2015": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2016": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2017": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2018": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2019": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2020": {
                "college": "Wake Forest",
                "position": "HC"
            },
            "2021": {
                "college": "Wake Forest",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "HC",
                "net_ppa": "-0.043345894",
                "net_sr": "0.011343728",
                "net_stuff": "0.026924107",
                "net_pass_sr": "0.017356779",
                "net_fpi": "1.706666667",
                "total_sr": "0.056718642",
                "total_stuffs": "0.134620537",
                "total_pass_sr": "0.086783895",
                "total_ppa": "-0.216729469"
            },
            {
                "college": "Wake Forest",
                "role": "HC",
                "net_ppa": "0.009327258",
                "net_sr": "0.017772025",
                "net_stuff": "-0.026697276",
                "net_pass_sr": "0.031061072",
                "net_fpi": "5.954166667",
                "total_sr": "0.142176197",
                "total_stuffs": "-0.213578205",
                "total_pass_sr": "0.248488574",
                "total_ppa": "0.074618067"
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.0658902449099003",
                "net_sr": "-0.0465034769168199",
                "net_stuff": "0.0162692859693662",
                "net_pass_sr": "-0.101286728737709",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0999088809099003
    },
    {
        "id": 69,
        "name": "Dino Babers",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 18,
            "closeness": 4.91766839571887e-7,
            "degree": 9,
            "eigen": 0.000233438210757532
        },
        "positions": {
            "2000": {
                "college": "Arizona",
                "position": "OC"
            },
            "2001": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2015": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2016": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2017": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2018": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2019": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2020": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2021": {
                "college": "Syracuse",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "HC",
                "net_ppa": "0.041605231",
                "net_sr": "-0.033316287",
                "net_stuff": "-0.054267819",
                "net_pass_sr": "-0.036371356",
                "net_fpi": "0.1",
                "total_sr": "-0.066632575",
                "total_stuffs": "-0.108535638",
                "total_pass_sr": "-0.072742711",
                "total_ppa": "0.083210461"
            },
            {
                "college": "Syracuse",
                "role": "HC",
                "net_ppa": "-0.024220142",
                "net_sr": "0.011820867",
                "net_stuff": "0.008940811",
                "net_pass_sr": "0.019854552",
                "net_fpi": "-0.05",
                "total_sr": "0.070925201",
                "total_stuffs": "0.053644869",
                "total_pass_sr": "0.119127315",
                "total_ppa": "-0.145320854"
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.017385089
    },
    {
        "id": 70,
        "name": "Mike Jinks",
        "community": 5,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.91424881529747e-7,
            "degree": 5,
            "eigen": 0.000456398189987552
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2017": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2018": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "HC",
                "net_ppa": "-0.29395821",
                "net_sr": "-0.098558064",
                "net_stuff": "0.072726",
                "net_pass_sr": "-0.06979164",
                "net_fpi": "-18.76666667",
                "total_sr": "-0.295674192",
                "total_stuffs": "0.218177999",
                "total_pass_sr": "-0.20937492",
                "total_ppa": "-0.881874631"
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.29395821
    },
    {
        "id": 71,
        "name": "Scot Loeffler",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 64,
            "closeness": 4.89705652623378e-7,
            "degree": 6,
            "eigen": 0.000333383102046062
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Temple",
                "position": "OC"
            },
            "2012": {
                "college": "Auburn",
                "position": "OC"
            },
            "2013": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2014": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2015": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2016": {
                "college": "Boston College",
                "position": "OC"
            },
            "2017": {
                "college": "Boston College",
                "position": "OC"
            },
            "2018": {
                "college": "Boston College",
                "position": "OC"
            },
            "2019": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2020": {
                "college": "Bowling Green",
                "position": "HC"
            },
            "2021": {
                "college": "Bowling Green",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "HC",
                "net_ppa": "-0.038705301",
                "net_sr": "-0.010319974",
                "net_stuff": "-0.023231705",
                "net_pass_sr": "-0.086844412",
                "net_fpi": "-8.633333333",
                "total_sr": "-0.030959921",
                "total_stuffs": "-0.069695115",
                "total_pass_sr": "-0.260533236",
                "total_ppa": "-0.116115903"
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.0692000277962876",
                "net_sr": "-0.0487526327615788",
                "net_stuff": "0.0362305126576487",
                "net_pass_sr": "-0.0322102800443281",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "-0.00966312494167403",
                "net_sr": "-0.0109669468753714",
                "net_stuff": "-0.00355388290703193",
                "net_pass_sr": "-0.015459418378497",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "0.0726940466309179",
                "net_sr": "0.0244214508459315",
                "net_stuff": "-0.0731607511218068",
                "net_pass_sr": "0.0481855034686595",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Virginia Tech",
                "role": "OC",
                "net_ppa": "0.047987639075633",
                "net_sr": "-0.00733133501661526",
                "net_stuff": "-0.00514890911722651",
                "net_pass_sr": "0.00659065913504869",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0031132319685892723
    },
    {
        "id": 74,
        "name": "Turner Gill",
        "community": 30,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.95249811433634e-7,
            "degree": 11,
            "eigen": 0.000555410896636435
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2007": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2008": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2009": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2010": {
                "college": "Kansas",
                "position": "HC"
            },
            "2011": {
                "college": "Kansas",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Liberty",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "HC",
                "net_ppa": "-0.017869561",
                "net_sr": "-0.023610316",
                "net_stuff": "-0.025107306",
                "net_pass_sr": "0.008254521",
                "net_fpi": "13.275",
                "total_sr": "-0.094441265",
                "total_stuffs": "-0.100429226",
                "total_pass_sr": "0.033018082",
                "total_ppa": "-0.071478243"
            },
            {
                "college": "Kansas",
                "role": "HC",
                "net_ppa": "-0.222031046",
                "net_sr": "-0.18836276",
                "net_stuff": "0.08972545",
                "net_pass_sr": "-0.17054588",
                "net_fpi": "-22.71666667",
                "total_sr": "-0.376725519",
                "total_stuffs": "0.1794509",
                "total_pass_sr": "-0.341091761",
                "total_ppa": "-0.444062092"
            },
            {
                "college": "Liberty",
                "role": "HC",
                "net_ppa": "0.083399825",
                "net_sr": "0.021642637",
                "net_stuff": "-0.061349758",
                "net_pass_sr": "0.005738031",
                "net_fpi": "N/A",
                "total_sr": "0.021642637",
                "total_stuffs": "-0.061349758",
                "total_pass_sr": "0.005738031",
                "total_ppa": "0.083399825"
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.156500782
    },
    {
        "id": 75,
        "name": "Jeff Quinn",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 4,
            "closeness": 4.90048103121802e-7,
            "degree": 5,
            "eigen": 0.000252318778585571
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2008": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2009": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2010": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2011": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2012": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2013": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2014": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "HC",
                "net_ppa": "0.052270341",
                "net_sr": "0.065145794",
                "net_stuff": "-0.037957301",
                "net_pass_sr": "0.024218037",
                "net_fpi": "-4.713333333",
                "total_sr": "0.325728971",
                "total_stuffs": "-0.189786505",
                "total_pass_sr": "0.121090184",
                "total_ppa": "0.261351703"
            },
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.306820636354211",
                "net_sr": "-0.116349472400282",
                "net_stuff": "0.0821969051067349",
                "net_pass_sr": "-0.10707142904572",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.254550295354211
    },
    {
        "id": 76,
        "name": "Lance Leipold",
        "community": 33,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89703494328254e-7,
            "degree": 3,
            "eigen": 5.59900439715698e-17
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2016": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2017": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2018": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2019": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2020": {
                "college": "Buffalo",
                "position": "HC"
            },
            "2021": {
                "college": "Kansas",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "HC",
                "net_ppa": "0.051623147",
                "net_sr": "-0.006792734",
                "net_stuff": "0.005589744",
                "net_pass_sr": "0.031796896",
                "net_fpi": "1.583333333",
                "total_sr": "-0.040756402",
                "total_stuffs": "0.033538465",
                "total_pass_sr": "0.190781373",
                "total_ppa": "0.309738881"
            },
            {
                "college": "Kansas",
                "role": "HC",
                "net_ppa": "-0.144532426",
                "net_sr": "-0.050050244",
                "net_stuff": "-0.004489119",
                "net_pass_sr": "-0.017275132",
                "net_fpi": "-5.833333333",
                "total_sr": "-0.050050244",
                "total_stuffs": "-0.004489119",
                "total_pass_sr": "-0.017275132",
                "total_ppa": "-0.144532426"
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.09290927899999998
    },
    {
        "id": 77,
        "name": "Maurice Linguist",
        "community": 11,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90048823564292e-7,
            "degree": 2,
            "eigen": 0.0000106076897646979
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Buffalo",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "HC",
                "net_ppa": "-0.279607023",
                "net_sr": "-0.067662683",
                "net_stuff": "-0.033318449",
                "net_pass_sr": "-0.079895789",
                "net_fpi": "-8.866666667",
                "total_sr": "-0.067662683",
                "total_stuffs": "-0.033318449",
                "total_pass_sr": "-0.079895789",
                "total_ppa": "-0.279607023"
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.279607023
    },
    {
        "id": 79,
        "name": "Gary Crowton",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 28,
            "closeness": 4.92109273848477e-7,
            "degree": 8,
            "eigen": 0.00246427108284599
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "BYU",
                "position": "HC"
            },
            "2002": {
                "college": "BYU",
                "position": "HC"
            },
            "2003": {
                "college": "BYU",
                "position": "HC"
            },
            "2004": {
                "college": "BYU",
                "position": "HC"
            },
            "2005": {
                "college": "Oregon",
                "position": "OC"
            },
            "2006": {
                "college": "Oregon",
                "position": "OC"
            },
            "2007": {
                "college": "LSU",
                "position": "OC"
            },
            "2008": {
                "college": "LSU",
                "position": "OC"
            },
            "2009": {
                "college": "LSU",
                "position": "OC"
            },
            "2010": {
                "college": "LSU",
                "position": "OC"
            },
            "2011": {
                "college": "Maryland",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.0920938306326988",
                "net_sr": "-0.0212133659036477",
                "net_stuff": "0.00906051008974906",
                "net_pass_sr": "-0.0644358962217166",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.00269216142470534",
                "net_sr": "0.0273370492576231",
                "net_stuff": "-0.0242691418389024",
                "net_pass_sr": "0.0118307685140865",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.09478599205740414
    },
    {
        "id": 80,
        "name": "Bronco Mendenhall",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 55,
            "closeness": 4.90387185202076e-7,
            "degree": 7,
            "eigen": 0.000781232556588822
        },
        "positions": {
            "2000": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2001": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2002": false,
            "2003": {
                "college": "BYU",
                "position": "DC"
            },
            "2004": {
                "college": "BYU",
                "position": "DC"
            },
            "2005": {
                "college": "BYU",
                "position": "HC"
            },
            "2006": {
                "college": "BYU",
                "position": "HC"
            },
            "2007": {
                "college": "BYU",
                "position": "HC"
            },
            "2008": {
                "college": "BYU",
                "position": "HC"
            },
            "2009": {
                "college": "BYU",
                "position": "HC"
            },
            "2010": {
                "college": "BYU",
                "position": "HC"
            },
            "2011": {
                "college": "BYU",
                "position": "HC"
            },
            "2012": {
                "college": "BYU",
                "position": "HC"
            },
            "2013": {
                "college": "BYU",
                "position": "HC"
            },
            "2014": {
                "college": "BYU",
                "position": "HC"
            },
            "2015": {
                "college": "BYU",
                "position": "HC"
            },
            "2016": {
                "college": "Virginia",
                "position": "HC"
            },
            "2017": {
                "college": "Virginia",
                "position": "HC"
            },
            "2018": {
                "college": "Virginia",
                "position": "HC"
            },
            "2019": {
                "college": "Virginia",
                "position": "HC"
            },
            "2020": {
                "college": "Virginia",
                "position": "HC"
            },
            "2021": {
                "college": "Virginia",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "BYU",
                "role": "HC",
                "net_ppa": "-0.003137775",
                "net_sr": "0.011072826",
                "net_stuff": "-0.022885397",
                "net_pass_sr": "-0.015526238",
                "net_fpi": "-2.345833333",
                "total_sr": "0.088582606",
                "total_stuffs": "-0.183083176",
                "total_pass_sr": "-0.124209907",
                "total_ppa": "-0.025102198"
            },
            {
                "college": "Virginia",
                "role": "HC",
                "net_ppa": "0.004482038",
                "net_sr": "0.029027677",
                "net_stuff": "0.021519806",
                "net_pass_sr": "0.008543358",
                "net_fpi": "N/A",
                "total_sr": "0.174166063",
                "total_stuffs": "0.129118835",
                "total_pass_sr": "0.051260145",
                "total_ppa": "0.026892229"
            }
        ],
        "yearsCoached": 21,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0013442629999999996
    },
    {
        "id": 81,
        "name": "Kalani Sitake",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 11,
            "closeness": 4.90047622827986e-7,
            "degree": 6,
            "eigen": 0.000976674969503104
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Utah",
                "position": "DC"
            },
            "2010": {
                "college": "Utah",
                "position": "DC"
            },
            "2011": {
                "college": "Utah",
                "position": "DC"
            },
            "2012": {
                "college": "Utah",
                "position": "DC"
            },
            "2013": {
                "college": "Utah",
                "position": "DC"
            },
            "2014": {
                "college": "Utah",
                "position": "DC"
            },
            "2015": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2016": {
                "college": "BYU",
                "position": "HC"
            },
            "2017": {
                "college": "BYU",
                "position": "HC"
            },
            "2018": {
                "college": "BYU",
                "position": "HC"
            },
            "2019": {
                "college": "BYU",
                "position": "HC"
            },
            "2020": {
                "college": "BYU",
                "position": "HC"
            },
            "2021": {
                "college": "BYU",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "BYU",
                "role": "HC",
                "net_ppa": "-0.016369659",
                "net_sr": "-0.015182724",
                "net_stuff": "0.044525295",
                "net_pass_sr": "-0.031276196",
                "net_fpi": "-4.733333333",
                "total_sr": "-0.091096344",
                "total_stuffs": "0.267151771",
                "total_pass_sr": "-0.187657178",
                "total_ppa": "-0.098217953"
            },
            {
                "college": "Oregon State",
                "role": "OC",
                "net_ppa": "-0.134536666651488",
                "net_sr": "-0.0982932720678938",
                "net_stuff": "0.0712490599854478",
                "net_pass_sr": "-0.0811455202897187",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "-0.00102579503020462",
                "net_sr": "-0.00960765167615729",
                "net_stuff": "-0.0234354994739383",
                "net_pass_sr": "-0.0129882175396135",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.15193212068169262
    },
    {
        "id": 83,
        "name": "Jeff Tedford",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 8,
            "closeness": 4.91768774256495e-7,
            "degree": 10,
            "eigen": 0.00341279150751233
        },
        "positions": {
            "2000": {
                "college": "Oregon",
                "position": "OC"
            },
            "2001": {
                "college": "Oregon",
                "position": "OC"
            },
            "2002": {
                "college": "California",
                "position": "HC"
            },
            "2003": {
                "college": "California",
                "position": "HC"
            },
            "2004": {
                "college": "California",
                "position": "HC"
            },
            "2005": {
                "college": "California",
                "position": "HC"
            },
            "2006": {
                "college": "California",
                "position": "HC"
            },
            "2007": {
                "college": "California",
                "position": "HC"
            },
            "2008": {
                "college": "California",
                "position": "HC"
            },
            "2009": {
                "college": "California",
                "position": "HC"
            },
            "2010": {
                "college": "California",
                "position": "HC"
            },
            "2011": {
                "college": "California",
                "position": "HC"
            },
            "2012": {
                "college": "California",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Fresno State",
                "position": "HC"
            },
            "2018": {
                "college": "Fresno State",
                "position": "HC"
            },
            "2019": {
                "college": "Fresno State",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "HC",
                "net_ppa": "0.312296784",
                "net_sr": "0.133176707",
                "net_stuff": "-0.090312376",
                "net_pass_sr": "0.140274846",
                "net_fpi": "15.7",
                "total_sr": "0.39953012",
                "total_stuffs": "-0.270937127",
                "total_pass_sr": "0.420824539",
                "total_ppa": "0.936890353"
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.312296784
    },
    {
        "id": 84,
        "name": "Sonny Dykes",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 106.5,
            "closeness": 4.95597852078909e-7,
            "degree": 12,
            "eigen": 0.00279875498428231
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2006": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2007": {
                "college": "Arizona",
                "position": "OC"
            },
            "2008": {
                "college": "Arizona",
                "position": "OC"
            },
            "2009": {
                "college": "Arizona",
                "position": "OC"
            },
            "2010": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2011": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2012": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2013": {
                "college": "California",
                "position": "HC"
            },
            "2014": {
                "college": "California",
                "position": "HC"
            },
            "2015": {
                "college": "California",
                "position": "HC"
            },
            "2016": {
                "college": "California",
                "position": "HC"
            },
            "2017": false,
            "2018": {
                "college": "SMU",
                "position": "HC"
            },
            "2019": {
                "college": "SMU",
                "position": "HC"
            },
            "2020": {
                "college": "SMU",
                "position": "HC"
            },
            "2021": {
                "college": "SMU",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "California",
                "role": "HC",
                "net_ppa": "-0.038636424",
                "net_sr": "-0.021123592",
                "net_stuff": "0.008542278",
                "net_pass_sr": "-0.03056296",
                "net_fpi": "N/A",
                "total_sr": "-0.084494369",
                "total_stuffs": "0.034169113",
                "total_pass_sr": "-0.122251838",
                "total_ppa": "-0.154545695"
            },
            {
                "college": "Louisiana Tech",
                "role": "HC",
                "net_ppa": "0.005406757",
                "net_sr": "0.019313893",
                "net_stuff": "-0.009848204",
                "net_pass_sr": "0.066561513",
                "net_fpi": "N/A",
                "total_sr": "0.057941679",
                "total_stuffs": "-0.029544612",
                "total_pass_sr": "0.199684538",
                "total_ppa": "0.016220271"
            },
            {
                "college": "SMU",
                "role": "HC",
                "net_ppa": "0.114801827",
                "net_sr": "0.068536214",
                "net_stuff": "-0.005041093",
                "net_pass_sr": "0.0353592",
                "net_fpi": "8.141666667",
                "total_sr": "0.274144856",
                "total_stuffs": "-0.020164371",
                "total_pass_sr": "0.141436802",
                "total_ppa": "0.45920731"
            },
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "0.0175314805814969",
                "net_sr": "0.0480547122486391",
                "net_stuff": "-0.0649309174235761",
                "net_pass_sr": "0.0444134752389425",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0991036405814969
    },
    {
        "id": 85,
        "name": "Justin Wilcox",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 131,
            "closeness": 4.91421259080236e-7,
            "degree": 8,
            "eigen": 0.00967138639302375
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Boise State",
                "position": "DC"
            },
            "2007": {
                "college": "Boise State",
                "position": "DC"
            },
            "2008": {
                "college": "Boise State",
                "position": "DC"
            },
            "2009": {
                "college": "Boise State",
                "position": "DC"
            },
            "2010": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2011": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2012": {
                "college": "Washington",
                "position": "DC"
            },
            "2013": {
                "college": "Washington",
                "position": "DC"
            },
            "2014": {
                "college": "USC",
                "position": "DC"
            },
            "2015": {
                "college": "USC",
                "position": "DC"
            },
            "2016": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2017": {
                "college": "California",
                "position": "HC"
            },
            "2018": {
                "college": "California",
                "position": "HC"
            },
            "2019": {
                "college": "California",
                "position": "HC"
            },
            "2020": {
                "college": "California",
                "position": "HC"
            },
            "2021": {
                "college": "California",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "California",
                "role": "HC",
                "net_ppa": "-0.007429695",
                "net_sr": "-0.035392835",
                "net_stuff": "0.005255049",
                "net_pass_sr": "-0.060843722",
                "net_fpi": "N/A",
                "total_sr": "-0.176964174",
                "total_stuffs": "0.026275244",
                "total_pass_sr": "-0.30421861",
                "total_ppa": "-0.037148474"
            },
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "0.0409970740737589",
                "net_sr": "0.00767992475322166",
                "net_stuff": "-0.0374755027165579",
                "net_pass_sr": "0.024639654324901",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "0.00346489288136713",
                "net_sr": "-0.00628263983024302",
                "net_stuff": "-0.0155587988414506",
                "net_pass_sr": "-0.0097475608792944",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "-0.0828061189993738",
                "net_sr": "-0.018587296845456",
                "net_stuff": "0.00175667404682733",
                "net_pass_sr": "-0.0314044571023955",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "0.0959312811713359",
                "net_sr": "0.0489903993420996",
                "net_stuff": "0.066929253402413",
                "net_pass_sr": "0.083119067628725",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "-0.0083803834106602",
                "net_sr": "-0.0276179205035403",
                "net_stuff": "-0.00918923252404963",
                "net_pass_sr": "-0.0332989152489848",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.04177705071642793
    },
    {
        "id": 86,
        "name": "Mike DeBord",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 28,
            "closeness": 4.91763212079278e-7,
            "degree": 6,
            "eigen": 0.0000895448122466224
        },
        "positions": {
            "2000": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2001": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2002": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2003": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Michigan",
                "position": "OC"
            },
            "2007": {
                "college": "Michigan",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2016": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2017": {
                "college": "Indiana",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "-0.0567435999843804",
                "net_sr": "-0.0412631473431846",
                "net_stuff": "-0.0137271321162675",
                "net_pass_sr": "-0.0182398696309815",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "-0.011535100606865",
                "net_sr": "-0.00545305674417718",
                "net_stuff": "0.067437294449217",
                "net_pass_sr": "0.000179581241147697",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "0.132034086044309",
                "net_sr": "0.0146737299204673",
                "net_stuff": "-0.0321147829375757",
                "net_pass_sr": "0.0312132196170828",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0637553854530636
    },
    {
        "id": 87,
        "name": "Brian Kelly",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.44209986688624e-7,
            "degree": 16,
            "eigen": 0.000366508371913217
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2005": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2006": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2007": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2008": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2009": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2010": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2011": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2012": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2013": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2014": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2015": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2016": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2017": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2018": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2019": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2020": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2021": {
                "college": "Notre Dame",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "HC",
                "net_ppa": "-0.035578559",
                "net_sr": "-0.069109052",
                "net_stuff": "0.097560807",
                "net_pass_sr": "-0.051214967",
                "net_fpi": "N/A",
                "total_sr": "-0.069109052",
                "total_stuffs": "0.097560807",
                "total_pass_sr": "-0.051214967",
                "total_ppa": "-0.035578559"
            },
            {
                "college": "Cincinnati",
                "role": "HC",
                "net_ppa": "0.048580033",
                "net_sr": "0.016868472",
                "net_stuff": "0.013035543",
                "net_pass_sr": "0.010333338",
                "net_fpi": "13.26666667",
                "total_sr": "0.050605417",
                "total_stuffs": "0.039106628",
                "total_pass_sr": "0.031000013",
                "total_ppa": "0.145740099"
            },
            {
                "college": "Notre Dame",
                "role": "HC",
                "net_ppa": "0.133601122",
                "net_sr": "0.060848181",
                "net_stuff": "-0.04819811",
                "net_pass_sr": "0.050126339",
                "net_fpi": "13.16666667",
                "total_sr": "0.73017817",
                "total_stuffs": "-0.578377322",
                "total_pass_sr": "0.601516064",
                "total_ppa": "1.60321347"
            }
        ],
        "yearsCoached": 18,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.14660259599999997
    },
    {
        "id": 88,
        "name": "Dan Enos",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 111,
            "closeness": 4.89704693378752e-7,
            "degree": 6,
            "eigen": 0.000297054226860054
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2011": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2012": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2013": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2014": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2015": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2016": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2017": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2018": false,
            "2019": {
                "college": "Miami",
                "position": "OC"
            },
            "2020": false,
            "2021": {
                "college": "Maryland",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "HC",
                "net_ppa": "-0.04057428",
                "net_sr": "-0.027352888",
                "net_stuff": "0.018835073",
                "net_pass_sr": "-0.012886405",
                "net_fpi": "N/A",
                "total_sr": "-0.136764441",
                "total_stuffs": "0.094175363",
                "total_pass_sr": "-0.064432025",
                "total_ppa": "-0.202871402"
            },
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "0.115041568042282",
                "net_sr": "0.0175510451411997",
                "net_stuff": "0.00897019867337301",
                "net_pass_sr": "0.0395428761619148",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "0.0907683202567024",
                "net_sr": "0.0950448789087009",
                "net_stuff": "-0.0381921609116918",
                "net_pass_sr": "0.147009697329148",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "-0.0460356599038255",
                "net_sr": "-0.00311796060726077",
                "net_stuff": "0.00889265525277308",
                "net_pass_sr": "-0.00560547287096369",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1191999483951589
    },
    {
        "id": 89,
        "name": "John Bonamego",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89705173000595e-7,
            "degree": 3,
            "eigen": 0.0000152924046081415
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2016": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2017": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2018": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "HC",
                "net_ppa": "-0.002990551",
                "net_sr": "-0.028778905",
                "net_stuff": "-0.000231996",
                "net_pass_sr": "-0.027037902",
                "net_fpi": "N/A",
                "total_sr": "-0.115115619",
                "total_stuffs": "-0.000927986",
                "total_pass_sr": "-0.108151607",
                "total_ppa": "-0.011962206"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.002990551
    },
    {
        "id": 90,
        "name": "Jim McElwain",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 41,
            "closeness": 4.96998131287026e-7,
            "degree": 12,
            "eigen": 0.00109821971318672
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2008": {
                "college": "Alabama",
                "position": "OC"
            },
            "2009": {
                "college": "Alabama",
                "position": "OC"
            },
            "2010": {
                "college": "Alabama",
                "position": "OC"
            },
            "2011": {
                "college": "Alabama",
                "position": "OC"
            },
            "2012": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2013": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2014": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2015": {
                "college": "Florida",
                "position": "HC"
            },
            "2016": {
                "college": "Florida",
                "position": "HC"
            },
            "2017": {
                "college": "Florida",
                "position": "HC"
            },
            "2018": false,
            "2019": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2020": {
                "college": "Central Michigan",
                "position": "HC"
            },
            "2021": {
                "college": "Central Michigan",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "HC",
                "net_ppa": "0.079025676",
                "net_sr": "0.059934481",
                "net_stuff": "-0.087578096",
                "net_pass_sr": "0.046094526",
                "net_fpi": "N/A",
                "total_sr": "0.179803443",
                "total_stuffs": "-0.262734288",
                "total_pass_sr": "0.138283579",
                "total_ppa": "0.237077027"
            },
            {
                "college": "Colorado State",
                "role": "HC",
                "net_ppa": "0.164975118",
                "net_sr": "0.074854441",
                "net_stuff": "0.011058422",
                "net_pass_sr": "0.110343268",
                "net_fpi": "9.333333333",
                "total_sr": "0.224563322",
                "total_stuffs": "0.033175266",
                "total_pass_sr": "0.331029803",
                "total_ppa": "0.494925354"
            },
            {
                "college": "Florida",
                "role": "HC",
                "net_ppa": "0.039580877",
                "net_sr": "0.007079539",
                "net_stuff": "-0.021500318",
                "net_pass_sr": "-0.002777408",
                "net_fpi": "-4.633333333",
                "total_sr": "0.021238618",
                "total_stuffs": "-0.064500955",
                "total_pass_sr": "-0.008332224",
                "total_ppa": "0.11874263"
            },
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "-0.0596117686525488",
                "net_sr": "-0.0245268739094263",
                "net_stuff": "0.0241387815007819",
                "net_pass_sr": "-0.0705658832619657",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "0.00734262404220489",
                "net_sr": "-0.0312016508880496",
                "net_stuff": "-0.0638532764700614",
                "net_pass_sr": "-0.00161771876194083",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.23131252638965613
    },
    {
        "id": 91,
        "name": "Brad Lambert",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 61.5,
            "closeness": 4.91080263631529e-7,
            "degree": 8,
            "eigen": 0.00023739087436311
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2009": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2010": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Charlotte",
                "position": "HC"
            },
            "2016": {
                "college": "Charlotte",
                "position": "HC"
            },
            "2017": {
                "college": "Charlotte",
                "position": "HC"
            },
            "2018": {
                "college": "Charlotte",
                "position": "HC"
            },
            "2019": {
                "college": "Marshall",
                "position": "DC"
            },
            "2020": {
                "college": "Marshall",
                "position": "DC"
            },
            "2021": {
                "college": "Purdue",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "0.0464703895053216",
                "net_sr": "0.000272242524389676",
                "net_stuff": "0.059282281076408",
                "net_pass_sr": "-0.00883139596484017",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "0.0441780658224482",
                "net_sr": "0.0472863084803918",
                "net_stuff": "0.0217338084325735",
                "net_pass_sr": "0.0628210720577133",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wake Forest",
                "role": "OC",
                "net_ppa": "-0.0730199140320692",
                "net_sr": "-0.0312609481806417",
                "net_stuff": "0.069498010546464",
                "net_pass_sr": "-0.00451379002008595",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0176285412957006
    },
    {
        "id": 92,
        "name": "Will Healy",
        "community": 36,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90048823564292e-7,
            "degree": 4,
            "eigen": 0
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Charlotte",
                "position": "HC"
            },
            "2020": {
                "college": "Charlotte",
                "position": "HC"
            },
            "2021": {
                "college": "Charlotte",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Charlotte",
                "role": "HC",
                "net_ppa": "0.031048941",
                "net_sr": "0.041326329",
                "net_stuff": "0.026659929",
                "net_pass_sr": "0.123913706",
                "net_fpi": "5.566666667",
                "total_sr": "0.123978988",
                "total_stuffs": "0.079979786",
                "total_pass_sr": "0.371741117",
                "total_ppa": "0.093146822"
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.031048941
    },
    {
        "id": 93,
        "name": "Rick Minter",
        "community": 12,
        "isWhite": true,
        "centrality": {
            "betweenness": 453,
            "closeness": 4.92117507818517e-7,
            "degree": 10,
            "eigen": 0.000148737485746184
        },
        "positions": {
            "2000": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2001": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2002": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2003": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2004": false,
            "2005": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2006": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2007": false,
            "2008": {
                "college": "Marshall",
                "position": "DC"
            },
            "2009": {
                "college": "Marshall",
                "position": "DC"
            },
            "2010": false,
            "2011": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2012": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "0.012830710740717",
                "net_sr": "0.0286373761595748",
                "net_stuff": "-0.0328659487929902",
                "net_pass_sr": "0.0466241720759841",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "0.0276729132773929",
                "net_sr": "-0.0474044318007993",
                "net_stuff": "-0.0213730458190734",
                "net_pass_sr": "-0.0312110266442983",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.040503624018109904
    },
    {
        "id": 94,
        "name": "Mark Dantonio",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 20,
            "closeness": 4.96261168357594e-7,
            "degree": 9,
            "eigen": 0.00265383892650683
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2002": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2003": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2004": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2005": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2006": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2007": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2008": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2009": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2010": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2011": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2012": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2013": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2014": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2015": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2016": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2017": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2018": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2019": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Michigan State",
                "role": "HC",
                "net_ppa": "0.021431538",
                "net_sr": "0.00822672",
                "net_stuff": "-0.034973527",
                "net_pass_sr": "0.006281859",
                "net_fpi": "N/A",
                "total_sr": "0.106947362",
                "total_stuffs": "-0.45465585",
                "total_pass_sr": "0.08166417",
                "total_ppa": "0.278609998"
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.021431538
    },
    {
        "id": 95,
        "name": "Luke Fickell",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 8.5,
            "closeness": 4.90391513872931e-7,
            "degree": 7,
            "eigen": 0.00207455127667198
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2006": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2007": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2008": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2009": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2010": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2011": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2012": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2013": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2014": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2015": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2016": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2017": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2018": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2019": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2020": {
                "college": "Cincinnati",
                "position": "HC"
            },
            "2021": {
                "college": "Cincinnati",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "HC",
                "net_ppa": "0.13600116",
                "net_sr": "0.059248042",
                "net_stuff": "-0.018396616",
                "net_pass_sr": "0.048019488",
                "net_fpi": "5.706666667",
                "total_sr": "0.296240211",
                "total_stuffs": "-0.091983081",
                "total_pass_sr": "0.240097438",
                "total_ppa": "0.680005799"
            },
            {
                "college": "Ohio State",
                "role": "HC",
                "net_ppa": "-0.078435758",
                "net_sr": "-0.053731312",
                "net_stuff": "-0.032023586",
                "net_pass_sr": "-0.139923306",
                "net_fpi": "N/A",
                "total_sr": "-0.053731312",
                "total_stuffs": "-0.032023586",
                "total_pass_sr": "-0.139923306",
                "total_ppa": "-0.078435758"
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.057565402000000016
    },
    {
        "id": 97,
        "name": "Dabo Swinney",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.97344429419117e-7,
            "degree": 8,
            "eigen": 0.00958090282317765
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Clemson",
                "position": "HC"
            },
            "2010": {
                "college": "Clemson",
                "position": "HC"
            },
            "2011": {
                "college": "Clemson",
                "position": "HC"
            },
            "2012": {
                "college": "Clemson",
                "position": "HC"
            },
            "2013": {
                "college": "Clemson",
                "position": "HC"
            },
            "2014": {
                "college": "Clemson",
                "position": "HC"
            },
            "2015": {
                "college": "Clemson",
                "position": "HC"
            },
            "2016": {
                "college": "Clemson",
                "position": "HC"
            },
            "2017": {
                "college": "Clemson",
                "position": "HC"
            },
            "2018": {
                "college": "Clemson",
                "position": "HC"
            },
            "2019": {
                "college": "Clemson",
                "position": "HC"
            },
            "2020": {
                "college": "Clemson",
                "position": "HC"
            },
            "2021": {
                "college": "Clemson",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Clemson",
                "role": "HC",
                "net_ppa": "0.108748589",
                "net_sr": "0.073560986",
                "net_stuff": "-0.054591513",
                "net_pass_sr": "0.096731426",
                "net_fpi": "6.907692308",
                "total_sr": "0.956292812",
                "total_stuffs": "-0.709689672",
                "total_pass_sr": "1.257508543",
                "total_ppa": "1.413731651"
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.108748589
    },
    {
        "id": 99,
        "name": "Jamey Chadwell",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 3,
            "closeness": 4.89704933189556e-7,
            "degree": 4,
            "eigen": 9.73396477224754e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Coastal Carolina",
                "position": "OC"
            },
            "2019": {
                "college": "Coastal Carolina",
                "position": "HC"
            },
            "2020": {
                "college": "Coastal Carolina",
                "position": "HC"
            },
            "2021": {
                "college": "Coastal Carolina",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Coastal Carolina",
                "role": "HC",
                "net_ppa": "0.269923491",
                "net_sr": "0.114170109",
                "net_stuff": "-0.035075882",
                "net_pass_sr": "0.160609644",
                "net_fpi": "N/A",
                "total_sr": "0.342510328",
                "total_stuffs": "-0.105227645",
                "total_pass_sr": "0.481828933",
                "total_ppa": "0.809770474"
            },
            {
                "college": "Coastal Carolina",
                "role": "OC",
                "net_ppa": "0.0651971501286037",
                "net_sr": "0.0447103909637946",
                "net_stuff": "-0.0453601758366709",
                "net_pass_sr": "0.0240674621339274",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.33512064112860374
    },
    {
        "id": 101,
        "name": "Jon Embree",
        "community": 14,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89363201453213e-7,
            "degree": 2,
            "eigen": 0.0000625679567190105
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Colorado",
                "position": "HC"
            },
            "2012": {
                "college": "Colorado",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "HC",
                "net_ppa": "-0.115982973",
                "net_sr": "-0.052306648",
                "net_stuff": "-0.014724576",
                "net_pass_sr": "-0.019992374",
                "net_fpi": "-11.36666667",
                "total_sr": "-0.104613295",
                "total_stuffs": "-0.029449152",
                "total_pass_sr": "-0.039984748",
                "total_ppa": "-0.231965945"
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.115982973
    },
    {
        "id": 102,
        "name": "Mike MacIntyre",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 88.5,
            "closeness": 4.9384764602581e-7,
            "degree": 11,
            "eigen": 0.00150907688120482
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Duke",
                "position": "DC"
            },
            "2009": {
                "college": "Duke",
                "position": "DC"
            },
            "2010": {
                "college": "San José State",
                "position": "HC"
            },
            "2011": {
                "college": "San José State",
                "position": "HC"
            },
            "2012": {
                "college": "San José State",
                "position": "HC"
            },
            "2013": {
                "college": "Colorado",
                "position": "HC"
            },
            "2014": {
                "college": "Colorado",
                "position": "HC"
            },
            "2015": {
                "college": "Colorado",
                "position": "HC"
            },
            "2016": {
                "college": "Colorado",
                "position": "HC"
            },
            "2017": {
                "college": "Colorado",
                "position": "HC"
            },
            "2018": {
                "college": "Colorado",
                "position": "HC"
            },
            "2019": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2020": {
                "college": "Memphis",
                "position": "DC"
            },
            "2021": {
                "college": "Memphis",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "HC",
                "net_ppa": "0.077713407",
                "net_sr": "0.068674772",
                "net_stuff": "0.030385567",
                "net_pass_sr": "0.111168876",
                "net_fpi": "8.983333333",
                "total_sr": "0.41204863",
                "total_stuffs": "0.182313401",
                "total_pass_sr": "0.667013255",
                "total_ppa": "0.466280439"
            },
            {
                "college": "San José State",
                "role": "HC",
                "net_ppa": "0.09763055",
                "net_sr": "0.041928288",
                "net_stuff": "-0.094066062",
                "net_pass_sr": "0.031639949",
                "net_fpi": "N/A",
                "total_sr": "0.125784864",
                "total_stuffs": "-0.282198186",
                "total_pass_sr": "0.094919846",
                "total_ppa": "0.29289165"
            },
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "0.0535909999397525",
                "net_sr": "0.0225908525252388",
                "net_stuff": "-0.00297426855142796",
                "net_pass_sr": "-0.0108305667243304",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.0389903045630553",
                "net_sr": "-0.018169346450509",
                "net_stuff": "0.0498478117799896",
                "net_pass_sr": "-0.0403414426545933",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "0.041083898698965",
                "net_sr": "0.0135008859070541",
                "net_stuff": "0.0165549625127605",
                "net_pass_sr": "0.000401068551662609",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2310285510756622
    },
    {
        "id": 103,
        "name": "Mel Tucker",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 21,
            "closeness": 4.91080022471822e-7,
            "degree": 5,
            "eigen": 0.00149017343480739
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Georgia",
                "position": "DC"
            },
            "2017": {
                "college": "Georgia",
                "position": "DC"
            },
            "2018": {
                "college": "Georgia",
                "position": "DC"
            },
            "2019": {
                "college": "Colorado",
                "position": "HC"
            },
            "2020": {
                "college": "Michigan State",
                "position": "HC"
            },
            "2021": {
                "college": "Michigan State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "HC",
                "net_ppa": "-0.101661375",
                "net_sr": "-0.057263551",
                "net_stuff": "-0.025305814",
                "net_pass_sr": "-0.106797601",
                "net_fpi": "-6.233333333",
                "total_sr": "-0.057263551",
                "total_stuffs": "-0.025305814",
                "total_pass_sr": "-0.106797601",
                "total_ppa": "-0.101661375"
            },
            {
                "college": "Michigan State",
                "role": "HC",
                "net_ppa": "-0.03277108",
                "net_sr": "-0.069517102",
                "net_stuff": "0.035140405",
                "net_pass_sr": "-0.051637016",
                "net_fpi": "N/A",
                "total_sr": "-0.139034203",
                "total_stuffs": "0.070280809",
                "total_pass_sr": "-0.103274033",
                "total_ppa": "-0.06554216"
            },
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "0.0808480762637841",
                "net_sr": "0.0233092155431503",
                "net_stuff": "0.0241811949040626",
                "net_pass_sr": "0.0461024119064247",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.05358437873621591
    },
    {
        "id": 104,
        "name": "Karl Dorrell",
        "community": 32,
        "isWhite": false,
        "centrality": {
            "betweenness": 245,
            "closeness": 5.0198031233215e-7,
            "degree": 10,
            "eigen": 0.00114557668136589
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "UCLA",
                "position": "HC"
            },
            "2004": {
                "college": "UCLA",
                "position": "HC"
            },
            "2005": {
                "college": "UCLA",
                "position": "HC"
            },
            "2006": {
                "college": "UCLA",
                "position": "HC"
            },
            "2007": {
                "college": "UCLA",
                "position": "HC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Colorado",
                "position": "HC"
            },
            "2021": {
                "college": "Colorado",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "HC",
                "net_ppa": "-0.008275899",
                "net_sr": "0.002603456",
                "net_stuff": "-0.041814833",
                "net_pass_sr": "-0.05966935",
                "net_fpi": "-1.166666667",
                "total_sr": "0.005206913",
                "total_stuffs": "-0.083629666",
                "total_pass_sr": "-0.1193387",
                "total_ppa": "-0.016551797"
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.163537001463797",
                "net_sr": "-0.00461192977633479",
                "net_stuff": "0.028163832491565",
                "net_pass_sr": "-0.0311363309906473",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.171812900463797
    },
    {
        "id": 106,
        "name": "Steve Fairchild",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 11,
            "closeness": 4.89705412811869e-7,
            "degree": 5,
            "eigen": 0.000491306017936843
        },
        "positions": {
            "2000": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2009": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2010": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2011": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2012": false,
            "2013": {
                "college": "Virginia",
                "position": "OC"
            },
            "2014": {
                "college": "Virginia",
                "position": "OC"
            },
            "2015": {
                "college": "Virginia",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "HC",
                "net_ppa": "-0.019593376",
                "net_sr": "0.001814407",
                "net_stuff": "-0.01142598",
                "net_pass_sr": "-0.031109063",
                "net_fpi": "-6.616666667",
                "total_sr": "0.007257628",
                "total_stuffs": "-0.04570392",
                "total_pass_sr": "-0.124436253",
                "total_ppa": "-0.078373504"
            },
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "0.00744742978206646",
                "net_sr": "-0.0112064174815066",
                "net_stuff": "0.0342838739869258",
                "net_pass_sr": "-0.00158766928508908",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.012145946217933539
    },
    {
        "id": 107,
        "name": "Mike Bobo",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 52,
            "closeness": 4.9177022527994e-7,
            "degree": 7,
            "eigen": 0.00102138719509888
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Georgia",
                "position": "OC"
            },
            "2008": {
                "college": "Georgia",
                "position": "OC"
            },
            "2009": {
                "college": "Georgia",
                "position": "OC"
            },
            "2010": {
                "college": "Georgia",
                "position": "OC"
            },
            "2011": {
                "college": "Georgia",
                "position": "OC"
            },
            "2012": {
                "college": "Georgia",
                "position": "OC"
            },
            "2013": {
                "college": "Georgia",
                "position": "OC"
            },
            "2014": {
                "college": "Georgia",
                "position": "OC"
            },
            "2015": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2016": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2017": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2018": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2019": {
                "college": "Colorado State",
                "position": "HC"
            },
            "2020": false,
            "2021": {
                "college": "Auburn",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "HC",
                "net_ppa": "-0.077129342",
                "net_sr": "-0.010148797",
                "net_stuff": "-0.030637181",
                "net_pass_sr": "-0.009538802",
                "net_fpi": "-3.066666667",
                "total_sr": "-0.050743984",
                "total_stuffs": "-0.153185903",
                "total_pass_sr": "-0.047694008",
                "total_ppa": "-0.385646709"
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "0.021443896605632",
                "net_sr": "-0.00638046907007783",
                "net_stuff": "0.0114976730877755",
                "net_pass_sr": "0.00380763812946655",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "0.0590631952118073",
                "net_sr": "0.0192590533035734",
                "net_stuff": "-0.0238733381660768",
                "net_pass_sr": "0.044894889349241",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.003377749817439296
    },
    {
        "id": 109,
        "name": "Ted Roof",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 280,
            "closeness": 4.90735406265121e-7,
            "degree": 14,
            "eigen": 0.00129605446433539
        },
        "positions": {
            "2000": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2001": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2002": {
                "college": "Duke",
                "position": "DC"
            },
            "2003": false,
            "2004": {
                "college": "Duke",
                "position": "HC"
            },
            "2005": {
                "college": "Duke",
                "position": "HC"
            },
            "2006": {
                "college": "Duke",
                "position": "HC"
            },
            "2007": {
                "college": "Duke",
                "position": "HC"
            },
            "2008": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2009": {
                "college": "Auburn",
                "position": "DC"
            },
            "2010": {
                "college": "Auburn",
                "position": "DC"
            },
            "2011": {
                "college": "Auburn",
                "position": "DC"
            },
            "2012": {
                "college": "Penn State",
                "position": "DC"
            },
            "2013": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2014": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2015": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2016": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2017": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2018": {
                "college": "NC State",
                "position": "DC"
            },
            "2019": {
                "college": "Appalachian State",
                "position": "DC"
            },
            "2020": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "OC",
                "net_ppa": "0.00259088462994382",
                "net_sr": "0.0497436117889197",
                "net_stuff": "0.0115171828792035",
                "net_pass_sr": "0.0608996763823041",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "0.163141212933755",
                "net_sr": "0.0712527281863785",
                "net_stuff": "-0.0327765555794791",
                "net_pass_sr": "0.0887095347096408",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia Tech",
                "role": "OC",
                "net_ppa": "0.0265496334044851",
                "net_sr": "0.0132946449621425",
                "net_stuff": "0.0274381741724521",
                "net_pass_sr": "0.0249214909513273",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.0723370423035629",
                "net_sr": "-0.0197106629332459",
                "net_stuff": "-0.00155408841480936",
                "net_pass_sr": "-0.0141964994327906",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "-0.113835846277836",
                "net_sr": "-0.0134549686480517",
                "net_stuff": "-0.0551678949934323",
                "net_pass_sr": "-0.0452978201935815",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "0.359962704075102",
                "net_sr": "0.132022392803911",
                "net_stuff": "-0.0438305487996442",
                "net_pass_sr": "0.159848834972871",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.0314300483501594",
                "net_sr": "-0.0108767738158467",
                "net_stuff": "-0.000252842155792743",
                "net_pass_sr": "0.00351024872851619",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 20,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.47931558271885344
    },
    {
        "id": 110,
        "name": "David Cutcliffe",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 15.5,
            "closeness": 5.57167627223656e-7,
            "degree": 16,
            "eigen": 0.00195724253996972
        },
        "positions": {
            "2000": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2001": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2002": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2003": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2004": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2005": false,
            "2006": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2007": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2008": {
                "college": "Duke",
                "position": "HC"
            },
            "2009": {
                "college": "Duke",
                "position": "HC"
            },
            "2010": {
                "college": "Duke",
                "position": "HC"
            },
            "2011": {
                "college": "Duke",
                "position": "HC"
            },
            "2012": {
                "college": "Duke",
                "position": "HC"
            },
            "2013": {
                "college": "Duke",
                "position": "HC"
            },
            "2014": {
                "college": "Duke",
                "position": "HC"
            },
            "2015": {
                "college": "Duke",
                "position": "HC"
            },
            "2016": {
                "college": "Duke",
                "position": "HC"
            },
            "2017": {
                "college": "Duke",
                "position": "HC"
            },
            "2018": {
                "college": "Duke",
                "position": "HC"
            },
            "2019": {
                "college": "Duke",
                "position": "HC"
            },
            "2020": {
                "college": "Duke",
                "position": "HC"
            },
            "2021": {
                "college": "Duke",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Duke",
                "role": "HC",
                "net_ppa": "0.055035582",
                "net_sr": "0.077116261",
                "net_stuff": "-0.017794515",
                "net_pass_sr": "0.098086678",
                "net_fpi": "12.1547619",
                "total_sr": "1.079627651",
                "total_stuffs": "-0.249123212",
                "total_pass_sr": "1.373213493",
                "total_ppa": "0.770498155"
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.00821271477822913",
                "net_sr": "0.00869643288787597",
                "net_stuff": "0.0194044430139679",
                "net_pass_sr": "0.0476661653132242",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 21,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.04682286722177087
    },
    {
        "id": 111,
        "name": "Steve Logan",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 2,
            "closeness": 4.95231416688704e-7,
            "degree": 3,
            "eigen": 0.00000948090287903912
        },
        "positions": {
            "2000": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2001": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2002": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Boston College",
                "position": "OC"
            },
            "2008": {
                "college": "Boston College",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "-0.0348015017228107",
                "net_sr": "-0.0113312690725988",
                "net_stuff": "-0.0153851543001816",
                "net_pass_sr": "-0.0370352724084341",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0348015017228107
    },
    {
        "id": 112,
        "name": "John Thompson",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 914,
            "closeness": 4.99103859021128e-7,
            "degree": 7,
            "eigen": 0.000371185189034426
        },
        "positions": {
            "2000": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2001": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2002": {
                "college": "Florida",
                "position": "DC"
            },
            "2003": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2004": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2013": false,
            "2014": {
                "college": "Texas State",
                "position": "DC"
            },
            "2015": {
                "college": "Texas State",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "-0.0475579708038608",
                "net_sr": "-0.0191523486497968",
                "net_stuff": "0.020024338357837",
                "net_pass_sr": "-0.0591893852736706",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0171766956398182",
                "net_sr": "-0.0379200995846284",
                "net_stuff": "0.0341815383508192",
                "net_pass_sr": "-0.0271348906816465",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas State",
                "role": "OC",
                "net_ppa": "-0.109668295672152",
                "net_sr": "-0.0133392568273828",
                "net_stuff": "0.0320672276489543",
                "net_pass_sr": "-0.0261890521027531",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.174402962115831
    },
    {
        "id": 113,
        "name": "Skip Holtz",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 36,
            "closeness": 5.01263434486623e-7,
            "degree": 13,
            "eigen": 0.000351775187881152
        },
        "positions": {
            "2000": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2001": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2006": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2007": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2008": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2009": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2010": {
                "college": "South Florida",
                "position": "HC"
            },
            "2011": {
                "college": "South Florida",
                "position": "HC"
            },
            "2012": {
                "college": "South Florida",
                "position": "HC"
            },
            "2013": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2014": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2015": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2016": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2017": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2018": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2019": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2020": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2021": {
                "college": "Louisiana Tech",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Louisiana Tech",
                "role": "HC",
                "net_ppa": "0.093841573",
                "net_sr": "0.007866783",
                "net_stuff": "-0.038838849",
                "net_pass_sr": "0.009659458",
                "net_fpi": "N/A",
                "total_sr": "0.070801049",
                "total_stuffs": "-0.349549637",
                "total_pass_sr": "0.086935124",
                "total_ppa": "0.84457416"
            },
            {
                "college": "South Florida",
                "role": "HC",
                "net_ppa": "-0.106857506",
                "net_sr": "-0.027406106",
                "net_stuff": "0.02010441",
                "net_pass_sr": "-0.097063812",
                "net_fpi": "N/A",
                "total_sr": "-0.082218317",
                "total_stuffs": "0.06031323",
                "total_pass_sr": "-0.291191437",
                "total_ppa": "-0.320572519"
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.013015933000000007
    },
    {
        "id": 114,
        "name": "Ruffin McNeill",
        "community": 14,
        "isWhite": false,
        "centrality": {
            "betweenness": 39,
            "closeness": 5.10623783119697e-7,
            "degree": 5,
            "eigen": 0.00138363973463577
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2008": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2009": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2010": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2011": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2012": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2013": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2014": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2015": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "HC",
                "net_ppa": "0.04643525",
                "net_sr": "0.040671261",
                "net_stuff": "0.066871719",
                "net_pass_sr": "0.06610612",
                "net_fpi": "N/A",
                "total_sr": "0.244027565",
                "total_stuffs": "0.401230312",
                "total_pass_sr": "0.39663672",
                "total_ppa": "0.278611498"
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "0.144368745714986",
                "net_sr": "0.0221710004793546",
                "net_stuff": "-0.0890095367713049",
                "net_pass_sr": "0.0271298077516477",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.19080399571498602
    },
    {
        "id": 115,
        "name": "Scottie Montgomery",
        "community": 20,
        "isWhite": false,
        "centrality": {
            "betweenness": 17.5,
            "closeness": 4.89705652623378e-7,
            "degree": 5,
            "eigen": 0.000379021700770169
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Duke",
                "position": "OC"
            },
            "2014": {
                "college": "Duke",
                "position": "OC"
            },
            "2015": {
                "college": "Duke",
                "position": "OC"
            },
            "2016": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2017": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2018": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2019": {
                "college": "Maryland",
                "position": "OC"
            },
            "2020": {
                "college": "Maryland",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "HC",
                "net_ppa": "-0.246367183",
                "net_sr": "-0.124159099",
                "net_stuff": "0.00684151",
                "net_pass_sr": "-0.137714767",
                "net_fpi": "N/A",
                "total_sr": "-0.372477296",
                "total_stuffs": "0.020524529",
                "total_pass_sr": "-0.413144301",
                "total_ppa": "-0.73910155"
            },
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "0.0874529910580167",
                "net_sr": "0.0407083465688285",
                "net_stuff": "-0.0747537978904527",
                "net_pass_sr": "-0.00639228098367095",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.00360844367797133",
                "net_sr": "-0.0130755032773882",
                "net_stuff": "-0.0105077985430142",
                "net_pass_sr": "0.0307924202148539",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.16252263561995464
    },
    {
        "id": 116,
        "name": "Mike Houston",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89705652623378e-7,
            "degree": 3,
            "eigen": 0.00000629899216994494
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2020": {
                "college": "East Carolina",
                "position": "HC"
            },
            "2021": {
                "college": "East Carolina",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "HC",
                "net_ppa": "0.081857362",
                "net_sr": "0.031533208",
                "net_stuff": "-0.018249426",
                "net_pass_sr": "0.031863022",
                "net_fpi": "N/A",
                "total_sr": "0.094599623",
                "total_stuffs": "-0.054748278",
                "total_pass_sr": "0.095589066",
                "total_ppa": "0.245572085"
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.081857362
    },
    {
        "id": 119,
        "name": "Ron English",
        "community": 16,
        "isWhite": false,
        "centrality": {
            "betweenness": 65,
            "closeness": 4.91079781312352e-7,
            "degree": 8,
            "eigen": 0.000103742874908131
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Michigan",
                "position": "DC"
            },
            "2007": {
                "college": "Michigan",
                "position": "DC"
            },
            "2008": {
                "college": "Louisville",
                "position": "DC"
            },
            "2009": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2010": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2011": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2012": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2013": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "San José State",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Purdue",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Eastern Michigan",
                "role": "HC",
                "net_ppa": "-0.027328458",
                "net_sr": "-0.033959439",
                "net_stuff": "0.045458432",
                "net_pass_sr": "-0.049997963",
                "net_fpi": "N/A",
                "total_sr": "-0.169797194",
                "total_stuffs": "0.227292159",
                "total_pass_sr": "-0.249989815",
                "total_ppa": "-0.136642292"
            },
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "-0.0499388940327454",
                "net_sr": "-0.0123904713392092",
                "net_stuff": "0.0330009647446697",
                "net_pass_sr": "-0.0201381183243811",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.0617589390300901",
                "net_sr": "-0.0126040274938428",
                "net_stuff": "-0.0478036175710594",
                "net_pass_sr": "-0.018498727735369",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "0.0957499257380418",
                "net_sr": "0.0277827951263589",
                "net_stuff": "-0.0347039770447655",
                "net_pass_sr": "-0.00322693796192031",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "-0.0402010233960347",
                "net_sr": "0.00451462552929144",
                "net_stuff": "0.0372865668167102",
                "net_pass_sr": "0.0583304744009267",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.04004048933935179
    },
    {
        "id": 120,
        "name": "Chris Creighton",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90390070974155e-7,
            "degree": 4,
            "eigen": 0.0000515665505613676
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2015": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2016": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2017": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2018": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2019": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2020": {
                "college": "Eastern Michigan",
                "position": "HC"
            },
            "2021": {
                "college": "Eastern Michigan",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Eastern Michigan",
                "role": "HC",
                "net_ppa": "-0.011720924",
                "net_sr": "0.01432692",
                "net_stuff": "0.013414099",
                "net_pass_sr": "0.084528465",
                "net_fpi": "N/A",
                "total_sr": "0.114615358",
                "total_stuffs": "0.107312795",
                "total_pass_sr": "0.676227724",
                "total_ppa": "-0.093767394"
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.011720924
    },
    {
        "id": 122,
        "name": "Carl Pelini",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 27,
            "closeness": 4.897061322471e-7,
            "degree": 5,
            "eigen": 0.00972655533674839
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2009": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2010": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2011": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2012": {
                "college": "Florida Atlantic",
                "position": "HC"
            },
            "2013": {
                "college": "Florida Atlantic",
                "position": "HC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "HC",
                "net_ppa": "0.085964148",
                "net_sr": "0.064086285",
                "net_stuff": "0.002746527",
                "net_pass_sr": "0.070609247",
                "net_fpi": "N/A",
                "total_sr": "0.12817257",
                "total_stuffs": "0.005493055",
                "total_pass_sr": "0.141218495",
                "total_ppa": "0.171928297"
            },
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.0723523696435723",
                "net_sr": "-0.0263258400496958",
                "net_stuff": "0.0440548416338776",
                "net_pass_sr": "0.0791285318088101",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "0.0659305464101007",
                "net_sr": "0.0178476639983056",
                "net_stuff": "0.068188337612954",
                "net_pass_sr": "0.0412294887066436",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0795423247665284
    },
    {
        "id": 123,
        "name": "Charlie Partridge",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 9,
            "closeness": 4.89705652623378e-7,
            "degree": 4,
            "eigen": 0.000205414097383142
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2012": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2013": false,
            "2014": {
                "college": "Florida Atlantic",
                "position": "HC"
            },
            "2015": {
                "college": "Florida Atlantic",
                "position": "HC"
            },
            "2016": {
                "college": "Florida Atlantic",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "HC",
                "net_ppa": "-0.093954127",
                "net_sr": "-0.022023042",
                "net_stuff": "0.04801985",
                "net_pass_sr": "-0.021133158",
                "net_fpi": "N/A",
                "total_sr": "-0.066069127",
                "total_stuffs": "0.14405955",
                "total_pass_sr": "-0.063399473",
                "total_ppa": "-0.281862381"
            },
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "0.000646346674610401",
                "net_sr": "0.00604140228509281",
                "net_stuff": "0.0370595177860597",
                "net_pass_sr": "0.00155946538285989",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0933077803253896
    },
    {
        "id": 124,
        "name": "Lane Kiffin",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 253.166666666667,
            "closeness": 5.46806649168854e-7,
            "degree": 16,
            "eigen": 0.00089143964715581
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "USC",
                "position": "OC"
            },
            "2006": {
                "college": "USC",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2010": {
                "college": "USC",
                "position": "HC"
            },
            "2011": {
                "college": "USC",
                "position": "HC"
            },
            "2012": {
                "college": "USC",
                "position": "HC"
            },
            "2013": {
                "college": "USC",
                "position": "HC"
            },
            "2014": {
                "college": "Alabama",
                "position": "OC"
            },
            "2015": {
                "college": "Alabama",
                "position": "OC"
            },
            "2016": {
                "college": "Alabama",
                "position": "OC"
            },
            "2017": {
                "college": "Florida Atlantic",
                "position": "HC"
            },
            "2018": {
                "college": "Florida Atlantic",
                "position": "HC"
            },
            "2019": {
                "college": "Florida Atlantic",
                "position": "HC"
            },
            "2020": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2021": {
                "college": "Ole Miss",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "HC",
                "net_ppa": "0.246455448",
                "net_sr": "0.100560406",
                "net_stuff": "-0.046998214",
                "net_pass_sr": "0.052564776",
                "net_fpi": "N/A",
                "total_sr": "0.301681219",
                "total_stuffs": "-0.140994643",
                "total_pass_sr": "0.157694328",
                "total_ppa": "0.739366344"
            },
            {
                "college": "Ole Miss",
                "role": "HC",
                "net_ppa": "-0.002752018",
                "net_sr": "-0.021826085",
                "net_stuff": "-0.007981068",
                "net_pass_sr": "0.006476548",
                "net_fpi": "5.283333333",
                "total_sr": "-0.043652171",
                "total_stuffs": "-0.015962137",
                "total_pass_sr": "0.012953096",
                "total_ppa": "-0.005504036"
            },
            {
                "college": "Tennessee",
                "role": "HC",
                "net_ppa": "0.049453022",
                "net_sr": "0.011671266",
                "net_stuff": "-0.041492858",
                "net_pass_sr": "0.051176979",
                "net_fpi": "0.966666667",
                "total_sr": "0.011671266",
                "total_stuffs": "-0.041492858",
                "total_pass_sr": "0.051176979",
                "total_ppa": "0.049453022"
            },
            {
                "college": "USC",
                "role": "HC",
                "net_ppa": "-0.111596595",
                "net_sr": "-0.071107858",
                "net_stuff": "0.087624877",
                "net_pass_sr": "-0.052489944",
                "net_fpi": "-7.441666667",
                "total_sr": "-0.284431431",
                "total_stuffs": "0.350499507",
                "total_pass_sr": "-0.209959777",
                "total_ppa": "-0.446386381"
            },
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.108844414195549",
                "net_sr": "-0.000770025694238341",
                "net_stuff": "0.0112413915010281",
                "net_pass_sr": "-0.0100624155736763",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.290404271195549
    },
    {
        "id": 125,
        "name": "Willie Taggart",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.41925820109892e-7,
            "degree": 19,
            "eigen": 0.000936838690704697
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2011": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2012": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2013": {
                "college": "South Florida",
                "position": "HC"
            },
            "2014": {
                "college": "South Florida",
                "position": "HC"
            },
            "2015": {
                "college": "South Florida",
                "position": "HC"
            },
            "2016": {
                "college": "South Florida",
                "position": "HC"
            },
            "2017": {
                "college": "Oregon",
                "position": "HC"
            },
            "2018": {
                "college": "Florida State",
                "position": "HC"
            },
            "2019": {
                "college": "Florida State",
                "position": "HC"
            },
            "2020": {
                "college": "Florida Atlantic",
                "position": "HC"
            },
            "2021": {
                "college": "Florida Atlantic",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "HC",
                "net_ppa": "-0.088905348",
                "net_sr": "-0.040504256",
                "net_stuff": "0.002754831",
                "net_pass_sr": "-0.034444642",
                "net_fpi": "N/A",
                "total_sr": "-0.081008512",
                "total_stuffs": "0.005509663",
                "total_pass_sr": "-0.068889284",
                "total_ppa": "-0.177810696"
            },
            {
                "college": "Florida State",
                "role": "HC",
                "net_ppa": "-0.084642648",
                "net_sr": "-0.120035536",
                "net_stuff": "0.039444954",
                "net_pass_sr": "-0.110268527",
                "net_fpi": "N/A",
                "total_sr": "-0.240071071",
                "total_stuffs": "0.078889908",
                "total_pass_sr": "-0.220537054",
                "total_ppa": "-0.169285297"
            },
            {
                "college": "Oregon",
                "role": "HC",
                "net_ppa": "0.060139106",
                "net_sr": "0.064646107",
                "net_stuff": "-0.040436695",
                "net_pass_sr": "0.029367678",
                "net_fpi": "-6.033333333",
                "total_sr": "0.064646107",
                "total_stuffs": "-0.040436695",
                "total_pass_sr": "0.029367678",
                "total_ppa": "0.060139106"
            },
            {
                "college": "South Florida",
                "role": "HC",
                "net_ppa": "0.038686084",
                "net_sr": "-0.069307465",
                "net_stuff": "0.014953151",
                "net_pass_sr": "-0.031683642",
                "net_fpi": "N/A",
                "total_sr": "-0.277229859",
                "total_stuffs": "0.059812605",
                "total_pass_sr": "-0.126734568",
                "total_ppa": "0.154744338"
            },
            {
                "college": "Western Kentucky",
                "role": "HC",
                "net_ppa": "0.054378937",
                "net_sr": "0.036989136",
                "net_stuff": "-0.136088985",
                "net_pass_sr": "0.057932935",
                "net_fpi": "N/A",
                "total_sr": "0.110967407",
                "total_stuffs": "-0.408266955",
                "total_pass_sr": "0.173798804",
                "total_ppa": "0.163136811"
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.020343868999999973
    },
    {
        "id": 127,
        "name": "Mario Cristobal",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 39,
            "closeness": 5.04884250236791e-7,
            "degree": 16,
            "eigen": 0.000603453044183387
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Florida International",
                "position": "HC"
            },
            "2008": {
                "college": "Florida International",
                "position": "HC"
            },
            "2009": {
                "college": "Florida International",
                "position": "HC"
            },
            "2010": {
                "college": "Florida International",
                "position": "HC"
            },
            "2011": {
                "college": "Florida International",
                "position": "HC"
            },
            "2012": {
                "college": "Florida International",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Oregon",
                "position": "OC"
            },
            "2018": {
                "college": "Oregon",
                "position": "HC"
            },
            "2019": {
                "college": "Oregon",
                "position": "HC"
            },
            "2020": {
                "college": "Oregon",
                "position": "HC"
            },
            "2021": {
                "college": "Oregon",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "HC",
                "net_ppa": "0.167070726",
                "net_sr": "0.112575348",
                "net_stuff": "-0.111672713",
                "net_pass_sr": "0.094151438",
                "net_fpi": "N/A",
                "total_sr": "0.675452089",
                "total_stuffs": "-0.67003628",
                "total_pass_sr": "0.564908631",
                "total_ppa": "1.002424358"
            },
            {
                "college": "Oregon",
                "role": "HC",
                "net_ppa": "0.084337606",
                "net_sr": "0.039449999",
                "net_stuff": "-0.018239125",
                "net_pass_sr": "0.039329081",
                "net_fpi": "4.441666667",
                "total_sr": "0.157799996",
                "total_stuffs": "-0.072956498",
                "total_pass_sr": "0.157316325",
                "total_ppa": "0.337350423"
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "-0.11182159018047",
                "net_sr": "-0.0317690652096546",
                "net_stuff": "0.00880760538718714",
                "net_pass_sr": "-0.0542397197124574",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "Other",
        "aggregate_xpa": 0.13958674181953
    },
    {
        "id": 128,
        "name": "Ron Turner",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.91080745951653e-7,
            "degree": 7,
            "eigen": 0.000149904537115399
        },
        "positions": {
            "2000": {
                "college": "Illinois",
                "position": "HC"
            },
            "2001": {
                "college": "Illinois",
                "position": "HC"
            },
            "2002": {
                "college": "Illinois",
                "position": "HC"
            },
            "2003": {
                "college": "Illinois",
                "position": "HC"
            },
            "2004": {
                "college": "Illinois",
                "position": "HC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Florida International",
                "position": "HC"
            },
            "2014": {
                "college": "Florida International",
                "position": "HC"
            },
            "2015": {
                "college": "Florida International",
                "position": "HC"
            },
            "2016": {
                "college": "Florida International",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "HC",
                "net_ppa": "-0.138522065",
                "net_sr": "-0.094571038",
                "net_stuff": "0.039519567",
                "net_pass_sr": "-0.067009314",
                "net_fpi": "N/A",
                "total_sr": "-0.37828415",
                "total_stuffs": "0.158078269",
                "total_pass_sr": "-0.268037257",
                "total_ppa": "-0.554088258"
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.138522065
    },
    {
        "id": 129,
        "name": "Butch Davis",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.10374640504863e-7,
            "degree": 10,
            "eigen": 0.000188211689968285
        },
        "positions": {
            "2000": {
                "college": "Miami",
                "position": "HC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2008": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2009": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2010": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Florida International",
                "position": "HC"
            },
            "2018": {
                "college": "Florida International",
                "position": "HC"
            },
            "2019": {
                "college": "Florida International",
                "position": "HC"
            },
            "2020": {
                "college": "Florida International",
                "position": "HC"
            },
            "2021": {
                "college": "Florida International",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "HC",
                "net_ppa": "0.007753624",
                "net_sr": "-0.016342309",
                "net_stuff": "-0.01967395",
                "net_pass_sr": "0.000765947",
                "net_fpi": "N/A",
                "total_sr": "-0.081711546",
                "total_stuffs": "-0.098369749",
                "total_pass_sr": "0.003829734",
                "total_ppa": "0.038768118"
            },
            {
                "college": "North Carolina",
                "role": "HC",
                "net_ppa": "0.084084833",
                "net_sr": "0.056634817",
                "net_stuff": "-0.087406477",
                "net_pass_sr": "0.049258861",
                "net_fpi": "N/A",
                "total_sr": "0.226539266",
                "total_stuffs": "-0.349625909",
                "total_pass_sr": "0.197035444",
                "total_ppa": "0.336339333"
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.091838457
    },
    {
        "id": 130,
        "name": "Steve Spurrier",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.0888565237614e-7,
            "degree": 7,
            "eigen": 0.0000767061813048737
        },
        "positions": {
            "2000": {
                "college": "Florida",
                "position": "HC"
            },
            "2001": {
                "college": "Florida",
                "position": "HC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2006": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2007": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2008": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2009": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2010": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2011": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2012": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2013": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2014": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2015": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Carolina",
                "role": "HC",
                "net_ppa": "0.021615019",
                "net_sr": "0.007785413",
                "net_stuff": "0.011582799",
                "net_pass_sr": "-0.011188013",
                "net_fpi": "3.63",
                "total_sr": "0.038927065",
                "total_stuffs": "0.057913994",
                "total_pass_sr": "-0.055940067",
                "total_ppa": "0.108075094"
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.021615019
    },
    {
        "id": 132,
        "name": "Will Muschamp",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 1047.33333333333,
            "closeness": 5.00190822798898e-7,
            "degree": 11,
            "eigen": 0.00146378950641627
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "LSU",
                "position": "DC"
            },
            "2003": {
                "college": "LSU",
                "position": "DC"
            },
            "2004": {
                "college": "LSU",
                "position": "DC"
            },
            "2005": false,
            "2006": {
                "college": "Auburn",
                "position": "DC"
            },
            "2007": {
                "college": "Auburn",
                "position": "DC"
            },
            "2008": {
                "college": "Texas",
                "position": "DC"
            },
            "2009": {
                "college": "Texas",
                "position": "DC"
            },
            "2010": {
                "college": "Texas",
                "position": "DC"
            },
            "2011": {
                "college": "Florida",
                "position": "HC"
            },
            "2012": {
                "college": "Florida",
                "position": "HC"
            },
            "2013": {
                "college": "Florida",
                "position": "HC"
            },
            "2014": {
                "college": "Florida",
                "position": "HC"
            },
            "2015": {
                "college": "Auburn",
                "position": "DC"
            },
            "2016": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2017": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2018": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2019": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2020": {
                "college": "South Carolina",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Florida",
                "role": "HC",
                "net_ppa": "-0.108792252",
                "net_sr": "-0.039235909",
                "net_stuff": "-0.005945764",
                "net_pass_sr": "-0.034314185",
                "net_fpi": "-12.03333333",
                "total_sr": "-0.156943638",
                "total_stuffs": "-0.023783055",
                "total_pass_sr": "-0.137256741",
                "total_ppa": "-0.435169009"
            },
            {
                "college": "South Carolina",
                "role": "HC",
                "net_ppa": "-0.048462947",
                "net_sr": "-0.020147321",
                "net_stuff": "0.000248785",
                "net_pass_sr": "-0.029244438",
                "net_fpi": "N/A",
                "total_sr": "-0.100736604",
                "total_stuffs": "0.001243924",
                "total_pass_sr": "-0.146222189",
                "total_ppa": "-0.242314736"
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.111503269347426",
                "net_sr": "-0.0110664560757673",
                "net_stuff": "0.0373661782886404",
                "net_pass_sr": "0.0049556681231171",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "-0.0424424881574174",
                "net_sr": "0.0154588779360665",
                "net_stuff": "-0.0515182479055428",
                "net_pass_sr": "0.0241586886974622",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 18,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.3112009565048434
    },
    {
        "id": 133,
        "name": "Dan Mullen",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 21,
            "closeness": 4.96644421963007e-7,
            "degree": 11,
            "eigen": 0.00216851765894251
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Florida",
                "position": "OC"
            },
            "2006": {
                "college": "Florida",
                "position": "OC"
            },
            "2007": {
                "college": "Florida",
                "position": "OC"
            },
            "2008": {
                "college": "Florida",
                "position": "OC"
            },
            "2009": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2010": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2011": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2012": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2013": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2014": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2015": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2016": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2017": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2018": {
                "college": "Florida",
                "position": "HC"
            },
            "2019": {
                "college": "Florida",
                "position": "HC"
            },
            "2020": {
                "college": "Florida",
                "position": "HC"
            },
            "2021": {
                "college": "Florida",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Florida",
                "role": "HC",
                "net_ppa": "0.06353037",
                "net_sr": "0.038128448",
                "net_stuff": "0.034290286",
                "net_pass_sr": "0.076359433",
                "net_fpi": "5.65",
                "total_sr": "0.152513791",
                "total_stuffs": "0.137161143",
                "total_pass_sr": "0.305437733",
                "total_ppa": "0.25412148"
            },
            {
                "college": "Mississippi State",
                "role": "HC",
                "net_ppa": "0.112849181",
                "net_sr": "0.07236447",
                "net_stuff": "-0.031883656",
                "net_pass_sr": "0.057636501",
                "net_fpi": "N/A",
                "total_sr": "0.651280229",
                "total_stuffs": "-0.286952908",
                "total_pass_sr": "0.518728507",
                "total_ppa": "1.015642627"
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.17637955100000002
    },
    {
        "id": 135,
        "name": "Jimbo Fisher",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 94,
            "closeness": 5.03410100017519e-7,
            "degree": 12,
            "eigen": 0.0037391865167383
        },
        "positions": {
            "2000": {
                "college": "LSU",
                "position": "OC"
            },
            "2001": {
                "college": "LSU",
                "position": "OC"
            },
            "2002": {
                "college": "LSU",
                "position": "OC"
            },
            "2003": {
                "college": "LSU",
                "position": "OC"
            },
            "2004": {
                "college": "LSU",
                "position": "OC"
            },
            "2005": {
                "college": "LSU",
                "position": "OC"
            },
            "2006": {
                "college": "LSU",
                "position": "OC"
            },
            "2007": {
                "college": "Florida State",
                "position": "OC"
            },
            "2008": {
                "college": "Florida State",
                "position": "OC"
            },
            "2009": {
                "college": "Florida State",
                "position": "OC"
            },
            "2010": {
                "college": "Florida State",
                "position": "HC"
            },
            "2011": {
                "college": "Florida State",
                "position": "HC"
            },
            "2012": {
                "college": "Florida State",
                "position": "HC"
            },
            "2013": {
                "college": "Florida State",
                "position": "HC"
            },
            "2014": {
                "college": "Florida State",
                "position": "HC"
            },
            "2015": {
                "college": "Florida State",
                "position": "HC"
            },
            "2016": {
                "college": "Florida State",
                "position": "HC"
            },
            "2017": {
                "college": "Florida State",
                "position": "HC"
            },
            "2018": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2019": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2020": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2021": {
                "college": "Texas A&M",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Florida State",
                "role": "HC",
                "net_ppa": "0.102344184",
                "net_sr": "0.026248058",
                "net_stuff": "0.033580786",
                "net_pass_sr": "0.029731884",
                "net_fpi": "N/A",
                "total_sr": "0.209984468",
                "total_stuffs": "0.268646285",
                "total_pass_sr": "0.237855075",
                "total_ppa": "0.81875347"
            },
            {
                "college": "Texas A&M",
                "role": "HC",
                "net_ppa": "0.070885631",
                "net_sr": "0.079453943",
                "net_stuff": "0.013314041",
                "net_pass_sr": "0.051760656",
                "net_fpi": "3.05",
                "total_sr": "0.317815774",
                "total_stuffs": "0.053256166",
                "total_pass_sr": "0.207042624",
                "total_ppa": "0.283542526"
            },
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "-0.120245904351577",
                "net_sr": "-0.0619165084765997",
                "net_stuff": "0.125658011585063",
                "net_pass_sr": "-0.0513879453752991",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 22,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.052983910648423005
    },
    {
        "id": 136,
        "name": "Mike Norvell",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 55,
            "closeness": 4.92114359503319e-7,
            "degree": 7,
            "eigen": 0.00060357111160444
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2012": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2013": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2014": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2015": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2016": {
                "college": "Memphis",
                "position": "HC"
            },
            "2017": {
                "college": "Memphis",
                "position": "HC"
            },
            "2018": {
                "college": "Memphis",
                "position": "HC"
            },
            "2019": {
                "college": "Memphis",
                "position": "HC"
            },
            "2020": {
                "college": "Florida State",
                "position": "HC"
            },
            "2021": {
                "college": "Florida State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Florida State",
                "role": "HC",
                "net_ppa": "-0.0565427",
                "net_sr": "-0.008952683",
                "net_stuff": "-0.054124968",
                "net_pass_sr": "-0.083573095",
                "net_fpi": "N/A",
                "total_sr": "-0.017905366",
                "total_stuffs": "-0.108249936",
                "total_pass_sr": "-0.167146191",
                "total_ppa": "-0.113085399"
            },
            {
                "college": "Memphis",
                "role": "HC",
                "net_ppa": "0.065608778",
                "net_sr": "0.014255194",
                "net_stuff": "0.035047948",
                "net_pass_sr": "0.022507418",
                "net_fpi": "4.466666667",
                "total_sr": "0.057020774",
                "total_stuffs": "0.140191792",
                "total_pass_sr": "0.09002967",
                "total_ppa": "0.26243511"
            },
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.0796861892551381",
                "net_sr": "0.0279086841587586",
                "net_stuff": "-0.0104963237769276",
                "net_pass_sr": "0.0300509951315998",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "0.0126933554616749",
                "net_sr": "-0.00840867216131497",
                "net_stuff": "-0.00553824780077339",
                "net_pass_sr": "0.00322632005637641",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.101445622716813
    },
    {
        "id": 138,
        "name": "Tim DeRuyter",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 122,
            "closeness": 4.90048103121802e-7,
            "degree": 11,
            "eigen": 0.00245035182478647
        },
        "positions": {
            "2000": {
                "college": "Navy",
                "position": "DC"
            },
            "2001": false,
            "2002": {
                "college": "Ohio",
                "position": "DC"
            },
            "2003": {
                "college": "Ohio",
                "position": "DC"
            },
            "2004": {
                "college": "Ohio",
                "position": "DC"
            },
            "2005": {
                "college": "Nevada",
                "position": "DC"
            },
            "2006": {
                "college": "Nevada",
                "position": "DC"
            },
            "2007": {
                "college": "Air Force",
                "position": "DC"
            },
            "2008": {
                "college": "Air Force",
                "position": "DC"
            },
            "2009": false,
            "2010": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2011": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2012": {
                "college": "Fresno State",
                "position": "HC"
            },
            "2013": {
                "college": "Fresno State",
                "position": "HC"
            },
            "2014": {
                "college": "Fresno State",
                "position": "HC"
            },
            "2015": {
                "college": "Fresno State",
                "position": "HC"
            },
            "2016": {
                "college": "Fresno State",
                "position": "HC"
            },
            "2017": {
                "college": "California",
                "position": "DC"
            },
            "2018": {
                "college": "California",
                "position": "DC"
            },
            "2019": {
                "college": "California",
                "position": "DC"
            },
            "2020": false,
            "2021": {
                "college": "Oregon",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "HC",
                "net_ppa": "-0.102560935",
                "net_sr": "-0.037291244",
                "net_stuff": "0.070309877",
                "net_pass_sr": "-0.057448041",
                "net_fpi": "-1.506666667",
                "total_sr": "-0.186456222",
                "total_stuffs": "0.351549387",
                "total_pass_sr": "-0.287240205",
                "total_ppa": "-0.512804674"
            },
            {
                "college": "Air Force",
                "role": "OC",
                "net_ppa": "0.0155794343929114",
                "net_sr": "-0.028325330611413",
                "net_stuff": "-0.0529817472487205",
                "net_pass_sr": "-0.0575161245038273",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "-0.0546755521128843",
                "net_sr": "-0.0273526034272923",
                "net_stuff": "0.0334241450843029",
                "net_pass_sr": "-0.0181433587969267",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "-0.114857029537291",
                "net_sr": "-0.0339537302387207",
                "net_stuff": "0.0171241646716254",
                "net_pass_sr": "-0.0278226832321591",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "0.0821662004678398",
                "net_sr": "-0.0114824342281347",
                "net_stuff": "-0.0360579827230431",
                "net_pass_sr": "-0.0428116924229009",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.17434788178942412
    },
    {
        "id": 139,
        "name": "Kalen DeBoer",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 1,
            "closeness": 4.89021228900568e-7,
            "degree": 3,
            "eigen": 0.000204215139656062
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2015": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2016": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Indiana",
                "position": "OC"
            },
            "2020": {
                "college": "Fresno State",
                "position": "HC"
            },
            "2021": {
                "college": "Fresno State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "HC",
                "net_ppa": "-0.033319358",
                "net_sr": "0.036254998",
                "net_stuff": "0.026946466",
                "net_pass_sr": "0.081278152",
                "net_fpi": "-5.183333333",
                "total_sr": "0.072509995",
                "total_stuffs": "0.053892932",
                "total_pass_sr": "0.162556304",
                "total_ppa": "-0.066638716"
            },
            {
                "college": "Eastern Michigan",
                "role": "OC",
                "net_ppa": "-0.168330534623018",
                "net_sr": "-0.0767303259478046",
                "net_stuff": "0.0165005743435166",
                "net_pass_sr": "-0.11963221963222",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.167745539639114",
                "net_sr": "0.036001183922535",
                "net_stuff": "-0.0242468863846627",
                "net_pass_sr": "0.0779385373324868",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.033904352983903985
    },
    {
        "id": 141,
        "name": "Mark Richt",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 50,
            "closeness": 5.06296300796708e-7,
            "degree": 12,
            "eigen": 0.000917746012961376
        },
        "positions": {
            "2000": {
                "college": "Florida State",
                "position": "OC"
            },
            "2001": {
                "college": "Georgia",
                "position": "HC"
            },
            "2002": {
                "college": "Georgia",
                "position": "HC"
            },
            "2003": {
                "college": "Georgia",
                "position": "HC"
            },
            "2004": {
                "college": "Georgia",
                "position": "HC"
            },
            "2005": {
                "college": "Georgia",
                "position": "HC"
            },
            "2006": {
                "college": "Georgia",
                "position": "HC"
            },
            "2007": {
                "college": "Georgia",
                "position": "HC"
            },
            "2008": {
                "college": "Georgia",
                "position": "HC"
            },
            "2009": {
                "college": "Georgia",
                "position": "HC"
            },
            "2010": {
                "college": "Georgia",
                "position": "HC"
            },
            "2011": {
                "college": "Georgia",
                "position": "HC"
            },
            "2012": {
                "college": "Georgia",
                "position": "HC"
            },
            "2013": {
                "college": "Georgia",
                "position": "HC"
            },
            "2014": {
                "college": "Georgia",
                "position": "HC"
            },
            "2015": {
                "college": "Georgia",
                "position": "HC"
            },
            "2016": {
                "college": "Miami",
                "position": "HC"
            },
            "2017": {
                "college": "Miami",
                "position": "HC"
            },
            "2018": {
                "college": "Miami",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami",
                "role": "HC",
                "net_ppa": "0.095216304",
                "net_sr": "0.03580945",
                "net_stuff": "-0.091720318",
                "net_pass_sr": "0.012271641",
                "net_fpi": "4.633333333",
                "total_sr": "0.10742835",
                "total_stuffs": "-0.275160953",
                "total_pass_sr": "0.036814922",
                "total_ppa": "0.285648913"
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.095216304
    },
    {
        "id": 142,
        "name": "Kirby Smart",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 18,
            "closeness": 4.95940725164528e-7,
            "degree": 7,
            "eigen": 0.0013904002177318
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Alabama",
                "position": "DC"
            },
            "2009": {
                "college": "Alabama",
                "position": "DC"
            },
            "2010": {
                "college": "Alabama",
                "position": "DC"
            },
            "2011": {
                "college": "Alabama",
                "position": "DC"
            },
            "2012": {
                "college": "Alabama",
                "position": "DC"
            },
            "2013": {
                "college": "Alabama",
                "position": "DC"
            },
            "2014": false,
            "2015": {
                "college": "Alabama",
                "position": "DC"
            },
            "2016": {
                "college": "Georgia",
                "position": "HC"
            },
            "2017": {
                "college": "Georgia",
                "position": "HC"
            },
            "2018": {
                "college": "Georgia",
                "position": "HC"
            },
            "2019": {
                "college": "Georgia",
                "position": "HC"
            },
            "2020": {
                "college": "Georgia",
                "position": "HC"
            },
            "2021": {
                "college": "Georgia",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Georgia",
                "role": "HC",
                "net_ppa": "0.032074497",
                "net_sr": "0.00478782",
                "net_stuff": "-0.001698057",
                "net_pass_sr": "-0.039218243",
                "net_fpi": "2.85",
                "total_sr": "0.028726923",
                "total_stuffs": "-0.010188344",
                "total_pass_sr": "-0.235309458",
                "total_ppa": "0.192446984"
            },
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.0452881605055365",
                "net_sr": "0.0296212506389352",
                "net_stuff": "-0.00706620239456651",
                "net_pass_sr": "0.0201917003301135",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0773626575055365
    },
    {
        "id": 143,
        "name": "Willie Fritz",
        "community": 22,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.9073420216188e-7,
            "degree": 5,
            "eigen": 0.0000130649204375039
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Georgia Southern",
                "position": "HC"
            },
            "2015": {
                "college": "Georgia Southern",
                "position": "HC"
            },
            "2016": {
                "college": "Tulane",
                "position": "HC"
            },
            "2017": {
                "college": "Tulane",
                "position": "HC"
            },
            "2018": {
                "college": "Tulane",
                "position": "HC"
            },
            "2019": {
                "college": "Tulane",
                "position": "HC"
            },
            "2020": {
                "college": "Tulane",
                "position": "HC"
            },
            "2021": {
                "college": "Tulane",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "HC",
                "net_ppa": "0.213039476",
                "net_sr": "0.071048081",
                "net_stuff": "-0.007569133",
                "net_pass_sr": "0.327922982",
                "net_fpi": "N/A",
                "total_sr": "0.142096163",
                "total_stuffs": "-0.015138265",
                "total_pass_sr": "0.655845963",
                "total_ppa": "0.426078952"
            },
            {
                "college": "Tulane",
                "role": "HC",
                "net_ppa": "0.105130617",
                "net_sr": "0.056970221",
                "net_stuff": "0.001683812",
                "net_pass_sr": "0.071515655",
                "net_fpi": "7.733333333",
                "total_sr": "0.341821326",
                "total_stuffs": "0.010102875",
                "total_pass_sr": "0.429093932",
                "total_ppa": "0.6307837"
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.318170093
    },
    {
        "id": 144,
        "name": "Tyson Summers",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 64,
            "closeness": 4.90049303860461e-7,
            "degree": 8,
            "eigen": 0.000191033858674776
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "UCF",
                "position": "DC"
            },
            "2015": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2016": {
                "college": "Georgia Southern",
                "position": "HC"
            },
            "2017": {
                "college": "Georgia Southern",
                "position": "HC"
            },
            "2018": false,
            "2019": {
                "college": "Colorado",
                "position": "DC"
            },
            "2020": {
                "college": "Colorado",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "HC",
                "net_ppa": "-0.257794396",
                "net_sr": "-0.077902642",
                "net_stuff": "0.01575492",
                "net_pass_sr": "0.044899437",
                "net_fpi": "N/A",
                "total_sr": "-0.155805283",
                "total_stuffs": "0.03150984",
                "total_pass_sr": "0.089798874",
                "total_ppa": "-0.515588792"
            },
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "0.0417056618919663",
                "net_sr": "0.00643619963157754",
                "net_stuff": "0.0654760330039909",
                "net_pass_sr": "0.0109186343049815",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "-0.0635448188174027",
                "net_sr": "-0.0167002262108223",
                "net_stuff": "0.13686203869063",
                "net_pass_sr": "-0.0286281467647052",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "0.212254837108239",
                "net_sr": "0.0711828481521922",
                "net_stuff": "-0.0611745102460063",
                "net_pass_sr": "0.036815149083777",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0673787158171974
    },
    {
        "id": 145,
        "name": "Chad Lunsford",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89705173000595e-7,
            "degree": 3,
            "eigen": 0.0000135688697342156
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Georgia Southern",
                "position": "HC"
            },
            "2019": {
                "college": "Georgia Southern",
                "position": "HC"
            },
            "2020": {
                "college": "Georgia Southern",
                "position": "HC"
            },
            "2021": {
                "college": "Georgia Southern",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "HC",
                "net_ppa": "-0.014978891",
                "net_sr": "-0.012660623",
                "net_stuff": "-0.004280546",
                "net_pass_sr": "-0.033632767",
                "net_fpi": "N/A",
                "total_sr": "-0.050642491",
                "total_stuffs": "-0.017122184",
                "total_pass_sr": "-0.13453107",
                "total_ppa": "-0.059915565"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.014978891
    },
    {
        "id": 146,
        "name": "Trent Miles",
        "community": 28,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.96628389861232e-7,
            "degree": 3,
            "eigen": 0.00000100559059398643
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Georgia State",
                "position": "HC"
            },
            "2014": {
                "college": "Georgia State",
                "position": "HC"
            },
            "2015": {
                "college": "Georgia State",
                "position": "HC"
            },
            "2016": {
                "college": "Georgia State",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia State",
                "role": "HC",
                "net_ppa": "0.256649773",
                "net_sr": "0.185269885",
                "net_stuff": "-0.01140432",
                "net_pass_sr": "0.368151784",
                "net_fpi": "N/A",
                "total_sr": "0.74107954",
                "total_stuffs": "-0.045617281",
                "total_pass_sr": "1.472607136",
                "total_ppa": "1.026599091"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.256649773
    },
    {
        "id": 147,
        "name": "Shawn Elliott",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 3,
            "closeness": 4.89704693378752e-7,
            "degree": 4,
            "eigen": 0.0000120400062112693
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2013": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2014": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Georgia State",
                "position": "HC"
            },
            "2018": {
                "college": "Georgia State",
                "position": "HC"
            },
            "2019": {
                "college": "Georgia State",
                "position": "HC"
            },
            "2020": {
                "college": "Georgia State",
                "position": "HC"
            },
            "2021": {
                "college": "Georgia State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Georgia State",
                "role": "HC",
                "net_ppa": "0.005019975",
                "net_sr": "0.086788619",
                "net_stuff": "-0.067014169",
                "net_pass_sr": "0.012694218",
                "net_fpi": "4.646666667",
                "total_sr": "0.433943097",
                "total_stuffs": "-0.335070846",
                "total_pass_sr": "0.063471092",
                "total_ppa": "0.025099874"
            },
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "0.0810710039529741",
                "net_sr": "0.0159840734528356",
                "net_stuff": "-0.00690387744495402",
                "net_pass_sr": "0.0278628857475393",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0860909789529741
    },
    {
        "id": 150,
        "name": "Paul Johnson",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.11805824964455e-7,
            "degree": 5,
            "eigen": 0.000383815533733262
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Navy",
                "position": "HC"
            },
            "2003": {
                "college": "Navy",
                "position": "HC"
            },
            "2004": {
                "college": "Navy",
                "position": "HC"
            },
            "2005": {
                "college": "Navy",
                "position": "HC"
            },
            "2006": {
                "college": "Navy",
                "position": "HC"
            },
            "2007": {
                "college": "Navy",
                "position": "HC"
            },
            "2008": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2009": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2010": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2011": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2012": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2013": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2014": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2015": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2016": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2017": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2018": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Tech",
                "role": "HC",
                "net_ppa": "0.074994125",
                "net_sr": "-0.019077339",
                "net_stuff": "0.025734508",
                "net_pass_sr": "-0.073364501",
                "net_fpi": "2.263636364",
                "total_sr": "-0.209850731",
                "total_stuffs": "0.28307959",
                "total_pass_sr": "-0.807009513",
                "total_ppa": "0.824935377"
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.074994125
    },
    {
        "id": 151,
        "name": "Geoff Collins",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 28,
            "closeness": 4.90047622827986e-7,
            "degree": 7,
            "eigen": 0.00045890008368912
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Florida International",
                "position": "DC"
            },
            "2011": false,
            "2012": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2013": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2014": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2015": {
                "college": "Florida",
                "position": "DC"
            },
            "2016": {
                "college": "Florida",
                "position": "DC"
            },
            "2017": {
                "college": "Temple",
                "position": "HC"
            },
            "2018": {
                "college": "Temple",
                "position": "HC"
            },
            "2019": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2020": {
                "college": "Georgia Tech",
                "position": "HC"
            },
            "2021": {
                "college": "Georgia Tech",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Georgia Tech",
                "role": "HC",
                "net_ppa": "-0.164616281",
                "net_sr": "-0.040434679",
                "net_stuff": "0.052165052",
                "net_pass_sr": "0.044413191",
                "net_fpi": "-13.73333333",
                "total_sr": "-0.121304037",
                "total_stuffs": "0.156495155",
                "total_pass_sr": "0.133239572",
                "total_ppa": "-0.493848842"
            },
            {
                "college": "Temple",
                "role": "HC",
                "net_ppa": "-0.012511873",
                "net_sr": "-0.02032363",
                "net_stuff": "0.009270238",
                "net_pass_sr": "0.022069826",
                "net_fpi": "-5.933333333",
                "total_sr": "-0.04064726",
                "total_stuffs": "0.018540476",
                "total_pass_sr": "0.044139653",
                "total_ppa": "-0.025023747"
            },
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "0.0211065210337702",
                "net_sr": "0.0109990286621741",
                "net_stuff": "0.00429663157531812",
                "net_pass_sr": "0.0262247209166231",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.07975661475813",
                "net_sr": "0.0594466210348064",
                "net_stuff": "-0.00942907550837066",
                "net_pass_sr": "0.0396971040097364",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "-0.0785287706794922",
                "net_sr": "-0.013068541219752",
                "net_stuff": "0.0594324346512789",
                "net_pass_sr": "-0.0156922570704388",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.154793788887592
    },
    {
        "id": 152,
        "name": "June Jones",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.93847158255297e-7,
            "degree": 7,
            "eigen": 0.0000880906109634391
        },
        "positions": {
            "2000": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2001": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2002": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2003": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2004": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2005": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2006": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2007": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2008": {
                "college": "SMU",
                "position": "HC"
            },
            "2009": {
                "college": "SMU",
                "position": "HC"
            },
            "2010": {
                "college": "SMU",
                "position": "HC"
            },
            "2011": {
                "college": "SMU",
                "position": "HC"
            },
            "2012": {
                "college": "SMU",
                "position": "HC"
            },
            "2013": {
                "college": "SMU",
                "position": "HC"
            },
            "2014": {
                "college": "SMU",
                "position": "HC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "SMU",
                "role": "HC",
                "net_ppa": "-0.000700758",
                "net_sr": "-0.004532773",
                "net_stuff": "0.016591798",
                "net_pass_sr": "2.93E-05",
                "net_fpi": "2.295238095",
                "total_sr": "-0.031729408",
                "total_stuffs": "0.116142586",
                "total_pass_sr": "0.00020542",
                "total_ppa": "-0.004905306"
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.000700758
    },
    {
        "id": 153,
        "name": "Greg McMackin",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.40014828807199e-7,
            "degree": 4,
            "eigen": 0.000187740147500079
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2009": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2010": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2011": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "HC",
                "net_ppa": "-0.03915891",
                "net_sr": "-0.03186494",
                "net_stuff": "0.038517424",
                "net_pass_sr": "-0.03088123",
                "net_fpi": "N/A",
                "total_sr": "-0.12745976",
                "total_stuffs": "0.154069695",
                "total_pass_sr": "-0.123524921",
                "total_ppa": "-0.156635642"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.03915891
    },
    {
        "id": 154,
        "name": "Norm Chow",
        "community": 39,
        "isWhite": false,
        "centrality": {
            "betweenness": 41,
            "closeness": 4.90737092019584e-7,
            "degree": 10,
            "eigen": 0.000291275130552176
        },
        "positions": {
            "2000": {
                "college": "NC State",
                "position": "OC"
            },
            "2001": {
                "college": "USC",
                "position": "OC"
            },
            "2002": {
                "college": "USC",
                "position": "OC"
            },
            "2003": {
                "college": "USC",
                "position": "OC"
            },
            "2004": {
                "college": "USC",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "UCLA",
                "position": "OC"
            },
            "2009": {
                "college": "UCLA",
                "position": "OC"
            },
            "2010": {
                "college": "UCLA",
                "position": "OC"
            },
            "2011": {
                "college": "Utah",
                "position": "OC"
            },
            "2012": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2013": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2014": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2015": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "HC",
                "net_ppa": "-0.138316628",
                "net_sr": "-0.079035189",
                "net_stuff": "-0.061611158",
                "net_pass_sr": "-0.073335305",
                "net_fpi": "N/A",
                "total_sr": "-0.316140755",
                "total_stuffs": "-0.246444633",
                "total_pass_sr": "-0.293341219",
                "total_ppa": "-0.553266514"
            },
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "-0.0306982849092962",
                "net_sr": "0.00469213170346322",
                "net_stuff": "-0.000396469305386421",
                "net_pass_sr": "0.0181763569241666",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "-0.0805271838915726",
                "net_sr": "-0.0574551866593012",
                "net_stuff": "0.0349905629797647",
                "net_pass_sr": "-0.0609204506284885",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.2495420968008688
    },
    {
        "id": 155,
        "name": "Nick Rolovich",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 18,
            "closeness": 4.90735647086478e-7,
            "degree": 9,
            "eigen": 0.000803606659038676
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2011": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2012": {
                "college": "Nevada",
                "position": "OC"
            },
            "2013": {
                "college": "Nevada",
                "position": "OC"
            },
            "2014": {
                "college": "Nevada",
                "position": "OC"
            },
            "2015": {
                "college": "Nevada",
                "position": "OC"
            },
            "2016": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2017": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2018": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2019": {
                "college": "Hawai'i",
                "position": "HC"
            },
            "2020": {
                "college": "Washington State",
                "position": "HC"
            },
            "2021": {
                "college": "Washington State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "HC",
                "net_ppa": "0.081550783",
                "net_sr": "0.054113082",
                "net_stuff": "0.013864917",
                "net_pass_sr": "0.070763964",
                "net_fpi": "N/A",
                "total_sr": "0.21645233",
                "total_stuffs": "0.055459667",
                "total_pass_sr": "0.283055857",
                "total_ppa": "0.326203132"
            },
            {
                "college": "Washington State",
                "role": "HC",
                "net_ppa": "-0.163404437",
                "net_sr": "-0.07163527",
                "net_stuff": "0.057455527",
                "net_pass_sr": "-0.093981239",
                "net_fpi": "N/A",
                "total_sr": "-0.143270541",
                "total_stuffs": "0.114911054",
                "total_pass_sr": "-0.187962479",
                "total_ppa": "-0.326808875"
            },
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "0.0190555334985911",
                "net_sr": "0.000566920759980383",
                "net_stuff": "0.014356430928943",
                "net_pass_sr": "0.00260503045750277",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "-0.024142899154118",
                "net_sr": "-0.0521478142629542",
                "net_stuff": "0.0287864539856534",
                "net_pass_sr": "-0.0150910080901289",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.08694101965552692
    },
    {
        "id": 156,
        "name": "Dana Dimel",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 23,
            "closeness": 4.98054846795839e-7,
            "degree": 9,
            "eigen": 0.00235422826771704
        },
        "positions": {
            "2000": {
                "college": "Houston",
                "position": "HC"
            },
            "2001": {
                "college": "Houston",
                "position": "HC"
            },
            "2002": {
                "college": "Houston",
                "position": "HC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2010": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2011": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2012": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2013": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2014": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2015": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2016": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2017": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2018": {
                "college": "UTEP",
                "position": "HC"
            },
            "2019": {
                "college": "UTEP",
                "position": "HC"
            },
            "2020": {
                "college": "UTEP",
                "position": "HC"
            },
            "2021": {
                "college": "UTEP",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "UTEP",
                "role": "HC",
                "net_ppa": "0.14708803",
                "net_sr": "0.034089647",
                "net_stuff": "-0.004847273",
                "net_pass_sr": "0.0535427",
                "net_fpi": "-0.825",
                "total_sr": "0.136358588",
                "total_stuffs": "-0.019389093",
                "total_pass_sr": "0.214170802",
                "total_ppa": "0.588352119"
            },
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "0.0980781503106076",
                "net_sr": "0.0438902652175449",
                "net_stuff": "-0.0275618395621298",
                "net_pass_sr": "0.0224703849620742",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2451661803106076
    },
    {
        "id": 157,
        "name": "Tony Levine",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90392716295063e-7,
            "degree": 5,
            "eigen": 0.0000323764084243095
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Houston",
                "position": "HC"
            },
            "2013": {
                "college": "Houston",
                "position": "HC"
            },
            "2014": {
                "college": "Houston",
                "position": "HC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "HC",
                "net_ppa": "-0.038284703",
                "net_sr": "-0.061638353",
                "net_stuff": "0.054269016",
                "net_pass_sr": "-0.103653963",
                "net_fpi": "-5.466666667",
                "total_sr": "-0.18491506",
                "total_stuffs": "0.162807048",
                "total_pass_sr": "-0.31096189",
                "total_ppa": "-0.114854109"
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.038284703
    },
    {
        "id": 158,
        "name": "Tom Herman",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 224.5,
            "closeness": 4.96645655244479e-7,
            "degree": 11,
            "eigen": 0.00318908691278306
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Rice",
                "position": "OC"
            },
            "2008": {
                "college": "Rice",
                "position": "OC"
            },
            "2009": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2010": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2011": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2012": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2013": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2014": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2015": {
                "college": "Houston",
                "position": "HC"
            },
            "2016": {
                "college": "Houston",
                "position": "HC"
            },
            "2017": {
                "college": "Texas",
                "position": "HC"
            },
            "2018": {
                "college": "Texas",
                "position": "HC"
            },
            "2019": {
                "college": "Texas",
                "position": "HC"
            },
            "2020": {
                "college": "Texas",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "HC",
                "net_ppa": "0.08595811",
                "net_sr": "0.077224014",
                "net_stuff": "-0.08698955",
                "net_pass_sr": "0.098587422",
                "net_fpi": "7.8",
                "total_sr": "0.154448028",
                "total_stuffs": "-0.1739791",
                "total_pass_sr": "0.197174845",
                "total_ppa": "0.171916219"
            },
            {
                "college": "Texas",
                "role": "HC",
                "net_ppa": "0.078797427",
                "net_sr": "0.029565917",
                "net_stuff": "-0.061153319",
                "net_pass_sr": "0.02250661",
                "net_fpi": "6.383333333",
                "total_sr": "0.11826367",
                "total_stuffs": "-0.244613276",
                "total_pass_sr": "0.090026441",
                "total_ppa": "0.315189709"
            },
            {
                "college": "Iowa State",
                "role": "OC",
                "net_ppa": "-0.00141052452776059",
                "net_sr": "-0.0016040091654147",
                "net_stuff": "-0.00638483721193331",
                "net_pass_sr": "-0.028867238588118",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "0.154135121541973",
                "net_sr": "0.0862420986041211",
                "net_stuff": "-0.0373193186315859",
                "net_pass_sr": "0.0713055522859222",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.0272086349248203",
                "net_sr": "0.0556801618298861",
                "net_stuff": "0.0353279008867143",
                "net_pass_sr": "0.129743741431386",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.3446887689390327
    },
    {
        "id": 159,
        "name": "Major Applewhite",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 110,
            "closeness": 4.90049303860461e-7,
            "degree": 9,
            "eigen": 0.000984678300280734
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Rice",
                "position": "OC"
            },
            "2007": {
                "college": "Alabama",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Texas",
                "position": "OC"
            },
            "2012": {
                "college": "Texas",
                "position": "OC"
            },
            "2013": {
                "college": "Texas",
                "position": "OC"
            },
            "2014": false,
            "2015": {
                "college": "Houston",
                "position": "OC"
            },
            "2016": {
                "college": "Houston",
                "position": "OC"
            },
            "2017": {
                "college": "Houston",
                "position": "HC"
            },
            "2018": {
                "college": "Houston",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": {
                "college": "South Alabama",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Houston",
                "role": "HC",
                "net_ppa": "-0.036710419",
                "net_sr": "-0.059775889",
                "net_stuff": "0.021783269",
                "net_pass_sr": "-0.047185629",
                "net_fpi": "-7.583333333",
                "total_sr": "-0.119551778",
                "total_stuffs": "0.043566539",
                "total_pass_sr": "-0.094371258",
                "total_ppa": "-0.073420838"
            },
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "-0.0194500482061659",
                "net_sr": "0.0147343921408335",
                "net_stuff": "-0.00730322505828787",
                "net_pass_sr": "0.00919775845133436",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "0.114388481809753",
                "net_sr": "0.0305320548612608",
                "net_stuff": "-0.0302869416947633",
                "net_pass_sr": "0.0673823834939596",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "-0.00820094991773786",
                "net_sr": "0.00468417725156622",
                "net_stuff": "0.0312637204584336",
                "net_pass_sr": "0.112011517893871",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "0.0798617233249117",
                "net_sr": "0.0276628705386214",
                "net_stuff": "0.00719861387050033",
                "net_pass_sr": "0.0776084517547097",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "-0.0389413523716204",
                "net_sr": "-0.0299310833985661",
                "net_stuff": "-0.0113777784251237",
                "net_pass_sr": "-0.0279583546945208",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.09094743563914053
    },
    {
        "id": 160,
        "name": "Dana Holgorsen",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 66,
            "closeness": 4.93503276121499e-7,
            "degree": 14,
            "eigen": 0.00088859354315237
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2006": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2007": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2008": {
                "college": "Houston",
                "position": "OC"
            },
            "2009": {
                "college": "Houston",
                "position": "OC"
            },
            "2010": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2011": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2012": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2013": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2014": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2015": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2016": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2017": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2018": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2019": {
                "college": "Houston",
                "position": "HC"
            },
            "2020": {
                "college": "Houston",
                "position": "HC"
            },
            "2021": {
                "college": "Houston",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Houston",
                "role": "HC",
                "net_ppa": "-0.085380554",
                "net_sr": "0.003909425",
                "net_stuff": "-0.006875447",
                "net_pass_sr": "-0.012695098",
                "net_fpi": "-1.733333333",
                "total_sr": "0.011728276",
                "total_stuffs": "-0.020626341",
                "total_pass_sr": "-0.038085294",
                "total_ppa": "-0.256141661"
            },
            {
                "college": "West Virginia",
                "role": "HC",
                "net_ppa": "0.017318129",
                "net_sr": "0.005625186",
                "net_stuff": "-0.047420659",
                "net_pass_sr": "0.012709962",
                "net_fpi": "-0.829166667",
                "total_sr": "0.045001491",
                "total_stuffs": "-0.37936527",
                "total_pass_sr": "0.101679699",
                "total_ppa": "0.138545033"
            },
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "0.0887425147781826",
                "net_sr": "0.0893177009353515",
                "net_stuff": "-0.0412133140412301",
                "net_pass_sr": "0.114464962492012",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "0.0504696712718111",
                "net_sr": "0.0321213776604855",
                "net_stuff": "-0.0239351779542896",
                "net_pass_sr": "0.0625534212276863",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.07114976104999371
    },
    {
        "id": 161,
        "name": "Tim Beckman",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 126.5,
            "closeness": 4.99821813523479e-7,
            "degree": 8,
            "eigen": 0.00114368871254913
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2002": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2008": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2009": {
                "college": "Toledo",
                "position": "HC"
            },
            "2010": {
                "college": "Toledo",
                "position": "HC"
            },
            "2011": {
                "college": "Toledo",
                "position": "HC"
            },
            "2012": {
                "college": "Illinois",
                "position": "HC"
            },
            "2013": {
                "college": "Illinois",
                "position": "HC"
            },
            "2014": {
                "college": "Illinois",
                "position": "HC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "HC",
                "net_ppa": "-0.10570241",
                "net_sr": "-0.052511353",
                "net_stuff": "0.087975313",
                "net_pass_sr": "0.003340846",
                "net_fpi": "-9.866666667",
                "total_sr": "-0.157534058",
                "total_stuffs": "0.26392594",
                "total_pass_sr": "0.010022537",
                "total_ppa": "-0.317107231"
            },
            {
                "college": "Toledo",
                "role": "HC",
                "net_ppa": "0.03263415",
                "net_sr": "0.042331632",
                "net_stuff": "-0.012521469",
                "net_pass_sr": "0.067210683",
                "net_fpi": "6.6",
                "total_sr": "0.126994895",
                "total_stuffs": "-0.037564406",
                "total_pass_sr": "0.201632048",
                "total_ppa": "0.09790245"
            },
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "-0.0575477118975431",
                "net_sr": "-0.0126323641442356",
                "net_stuff": "-0.0351967751253047",
                "net_pass_sr": "-0.0293671316634824",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1306159718975431
    },
    {
        "id": 162,
        "name": "Bill Cubit",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 177,
            "closeness": 4.94895401382441e-7,
            "degree": 13,
            "eigen": 0.000298474489031471
        },
        "positions": {
            "2000": {
                "college": "Missouri",
                "position": "OC"
            },
            "2001": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2002": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2003": false,
            "2004": {
                "college": "Stanford",
                "position": "OC"
            },
            "2005": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2006": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2007": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2008": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2009": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2010": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2011": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2012": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2013": {
                "college": "Illinois",
                "position": "OC"
            },
            "2014": {
                "college": "Illinois",
                "position": "OC"
            },
            "2015": {
                "college": "Illinois",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "HC",
                "net_ppa": "0.072245989",
                "net_sr": "0.02114847",
                "net_stuff": "-0.090320751",
                "net_pass_sr": "0.009976857",
                "net_fpi": "7.766666667",
                "total_sr": "0.02114847",
                "total_stuffs": "-0.090320751",
                "total_pass_sr": "0.009976857",
                "total_ppa": "0.072245989"
            },
            {
                "college": "Western Michigan",
                "role": "HC",
                "net_ppa": "0.066509038",
                "net_sr": "0.046407731",
                "net_stuff": "-0.019198139",
                "net_pass_sr": "0.037306413",
                "net_fpi": "N/A",
                "total_sr": "0.324854119",
                "total_stuffs": "-0.134386973",
                "total_pass_sr": "0.26114489",
                "total_ppa": "0.465563263"
            },
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "0.0645835793515201",
                "net_sr": "0.0171603297841894",
                "net_stuff": "0.0338335137635771",
                "net_pass_sr": "0.0418204741989239",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.20333860635152012
    },
    {
        "id": 163,
        "name": "Lovie Smith",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.01248359038185e-7,
            "degree": 3,
            "eigen": 0.000236565127849293
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Illinois",
                "position": "HC"
            },
            "2017": {
                "college": "Illinois",
                "position": "HC"
            },
            "2018": {
                "college": "Illinois",
                "position": "HC"
            },
            "2019": {
                "college": "Illinois",
                "position": "HC"
            },
            "2020": {
                "college": "Illinois",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "HC",
                "net_ppa": "-0.079223315",
                "net_sr": "-0.054986859",
                "net_stuff": "0.012969251",
                "net_pass_sr": "-0.100779387",
                "net_fpi": "-5.173333333",
                "total_sr": "-0.274934297",
                "total_stuffs": "0.064846257",
                "total_pass_sr": "-0.503896933",
                "total_ppa": "-0.396116574"
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.079223315
    },
    {
        "id": 164,
        "name": "Cam Cameron",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 2,
            "closeness": 4.89363201453213e-7,
            "degree": 3,
            "eigen": 0.00207738601123342
        },
        "positions": {
            "2000": {
                "college": "Indiana",
                "position": "HC"
            },
            "2001": {
                "college": "Indiana",
                "position": "HC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "LSU",
                "position": "OC"
            },
            "2014": {
                "college": "LSU",
                "position": "OC"
            },
            "2015": {
                "college": "LSU",
                "position": "OC"
            },
            "2016": {
                "college": "LSU",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "0.180421417868809",
                "net_sr": "0.024969724094613",
                "net_stuff": "-0.0017853886476662",
                "net_pass_sr": "0.0224123076589519",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.180421417868809
    },
    {
        "id": 167,
        "name": "Kevin Wilson",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 112.5,
            "closeness": 4.97700375415393e-7,
            "degree": 11,
            "eigen": 0.0154432422996183
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2003": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2004": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2005": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2006": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2007": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2008": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2009": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2010": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2011": {
                "college": "Indiana",
                "position": "HC"
            },
            "2012": {
                "college": "Indiana",
                "position": "HC"
            },
            "2013": {
                "college": "Indiana",
                "position": "HC"
            },
            "2014": {
                "college": "Indiana",
                "position": "HC"
            },
            "2015": {
                "college": "Indiana",
                "position": "HC"
            },
            "2016": {
                "college": "Indiana",
                "position": "HC"
            },
            "2017": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2018": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2019": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2020": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2021": {
                "college": "Ohio State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "HC",
                "net_ppa": "0.067423216",
                "net_sr": "0.040629696",
                "net_stuff": "-0.039950596",
                "net_pass_sr": "0.040694213",
                "net_fpi": "5.4",
                "total_sr": "0.243778175",
                "total_stuffs": "-0.239703577",
                "total_pass_sr": "0.244165275",
                "total_ppa": "0.404539294"
            },
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "0.0858857885488455",
                "net_sr": "0.0122127631148929",
                "net_stuff": "-0.00062879790680645",
                "net_pass_sr": "0.0772887540940058",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma",
                "role": "OC",
                "net_ppa": "0.0346690114465173",
                "net_sr": "0.0701580000044169",
                "net_stuff": "-0.0599183778455824",
                "net_pass_sr": "0.0944266334128431",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 20,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1879780159953628
    },
    {
        "id": 168,
        "name": "Tom Allen",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 22,
            "closeness": 4.96998131287026e-7,
            "degree": 10,
            "eigen": 0.000163782422373827
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "South Florida",
                "position": "DC"
            },
            "2016": false,
            "2017": {
                "college": "Indiana",
                "position": "HC"
            },
            "2018": {
                "college": "Indiana",
                "position": "HC"
            },
            "2019": {
                "college": "Indiana",
                "position": "HC"
            },
            "2020": {
                "college": "Indiana",
                "position": "HC"
            },
            "2021": {
                "college": "Indiana",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "HC",
                "net_ppa": "-0.047532085",
                "net_sr": "-0.020525739",
                "net_stuff": "0.015834844",
                "net_pass_sr": "-0.015737603",
                "net_fpi": "4.06",
                "total_sr": "-0.102628693",
                "total_stuffs": "0.079174221",
                "total_pass_sr": "-0.078688016",
                "total_ppa": "-0.237660425"
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.0324068095938144",
                "net_sr": "-0.00377896691349916",
                "net_stuff": "-0.0794510674882599",
                "net_pass_sr": "-0.0753364696454293",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0151252754061856
    },
    {
        "id": 170,
        "name": "Dan McCarney",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.91423432543534e-7,
            "degree": 8,
            "eigen": 0.00596331740954472
        },
        "positions": {
            "2000": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2001": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2002": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2003": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2004": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2005": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2006": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "North Texas",
                "position": "HC"
            },
            "2012": {
                "college": "North Texas",
                "position": "HC"
            },
            "2013": {
                "college": "North Texas",
                "position": "HC"
            },
            "2014": {
                "college": "North Texas",
                "position": "HC"
            },
            "2015": {
                "college": "North Texas",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "HC",
                "net_ppa": "-0.09055403",
                "net_sr": "-0.03067854",
                "net_stuff": "0.00489017",
                "net_pass_sr": "-0.014696869",
                "net_fpi": "7.506666667",
                "total_sr": "-0.1533927",
                "total_stuffs": "0.024450848",
                "total_pass_sr": "-0.073484346",
                "total_ppa": "-0.45277015"
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.09055403
    },
    {
        "id": 171,
        "name": "Paul Rhoads",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 321,
            "closeness": 5.08137569100358e-7,
            "degree": 9,
            "eigen": 0.00130216989465703
        },
        "positions": {
            "2000": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2001": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2002": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2003": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2004": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2005": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2006": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2007": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2008": {
                "college": "Auburn",
                "position": "DC"
            },
            "2009": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2010": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2011": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2012": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2013": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2014": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2015": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2016": false,
            "2017": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Arizona",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Iowa State",
                "role": "HC",
                "net_ppa": "0.018632569",
                "net_sr": "0.006458874",
                "net_stuff": "0.018104852",
                "net_pass_sr": "0.003431796",
                "net_fpi": "4.842857143",
                "total_sr": "0.045212121",
                "total_stuffs": "0.126733961",
                "total_pass_sr": "0.024022575",
                "total_ppa": "0.130427985"
            },
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "0.0178917183248847",
                "net_sr": "0.0100386026221599",
                "net_stuff": "-0.046389875582079",
                "net_pass_sr": "0.0121496279654342",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "-0.243671047737936",
                "net_sr": "-0.0613113474500447",
                "net_stuff": "0.056435658189807",
                "net_pass_sr": "-0.0705501632607829",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.0271785751780193",
                "net_sr": "-0.00492966906611486",
                "net_stuff": "-0.0270491013854481",
                "net_pass_sr": "0.00550438320035701",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "0.0353837189677645",
                "net_sr": "0.0350964264039457",
                "net_stuff": "-0.00878267462915461",
                "net_pass_sr": "0.0545920570955719",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 18,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.19894161662330612
    },
    {
        "id": 172,
        "name": "Matt Campbell",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 44,
            "closeness": 4.91762002926967e-7,
            "degree": 5,
            "eigen": 0.000175230464740858
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Toledo",
                "position": "OC"
            },
            "2011": {
                "college": "Toledo",
                "position": "OC"
            },
            "2012": {
                "college": "Toledo",
                "position": "HC"
            },
            "2013": {
                "college": "Toledo",
                "position": "HC"
            },
            "2014": {
                "college": "Toledo",
                "position": "HC"
            },
            "2015": {
                "college": "Toledo",
                "position": "HC"
            },
            "2016": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2017": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2018": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2019": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2020": {
                "college": "Iowa State",
                "position": "HC"
            },
            "2021": {
                "college": "Iowa State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Iowa State",
                "role": "HC",
                "net_ppa": "0.182756805",
                "net_sr": "0.08939183",
                "net_stuff": "-0.039532577",
                "net_pass_sr": "0.113405414",
                "net_fpi": "12.46666667",
                "total_sr": "0.536350978",
                "total_stuffs": "-0.237195464",
                "total_pass_sr": "0.680432482",
                "total_ppa": "1.09654083"
            },
            {
                "college": "Toledo",
                "role": "HC",
                "net_ppa": "0.01877506",
                "net_sr": "0.037781567",
                "net_stuff": "-0.04383347",
                "net_pass_sr": "-0.022375252",
                "net_fpi": "5.766666667",
                "total_sr": "0.151126269",
                "total_stuffs": "-0.175333881",
                "total_pass_sr": "-0.089501007",
                "total_ppa": "0.07510024"
            },
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "-0.0209561279075327",
                "net_sr": "0.000143700871026498",
                "net_stuff": "0.0208304002856624",
                "net_pass_sr": "0.0175564555791902",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1805757370924673
    },
    {
        "id": 174,
        "name": "Mark Mangino",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 220,
            "closeness": 4.99475550671795e-7,
            "degree": 8,
            "eigen": 0.00395534808504944
        },
        "positions": {
            "2000": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2001": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2002": {
                "college": "Kansas",
                "position": "HC"
            },
            "2003": {
                "college": "Kansas",
                "position": "HC"
            },
            "2004": {
                "college": "Kansas",
                "position": "HC"
            },
            "2005": {
                "college": "Kansas",
                "position": "HC"
            },
            "2006": {
                "college": "Kansas",
                "position": "HC"
            },
            "2007": {
                "college": "Kansas",
                "position": "HC"
            },
            "2008": {
                "college": "Kansas",
                "position": "HC"
            },
            "2009": {
                "college": "Kansas",
                "position": "HC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2015": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Iowa State",
                "role": "OC",
                "net_ppa": "0.0937536679019084",
                "net_sr": "0.0359695460270675",
                "net_stuff": "-0.0252977987059957",
                "net_pass_sr": "0.0194765592966509",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0937536679019084
    },
    {
        "id": 175,
        "name": "Charlie Weis",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 676,
            "closeness": 4.95595887148852e-7,
            "degree": 8,
            "eigen": 0.000148095377619207
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2006": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2007": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2008": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2009": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2010": false,
            "2011": {
                "college": "Florida",
                "position": "OC"
            },
            "2012": {
                "college": "Kansas",
                "position": "HC"
            },
            "2013": {
                "college": "Kansas",
                "position": "HC"
            },
            "2014": {
                "college": "Kansas",
                "position": "HC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "HC",
                "net_ppa": "-0.065854466",
                "net_sr": "-0.023836917",
                "net_stuff": "0.01116316",
                "net_pass_sr": "-0.058162216",
                "net_fpi": "-3.066666667",
                "total_sr": "-0.071510751",
                "total_stuffs": "0.03348948",
                "total_pass_sr": "-0.174486649",
                "total_ppa": "-0.197563399"
            },
            {
                "college": "Notre Dame",
                "role": "HC",
                "net_ppa": "0.052340221",
                "net_sr": "0.082885093",
                "net_stuff": "-0.076028443",
                "net_pass_sr": "0.055284807",
                "net_fpi": "5.033333333",
                "total_sr": "0.082885093",
                "total_stuffs": "-0.076028443",
                "total_pass_sr": "0.055284807",
                "total_ppa": "0.052340221"
            },
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "-0.113356695621202",
                "net_sr": "-0.0511715945817273",
                "net_stuff": "0.0647555352546167",
                "net_pass_sr": "-0.0431275812695752",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.126870940621202
    },
    {
        "id": 176,
        "name": "David Beaty",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 14,
            "closeness": 4.90047862974777e-7,
            "degree": 6,
            "eigen": 0.000278757385794858
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Rice",
                "position": "OC"
            },
            "2011": {
                "college": "Kansas",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Kansas",
                "position": "HC"
            },
            "2016": {
                "college": "Kansas",
                "position": "HC"
            },
            "2017": {
                "college": "Kansas",
                "position": "HC"
            },
            "2018": {
                "college": "Kansas",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "HC",
                "net_ppa": "-0.059694462",
                "net_sr": "-0.041202103",
                "net_stuff": "-0.010428954",
                "net_pass_sr": "-0.005172683",
                "net_fpi": "-4.325",
                "total_sr": "-0.164808411",
                "total_stuffs": "-0.041715814",
                "total_pass_sr": "-0.020690733",
                "total_ppa": "-0.238777848"
            },
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.0543310154640809",
                "net_sr": "-0.0285841144793796",
                "net_stuff": "0.0303459193484141",
                "net_pass_sr": "-0.0313692082808106",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.0187490088419185",
                "net_sr": "-0.018439801147242",
                "net_stuff": "-0.0324663421726173",
                "net_pass_sr": "-0.00972784026820622",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0952764686221624
    },
    {
        "id": 177,
        "name": "Les Miles",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.87514724587785e-7,
            "degree": 16,
            "eigen": 0.0114167761227062
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Oklahoma State",
                "position": "HC"
            },
            "2002": {
                "college": "Oklahoma State",
                "position": "HC"
            },
            "2003": {
                "college": "Oklahoma State",
                "position": "HC"
            },
            "2004": {
                "college": "Oklahoma State",
                "position": "HC"
            },
            "2005": {
                "college": "LSU",
                "position": "HC"
            },
            "2006": {
                "college": "LSU",
                "position": "HC"
            },
            "2007": {
                "college": "LSU",
                "position": "HC"
            },
            "2008": {
                "college": "LSU",
                "position": "HC"
            },
            "2009": {
                "college": "LSU",
                "position": "HC"
            },
            "2010": {
                "college": "LSU",
                "position": "HC"
            },
            "2011": {
                "college": "LSU",
                "position": "HC"
            },
            "2012": {
                "college": "LSU",
                "position": "HC"
            },
            "2013": {
                "college": "LSU",
                "position": "HC"
            },
            "2014": {
                "college": "LSU",
                "position": "HC"
            },
            "2015": {
                "college": "LSU",
                "position": "HC"
            },
            "2016": {
                "college": "LSU",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Kansas",
                "position": "HC"
            },
            "2020": {
                "college": "Kansas",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "HC",
                "net_ppa": "-0.041056916",
                "net_sr": "-0.023830433",
                "net_stuff": "0.096633275",
                "net_pass_sr": "-0.00918728",
                "net_fpi": "-2.583333333",
                "total_sr": "-0.047660866",
                "total_stuffs": "0.193266551",
                "total_pass_sr": "-0.018374559",
                "total_ppa": "-0.082113833"
            }
        ],
        "yearsCoached": 18,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.041056916
    },
    {
        "id": 179,
        "name": "Ron Prince",
        "community": 16,
        "isWhite": false,
        "centrality": {
            "betweenness": 96,
            "closeness": 4.9454051993023e-7,
            "degree": 6,
            "eigen": 0.000252920601959569
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Virginia",
                "position": "OC"
            },
            "2004": {
                "college": "Virginia",
                "position": "OC"
            },
            "2005": {
                "college": "Virginia",
                "position": "OC"
            },
            "2006": {
                "college": "Kansas State",
                "position": "HC"
            },
            "2007": {
                "college": "Kansas State",
                "position": "HC"
            },
            "2008": {
                "college": "Kansas State",
                "position": "HC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "HC",
                "net_ppa": "0.048303834",
                "net_sr": "0.079714024",
                "net_stuff": "-0.095336589",
                "net_pass_sr": "0.113663055",
                "net_fpi": "4",
                "total_sr": "0.239142071",
                "total_stuffs": "-0.286009767",
                "total_pass_sr": "0.340989166",
                "total_ppa": "0.144911503"
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.0675260733617693",
                "net_sr": "0.0485105369799406",
                "net_stuff": "-0.0466629592034468",
                "net_pass_sr": "0.0512288263267238",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.11582990736176929
    },
    {
        "id": 180,
        "name": "Chris Klieman",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89705652623378e-7,
            "degree": 3,
            "eigen": 0.000622771103391747
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Kansas State",
                "position": "HC"
            },
            "2020": {
                "college": "Kansas State",
                "position": "HC"
            },
            "2021": {
                "college": "Kansas State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "HC",
                "net_ppa": "-0.006633129",
                "net_sr": "0.016228034",
                "net_stuff": "0.022879904",
                "net_pass_sr": "0.05140934",
                "net_fpi": "-1.7",
                "total_sr": "0.048684102",
                "total_stuffs": "0.068639713",
                "total_pass_sr": "0.154228019",
                "total_ppa": "-0.019899388"
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.006633129
    },
    {
        "id": 182,
        "name": "Doug Martin",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 214,
            "closeness": 4.94544433086407e-7,
            "degree": 11,
            "eigen": 0.0000619429916074356
        },
        "positions": {
            "2000": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2001": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2002": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2003": {
                "college": "Kent State",
                "position": "OC"
            },
            "2004": {
                "college": "Kent State",
                "position": "HC"
            },
            "2005": {
                "college": "Kent State",
                "position": "HC"
            },
            "2006": {
                "college": "Kent State",
                "position": "HC"
            },
            "2007": {
                "college": "Kent State",
                "position": "HC"
            },
            "2008": {
                "college": "Kent State",
                "position": "HC"
            },
            "2009": {
                "college": "Kent State",
                "position": "HC"
            },
            "2010": {
                "college": "Kent State",
                "position": "HC"
            },
            "2011": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2012": {
                "college": "Boston College",
                "position": "OC"
            },
            "2013": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2014": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2015": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2016": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2017": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2018": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2019": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2020": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2021": {
                "college": "New Mexico State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "New Mexico State",
                "role": "HC",
                "net_ppa": "-0.050514624",
                "net_sr": "-0.006759619",
                "net_stuff": "0.011665626",
                "net_pass_sr": "-0.01808323",
                "net_fpi": "N/A",
                "total_sr": "-0.054076955",
                "total_stuffs": "0.093325011",
                "total_pass_sr": "-0.144665839",
                "total_ppa": "-0.404116989"
            },
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "0.0349835539633592",
                "net_sr": "0.0146478933237585",
                "net_stuff": "0.0611781355712644",
                "net_pass_sr": "0.0230957673167728",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "0.107513360618428",
                "net_sr": "0.0303113699594436",
                "net_stuff": "0.0188669216506244",
                "net_pass_sr": "0.0475023999538338",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 22,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0919822905817872
    },
    {
        "id": 183,
        "name": "Darrell Hazell",
        "community": 10,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90392235325503e-7,
            "degree": 5,
            "eigen": 0.00015836289640146
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Kent State",
                "position": "HC"
            },
            "2012": {
                "college": "Kent State",
                "position": "HC"
            },
            "2013": {
                "college": "Purdue",
                "position": "HC"
            },
            "2014": {
                "college": "Purdue",
                "position": "HC"
            },
            "2015": {
                "college": "Purdue",
                "position": "HC"
            },
            "2016": {
                "college": "Purdue",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kent State",
                "role": "HC",
                "net_ppa": "-0.045818806",
                "net_sr": "-0.058901879",
                "net_stuff": "-0.002818728",
                "net_pass_sr": "-0.047763049",
                "net_fpi": "5.4",
                "total_sr": "-0.117803758",
                "total_stuffs": "-0.005637457",
                "total_pass_sr": "-0.095526097",
                "total_ppa": "-0.091637612"
            },
            {
                "college": "Purdue",
                "role": "HC",
                "net_ppa": "-0.05150812",
                "net_sr": "-0.036754108",
                "net_stuff": "0.024721394",
                "net_pass_sr": "-0.036832782",
                "net_fpi": "-6.183333333",
                "total_sr": "-0.147016431",
                "total_stuffs": "0.098885577",
                "total_pass_sr": "-0.147331126",
                "total_ppa": "-0.206032482"
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.097326926
    },
    {
        "id": 184,
        "name": "Paul Haynes",
        "community": 11,
        "isWhite": false,
        "centrality": {
            "betweenness": 8,
            "closeness": 4.91422708053632e-7,
            "degree": 6,
            "eigen": 0.000218721873853094
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2013": {
                "college": "Kent State",
                "position": "HC"
            },
            "2014": {
                "college": "Kent State",
                "position": "HC"
            },
            "2015": {
                "college": "Kent State",
                "position": "HC"
            },
            "2016": {
                "college": "Kent State",
                "position": "HC"
            },
            "2017": {
                "college": "Kent State",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kent State",
                "role": "HC",
                "net_ppa": "-0.121738015",
                "net_sr": "-0.039549924",
                "net_stuff": "0.089039677",
                "net_pass_sr": "0.006796252",
                "net_fpi": "-9.046666667",
                "total_sr": "-0.197749621",
                "total_stuffs": "0.445198384",
                "total_pass_sr": "0.033981259",
                "total_ppa": "-0.608690074"
            },
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "0.00258654656939223",
                "net_sr": "0.0137943506627753",
                "net_stuff": "0.00459700524882919",
                "net_pass_sr": "0.0156522309075907",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.11915146843060778
    },
    {
        "id": 185,
        "name": "Sean Lewis",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 6,
            "closeness": 4.89362483025239e-7,
            "degree": 3,
            "eigen": 0.0000332902696591771
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2016": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2017": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2018": {
                "college": "Kent State",
                "position": "HC"
            },
            "2019": {
                "college": "Kent State",
                "position": "HC"
            },
            "2020": {
                "college": "Kent State",
                "position": "HC"
            },
            "2021": {
                "college": "Kent State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Kent State",
                "role": "HC",
                "net_ppa": "0.185781243",
                "net_sr": "0.079944309",
                "net_stuff": "-0.028637268",
                "net_pass_sr": "0.084478725",
                "net_fpi": "7.1",
                "total_sr": "0.319777237",
                "total_stuffs": "-0.114549073",
                "total_pass_sr": "0.337914901",
                "total_ppa": "0.743124972"
            },
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "0.245066859155435",
                "net_sr": "0.0674815859863831",
                "net_stuff": "-0.0528658649836123",
                "net_pass_sr": "0.0886979016204008",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "0.073552744886937",
                "net_sr": "0.0181374343998835",
                "net_stuff": "-0.0279089699707622",
                "net_pass_sr": "0.0544724880356613",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.504400847042372
    },
    {
        "id": 186,
        "name": "Hal Mumme",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 8,
            "closeness": 4.91423674040643e-7,
            "degree": 7,
            "eigen": 0.000324515750138169
        },
        "positions": {
            "2000": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2006": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2007": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2008": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "SMU",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico State",
                "role": "HC",
                "net_ppa": "0.111651654",
                "net_sr": "0.087359241",
                "net_stuff": "-0.006719058",
                "net_pass_sr": "0.194446813",
                "net_fpi": "N/A",
                "total_sr": "0.262077724",
                "total_stuffs": "-0.020157174",
                "total_pass_sr": "0.583340438",
                "total_ppa": "0.334954961"
            },
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "0.0359249670128298",
                "net_sr": "0.015579171766831",
                "net_stuff": "0.0157710036215218",
                "net_pass_sr": "0.0416912521978932",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1475766210128298
    },
    {
        "id": 188,
        "name": "Joker Phillips",
        "community": 28,
        "isWhite": false,
        "centrality": {
            "betweenness": 54.5,
            "closeness": 4.93152819675219e-7,
            "degree": 5,
            "eigen": 0.000338055012439279
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2006": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2007": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2008": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2009": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2010": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2011": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2012": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Maryland",
                "position": "OC"
            },
            "2020": {
                "college": "Maryland",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Kentucky",
                "role": "HC",
                "net_ppa": "-0.039546523",
                "net_sr": "-0.057339364",
                "net_stuff": "0.046830935",
                "net_pass_sr": "-0.045054057",
                "net_fpi": "-7.1",
                "total_sr": "-0.172018093",
                "total_stuffs": "0.140492806",
                "total_pass_sr": "-0.135162172",
                "total_ppa": "-0.118639569"
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.0385544826741796",
                "net_sr": "-0.0107738747469128",
                "net_stuff": "0.0725996023611732",
                "net_pass_sr": "-0.0946965953085309",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0781010056741796
    },
    {
        "id": 189,
        "name": "Mark Stoops",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 214,
            "closeness": 4.95240491258758e-7,
            "degree": 12,
            "eigen": 0.00594964399358685
        },
        "positions": {
            "2000": {
                "college": "Houston",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Arizona",
                "position": "DC"
            },
            "2005": {
                "college": "Arizona",
                "position": "DC"
            },
            "2006": {
                "college": "Arizona",
                "position": "DC"
            },
            "2007": {
                "college": "Arizona",
                "position": "DC"
            },
            "2008": {
                "college": "Arizona",
                "position": "DC"
            },
            "2009": {
                "college": "Arizona",
                "position": "DC"
            },
            "2010": {
                "college": "Florida State",
                "position": "DC"
            },
            "2011": {
                "college": "Florida State",
                "position": "DC"
            },
            "2012": {
                "college": "Florida State",
                "position": "DC"
            },
            "2013": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2014": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2015": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2016": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2017": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2018": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2019": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2020": {
                "college": "Kentucky",
                "position": "HC"
            },
            "2021": {
                "college": "Kentucky",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Kentucky",
                "role": "HC",
                "net_ppa": "0.074209615",
                "net_sr": "0.022050213",
                "net_stuff": "-0.019664415",
                "net_pass_sr": "-0.012439002",
                "net_fpi": "5.755555556",
                "total_sr": "0.198451914",
                "total_stuffs": "-0.176979737",
                "total_pass_sr": "-0.111951014",
                "total_ppa": "0.667886532"
            },
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "0.0523318437013707",
                "net_sr": "0.0201333911376038",
                "net_stuff": "0.0377294167618878",
                "net_pass_sr": "0.0204523936974885",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1265414587013707
    },
    {
        "id": 192,
        "name": "Mark Hudspeth",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 9,
            "closeness": 4.9177022527994e-7,
            "degree": 9,
            "eigen": 0.000345263679825963
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Navy",
                "position": "OC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2012": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2013": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2014": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2015": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2016": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2017": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "HC",
                "net_ppa": "0.031650259",
                "net_sr": "0.046376702",
                "net_stuff": "-0.048810667",
                "net_pass_sr": "0.011520341",
                "net_fpi": "4.89047619",
                "total_sr": "0.324636912",
                "total_stuffs": "-0.341674668",
                "total_pass_sr": "0.080642386",
                "total_ppa": "0.221551816"
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.031650259
    },
    {
        "id": 193,
        "name": "Billy Napier",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 50,
            "closeness": 4.90392235325503e-7,
            "degree": 7,
            "eigen": 0.000964271392828333
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Clemson",
                "position": "OC"
            },
            "2010": {
                "college": "Clemson",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2018": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2019": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2020": {
                "college": "Louisiana",
                "position": "HC"
            },
            "2021": {
                "college": "Louisiana",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "HC",
                "net_ppa": "0.165269406",
                "net_sr": "0.06126727",
                "net_stuff": "0.035992606",
                "net_pass_sr": "0.128228145",
                "net_fpi": "14.975",
                "total_sr": "0.24506908",
                "total_stuffs": "0.143970424",
                "total_pass_sr": "0.512912582",
                "total_ppa": "0.661077623"
            },
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.0305830271617039",
                "net_sr": "0.0160812601957124",
                "net_stuff": "-0.0238017772072979",
                "net_pass_sr": "0.010035643648912",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Clemson",
                "role": "OC",
                "net_ppa": "-0.0633621097925848",
                "net_sr": "-0.031286571817315",
                "net_stuff": "0.00928469046921201",
                "net_pass_sr": "-0.0145569218284",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.13249032336911912
    },
    {
        "id": 196,
        "name": "Matt Viator",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90048103121802e-7,
            "degree": 4,
            "eigen": 0.00000223552687666974
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2017": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2018": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2019": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2020": {
                "college": "Louisiana Monroe",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "HC",
                "net_ppa": "-0.073659252",
                "net_sr": "-0.024121547",
                "net_stuff": "0.014190152",
                "net_pass_sr": "-0.040547239",
                "net_fpi": "N/A",
                "total_sr": "-0.120607735",
                "total_stuffs": "0.070950758",
                "total_pass_sr": "-0.202736196",
                "total_ppa": "-0.368296262"
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.073659252
    },
    {
        "id": 198,
        "name": "Derek Dooley",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 78,
            "closeness": 4.93148442119414e-7,
            "degree": 6,
            "eigen": 0.00112558716546787
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2008": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2009": {
                "college": "Louisiana Tech",
                "position": "HC"
            },
            "2010": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2011": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2012": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Missouri",
                "position": "OC"
            },
            "2019": {
                "college": "Missouri",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Tech",
                "role": "HC",
                "net_ppa": "0.164534405",
                "net_sr": "0.091773232",
                "net_stuff": "-0.019448272",
                "net_pass_sr": "0.047537815",
                "net_fpi": "N/A",
                "total_sr": "0.275319697",
                "total_stuffs": "-0.058344815",
                "total_pass_sr": "0.142613445",
                "total_ppa": "0.493603214"
            },
            {
                "college": "Tennessee",
                "role": "HC",
                "net_ppa": "0.00824715",
                "net_sr": "-0.002120709",
                "net_stuff": "0.024604066",
                "net_pass_sr": "0.018347841",
                "net_fpi": "-2.9",
                "total_sr": "-0.006362126",
                "total_stuffs": "0.073812199",
                "total_pass_sr": "0.055043524",
                "total_ppa": "0.024741451"
            },
            {
                "college": "Missouri",
                "role": "OC",
                "net_ppa": "0.00494103164612733",
                "net_sr": "0.030626037817461",
                "net_stuff": "-0.0143451675423074",
                "net_pass_sr": "0.037782692961326",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.17772258664612733
    },
    {
        "id": 199,
        "name": "Steve Kragthorpe",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 152,
            "closeness": 5.53853072749155e-7,
            "degree": 9,
            "eigen": 0.000131102047609079
        },
        "positions": {
            "2000": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2004": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2005": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2006": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2007": {
                "college": "Louisville",
                "position": "HC"
            },
            "2008": {
                "college": "Louisville",
                "position": "HC"
            },
            "2009": {
                "college": "Louisville",
                "position": "HC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "HC",
                "net_ppa": "-0.163151496",
                "net_sr": "-0.086983186",
                "net_stuff": "0.036825704",
                "net_pass_sr": "-0.184606898",
                "net_fpi": "-19.63333333",
                "total_sr": "-0.260949559",
                "total_stuffs": "0.110477112",
                "total_pass_sr": "-0.553820694",
                "total_ppa": "-0.489454488"
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.163151496
    },
    {
        "id": 200,
        "name": "Charlie Strong",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 51,
            "closeness": 4.95941954953592e-7,
            "degree": 11,
            "eigen": 0.00313646915712283
        },
        "positions": {
            "2000": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2001": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2002": false,
            "2003": {
                "college": "Florida",
                "position": "DC"
            },
            "2004": {
                "college": "Florida",
                "position": "DC"
            },
            "2005": {
                "college": "Florida",
                "position": "DC"
            },
            "2006": {
                "college": "Florida",
                "position": "DC"
            },
            "2007": {
                "college": "Florida",
                "position": "DC"
            },
            "2008": {
                "college": "Florida",
                "position": "DC"
            },
            "2009": {
                "college": "Florida",
                "position": "DC"
            },
            "2010": {
                "college": "Louisville",
                "position": "HC"
            },
            "2011": {
                "college": "Louisville",
                "position": "HC"
            },
            "2012": {
                "college": "Louisville",
                "position": "HC"
            },
            "2013": {
                "college": "Louisville",
                "position": "HC"
            },
            "2014": {
                "college": "Texas",
                "position": "HC"
            },
            "2015": {
                "college": "Texas",
                "position": "HC"
            },
            "2016": {
                "college": "Texas",
                "position": "HC"
            },
            "2017": {
                "college": "South Florida",
                "position": "HC"
            },
            "2018": {
                "college": "South Florida",
                "position": "HC"
            },
            "2019": {
                "college": "South Florida",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "HC",
                "net_ppa": "0.074051419",
                "net_sr": "0.019747265",
                "net_stuff": "0.051479827",
                "net_pass_sr": "0.096311236",
                "net_fpi": "7.208333333",
                "total_sr": "0.078989061",
                "total_stuffs": "0.205919308",
                "total_pass_sr": "0.385244944",
                "total_ppa": "0.296205677"
            },
            {
                "college": "South Florida",
                "role": "HC",
                "net_ppa": "-0.020343626",
                "net_sr": "0.032846519",
                "net_stuff": "-0.038636707",
                "net_pass_sr": "0.040740621",
                "net_fpi": "N/A",
                "total_sr": "0.098539557",
                "total_stuffs": "-0.11591012",
                "total_pass_sr": "0.122221863",
                "total_ppa": "-0.061030878"
            },
            {
                "college": "Texas",
                "role": "HC",
                "net_ppa": "-0.006496639",
                "net_sr": "-0.022510557",
                "net_stuff": "0.074391183",
                "net_pass_sr": "-0.023292659",
                "net_fpi": "-6.533333333",
                "total_sr": "-0.06753167",
                "total_stuffs": "0.22317355",
                "total_pass_sr": "-0.069877976",
                "total_ppa": "-0.019489916"
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.04721115399999999
    },
    {
        "id": 201,
        "name": "Ed Orgeron",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 349.333333333333,
            "closeness": 5.42152344808891e-7,
            "degree": 10,
            "eigen": 0.00285435438624952
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2006": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2007": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "USC",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "LSU",
                "position": "HC"
            },
            "2018": {
                "college": "LSU",
                "position": "HC"
            },
            "2019": {
                "college": "LSU",
                "position": "HC"
            },
            "2020": {
                "college": "LSU",
                "position": "HC"
            },
            "2021": {
                "college": "LSU",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "LSU",
                "role": "HC",
                "net_ppa": "-0.102994961",
                "net_sr": "-0.02194901",
                "net_stuff": "0.003704182",
                "net_pass_sr": "0.030907372",
                "net_fpi": "-6.013333333",
                "total_sr": "-0.109745048",
                "total_stuffs": "0.018520908",
                "total_pass_sr": "0.154536859",
                "total_ppa": "-0.514974806"
            },
            {
                "college": "Ole Miss",
                "role": "HC",
                "net_ppa": "0.024092724",
                "net_sr": "0.010448929",
                "net_stuff": "-0.057734181",
                "net_pass_sr": "0.006394277",
                "net_fpi": "1.9",
                "total_sr": "0.010448929",
                "total_stuffs": "-0.057734181",
                "total_pass_sr": "0.006394277",
                "total_ppa": "0.024092724"
            },
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "0.0311048818707226",
                "net_sr": "-0.00845556525718694",
                "net_stuff": "0.020339427570345",
                "net_pass_sr": "0.0210877383374151",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0477973551292774
    },
    {
        "id": 202,
        "name": "Bob Pruett",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 8,
            "closeness": 4.9004858341656e-7,
            "degree": 5,
            "eigen": 0.0000274914885050579
        },
        "positions": {
            "2000": {
                "college": "Marshall",
                "position": "HC"
            },
            "2001": {
                "college": "Marshall",
                "position": "HC"
            },
            "2002": {
                "college": "Marshall",
                "position": "HC"
            },
            "2003": {
                "college": "Marshall",
                "position": "HC"
            },
            "2004": {
                "college": "Marshall",
                "position": "HC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Virginia",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "0.06364987443936",
                "net_sr": "-0.00615996794547724",
                "net_stuff": "-0.0230270075937842",
                "net_pass_sr": "-0.00700695793100015",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.06364987443936
    },
    {
        "id": 203,
        "name": "Mark Snyder",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 74,
            "closeness": 4.93849109343131e-7,
            "degree": 8,
            "eigen": 0.000356244710699855
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2005": {
                "college": "Marshall",
                "position": "HC"
            },
            "2006": {
                "college": "Marshall",
                "position": "HC"
            },
            "2007": {
                "college": "Marshall",
                "position": "HC"
            },
            "2008": {
                "college": "Marshall",
                "position": "HC"
            },
            "2009": {
                "college": "Marshall",
                "position": "HC"
            },
            "2010": {
                "college": "South Florida",
                "position": "DC"
            },
            "2011": {
                "college": "South Florida",
                "position": "DC"
            },
            "2012": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2013": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2014": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "-0.0437104386938796",
                "net_sr": "-0.0124952812025109",
                "net_stuff": "0.0274534963693196",
                "net_pass_sr": "-0.024492394210602",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "-0.0984220972951476",
                "net_sr": "-0.041991515521099",
                "net_stuff": "0.0253920259721371",
                "net_pass_sr": "-0.0181056139741335",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1421325359890272
    },
    {
        "id": 204,
        "name": "Doc Holliday",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.94196160293513e-7,
            "degree": 9,
            "eigen": 0.000238572510822585
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Marshall",
                "position": "HC"
            },
            "2011": {
                "college": "Marshall",
                "position": "HC"
            },
            "2012": {
                "college": "Marshall",
                "position": "HC"
            },
            "2013": {
                "college": "Marshall",
                "position": "HC"
            },
            "2014": {
                "college": "Marshall",
                "position": "HC"
            },
            "2015": {
                "college": "Marshall",
                "position": "HC"
            },
            "2016": {
                "college": "Marshall",
                "position": "HC"
            },
            "2017": {
                "college": "Marshall",
                "position": "HC"
            },
            "2018": {
                "college": "Marshall",
                "position": "HC"
            },
            "2019": {
                "college": "Marshall",
                "position": "HC"
            },
            "2020": {
                "college": "Marshall",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Marshall",
                "role": "HC",
                "net_ppa": "0.106326752",
                "net_sr": "0.057433105",
                "net_stuff": "-0.045019283",
                "net_pass_sr": "0.075930601",
                "net_fpi": "4.896969697",
                "total_sr": "0.631764158",
                "total_stuffs": "-0.495212109",
                "total_pass_sr": "0.835236611",
                "total_ppa": "1.169594269"
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.106326752
    },
    {
        "id": 205,
        "name": "Charles Huff",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89363680406368e-7,
            "degree": 2,
            "eigen": 0.00000791125294886001
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Marshall",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Marshall",
                "role": "HC",
                "net_ppa": "0.003767795",
                "net_sr": "0.004997372",
                "net_stuff": "0.016699334",
                "net_pass_sr": "0.04644224",
                "net_fpi": "1.433333333",
                "total_sr": "0.004997372",
                "total_stuffs": "0.016699334",
                "total_pass_sr": "0.04644224",
                "total_ppa": "0.003767795"
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.003767795
    },
    {
        "id": 207,
        "name": "Ralph Friedgen",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 38.5,
            "closeness": 4.95233869242411e-7,
            "degree": 7,
            "eigen": 0.000321395247017727
        },
        "positions": {
            "2000": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2001": {
                "college": "Maryland",
                "position": "HC"
            },
            "2002": {
                "college": "Maryland",
                "position": "HC"
            },
            "2003": {
                "college": "Maryland",
                "position": "HC"
            },
            "2004": {
                "college": "Maryland",
                "position": "HC"
            },
            "2005": {
                "college": "Maryland",
                "position": "HC"
            },
            "2006": {
                "college": "Maryland",
                "position": "HC"
            },
            "2007": {
                "college": "Maryland",
                "position": "HC"
            },
            "2008": {
                "college": "Maryland",
                "position": "HC"
            },
            "2009": {
                "college": "Maryland",
                "position": "HC"
            },
            "2010": {
                "college": "Maryland",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "HC",
                "net_ppa": "-0.065675652",
                "net_sr": "-0.054255448",
                "net_stuff": "-0.046228701",
                "net_pass_sr": "-0.012992452",
                "net_fpi": "-0.5",
                "total_sr": "-0.108510895",
                "total_stuffs": "-0.092457402",
                "total_pass_sr": "-0.025984903",
                "total_ppa": "-0.131351303"
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.168089945935784",
                "net_sr": "0.073127910137746",
                "net_stuff": "-0.0463852946474901",
                "net_pass_sr": "0.0231978289356299",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.102414293935784
    },
    {
        "id": 208,
        "name": "Randy Edsall",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.10369430912566e-7,
            "degree": 18,
            "eigen": 0.00080640466503668
        },
        "positions": {
            "2000": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2001": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2002": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2003": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2004": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2005": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2006": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2007": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2008": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2009": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2010": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2011": {
                "college": "Maryland",
                "position": "HC"
            },
            "2012": {
                "college": "Maryland",
                "position": "HC"
            },
            "2013": {
                "college": "Maryland",
                "position": "HC"
            },
            "2014": {
                "college": "Maryland",
                "position": "HC"
            },
            "2015": {
                "college": "Maryland",
                "position": "HC"
            },
            "2016": false,
            "2017": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2018": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2019": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2020": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2021": {
                "college": "Connecticut",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "HC",
                "net_ppa": "-0.05870078",
                "net_sr": "-0.026017563",
                "net_stuff": "0.034565229",
                "net_pass_sr": "-0.018134857",
                "net_fpi": "-2.906666667",
                "total_sr": "-0.130087815",
                "total_stuffs": "0.172826145",
                "total_pass_sr": "-0.090674283",
                "total_ppa": "-0.293503901"
            }
        ],
        "yearsCoached": 21,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.05870078
    },
    {
        "id": 209,
        "name": "D.J. Durkin",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 152.166666666667,
            "closeness": 4.90734924623116e-7,
            "degree": 6,
            "eigen": 0.000280330498966155
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Florida",
                "position": "DC"
            },
            "2014": {
                "college": "Florida",
                "position": "DC"
            },
            "2015": {
                "college": "Michigan",
                "position": "DC"
            },
            "2016": {
                "college": "Maryland",
                "position": "HC"
            },
            "2017": {
                "college": "Maryland",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2021": {
                "college": "Ole Miss",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "HC",
                "net_ppa": "-0.055052804",
                "net_sr": "-0.04656729",
                "net_stuff": "0.022650041",
                "net_pass_sr": "-0.039159207",
                "net_fpi": "-5.233333333",
                "total_sr": "-0.093134579",
                "total_stuffs": "0.045300082",
                "total_pass_sr": "-0.078318414",
                "total_ppa": "-0.110105608"
            },
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "-0.0457462792412234",
                "net_sr": "-0.0126450082464438",
                "net_stuff": "0.0147477618729219",
                "net_pass_sr": "0.016462321478239",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.040147543253215",
                "net_sr": "0.0829732214809531",
                "net_stuff": "-0.00683452438459362",
                "net_pass_sr": "0.104788730920354",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0438309721263885",
                "net_sr": "-0.0259618122865871",
                "net_stuff": "0.0175756077726628",
                "net_pass_sr": "-0.0228990907472618",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.10448251211439691
    },
    {
        "id": 210,
        "name": "Matt Canada",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 55.8333333333333,
            "closeness": 4.89706372059313e-7,
            "degree": 9,
            "eigen": 0.00071876885831461
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Indiana",
                "position": "OC"
            },
            "2008": {
                "college": "Indiana",
                "position": "OC"
            },
            "2009": {
                "college": "Indiana",
                "position": "OC"
            },
            "2010": {
                "college": "Indiana",
                "position": "OC"
            },
            "2011": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2012": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2013": {
                "college": "NC State",
                "position": "OC"
            },
            "2014": {
                "college": "NC State",
                "position": "OC"
            },
            "2015": {
                "college": "NC State",
                "position": "OC"
            },
            "2016": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2017": {
                "college": "LSU",
                "position": "OC"
            },
            "2018": {
                "college": "Maryland",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "HC",
                "net_ppa": "0.16436446",
                "net_sr": "0.034883951",
                "net_stuff": "0.021074607",
                "net_pass_sr": "0.014016117",
                "net_fpi": "7.566666667",
                "total_sr": "0.034883951",
                "total_stuffs": "0.021074607",
                "total_pass_sr": "0.014016117",
                "total_ppa": "0.16436446"
            },
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.0153939490004056",
                "net_sr": "0.0342941272318713",
                "net_stuff": "0.00806768915290684",
                "net_pass_sr": "0.0590926873264531",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.00883650220859525",
                "net_sr": "0.0112110281996318",
                "net_stuff": "-0.0184225535712043",
                "net_pass_sr": "0.0290181828370146",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "0.132996621561271",
                "net_sr": "0.0489770106278857",
                "net_stuff": "-0.0138161477245577",
                "net_pass_sr": "0.0233910510602268",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "0.126875274290234",
                "net_sr": "-0.0208536983131035",
                "net_stuff": "0.0268287484023656",
                "net_pass_sr": "-0.00616116607110262",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "-0.146964938962536",
                "net_sr": "-0.0968869780408587",
                "net_stuff": "0.0505295149409626",
                "net_pass_sr": "-0.140899238926307",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2838288636807793
    },
    {
        "id": 211,
        "name": "Mike Locksley",
        "community": 5,
        "isWhite": false,
        "centrality": {
            "betweenness": 89.5,
            "closeness": 5.00191323181117e-7,
            "degree": 13,
            "eigen": 0.000941310434514084
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Illinois",
                "position": "OC"
            },
            "2006": {
                "college": "Illinois",
                "position": "OC"
            },
            "2007": {
                "college": "Illinois",
                "position": "OC"
            },
            "2008": {
                "college": "Illinois",
                "position": "OC"
            },
            "2009": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2010": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2011": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2012": {
                "college": "Maryland",
                "position": "OC"
            },
            "2013": {
                "college": "Maryland",
                "position": "OC"
            },
            "2014": {
                "college": "Maryland",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Alabama",
                "position": "OC"
            },
            "2018": {
                "college": "Alabama",
                "position": "OC"
            },
            "2019": {
                "college": "Maryland",
                "position": "HC"
            },
            "2020": {
                "college": "Maryland",
                "position": "HC"
            },
            "2021": {
                "college": "Maryland",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "HC",
                "net_ppa": "0.002185112",
                "net_sr": "0.040655482",
                "net_stuff": "-0.027079461",
                "net_pass_sr": "0.074020813",
                "net_fpi": "-1.1",
                "total_sr": "0.121966445",
                "total_stuffs": "-0.081238384",
                "total_pass_sr": "0.222062439",
                "total_ppa": "0.006555335"
            },
            {
                "college": "New Mexico",
                "role": "HC",
                "net_ppa": "-0.153085715",
                "net_sr": "-0.112863399",
                "net_stuff": "0.066721966",
                "net_pass_sr": "-0.109535934",
                "net_fpi": "-20.1",
                "total_sr": "-0.338590197",
                "total_stuffs": "0.200165898",
                "total_pass_sr": "-0.328607803",
                "total_ppa": "-0.459257145"
            },
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.0831495180942152",
                "net_sr": "0.0336609637474246",
                "net_stuff": "-0.0247208035898756",
                "net_pass_sr": "0.0495629564435992",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "-0.0263359021839004",
                "net_sr": "0.0137150163378081",
                "net_stuff": "0.0155833178449054",
                "net_pass_sr": "0.0273550454734273",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "0.0132571880005151",
                "net_sr": "-0.0126814517389188",
                "net_stuff": "0.0221529403729486",
                "net_pass_sr": "-0.0149085621637062",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.08082979908917012
    },
    {
        "id": 212,
        "name": "Charley Molnar",
        "community": 25,
        "isWhite": false,
        "centrality": {
            "betweenness": 114,
            "closeness": 5.31481510024007e-7,
            "degree": 4,
            "eigen": 0.0000682398488643477
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2011": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2012": {
                "college": "UMass",
                "position": "HC"
            },
            "2013": {
                "college": "UMass",
                "position": "HC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UMass",
                "role": "HC",
                "net_ppa": "-0.092756632",
                "net_sr": "-0.106114059",
                "net_stuff": "0.109281998",
                "net_pass_sr": "-0.134303088",
                "net_fpi": "N/A",
                "total_sr": "-0.212228119",
                "total_stuffs": "0.218563995",
                "total_pass_sr": "-0.268606176",
                "total_ppa": "-0.185513263"
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.0590927219441612",
                "net_sr": "0.0537689274109836",
                "net_stuff": "-0.0217263203654858",
                "net_pass_sr": "0.0627467534315981",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.033663910055838804
    },
    {
        "id": 213,
        "name": "Mark Whipple",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 44,
            "closeness": 4.89362961976987e-7,
            "degree": 4,
            "eigen": 0.000437844687915675
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Miami",
                "position": "OC"
            },
            "2010": {
                "college": "Miami",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "UMass",
                "position": "HC"
            },
            "2015": {
                "college": "UMass",
                "position": "HC"
            },
            "2016": {
                "college": "UMass",
                "position": "HC"
            },
            "2017": {
                "college": "UMass",
                "position": "HC"
            },
            "2018": {
                "college": "UMass",
                "position": "HC"
            },
            "2019": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2020": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2021": {
                "college": "Pittsburgh",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "UMass",
                "role": "HC",
                "net_ppa": "0.081722249",
                "net_sr": "0.018405414",
                "net_stuff": "-0.026674959",
                "net_pass_sr": "0.019903136",
                "net_fpi": "N/A",
                "total_sr": "0.092027068",
                "total_stuffs": "-0.133374793",
                "total_pass_sr": "0.09951568",
                "total_ppa": "0.408611245"
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "0.071073570310772",
                "net_sr": "0.0622438366460756",
                "net_stuff": "-0.0542023851774468",
                "net_pass_sr": "0.0684068477755458",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "-0.0422862996737836",
                "net_sr": "-0.015180742191826",
                "net_stuff": "0.0140873173194574",
                "net_pass_sr": "0.0200208450891369",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1105095196369884
    },
    {
        "id": 214,
        "name": "Walt Bell",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 135,
            "closeness": 4.89705892435121e-7,
            "degree": 6,
            "eigen": 0.000114521252602078
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2015": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2016": {
                "college": "Maryland",
                "position": "OC"
            },
            "2017": {
                "college": "Maryland",
                "position": "OC"
            },
            "2018": {
                "college": "Florida State",
                "position": "OC"
            },
            "2019": {
                "college": "UMass",
                "position": "HC"
            },
            "2020": {
                "college": "UMass",
                "position": "HC"
            },
            "2021": {
                "college": "UMass",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "UMass",
                "role": "HC",
                "net_ppa": "-0.243371449",
                "net_sr": "-0.088970799",
                "net_stuff": "0.033525813",
                "net_pass_sr": "-0.118417282",
                "net_fpi": "-12.95",
                "total_sr": "-0.266912398",
                "total_stuffs": "0.10057744",
                "total_pass_sr": "-0.355251845",
                "total_ppa": "-0.730114347"
            },
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "0.072801166376604",
                "net_sr": "-0.0127609021179452",
                "net_stuff": "0.0262413196066341",
                "net_pass_sr": "-0.0390769395807717",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "-0.11068638154044",
                "net_sr": "-0.119771381273196",
                "net_stuff": "0.038919373165034",
                "net_pass_sr": "-0.096371216798708",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "0.0272479592112554",
                "net_sr": "-0.00461966948788467",
                "net_stuff": "-0.000818814331884937",
                "net_pass_sr": "-0.0218968646100908",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2540087049525806
    },
    {
        "id": 216,
        "name": "Tommy West",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 498,
            "closeness": 4.92457763128801e-7,
            "degree": 10,
            "eigen": 0.000148667712940984
        },
        "positions": {
            "2000": {
                "college": "Memphis",
                "position": "DC"
            },
            "2001": {
                "college": "Memphis",
                "position": "HC"
            },
            "2002": {
                "college": "Memphis",
                "position": "HC"
            },
            "2003": {
                "college": "Memphis",
                "position": "HC"
            },
            "2004": {
                "college": "Memphis",
                "position": "HC"
            },
            "2005": {
                "college": "Memphis",
                "position": "HC"
            },
            "2006": {
                "college": "Memphis",
                "position": "HC"
            },
            "2007": {
                "college": "Memphis",
                "position": "HC"
            },
            "2008": {
                "college": "Memphis",
                "position": "HC"
            },
            "2009": {
                "college": "Memphis",
                "position": "HC"
            },
            "2010": false,
            "2011": {
                "college": "UAB",
                "position": "DC"
            },
            "2012": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.166733392760902",
                "net_sr": "0.15749300273186",
                "net_stuff": "-0.0958802030685502",
                "net_pass_sr": "0.141893737416965",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "0.0512251759066782",
                "net_sr": "-0.0469329958942508",
                "net_stuff": "-0.0032606882637064",
                "net_pass_sr": "-0.0454259442155335",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.21795856866758018
    },
    {
        "id": 217,
        "name": "Larry Porter",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.91767806912288e-7,
            "degree": 3,
            "eigen": 0.0000101484941018796
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Memphis",
                "position": "HC"
            },
            "2011": {
                "college": "Memphis",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "HC",
                "net_ppa": "-0.049519144",
                "net_sr": "-0.074324939",
                "net_stuff": "0.038514994",
                "net_pass_sr": "-0.059174816",
                "net_fpi": "-14.9",
                "total_sr": "-0.148649878",
                "total_stuffs": "0.077029989",
                "total_pass_sr": "-0.118349631",
                "total_ppa": "-0.099038287"
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.049519144
    },
    {
        "id": 218,
        "name": "Justin Fuente",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 74,
            "closeness": 5.01945037018446e-7,
            "degree": 6,
            "eigen": 0.000500900899935524
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "TCU",
                "position": "OC"
            },
            "2010": {
                "college": "TCU",
                "position": "OC"
            },
            "2011": {
                "college": "TCU",
                "position": "OC"
            },
            "2012": {
                "college": "Memphis",
                "position": "HC"
            },
            "2013": {
                "college": "Memphis",
                "position": "HC"
            },
            "2014": {
                "college": "Memphis",
                "position": "HC"
            },
            "2015": {
                "college": "Memphis",
                "position": "HC"
            },
            "2016": {
                "college": "Virginia Tech",
                "position": "HC"
            },
            "2017": {
                "college": "Virginia Tech",
                "position": "HC"
            },
            "2018": {
                "college": "Virginia Tech",
                "position": "HC"
            },
            "2019": {
                "college": "Virginia Tech",
                "position": "HC"
            },
            "2020": {
                "college": "Virginia Tech",
                "position": "HC"
            },
            "2021": {
                "college": "Virginia Tech",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "HC",
                "net_ppa": "0.162715879",
                "net_sr": "0.091965295",
                "net_stuff": "-0.076302885",
                "net_pass_sr": "0.111712555",
                "net_fpi": "18.43333333",
                "total_sr": "0.367861178",
                "total_stuffs": "-0.305211542",
                "total_pass_sr": "0.446850222",
                "total_ppa": "0.650863517"
            },
            {
                "college": "Virginia Tech",
                "role": "HC",
                "net_ppa": "-0.009685788",
                "net_sr": "-0.008841476",
                "net_stuff": "-0.010755911",
                "net_pass_sr": "-0.006528612",
                "net_fpi": "N/A",
                "total_sr": "-0.053048854",
                "total_stuffs": "-0.064535465",
                "total_pass_sr": "-0.039171671",
                "total_ppa": "-0.058114728"
            },
            {
                "college": "TCU",
                "role": "OC",
                "net_ppa": "-0.0466791242880335",
                "net_sr": "0.0315544576163654",
                "net_stuff": "-0.0488252492810146",
                "net_pass_sr": "0.0434921026521423",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1063509667119665
    },
    {
        "id": 219,
        "name": "Ryan Silverfield",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.94538318807094e-7,
            "degree": 2,
            "eigen": 0.000515965321789979
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Memphis",
                "position": "HC"
            },
            "2021": {
                "college": "Memphis",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "HC",
                "net_ppa": "-0.139914629",
                "net_sr": "-0.064353386",
                "net_stuff": "0.042033829",
                "net_pass_sr": "-0.072681814",
                "net_fpi": "-8.95",
                "total_sr": "-0.128706772",
                "total_stuffs": "0.084067658",
                "total_pass_sr": "-0.145363627",
                "total_ppa": "-0.279829258"
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.139914629
    },
    {
        "id": 221,
        "name": "Randy Shannon",
        "community": 14,
        "isWhite": false,
        "centrality": {
            "betweenness": 120.5,
            "closeness": 4.91079540153119e-7,
            "degree": 8,
            "eigen": 0.0011718742292149
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Miami",
                "position": "DC"
            },
            "2002": {
                "college": "Miami",
                "position": "DC"
            },
            "2003": {
                "college": "Miami",
                "position": "DC"
            },
            "2004": {
                "college": "Miami",
                "position": "DC"
            },
            "2005": {
                "college": "Miami",
                "position": "DC"
            },
            "2006": {
                "college": "Miami",
                "position": "DC"
            },
            "2007": {
                "college": "Miami",
                "position": "HC"
            },
            "2008": {
                "college": "Miami",
                "position": "HC"
            },
            "2009": {
                "college": "Miami",
                "position": "HC"
            },
            "2010": {
                "college": "Miami",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Florida",
                "position": "DC"
            },
            "2016": {
                "college": "Florida",
                "position": "DC"
            },
            "2017": {
                "college": "Florida",
                "position": "DC"
            },
            "2018": {
                "college": "UCF",
                "position": "DC"
            },
            "2019": {
                "college": "UCF",
                "position": "DC"
            },
            "2020": {
                "college": "UCF",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Miami",
                "role": "HC",
                "net_ppa": "-0.065208581",
                "net_sr": "-0.029956988",
                "net_stuff": "0.039849093",
                "net_pass_sr": "-0.032882616",
                "net_fpi": "-3.35",
                "total_sr": "-0.119827952",
                "total_stuffs": "0.159396371",
                "total_pass_sr": "-0.131530464",
                "total_ppa": "-0.260834323"
            },
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "-0.0889569448812256",
                "net_sr": "-0.0464153834394964",
                "net_stuff": "0.0984425535385021",
                "net_pass_sr": "-0.0181093079914776",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "0.160810647454864",
                "net_sr": "0.0951292447810373",
                "net_stuff": "-0.088939509380928",
                "net_pass_sr": "0.0981464653596858",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.0715219625136274",
                "net_sr": "-0.00850098745698524",
                "net_stuff": "0.0358819603437463",
                "net_pass_sr": "-0.00359137227175527",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.06487684093998901
    },
    {
        "id": 222,
        "name": "Al Golden",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 22,
            "closeness": 4.92454610458554e-7,
            "degree": 6,
            "eigen": 0.000281131362007022
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Virginia",
                "position": "DC"
            },
            "2002": {
                "college": "Virginia",
                "position": "DC"
            },
            "2003": {
                "college": "Virginia",
                "position": "DC"
            },
            "2004": {
                "college": "Virginia",
                "position": "DC"
            },
            "2005": {
                "college": "Virginia",
                "position": "DC"
            },
            "2006": {
                "college": "Temple",
                "position": "HC"
            },
            "2007": {
                "college": "Temple",
                "position": "HC"
            },
            "2008": {
                "college": "Temple",
                "position": "HC"
            },
            "2009": {
                "college": "Temple",
                "position": "HC"
            },
            "2010": {
                "college": "Temple",
                "position": "HC"
            },
            "2011": {
                "college": "Miami",
                "position": "HC"
            },
            "2012": {
                "college": "Miami",
                "position": "HC"
            },
            "2013": {
                "college": "Miami",
                "position": "HC"
            },
            "2014": {
                "college": "Miami",
                "position": "HC"
            },
            "2015": {
                "college": "Miami",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami",
                "role": "HC",
                "net_ppa": "-0.013729061",
                "net_sr": "-0.052762323",
                "net_stuff": "0.087486279",
                "net_pass_sr": "-0.044405315",
                "net_fpi": "-2.106666667",
                "total_sr": "-0.263811614",
                "total_stuffs": "0.437431394",
                "total_pass_sr": "-0.222026573",
                "total_ppa": "-0.068645304"
            },
            {
                "college": "Temple",
                "role": "HC",
                "net_ppa": "0.110083742",
                "net_sr": "0.087690474",
                "net_stuff": "-0.015127032",
                "net_pass_sr": "0.057915443",
                "net_fpi": "16.68",
                "total_sr": "0.438452369",
                "total_stuffs": "-0.075635158",
                "total_pass_sr": "0.289577214",
                "total_ppa": "0.550418711"
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.096354681
    },
    {
        "id": 223,
        "name": "Manny Diaz",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 219,
            "closeness": 4.9107881667684e-7,
            "degree": 9,
            "eigen": 0.00102142919508507
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2007": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2008": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2009": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2010": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2011": {
                "college": "Texas",
                "position": "DC"
            },
            "2012": {
                "college": "Texas",
                "position": "DC"
            },
            "2013": {
                "college": "Texas",
                "position": "DC"
            },
            "2014": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2015": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2016": {
                "college": "Miami",
                "position": "DC"
            },
            "2017": {
                "college": "Miami",
                "position": "DC"
            },
            "2018": {
                "college": "Miami",
                "position": "DC"
            },
            "2019": {
                "college": "Miami",
                "position": "HC"
            },
            "2020": {
                "college": "Miami",
                "position": "HC"
            },
            "2021": {
                "college": "Miami",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Miami",
                "role": "HC",
                "net_ppa": "-0.091490669",
                "net_sr": "-0.015772937",
                "net_stuff": "0.038100458",
                "net_pass_sr": "0.002536461",
                "net_fpi": "-5.6",
                "total_sr": "-0.047318812",
                "total_stuffs": "0.114301374",
                "total_pass_sr": "0.007609382",
                "total_ppa": "-0.274472006"
            },
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "0.00893611731134128",
                "net_sr": "0.0505228913850152",
                "net_stuff": "-0.0667948787543681",
                "net_pass_sr": "0.0405678352508139",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "0.0880462321840716",
                "net_sr": "0.0555379415523469",
                "net_stuff": "-0.103305803606617",
                "net_pass_sr": "0.045167509801738",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "-0.0136730263714284",
                "net_sr": "0.0390580107920146",
                "net_stuff": "0.102538618839316",
                "net_pass_sr": "0.0795822880337581",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "-0.0761729362356523",
                "net_sr": "-0.0274710955813008",
                "net_stuff": "0.0062404505388467",
                "net_pass_sr": "-0.05317216043918",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "-0.0722291597827264",
                "net_sr": "-0.0366859939420387",
                "net_stuff": "0.0144767821938768",
                "net_pass_sr": "-0.0431555943450745",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.15658344189439422
    },
    {
        "id": 224,
        "name": "Shane Montgomery",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 22,
            "closeness": 4.89363440929673e-7,
            "degree": 6,
            "eigen": 0.0000445433404675974
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2002": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2003": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2004": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2005": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2006": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2007": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2008": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2009": {
                "college": "Akron",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Charlotte",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Buffalo",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "-0.0427882177444288",
                "net_sr": "-0.00425382534805835",
                "net_stuff": "0.0314168430393403",
                "net_pass_sr": "0.00536930737235292",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "-0.08913359262235",
                "net_sr": "-0.0233238062420249",
                "net_stuff": "-0.0378585673657506",
                "net_pass_sr": "-0.0129267421098724",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Charlotte",
                "role": "OC",
                "net_ppa": "0.109330339095639",
                "net_sr": "0.0351938268377425",
                "net_stuff": "-0.0102158847818964",
                "net_pass_sr": "0.0669958373163835",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.022591471271139807
    },
    {
        "id": 225,
        "name": "Michael Haywood",
        "community": 13,
        "isWhite": false,
        "centrality": {
            "betweenness": 81,
            "closeness": 4.897061322471e-7,
            "degree": 4,
            "eigen": 0.000085359067193244
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2006": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2007": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2008": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2009": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2010": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "HC",
                "net_ppa": "0.140145941",
                "net_sr": "0.048262419",
                "net_stuff": "-0.039674471",
                "net_pass_sr": "0.061568462",
                "net_fpi": "-0.75",
                "total_sr": "0.096524838",
                "total_stuffs": "-0.079348942",
                "total_pass_sr": "0.123136924",
                "total_ppa": "0.280291883"
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.140145941
    },
    {
        "id": 226,
        "name": "Don Treadwell",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 27,
            "closeness": 4.89705652623378e-7,
            "degree": 6,
            "eigen": 0.00140447294620886
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Ball State",
                "position": "OC"
            },
            "2004": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2005": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2006": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2007": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2008": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2009": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2010": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2011": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2012": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2013": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2014": false,
            "2015": {
                "college": "Kent State",
                "position": "OC"
            },
            "2016": {
                "college": "Kent State",
                "position": "OC"
            },
            "2017": {
                "college": "Kent State",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "HC",
                "net_ppa": "-0.063791898",
                "net_sr": "-0.08482374",
                "net_stuff": "0.027646355",
                "net_pass_sr": "-0.071786871",
                "net_fpi": "-1.2",
                "total_sr": "-0.254471219",
                "total_stuffs": "0.082939064",
                "total_pass_sr": "-0.215360613",
                "total_ppa": "-0.191375694"
            },
            {
                "college": "Kent State",
                "role": "OC",
                "net_ppa": "-0.0387734296126144",
                "net_sr": "-0.0441097090181397",
                "net_stuff": "0.0210727946374237",
                "net_pass_sr": "-0.0701216398666343",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "-0.0763470850312633",
                "net_sr": "-0.047351337254148",
                "net_stuff": "0.0254159126532841",
                "net_pass_sr": "-0.0184722975503171",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1789124126438777
    },
    {
        "id": 227,
        "name": "Chuck Martin",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 5,
            "closeness": 4.90387666161731e-7,
            "degree": 6,
            "eigen": 0.000049457111145587
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2013": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2014": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2015": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2016": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2017": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2018": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2019": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2020": {
                "college": "Miami (OH)",
                "position": "HC"
            },
            "2021": {
                "college": "Miami (OH)",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "HC",
                "net_ppa": "0.063544197",
                "net_sr": "0.045950498",
                "net_stuff": "0.014757508",
                "net_pass_sr": "0.039733035",
                "net_fpi": "5.745833333",
                "total_sr": "0.367603986",
                "total_stuffs": "0.118060065",
                "total_pass_sr": "0.317864276",
                "total_ppa": "0.508353574"
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.00814047358910973",
                "net_sr": "-0.00887929737463172",
                "net_stuff": "-0.0137162077663153",
                "net_pass_sr": "-0.00905313742632452",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.07168467058910973
    },
    {
        "id": 229,
        "name": "Jim Harbaugh",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.14785144124397e-7,
            "degree": 10,
            "eigen": 0.000649731450807076
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Stanford",
                "position": "HC"
            },
            "2008": {
                "college": "Stanford",
                "position": "HC"
            },
            "2009": {
                "college": "Stanford",
                "position": "HC"
            },
            "2010": {
                "college": "Stanford",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Michigan",
                "position": "HC"
            },
            "2016": {
                "college": "Michigan",
                "position": "HC"
            },
            "2017": {
                "college": "Michigan",
                "position": "HC"
            },
            "2018": {
                "college": "Michigan",
                "position": "HC"
            },
            "2019": {
                "college": "Michigan",
                "position": "HC"
            },
            "2020": {
                "college": "Michigan",
                "position": "HC"
            },
            "2021": {
                "college": "Michigan",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Michigan",
                "role": "HC",
                "net_ppa": "0.112339926",
                "net_sr": "0.07904787",
                "net_stuff": "-0.07833779",
                "net_pass_sr": "0.066268429",
                "net_fpi": "5.80952381",
                "total_sr": "0.553335093",
                "total_stuffs": "-0.54836453",
                "total_pass_sr": "0.463879005",
                "total_ppa": "0.78637948"
            },
            {
                "college": "Stanford",
                "role": "HC",
                "net_ppa": "0.159937905",
                "net_sr": "0.065434259",
                "net_stuff": "-0.117848865",
                "net_pass_sr": "0.063947479",
                "net_fpi": "11.5",
                "total_sr": "0.261737035",
                "total_stuffs": "-0.47139546",
                "total_pass_sr": "0.255789917",
                "total_ppa": "0.639751619"
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.272277831
    },
    {
        "id": 232,
        "name": "Rick Stockstill",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 865,
            "closeness": 4.98399390357866e-7,
            "degree": 12,
            "eigen": 0.000748932915660643
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2007": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2008": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2009": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2010": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2011": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2012": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2013": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2014": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2015": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2016": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2017": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2018": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2019": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2020": {
                "college": "Middle Tennessee",
                "position": "HC"
            },
            "2021": {
                "college": "Middle Tennessee",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Middle Tennessee",
                "role": "HC",
                "net_ppa": "0.048853193",
                "net_sr": "0.158783425",
                "net_stuff": "0.109682635",
                "net_pass_sr": "0.197836731",
                "net_fpi": "N/A",
                "total_sr": "2.540534794",
                "total_stuffs": "1.754922161",
                "total_pass_sr": "3.165387688",
                "total_ppa": "0.781651082"
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.048853193
    },
    {
        "id": 234,
        "name": "Tim Brewster",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.96302546032061e-7,
            "degree": 8,
            "eigen": 0.000157134094193201
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2008": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2009": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2010": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "HC",
                "net_ppa": "-0.053051379",
                "net_sr": "-0.050068403",
                "net_stuff": "-0.000163533",
                "net_pass_sr": "-0.068169192",
                "net_fpi": "-12.625",
                "total_sr": "-0.200273613",
                "total_stuffs": "-0.000654131",
                "total_pass_sr": "-0.27267677",
                "total_ppa": "-0.212205517"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.053051379
    },
    {
        "id": 235,
        "name": "Jerry Kill",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 60,
            "closeness": 4.90042339658147e-7,
            "degree": 3,
            "eigen": 0.000389266301630143
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2009": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2010": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2011": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2012": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2013": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2014": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2015": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2016": false,
            "2017": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "HC",
                "net_ppa": "0.030333588",
                "net_sr": "0.012123473",
                "net_stuff": "-0.006119183",
                "net_pass_sr": "0.05423029",
                "net_fpi": "1.92",
                "total_sr": "0.060617364",
                "total_stuffs": "-0.030595914",
                "total_pass_sr": "0.271151448",
                "total_ppa": "0.151667938"
            },
            {
                "college": "Northern Illinois",
                "role": "HC",
                "net_ppa": "0.075558666",
                "net_sr": "0.053203671",
                "net_stuff": "-0.089153373",
                "net_pass_sr": "0.064301327",
                "net_fpi": "N/A",
                "total_sr": "0.159611012",
                "total_stuffs": "-0.26746012",
                "total_pass_sr": "0.19290398",
                "total_ppa": "0.226675998"
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.0268990254963215",
                "net_sr": "0.0353598246989478",
                "net_stuff": "0.01650725319658",
                "net_pass_sr": "-0.0354777091790351",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1327912794963215
    },
    {
        "id": 236,
        "name": "Tracy Claeys",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 34,
            "closeness": 4.89363680406368e-7,
            "degree": 4,
            "eigen": 0.000824860684381709
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2009": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2010": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2011": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2012": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2013": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2014": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2015": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2016": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2017": false,
            "2018": {
                "college": "Washington State",
                "position": "DC"
            },
            "2019": {
                "college": "Washington State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "HC",
                "net_ppa": "0.033278827",
                "net_sr": "0.065685265",
                "net_stuff": "-0.008297291",
                "net_pass_sr": "0.048231213",
                "net_fpi": "2.066666667",
                "total_sr": "0.065685265",
                "total_stuffs": "-0.008297291",
                "total_pass_sr": "0.048231213",
                "total_ppa": "0.033278827"
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "-0.0613681259136097",
                "net_sr": "-0.0333799851374083",
                "net_stuff": "0.0162395544414567",
                "net_pass_sr": "-0.0656247857110832",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "0.130151033279161",
                "net_sr": "0.0120721910290604",
                "net_stuff": "-0.0221039636672831",
                "net_pass_sr": "-0.0098940342835343",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "0.061870081392036",
                "net_sr": "0.035061427341433",
                "net_stuff": "-0.064345354432997",
                "net_pass_sr": "0.032526055624655",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1639318157575873
    },
    {
        "id": 237,
        "name": "P.J. Fleck",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.91767323241612e-7,
            "degree": 7,
            "eigen": 0.000195797361979984
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2014": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2015": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2016": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2017": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2018": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2019": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2020": {
                "college": "Minnesota",
                "position": "HC"
            },
            "2021": {
                "college": "Minnesota",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "HC",
                "net_ppa": "0.006757141",
                "net_sr": "-0.003937158",
                "net_stuff": "0.019440265",
                "net_pass_sr": "-0.023819547",
                "net_fpi": "0.36",
                "total_sr": "-0.019685792",
                "total_stuffs": "0.097201325",
                "total_pass_sr": "-0.119097734",
                "total_ppa": "0.033785706"
            },
            {
                "college": "Western Michigan",
                "role": "HC",
                "net_ppa": "0.060529846",
                "net_sr": "-0.005568582",
                "net_stuff": "-0.012347913",
                "net_pass_sr": "-0.004021607",
                "net_fpi": "N/A",
                "total_sr": "-0.022274328",
                "total_stuffs": "-0.049391652",
                "total_pass_sr": "-0.016086426",
                "total_ppa": "0.242119385"
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.06728698699999999
    },
    {
        "id": 240,
        "name": "Joe Moorhead",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 30,
            "closeness": 4.89363440929673e-7,
            "degree": 6,
            "eigen": 0.000224299903231527
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Akron",
                "position": "OC"
            },
            "2007": {
                "college": "Akron",
                "position": "OC"
            },
            "2008": {
                "college": "Akron",
                "position": "OC"
            },
            "2009": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2010": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Penn State",
                "position": "OC"
            },
            "2017": {
                "college": "Penn State",
                "position": "OC"
            },
            "2018": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2019": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2020": {
                "college": "Oregon",
                "position": "OC"
            },
            "2021": {
                "college": "Oregon",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Mississippi State",
                "role": "HC",
                "net_ppa": "0.08498736",
                "net_sr": "0.010491333",
                "net_stuff": "-0.03585736",
                "net_pass_sr": "-0.041873159",
                "net_fpi": "N/A",
                "total_sr": "0.020982665",
                "total_stuffs": "-0.07171472",
                "total_pass_sr": "-0.083746318",
                "total_ppa": "0.16997472"
            },
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "-0.0158768586173721",
                "net_sr": "-0.0364939305135351",
                "net_stuff": "0.133502983215316",
                "net_pass_sr": "-0.0189574983043488",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "-0.299592123917369",
                "net_sr": "-0.105307552825418",
                "net_stuff": "0.0814625320912363",
                "net_pass_sr": "-0.0999350639681772",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "-0.0182838189170048",
                "net_sr": "-0.00248846581322637",
                "net_stuff": "-0.0702740601178714",
                "net_pass_sr": "-0.0234944588996405",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "0.00630254039140625",
                "net_sr": "-0.0434208884021962",
                "net_stuff": "0.0769334932677724",
                "net_pass_sr": "-0.0500357451105524",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.24246290106033963
    },
    {
        "id": 241,
        "name": "Mike Leach",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.17428010240935e-7,
            "degree": 9,
            "eigen": 0.000834039441617092
        },
        "positions": {
            "2000": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2001": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2002": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2003": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2004": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2005": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2006": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2007": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2008": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2009": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Washington State",
                "position": "HC"
            },
            "2013": {
                "college": "Washington State",
                "position": "HC"
            },
            "2014": {
                "college": "Washington State",
                "position": "HC"
            },
            "2015": {
                "college": "Washington State",
                "position": "HC"
            },
            "2016": {
                "college": "Washington State",
                "position": "HC"
            },
            "2017": {
                "college": "Washington State",
                "position": "HC"
            },
            "2018": {
                "college": "Washington State",
                "position": "HC"
            },
            "2019": {
                "college": "Washington State",
                "position": "HC"
            },
            "2020": {
                "college": "Mississippi State",
                "position": "HC"
            },
            "2021": {
                "college": "Mississippi State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Mississippi State",
                "role": "HC",
                "net_ppa": "-0.157891981",
                "net_sr": "-0.021011901",
                "net_stuff": "0.037931947",
                "net_pass_sr": "0.026956545",
                "net_fpi": "N/A",
                "total_sr": "-0.042023802",
                "total_stuffs": "0.075863895",
                "total_pass_sr": "0.053913089",
                "total_ppa": "-0.315783963"
            },
            {
                "college": "Texas Tech",
                "role": "HC",
                "net_ppa": "-0.070012046",
                "net_sr": "-0.009678503",
                "net_stuff": "-0.025769705",
                "net_pass_sr": "-0.007401433",
                "net_fpi": "-0.366666667",
                "total_sr": "-0.009678503",
                "total_stuffs": "-0.025769705",
                "total_pass_sr": "-0.007401433",
                "total_ppa": "-0.070012046"
            },
            {
                "college": "Washington State",
                "role": "HC",
                "net_ppa": "0.153786001",
                "net_sr": "0.129848147",
                "net_stuff": "-0.092791523",
                "net_pass_sr": "0.120641581",
                "net_fpi": "N/A",
                "total_sr": "1.038785173",
                "total_stuffs": "-0.742332188",
                "total_pass_sr": "0.965132652",
                "total_ppa": "1.23028801"
            }
        ],
        "yearsCoached": 20,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.07411802599999998
    },
    {
        "id": 244,
        "name": "Barry Odom",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 143,
            "closeness": 4.98753863472115e-7,
            "degree": 7,
            "eigen": 0.000724202296553913
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Memphis",
                "position": "DC"
            },
            "2013": {
                "college": "Memphis",
                "position": "DC"
            },
            "2014": {
                "college": "Memphis",
                "position": "DC"
            },
            "2015": {
                "college": "Missouri",
                "position": "DC"
            },
            "2016": {
                "college": "Missouri",
                "position": "HC"
            },
            "2017": {
                "college": "Missouri",
                "position": "HC"
            },
            "2018": {
                "college": "Missouri",
                "position": "HC"
            },
            "2019": {
                "college": "Missouri",
                "position": "HC"
            },
            "2020": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Missouri",
                "role": "HC",
                "net_ppa": "0.073422524",
                "net_sr": "0.072042231",
                "net_stuff": "-0.048888377",
                "net_pass_sr": "0.106950522",
                "net_fpi": "-5.658333333",
                "total_sr": "0.288168925",
                "total_stuffs": "-0.19555351",
                "total_pass_sr": "0.427802086",
                "total_ppa": "0.293690096"
            },
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "0.0812229183792694",
                "net_sr": "0.00356766398895103",
                "net_stuff": "-0.00211323947332828",
                "net_pass_sr": "0.0059665683322207",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "0.0490877423374007",
                "net_sr": "0.0517601667391164",
                "net_stuff": "-0.0313622822165552",
                "net_pass_sr": "0.0589948422256717",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Missouri",
                "role": "OC",
                "net_ppa": "0.0416528121761673",
                "net_sr": "0.0300993403740789",
                "net_stuff": "-0.0422124894317363",
                "net_pass_sr": "0.0398199259021661",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2453859968928374
    },
    {
        "id": 245,
        "name": "Ken Niumatalolo",
        "community": 6,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90387425681786e-7,
            "degree": 5,
            "eigen": 0.000115132185693718
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Navy",
                "position": "HC"
            },
            "2009": {
                "college": "Navy",
                "position": "HC"
            },
            "2010": {
                "college": "Navy",
                "position": "HC"
            },
            "2011": {
                "college": "Navy",
                "position": "HC"
            },
            "2012": {
                "college": "Navy",
                "position": "HC"
            },
            "2013": {
                "college": "Navy",
                "position": "HC"
            },
            "2014": {
                "college": "Navy",
                "position": "HC"
            },
            "2015": {
                "college": "Navy",
                "position": "HC"
            },
            "2016": {
                "college": "Navy",
                "position": "HC"
            },
            "2017": {
                "college": "Navy",
                "position": "HC"
            },
            "2018": {
                "college": "Navy",
                "position": "HC"
            },
            "2019": {
                "college": "Navy",
                "position": "HC"
            },
            "2020": {
                "college": "Navy",
                "position": "HC"
            },
            "2021": {
                "college": "Navy",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Navy",
                "role": "HC",
                "net_ppa": "-0.030951872",
                "net_sr": "-0.023876901",
                "net_stuff": "0.029151638",
                "net_pass_sr": "-0.011709163",
                "net_fpi": "0.252380952",
                "total_sr": "-0.334276612",
                "total_stuffs": "0.40812293",
                "total_pass_sr": "-0.163928288",
                "total_ppa": "-0.433326202"
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "HC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.030951872
    },
    {
        "id": 246,
        "name": "Chuck Amato",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 13,
            "closeness": 4.93156954104841e-7,
            "degree": 8,
            "eigen": 0.000101983162906132
        },
        "positions": {
            "2000": {
                "college": "NC State",
                "position": "HC"
            },
            "2001": {
                "college": "NC State",
                "position": "HC"
            },
            "2002": {
                "college": "NC State",
                "position": "HC"
            },
            "2003": {
                "college": "NC State",
                "position": "HC"
            },
            "2004": {
                "college": "NC State",
                "position": "HC"
            },
            "2005": {
                "college": "NC State",
                "position": "HC"
            },
            "2006": {
                "college": "NC State",
                "position": "HC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Akron",
                "position": "DC"
            },
            "2013": {
                "college": "Akron",
                "position": "DC"
            },
            "2014": {
                "college": "Akron",
                "position": "DC"
            },
            "2015": {
                "college": "Akron",
                "position": "DC"
            },
            "2016": {
                "college": "Akron",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "-0.0399764566812918",
                "net_sr": "0.0173414089433671",
                "net_stuff": "-0.0195232366318112",
                "net_pass_sr": "0.0432131674500236",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0399764566812918
    },
    {
        "id": 247,
        "name": "Dave Doeren",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 247,
            "closeness": 4.97353581592347e-7,
            "degree": 12,
            "eigen": 0.00207555423437938
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Kansas",
                "position": "DC"
            },
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2009": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2010": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2011": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2012": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2013": {
                "college": "NC State",
                "position": "HC"
            },
            "2014": {
                "college": "NC State",
                "position": "HC"
            },
            "2015": {
                "college": "NC State",
                "position": "HC"
            },
            "2016": {
                "college": "NC State",
                "position": "HC"
            },
            "2017": {
                "college": "NC State",
                "position": "HC"
            },
            "2018": {
                "college": "NC State",
                "position": "HC"
            },
            "2019": {
                "college": "NC State",
                "position": "HC"
            },
            "2020": {
                "college": "NC State",
                "position": "HC"
            },
            "2021": {
                "college": "NC State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "NC State",
                "role": "HC",
                "net_ppa": "0.031511035",
                "net_sr": "0.037666629",
                "net_stuff": "0.010420191",
                "net_pass_sr": "0.019987852",
                "net_fpi": "-0.788888889",
                "total_sr": "0.338999659",
                "total_stuffs": "0.093781715",
                "total_pass_sr": "0.17989067",
                "total_ppa": "0.283599317"
            },
            {
                "college": "Northern Illinois",
                "role": "HC",
                "net_ppa": "0.027195816",
                "net_sr": "-0.002949773",
                "net_stuff": "0.020915376",
                "net_pass_sr": "-0.025132734",
                "net_fpi": "N/A",
                "total_sr": "-0.005899546",
                "total_stuffs": "0.041830752",
                "total_pass_sr": "-0.050265467",
                "total_ppa": "0.054391632"
            },
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "0.00722506920543095",
                "net_sr": "-0.00432789869086397",
                "net_stuff": "-0.026663687450616",
                "net_pass_sr": "-0.0404988292373928",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.06593192020543095
    },
    {
        "id": 250,
        "name": "Bo Pelini",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 47.5,
            "closeness": 4.91076405104641e-7,
            "degree": 8,
            "eigen": 0.0535069387945775
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2004": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2005": {
                "college": "LSU",
                "position": "DC"
            },
            "2006": {
                "college": "LSU",
                "position": "DC"
            },
            "2007": {
                "college": "LSU",
                "position": "DC"
            },
            "2008": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2009": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2010": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2011": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2012": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2013": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2014": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "LSU",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Nebraska",
                "role": "HC",
                "net_ppa": "0.08108071",
                "net_sr": "0.031654033",
                "net_stuff": "0.065708276",
                "net_pass_sr": "0.028609554",
                "net_fpi": "4.60952381",
                "total_sr": "0.221578229",
                "total_stuffs": "0.459957935",
                "total_pass_sr": "0.20026688",
                "total_ppa": "0.567564969"
            },
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.176936639152397",
                "net_sr": "-0.0943686654164367",
                "net_stuff": "-0.000830638676880907",
                "net_pass_sr": "-0.105131548821563",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.09585592915239699
    },
    {
        "id": 251,
        "name": "Mike Riley",
        "community": 12,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.45009322384459e-7,
            "degree": 5,
            "eigen": 0.000339638292631592
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2004": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2005": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2006": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2007": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2008": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2009": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2010": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2011": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2012": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2013": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2014": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2015": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2016": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2017": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nebraska",
                "role": "HC",
                "net_ppa": "-0.141294275",
                "net_sr": "-0.084513317",
                "net_stuff": "0.013929439",
                "net_pass_sr": "-0.093889978",
                "net_fpi": "-6.933333333",
                "total_sr": "-0.253539952",
                "total_stuffs": "0.041788316",
                "total_pass_sr": "-0.281669934",
                "total_ppa": "-0.423882826"
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.141294275
    },
    {
        "id": 252,
        "name": "Scott Frost",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 8,
            "closeness": 4.89704213757848e-7,
            "degree": 4,
            "eigen": 0.00313198071292459
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Oregon",
                "position": "OC"
            },
            "2014": {
                "college": "Oregon",
                "position": "OC"
            },
            "2015": {
                "college": "Oregon",
                "position": "OC"
            },
            "2016": {
                "college": "UCF",
                "position": "HC"
            },
            "2017": {
                "college": "UCF",
                "position": "HC"
            },
            "2018": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2019": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2020": {
                "college": "Nebraska",
                "position": "HC"
            },
            "2021": {
                "college": "Nebraska",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Nebraska",
                "role": "HC",
                "net_ppa": "0.078346686",
                "net_sr": "0.045342706",
                "net_stuff": "0.001084785",
                "net_pass_sr": "0.065721697",
                "net_fpi": "0.483333333",
                "total_sr": "0.181370822",
                "total_stuffs": "0.004339141",
                "total_pass_sr": "0.262886787",
                "total_ppa": "0.313386742"
            },
            {
                "college": "UCF",
                "role": "HC",
                "net_ppa": "0.223542234",
                "net_sr": "0.051537518",
                "net_stuff": "0.050463899",
                "net_pass_sr": "0.084274611",
                "net_fpi": "7.916666667",
                "total_sr": "0.103075036",
                "total_stuffs": "0.100927797",
                "total_pass_sr": "0.168549222",
                "total_ppa": "0.447084468"
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "0.160126194601858",
                "net_sr": "0.0408290290168882",
                "net_stuff": "-0.0449785123552038",
                "net_pass_sr": "0.0426512575495183",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.462015114601858
    },
    {
        "id": 253,
        "name": "Chris Tormey",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 9,
            "closeness": 4.89362243549716e-7,
            "degree": 3,
            "eigen": 0.0000506472140730981
        },
        "positions": {
            "2000": {
                "college": "Nevada",
                "position": "HC"
            },
            "2001": {
                "college": "Nevada",
                "position": "HC"
            },
            "2002": {
                "college": "Nevada",
                "position": "HC"
            },
            "2003": {
                "college": "Nevada",
                "position": "HC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2013": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "-0.0473854863890701",
                "net_sr": "0.00267430418746517",
                "net_stuff": "0.0115065323684936",
                "net_pass_sr": "-0.0429781129005198",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0473854863890701
    },
    {
        "id": 255,
        "name": "Brian Polian",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.92457520614272e-7,
            "degree": 5,
            "eigen": 0.000740014171220969
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Nevada",
                "position": "HC"
            },
            "2014": {
                "college": "Nevada",
                "position": "HC"
            },
            "2015": {
                "college": "Nevada",
                "position": "HC"
            },
            "2016": {
                "college": "Nevada",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nevada",
                "role": "HC",
                "net_ppa": "-0.163895982",
                "net_sr": "-0.134114183",
                "net_stuff": "0.022163619",
                "net_pass_sr": "-0.157111055",
                "net_fpi": "-10.25833333",
                "total_sr": "-0.536456733",
                "total_stuffs": "0.088654478",
                "total_pass_sr": "-0.62844422",
                "total_ppa": "-0.655583928"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.163895982
    },
    {
        "id": 256,
        "name": "Jay Norvell",
        "community": 38,
        "isWhite": false,
        "centrality": {
            "betweenness": 42,
            "closeness": 4.89704693378752e-7,
            "degree": 7,
            "eigen": 0.00629443516967414
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2005": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2006": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2007": {
                "college": "UCLA",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2012": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2013": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2014": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2015": {
                "college": "Texas",
                "position": "OC"
            },
            "2016": false,
            "2017": {
                "college": "Nevada",
                "position": "HC"
            },
            "2018": {
                "college": "Nevada",
                "position": "HC"
            },
            "2019": {
                "college": "Nevada",
                "position": "HC"
            },
            "2020": {
                "college": "Nevada",
                "position": "HC"
            },
            "2021": {
                "college": "Nevada",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Nevada",
                "role": "HC",
                "net_ppa": "0.055385968",
                "net_sr": "0.018516862",
                "net_stuff": "0.021528196",
                "net_pass_sr": "0.025015406",
                "net_fpi": "-0.033333333",
                "total_sr": "0.092584308",
                "total_stuffs": "0.10764098",
                "total_pass_sr": "0.125077028",
                "total_ppa": "0.27692984"
            },
            {
                "college": "Oklahoma",
                "role": "OC",
                "net_ppa": "0.0633862250559446",
                "net_sr": "0.0274412548442044",
                "net_stuff": "-0.0139728294506094",
                "net_pass_sr": "0.00204492222595026",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.0933540768925223",
                "net_sr": "-0.00903188094328111",
                "net_stuff": "-0.00162544063746259",
                "net_pass_sr": "-0.0957414525254024",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "-0.083731168789857",
                "net_sr": "-0.0373909805405561",
                "net_stuff": "0.0059829053329325",
                "net_pass_sr": "-0.0591441011495057",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.12839510115860991
    },
    {
        "id": 257,
        "name": "Rocky Long",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 180,
            "closeness": 4.98409326634047e-7,
            "degree": 12,
            "eigen": 0.00182151005766989
        },
        "positions": {
            "2000": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2001": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2002": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2003": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2004": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2005": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2006": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2007": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2008": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2009": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2010": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2011": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2012": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2013": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2014": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2015": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2016": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2017": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2018": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2019": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2020": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2021": {
                "college": "New Mexico",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "San Diego State",
                "role": "HC",
                "net_ppa": "0.085343194",
                "net_sr": "0.041583201",
                "net_stuff": "-0.027288061",
                "net_pass_sr": "0.049944382",
                "net_fpi": "8.022222222",
                "total_sr": "0.374248811",
                "total_stuffs": "-0.245592551",
                "total_pass_sr": "0.449499441",
                "total_ppa": "0.768088744"
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "-0.241852999105136",
                "net_sr": "-0.0543716590316707",
                "net_stuff": "0.0569666651374322",
                "net_pass_sr": "-0.0355421131021052",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "-0.046233690618824",
                "net_sr": "-0.0335227813957704",
                "net_stuff": "0.0812218805829105",
                "net_pass_sr": "-0.0558255284950891",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 22,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.20274349572396
    },
    {
        "id": 258,
        "name": "Bob Davie",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.91424157035572e-7,
            "degree": 8,
            "eigen": 0.000321636107457029
        },
        "positions": {
            "2000": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2001": {
                "college": "Notre Dame",
                "position": "HC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2013": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2014": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2015": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2016": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2017": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2018": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2019": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico",
                "role": "HC",
                "net_ppa": "0.044333653",
                "net_sr": "0.073466527",
                "net_stuff": "-0.041870591",
                "net_pass_sr": "0.022470184",
                "net_fpi": "9.6",
                "total_sr": "0.587732215",
                "total_stuffs": "-0.334964732",
                "total_pass_sr": "0.179761468",
                "total_ppa": "0.354669223"
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.044333653
    },
    {
        "id": 259,
        "name": "Danny Gonzales",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 34,
            "closeness": 4.98396409552266e-7,
            "degree": 4,
            "eigen": 0.000257507101362744
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2018": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "New Mexico",
                "position": "HC"
            },
            "2021": {
                "college": "New Mexico",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "New Mexico",
                "role": "HC",
                "net_ppa": "-0.077479458",
                "net_sr": "-0.045677535",
                "net_stuff": "-0.017097034",
                "net_pass_sr": "-0.03199521",
                "net_fpi": "-0.35",
                "total_sr": "-0.091355071",
                "total_stuffs": "-0.034194068",
                "total_pass_sr": "-0.063990419",
                "total_ppa": "-0.154958915"
            },
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.055180531986821",
                "net_sr": "-0.0268362453304882",
                "net_stuff": "0.0318555291458259",
                "net_pass_sr": "-0.0238898791748757",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "-0.0266805400753042",
                "net_sr": "-0.0222768058044904",
                "net_stuff": "0.0473220173468303",
                "net_pass_sr": "0.00323473823509024",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.048979466088483206
    },
    {
        "id": 261,
        "name": "DeWayne Walker",
        "community": 32,
        "isWhite": false,
        "centrality": {
            "betweenness": 197,
            "closeness": 4.96296141893052e-7,
            "degree": 8,
            "eigen": 0.000117491933386539
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "UCLA",
                "position": "DC"
            },
            "2007": {
                "college": "UCLA",
                "position": "DC"
            },
            "2008": {
                "college": "UCLA",
                "position": "DC"
            },
            "2009": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2010": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2011": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2012": {
                "college": "New Mexico State",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico State",
                "role": "HC",
                "net_ppa": "-0.069306024",
                "net_sr": "-0.025627484",
                "net_stuff": "-0.033833716",
                "net_pass_sr": "-0.077046328",
                "net_fpi": "N/A",
                "total_sr": "-0.102509935",
                "total_stuffs": "-0.135334862",
                "total_pass_sr": "-0.308185312",
                "total_ppa": "-0.277224097"
            },
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "0.197836350555337",
                "net_sr": "0.0871224207015374",
                "net_stuff": "-0.0304142762158935",
                "net_pass_sr": "0.0444377029291725",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.128530326555337
    },
    {
        "id": 262,
        "name": "Carl Torbush",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 8,
            "closeness": 4.89363680406368e-7,
            "degree": 5,
            "eigen": 0.000284582901148676
        },
        "positions": {
            "2000": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2001": {
                "college": "Alabama",
                "position": "DC"
            },
            "2002": {
                "college": "Alabama",
                "position": "DC"
            },
            "2003": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2004": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2005": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2010": {
                "college": "Kansas",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.0882954362136048",
                "net_sr": "-0.083559930230266",
                "net_stuff": "0.0873954642269568",
                "net_pass_sr": "-0.0580896674670264",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.0170817741296702",
                "net_sr": "-0.0419537973210684",
                "net_stuff": "0.0146731385590736",
                "net_pass_sr": "-0.0635016684146539",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0712136620839346
    },
    {
        "id": 264,
        "name": "Everett Withers",
        "community": 10,
        "isWhite": false,
        "centrality": {
            "betweenness": 11.5,
            "closeness": 4.90049063712259e-7,
            "degree": 7,
            "eigen": 0.000621450857176203
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2008": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2009": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2010": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2011": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2012": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2013": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Texas State",
                "position": "HC"
            },
            "2017": {
                "college": "Texas State",
                "position": "HC"
            },
            "2018": {
                "college": "Texas State",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Carolina",
                "role": "HC",
                "net_ppa": "0.033747629",
                "net_sr": "0.021834725",
                "net_stuff": "0.001007164",
                "net_pass_sr": "0.029865347",
                "net_fpi": "N/A",
                "total_sr": "0.021834725",
                "total_stuffs": "0.001007164",
                "total_pass_sr": "0.029865347",
                "total_ppa": "0.033747629"
            },
            {
                "college": "Texas State",
                "role": "HC",
                "net_ppa": "-0.090588794",
                "net_sr": "-0.040939675",
                "net_stuff": "0.029054954",
                "net_pass_sr": "-0.00679183",
                "net_fpi": "-11.9",
                "total_sr": "-0.122819025",
                "total_stuffs": "0.087164863",
                "total_pass_sr": "-0.020375491",
                "total_ppa": "-0.271766382"
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "-0.0519070872799924",
                "net_sr": "-0.0267121045082878",
                "net_stuff": "-0.00499040490066577",
                "net_pass_sr": "-0.0375841168301956",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "0.08047183899954",
                "net_sr": "0.0312065859173005",
                "net_stuff": "-0.0350973818282762",
                "net_pass_sr": "0.0116898634017609",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "-0.0651762355787187",
                "net_sr": "-0.0337136047884945",
                "net_stuff": "0.0461502358218919",
                "net_pass_sr": "-0.0314457713909644",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.09345264885917111
    },
    {
        "id": 265,
        "name": "Larry Fedora",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 2052,
            "closeness": 5.34762503950558e-7,
            "degree": 13,
            "eigen": 0.00192199082277488
        },
        "positions": {
            "2000": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2001": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Florida",
                "position": "OC"
            },
            "2005": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2006": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2007": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2008": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2009": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2010": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2011": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2012": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2013": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2014": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2015": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2016": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2017": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2018": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2019": false,
            "2020": {
                "college": "Baylor",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "North Carolina",
                "role": "HC",
                "net_ppa": "-0.054289392",
                "net_sr": "-0.01695137",
                "net_stuff": "0.037147026",
                "net_pass_sr": "0.005148729",
                "net_fpi": "N/A",
                "total_sr": "-0.118659591",
                "total_stuffs": "0.260029185",
                "total_pass_sr": "0.036041101",
                "total_ppa": "-0.380025741"
            },
            {
                "college": "Southern Mississippi",
                "role": "HC",
                "net_ppa": "0.061532524",
                "net_sr": "0.040660073",
                "net_stuff": "-0.039907061",
                "net_pass_sr": "0.025143097",
                "net_fpi": "N/A",
                "total_sr": "0.162640291",
                "total_stuffs": "-0.159628242",
                "total_pass_sr": "0.100572389",
                "total_ppa": "0.246130097"
            },
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "-0.150083659395249",
                "net_sr": "-0.0833248262879713",
                "net_stuff": "0.0526101441622028",
                "net_pass_sr": "-0.0498460848316454",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 18,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.142840527395249
    },
    {
        "id": 266,
        "name": "Mack Brown",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.65847056067521e-7,
            "degree": 14,
            "eigen": 0.00339626777614762
        },
        "positions": {
            "2000": {
                "college": "Texas",
                "position": "HC"
            },
            "2001": {
                "college": "Texas",
                "position": "HC"
            },
            "2002": {
                "college": "Texas",
                "position": "HC"
            },
            "2003": {
                "college": "Texas",
                "position": "HC"
            },
            "2004": {
                "college": "Texas",
                "position": "HC"
            },
            "2005": {
                "college": "Texas",
                "position": "HC"
            },
            "2006": {
                "college": "Texas",
                "position": "HC"
            },
            "2007": {
                "college": "Texas",
                "position": "HC"
            },
            "2008": {
                "college": "Texas",
                "position": "HC"
            },
            "2009": {
                "college": "Texas",
                "position": "HC"
            },
            "2010": {
                "college": "Texas",
                "position": "HC"
            },
            "2011": {
                "college": "Texas",
                "position": "HC"
            },
            "2012": {
                "college": "Texas",
                "position": "HC"
            },
            "2013": {
                "college": "Texas",
                "position": "HC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2020": {
                "college": "North Carolina",
                "position": "HC"
            },
            "2021": {
                "college": "North Carolina",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "North Carolina",
                "role": "HC",
                "net_ppa": "0.146738862",
                "net_sr": "0.065460144",
                "net_stuff": "-0.029054241",
                "net_pass_sr": "0.045372476",
                "net_fpi": "N/A",
                "total_sr": "0.196380431",
                "total_stuffs": "-0.087162722",
                "total_pass_sr": "0.136117429",
                "total_ppa": "0.440216587"
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.146738862
    },
    {
        "id": 267,
        "name": "Darrell Dickey",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 96,
            "closeness": 4.90047622827986e-7,
            "degree": 9,
            "eigen": 0.000968465477596437
        },
        "positions": {
            "2000": {
                "college": "North Texas",
                "position": "HC"
            },
            "2001": {
                "college": "North Texas",
                "position": "HC"
            },
            "2002": {
                "college": "North Texas",
                "position": "HC"
            },
            "2003": {
                "college": "North Texas",
                "position": "HC"
            },
            "2004": {
                "college": "North Texas",
                "position": "HC"
            },
            "2005": {
                "college": "North Texas",
                "position": "HC"
            },
            "2006": {
                "college": "North Texas",
                "position": "HC"
            },
            "2007": {
                "college": "Utah State",
                "position": "OC"
            },
            "2008": {
                "college": "Utah State",
                "position": "OC"
            },
            "2009": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2010": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2011": false,
            "2012": {
                "college": "Memphis",
                "position": "OC"
            },
            "2013": {
                "college": "Memphis",
                "position": "OC"
            },
            "2014": {
                "college": "Memphis",
                "position": "OC"
            },
            "2015": {
                "college": "Memphis",
                "position": "OC"
            },
            "2016": false,
            "2017": {
                "college": "Memphis",
                "position": "OC"
            },
            "2018": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2019": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2020": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2021": {
                "college": "Texas A&M",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "0.199254013642882",
                "net_sr": "0.059054398147228",
                "net_stuff": "-0.053209148637074",
                "net_pass_sr": "0.0720028470941627",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "-0.0447250051870625",
                "net_sr": "-0.0375131101574721",
                "net_stuff": "0.032296314262745",
                "net_pass_sr": "-0.0332989451307511",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "0.0533323890376452",
                "net_sr": "0.0512420848085087",
                "net_stuff": "0.0163455429807517",
                "net_pass_sr": "0.0406916075463221",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.08827126398413",
                "net_sr": "0.0233571322417077",
                "net_stuff": "-0.088388368279891",
                "net_pass_sr": "0.0560563269948578",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 20,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2961326614775947
    },
    {
        "id": 268,
        "name": "Todd Dodge",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89705412811869e-7,
            "degree": 3,
            "eigen": 0.0000181540932655411
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "North Texas",
                "position": "HC"
            },
            "2008": {
                "college": "North Texas",
                "position": "HC"
            },
            "2009": {
                "college": "North Texas",
                "position": "HC"
            },
            "2010": {
                "college": "North Texas",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "HC",
                "net_ppa": "0.225204355",
                "net_sr": "0.175759147",
                "net_stuff": "-0.109107027",
                "net_pass_sr": "0.217387544",
                "net_fpi": "0.65",
                "total_sr": "0.703036587",
                "total_stuffs": "-0.436428106",
                "total_pass_sr": "0.869550177",
                "total_ppa": "0.90081742"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.225204355
    },
    {
        "id": 269,
        "name": "Seth Littrell",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 113.5,
            "closeness": 4.9246067332178e-7,
            "degree": 10,
            "eigen": 0.00317256206664713
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Arizona",
                "position": "OC"
            },
            "2011": {
                "college": "Arizona",
                "position": "OC"
            },
            "2012": {
                "college": "Indiana",
                "position": "OC"
            },
            "2013": {
                "college": "Indiana",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "North Texas",
                "position": "HC"
            },
            "2017": {
                "college": "North Texas",
                "position": "HC"
            },
            "2018": {
                "college": "North Texas",
                "position": "HC"
            },
            "2019": {
                "college": "North Texas",
                "position": "HC"
            },
            "2020": {
                "college": "North Texas",
                "position": "HC"
            },
            "2021": {
                "college": "North Texas",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "HC",
                "net_ppa": "0.127621693",
                "net_sr": "0.049370638",
                "net_stuff": "-0.007835777",
                "net_pass_sr": "0.062026705",
                "net_fpi": "0.25",
                "total_sr": "0.296223828",
                "total_stuffs": "-0.047014661",
                "total_pass_sr": "0.372160228",
                "total_ppa": "0.76573016"
            },
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "0.0943001203148314",
                "net_sr": "0.0527128931862639",
                "net_stuff": "0.00408232969956712",
                "net_pass_sr": "0.0805262815093915",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.0436694329526574",
                "net_sr": "0.0229898391878952",
                "net_stuff": "-0.01537255117946",
                "net_pass_sr": "0.0173314168364773",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2655912462674888
    },
    {
        "id": 271,
        "name": "Rod Carey",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 48,
            "closeness": 4.90390070974155e-7,
            "degree": 6,
            "eigen": 0.000201297804159665
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2013": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2014": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2015": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2016": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2017": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2018": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2019": {
                "college": "Temple",
                "position": "HC"
            },
            "2020": {
                "college": "Temple",
                "position": "HC"
            },
            "2021": {
                "college": "Temple",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Northern Illinois",
                "role": "HC",
                "net_ppa": "-0.03129885",
                "net_sr": "-0.021154723",
                "net_stuff": "0.038836714",
                "net_pass_sr": "-0.040843979",
                "net_fpi": "N/A",
                "total_sr": "-0.126928337",
                "total_stuffs": "0.233020285",
                "total_pass_sr": "-0.245063876",
                "total_ppa": "-0.187793101"
            },
            {
                "college": "Temple",
                "role": "HC",
                "net_ppa": "-0.200172203",
                "net_sr": "-0.087230458",
                "net_stuff": "0.008263586",
                "net_pass_sr": "-0.079543905",
                "net_fpi": "-15.63333333",
                "total_sr": "-0.261691375",
                "total_stuffs": "0.024790757",
                "total_pass_sr": "-0.238631716",
                "total_ppa": "-0.600516608"
            },
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "-0.0263174671226756",
                "net_sr": "-0.0108932466036851",
                "net_stuff": "-0.0265196487331172",
                "net_pass_sr": "-0.0342956713897214",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.25778852012267556
    },
    {
        "id": 272,
        "name": "Thomas Hammock",
        "community": 38,
        "isWhite": false,
        "centrality": {
            "betweenness": 2,
            "closeness": 4.89362961976987e-7,
            "degree": 3,
            "eigen": 0.00000950657947181889
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2020": {
                "college": "Northern Illinois",
                "position": "HC"
            },
            "2021": {
                "college": "Northern Illinois",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Northern Illinois",
                "role": "HC",
                "net_ppa": "-0.105185097",
                "net_sr": "-0.070454969",
                "net_stuff": "0.033843491",
                "net_pass_sr": "-0.054912054",
                "net_fpi": "N/A",
                "total_sr": "-0.211364908",
                "total_stuffs": "0.101530474",
                "total_pass_sr": "-0.164736161",
                "total_ppa": "-0.31555529"
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.00657201088093955",
                "net_sr": "-0.00580551034117482",
                "net_stuff": "0.00436590108284929",
                "net_pass_sr": "-0.00851592361111803",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.09861308611906046
    },
    {
        "id": 274,
        "name": "Pat Fitzgerald",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.02323497336932e-7,
            "degree": 6,
            "eigen": 0.000694993736582061
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2007": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2008": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2009": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2010": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2011": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2012": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2013": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2014": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2015": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2016": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2017": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2018": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2019": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2020": {
                "college": "Northwestern",
                "position": "HC"
            },
            "2021": {
                "college": "Northwestern",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Northwestern",
                "role": "HC",
                "net_ppa": "0.002434441",
                "net_sr": "0.010758926",
                "net_stuff": "-0.076658031",
                "net_pass_sr": "0.001993396",
                "net_fpi": "-2.3875",
                "total_sr": "0.172142817",
                "total_stuffs": "-1.226528491",
                "total_pass_sr": "0.03189434",
                "total_ppa": "0.038951052"
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.002434441
    },
    {
        "id": 276,
        "name": "Brian Knorr",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 47,
            "closeness": 4.91421742070419e-7,
            "degree": 6,
            "eigen": 0.0019612964885049
        },
        "positions": {
            "2000": {
                "college": "Ohio",
                "position": "DC"
            },
            "2001": {
                "college": "Ohio",
                "position": "HC"
            },
            "2002": {
                "college": "Ohio",
                "position": "HC"
            },
            "2003": {
                "college": "Ohio",
                "position": "HC"
            },
            "2004": {
                "college": "Ohio",
                "position": "HC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2012": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2013": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2014": {
                "college": "Indiana",
                "position": "DC"
            },
            "2015": {
                "college": "Indiana",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "-0.0806411890017293",
                "net_sr": "0.0227725710932018",
                "net_stuff": "-0.0245472736624431",
                "net_pass_sr": "-0.000510830912796911",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wake Forest",
                "role": "OC",
                "net_ppa": "0.022037821602172",
                "net_sr": "0.00451598731902209",
                "net_stuff": "-0.0458155469701695",
                "net_pass_sr": "-0.0122244417198187",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0586033673995573
    },
    {
        "id": 277,
        "name": "Tim Albin",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 1,
            "closeness": 4.90049544008899e-7,
            "degree": 5,
            "eigen": 0.699252137276695
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Ohio",
                "position": "OC"
            },
            "2006": {
                "college": "Ohio",
                "position": "OC"
            },
            "2007": {
                "college": "Ohio",
                "position": "OC"
            },
            "2008": {
                "college": "Ohio",
                "position": "OC"
            },
            "2009": {
                "college": "Ohio",
                "position": "OC"
            },
            "2010": {
                "college": "Ohio",
                "position": "OC"
            },
            "2011": {
                "college": "Ohio",
                "position": "OC"
            },
            "2012": {
                "college": "Ohio",
                "position": "OC"
            },
            "2013": {
                "college": "Ohio",
                "position": "OC"
            },
            "2014": false,
            "2015": {
                "college": "Ohio",
                "position": "OC"
            },
            "2016": {
                "college": "Ohio",
                "position": "OC"
            },
            "2017": {
                "college": "Ohio",
                "position": "OC"
            },
            "2018": {
                "college": "Ohio",
                "position": "OC"
            },
            "2019": {
                "college": "Ohio",
                "position": "OC"
            },
            "2020": {
                "college": "Ohio",
                "position": "OC"
            },
            "2021": {
                "college": "Ohio",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Ohio",
                "role": "HC",
                "net_ppa": "-0.1555792",
                "net_sr": "-0.079103913",
                "net_stuff": "0.03302585",
                "net_pass_sr": "-0.129689954",
                "net_fpi": "-14.43333333",
                "total_sr": "-0.079103913",
                "total_stuffs": "0.03302585",
                "total_pass_sr": "-0.129689954",
                "total_ppa": "-0.1555792"
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1555792
    },
    {
        "id": 280,
        "name": "Ryan Day",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 66,
            "closeness": 4.99813319725083e-7,
            "degree": 6,
            "eigen": 0.0029462747040228
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Temple",
                "position": "OC"
            },
            "2013": {
                "college": "Boston College",
                "position": "OC"
            },
            "2014": {
                "college": "Boston College",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2018": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2019": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2020": {
                "college": "Ohio State",
                "position": "HC"
            },
            "2021": {
                "college": "Ohio State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Ohio State",
                "role": "HC",
                "net_ppa": "0.037462481",
                "net_sr": "-0.023333373",
                "net_stuff": "0.028585099",
                "net_pass_sr": "0.027356321",
                "net_fpi": "N/A",
                "total_sr": "-0.070000119",
                "total_stuffs": "0.085755296",
                "total_pass_sr": "0.082068964",
                "total_ppa": "0.112387444"
            },
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "0.132392845946501",
                "net_sr": "0.038056427755045",
                "net_stuff": "-0.0628413740524397",
                "net_pass_sr": "0.045338644021945",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "0.0338096061437838",
                "net_sr": "0.011008177230475",
                "net_stuff": "-0.00127404530262948",
                "net_pass_sr": "0.0560657587036675",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "-0.0154357711712796",
                "net_sr": "-0.0214366213575808",
                "net_stuff": "0.00718663149595763",
                "net_pass_sr": "-0.0247867058571142",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1882291619190052
    },
    {
        "id": 282,
        "name": "Lincoln Riley",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 147.5,
            "closeness": 5.09213972220341e-7,
            "degree": 6,
            "eigen": 0.00544116825856336
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2011": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2012": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2013": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2014": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2015": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2016": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2017": {
                "college": "Oklahoma",
                "position": "HC"
            },
            "2018": {
                "college": "Oklahoma",
                "position": "HC"
            },
            "2019": {
                "college": "Oklahoma",
                "position": "HC"
            },
            "2020": {
                "college": "Oklahoma",
                "position": "HC"
            },
            "2021": {
                "college": "Oklahoma",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Oklahoma",
                "role": "HC",
                "net_ppa": "0.043255231",
                "net_sr": "0.022786229",
                "net_stuff": "-0.014107202",
                "net_pass_sr": "0.013206022",
                "net_fpi": "-1.453333333",
                "total_sr": "0.113931144",
                "total_stuffs": "-0.070536009",
                "total_pass_sr": "0.066030108",
                "total_ppa": "0.216276157"
            },
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "0.109837304734948",
                "net_sr": "0.0560252496340927",
                "net_stuff": "-0.0122587589664612",
                "net_pass_sr": "0.0785917321049469",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma",
                "role": "OC",
                "net_ppa": "0.191336818530484",
                "net_sr": "0.0353207676144432",
                "net_stuff": "0.00705461905231067",
                "net_pass_sr": "0.0896361003622829",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.344429354265432
    },
    {
        "id": 285,
        "name": "Bobby Wilder",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90391032905729e-7,
            "degree": 5,
            "eigen": 0.00000786685494549453
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Old Dominion",
                "position": "HC"
            },
            "2015": {
                "college": "Old Dominion",
                "position": "HC"
            },
            "2016": {
                "college": "Old Dominion",
                "position": "HC"
            },
            "2017": {
                "college": "Old Dominion",
                "position": "HC"
            },
            "2018": {
                "college": "Old Dominion",
                "position": "HC"
            },
            "2019": {
                "college": "Old Dominion",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Old Dominion",
                "role": "HC",
                "net_ppa": "-0.138171044",
                "net_sr": "-0.100823437",
                "net_stuff": "0.06560296",
                "net_pass_sr": "-0.060353091",
                "net_fpi": "N/A",
                "total_sr": "-0.604940621",
                "total_stuffs": "0.393617762",
                "total_pass_sr": "-0.362118545",
                "total_ppa": "-0.829026262"
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.138171044
    },
    {
        "id": 286,
        "name": "Ricky Rahne",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 20,
            "closeness": 4.89363201453213e-7,
            "degree": 3,
            "eigen": 0.0000756975198814346
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Penn State",
                "position": "OC"
            },
            "2019": {
                "college": "Penn State",
                "position": "OC"
            },
            "2020": {
                "college": "Old Dominion",
                "position": "HC"
            },
            "2021": {
                "college": "Old Dominion",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Old Dominion",
                "role": "HC",
                "net_ppa": "0.149465871",
                "net_sr": "0.049303777",
                "net_stuff": "0.02007984",
                "net_pass_sr": "0.043630782",
                "net_fpi": "N/A",
                "total_sr": "0.049303777",
                "total_stuffs": "0.02007984",
                "total_pass_sr": "0.043630782",
                "total_ppa": "0.149465871"
            },
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "0.0262440546687651",
                "net_sr": "0.0214385942087547",
                "net_stuff": "-0.0815950197562567",
                "net_pass_sr": "-0.0100574496971024",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1757099256687651
    },
    {
        "id": 287,
        "name": "Matt Luke",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 625.5,
            "closeness": 5.50778773646998e-7,
            "degree": 8,
            "eigen": 0.000961447910717735
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Tennessee",
                "position": "Positional Coach"
            },
            "2007": {
                "college": "Tennessee",
                "position": "Positional Coach"
            },
            "2008": {
                "college": "Duke",
                "position": "OC"
            },
            "2009": {
                "college": "Duke",
                "position": "OC"
            },
            "2010": {
                "college": "Duke",
                "position": "OC"
            },
            "2011": {
                "college": "Duke",
                "position": "OC"
            },
            "2012": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2013": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2014": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2015": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2016": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2017": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2018": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2019": {
                "college": "Ole Miss",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ole Miss",
                "role": "HC",
                "net_ppa": "-0.096665317",
                "net_sr": "-0.050742125",
                "net_stuff": "0.025117108",
                "net_pass_sr": "-0.056644789",
                "net_fpi": "-14.93333333",
                "total_sr": "-0.152226375",
                "total_stuffs": "0.075351324",
                "total_pass_sr": "-0.169934367",
                "total_ppa": "-0.289995952"
            },
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "0.0482869517300119",
                "net_sr": "0.0589077553609572",
                "net_stuff": "-0.00621766737359991",
                "net_pass_sr": "0.100581858261877",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "0.165010645659574",
                "net_sr": "0.0639399939199762",
                "net_stuff": "-0.0392247946964704",
                "net_pass_sr": "0.0638140501020907",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.00821271477822913",
                "net_sr": "0.00869643288787597",
                "net_stuff": "0.0194044430139679",
                "net_pass_sr": "0.0476661653132242",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "Positional Coach",
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.10841956561135677
    },
    {
        "id": 289,
        "name": "Chip Kelly",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 46,
            "closeness": 5.07361562592435e-7,
            "degree": 5,
            "eigen": 0.00404022151094
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Oregon",
                "position": "OC"
            },
            "2008": {
                "college": "Oregon",
                "position": "OC"
            },
            "2009": {
                "college": "Oregon",
                "position": "HC"
            },
            "2010": {
                "college": "Oregon",
                "position": "HC"
            },
            "2011": {
                "college": "Oregon",
                "position": "HC"
            },
            "2012": {
                "college": "Oregon",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "UCLA",
                "position": "HC"
            },
            "2019": {
                "college": "UCLA",
                "position": "HC"
            },
            "2020": {
                "college": "UCLA",
                "position": "HC"
            },
            "2021": {
                "college": "UCLA",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Oregon",
                "role": "HC",
                "net_ppa": "0.070479671",
                "net_sr": "-0.011064612",
                "net_stuff": "0.031773812",
                "net_pass_sr": "0.04228266",
                "net_fpi": "7.925",
                "total_sr": "-0.044258446",
                "total_stuffs": "0.127095248",
                "total_pass_sr": "0.169130639",
                "total_ppa": "0.281918686"
            },
            {
                "college": "UCLA",
                "role": "HC",
                "net_ppa": "-0.06047982",
                "net_sr": "-0.010954468",
                "net_stuff": "-0.084027902",
                "net_pass_sr": "-0.050019483",
                "net_fpi": "-4.483333333",
                "total_sr": "-0.043817873",
                "total_stuffs": "-0.33611161",
                "total_pass_sr": "-0.20007793",
                "total_ppa": "-0.241919278"
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "-0.0125693930847817",
                "net_sr": "0.00290931542217815",
                "net_stuff": "-0.02384360532728",
                "net_pass_sr": "-0.0358372414399153",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0025695420847817035
    },
    {
        "id": 290,
        "name": "Mark Helfrich",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 141,
            "closeness": 5.06312706828741e-7,
            "degree": 7,
            "eigen": 0.0174993695736201
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Colorado",
                "position": "OC"
            },
            "2007": {
                "college": "Colorado",
                "position": "OC"
            },
            "2008": {
                "college": "Colorado",
                "position": "OC"
            },
            "2009": {
                "college": "Oregon",
                "position": "OC"
            },
            "2010": {
                "college": "Oregon",
                "position": "OC"
            },
            "2011": {
                "college": "Oregon",
                "position": "OC"
            },
            "2012": {
                "college": "Oregon",
                "position": "OC"
            },
            "2013": {
                "college": "Oregon",
                "position": "HC"
            },
            "2014": {
                "college": "Oregon",
                "position": "HC"
            },
            "2015": {
                "college": "Oregon",
                "position": "HC"
            },
            "2016": {
                "college": "Oregon",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Oregon",
                "role": "HC",
                "net_ppa": "-0.040478571",
                "net_sr": "-0.029714474",
                "net_stuff": "-0.009803373",
                "net_pass_sr": "-0.032566245",
                "net_fpi": "-8.158333333",
                "total_sr": "-0.118857895",
                "total_stuffs": "-0.039213492",
                "total_pass_sr": "-0.130264979",
                "total_ppa": "-0.161914285"
            },
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "-0.0109746283214046",
                "net_sr": "-0.0086735902495727",
                "net_stuff": "-0.0378238846678355",
                "net_pass_sr": "-0.0645267626446786",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "0.0623360072029775",
                "net_sr": "0.00885301307288339",
                "net_stuff": "-0.00279057537054911",
                "net_pass_sr": "0.0381149706994971",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.010882807881572906
    },
    {
        "id": 291,
        "name": "Gary Andersen",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 444,
            "closeness": 5.50607430116905e-7,
            "degree": 15,
            "eigen": 0.00139950226487436
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Utah",
                "position": "DC"
            },
            "2006": {
                "college": "Utah",
                "position": "DC"
            },
            "2007": {
                "college": "Utah",
                "position": "DC"
            },
            "2008": {
                "college": "Utah",
                "position": "DC"
            },
            "2009": {
                "college": "Utah State",
                "position": "HC"
            },
            "2010": {
                "college": "Utah State",
                "position": "HC"
            },
            "2011": {
                "college": "Utah State",
                "position": "HC"
            },
            "2012": {
                "college": "Utah State",
                "position": "HC"
            },
            "2013": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2014": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2015": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2016": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2017": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2018": false,
            "2019": {
                "college": "Utah State",
                "position": "HC"
            },
            "2020": {
                "college": "Utah State",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Oregon State",
                "role": "HC",
                "net_ppa": "-0.107305295",
                "net_sr": "-0.093376927",
                "net_stuff": "0.039871635",
                "net_pass_sr": "-0.126019952",
                "net_fpi": "-17.13333333",
                "total_sr": "-0.28013078",
                "total_stuffs": "0.119614904",
                "total_pass_sr": "-0.378059855",
                "total_ppa": "-0.321915884"
            },
            {
                "college": "Utah State",
                "role": "HC",
                "net_ppa": "0.206700835",
                "net_sr": "0.134854582",
                "net_stuff": "-0.02417641",
                "net_pass_sr": "0.182821884",
                "net_fpi": "15.13333333",
                "total_sr": "1.618254978",
                "total_stuffs": "-0.290116918",
                "total_pass_sr": "2.193862611",
                "total_ppa": "2.480410016"
            },
            {
                "college": "Wisconsin",
                "role": "HC",
                "net_ppa": "0.036017317",
                "net_sr": "0.012982007",
                "net_stuff": "0.010652518",
                "net_pass_sr": "-0.012455951",
                "net_fpi": "-0.6",
                "total_sr": "0.025964013",
                "total_stuffs": "0.021305036",
                "total_pass_sr": "-0.024911901",
                "total_ppa": "0.072034633"
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.135412857
    },
    {
        "id": 292,
        "name": "Jonathan Smith",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 4,
            "closeness": 4.89362243549716e-7,
            "degree": 3,
            "eigen": 0.00869136684836413
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Washington",
                "position": "OC"
            },
            "2015": {
                "college": "Washington",
                "position": "OC"
            },
            "2016": {
                "college": "Washington",
                "position": "OC"
            },
            "2017": {
                "college": "Washington",
                "position": "OC"
            },
            "2018": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2019": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2020": {
                "college": "Oregon State",
                "position": "HC"
            },
            "2021": {
                "college": "Oregon State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Oregon State",
                "role": "HC",
                "net_ppa": "0.090080604",
                "net_sr": "0.062294818",
                "net_stuff": "-0.030499023",
                "net_pass_sr": "0.062225406",
                "net_fpi": "N/A",
                "total_sr": "0.249179272",
                "total_stuffs": "-0.121996093",
                "total_pass_sr": "0.248901625",
                "total_ppa": "0.360322418"
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "0.0168012424230337",
                "net_sr": "0.00203278555166736",
                "net_stuff": "-0.0147030041940849",
                "net_pass_sr": "0.0738418367321487",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1068818464230337
    },
    {
        "id": 294,
        "name": "Bill O'Brien",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 60.5,
            "closeness": 4.90735165444e-7,
            "degree": 6,
            "eigen": 0.000334658841043199
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2002": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Duke",
                "position": "OC"
            },
            "2006": {
                "college": "Duke",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Penn State",
                "position": "HC"
            },
            "2013": {
                "college": "Penn State",
                "position": "HC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Alabama",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Penn State",
                "role": "HC",
                "net_ppa": "0.022796521",
                "net_sr": "0.007494555",
                "net_stuff": "-0.029654972",
                "net_pass_sr": "-0.018544174",
                "net_fpi": "-3.05",
                "total_sr": "0.014989109",
                "total_stuffs": "-0.059309945",
                "total_pass_sr": "-0.037088348",
                "total_ppa": "0.045593042"
            },
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "-0.125030793349668",
                "net_sr": "-0.0704453931891746",
                "net_stuff": "0.0288610557082934",
                "net_pass_sr": "-0.0871553238438564",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.10223427234966799
    },
    {
        "id": 295,
        "name": "James Franklin",
        "community": 24,
        "isWhite": false,
        "centrality": {
            "betweenness": 115,
            "closeness": 4.93152333276292e-7,
            "degree": 12,
            "eigen": 0.000706122365521783
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2007": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2008": {
                "college": "Maryland",
                "position": "OC"
            },
            "2009": {
                "college": "Maryland",
                "position": "OC"
            },
            "2010": {
                "college": "Maryland",
                "position": "OC"
            },
            "2011": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2012": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2013": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2014": {
                "college": "Penn State",
                "position": "HC"
            },
            "2015": {
                "college": "Penn State",
                "position": "HC"
            },
            "2016": {
                "college": "Penn State",
                "position": "HC"
            },
            "2017": {
                "college": "Penn State",
                "position": "HC"
            },
            "2018": {
                "college": "Penn State",
                "position": "HC"
            },
            "2019": {
                "college": "Penn State",
                "position": "HC"
            },
            "2020": {
                "college": "Penn State",
                "position": "HC"
            },
            "2021": {
                "college": "Penn State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Penn State",
                "role": "HC",
                "net_ppa": "0.067916696",
                "net_sr": "0.019968774",
                "net_stuff": "0.029938207",
                "net_pass_sr": "0.047817967",
                "net_fpi": "7.391666667",
                "total_sr": "0.159750189",
                "total_stuffs": "0.23950566",
                "total_pass_sr": "0.382543738",
                "total_ppa": "0.543333564"
            },
            {
                "college": "Vanderbilt",
                "role": "HC",
                "net_ppa": "0.121744892",
                "net_sr": "0.061585305",
                "net_stuff": "-0.045257518",
                "net_pass_sr": "0.09156542",
                "net_fpi": "9.566666667",
                "total_sr": "0.184755914",
                "total_stuffs": "-0.135772553",
                "total_pass_sr": "0.274696261",
                "total_ppa": "0.365234677"
            },
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "0.0317082589028519",
                "net_sr": "0.0943499222257046",
                "net_stuff": "-0.0723754789272031",
                "net_pass_sr": "0.101330335009356",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.0302230334125591",
                "net_sr": "-0.0383915697400718",
                "net_stuff": "-0.0218165601382954",
                "net_pass_sr": "-0.0345428311039714",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.19114681349029278
    },
    {
        "id": 298,
        "name": "Paul Chryst",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 514,
            "closeness": 5.41720026089236e-7,
            "degree": 9,
            "eigen": 0.0010867012960667
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2004": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2005": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2006": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2007": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2008": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2009": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2010": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2011": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2012": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2013": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2014": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2015": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2016": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2017": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2018": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2019": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2020": {
                "college": "Wisconsin",
                "position": "HC"
            },
            "2021": {
                "college": "Wisconsin",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Pittsburgh",
                "role": "HC",
                "net_ppa": "0.021442334",
                "net_sr": "0.040103341",
                "net_stuff": "-0.010777868",
                "net_pass_sr": "0.019738321",
                "net_fpi": "N/A",
                "total_sr": "0.120310022",
                "total_stuffs": "-0.032333605",
                "total_pass_sr": "0.059214963",
                "total_ppa": "0.064327001"
            },
            {
                "college": "Wisconsin",
                "role": "HC",
                "net_ppa": "0.048330331",
                "net_sr": "0.026756965",
                "net_stuff": "-0.044573661",
                "net_pass_sr": "0.026541603",
                "net_fpi": "0.30952381",
                "total_sr": "0.187298753",
                "total_stuffs": "-0.312015626",
                "total_pass_sr": "0.185791221",
                "total_ppa": "0.33831232"
            },
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "0.0476230389431127",
                "net_sr": "0.0615627516847116",
                "net_stuff": "-0.0755327504788212",
                "net_pass_sr": "0.0720064365874357",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.11739570394311269
    },
    {
        "id": 299,
        "name": "Pat Narduzzi",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 33,
            "closeness": 4.92460430804385e-7,
            "degree": 8,
            "eigen": 0.00230486678750136
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2004": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2005": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2006": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2007": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2008": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2009": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2010": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2011": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2012": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2013": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2014": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2015": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2016": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2017": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2018": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2019": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2020": {
                "college": "Pittsburgh",
                "position": "HC"
            },
            "2021": {
                "college": "Pittsburgh",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Pittsburgh",
                "role": "HC",
                "net_ppa": "0.009417514",
                "net_sr": "-0.010481036",
                "net_stuff": "0.011823955",
                "net_pass_sr": "-0.034284769",
                "net_fpi": "N/A",
                "total_sr": "-0.073367251",
                "total_stuffs": "0.082767686",
                "total_pass_sr": "-0.239993386",
                "total_ppa": "0.065922596"
            },
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "0.0473866398332799",
                "net_sr": "-0.0162868010454275",
                "net_stuff": "-0.0616327723189121",
                "net_pass_sr": "-0.0509503515214115",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0568041538332799
    },
    {
        "id": 301,
        "name": "Danny Hope",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 7.5,
            "closeness": 4.91421017585501e-7,
            "degree": 5,
            "eigen": 0.000133839788644458
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Purdue",
                "position": "HC"
            },
            "2010": {
                "college": "Purdue",
                "position": "HC"
            },
            "2011": {
                "college": "Purdue",
                "position": "HC"
            },
            "2012": {
                "college": "Purdue",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": {
                "college": "South Florida",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "HC",
                "net_ppa": "-0.051379311",
                "net_sr": "-0.036872004",
                "net_stuff": "-0.026097232",
                "net_pass_sr": "-0.033382434",
                "net_fpi": "-5.016666667",
                "total_sr": "-0.147488014",
                "total_stuffs": "-0.10438893",
                "total_pass_sr": "-0.133529736",
                "total_ppa": "-0.205517245"
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.201378379712127",
                "net_sr": "0.0813899851069499",
                "net_stuff": "-0.0444677117676695",
                "net_pass_sr": "0.0417107943878762",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.14999906871212698
    },
    {
        "id": 302,
        "name": "Jeff Brohm",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 190.5,
            "closeness": 4.99127525086149e-7,
            "degree": 11,
            "eigen": 0.000304647646131533
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Louisville",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "UAB",
                "position": "OC"
            },
            "2013": false,
            "2014": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2015": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2016": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2017": {
                "college": "Purdue",
                "position": "HC"
            },
            "2018": {
                "college": "Purdue",
                "position": "HC"
            },
            "2019": {
                "college": "Purdue",
                "position": "HC"
            },
            "2020": {
                "college": "Purdue",
                "position": "HC"
            },
            "2021": {
                "college": "Purdue",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "HC",
                "net_ppa": "0.119916854",
                "net_sr": "0.065850544",
                "net_stuff": "0.039165007",
                "net_pass_sr": "0.082787881",
                "net_fpi": "10.5",
                "total_sr": "0.32925272",
                "total_stuffs": "0.195825037",
                "total_pass_sr": "0.413939407",
                "total_ppa": "0.59958427"
            },
            {
                "college": "Western Kentucky",
                "role": "HC",
                "net_ppa": "0.091445554",
                "net_sr": "0.031025166",
                "net_stuff": "0.03996654",
                "net_pass_sr": "0.020160869",
                "net_fpi": "N/A",
                "total_sr": "0.093075498",
                "total_stuffs": "0.119899621",
                "total_pass_sr": "0.060482607",
                "total_ppa": "0.274336663"
            },
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "-0.13637940086698",
                "net_sr": "-0.0620664887251348",
                "net_stuff": "0.0517812413486223",
                "net_pass_sr": "-0.113471181284861",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "0.00877507381696972",
                "net_sr": "-0.0277156635628847",
                "net_stuff": "0.0118869362413608",
                "net_pass_sr": "0.00116571410389499",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.08375808094998972
    },
    {
        "id": 304,
        "name": "David Bailiff",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 67,
            "closeness": 5.01255896649054e-7,
            "degree": 11,
            "eigen": 0.000670741280550968
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "TCU",
                "position": "DC"
            },
            "2003": {
                "college": "TCU",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Rice",
                "position": "HC"
            },
            "2008": {
                "college": "Rice",
                "position": "HC"
            },
            "2009": {
                "college": "Rice",
                "position": "HC"
            },
            "2010": {
                "college": "Rice",
                "position": "HC"
            },
            "2011": {
                "college": "Rice",
                "position": "HC"
            },
            "2012": {
                "college": "Rice",
                "position": "HC"
            },
            "2013": {
                "college": "Rice",
                "position": "HC"
            },
            "2014": {
                "college": "Rice",
                "position": "HC"
            },
            "2015": {
                "college": "Rice",
                "position": "HC"
            },
            "2016": {
                "college": "Rice",
                "position": "HC"
            },
            "2017": {
                "college": "Rice",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rice",
                "role": "HC",
                "net_ppa": "0.041452057",
                "net_sr": "0.035243942",
                "net_stuff": "0.037540323",
                "net_pass_sr": "0.076021069",
                "net_fpi": "1.05",
                "total_sr": "0.387683357",
                "total_stuffs": "0.412943549",
                "total_pass_sr": "0.836231763",
                "total_ppa": "0.455972628"
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.041452057
    },
    {
        "id": 305,
        "name": "Mike Bloomgren",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 6,
            "closeness": 4.89705173000595e-7,
            "degree": 4,
            "eigen": 0.000668151452974043
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Stanford",
                "position": "OC"
            },
            "2014": {
                "college": "Stanford",
                "position": "OC"
            },
            "2015": {
                "college": "Stanford",
                "position": "OC"
            },
            "2016": {
                "college": "Stanford",
                "position": "OC"
            },
            "2017": {
                "college": "Stanford",
                "position": "OC"
            },
            "2018": {
                "college": "Rice",
                "position": "HC"
            },
            "2019": {
                "college": "Rice",
                "position": "HC"
            },
            "2020": {
                "college": "Rice",
                "position": "HC"
            },
            "2021": {
                "college": "Rice",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Rice",
                "role": "HC",
                "net_ppa": "0.067256746",
                "net_sr": "-0.002016069",
                "net_stuff": "-0.009815931",
                "net_pass_sr": "0.050654007",
                "net_fpi": "1.666666667",
                "total_sr": "-0.008064274",
                "total_stuffs": "-0.039263725",
                "total_pass_sr": "0.202616026",
                "total_ppa": "0.269026984"
            },
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "0.0749841965885301",
                "net_sr": "-0.0153548923440351",
                "net_stuff": "0.00576881926170161",
                "net_pass_sr": "-0.0210980645554693",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1422409425885301
    },
    {
        "id": 307,
        "name": "Greg Schiano",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 36,
            "closeness": 4.98410320283456e-7,
            "degree": 12,
            "eigen": 0.00106879303761786
        },
        "positions": {
            "2000": {
                "college": "Miami",
                "position": "DC"
            },
            "2001": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2002": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2003": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2004": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2005": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2006": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2007": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2008": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2009": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2010": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2011": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2017": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2018": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2021": {
                "college": "Rutgers",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "0.0205376128484206",
                "net_sr": "0.0274628162272545",
                "net_stuff": "-0.0641300065774125",
                "net_pass_sr": "0.000489018591794455",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0205376128484206
    },
    {
        "id": 308,
        "name": "Kyle Flood",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 84,
            "closeness": 4.98404855260738e-7,
            "degree": 8,
            "eigen": 0.000246236600937377
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2013": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2014": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2015": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Texas",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "HC",
                "net_ppa": "-0.023140199",
                "net_sr": "0.003096545",
                "net_stuff": "0.007798727",
                "net_pass_sr": "0.00924294",
                "net_fpi": "-2.333333333",
                "total_sr": "0.012386178",
                "total_stuffs": "0.031194907",
                "total_pass_sr": "0.036971761",
                "total_ppa": "-0.092560795"
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.0216656853494032",
                "net_sr": "-0.00168259543639304",
                "net_stuff": "-0.046365929425072",
                "net_pass_sr": "-0.00539363681974864",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0014745136505967989
    },
    {
        "id": 309,
        "name": "Chris Ash",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 141.5,
            "closeness": 4.92456065532114e-7,
            "degree": 10,
            "eigen": 0.000982608537550412
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2012": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2013": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2014": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2015": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2016": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2017": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2018": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2019": {
                "college": "Rutgers",
                "position": "HC"
            },
            "2020": {
                "college": "Texas",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "HC",
                "net_ppa": "-0.123622844",
                "net_sr": "-0.083580312",
                "net_stuff": "0.039190922",
                "net_pass_sr": "-0.066375926",
                "net_fpi": "-9.108333333",
                "total_sr": "-0.334321247",
                "total_stuffs": "0.156763689",
                "total_pass_sr": "-0.265503704",
                "total_ppa": "-0.494491375"
            },
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "-0.101841611751571",
                "net_sr": "-0.0830900826085724",
                "net_stuff": "0.0409012014711967",
                "net_pass_sr": "-0.0723105378442082",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "-0.0226368253544468",
                "net_sr": "0.0175273528007573",
                "net_stuff": "0.0141150786966817",
                "net_pass_sr": "0.0690780696057955",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.00508632270748108",
                "net_sr": "-0.00496812116235729",
                "net_stuff": "-0.00916642048913238",
                "net_pass_sr": "-0.0448319212693667",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "0.000646346674610401",
                "net_sr": "0.00604140228509281",
                "net_stuff": "0.0370595177860597",
                "net_pass_sr": "0.00155946538285989",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.24236861172392632
    },
    {
        "id": 312,
        "name": "Chuck Long",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 4,
            "closeness": 4.89362961976987e-7,
            "degree": 4,
            "eigen": 0.00565772880906753
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2003": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2004": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2005": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2006": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2007": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2008": {
                "college": "San Diego State",
                "position": "HC"
            },
            "2009": false,
            "2010": {
                "college": "Kansas",
                "position": "OC"
            },
            "2011": {
                "college": "Kansas",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San Diego State",
                "role": "HC",
                "net_ppa": "-0.058009349",
                "net_sr": "-0.036175814",
                "net_stuff": "0.051956112",
                "net_pass_sr": "-0.033179117",
                "net_fpi": "-10.76666667",
                "total_sr": "-0.108527443",
                "total_stuffs": "0.155868335",
                "total_pass_sr": "-0.09953735",
                "total_ppa": "-0.174028046"
            },
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.115990974374219",
                "net_sr": "-0.0883294673924805",
                "net_stuff": "0.0143943827009637",
                "net_pass_sr": "-0.085190857875811",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.174000323374219
    },
    {
        "id": 313,
        "name": "Dave Baldwin",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 189,
            "closeness": 4.89706372059313e-7,
            "degree": 9,
            "eigen": 0.00071273225314265
        },
        "positions": {
            "2000": {
                "college": "San José State",
                "position": "HC"
            },
            "2001": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2002": {
                "college": "Baylor",
                "position": "OC"
            },
            "2003": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2004": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2005": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2006": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2007": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2008": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2009": {
                "college": "Utah State",
                "position": "OC"
            },
            "2010": {
                "college": "Utah State",
                "position": "OC"
            },
            "2011": {
                "college": "Utah State",
                "position": "OC"
            },
            "2012": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2013": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2014": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2015": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "0.1343976369118",
                "net_sr": "0.0244311669079311",
                "net_stuff": "-0.00913289973476109",
                "net_pass_sr": "0.0643564648490809",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "-0.0110170942625663",
                "net_sr": "0.0117271665823438",
                "net_stuff": "-0.0447505937278737",
                "net_pass_sr": "0.019436096964329",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon State",
                "role": "OC",
                "net_ppa": "-0.0220458582540834",
                "net_sr": "-0.0433158500389021",
                "net_stuff": "-0.00748020219185971",
                "net_pass_sr": "-0.0940048110366836",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.142776839052989",
                "net_sr": "0.0702078863179683",
                "net_stuff": "-0.0475994722248241",
                "net_pass_sr": "0.0587451663212831",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2441115234481393
    },
    {
        "id": 315,
        "name": "Ron Caragher",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.9454736799418e-7,
            "degree": 5,
            "eigen": 0.000119931498391433
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "San José State",
                "position": "HC"
            },
            "2014": {
                "college": "San José State",
                "position": "HC"
            },
            "2015": {
                "college": "San José State",
                "position": "HC"
            },
            "2016": {
                "college": "San José State",
                "position": "HC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San José State",
                "role": "HC",
                "net_ppa": "-0.025637261",
                "net_sr": "0.020320239",
                "net_stuff": "0.016288714",
                "net_pass_sr": "0.026451099",
                "net_fpi": "N/A",
                "total_sr": "0.081280957",
                "total_stuffs": "0.065154854",
                "total_pass_sr": "0.105804396",
                "total_ppa": "-0.102549043"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.025637261
    },
    {
        "id": 316,
        "name": "Brent Brennan",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89705412811869e-7,
            "degree": 3,
            "eigen": 0.0000344756332417325
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "San José State",
                "position": "HC"
            },
            "2018": {
                "college": "San José State",
                "position": "HC"
            },
            "2019": {
                "college": "San José State",
                "position": "HC"
            },
            "2020": {
                "college": "San José State",
                "position": "HC"
            },
            "2021": {
                "college": "San José State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "San José State",
                "role": "HC",
                "net_ppa": "-0.060004335",
                "net_sr": "-0.021435999",
                "net_stuff": "0.047608209",
                "net_pass_sr": "-0.062206292",
                "net_fpi": "N/A",
                "total_sr": "-0.107179996",
                "total_stuffs": "0.238041044",
                "total_pass_sr": "-0.311031459",
                "total_ppa": "-0.300021675"
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.060004335
    },
    {
        "id": 318,
        "name": "Phil Bennett",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 74,
            "closeness": 4.89704213757848e-7,
            "degree": 9,
            "eigen": 0.000753976890931448
        },
        "positions": {
            "2000": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2001": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2002": {
                "college": "SMU",
                "position": "HC"
            },
            "2003": {
                "college": "SMU",
                "position": "HC"
            },
            "2004": {
                "college": "SMU",
                "position": "HC"
            },
            "2005": {
                "college": "SMU",
                "position": "HC"
            },
            "2006": {
                "college": "SMU",
                "position": "HC"
            },
            "2007": {
                "college": "SMU",
                "position": "HC"
            },
            "2008": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2009": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2010": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2011": {
                "college": "Baylor",
                "position": "DC"
            },
            "2012": {
                "college": "Baylor",
                "position": "DC"
            },
            "2013": {
                "college": "Baylor",
                "position": "DC"
            },
            "2014": {
                "college": "Baylor",
                "position": "DC"
            },
            "2015": {
                "college": "Baylor",
                "position": "DC"
            },
            "2016": {
                "college": "Baylor",
                "position": "DC"
            },
            "2017": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "North Texas",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "-0.0998777349843827",
                "net_sr": "-0.00427582811497884",
                "net_stuff": "-0.0300406467698432",
                "net_pass_sr": "-0.0148576445216527",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "0.078916096187805",
                "net_sr": "-0.0665227734089212",
                "net_stuff": "-0.0177232308133023",
                "net_pass_sr": "-0.0495930568024458",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "-0.205137511884211",
                "net_sr": "-0.0360591954496239",
                "net_stuff": "0.0124944511026696",
                "net_pass_sr": "-0.0630235115287491",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "0.0778521317880134",
                "net_sr": "0.0762429249161594",
                "net_stuff": "-0.0326939776024557",
                "net_pass_sr": "0.0597644685964457",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1482470188927753
    },
    {
        "id": 319,
        "name": "Joey Jones",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.945476125714e-7,
            "degree": 8,
            "eigen": 0.0000120562437454964
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "South Alabama",
                "position": "HC"
            },
            "2013": {
                "college": "South Alabama",
                "position": "HC"
            },
            "2014": {
                "college": "South Alabama",
                "position": "HC"
            },
            "2015": {
                "college": "South Alabama",
                "position": "HC"
            },
            "2016": {
                "college": "South Alabama",
                "position": "HC"
            },
            "2017": {
                "college": "South Alabama",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "HC",
                "net_ppa": "-0.017052214",
                "net_sr": "-0.072896133",
                "net_stuff": "-0.004782132",
                "net_pass_sr": "0.006720672",
                "net_fpi": "N/A",
                "total_sr": "-0.437376795",
                "total_stuffs": "-0.028692792",
                "total_pass_sr": "0.040324032",
                "total_ppa": "-0.102313282"
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.017052214
    },
    {
        "id": 320,
        "name": "Steve Campbell",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 2,
            "closeness": 4.89362722500996e-7,
            "degree": 3,
            "eigen": 0.0000522588636226559
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "South Alabama",
                "position": "HC"
            },
            "2019": {
                "college": "South Alabama",
                "position": "HC"
            },
            "2020": {
                "college": "South Alabama",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "HC",
                "net_ppa": "-0.031812088",
                "net_sr": "-0.047921846",
                "net_stuff": "-0.039799639",
                "net_pass_sr": "-0.087708926",
                "net_fpi": "-2.966666667",
                "total_sr": "-0.143765539",
                "total_stuffs": "-0.119398918",
                "total_pass_sr": "-0.263126778",
                "total_ppa": "-0.095436265"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.031812088
    },
    {
        "id": 321,
        "name": "Kane Wommack",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 17,
            "closeness": 4.90736128729901e-7,
            "degree": 4,
            "eigen": 0.0000631627755649136
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2017": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2018": false,
            "2019": {
                "college": "Indiana",
                "position": "DC"
            },
            "2020": {
                "college": "Indiana",
                "position": "DC"
            },
            "2021": {
                "college": "South Alabama",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "HC",
                "net_ppa": "0.148294982",
                "net_sr": "0.09751815",
                "net_stuff": "-0.000891129",
                "net_pass_sr": "0.161419143",
                "net_fpi": "6.1",
                "total_sr": "0.09751815",
                "total_stuffs": "-0.000891129",
                "total_pass_sr": "0.161419143",
                "total_ppa": "0.148294982"
            },
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.0138801105250804",
                "net_sr": "0.00312507667726464",
                "net_stuff": "0.0292505212770498",
                "net_pass_sr": "-0.0107035063477856",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "0.005971338081215",
                "net_sr": "-0.00887926388172328",
                "net_stuff": "-0.00170101528543412",
                "net_pass_sr": "-0.0118246984909899",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1681464306062954
    },
    {
        "id": 323,
        "name": "Shane Beamer",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89363680406368e-7,
            "degree": 2,
            "eigen": 0.00000435382779680332
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "South Carolina",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "South Carolina",
                "role": "HC",
                "net_ppa": "0.068491884",
                "net_sr": "0.016978925",
                "net_stuff": "0.029551774",
                "net_pass_sr": "0.039390381",
                "net_fpi": "N/A",
                "total_sr": "0.016978925",
                "total_stuffs": "0.029551774",
                "total_pass_sr": "0.039390381",
                "total_ppa": "0.068491884"
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.068491884
    },
    {
        "id": 324,
        "name": "Jeff Scott",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 2,
            "closeness": 4.89363201453213e-7,
            "degree": 3,
            "eigen": 0.00231046750598358
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Clemson",
                "position": "OC"
            },
            "2016": {
                "college": "Clemson",
                "position": "OC"
            },
            "2017": {
                "college": "Clemson",
                "position": "OC"
            },
            "2018": {
                "college": "Clemson",
                "position": "OC"
            },
            "2019": {
                "college": "Clemson",
                "position": "OC"
            },
            "2020": {
                "college": "South Florida",
                "position": "HC"
            },
            "2021": {
                "college": "South Florida",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "South Florida",
                "role": "HC",
                "net_ppa": "-0.142007234",
                "net_sr": "-0.050607825",
                "net_stuff": "0.040256397",
                "net_pass_sr": "-0.084554754",
                "net_fpi": "N/A",
                "total_sr": "-0.101215651",
                "total_stuffs": "0.080512795",
                "total_pass_sr": "-0.169109508",
                "total_ppa": "-0.284014467"
            },
            {
                "college": "Clemson",
                "role": "OC",
                "net_ppa": "0.0788176412378093",
                "net_sr": "0.073527983686409",
                "net_stuff": "-0.0314755300670551",
                "net_pass_sr": "0.145910305210692",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.06318959276219072
    },
    {
        "id": 326,
        "name": "Ellis Johnson",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 497,
            "closeness": 4.93149658099342e-7,
            "degree": 6,
            "eigen": 0.000163250841947574
        },
        "positions": {
            "2000": {
                "college": "Alabama",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2005": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2006": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2007": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2008": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2009": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2010": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2011": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2012": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2013": {
                "college": "Auburn",
                "position": "DC"
            },
            "2014": {
                "college": "Auburn",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Southern Mississippi",
                "role": "HC",
                "net_ppa": "-0.164024613",
                "net_sr": "-0.136890364",
                "net_stuff": "0.075626823",
                "net_pass_sr": "-0.147856212",
                "net_fpi": "N/A",
                "total_sr": "-0.136890364",
                "total_stuffs": "0.075626823",
                "total_pass_sr": "-0.147856212",
                "total_ppa": "-0.164024613"
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.0437564331353381",
                "net_sr": "0.0212186934663383",
                "net_stuff": "-0.0166423869212399",
                "net_pass_sr": "0.0232401171871927",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.0830503161653786",
                "net_sr": "0.0218485953499205",
                "net_stuff": "-0.047414452042242",
                "net_pass_sr": "0.0232628608438194",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "0.0584333267244222",
                "net_sr": "0.0263046636528668",
                "net_stuff": "0.0136347899664289",
                "net_pass_sr": "0.0163694184285237",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0662974032455373
    },
    {
        "id": 327,
        "name": "Todd Monken",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 36,
            "closeness": 4.91423432543534e-7,
            "degree": 5,
            "eigen": 0.000552243051802399
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2012": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2013": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2014": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2015": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Georgia",
                "position": "OC"
            },
            "2021": {
                "college": "Georgia",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Southern Mississippi",
                "role": "HC",
                "net_ppa": "-0.063364182",
                "net_sr": "-0.047825613",
                "net_stuff": "0.077245639",
                "net_pass_sr": "-0.002017602",
                "net_fpi": "N/A",
                "total_sr": "-0.143476839",
                "total_stuffs": "0.231736916",
                "total_pass_sr": "-0.006052807",
                "total_ppa": "-0.190092547"
            },
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "-0.0181254032342591",
                "net_sr": "-0.0155295072298861",
                "net_stuff": "-0.0249812480125923",
                "net_pass_sr": "-0.046514974949518",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "0.027171024723292",
                "net_sr": "0.0242443331900761",
                "net_stuff": "0.0165170271812933",
                "net_pass_sr": "0.0337409260418216",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.054318560510967104
    },
    {
        "id": 328,
        "name": "Jay Hopson",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 12,
            "closeness": 4.90735887908071e-7,
            "degree": 8,
            "eigen": 0.0000942142398759372
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2006": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2007": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Memphis",
                "position": "DC"
            },
            "2011": {
                "college": "Memphis",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2017": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2018": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2019": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2020": {
                "college": "Southern Mississippi",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Southern Mississippi",
                "role": "HC",
                "net_ppa": "0.077587293",
                "net_sr": "0.055866018",
                "net_stuff": "-0.047253711",
                "net_pass_sr": "0.06314909",
                "net_fpi": "N/A",
                "total_sr": "0.279330091",
                "total_stuffs": "-0.236268553",
                "total_pass_sr": "0.315745449",
                "total_ppa": "0.387936464"
            },
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "0.00607077435572191",
                "net_sr": "0.00292472780682984",
                "net_stuff": "-0.0272021383738138",
                "net_pass_sr": "-0.0249139374589629",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.0915476783749756",
                "net_sr": "0.00955069092826166",
                "net_stuff": "-0.0342949116225699",
                "net_pass_sr": "0.0178027134628042",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.17520574573069753
    },
    {
        "id": 329,
        "name": "Will Hall",
        "community": 22,
        "isWhite": true,
        "centrality": {
            "betweenness": 3,
            "closeness": 4.89021468042447e-7,
            "degree": 3,
            "eigen": 0.0000166571893243972
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2018": false,
            "2019": {
                "college": "Tulane",
                "position": "OC"
            },
            "2020": {
                "college": "Tulane",
                "position": "OC"
            },
            "2021": {
                "college": "Southern Mississippi",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Southern Mississippi",
                "role": "HC",
                "net_ppa": "-0.218336674",
                "net_sr": "-0.075780533",
                "net_stuff": "0.034032085",
                "net_pass_sr": "-0.069414689",
                "net_fpi": "N/A",
                "total_sr": "-0.075780533",
                "total_stuffs": "0.034032085",
                "total_pass_sr": "-0.069414689",
                "total_ppa": "-0.218336674"
            },
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "0.0103884958909856",
                "net_sr": "-0.0158124480670183",
                "net_stuff": "0.0515239524142095",
                "net_pass_sr": "0.00180125653169355",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "0.0797199270835463",
                "net_sr": "0.0572190907038694",
                "net_stuff": "-0.0175472251099878",
                "net_pass_sr": "0.0531691624511003",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.12822825102546812
    },
    {
        "id": 331,
        "name": "David Shaw",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 58,
            "closeness": 5.09230049257823e-7,
            "degree": 7,
            "eigen": 0.00268604537763722
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Stanford",
                "position": "OC"
            },
            "2008": {
                "college": "Stanford",
                "position": "OC"
            },
            "2009": {
                "college": "Stanford",
                "position": "OC"
            },
            "2010": {
                "college": "Stanford",
                "position": "OC"
            },
            "2011": {
                "college": "Stanford",
                "position": "HC"
            },
            "2012": {
                "college": "Stanford",
                "position": "HC"
            },
            "2013": {
                "college": "Stanford",
                "position": "HC"
            },
            "2014": {
                "college": "Stanford",
                "position": "HC"
            },
            "2015": {
                "college": "Stanford",
                "position": "HC"
            },
            "2016": {
                "college": "Stanford",
                "position": "HC"
            },
            "2017": {
                "college": "Stanford",
                "position": "HC"
            },
            "2018": {
                "college": "Stanford",
                "position": "HC"
            },
            "2019": {
                "college": "Stanford",
                "position": "HC"
            },
            "2020": {
                "college": "Stanford",
                "position": "HC"
            },
            "2021": {
                "college": "Stanford",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Stanford",
                "role": "HC",
                "net_ppa": "-0.00481842",
                "net_sr": "-0.008382032",
                "net_stuff": "-0.01412432",
                "net_pass_sr": "0.016160439",
                "net_fpi": "-0.53030303",
                "total_sr": "-0.092202352",
                "total_stuffs": "-0.155367516",
                "total_pass_sr": "0.177764826",
                "total_ppa": "-0.053002621"
            },
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "0.0709381597228784",
                "net_sr": "0.0481746832162352",
                "net_stuff": "-0.0519902571047049",
                "net_pass_sr": "0.0411656700133465",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.06611973972287839
    },
    {
        "id": 332,
        "name": "Paul Pasqualoni",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90734924623116e-7,
            "degree": 6,
            "eigen": 0.0000633153506534507
        },
        "positions": {
            "2000": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2001": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2002": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2003": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2004": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2012": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2013": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "HC",
                "net_ppa": "-0.04090706",
                "net_sr": "-0.013296487",
                "net_stuff": "-0.021906953",
                "net_pass_sr": "0.012884101",
                "net_fpi": "N/A",
                "total_sr": "-0.039889461",
                "total_stuffs": "-0.065720859",
                "total_pass_sr": "0.038652303",
                "total_ppa": "-0.12272118"
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.04090706
    },
    {
        "id": 333,
        "name": "Greg Robinson",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 40,
            "closeness": 4.90392716295063e-7,
            "degree": 8,
            "eigen": 0.000469378712369668
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Texas",
                "position": "DC"
            },
            "2005": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2006": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2007": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2008": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2009": {
                "college": "Michigan",
                "position": "DC"
            },
            "2010": {
                "college": "Michigan",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "San José State",
                "position": "DC"
            },
            "2015": {
                "college": "San José State",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "-0.0793551346399848",
                "net_sr": "-0.0548434668555816",
                "net_stuff": "0.0393375995849176",
                "net_pass_sr": "-0.0676924135065983",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "-0.0934783714843135",
                "net_sr": "0.00147011866308866",
                "net_stuff": "0.0359541884955868",
                "net_pass_sr": "0.0668262396003875",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1728335061242983
    },
    {
        "id": 334,
        "name": "Doug Marrone",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.93144794215609e-7,
            "degree": 3,
            "eigen": 0.0000758076665910239
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2010": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2011": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2012": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "HC",
                "net_ppa": "0.148266997",
                "net_sr": "0.1389368",
                "net_stuff": "-0.065637188",
                "net_pass_sr": "0.149901517",
                "net_fpi": "7.508333333",
                "total_sr": "0.555747199",
                "total_stuffs": "-0.262548754",
                "total_pass_sr": "0.59960607",
                "total_ppa": "0.593067988"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.148266997
    },
    {
        "id": 335,
        "name": "Scott Shafer",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 480.5,
            "closeness": 4.92114359503319e-7,
            "degree": 8,
            "eigen": 0.000412641263484149
        },
        "positions": {
            "2000": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2001": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2002": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2003": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2004": false,
            "2005": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2006": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2007": false,
            "2008": {
                "college": "Michigan",
                "position": "DC"
            },
            "2009": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2010": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2011": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2012": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2013": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2014": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2015": {
                "college": "Syracuse",
                "position": "HC"
            },
            "2016": false,
            "2017": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2018": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2019": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2020": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2021": {
                "college": "Middle Tennessee",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "HC",
                "net_ppa": "-0.095431633",
                "net_sr": "-0.068472128",
                "net_stuff": "0.047905107",
                "net_pass_sr": "-0.064599781",
                "net_fpi": "-1.766666667",
                "total_sr": "-0.205416385",
                "total_stuffs": "0.143715321",
                "total_pass_sr": "-0.193799342",
                "total_ppa": "-0.2862949"
            },
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.141761045296459",
                "net_sr": "0.0447632193515231",
                "net_stuff": "-0.0756412299511907",
                "net_pass_sr": "0.0464057896469986",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "-0.132775465482731",
                "net_sr": "-0.0626778562863581",
                "net_stuff": "0.0853115163012038",
                "net_pass_sr": "-0.00649718795946946",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "0.25078183727249",
                "net_sr": "0.0233191488540061",
                "net_stuff": "-0.0392783172254717",
                "net_pass_sr": "-0.0112836307444804",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 19,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.16433578408621796
    },
    {
        "id": 339,
        "name": "Jeremy Pruitt",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 49.5,
            "closeness": 4.92116054744958e-7,
            "degree": 8,
            "eigen": 0.000592692934716841
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Florida State",
                "position": "DC"
            },
            "2014": {
                "college": "Georgia",
                "position": "DC"
            },
            "2015": {
                "college": "Georgia",
                "position": "DC"
            },
            "2016": {
                "college": "Alabama",
                "position": "DC"
            },
            "2017": {
                "college": "Alabama",
                "position": "DC"
            },
            "2018": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2019": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2020": {
                "college": "Tennessee",
                "position": "HC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Tennessee",
                "role": "HC",
                "net_ppa": "-0.014119402",
                "net_sr": "-0.046948044",
                "net_stuff": "0.040888555",
                "net_pass_sr": "-0.07882859",
                "net_fpi": "-7.133333333",
                "total_sr": "-0.140844132",
                "total_stuffs": "0.122665665",
                "total_pass_sr": "-0.236485771",
                "total_ppa": "-0.042358207"
            },
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.064820275261161",
                "net_sr": "0.0235744318703366",
                "net_stuff": "-0.000159517591802466",
                "net_pass_sr": "0.0208813195368402",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "0.0193504152183218",
                "net_sr": "0.004650738183062",
                "net_stuff": "0.0376094564168356",
                "net_pass_sr": "-0.0229155453653231",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "-0.0466610237465161",
                "net_sr": "-0.00706395371421975",
                "net_stuff": "0.0303371085577519",
                "net_pass_sr": "0.0291684982100975",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.023390264732966702
    },
    {
        "id": 340,
        "name": "Josh Heupel",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 138.5,
            "closeness": 4.92803832042598e-7,
            "degree": 8,
            "eigen": 0.00561867077041452
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2012": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2013": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2014": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2015": {
                "college": "Utah State",
                "position": "OC"
            },
            "2016": {
                "college": "Missouri",
                "position": "OC"
            },
            "2017": {
                "college": "Missouri",
                "position": "OC"
            },
            "2018": {
                "college": "UCF",
                "position": "HC"
            },
            "2019": {
                "college": "UCF",
                "position": "HC"
            },
            "2020": {
                "college": "UCF",
                "position": "HC"
            },
            "2021": {
                "college": "Tennessee",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Tennessee",
                "role": "HC",
                "net_ppa": "0.137740961",
                "net_sr": "0.115777643",
                "net_stuff": "-0.092862733",
                "net_pass_sr": "0.075101129",
                "net_fpi": "5.3",
                "total_sr": "0.115777643",
                "total_stuffs": "-0.092862733",
                "total_pass_sr": "0.075101129",
                "total_ppa": "0.137740961"
            },
            {
                "college": "UCF",
                "role": "HC",
                "net_ppa": "0.179890406",
                "net_sr": "0.046775709",
                "net_stuff": "-0.079095706",
                "net_pass_sr": "0.037306744",
                "net_fpi": "13.86666667",
                "total_sr": "0.140327127",
                "total_stuffs": "-0.237287119",
                "total_pass_sr": "0.111920231",
                "total_ppa": "0.539671219"
            },
            {
                "college": "Missouri",
                "role": "OC",
                "net_ppa": "-0.0472354386202321",
                "net_sr": "-0.0717770307792471",
                "net_stuff": "0.0325148699207643",
                "net_pass_sr": "-0.0868906993946241",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma",
                "role": "OC",
                "net_ppa": "-0.0587024962903549",
                "net_sr": "0.0299039208633961",
                "net_stuff": "-0.0508984421775897",
                "net_pass_sr": "0.0488508496133077",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "-0.0438203185313081",
                "net_sr": "0.0130688385268146",
                "net_stuff": "0.0255050669055372",
                "net_pass_sr": "0.0345267804307633",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.16787311355810489
    },
    {
        "id": 341,
        "name": "Steve Sarkisian",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 85.5,
            "closeness": 5.05225292588598e-7,
            "degree": 11,
            "eigen": 0.00443980163194513
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "USC",
                "position": "OC"
            },
            "2008": {
                "college": "USC",
                "position": "OC"
            },
            "2009": {
                "college": "Washington",
                "position": "HC"
            },
            "2010": {
                "college": "Washington",
                "position": "HC"
            },
            "2011": {
                "college": "Washington",
                "position": "HC"
            },
            "2012": {
                "college": "Washington",
                "position": "HC"
            },
            "2013": {
                "college": "Washington",
                "position": "HC"
            },
            "2014": {
                "college": "USC",
                "position": "HC"
            },
            "2015": {
                "college": "USC",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Alabama",
                "position": "OC"
            },
            "2020": {
                "college": "Alabama",
                "position": "OC"
            },
            "2021": {
                "college": "Texas",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Texas",
                "role": "HC",
                "net_ppa": "-0.088553877",
                "net_sr": "-0.020387911",
                "net_stuff": "-0.0083549",
                "net_pass_sr": "-0.025187003",
                "net_fpi": "-5.833333333",
                "total_sr": "-0.020387911",
                "total_stuffs": "-0.0083549",
                "total_pass_sr": "-0.025187003",
                "total_ppa": "-0.088553877"
            },
            {
                "college": "USC",
                "role": "HC",
                "net_ppa": "0.076506967",
                "net_sr": "0.004914317",
                "net_stuff": "-0.019284183",
                "net_pass_sr": "-0.011320212",
                "net_fpi": "2.333333333",
                "total_sr": "0.009828633",
                "total_stuffs": "-0.038568366",
                "total_pass_sr": "-0.022640425",
                "total_ppa": "0.153013934"
            },
            {
                "college": "Washington",
                "role": "HC",
                "net_ppa": "0.140261559",
                "net_sr": "0.077448267",
                "net_stuff": "-0.018231407",
                "net_pass_sr": "0.090367865",
                "net_fpi": "8.173333333",
                "total_sr": "0.387241335",
                "total_stuffs": "-0.091157034",
                "total_pass_sr": "0.451839325",
                "total_ppa": "0.701307794"
            },
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.117849449818856",
                "net_sr": "0.042386184655647",
                "net_stuff": "0.00885712181260942",
                "net_pass_sr": "0.0812491531496993",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "-0.0741246277267154",
                "net_sr": "-0.0049160974466434",
                "net_stuff": "0.0135723905601678",
                "net_pass_sr": "-0.0255797638374541",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.17193947109214064
    },
    {
        "id": 343,
        "name": "Mike Sherman",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.91077852045118e-7,
            "degree": 3,
            "eigen": 0.000223017898319336
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2009": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2010": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2011": {
                "college": "Texas A&M",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas A&M",
                "role": "HC",
                "net_ppa": "0.023119183",
                "net_sr": "0.01395719",
                "net_stuff": "0.013555762",
                "net_pass_sr": "0.053410748",
                "net_fpi": "-1.675",
                "total_sr": "0.055828758",
                "total_stuffs": "0.054223046",
                "total_pass_sr": "0.21364299",
                "total_ppa": "0.092476733"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.023119183
    },
    {
        "id": 344,
        "name": "Jake Spavital",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 39,
            "closeness": 4.89705892435121e-7,
            "degree": 6,
            "eigen": 0.000438758385468533
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2014": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2015": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2016": {
                "college": "California",
                "position": "OC"
            },
            "2017": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2018": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2019": {
                "college": "Texas State",
                "position": "HC"
            },
            "2020": {
                "college": "Texas State",
                "position": "HC"
            },
            "2021": {
                "college": "Texas State",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Texas State",
                "role": "HC",
                "net_ppa": "-0.013460963",
                "net_sr": "-0.030331283",
                "net_stuff": "-0.011437277",
                "net_pass_sr": "-0.042657586",
                "net_fpi": "8.333333333",
                "total_sr": "-0.090993848",
                "total_stuffs": "-0.034311832",
                "total_pass_sr": "-0.127972758",
                "total_ppa": "-0.04038289"
            },
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "0.0167113296567378",
                "net_sr": "0.00754217864288931",
                "net_stuff": "-0.0261633598406708",
                "net_pass_sr": "-0.00461446146426264",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "0.0702796335536562",
                "net_sr": "0.0248058050621121",
                "net_stuff": "-0.0176804907204498",
                "net_pass_sr": "0.0227621691195501",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "0.077145100828879",
                "net_sr": "0.0115860085938805",
                "net_stuff": "0.00294884031011364",
                "net_pass_sr": "0.0405712157659485",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.150675101039273
    },
    {
        "id": 345,
        "name": "Kliff Kingsbury",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 12.5,
            "closeness": 4.91079540153119e-7,
            "degree": 8,
            "eigen": 0.000524445136006629
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Houston",
                "position": "OC"
            },
            "2011": {
                "college": "Houston",
                "position": "OC"
            },
            "2012": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2013": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2014": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2015": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2016": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2017": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2018": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas Tech",
                "role": "HC",
                "net_ppa": "-0.036074303",
                "net_sr": "-0.041696012",
                "net_stuff": "0.003187196",
                "net_pass_sr": "-0.045114607",
                "net_fpi": "0.533333333",
                "total_sr": "-0.250176073",
                "total_stuffs": "0.019123174",
                "total_pass_sr": "-0.270687641",
                "total_ppa": "-0.216445816"
            },
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.00758494916518174",
                "net_sr": "-0.0135560542360469",
                "net_stuff": "-0.0219382858239754",
                "net_pass_sr": "-0.00491501941493327",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "0.0922943251819263",
                "net_sr": "0.015746187454612",
                "net_stuff": "-0.00758162245855626",
                "net_pass_sr": "0.0352673117772127",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.048635073016744565
    },
    {
        "id": 346,
        "name": "Matt Wells",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 74.5,
            "closeness": 4.96647381848829e-7,
            "degree": 12,
            "eigen": 0.000644299749986798
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Utah State",
                "position": "OC"
            },
            "2013": {
                "college": "Utah State",
                "position": "HC"
            },
            "2014": {
                "college": "Utah State",
                "position": "HC"
            },
            "2015": {
                "college": "Utah State",
                "position": "HC"
            },
            "2016": {
                "college": "Utah State",
                "position": "HC"
            },
            "2017": {
                "college": "Utah State",
                "position": "HC"
            },
            "2018": {
                "college": "Utah State",
                "position": "HC"
            },
            "2019": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2020": {
                "college": "Texas Tech",
                "position": "HC"
            },
            "2021": {
                "college": "Texas Tech",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Texas Tech",
                "role": "HC",
                "net_ppa": "-0.03727262",
                "net_sr": "-0.002920172",
                "net_stuff": "-0.03685839",
                "net_pass_sr": "-0.03093288",
                "net_fpi": "-4.333333333",
                "total_sr": "-0.008760515",
                "total_stuffs": "-0.11057517",
                "total_pass_sr": "-0.092798641",
                "total_ppa": "-0.111817859"
            },
            {
                "college": "Utah State",
                "role": "HC",
                "net_ppa": "0.024721417",
                "net_sr": "0.01981458",
                "net_stuff": "0.012199565",
                "net_pass_sr": "0.027132653",
                "net_fpi": "3.866666667",
                "total_sr": "0.118887481",
                "total_stuffs": "0.073197389",
                "total_pass_sr": "0.162795919",
                "total_ppa": "0.148328504"
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.0271807134026483",
                "net_sr": "0.02250836189045",
                "net_stuff": "-0.00471126425332474",
                "net_pass_sr": "0.0743397118503188",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.014629510402648298
    },
    {
        "id": 348,
        "name": "Jason Candle",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 29,
            "closeness": 4.90391032905729e-7,
            "degree": 6,
            "eigen": 0.00022450406110778
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Toledo",
                "position": "OC"
            },
            "2013": {
                "college": "Toledo",
                "position": "OC"
            },
            "2014": {
                "college": "Toledo",
                "position": "OC"
            },
            "2015": {
                "college": "Toledo",
                "position": "OC"
            },
            "2016": {
                "college": "Toledo",
                "position": "HC"
            },
            "2017": {
                "college": "Toledo",
                "position": "HC"
            },
            "2018": {
                "college": "Toledo",
                "position": "HC"
            },
            "2019": {
                "college": "Toledo",
                "position": "HC"
            },
            "2020": {
                "college": "Toledo",
                "position": "HC"
            },
            "2021": {
                "college": "Toledo",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Toledo",
                "role": "HC",
                "net_ppa": "0.040065707",
                "net_sr": "-0.004609512",
                "net_stuff": "0.039052944",
                "net_pass_sr": "0.033957691",
                "net_fpi": "-4.8",
                "total_sr": "-0.027657071",
                "total_stuffs": "0.234317666",
                "total_pass_sr": "0.203746144",
                "total_ppa": "0.240394243"
            },
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "0.118865702201659",
                "net_sr": "0.0529354446162083",
                "net_stuff": "-0.0437672782627189",
                "net_pass_sr": "0.0113329293539577",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.158931409201659
    },
    {
        "id": 350,
        "name": "Neal Brown",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 133.5,
            "closeness": 4.92112179924087e-7,
            "degree": 11,
            "eigen": 0.000930770140415009
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Troy",
                "position": "OC"
            },
            "2009": {
                "college": "Troy",
                "position": "OC"
            },
            "2010": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2011": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2012": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2013": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2014": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2015": {
                "college": "Troy",
                "position": "HC"
            },
            "2016": {
                "college": "Troy",
                "position": "HC"
            },
            "2017": {
                "college": "Troy",
                "position": "HC"
            },
            "2018": {
                "college": "Troy",
                "position": "HC"
            },
            "2019": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2020": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2021": {
                "college": "West Virginia",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Troy",
                "role": "HC",
                "net_ppa": "0.085481562",
                "net_sr": "0.026050408",
                "net_stuff": "-0.03257089",
                "net_pass_sr": "0.01792942",
                "net_fpi": "8.8",
                "total_sr": "0.104201633",
                "total_stuffs": "-0.130283561",
                "total_pass_sr": "0.07171768",
                "total_ppa": "0.34192625"
            },
            {
                "college": "West Virginia",
                "role": "HC",
                "net_ppa": "-0.090458894",
                "net_sr": "-0.034321139",
                "net_stuff": "0.033383265",
                "net_pass_sr": "-0.045742147",
                "net_fpi": "-7.733333333",
                "total_sr": "-0.102963418",
                "total_stuffs": "0.100149794",
                "total_pass_sr": "-0.137226441",
                "total_ppa": "-0.271376683"
            },
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "0.0836144289292755",
                "net_sr": "0.0147882965639368",
                "net_stuff": "0.0103580628716983",
                "net_pass_sr": "0.0119988775792328",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "-0.0390913441847228",
                "net_sr": "-0.0190214016235253",
                "net_stuff": "0.0348497052392653",
                "net_pass_sr": "-0.00852032930677499",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "0.0765274312082898",
                "net_sr": "0.0824539733921533",
                "net_stuff": "0.00131660603344658",
                "net_pass_sr": "0.110521955462598",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1160731839528425
    },
    {
        "id": 351,
        "name": "Chip Lindsey",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 120,
            "closeness": 4.89705652623378e-7,
            "degree": 6,
            "eigen": 0.000236504783011191
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2015": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2016": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2017": {
                "college": "Auburn",
                "position": "OC"
            },
            "2018": {
                "college": "Auburn",
                "position": "OC"
            },
            "2019": {
                "college": "Troy",
                "position": "HC"
            },
            "2020": {
                "college": "Troy",
                "position": "HC"
            },
            "2021": {
                "college": "Troy",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Troy",
                "role": "HC",
                "net_ppa": "-0.089953261",
                "net_sr": "-0.037291614",
                "net_stuff": "0.062814779",
                "net_pass_sr": "-0.039658075",
                "net_fpi": "-6.433333333",
                "total_sr": "-0.111874842",
                "total_stuffs": "0.188444338",
                "total_pass_sr": "-0.118974224",
                "total_ppa": "-0.269859784"
            },
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "-0.00256475012768445",
                "net_sr": "-0.0274053057817324",
                "net_stuff": "-0.0363099735214123",
                "net_pass_sr": "-0.0203558373948545",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.0350340710888847",
                "net_sr": "-0.028259190476458",
                "net_stuff": "0.00800645586982524",
                "net_pass_sr": "-0.00880481288465373",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.17047671796274",
                "net_sr": "0.0221168060741218",
                "net_stuff": "0.00557866173785598",
                "net_pass_sr": "0.0573652476830094",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.042924635746170836
    },
    {
        "id": 353,
        "name": "Bob Toledo",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 67,
            "closeness": 4.92117023459711e-7,
            "degree": 11,
            "eigen": 0.00401252726565285
        },
        "positions": {
            "2000": {
                "college": "UCLA",
                "position": "HC"
            },
            "2001": {
                "college": "UCLA",
                "position": "HC"
            },
            "2002": {
                "college": "UCLA",
                "position": "HC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2007": {
                "college": "Tulane",
                "position": "HC"
            },
            "2008": {
                "college": "Tulane",
                "position": "HC"
            },
            "2009": {
                "college": "Tulane",
                "position": "HC"
            },
            "2010": {
                "college": "Tulane",
                "position": "HC"
            },
            "2011": {
                "college": "Tulane",
                "position": "HC"
            },
            "2012": false,
            "2013": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2014": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulane",
                "role": "HC",
                "net_ppa": "-0.073728176",
                "net_sr": "-0.031254012",
                "net_stuff": "0.035088966",
                "net_pass_sr": "-0.018767916",
                "net_fpi": "-5.02",
                "total_sr": "-0.156270058",
                "total_stuffs": "0.17544483",
                "total_pass_sr": "-0.093839582",
                "total_ppa": "-0.368640879"
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "0.017384265004687",
                "net_sr": "0.00881788579614268",
                "net_stuff": "-0.0778566010797305",
                "net_pass_sr": "-0.021193812672002",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "0.0162564915086393",
                "net_sr": "-0.00963221432447015",
                "net_stuff": "-0.00962965741248428",
                "net_pass_sr": "-0.0139582386047807",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.040087419486673714
    },
    {
        "id": 354,
        "name": "Curtis Johnson",
        "community": 39,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90047622827986e-7,
            "degree": 4,
            "eigen": 0.0000417282124906617
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Tulane",
                "position": "HC"
            },
            "2013": {
                "college": "Tulane",
                "position": "HC"
            },
            "2014": {
                "college": "Tulane",
                "position": "HC"
            },
            "2015": {
                "college": "Tulane",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulane",
                "role": "HC",
                "net_ppa": "-0.024541811",
                "net_sr": "-0.008666309",
                "net_stuff": "-0.061484042",
                "net_pass_sr": "-0.048212352",
                "net_fpi": "6.791666667",
                "total_sr": "-0.034665237",
                "total_stuffs": "-0.245936169",
                "total_pass_sr": "-0.19284941",
                "total_ppa": "-0.098167244"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.024541811
    },
    {
        "id": 355,
        "name": "Keith Burns",
        "community": 12,
        "isWhite": true,
        "centrality": {
            "betweenness": 6,
            "closeness": 4.89705652623378e-7,
            "degree": 5,
            "eigen": 0.00000825570557580332
        },
        "positions": {
            "2000": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2001": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2002": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2003": false,
            "2004": {
                "college": "San José State",
                "position": "DC"
            },
            "2005": false,
            "2006": {
                "college": "San José State",
                "position": "DC"
            },
            "2007": false,
            "2008": {
                "college": "San José State",
                "position": "DC"
            },
            "2009": {
                "college": "San José State",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "0.0827568655235239",
                "net_sr": "-0.0025850252661932",
                "net_stuff": "-0.00859010511958072",
                "net_pass_sr": "0.0592363962005957",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0827568655235239
    },
    {
        "id": 356,
        "name": "Bill Blankenship",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.9210564130223e-7,
            "degree": 3,
            "eigen": 0.000065682591068393
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2012": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2013": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2014": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulsa",
                "role": "HC",
                "net_ppa": "-0.102170495",
                "net_sr": "-0.043025894",
                "net_stuff": "0.008274312",
                "net_pass_sr": "-0.048443479",
                "net_fpi": "-6.366666667",
                "total_sr": "-0.172103575",
                "total_stuffs": "0.033097248",
                "total_pass_sr": "-0.193773917",
                "total_ppa": "-0.408681978"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.102170495
    },
    {
        "id": 357,
        "name": "Philip Montgomery",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 4,
            "closeness": 4.90391754356886e-7,
            "degree": 6,
            "eigen": 0.000567653924674102
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Houston",
                "position": "OC"
            },
            "2008": {
                "college": "Baylor",
                "position": "OC"
            },
            "2009": {
                "college": "Baylor",
                "position": "OC"
            },
            "2010": {
                "college": "Baylor",
                "position": "OC"
            },
            "2011": {
                "college": "Baylor",
                "position": "OC"
            },
            "2012": {
                "college": "Baylor",
                "position": "OC"
            },
            "2013": {
                "college": "Baylor",
                "position": "OC"
            },
            "2014": {
                "college": "Baylor",
                "position": "OC"
            },
            "2015": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2016": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2017": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2018": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2019": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2020": {
                "college": "Tulsa",
                "position": "HC"
            },
            "2021": {
                "college": "Tulsa",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Tulsa",
                "role": "HC",
                "net_ppa": "0.063127359",
                "net_sr": "0.010508478",
                "net_stuff": "0.030144713",
                "net_pass_sr": "0.027054405",
                "net_fpi": "3.80952381",
                "total_sr": "0.073559344",
                "total_stuffs": "0.211012988",
                "total_pass_sr": "0.189380837",
                "total_ppa": "0.44189151"
            },
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "0.152196384713902",
                "net_sr": "0.0722058419072441",
                "net_stuff": "-0.0571737832044868",
                "net_pass_sr": "0.0613953618068348",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0152781653128474",
                "net_sr": "-0.00357971424955866",
                "net_stuff": "0.0306667119211667",
                "net_pass_sr": "0.0378309341011319",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2000455784010546
    },
    {
        "id": 359,
        "name": "Neil Callaway",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 36,
            "closeness": 4.93495969865162e-7,
            "degree": 5,
            "eigen": 0.000280188756998616
        },
        "positions": {
            "2000": {
                "college": "Alabama",
                "position": "OC"
            },
            "2001": {
                "college": "Georgia",
                "position": "OC"
            },
            "2002": {
                "college": "Georgia",
                "position": "OC"
            },
            "2003": {
                "college": "Georgia",
                "position": "OC"
            },
            "2004": {
                "college": "Georgia",
                "position": "OC"
            },
            "2005": {
                "college": "Georgia",
                "position": "OC"
            },
            "2006": {
                "college": "Georgia",
                "position": "OC"
            },
            "2007": {
                "college": "UAB",
                "position": "HC"
            },
            "2008": {
                "college": "UAB",
                "position": "HC"
            },
            "2009": {
                "college": "UAB",
                "position": "HC"
            },
            "2010": {
                "college": "UAB",
                "position": "HC"
            },
            "2011": {
                "college": "UAB",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UAB",
                "role": "HC",
                "net_ppa": "-0.088879377",
                "net_sr": "-0.042854409",
                "net_stuff": "0.01338785",
                "net_pass_sr": "-0.048025106",
                "net_fpi": "-6.49",
                "total_sr": "-0.214272047",
                "total_stuffs": "0.066939251",
                "total_pass_sr": "-0.240125532",
                "total_ppa": "-0.444396887"
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.088879377
    },
    {
        "id": 360,
        "name": "Garrick McGee",
        "community": 20,
        "isWhite": false,
        "centrality": {
            "betweenness": 129,
            "closeness": 5.00190822798898e-7,
            "degree": 6,
            "eigen": 0.00016016577852525
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2007": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2011": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2012": {
                "college": "UAB",
                "position": "HC"
            },
            "2013": {
                "college": "UAB",
                "position": "HC"
            },
            "2014": {
                "college": "Louisville",
                "position": "OC"
            },
            "2015": {
                "college": "Louisville",
                "position": "OC"
            },
            "2016": {
                "college": "Illinois",
                "position": "OC"
            },
            "2017": {
                "college": "Illinois",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UAB",
                "role": "HC",
                "net_ppa": "0.014479121",
                "net_sr": "0.007474906",
                "net_stuff": "-0.014585625",
                "net_pass_sr": "0.008113921",
                "net_fpi": "-5.683333333",
                "total_sr": "0.014949811",
                "total_stuffs": "-0.029171249",
                "total_pass_sr": "0.016227843",
                "total_ppa": "0.028958242"
            },
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "0.0791681657042091",
                "net_sr": "0.0271342387121075",
                "net_stuff": "0.000960260479866692",
                "net_pass_sr": "0.0400986423945489",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "-0.0413844822027224",
                "net_sr": "-0.0422807737374481",
                "net_stuff": "0.0187218434389222",
                "net_pass_sr": "-0.0550366316268699",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.0500414779754387",
                "net_sr": "-0.0295423384386839",
                "net_stuff": "-0.0135592647668566",
                "net_pass_sr": "-0.0803965526997517",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Northwestern",
                "role": "OC",
                "net_ppa": "-0.103378516681144",
                "net_sr": "-0.0523477461462717",
                "net_stuff": "0.0163912455786802",
                "net_pass_sr": "-0.0461370677536453",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0010742342042185937
    },
    {
        "id": 361,
        "name": "Bill Clark",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 3,
            "closeness": 4.90047862974777e-7,
            "degree": 5,
            "eigen": 0.0000623461601788431
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2013": false,
            "2014": {
                "college": "UAB",
                "position": "HC"
            },
            "2015": false,
            "2016": false,
            "2017": {
                "college": "UAB",
                "position": "HC"
            },
            "2018": {
                "college": "UAB",
                "position": "HC"
            },
            "2019": {
                "college": "UAB",
                "position": "HC"
            },
            "2020": {
                "college": "UAB",
                "position": "HC"
            },
            "2021": {
                "college": "UAB",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "UAB",
                "role": "HC",
                "net_ppa": "0.191785158",
                "net_sr": "0.124714074",
                "net_stuff": "-0.036879304",
                "net_pass_sr": "0.141226235",
                "net_fpi": "N/A",
                "total_sr": "0.748284443",
                "total_stuffs": "-0.221275825",
                "total_pass_sr": "0.847357408",
                "total_ppa": "1.15071095"
            },
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "0.0513136247777915",
                "net_sr": "-0.00287141073657932",
                "net_stuff": "0.00193185809624166",
                "net_pass_sr": "0.0368876080691643",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2430987827777915
    },
    {
        "id": 362,
        "name": "Mike Kruczek",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 224,
            "closeness": 5.30304516762661e-7,
            "degree": 4,
            "eigen": 0.000111280744746946
        },
        "positions": {
            "2000": {
                "college": "UCF",
                "position": "HC"
            },
            "2001": {
                "college": "UCF",
                "position": "HC"
            },
            "2002": {
                "college": "UCF",
                "position": "HC"
            },
            "2003": {
                "college": "UCF",
                "position": "HC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "UMass",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UMass",
                "role": "OC",
                "net_ppa": "-0.113733200331544",
                "net_sr": "-0.110953246238328",
                "net_stuff": "0.108120845807679",
                "net_pass_sr": "-0.155973200090847",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.113733200331544
    },
    {
        "id": 363,
        "name": "Rick Neuheisel",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 5.01622246344679e-7,
            "degree": 7,
            "eigen": 0.0000655693519102895
        },
        "positions": {
            "2000": {
                "college": "Washington",
                "position": "HC"
            },
            "2001": {
                "college": "Washington",
                "position": "HC"
            },
            "2002": {
                "college": "Washington",
                "position": "HC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "UCLA",
                "position": "HC"
            },
            "2009": {
                "college": "UCLA",
                "position": "HC"
            },
            "2010": {
                "college": "UCLA",
                "position": "HC"
            },
            "2011": {
                "college": "UCLA",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCLA",
                "role": "HC",
                "net_ppa": "-0.011371647",
                "net_sr": "-0.01632625",
                "net_stuff": "0.028022765",
                "net_pass_sr": "-0.001639249",
                "net_fpi": "-8.375",
                "total_sr": "-0.065304999",
                "total_stuffs": "0.112091058",
                "total_pass_sr": "-0.006556995",
                "total_ppa": "-0.045486586"
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.011371647
    },
    {
        "id": 364,
        "name": "Jim L. Mora",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90391754356886e-7,
            "degree": 5,
            "eigen": 0.000137876302825182
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "UCLA",
                "position": "HC"
            },
            "2013": {
                "college": "UCLA",
                "position": "HC"
            },
            "2014": {
                "college": "UCLA",
                "position": "HC"
            },
            "2015": {
                "college": "UCLA",
                "position": "HC"
            },
            "2016": {
                "college": "UCLA",
                "position": "HC"
            },
            "2017": {
                "college": "UCLA",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCLA",
                "role": "HC",
                "net_ppa": "0.080840403",
                "net_sr": "0.033535449",
                "net_stuff": "0.046769534",
                "net_pass_sr": "0.057419011",
                "net_fpi": "9.35",
                "total_sr": "0.201212696",
                "total_stuffs": "0.280617202",
                "total_pass_sr": "0.344514067",
                "total_ppa": "0.485042417"
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.080840403
    },
    {
        "id": 365,
        "name": "Bob Diaco",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 52,
            "closeness": 4.90047862974777e-7,
            "degree": 9,
            "eigen": 0.000186791010639136
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Virginia",
                "position": "DC"
            },
            "2010": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2011": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2012": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2013": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2014": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2015": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2016": {
                "college": "Connecticut",
                "position": "HC"
            },
            "2017": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2018": false,
            "2019": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2020": {
                "college": "Purdue",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "HC",
                "net_ppa": "-0.088174393",
                "net_sr": "-0.057627117",
                "net_stuff": "0.080155462",
                "net_pass_sr": "-0.062579944",
                "net_fpi": "N/A",
                "total_sr": "-0.172881352",
                "total_stuffs": "0.240466386",
                "total_pass_sr": "-0.187739832",
                "total_ppa": "-0.26452318"
            },
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.0905921457247296",
                "net_sr": "-0.0349175236746729",
                "net_stuff": "0.032670977166521",
                "net_pass_sr": "-0.0449043495061238",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "0.0950966587407913",
                "net_sr": "0.0349876752441717",
                "net_stuff": "-0.0101080515603883",
                "net_pass_sr": "0.000422023031346863",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "-0.135747392337839",
                "net_sr": "-0.108568073220622",
                "net_stuff": "0.0615258154938848",
                "net_pass_sr": "-0.0999412038696658",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.00695832172438076",
                "net_sr": "0.00206366877642067",
                "net_stuff": "0.00810996949008172",
                "net_pass_sr": "-0.0198569609801569",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.0124042139708779",
                "net_sr": "-0.00606264458388212",
                "net_stuff": "-0.00940572872200121",
                "net_pass_sr": "-0.0472649006059679",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "-0.0204853881573996",
                "net_sr": "-0.023339194471751",
                "net_stuff": "0.045386298396559",
                "net_pass_sr": "0.0119921321508925",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.24534855272567402
    },
    {
        "id": 367,
        "name": "Mike Sanford Sr",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 58,
            "closeness": 4.92110726881991e-7,
            "degree": 9,
            "eigen": 0.000868682720266534
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Stanford",
                "position": "OC"
            },
            "2003": {
                "college": "Utah",
                "position": "OC"
            },
            "2004": {
                "college": "Utah",
                "position": "OC"
            },
            "2005": {
                "college": "UNLV",
                "position": "HC"
            },
            "2006": {
                "college": "UNLV",
                "position": "HC"
            },
            "2007": {
                "college": "UNLV",
                "position": "HC"
            },
            "2008": {
                "college": "UNLV",
                "position": "HC"
            },
            "2009": {
                "college": "UNLV",
                "position": "HC"
            },
            "2010": false,
            "2011": {
                "college": "Louisville",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.00724282975783458",
                "net_sr": "-0.0103821812790919",
                "net_stuff": "0.0235979032296107",
                "net_pass_sr": "0.0305900290587285",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00724282975783458
    },
    {
        "id": 368,
        "name": "Bobby Hauck",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90736369551967e-7,
            "degree": 6,
            "eigen": 0.00000134203748372914
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "UNLV",
                "position": "HC"
            },
            "2011": {
                "college": "UNLV",
                "position": "HC"
            },
            "2012": {
                "college": "UNLV",
                "position": "HC"
            },
            "2013": {
                "college": "UNLV",
                "position": "HC"
            },
            "2014": {
                "college": "UNLV",
                "position": "HC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "HC",
                "net_ppa": "-0.061347314",
                "net_sr": "-0.007371435",
                "net_stuff": "-0.052578478",
                "net_pass_sr": "-0.049765328",
                "net_fpi": "-6.046666667",
                "total_sr": "-0.036857177",
                "total_stuffs": "-0.262892392",
                "total_pass_sr": "-0.248826638",
                "total_ppa": "-0.306736572"
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.061347314
    },
    {
        "id": 369,
        "name": "Tony Sanchez",
        "community": 18,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90048103121802e-7,
            "degree": 4,
            "eigen": 0.00871195893117145
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "UNLV",
                "position": "HC"
            },
            "2016": {
                "college": "UNLV",
                "position": "HC"
            },
            "2017": {
                "college": "UNLV",
                "position": "HC"
            },
            "2018": {
                "college": "UNLV",
                "position": "HC"
            },
            "2019": {
                "college": "UNLV",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "HC",
                "net_ppa": "0.034310578",
                "net_sr": "-0.004012274",
                "net_stuff": "-0.017367959",
                "net_pass_sr": "-0.034965976",
                "net_fpi": "-0.14",
                "total_sr": "-0.02006137",
                "total_stuffs": "-0.086839796",
                "total_pass_sr": "-0.174829878",
                "total_ppa": "0.171552892"
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC"
        ],
        "race": "Other",
        "aggregate_xpa": 0.034310578
    },
    {
        "id": 370,
        "name": "Marcus Arroyo",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 28,
            "closeness": 4.89363440929673e-7,
            "degree": 7,
            "eigen": 0.000155573665118618
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "San José State",
                "position": "OC"
            },
            "2008": {
                "college": "San José State",
                "position": "OC"
            },
            "2009": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2010": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Oregon",
                "position": "OC"
            },
            "2018": {
                "college": "Oregon",
                "position": "OC"
            },
            "2019": {
                "college": "Oregon",
                "position": "OC"
            },
            "2020": {
                "college": "UNLV",
                "position": "HC"
            },
            "2021": {
                "college": "UNLV",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "HC",
                "net_ppa": "-0.080644298",
                "net_sr": "-0.047118361",
                "net_stuff": "0.002452471",
                "net_pass_sr": "-0.028444896",
                "net_fpi": "-3.15",
                "total_sr": "-0.094236723",
                "total_stuffs": "0.004904941",
                "total_pass_sr": "-0.056889793",
                "total_ppa": "-0.161288597"
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "-0.0836861084897566",
                "net_sr": "-0.0303997634212201",
                "net_stuff": "0.00166768040633092",
                "net_pass_sr": "-0.0360123332143555",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "-0.0463823266153386",
                "net_sr": "0.00328230907611871",
                "net_stuff": "0.0614465154616822",
                "net_pass_sr": "0.0299981809362115",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "-0.131263390242848",
                "net_sr": "-0.0712512427899624",
                "net_stuff": "0.0897469252310532",
                "net_pass_sr": "-0.0251159760304044",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "-0.0194347779758152",
                "net_sr": "-0.0180573596755722",
                "net_stuff": "0.0487924888369736",
                "net_pass_sr": "0.00550542918240193",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.3614109013237584
    },
    {
        "id": 373,
        "name": "Clay Helton",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 184,
            "closeness": 4.90047622827986e-7,
            "degree": 7,
            "eigen": 0.00078332757491029
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Memphis",
                "position": "OC"
            },
            "2008": {
                "college": "Memphis",
                "position": "OC"
            },
            "2009": {
                "college": "Memphis",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "USC",
                "position": "OC"
            },
            "2014": {
                "college": "USC",
                "position": "OC"
            },
            "2015": {
                "college": "USC",
                "position": "OC"
            },
            "2016": {
                "college": "USC",
                "position": "HC"
            },
            "2017": {
                "college": "USC",
                "position": "HC"
            },
            "2018": {
                "college": "USC",
                "position": "HC"
            },
            "2019": {
                "college": "USC",
                "position": "HC"
            },
            "2020": {
                "college": "USC",
                "position": "HC"
            },
            "2021": {
                "college": "USC",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "USC",
                "role": "HC",
                "net_ppa": "-0.079417775",
                "net_sr": "0.012851503",
                "net_stuff": "-0.003518739",
                "net_pass_sr": "0.019632671",
                "net_fpi": "-6.8",
                "total_sr": "0.077109019",
                "total_stuffs": "-0.021112435",
                "total_pass_sr": "0.117796024",
                "total_ppa": "-0.47650665"
            },
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.0392859346546499",
                "net_sr": "0.00140898949357698",
                "net_stuff": "0.00574983250336369",
                "net_pass_sr": "0.0140607952813197",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "0.113835963069468",
                "net_sr": "-0.000480364934599853",
                "net_stuff": "0.0138112995160427",
                "net_pass_sr": "0.0176374300928822",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.004867746585181895
    },
    {
        "id": 374,
        "name": "Jim Leavitt",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 99.5,
            "closeness": 4.91079781312352e-7,
            "degree": 11,
            "eigen": 0.000848101352887655
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "South Florida",
                "position": "HC"
            },
            "2002": {
                "college": "South Florida",
                "position": "HC"
            },
            "2003": {
                "college": "South Florida",
                "position": "HC"
            },
            "2004": {
                "college": "South Florida",
                "position": "HC"
            },
            "2005": {
                "college": "South Florida",
                "position": "HC"
            },
            "2006": {
                "college": "South Florida",
                "position": "HC"
            },
            "2007": {
                "college": "South Florida",
                "position": "HC"
            },
            "2008": {
                "college": "South Florida",
                "position": "HC"
            },
            "2009": {
                "college": "South Florida",
                "position": "HC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Colorado",
                "position": "DC"
            },
            "2016": {
                "college": "Colorado",
                "position": "DC"
            },
            "2017": {
                "college": "Oregon",
                "position": "DC"
            },
            "2018": {
                "college": "Oregon",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2021": {
                "college": "SMU",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "0.0556356058395992",
                "net_sr": "0.0341904563949718",
                "net_stuff": "0.0548781019959848",
                "net_pass_sr": "0.0534495635193969",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "0.0530475870391183",
                "net_sr": "-0.000309564907966187",
                "net_stuff": "0.00296366651600991",
                "net_pass_sr": "0.000845687522142424",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "0.14936171680106",
                "net_sr": "0.0778751304759973",
                "net_stuff": "-0.026983962108981",
                "net_pass_sr": "0.0541422986328179",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "-0.0544824160824989",
                "net_sr": "0.028985774959162",
                "net_stuff": "0.0455965598120738",
                "net_pass_sr": "-0.00857895300484413",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2035624935972786
    },
    {
        "id": 378,
        "name": "Brent Guy",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 35,
            "closeness": 4.91076887417186e-7,
            "degree": 7,
            "eigen": 0.000137659350979413
        },
        "positions": {
            "2000": {
                "college": "Boise State",
                "position": "DC"
            },
            "2001": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2002": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2003": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2004": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2005": {
                "college": "Utah State",
                "position": "HC"
            },
            "2006": {
                "college": "Utah State",
                "position": "HC"
            },
            "2007": {
                "college": "Utah State",
                "position": "HC"
            },
            "2008": {
                "college": "Utah State",
                "position": "HC"
            },
            "2009": {
                "college": "Louisville",
                "position": "DC"
            },
            "2010": false,
            "2011": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2012": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2013": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2014": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Florida International",
                "position": "DC"
            },
            "2018": {
                "college": "Florida International",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "-0.0458861286728409",
                "net_sr": "-0.012852122947529",
                "net_stuff": "-0.0020890483443787",
                "net_pass_sr": "-0.02087855397818",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "-0.0684069533668754",
                "net_sr": "-0.0371498042311358",
                "net_stuff": "0.0484130859225903",
                "net_pass_sr": "-0.0661440007421799",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "-0.0457577679922608",
                "net_sr": "-0.000482183705572459",
                "net_stuff": "-0.00908847757080056",
                "net_pass_sr": "-0.0022930566119988",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1600508500319771
    },
    {
        "id": 379,
        "name": "Gary Nord",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 11.5,
            "closeness": 4.9004858341656e-7,
            "degree": 6,
            "eigen": 0.000105149795671022
        },
        "positions": {
            "2000": {
                "college": "UTEP",
                "position": "HC"
            },
            "2001": {
                "college": "UTEP",
                "position": "HC"
            },
            "2002": {
                "college": "UTEP",
                "position": "HC"
            },
            "2003": {
                "college": "UTEP",
                "position": "HC"
            },
            "2004": false,
            "2005": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2006": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2007": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2008": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2009": {
                "college": "Purdue",
                "position": "OC"
            },
            "2010": {
                "college": "Purdue",
                "position": "OC"
            },
            "2011": {
                "college": "Purdue",
                "position": "OC"
            },
            "2012": {
                "college": "Purdue",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.0377868543108345",
                "net_sr": "-0.029441458980869",
                "net_stuff": "-0.00991934823624044",
                "net_pass_sr": "-0.0255185552423152",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "HC",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0377868543108345
    },
    {
        "id": 381,
        "name": "Sean Kugler",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90048103121802e-7,
            "degree": 4,
            "eigen": 0.00022032011107835
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "UTEP",
                "position": "HC"
            },
            "2014": {
                "college": "UTEP",
                "position": "HC"
            },
            "2015": {
                "college": "UTEP",
                "position": "HC"
            },
            "2016": {
                "college": "UTEP",
                "position": "HC"
            },
            "2017": {
                "college": "UTEP",
                "position": "HC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UTEP",
                "role": "HC",
                "net_ppa": "-0.11222702",
                "net_sr": "-0.013209201",
                "net_stuff": "-0.025767202",
                "net_pass_sr": "-0.032709154",
                "net_fpi": "-9.34",
                "total_sr": "-0.066046003",
                "total_stuffs": "-0.128836008",
                "total_pass_sr": "-0.163545772",
                "total_ppa": "-0.561135099"
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.11222702
    },
    {
        "id": 382,
        "name": "Frank Wilson",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.90392475810165e-7,
            "degree": 5,
            "eigen": 0.000101309425619689
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "UT San Antonio",
                "position": "HC"
            },
            "2017": {
                "college": "UT San Antonio",
                "position": "HC"
            },
            "2018": {
                "college": "UT San Antonio",
                "position": "HC"
            },
            "2019": {
                "college": "UT San Antonio",
                "position": "HC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UT San Antonio",
                "role": "HC",
                "net_ppa": "-0.007327021",
                "net_sr": "-0.004931647",
                "net_stuff": "-0.05964697",
                "net_pass_sr": "-0.016810043",
                "net_fpi": "N/A",
                "total_sr": "-0.019726586",
                "total_stuffs": "-0.238587882",
                "total_pass_sr": "-0.067240171",
                "total_ppa": "-0.029308082"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.007327021
    },
    {
        "id": 383,
        "name": "Jeff Traylor",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89706372059313e-7,
            "degree": 3,
            "eigen": 0.0000100692604838517
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "UT San Antonio",
                "position": "HC"
            },
            "2021": {
                "college": "UT San Antonio",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "UT San Antonio",
                "role": "HC",
                "net_ppa": "0.139206126",
                "net_sr": "0.039421707",
                "net_stuff": "-0.006937847",
                "net_pass_sr": "0.092481484",
                "net_fpi": "N/A",
                "total_sr": "0.078843415",
                "total_stuffs": "-0.013875694",
                "total_pass_sr": "0.184962967",
                "total_ppa": "0.278412252"
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.139206126
    },
    {
        "id": 386,
        "name": "Robbie Caldwell",
        "community": 42,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89363680406368e-7,
            "degree": 2,
            "eigen": 6.53039388110914e-8
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Vanderbilt",
                "role": "HC",
                "net_ppa": "-0.053523933",
                "net_sr": "-0.021723866",
                "net_stuff": "-0.025995144",
                "net_pass_sr": "-0.093940961",
                "net_fpi": "-9.5",
                "total_sr": "-0.021723866",
                "total_stuffs": "-0.025995144",
                "total_pass_sr": "-0.093940961",
                "total_ppa": "-0.053523933"
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.053523933
    },
    {
        "id": 387,
        "name": "Derek Mason",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 276,
            "closeness": 5.06327575713694e-7,
            "degree": 8,
            "eigen": 0.0141931097694198
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Stanford",
                "position": "DC"
            },
            "2012": {
                "college": "Stanford",
                "position": "DC"
            },
            "2013": {
                "college": "Stanford",
                "position": "DC"
            },
            "2014": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2015": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2016": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2017": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2018": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2019": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2020": {
                "college": "Vanderbilt",
                "position": "HC"
            },
            "2021": {
                "college": "Auburn",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Vanderbilt",
                "role": "HC",
                "net_ppa": "-0.149001316",
                "net_sr": "-0.057772884",
                "net_stuff": "0.029780092",
                "net_pass_sr": "-0.059887118",
                "net_fpi": "-10.88571429",
                "total_sr": "-0.404410188",
                "total_stuffs": "0.208460642",
                "total_pass_sr": "-0.419209828",
                "total_ppa": "-1.043009215"
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "0.0133417639017151",
                "net_sr": "-0.00985309903433457",
                "net_stuff": "-0.0322670032874608",
                "net_pass_sr": "-0.0603190639823692",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "0.0457529081175842",
                "net_sr": "0.0532133312003213",
                "net_stuff": "-0.0766055267652054",
                "net_pass_sr": "0.0324236435666816",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0899066439807007
    },
    {
        "id": 388,
        "name": "Clark Lea",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 2,
            "closeness": 4.89363680406368e-7,
            "degree": 3,
            "eigen": 0.0000494215544119242
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2019": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2020": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2021": {
                "college": "Vanderbilt",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Vanderbilt",
                "role": "HC",
                "net_ppa": "-0.093953346",
                "net_sr": "-0.069718702",
                "net_stuff": "0.006125571",
                "net_pass_sr": "-0.0745107",
                "net_fpi": "-11.53333333",
                "total_sr": "-0.069718702",
                "total_stuffs": "0.006125571",
                "total_pass_sr": "-0.0745107",
                "total_ppa": "-0.093953346"
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.0455173953931955",
                "net_sr": "0.0248412474641968",
                "net_stuff": "-0.022211514130662",
                "net_pass_sr": "0.0437872242647644",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.048435950606804494
    },
    {
        "id": 390,
        "name": "Al Groh",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 54,
            "closeness": 5.077729889143e-7,
            "degree": 9,
            "eigen": 0.000262801428096536
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Virginia",
                "position": "HC"
            },
            "2002": {
                "college": "Virginia",
                "position": "HC"
            },
            "2003": {
                "college": "Virginia",
                "position": "HC"
            },
            "2004": {
                "college": "Virginia",
                "position": "HC"
            },
            "2005": {
                "college": "Virginia",
                "position": "HC"
            },
            "2006": {
                "college": "Virginia",
                "position": "HC"
            },
            "2007": {
                "college": "Virginia",
                "position": "HC"
            },
            "2008": {
                "college": "Virginia",
                "position": "HC"
            },
            "2009": {
                "college": "Virginia",
                "position": "HC"
            },
            "2010": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2011": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2012": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Tech",
                "role": "OC",
                "net_ppa": "-0.0147582290961475",
                "net_sr": "-0.0245347387694291",
                "net_stuff": "0.0392551208178576",
                "net_pass_sr": "-0.0265089761115043",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0147582290961475
    },
    {
        "id": 391,
        "name": "Mike London",
        "community": 32,
        "isWhite": false,
        "centrality": {
            "betweenness": 14,
            "closeness": 4.91077369730678e-7,
            "degree": 5,
            "eigen": 0.000115607321355813
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Virginia",
                "position": "DC"
            },
            "2007": {
                "college": "Virginia",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Virginia",
                "position": "HC"
            },
            "2011": {
                "college": "Virginia",
                "position": "HC"
            },
            "2012": {
                "college": "Virginia",
                "position": "HC"
            },
            "2013": {
                "college": "Virginia",
                "position": "HC"
            },
            "2014": {
                "college": "Virginia",
                "position": "HC"
            },
            "2015": {
                "college": "Virginia",
                "position": "HC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Virginia",
                "role": "HC",
                "net_ppa": "0.027807292",
                "net_sr": "0.026770941",
                "net_stuff": "-0.127640204",
                "net_pass_sr": "0.033178156",
                "net_fpi": "N/A",
                "total_sr": "0.160625648",
                "total_stuffs": "-0.765841222",
                "total_pass_sr": "0.199068937",
                "total_ppa": "0.166843751"
            },
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "0.120258538896548",
                "net_sr": "0.0676558992416699",
                "net_stuff": "-0.00625777606480657",
                "net_pass_sr": "0.0662793176972282",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.148065830896548
    },
    {
        "id": 395,
        "name": "Jimmy Lake",
        "community": 23,
        "isWhite": false,
        "centrality": {
            "betweenness": 7,
            "closeness": 4.90049063712259e-7,
            "degree": 5,
            "eigen": 0.0094610050471651
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Washington",
                "position": "DC"
            },
            "2017": {
                "college": "Washington",
                "position": "DC"
            },
            "2018": {
                "college": "Washington",
                "position": "DC"
            },
            "2019": {
                "college": "Washington",
                "position": "DC"
            },
            "2020": {
                "college": "Washington",
                "position": "HC"
            },
            "2021": {
                "college": "Washington",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Washington",
                "role": "HC",
                "net_ppa": "-0.152687014",
                "net_sr": "-0.091254365",
                "net_stuff": "0.008052378",
                "net_pass_sr": "-0.028915896",
                "net_fpi": "-13.63333333",
                "total_sr": "-0.182508731",
                "total_stuffs": "0.016104756",
                "total_pass_sr": "-0.057831792",
                "total_ppa": "-0.305374028"
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "0.00783737672034725",
                "net_sr": "0.0192896207041121",
                "net_stuff": "0.00240371649643148",
                "net_pass_sr": "0.020148229792614",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.14484963727965275
    },
    {
        "id": 396,
        "name": "Bill Doba",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 1,
            "closeness": 4.89362004074428e-7,
            "degree": 3,
            "eigen": 0.0000386669061271515
        },
        "positions": {
            "2000": {
                "college": "Washington State",
                "position": "DC"
            },
            "2001": {
                "college": "Washington State",
                "position": "DC"
            },
            "2002": {
                "college": "Washington State",
                "position": "DC"
            },
            "2003": {
                "college": "Washington State",
                "position": "HC"
            },
            "2004": {
                "college": "Washington State",
                "position": "HC"
            },
            "2005": {
                "college": "Washington State",
                "position": "HC"
            },
            "2006": {
                "college": "Washington State",
                "position": "HC"
            },
            "2007": {
                "college": "Washington State",
                "position": "HC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Washington State",
                "role": "HC",
                "net_ppa": "-0.06444198",
                "net_sr": "-0.032997959",
                "net_stuff": "0.069894762",
                "net_pass_sr": "-0.011921012",
                "net_fpi": "N/A",
                "total_sr": "-0.032997959",
                "total_stuffs": "0.069894762",
                "total_pass_sr": "-0.011921012",
                "total_ppa": "-0.06444198"
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.06444198
    },
    {
        "id": 397,
        "name": "Paul Wulff",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89704453568183e-7,
            "degree": 3,
            "eigen": 0.0000507886522993343
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Washington State",
                "position": "HC"
            },
            "2009": {
                "college": "Washington State",
                "position": "HC"
            },
            "2010": {
                "college": "Washington State",
                "position": "HC"
            },
            "2011": {
                "college": "Washington State",
                "position": "HC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Washington State",
                "role": "HC",
                "net_ppa": "-0.145146337",
                "net_sr": "-0.078975007",
                "net_stuff": "0.030008202",
                "net_pass_sr": "-0.053719642",
                "net_fpi": "N/A",
                "total_sr": "-0.31590003",
                "total_stuffs": "0.120032806",
                "total_pass_sr": "-0.214878566",
                "total_ppa": "-0.580585347"
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.145146337
    },
    {
        "id": 399,
        "name": "Bill Stewart",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.89362722500996e-7,
            "degree": 2,
            "eigen": 0.000327905269221086
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2009": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2010": {
                "college": "West Virginia",
                "position": "HC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "West Virginia",
                "role": "HC",
                "net_ppa": "-0.057752381",
                "net_sr": "-0.011725382",
                "net_stuff": "0.019085282",
                "net_pass_sr": "0.00972365",
                "net_fpi": "-6.3",
                "total_sr": "-0.035176145",
                "total_stuffs": "0.057255845",
                "total_pass_sr": "0.029170949",
                "total_ppa": "-0.173257142"
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.057752381
    },
    {
        "id": 400,
        "name": "David Elson",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 53,
            "closeness": 4.89706372059313e-7,
            "degree": 6,
            "eigen": 0.0000100262548424907
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2009": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2010": false,
            "2011": false,
            "2012": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2013": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Ball State",
                "position": "DC"
            },
            "2018": {
                "college": "Ball State",
                "position": "DC"
            },
            "2019": {
                "college": "Ball State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "HC",
                "net_ppa": "-0.17682052",
                "net_sr": "-0.02872198",
                "net_stuff": "-0.042997989",
                "net_pass_sr": "-0.193073297",
                "net_fpi": "N/A",
                "total_sr": "-0.05744396",
                "total_stuffs": "-0.085995979",
                "total_pass_sr": "-0.386146593",
                "total_ppa": "-0.353641039"
            },
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "0.00583061842162583",
                "net_sr": "0.00359525321487542",
                "net_stuff": "0.0100146908593456",
                "net_pass_sr": "0.0247197042793367",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "-0.0288617662866603",
                "net_sr": "-0.0310285418823281",
                "net_stuff": "0.0108334134212162",
                "net_pass_sr": "-0.033443381494392",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.19985166786503447
    },
    {
        "id": 401,
        "name": "Mike Sanford Jr",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 20,
            "closeness": 4.89363201453213e-7,
            "degree": 6,
            "eigen": 0.000736505241701804
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Boise State",
                "position": "OC"
            },
            "2015": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2016": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2017": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2018": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2019": {
                "college": "Utah State",
                "position": "OC"
            },
            "2020": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2021": {
                "college": "Minnesota",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "HC",
                "net_ppa": "-0.177903196",
                "net_sr": "-0.081121611",
                "net_stuff": "0.043929023",
                "net_pass_sr": "-0.083001659",
                "net_fpi": "N/A",
                "total_sr": "-0.162243222",
                "total_stuffs": "0.087858046",
                "total_pass_sr": "-0.166003318",
                "total_ppa": "-0.355806391"
            },
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "0.166221988511055",
                "net_sr": "0.0110499487320498",
                "net_stuff": "-0.0145316814608153",
                "net_pass_sr": "-0.039442952122128",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.0184477232500561",
                "net_sr": "-0.0113304366693777",
                "net_stuff": "-0.0117956245214732",
                "net_pass_sr": "0.0127280370325501",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.124319902249374",
                "net_sr": "0.013978468800755",
                "net_stuff": "-0.00919077458480752",
                "net_pass_sr": "-0.000583222479632772",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "-0.0395195261983619",
                "net_sr": "0.00763361886479613",
                "net_stuff": "0.00209629292193289",
                "net_pass_sr": "-0.00539626724162062",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.09156689181212319
    },
    {
        "id": 402,
        "name": "Tyson Helton",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 70,
            "closeness": 4.90392235325503e-7,
            "degree": 7,
            "eigen": 0.0000620850012779282
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2015": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2019": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2020": {
                "college": "Western Kentucky",
                "position": "HC"
            },
            "2021": {
                "college": "Western Kentucky",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "HC",
                "net_ppa": "-0.010240107",
                "net_sr": "0.010350236",
                "net_stuff": "0.028744211",
                "net_pass_sr": "0.040415303",
                "net_fpi": "N/A",
                "total_sr": "0.031050708",
                "total_stuffs": "0.086232633",
                "total_pass_sr": "0.12124591",
                "total_ppa": "-0.03072032"
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.0196270155581134",
                "net_sr": "-0.0461046850693688",
                "net_stuff": "0.11685789229181",
                "net_pass_sr": "-0.0192163258908351",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "0.233447838107192",
                "net_sr": "0.0642090441923936",
                "net_stuff": "0.0175205631218962",
                "net_pass_sr": "0.0738000617939106",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.20358071554907858
    },
    {
        "id": 403,
        "name": "Gary Darnell",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 5,
            "closeness": 4.90736128729901e-7,
            "degree": 7,
            "eigen": 0.000282962655256077
        },
        "positions": {
            "2000": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2001": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2002": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2003": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2004": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2005": false,
            "2006": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2007": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "0.0493687533925682",
                "net_sr": "0.00889714795443125",
                "net_stuff": "0.0262292685536223",
                "net_pass_sr": "0.0356141929398351",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0493687533925682
    },
    {
        "id": 404,
        "name": "Tim Lester",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 353.5,
            "closeness": 4.91080022471822e-7,
            "degree": 8,
            "eigen": 0.000231196510109756
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2016": false,
            "2017": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2018": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2019": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2020": {
                "college": "Western Michigan",
                "position": "HC"
            },
            "2021": {
                "college": "Western Michigan",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Western Michigan",
                "role": "HC",
                "net_ppa": "-0.112098039",
                "net_sr": "-0.025750602",
                "net_stuff": "-0.003658594",
                "net_pass_sr": "-0.050793294",
                "net_fpi": "N/A",
                "total_sr": "-0.128753012",
                "total_stuffs": "-0.018292972",
                "total_pass_sr": "-0.253966469",
                "total_ppa": "-0.560490195"
            },
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "0.0375212795376714",
                "net_sr": "-0.00451078406431732",
                "net_stuff": "0.0275533869621829",
                "net_pass_sr": "-0.0287227257444134",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0745767594623286
    },
    {
        "id": 406,
        "name": "Vic Koenning",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 26,
            "closeness": 4.89362961976987e-7,
            "degree": 7,
            "eigen": 0.000504351156934565
        },
        "positions": {
            "2000": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2001": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2002": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2003": {
                "college": "Troy",
                "position": "DC"
            },
            "2004": {
                "college": "Troy",
                "position": "DC"
            },
            "2005": {
                "college": "Clemson",
                "position": "DC"
            },
            "2006": {
                "college": "Clemson",
                "position": "DC"
            },
            "2007": {
                "college": "Clemson",
                "position": "DC"
            },
            "2008": {
                "college": "Clemson",
                "position": "DC"
            },
            "2009": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2010": {
                "college": "Illinois",
                "position": "DC"
            },
            "2011": {
                "college": "Illinois",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Troy",
                "position": "DC"
            },
            "2016": {
                "college": "Troy",
                "position": "DC"
            },
            "2017": {
                "college": "Troy",
                "position": "DC"
            },
            "2018": {
                "college": "Troy",
                "position": "DC"
            },
            "2019": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "0.00513340255030673",
                "net_sr": "-0.0038632166739907",
                "net_stuff": "-0.0189472671391737",
                "net_pass_sr": "0.0109505203518219",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "0.0541575904170661",
                "net_sr": "-0.0119427659099036",
                "net_stuff": "0.00129210562264898",
                "net_pass_sr": "-0.0131010918512817",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "0.00929513023261105",
                "net_sr": "0.0288212861741478",
                "net_stuff": "0.0200830154291766",
                "net_pass_sr": "0.0352051037383416",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "-0.0176530345491933",
                "net_sr": "0.0665526287774474",
                "net_stuff": "-0.0292303168811729",
                "net_pass_sr": "0.084058261746735",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "HC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.05093308865079059
    },
    {
        "id": 408,
        "name": "Dave Christensen",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 48.5,
            "closeness": 4.93152090077188e-7,
            "degree": 7,
            "eigen": 0.000296319555039915
        },
        "positions": {
            "2000": {
                "college": "Toledo",
                "position": "OC"
            },
            "2001": {
                "college": "Missouri",
                "position": "OC"
            },
            "2002": false,
            "2003": {
                "college": "Missouri",
                "position": "OC"
            },
            "2004": {
                "college": "Missouri",
                "position": "OC"
            },
            "2005": {
                "college": "Missouri",
                "position": "OC"
            },
            "2006": {
                "college": "Missouri",
                "position": "OC"
            },
            "2007": {
                "college": "Missouri",
                "position": "OC"
            },
            "2008": {
                "college": "Missouri",
                "position": "OC"
            },
            "2009": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2010": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2011": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2012": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2013": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2014": {
                "college": "Utah",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Wyoming",
                "role": "HC",
                "net_ppa": "-0.088614498",
                "net_sr": "-0.065037314",
                "net_stuff": "0.02797909",
                "net_pass_sr": "-0.04382064",
                "net_fpi": "-2.286666667",
                "total_sr": "-0.325186572",
                "total_stuffs": "0.139895449",
                "total_pass_sr": "-0.219103201",
                "total_ppa": "-0.443072491"
            },
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "0.121087899306459",
                "net_sr": "0.0388761979072925",
                "net_stuff": "-0.0277095686507854",
                "net_pass_sr": "0.0221560817694434",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.032473401306458996
    },
    {
        "id": 409,
        "name": "Craig Bohl",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 6,
            "closeness": 4.90735647086478e-7,
            "degree": 7,
            "eigen": 0.145293458324326
        },
        "positions": {
            "2000": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2001": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2002": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2015": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2016": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2017": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2018": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2019": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2020": {
                "college": "Wyoming",
                "position": "HC"
            },
            "2021": {
                "college": "Wyoming",
                "position": "HC"
            }
        },
        "performance": [
            {
                "college": "Wyoming",
                "role": "HC",
                "net_ppa": "0.059960565",
                "net_sr": "0.031185363",
                "net_stuff": "-0.043388383",
                "net_pass_sr": "-0.007902227",
                "net_fpi": "3.325",
                "total_sr": "0.249482905",
                "total_stuffs": "-0.347107062",
                "total_pass_sr": "-0.063217813",
                "total_ppa": "0.479684518"
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC",
            "HC"
        ],
        "race": "White",
        "aggregate_xpa": 0.059960565
    },
    {
        "id": 411,
        "name": "John Rudzinski",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000845945438237086
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Air Force",
                "position": "DC"
            },
            "2019": {
                "college": "Air Force",
                "position": "DC"
            },
            "2020": {
                "college": "Air Force",
                "position": "DC"
            },
            "2021": {
                "college": "Air Force",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Air Force",
                "role": "OC",
                "net_ppa": "0.04056482356557",
                "net_sr": "-0.0233348893052618",
                "net_stuff": "0.0559502355201938",
                "net_pass_sr": "-0.0505676175394426",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.04056482356557
    },
    {
        "id": 413,
        "name": "Joe Tresey",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.0000910667673202463
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Akron",
                "position": "DC"
            },
            "2003": {
                "college": "Akron",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2007": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2008": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2009": {
                "college": "South Florida",
                "position": "DC"
            },
            "2010": false,
            "2011": {
                "college": "UCLA",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Akron",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "0.0687213202384742",
                "net_sr": "0.0222094325757125",
                "net_stuff": "-0.0111752215905714",
                "net_pass_sr": "0.0460140661408612",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "-0.0459664210553613",
                "net_sr": "-0.0709131000522039",
                "net_stuff": "0.0385305190762754",
                "net_pass_sr": "-0.085856731100947",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.0366570389865285",
                "net_sr": "-0.0380618853552995",
                "net_stuff": "0.0355908680442354",
                "net_pass_sr": "-0.0443042111198444",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "-0.0241173832168502",
                "net_sr": "-0.0458309167176595",
                "net_stuff": "-0.0132922379058159",
                "net_pass_sr": "-0.049224209996972",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "-0.076845044431189",
                "net_sr": "-0.0404723389952211",
                "net_stuff": "0.0341486660388194",
                "net_pass_sr": "-0.0616727091427966",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1148645674514548
    },
    {
        "id": 414,
        "name": "Jim Fleming",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000808670826886645
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Akron",
                "position": "DC"
            },
            "2005": {
                "college": "Akron",
                "position": "DC"
            },
            "2006": {
                "college": "Akron",
                "position": "DC"
            },
            "2007": {
                "college": "Akron",
                "position": "DC"
            },
            "2008": {
                "college": "Akron",
                "position": "DC"
            },
            "2009": {
                "college": "Akron",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": {
                "college": "UCF",
                "position": "DC"
            },
            "2013": {
                "college": "UCF",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "-0.0512867816028119",
                "net_sr": "-0.0779106526193217",
                "net_stuff": "0.0387389434485748",
                "net_pass_sr": "-0.0999449513640472",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.0345625382924792",
                "net_sr": "-0.0223834622372917",
                "net_stuff": "0.014790539991484",
                "net_pass_sr": "-0.0119730536838434",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0858493198952911
    },
    {
        "id": 415,
        "name": "Curt Mallory",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000102654352475693
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Illinois",
                "position": "DC"
            },
            "2008": {
                "college": "Illinois",
                "position": "DC"
            },
            "2009": {
                "college": "Illinois",
                "position": "DC"
            },
            "2010": {
                "college": "Akron",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "-0.029522827092609",
                "net_sr": "0.0208059145059012",
                "net_stuff": "0.0180497644507927",
                "net_pass_sr": "-0.00163027709361779",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "0.00512228303046067",
                "net_sr": "0.0180832795124352",
                "net_stuff": "-0.0195590705225938",
                "net_pass_sr": "0.0280781699961286",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.024400544062148328
    },
    {
        "id": 416,
        "name": "Kevin Cosgrove",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.000365976624231678
        },
        "positions": {
            "2000": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2001": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2002": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2003": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2004": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2005": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2006": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2007": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2008": false,
            "2009": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2010": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2011": {
                "college": "Akron",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2015": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2016": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2017": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2018": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "-0.00816274477295716",
                "net_sr": "-0.0381502710862603",
                "net_stuff": "0.0364158030415504",
                "net_pass_sr": "-0.0292414922143379",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.0398190211971033",
                "net_sr": "0.0247317122579223",
                "net_stuff": "-0.0284653361840844",
                "net_pass_sr": "-0.0162359758514997",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "-0.0618521553074468",
                "net_sr": "0.047566618451186",
                "net_stuff": "-0.017656963028467",
                "net_pass_sr": "0.0772705208715346",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.030195878883300657
    },
    {
        "id": 417,
        "name": "Matt Feeney",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 5.97779221093526e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Akron",
                "position": "DC"
            },
            "2020": {
                "college": "Akron",
                "position": "DC"
            },
            "2021": {
                "college": "Akron",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "-0.191592599165298",
                "net_sr": "-0.0542337112367909",
                "net_stuff": "0.0499171891621061",
                "net_pass_sr": "-0.0794247938142959",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.191592599165298
    },
    {
        "id": 418,
        "name": "Joe Kines",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000209103827541753
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Alabama",
                "position": "DC"
            },
            "2004": {
                "college": "Alabama",
                "position": "DC"
            },
            "2005": {
                "college": "Alabama",
                "position": "DC"
            },
            "2006": {
                "college": "Alabama",
                "position": "DC"
            },
            "2007": false,
            "2008": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2009": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "0.0074955587044874",
                "net_sr": "-0.0120998910617591",
                "net_stuff": "-0.000899484019849628",
                "net_pass_sr": "-0.0232873830534499",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0074955587044874
    },
    {
        "id": 419,
        "name": "Tosh Lupoi",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00039920233533861
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Alabama",
                "position": "DC"
            },
            "2017": {
                "college": "Alabama",
                "position": "DC"
            },
            "2018": {
                "college": "Alabama",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.0957196681286161",
                "net_sr": "0.0768987615345468",
                "net_stuff": "-0.025686797053533",
                "net_pass_sr": "0.0532208239643689",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0957196681286161
    },
    {
        "id": 420,
        "name": "Pete Golding",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000541339132473238
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2017": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2018": {
                "college": "Alabama",
                "position": "DC"
            },
            "2019": {
                "college": "Alabama",
                "position": "DC"
            },
            "2020": {
                "college": "Alabama",
                "position": "DC"
            },
            "2021": {
                "college": "Alabama",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.150654967924586",
                "net_sr": "0.0654436080789679",
                "net_stuff": "-0.0414496235560813",
                "net_pass_sr": "0.0318583703934973",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UT San Antonio",
                "role": "OC",
                "net_ppa": "-0.11630969782357",
                "net_sr": "-0.0572738981319384",
                "net_stuff": "0.0607690181812977",
                "net_pass_sr": "-0.04532262281838",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.034345270101016
    },
    {
        "id": 421,
        "name": "Charles Kelly",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000935608605788021
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Florida State",
                "position": "DC"
            },
            "2015": {
                "college": "Florida State",
                "position": "DC"
            },
            "2016": {
                "college": "Florida State",
                "position": "DC"
            },
            "2017": {
                "college": "Florida State",
                "position": "DC"
            },
            "2018": false,
            "2019": {
                "college": "Alabama",
                "position": "DC"
            },
            "2020": {
                "college": "Alabama",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "-0.0674792246541757",
                "net_sr": "-0.0511982109815541",
                "net_stuff": "0.053183904461223",
                "net_pass_sr": "-0.0366433416856155",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "-0.152409931010756",
                "net_sr": "-0.0641740961374027",
                "net_stuff": "0.0168019210855595",
                "net_pass_sr": "-0.0481499482432909",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.21988915566493172
    },
    {
        "id": 422,
        "name": "Nate Woody",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000422242589372807
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Appalachian State",
                "position": "DC"
            },
            "2015": {
                "college": "Appalachian State",
                "position": "DC"
            },
            "2016": {
                "college": "Appalachian State",
                "position": "DC"
            },
            "2017": {
                "college": "Appalachian State",
                "position": "DC"
            },
            "2018": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "Army",
                "position": "DC"
            },
            "2021": {
                "college": "Army",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "OC",
                "net_ppa": "0.0483120551178003",
                "net_sr": "0.0147163807082384",
                "net_stuff": "-0.00239647774845048",
                "net_pass_sr": "0.0452495162567155",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "0.0546603632043606",
                "net_sr": "0.047987422221907",
                "net_stuff": "-0.0435418398248356",
                "net_pass_sr": "0.00438320714497903",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia Tech",
                "role": "OC",
                "net_ppa": "-0.0713620598060729",
                "net_sr": "-0.0389389490279569",
                "net_stuff": "0.000571412501201379",
                "net_pass_sr": "-0.0455572081234619",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.031610358516088
    },
    {
        "id": 423,
        "name": "Scot Sloan",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000057209490749939
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Appalachian State",
                "position": "DC"
            },
            "2018": {
                "college": "Georgia Southern",
                "position": "DC"
            },
            "2019": {
                "college": "Georgia Southern",
                "position": "DC"
            },
            "2020": {
                "college": "Georgia Southern",
                "position": "DC"
            },
            "2021": {
                "college": "Georgia Southern",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "OC",
                "net_ppa": "0.127565925976451",
                "net_sr": "0.0372159409870664",
                "net_stuff": "-0.00114004408440313",
                "net_pass_sr": "0.0528703818773126",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia Southern",
                "role": "OC",
                "net_ppa": "0.0242745951918532",
                "net_sr": "0.0506290355685085",
                "net_stuff": "-0.0360790089687939",
                "net_pass_sr": "0.0446295565398676",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1518405211683042
    },
    {
        "id": 424,
        "name": "Bryan Brown",
        "community": 10,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000987462964373646
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Appalachian State",
                "position": "DC"
            },
            "2019": {
                "college": "Louisville",
                "position": "DC"
            },
            "2020": {
                "college": "Louisville",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "OC",
                "net_ppa": "0.0591108575972374",
                "net_sr": "0.0152710586355226",
                "net_stuff": "-0.00791841251977224",
                "net_pass_sr": "0.0264837693217522",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "-0.0183304899172962",
                "net_sr": "0.0229262656225488",
                "net_stuff": "0.00675002627446539",
                "net_pass_sr": "0.0291716718954265",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0407803676799412
    },
    {
        "id": 425,
        "name": "Dale Jones",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000501713120976334
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Appalachian State",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "Appalachian State",
                "position": "DC"
            },
            "2021": {
                "college": "Appalachian State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "OC",
                "net_ppa": "-0.0127807686947258",
                "net_sr": "0.0138728975430528",
                "net_stuff": "-0.017925970497347",
                "net_pass_sr": "0.0149793420912575",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0127807686947258
    },
    {
        "id": 427,
        "name": "Larry Mac Duff",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000153259824749992
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Arizona",
                "position": "DC"
            },
            "2002": {
                "college": "Arizona",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Texas",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.00990186297804516",
                "net_sr": "-0.0454029806660098",
                "net_stuff": "0.0178809990865451",
                "net_pass_sr": "-0.0573946758255834",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00990186297804516
    },
    {
        "id": 428,
        "name": "Greg Brown",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000693315774240688
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Arizona",
                "position": "DC"
            },
            "2011": {
                "college": "Colorado",
                "position": "DC"
            },
            "2012": {
                "college": "Colorado",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "-0.00739182175151712",
                "net_sr": "0.0160779572650624",
                "net_stuff": "-0.0139975732811544",
                "net_pass_sr": "-0.00483397803119495",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "-0.10290725725269",
                "net_sr": "-0.0383979280471562",
                "net_stuff": "0.00899539662386273",
                "net_pass_sr": "-0.0164591807354321",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.11029907900420712
    },
    {
        "id": 429,
        "name": "Tim Kish",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00149318637127559
        },
        "positions": {
            "2000": {
                "college": "Illinois",
                "position": "DC"
            },
            "2001": {
                "college": "Ohio",
                "position": "DC"
            },
            "2002": {
                "college": "Indiana",
                "position": "DC"
            },
            "2003": {
                "college": "Indiana",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Arizona",
                "position": "DC"
            },
            "2011": {
                "college": "Arizona",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "0.240410816955335",
                "net_sr": "0.0557526194439799",
                "net_stuff": "-0.00637279708921593",
                "net_pass_sr": "0.077728919347189",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.240410816955335
    },
    {
        "id": 430,
        "name": "Jeff Casteel",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00236600698323259
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2003": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2004": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2005": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2006": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2007": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2008": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2009": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2010": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2011": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2012": {
                "college": "Arizona",
                "position": "DC"
            },
            "2013": {
                "college": "Arizona",
                "position": "DC"
            },
            "2014": {
                "college": "Arizona",
                "position": "DC"
            },
            "2015": {
                "college": "Arizona",
                "position": "DC"
            },
            "2016": false,
            "2017": {
                "college": "Nevada",
                "position": "DC"
            },
            "2018": {
                "college": "Nevada",
                "position": "DC"
            },
            "2019": {
                "college": "Nevada",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "-0.079106945676305",
                "net_sr": "-0.0293487839381553",
                "net_stuff": "0.016786158413469",
                "net_pass_sr": "-0.0418448673132391",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "0.0211916938153898",
                "net_sr": "0.0391284266035425",
                "net_stuff": "-0.0331774287098832",
                "net_pass_sr": "0.0114838816833778",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0579152518609152
    },
    {
        "id": 431,
        "name": "Marcel Yates",
        "community": 15,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00181116961372123
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2013": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2014": {
                "college": "Boise State",
                "position": "DC"
            },
            "2015": {
                "college": "Boise State",
                "position": "DC"
            },
            "2016": {
                "college": "Arizona",
                "position": "DC"
            },
            "2017": {
                "college": "Arizona",
                "position": "DC"
            },
            "2018": {
                "college": "Arizona",
                "position": "DC"
            },
            "2019": {
                "college": "Arizona",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "-0.0969333069848135",
                "net_sr": "-0.0342312037889088",
                "net_stuff": "0.0139410453947378",
                "net_pass_sr": "-0.0124277742652846",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "-0.00501604849337103",
                "net_sr": "0.0406668022208785",
                "net_stuff": "-0.0407024843604515",
                "net_pass_sr": "0.0357225515077094",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "-0.0618544816804369",
                "net_sr": "-0.0361615964119489",
                "net_stuff": "0.0126089630582353",
                "net_pass_sr": "-0.0225705395478854",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.16380383715862143
    },
    {
        "id": 432,
        "name": "Don Brown",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.000347196694238918
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Maryland",
                "position": "DC"
            },
            "2010": {
                "college": "Maryland",
                "position": "DC"
            },
            "2011": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2012": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2013": {
                "college": "Boston College",
                "position": "DC"
            },
            "2014": {
                "college": "Boston College",
                "position": "DC"
            },
            "2015": {
                "college": "Boston College",
                "position": "DC"
            },
            "2016": {
                "college": "Michigan",
                "position": "DC"
            },
            "2017": {
                "college": "Michigan",
                "position": "DC"
            },
            "2018": {
                "college": "Michigan",
                "position": "DC"
            },
            "2019": {
                "college": "Michigan",
                "position": "DC"
            },
            "2020": {
                "college": "Michigan",
                "position": "DC"
            },
            "2021": {
                "college": "Arizona",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "0.0244425124373408",
                "net_sr": "0.0412612488800148",
                "net_stuff": "0.017049970594498",
                "net_pass_sr": "0.044989624617372",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "-0.0229615426448175",
                "net_sr": "0.0490538764185783",
                "net_stuff": "-0.0483106269907732",
                "net_pass_sr": "0.0437242036429727",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "0.000899629194885668",
                "net_sr": "0.0341255569634617",
                "net_stuff": "-0.0769650993155007",
                "net_pass_sr": "0.0120768867297992",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "0.0514077889277912",
                "net_sr": "0.0245473028872795",
                "net_stuff": "-0.0432390264730095",
                "net_pass_sr": "-0.017524277995672",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "-0.00949465748679923",
                "net_sr": "0.0390824740945449",
                "net_stuff": "-0.0150774328785699",
                "net_pass_sr": "0.0558892874707091",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.044293730428400936
    },
    {
        "id": 433,
        "name": "Phil Snow",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.00043777416730292
        },
        "positions": {
            "2000": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2001": {
                "college": "UCLA",
                "position": "DC"
            },
            "2002": {
                "college": "UCLA",
                "position": "DC"
            },
            "2003": {
                "college": "Washington",
                "position": "DC"
            },
            "2004": {
                "college": "Washington",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2011": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2012": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2013": {
                "college": "Temple",
                "position": "DC"
            },
            "2014": {
                "college": "Temple",
                "position": "DC"
            },
            "2015": {
                "college": "Temple",
                "position": "DC"
            },
            "2016": {
                "college": "Temple",
                "position": "DC"
            },
            "2017": {
                "college": "Baylor",
                "position": "DC"
            },
            "2018": {
                "college": "Baylor",
                "position": "DC"
            },
            "2019": {
                "college": "Baylor",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "-0.0625226618993031",
                "net_sr": "0.0207599548906441",
                "net_stuff": "-0.0277376259379087",
                "net_pass_sr": "0.0294672011049943",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Eastern Michigan",
                "role": "OC",
                "net_ppa": "-0.0263622940432359",
                "net_sr": "-0.0511379361454951",
                "net_stuff": "0.0358786442037358",
                "net_pass_sr": "-0.0398424371699302",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "-0.0229159991361487",
                "net_sr": "0.0140825630304602",
                "net_stuff": "0.0549018502676232",
                "net_pass_sr": "-0.00348475900717382",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.11180095507868769
    },
    {
        "id": 434,
        "name": "Bill Miller",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000198629406983967
        },
        "positions": {
            "2000": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2001": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2002": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2006": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2007": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2008": false,
            "2009": {
                "college": "Kansas",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.0626944951269242",
                "net_sr": "-0.017101943007661",
                "net_stuff": "0.03789081195752",
                "net_pass_sr": "-0.0194662377828486",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "0.0450104701212403",
                "net_sr": "-0.0135005611757187",
                "net_stuff": "0.0202013426660471",
                "net_pass_sr": "0.0171343656543593",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.017684025005683897
    },
    {
        "id": 435,
        "name": "Craig Bray",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000898087022502733
        },
        "positions": {
            "2000": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2001": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2002": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2008": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2009": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2010": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2011": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.0622435536323511",
                "net_sr": "0.0179629590404758",
                "net_stuff": "-0.0146694398509912",
                "net_pass_sr": "-0.0108600017438829",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0622435536323511
    },
    {
        "id": 436,
        "name": "Paul Randolph",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000298862641461917
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Rice",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2011": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2012": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2013": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.00353379265765836",
                "net_sr": "0.0200974353914404",
                "net_stuff": "-0.0295554834094388",
                "net_pass_sr": "-0.00536873210117533",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "0.121849463500025",
                "net_sr": "0.0367310624289753",
                "net_stuff": "0.0181539916695513",
                "net_pass_sr": "0.0206990267458289",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "-0.088007065740939",
                "net_sr": "-0.0389294097317224",
                "net_stuff": "-0.0294316943110509",
                "net_pass_sr": "-0.0270552232577549",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "0.257660643645897",
                "net_sr": "0.072804461471265",
                "net_stuff": "-0.0319547472488619",
                "net_pass_sr": "0.0724318373394111",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.29503683406264136
    },
    {
        "id": 437,
        "name": "Ron West",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000205574620210407
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2013": false,
            "2014": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.267300301514992",
                "net_sr": "0.101669746320343",
                "net_stuff": "-0.0734927220835196",
                "net_pass_sr": "0.0490374969505444",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "-0.0273421420559294",
                "net_sr": "-0.00599464537801531",
                "net_stuff": "-0.0321310460382969",
                "net_pass_sr": "3.05490223060922e-05",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "0.0616236188657761",
                "net_sr": "0.036945738019753",
                "net_stuff": "0.0160905404723405",
                "net_pass_sr": "0.081377760023443",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.30158177832483873
    },
    {
        "id": 438,
        "name": "Chris Ball",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000269459628413413
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Washington State",
                "position": "DC"
            },
            "2009": {
                "college": "Washington State",
                "position": "DC"
            },
            "2010": {
                "college": "Washington State",
                "position": "DC"
            },
            "2011": {
                "college": "Washington State",
                "position": "DC"
            },
            "2012": false,
            "2013": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2014": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2015": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2016": {
                "college": "Memphis",
                "position": "DC"
            },
            "2017": {
                "college": "Memphis",
                "position": "DC"
            },
            "2018": {
                "college": "Memphis",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "-0.101752027612033",
                "net_sr": "-0.00299854039568714",
                "net_stuff": "-0.00190424720185092",
                "net_pass_sr": "-0.0153025192417612",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.103674097664125",
                "net_sr": "-0.0268552459037171",
                "net_stuff": "0.0436470757944234",
                "net_pass_sr": "-0.0112862680670079",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "-0.0390689704627339",
                "net_sr": "-0.0372394648428325",
                "net_stuff": "0.00884942462265267",
                "net_pass_sr": "-0.0285762815918928",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2444950957388919
    },
    {
        "id": 439,
        "name": "Keith Patterson",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000619181074397696
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2007": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2008": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2009": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2010": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2011": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2012": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2013": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2014": false,
            "2015": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2016": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2017": false,
            "2018": {
                "college": "Utah State",
                "position": "DC"
            },
            "2019": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2020": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2021": {
                "college": "Texas Tech",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "-0.195540099974001",
                "net_sr": "-0.0534082176809793",
                "net_stuff": "-0.015639723127926",
                "net_pass_sr": "-0.0580784515721916",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "-0.0108867496079656",
                "net_sr": "-0.0173330691179385",
                "net_stuff": "-0.0200723901396739",
                "net_pass_sr": "-0.0495090027375156",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "-0.00819486381542542",
                "net_sr": "0.0185991327223854",
                "net_stuff": "-0.00291375391135953",
                "net_pass_sr": "0.0111507499979507",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "-0.0379432261702406",
                "net_sr": "-0.0483426884814637",
                "net_stuff": "0.0740082240313698",
                "net_pass_sr": "-0.0362203945757406",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.103520976898584",
                "net_sr": "0.0376823561478704",
                "net_stuff": "-0.0178314159711065",
                "net_pass_sr": "0.00836760232378653",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "-0.111025298726888",
                "net_sr": "-0.0538700501104364",
                "net_stuff": "-0.00614227604100567",
                "net_pass_sr": "-0.101803050869273",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.26006926139593667
    },
    {
        "id": 440,
        "name": "Marvin Lewis",
        "community": 19,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000108474859443535
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Arizona State",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.00485370997386464",
                "net_sr": "0.0063189336233388",
                "net_stuff": "-0.0441246713201967",
                "net_pass_sr": "0.0515822932553425",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.00485370997386464
    },
    {
        "id": 442,
        "name": "Dave Wommack",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000171663264124184
        },
        "positions": {
            "2000": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2001": false,
            "2002": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2003": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2004": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2009": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2010": false,
            "2011": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2012": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2013": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2014": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2015": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2016": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "0.104837833981727",
                "net_sr": "0.0605925718016609",
                "net_stuff": "-0.0498811945409831",
                "net_pass_sr": "0.0487008314984065",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia Tech",
                "role": "OC",
                "net_ppa": "0.000608855915417102",
                "net_sr": "-0.0402579219027278",
                "net_stuff": "0.0359904894576683",
                "net_pass_sr": "-0.0286870336079461",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0351615814075237",
                "net_sr": "-0.0139631011562295",
                "net_stuff": "0.0133650237068518",
                "net_pass_sr": "-0.0314187052138827",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.07028510848962041
    },
    {
        "id": 444,
        "name": "Willy Robinson",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000615065571379246
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2009": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2010": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2011": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "0.27142068724483",
                "net_sr": "0.096146190425586",
                "net_stuff": "-0.0960122066620311",
                "net_pass_sr": "0.133307941140583",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.27142068724483
    },
    {
        "id": 445,
        "name": "Robb Smith",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000301314287310477
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2013": false,
            "2014": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2015": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2016": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2017": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2018": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2021": {
                "college": "Rutgers",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "0.097400014431351",
                "net_sr": "-0.00502184284742241",
                "net_stuff": "-0.00338546665600092",
                "net_pass_sr": "-0.0303686436805914",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.0654585980907921",
                "net_sr": "0.0228561348561689",
                "net_stuff": "-0.0139420122013618",
                "net_pass_sr": "0.0491278850053419",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.169294572312796",
                "net_sr": "0.0749971445200101",
                "net_stuff": "-0.105251065224458",
                "net_pass_sr": "0.0435112451699035",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.3321531848349391
    },
    {
        "id": 446,
        "name": "John Chavis",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00457419752883931
        },
        "positions": {
            "2000": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2001": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2002": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2003": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2004": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2005": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2006": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2007": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2008": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2009": {
                "college": "LSU",
                "position": "DC"
            },
            "2010": {
                "college": "LSU",
                "position": "DC"
            },
            "2011": {
                "college": "LSU",
                "position": "DC"
            },
            "2012": {
                "college": "LSU",
                "position": "DC"
            },
            "2013": {
                "college": "LSU",
                "position": "DC"
            },
            "2014": {
                "college": "LSU",
                "position": "DC"
            },
            "2015": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2016": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2017": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2018": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2019": {
                "college": "Arkansas",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "-0.021287038027818",
                "net_sr": "-0.000293407445547511",
                "net_stuff": "-0.0101645108187366",
                "net_pass_sr": "-0.0110192085571288",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.0598467428403057",
                "net_sr": "-0.0364723182716069",
                "net_stuff": "0.00675908465279448",
                "net_pass_sr": "-0.0322680004507102",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.041194356045883",
                "net_sr": "-0.0521671148587454",
                "net_stuff": "0.0906812170228088",
                "net_pass_sr": "-0.0327077747989276",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "-0.0126504871009601",
                "net_sr": "0.0226526031743356",
                "net_stuff": "-0.0512743102365471",
                "net_pass_sr": "0.0245386525940367",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 20,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1349786240149668
    },
    {
        "id": 448,
        "name": "Jack Curtis",
        "community": 22,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000147002783461608
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2003": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2004": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2005": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2006": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2007": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2008": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Georgia Southern",
                "position": "DC"
            },
            "2015": {
                "college": "Georgia Southern",
                "position": "DC"
            },
            "2016": {
                "college": "Tulane",
                "position": "DC"
            },
            "2017": {
                "college": "Tulane",
                "position": "DC"
            },
            "2018": {
                "college": "Tulane",
                "position": "DC"
            },
            "2019": {
                "college": "Tulane",
                "position": "DC"
            },
            "2020": {
                "college": "Tulane",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "OC",
                "net_ppa": "-0.00838450926834872",
                "net_sr": "0.0286075100910705",
                "net_stuff": "0.0021644904012231",
                "net_pass_sr": "0.0440056409792665",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "-0.0381585663026809",
                "net_sr": "0.0121436208454378",
                "net_stuff": "0.0527454378182115",
                "net_pass_sr": "0.0494274150904391",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.046543075571029616
    },
    {
        "id": 450,
        "name": "Joe Cauthen",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000192443591923225
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2015": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2016": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2017": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2018": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2019": {
                "college": "Houston",
                "position": "DC"
            },
            "2020": {
                "college": "Houston",
                "position": "DC"
            },
            "2021": {
                "college": "Buffalo",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "-0.073555092274212",
                "net_sr": "0.012931960687087",
                "net_stuff": "-0.0142805039793434",
                "net_pass_sr": "0.0272129009482759",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "-0.190473430877233",
                "net_sr": "-0.0443388765250424",
                "net_stuff": "0.00454011878434235",
                "net_pass_sr": "-0.0669690468033174",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.116795564623063",
                "net_sr": "-0.00584810129471697",
                "net_stuff": "-0.0378912761922837",
                "net_pass_sr": "-0.0271103390520467",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.380824087774508
    },
    {
        "id": 451,
        "name": "David Duggan",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000182669988081781
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2012": false,
            "2013": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2014": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2015": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Arkansas State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "-0.106655647539634",
                "net_sr": "-0.0677306088450204",
                "net_stuff": "0.0732932174825398",
                "net_pass_sr": "-0.0879610465734696",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "-0.0643049338033336",
                "net_sr": "-0.0247029135875037",
                "net_stuff": "0.0153039179176108",
                "net_pass_sr": "-0.0246633941094719",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1709605813429676
    },
    {
        "id": 452,
        "name": "Dennis Therrell",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000144554908104819
        },
        "positions": {
            "2000": {
                "college": "Army",
                "position": "DC"
            },
            "2001": {
                "college": "Army",
                "position": "DC"
            },
            "2002": {
                "college": "Army",
                "position": "DC"
            },
            "2003": {
                "college": "Army",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "UNLV",
                "position": "DC"
            },
            "2009": {
                "college": "UNLV",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "-0.0834534667272481",
                "net_sr": "-0.0291300043329549",
                "net_stuff": "0.0369660169809782",
                "net_pass_sr": "-0.0208140716650122",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0834534667272481
    },
    {
        "id": 454,
        "name": "Chris Smeland",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000550225538169828
        },
        "positions": {
            "2000": {
                "college": "Louisville",
                "position": "DC"
            },
            "2001": {
                "college": "Louisville",
                "position": "DC"
            },
            "2002": {
                "college": "Louisville",
                "position": "DC"
            },
            "2003": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2004": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2005": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2006": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Army",
                "position": "DC"
            },
            "2010": {
                "college": "Army",
                "position": "DC"
            },
            "2011": {
                "college": "Army",
                "position": "DC"
            },
            "2012": {
                "college": "Army",
                "position": "DC"
            },
            "2013": {
                "college": "Army",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "0.00244389452360116",
                "net_sr": "0.0184850061712737",
                "net_stuff": "-0.0190139675239086",
                "net_pass_sr": "0.0322483748771678",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00244389452360116
    },
    {
        "id": 455,
        "name": "Payam Saadat",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000364863350445853
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Army",
                "position": "DC"
            },
            "2010": {
                "college": "Army",
                "position": "DC"
            },
            "2011": {
                "college": "Army",
                "position": "DC"
            },
            "2012": {
                "college": "Army",
                "position": "DC"
            },
            "2013": {
                "college": "Army",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "0.213777378534871",
                "net_sr": "0.0341360300094767",
                "net_stuff": "-0.0242349059375982",
                "net_pass_sr": "0.0108662292058292",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.213777378534871
    },
    {
        "id": 456,
        "name": "Jay Bateman",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000496396346751793
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Ball State",
                "position": "DC"
            },
            "2012": {
                "college": "Ball State",
                "position": "DC"
            },
            "2013": {
                "college": "Ball State",
                "position": "DC"
            },
            "2014": {
                "college": "Army",
                "position": "DC"
            },
            "2015": {
                "college": "Army",
                "position": "DC"
            },
            "2016": {
                "college": "Army",
                "position": "DC"
            },
            "2017": {
                "college": "Army",
                "position": "DC"
            },
            "2018": {
                "college": "Army",
                "position": "DC"
            },
            "2019": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2020": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2021": {
                "college": "North Carolina",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "-0.0777861514155961",
                "net_sr": "0.00190838383746017",
                "net_stuff": "-0.000434148520354227",
                "net_pass_sr": "-0.012889136775283",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "-0.0788054225310763",
                "net_sr": "-0.0115260784118773",
                "net_stuff": "0.0623637028364359",
                "net_pass_sr": "-0.0213704914484873",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "0.00883087278150435",
                "net_sr": "0.0167732117536826",
                "net_stuff": "-0.00119364048561435",
                "net_pass_sr": "-0.00425222269047698",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.14776070116516804
    },
    {
        "id": 457,
        "name": "John Loose",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000593912614481504
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Army",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "-0.0374145723757356",
                "net_sr": "0.0045165419852678",
                "net_stuff": "0.0172540138723133",
                "net_pass_sr": "0.0177882181488673",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0374145723757356
    },
    {
        "id": 458,
        "name": "Greg Gasparato",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000593912614481758
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Army",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "0.142060196449331",
                "net_sr": "0.0632833836701564",
                "net_stuff": "-0.0822585440389888",
                "net_pass_sr": "0.00686419667466581",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.142060196449331
    },
    {
        "id": 459,
        "name": "Shiel Wood",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000593912614481801
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Army",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "0.0346494434666676",
                "net_sr": "0.00147910894286102",
                "net_stuff": "-0.0241350112331433",
                "net_pass_sr": "0.00568641434096545",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0346494434666676
    },
    {
        "id": 460,
        "name": "John Lovett",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00023768282880357
        },
        "positions": {
            "2000": {
                "college": "Auburn",
                "position": "DC"
            },
            "2001": {
                "college": "Auburn",
                "position": "DC"
            },
            "2002": {
                "college": "Clemson",
                "position": "DC"
            },
            "2003": {
                "college": "Clemson",
                "position": "DC"
            },
            "2004": {
                "college": "Clemson",
                "position": "DC"
            },
            "2005": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2006": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Miami",
                "position": "DC"
            },
            "2010": {
                "college": "Miami",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "-0.0170769837311839",
                "net_sr": "-0.00342244958690074",
                "net_stuff": "0.0243994130291746",
                "net_pass_sr": "-0.00749195459538521",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0170769837311839
    },
    {
        "id": 461,
        "name": "David Gibbs",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.000208664305490974
        },
        "positions": {
            "2000": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Auburn",
                "position": "DC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Houston",
                "position": "DC"
            },
            "2014": {
                "college": "Houston",
                "position": "DC"
            },
            "2015": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2016": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2017": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2018": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": {
                "college": "UCF",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0114858796933449",
                "net_sr": "0.00326827131139534",
                "net_stuff": "-0.00634681396581813",
                "net_pass_sr": "-0.028863563331229",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "-0.141148224878286",
                "net_sr": "-0.0379351373400368",
                "net_stuff": "0.00891308913934546",
                "net_pass_sr": "-0.0654104251300981",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.0217828372011322",
                "net_sr": "0.0122761875103409",
                "net_stuff": "0.0422294190033608",
                "net_pass_sr": "0.0255880949829217",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1744169417727631
    },
    {
        "id": 462,
        "name": "Brian VanGorder",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.000305181961858926
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Georgia",
                "position": "DC"
            },
            "2002": {
                "college": "Georgia",
                "position": "DC"
            },
            "2003": {
                "college": "Georgia",
                "position": "DC"
            },
            "2004": {
                "college": "Georgia",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Auburn",
                "position": "DC"
            },
            "2013": false,
            "2014": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2015": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2016": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2017": false,
            "2018": {
                "college": "Louisville",
                "position": "DC"
            },
            "2019": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2020": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.0694655771439343",
                "net_sr": "-0.0208627410256096",
                "net_stuff": "0.0386503717225625",
                "net_pass_sr": "-0.0352084541258726",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.0348909000481399",
                "net_sr": "0.00922097842723724",
                "net_stuff": "-0.0236083736128534",
                "net_pass_sr": "-0.0406410471784761",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "-0.250957613726242",
                "net_sr": "-0.139117513315394",
                "net_stuff": "0.0778622696591749",
                "net_pass_sr": "-0.115808145310061",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "-0.123997583213137",
                "net_sr": "-0.0151703307760097",
                "net_stuff": "-0.0220257079189296",
                "net_pass_sr": "-0.0109832715074056",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.47931167413145315
    },
    {
        "id": 463,
        "name": "Charlie Harbison",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00182731440212199
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2009": {
                "college": "Clemson",
                "position": "DC"
            },
            "2010": {
                "college": "Clemson",
                "position": "DC"
            },
            "2011": {
                "college": "Clemson",
                "position": "DC"
            },
            "2012": {
                "college": "Clemson",
                "position": "DC"
            },
            "2013": false,
            "2014": {
                "college": "Auburn",
                "position": "DC"
            },
            "2015": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2016": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2017": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.0291738066003179",
                "net_sr": "0.029057142180258",
                "net_stuff": "-0.0261659195520918",
                "net_pass_sr": "0.0279900423144764",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Clemson",
                "role": "OC",
                "net_ppa": "-0.0191473542186826",
                "net_sr": "0.00741697651345635",
                "net_stuff": "0.00111762783448108",
                "net_pass_sr": "0.0149505370564397",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.0829348035153092",
                "net_sr": "-0.0112523690220226",
                "net_stuff": "0.0338384244941614",
                "net_pass_sr": "0.000334902145360028",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.0017078229380111",
                "net_sr": "0.000811318572035979",
                "net_stuff": "0.01773919302634",
                "net_pass_sr": "0.0348582797942369",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.12954814139629858
    },
    {
        "id": 466,
        "name": "Doug Graber",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000110234639333412
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Ball State",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "0.0576690077914743",
                "net_sr": "-0.0178058864350653",
                "net_stuff": "-0.0186872815253687",
                "net_pass_sr": "-0.0340906193864886",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0576690077914743
    },
    {
        "id": 467,
        "name": "Jay Hood",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000150110419149144
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2008": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2009": false,
            "2010": {
                "college": "Ball State",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "0.0234699965317514",
                "net_sr": "0.00944448173358592",
                "net_stuff": "0.0402734551559432",
                "net_pass_sr": "0.0490594249459497",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "-0.0241235181030694",
                "net_sr": "-0.0397364745107758",
                "net_stuff": "0.00512578906900801",
                "net_pass_sr": "-0.0327270476547985",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0006535215713180005
    },
    {
        "id": 468,
        "name": "Kevin Kelly",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000955656011327548
        },
        "positions": {
            "2000": {
                "college": "Marshall",
                "position": "DC"
            },
            "2001": {
                "college": "Marshall",
                "position": "DC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Ball State",
                "position": "DC"
            },
            "2015": {
                "college": "Ball State",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "-0.129118853383462",
                "net_sr": "-0.0282094774244151",
                "net_stuff": "-0.0072811902520992",
                "net_pass_sr": "-0.0429440142122544",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.129118853383462
    },
    {
        "id": 469,
        "name": "Tim Daoust",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000213352885252542
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Ball State",
                "position": "DC"
            },
            "2017": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2018": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "0.0205937508198605",
                "net_sr": "-0.0454627376551844",
                "net_stuff": "0.0717579439670555",
                "net_pass_sr": "-0.0493900703702623",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "-0.110300215110804",
                "net_sr": "-0.00766536036799204",
                "net_stuff": "-0.0239654175239794",
                "net_pass_sr": "0.0238124714711626",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0897064642909435
    },
    {
        "id": 470,
        "name": "Tyler Stockton",
        "community": 10,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000191482547051536
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Ball State",
                "position": "DC"
            },
            "2020": {
                "college": "Ball State",
                "position": "DC"
            },
            "2021": {
                "college": "Ball State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "-0.0997462367540702",
                "net_sr": "-0.00349246021445904",
                "net_stuff": "-0.0446318343231974",
                "net_pass_sr": "-0.028747302950467",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0997462367540702
    },
    {
        "id": 472,
        "name": "Bob Trott",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000137060790963665
        },
        "positions": {
            "2000": {
                "college": "Duke",
                "position": "DC"
            },
            "2001": {
                "college": "Duke",
                "position": "DC"
            },
            "2002": {
                "college": "Baylor",
                "position": "DC"
            },
            "2003": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2004": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "0.0122519008242861",
                "net_sr": "0.000946339041870359",
                "net_stuff": "-0.0116486141325814",
                "net_pass_sr": "-0.00798106732868553",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0122519008242861
    },
    {
        "id": 474,
        "name": "Larry Hoefer",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00002844819810218
        },
        "positions": {
            "2000": {
                "college": "UTEP",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Baylor",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "0.0149409288857223",
                "net_sr": "-0.0457992697544514",
                "net_stuff": "-0.00740244684407451",
                "net_pass_sr": "-0.0668581351425074",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0149409288857223
    },
    {
        "id": 475,
        "name": "Brian Norwood",
        "community": 19,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000241861709395235
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Baylor",
                "position": "DC"
            },
            "2009": {
                "college": "Baylor",
                "position": "DC"
            },
            "2010": {
                "college": "Baylor",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2016": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2017": false,
            "2018": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2019": {
                "college": "Navy",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "0.0349489521695338",
                "net_sr": "0.00445339136479272",
                "net_stuff": "-0.0172113112625692",
                "net_pass_sr": "-0.0392068326443415",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "0.0103589972548158",
                "net_sr": "-0.025597608094778",
                "net_stuff": "0.019531063575393",
                "net_pass_sr": "-0.00472999081701497",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Navy",
                "role": "OC",
                "net_ppa": "0.178365871193576",
                "net_sr": "0.0770160947836556",
                "net_stuff": "-0.0291553618396521",
                "net_pass_sr": "0.0934640122485426",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "-0.0707725660380431",
                "net_sr": "-0.0257644963312292",
                "net_stuff": "0.0396436162276608",
                "net_pass_sr": "-0.00818308702340736",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.1529012545798825
    },
    {
        "id": 476,
        "name": "Ron Roberts",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000199113941032228
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2019": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2020": {
                "college": "Baylor",
                "position": "DC"
            },
            "2021": {
                "college": "Baylor",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "0.0274849711133643",
                "net_sr": "0.0288754422381273",
                "net_stuff": "-0.0764028272419887",
                "net_pass_sr": "-0.0158707479704931",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.214577218223337",
                "net_sr": "-0.0977622820674816",
                "net_stuff": "0.0076594803357613",
                "net_pass_sr": "-0.144980453445379",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1870922471099727
    },
    {
        "id": 477,
        "name": "Bob Gregory",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00691194021757993
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Boise State",
                "position": "DC"
            },
            "2002": {
                "college": "California",
                "position": "DC"
            },
            "2003": {
                "college": "California",
                "position": "DC"
            },
            "2004": {
                "college": "California",
                "position": "DC"
            },
            "2005": {
                "college": "California",
                "position": "DC"
            },
            "2006": {
                "college": "California",
                "position": "DC"
            },
            "2007": {
                "college": "California",
                "position": "DC"
            },
            "2008": {
                "college": "California",
                "position": "DC"
            },
            "2009": {
                "college": "California",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Washington",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "-0.0504170207064906",
                "net_sr": "-0.00306890009869126",
                "net_stuff": "0.0470408231514408",
                "net_pass_sr": "0.0564073242907508",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0504170207064906
    },
    {
        "id": 478,
        "name": "Ron Collins",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.25773909595189
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Boise State",
                "position": "DC"
            },
            "2003": {
                "college": "Boise State",
                "position": "DC"
            },
            "2004": {
                "college": "Boise State",
                "position": "DC"
            },
            "2005": {
                "college": "Boise State",
                "position": "DC"
            },
            "2006": {
                "college": "Colorado",
                "position": "DC"
            },
            "2007": {
                "college": "Colorado",
                "position": "DC"
            },
            "2008": {
                "college": "Colorado",
                "position": "DC"
            },
            "2009": {
                "college": "Colorado",
                "position": "DC"
            },
            "2010": {
                "college": "Colorado",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Ohio",
                "position": "DC"
            },
            "2018": {
                "college": "Ohio",
                "position": "DC"
            },
            "2019": {
                "college": "Ohio",
                "position": "DC"
            },
            "2020": {
                "college": "Ohio",
                "position": "DC"
            },
            "2021": {
                "college": "Ohio",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "0.138946440623677",
                "net_sr": "0.0642120172256261",
                "net_stuff": "-0.0689717259350115",
                "net_pass_sr": "0.0444270666733355",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ohio",
                "role": "OC",
                "net_ppa": "0.117476872349544",
                "net_sr": "0.0709896406237202",
                "net_stuff": "-0.0720778510270347",
                "net_pass_sr": "0.0366748643222248",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.256423312973221
    },
    {
        "id": 479,
        "name": "Pete Kwiatkowski",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0207633391845358
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Boise State",
                "position": "DC"
            },
            "2011": {
                "college": "Boise State",
                "position": "DC"
            },
            "2012": {
                "college": "Boise State",
                "position": "DC"
            },
            "2013": {
                "college": "Boise State",
                "position": "DC"
            },
            "2014": {
                "college": "Washington",
                "position": "DC"
            },
            "2015": {
                "college": "Washington",
                "position": "DC"
            },
            "2016": {
                "college": "Washington",
                "position": "DC"
            },
            "2017": {
                "college": "Washington",
                "position": "DC"
            },
            "2018": {
                "college": "Washington",
                "position": "DC"
            },
            "2019": {
                "college": "Washington",
                "position": "DC"
            },
            "2020": {
                "college": "Washington",
                "position": "DC"
            },
            "2021": {
                "college": "Texas",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "0.327181514407106",
                "net_sr": "0.117257860930594",
                "net_stuff": "-0.0350592286058873",
                "net_pass_sr": "0.126049241513464",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "-0.0913701812832803",
                "net_sr": "-0.0284220837108819",
                "net_stuff": "-0.0480587524653002",
                "net_pass_sr": "-0.0393998184711186",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "-0.0626762834669808",
                "net_sr": "-0.0594482824261257",
                "net_stuff": "0.0414133286396804",
                "net_pass_sr": "-0.0416976384049555",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.17313504965684495
    },
    {
        "id": 480,
        "name": "Jeff Schmedding",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00122240892820423
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Boise State",
                "position": "DC"
            },
            "2020": {
                "college": "Boise State",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "-0.0323969532250552",
                "net_sr": "0.0301967073220825",
                "net_stuff": "-0.0289097441518331",
                "net_pass_sr": "0.0268715923176054",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0323969532250552
    },
    {
        "id": 481,
        "name": "Spencer Danielson",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00130987555429175
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Boise State",
                "position": "DC"
            },
            "2020": {
                "college": "Boise State",
                "position": "DC"
            },
            "2021": {
                "college": "Boise State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "0.184524290234376",
                "net_sr": "0.0798019880019415",
                "net_stuff": "-0.0231859673218232",
                "net_pass_sr": "0.103339965989928",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.184524290234376
    },
    {
        "id": 482,
        "name": "Kane Ioane",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000874666260875264
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Boise State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "0.0347226192400069",
                "net_sr": "0.0137631236456709",
                "net_stuff": "0.0206501222390897",
                "net_pass_sr": "0.0067823607423726",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0347226192400069
    },
    {
        "id": 483,
        "name": "Bill McGovern",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000660058300711575
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Boston College",
                "position": "DC"
            },
            "2010": {
                "college": "Boston College",
                "position": "DC"
            },
            "2011": {
                "college": "Boston College",
                "position": "DC"
            },
            "2012": {
                "college": "Boston College",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "-0.0770545658427784",
                "net_sr": "-0.0573226443052764",
                "net_stuff": "0.0695208037889123",
                "net_pass_sr": "-0.0611161290760446",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0770545658427784
    },
    {
        "id": 484,
        "name": "Jim Reid",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000230620855970598
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Virginia",
                "position": "DC"
            },
            "2012": {
                "college": "Virginia",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Boston College",
                "position": "DC"
            },
            "2017": {
                "college": "Boston College",
                "position": "DC"
            },
            "2018": {
                "college": "Boston College",
                "position": "DC"
            },
            "2019": {
                "college": "Boston College",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "-0.0608831533462104",
                "net_sr": "-0.0178651139608261",
                "net_stuff": "0.0455502904011009",
                "net_pass_sr": "0.00451863052122808",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "0.0238244293253304",
                "net_sr": "0.0286356263984912",
                "net_stuff": "-0.0815900999443129",
                "net_pass_sr": "0.0157601624626508",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.03705872402088001
    },
    {
        "id": 485,
        "name": "Anthony Campanile",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000550678844148025
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Boston College",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "-0.0428752531507846",
                "net_sr": "-0.0530560317544678",
                "net_stuff": "0.0668233397539978",
                "net_pass_sr": "-0.0399357834759647",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0428752531507846
    },
    {
        "id": 486,
        "name": "Tem Lukabu",
        "community": 19,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000148908274065561
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Boston College",
                "position": "DC"
            },
            "2021": {
                "college": "Boston College",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "-0.154017416223847",
                "net_sr": "-0.0252558891272419",
                "net_stuff": "0.0286045138280172",
                "net_pass_sr": "-0.0299746236676811",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.154017416223847
    },
    {
        "id": 487,
        "name": "Mike Ward",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000201768683045455
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2008": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.00609907656040391",
                "net_sr": "-0.0483541867454514",
                "net_stuff": "0.0684848420041402",
                "net_pass_sr": "-0.0545420968985365",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00609907656040391
    },
    {
        "id": 488,
        "name": "Mike Elko",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00177175389515385
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2010": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2011": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2012": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2013": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2014": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2015": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2016": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2017": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2018": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2019": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2020": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2021": {
                "college": "Texas A&M",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.0211799835271665",
                "net_sr": "0.0244565355430093",
                "net_stuff": "-0.00413868208151741",
                "net_pass_sr": "0.0147235391053705",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.0868880725247191",
                "net_sr": "0.0468805091240242",
                "net_stuff": "0.00267451032380273",
                "net_pass_sr": "0.0313467855390419",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "0.0175532424247195",
                "net_sr": "0.0282118586719849",
                "net_stuff": "-0.00303150158086046",
                "net_pass_sr": "0.0110690485551551",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wake Forest",
                "role": "OC",
                "net_ppa": "-0.0878075540938433",
                "net_sr": "0.00487703361998343",
                "net_stuff": "0.0335309825098831",
                "net_pass_sr": "0.00181920884002601",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.004546222671571204
    },
    {
        "id": 489,
        "name": "Shannon Morrison",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000135734378207611
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "0.19686047609923",
                "net_sr": "-0.0261998415747786",
                "net_stuff": "-0.0170555238635726",
                "net_pass_sr": "0.0197400937139932",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.19686047609923
    },
    {
        "id": 490,
        "name": "Kim McCloud",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000104488466682799
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.164572712321266",
                "net_sr": "-0.062368966744199",
                "net_stuff": "-0.0105379437182202",
                "net_pass_sr": "-0.0675068205327565",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.164572712321266
    },
    {
        "id": 491,
        "name": "Brian Ward",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000615730285928601
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2016": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2017": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2018": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2019": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2020": {
                "college": "Nevada",
                "position": "DC"
            },
            "2021": {
                "college": "Nevada",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.0696778702413848",
                "net_sr": "-0.0196546159799104",
                "net_stuff": "0.0252761962861318",
                "net_pass_sr": "-0.00450314603172941",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "0.0231631060664509",
                "net_sr": "-0.00258166084619998",
                "net_stuff": "0.0657558273764922",
                "net_pass_sr": "0.0283322547670064",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "-0.105050972914425",
                "net_sr": "0.00101013852005316",
                "net_stuff": "0.0206161802819802",
                "net_pass_sr": "0.0155866465879181",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1515657370893589
    },
    {
        "id": 492,
        "name": "Marcus White",
        "community": 5,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000204286808546964
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.15544857484914",
                "net_sr": "-0.0178780117671312",
                "net_stuff": "0.0485693130106466",
                "net_pass_sr": "-0.0320450120908788",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.15544857484914
    },
    {
        "id": 493,
        "name": "Perry Eliano",
        "community": 5,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000408573617094026
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2017": {
                "college": "Bowling Green",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "0.0777470775425352",
                "net_sr": "0.0048954092491999",
                "net_stuff": "-0.0633618606899767",
                "net_pass_sr": "0.00642045714191136",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0777470775425352
    },
    {
        "id": 494,
        "name": "Eric Lewis",
        "community": 16,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000195660440211943
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Bowling Green",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "0.148530147963832",
                "net_sr": "0.0462501744718771",
                "net_stuff": "0.0395411505595136",
                "net_pass_sr": "0.0588182913254555",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Eastern Michigan",
                "role": "OC",
                "net_ppa": "-0.0464783112947166",
                "net_sr": "-0.0209141103746469",
                "net_stuff": "0.0430544292874702",
                "net_pass_sr": "0.00297417267691658",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.10205183666911538
    },
    {
        "id": 498,
        "name": "Jimmy Williams",
        "community": 30,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000745816626764301
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2007": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2008": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "-0.0595175318325864",
                "net_sr": "-0.0702577943455051",
                "net_stuff": "0.0134471359627883",
                "net_pass_sr": "-0.0794341783147753",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0595175318325864
    },
    {
        "id": 499,
        "name": "Fred Reed",
        "community": 30,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000248605542254723
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "0.0627339421308137",
                "net_sr": "0.0140393726232849",
                "net_stuff": "0.00244678746538174",
                "net_pass_sr": "0.0330020737081256",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0627339421308137
    },
    {
        "id": 500,
        "name": "William Inge",
        "community": 14,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00212194933375175
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2011": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2012": false,
            "2013": {
                "college": "Indiana",
                "position": "DC"
            },
            "2014": {
                "college": "Indiana",
                "position": "DC"
            },
            "2015": {
                "college": "Indiana",
                "position": "DC"
            },
            "2016": false,
            "2017": {
                "college": "Indiana",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2021": {
                "college": "Fresno State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "0.0609150821194102",
                "net_sr": "-0.0986140133859364",
                "net_stuff": "-0.0108676224701295",
                "net_pass_sr": "-0.120303809596688",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "0.0549982841231019",
                "net_sr": "0.010174354290012",
                "net_stuff": "0.00711411907851378",
                "net_pass_sr": "0.0038344098608129",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "-0.0745992719451897",
                "net_sr": "-0.0509919971322733",
                "net_stuff": "0.0183066882659569",
                "net_pass_sr": "-0.0467537044484085",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0413140942973224
    },
    {
        "id": 501,
        "name": "Lou Tepper",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000338818596203215
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2013": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2014": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "-0.0804511554795424",
                "net_sr": "0.0252253269863308",
                "net_stuff": "0.00392618039844075",
                "net_pass_sr": "0.00791617770589975",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0804511554795424
    },
    {
        "id": 502,
        "name": "Brian Borland",
        "community": 33,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 3.67086710326162e-17
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2016": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2017": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2018": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2019": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2020": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2021": {
                "college": "Kansas",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "-0.0540452421425537",
                "net_sr": "-0.034457732921372",
                "net_stuff": "0.0346360209312674",
                "net_pass_sr": "0.00612744536992355",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.180439863394433",
                "net_sr": "-0.0725891990920458",
                "net_stuff": "0.0801906141940154",
                "net_pass_sr": "-0.0630968248040565",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2344851055369867
    },
    {
        "id": 503,
        "name": "Chris Simpson",
        "community": 33,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Buffalo",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "0.0203980657705443",
                "net_sr": "0.0108633330019288",
                "net_stuff": "0.0432442005688664",
                "net_pass_sr": "0.0159240105785562",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0203980657705443
    },
    {
        "id": 505,
        "name": "Jaime Hill",
        "community": 5,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000104905437326358
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "BYU",
                "position": "DC"
            },
            "2009": {
                "college": "BYU",
                "position": "DC"
            },
            "2010": {
                "college": "BYU",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "BYU",
                "role": "OC",
                "net_ppa": "0.00378236849083055",
                "net_sr": "0.00622266901742657",
                "net_stuff": "-0.00747724476666509",
                "net_pass_sr": "0.00097414784675981",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.00378236849083055
    },
    {
        "id": 506,
        "name": "Nick Howell",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000314716311979106
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "BYU",
                "position": "DC"
            },
            "2014": {
                "college": "BYU",
                "position": "DC"
            },
            "2015": {
                "college": "BYU",
                "position": "DC"
            },
            "2016": {
                "college": "Virginia",
                "position": "DC"
            },
            "2017": {
                "college": "Virginia",
                "position": "DC"
            },
            "2018": {
                "college": "Virginia",
                "position": "DC"
            },
            "2019": {
                "college": "Virginia",
                "position": "DC"
            },
            "2020": {
                "college": "Virginia",
                "position": "DC"
            },
            "2021": {
                "college": "Virginia",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "BYU",
                "role": "OC",
                "net_ppa": "0.207599166031457",
                "net_sr": "0.0633156033892925",
                "net_stuff": "-0.0676956700023061",
                "net_pass_sr": "0.0655374252313897",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "0.0318733170887497",
                "net_sr": "-0.00764619270684513",
                "net_stuff": "-0.0382807874327613",
                "net_pass_sr": "0.00240990695825988",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2394724831202067
    },
    {
        "id": 507,
        "name": "Ilaisa Tuiaki",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000262299654404565
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "BYU",
                "position": "DC"
            },
            "2017": {
                "college": "BYU",
                "position": "DC"
            },
            "2018": {
                "college": "BYU",
                "position": "DC"
            },
            "2019": {
                "college": "BYU",
                "position": "DC"
            },
            "2020": {
                "college": "BYU",
                "position": "DC"
            },
            "2021": {
                "college": "BYU",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "BYU",
                "role": "OC",
                "net_ppa": "-0.0675352455722385",
                "net_sr": "-0.0422033555578984",
                "net_stuff": "0.0501261300952855",
                "net_pass_sr": "-0.0482571792822013",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0675352455722385
    },
    {
        "id": 509,
        "name": "Clancy Pendergast",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000638426760156826
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "California",
                "position": "DC"
            },
            "2011": {
                "college": "California",
                "position": "DC"
            },
            "2012": {
                "college": "California",
                "position": "DC"
            },
            "2013": {
                "college": "USC",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "USC",
                "position": "DC"
            },
            "2017": {
                "college": "USC",
                "position": "DC"
            },
            "2018": {
                "college": "USC",
                "position": "DC"
            },
            "2019": {
                "college": "USC",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "-0.0320626737005928",
                "net_sr": "-0.00826458449702805",
                "net_stuff": "0.00768888513340693",
                "net_pass_sr": "0.0288960799738826",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "-0.0494266758835014",
                "net_sr": "0.0243564463687879",
                "net_stuff": "0.00241582429079024",
                "net_pass_sr": "0.0209076789948466",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0814893495840942
    },
    {
        "id": 510,
        "name": "Andy Buh",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 6,
            "eigen": 0.000314462611454445
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Stanford",
                "position": "DC"
            },
            "2009": {
                "college": "Stanford",
                "position": "DC"
            },
            "2010": {
                "college": "Nevada",
                "position": "DC"
            },
            "2011": {
                "college": "Nevada",
                "position": "DC"
            },
            "2012": false,
            "2013": {
                "college": "California",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Maryland",
                "position": "DC"
            },
            "2017": {
                "college": "Maryland",
                "position": "DC"
            },
            "2018": {
                "college": "Maryland",
                "position": "DC"
            },
            "2019": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "-0.0893100154902405",
                "net_sr": "-0.0382653016039239",
                "net_stuff": "-0.0295055860409525",
                "net_pass_sr": "-0.09611693056549",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.0581633219133806",
                "net_sr": "-0.0346799194173277",
                "net_stuff": "0.0283899294073766",
                "net_pass_sr": "-0.00302615025887509",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "0.0277915108065046",
                "net_sr": "0.00195143293572886",
                "net_stuff": "0.0569001509091765",
                "net_pass_sr": "0.0361716059473834",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "-0.0799208187206893",
                "net_sr": "-0.0287462564459007",
                "net_stuff": "0.0140544385427732",
                "net_pass_sr": "-0.0459061079847793",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "0.0147041797901316",
                "net_sr": "-0.0118800459457389",
                "net_stuff": "-0.0165484666935458",
                "net_pass_sr": "-0.0289671958337626",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1848984655276742
    },
    {
        "id": 511,
        "name": "Art Kaufman",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00058610391986537
        },
        "positions": {
            "2000": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2012": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2013": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2014": {
                "college": "California",
                "position": "DC"
            },
            "2015": {
                "college": "California",
                "position": "DC"
            },
            "2016": {
                "college": "California",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "-0.203155083141963",
                "net_sr": "-0.0743227689635397",
                "net_stuff": "0.0817593571983612",
                "net_pass_sr": "-0.063082825711204",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.0286145635617766",
                "net_sr": "-0.00203994045976003",
                "net_stuff": "0.0219699333312698",
                "net_pass_sr": "-0.00366303922609895",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "-0.0264752852790894",
                "net_sr": "-0.0116423880836234",
                "net_stuff": "-0.00123432905391982",
                "net_pass_sr": "-0.0341441089687934",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "0.0402132561566963",
                "net_sr": "0.0232931781548865",
                "net_stuff": "-0.0327201379450932",
                "net_pass_sr": "0.0397388109186648",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2180316758261327
    },
    {
        "id": 512,
        "name": "Peter Sirmon",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000978236039068414
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2017": {
                "college": "Louisville",
                "position": "DC"
            },
            "2018": false,
            "2019": {
                "college": "California",
                "position": "DC"
            },
            "2020": {
                "college": "California",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "-0.0143198343514525",
                "net_sr": "-0.000422939686490764",
                "net_stuff": "0.014588800062647",
                "net_pass_sr": "0.0275509563793981",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.181179519407355",
                "net_sr": "0.074039988535086",
                "net_stuff": "-0.0240712324506168",
                "net_pass_sr": "0.081792824721484",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.128427486595704",
                "net_sr": "0.0395980979773883",
                "net_stuff": "-0.0211144959957905",
                "net_pass_sr": "0.0152301064544704",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.29528717165160645
    },
    {
        "id": 515,
        "name": "John Jancek",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000239591464276653
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Georgia",
                "position": "DC"
            },
            "2010": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2011": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2012": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2013": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2014": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2015": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2019": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.00454206356231779",
                "net_sr": "0.00598918807544679",
                "net_stuff": "-0.00314727610946675",
                "net_pass_sr": "-0.00516511100695527",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "-0.0206911549274791",
                "net_sr": "-0.0242035828956239",
                "net_stuff": "0.031149136375904",
                "net_pass_sr": "-0.0127837475663563",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "-0.0438104751021577",
                "net_sr": "-0.00719859791061167",
                "net_stuff": "-0.0233481580654239",
                "net_pass_sr": "0.00387486240261381",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.103636012620896",
                "net_sr": "0.00330576314289127",
                "net_stuff": "0.0323887823061885",
                "net_pass_sr": "0.0260991567973812",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1726797062128506
    },
    {
        "id": 517,
        "name": "Tim Banks",
        "community": 24,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.000652024103725041
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2008": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2009": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2010": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2011": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2012": {
                "college": "Illinois",
                "position": "DC"
            },
            "2013": {
                "college": "Illinois",
                "position": "DC"
            },
            "2014": {
                "college": "Illinois",
                "position": "DC"
            },
            "2015": {
                "college": "Illinois",
                "position": "DC"
            },
            "2016": {
                "college": "Penn State",
                "position": "DC"
            },
            "2017": {
                "college": "Penn State",
                "position": "DC"
            },
            "2018": {
                "college": "Penn State",
                "position": "DC"
            },
            "2019": {
                "college": "Penn State",
                "position": "DC"
            },
            "2020": {
                "college": "Penn State",
                "position": "DC"
            },
            "2021": {
                "college": "Tennessee",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "0.0243010855424016",
                "net_sr": "-0.0184870799679183",
                "net_stuff": "0.00180492120907794",
                "net_pass_sr": "0.0189163973697709",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "0.0762457817895974",
                "net_sr": "0.0331181172542594",
                "net_stuff": "-0.0316923886308735",
                "net_pass_sr": "-0.00209285737457399",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "0.0607477301586657",
                "net_sr": "-0.0172790597892827",
                "net_stuff": "0.00329245041702911",
                "net_pass_sr": "-0.0277536702741386",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "0.157616113425834",
                "net_sr": "0.0325588240610432",
                "net_stuff": "-0.0785120046801428",
                "net_pass_sr": "0.0290576522772268",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.0547241473209614",
                "net_sr": "-0.00753412952899624",
                "net_stuff": "0.0191059638882422",
                "net_pass_sr": "0.00560147656502014",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.26418656359553727
    },
    {
        "id": 518,
        "name": "Joe Tumpkin",
        "community": 11,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000664817053419642
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2011": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2012": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2013": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2014": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "-0.0317792942467843",
                "net_sr": "-0.0093759116390062",
                "net_stuff": "-0.00122291932984303",
                "net_pass_sr": "0.00762332432830676",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0317792942467843
    },
    {
        "id": 519,
        "name": "Greg Colby",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.0000740473287034522
        },
        "positions": {
            "2000": {
                "college": "Kent State",
                "position": "DC"
            },
            "2001": {
                "college": "Kent State",
                "position": "DC"
            },
            "2002": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2003": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2004": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2005": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2006": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2007": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2016": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2017": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2018": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "0.00594724201010642",
                "net_sr": "0.0242923500243561",
                "net_stuff": "0.00043675606804644",
                "net_pass_sr": "0.0492131526127315",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00594724201010642
    },
    {
        "id": 520,
        "name": "Robb Akey",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000154394117182468
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Washington State",
                "position": "DC"
            },
            "2004": {
                "college": "Washington State",
                "position": "DC"
            },
            "2005": {
                "college": "Washington State",
                "position": "DC"
            },
            "2006": {
                "college": "Washington State",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2020": {
                "college": "Central Michigan",
                "position": "DC"
            },
            "2021": {
                "college": "Central Michigan",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "0.0828370224300596",
                "net_sr": "0.0334353994179148",
                "net_stuff": "0.0268128393653234",
                "net_pass_sr": "0.0539496198841626",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0828370224300596
    },
    {
        "id": 521,
        "name": "Matt Wallerstedt",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000146551585408599
        },
        "positions": {
            "2000": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2001": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2002": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2014": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2015": {
                "college": "Charlotte",
                "position": "DC"
            },
            "2016": {
                "college": "Charlotte",
                "position": "DC"
            },
            "2017": {
                "college": "Charlotte",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Charlotte",
                "role": "OC",
                "net_ppa": "-0.0397434314730699",
                "net_sr": "-0.00969502754065316",
                "net_stuff": "-0.0176991181815809",
                "net_pass_sr": "-0.0522761714957416",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "0.0864239781596855",
                "net_sr": "-0.00558593047059952",
                "net_stuff": "-0.0193919495876319",
                "net_pass_sr": "-0.00968227724829773",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0466805466866156
    },
    {
        "id": 522,
        "name": "Chip West",
        "community": 2,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000106257704709579
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Charlotte",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Charlotte",
                "role": "OC",
                "net_ppa": "0.0846646152902879",
                "net_sr": "0.0507407905442967",
                "net_stuff": "-0.0611140769342956",
                "net_pass_sr": "0.00899491591410018",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0846646152902879
    },
    {
        "id": 523,
        "name": "Glenn Spencer",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00157023961314123
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2012": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2013": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2014": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2015": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2016": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2017": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2018": {
                "college": "Charlotte",
                "position": "DC"
            },
            "2019": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2020": {
                "college": "South Florida",
                "position": "DC"
            },
            "2021": {
                "college": "South Florida",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Charlotte",
                "role": "OC",
                "net_ppa": "0.0846646152902879",
                "net_sr": "0.0507407905442967",
                "net_stuff": "-0.0611140769342956",
                "net_pass_sr": "0.00899491591410018",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "0.117316085845957",
                "net_sr": "0.0708875531999526",
                "net_stuff": "-0.063062946682992",
                "net_pass_sr": "0.0683391871513842",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "-0.0652892919257035",
                "net_sr": "-0.00434987852698282",
                "net_stuff": "-0.0146710304557666",
                "net_pass_sr": "-0.00238056541597209",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "-0.120775232074149",
                "net_sr": "-0.0630810096864585",
                "net_stuff": "0.0395434802618627",
                "net_pass_sr": "-0.113573049727055",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0159161771363924
    },
    {
        "id": 524,
        "name": "Brandon Cooper",
        "community": 36,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Charlotte",
                "position": "DC"
            },
            "2020": {
                "college": "Charlotte",
                "position": "DC"
            },
            "2021": {
                "college": "Charlotte",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Charlotte",
                "role": "OC",
                "net_ppa": "-0.100496462787142",
                "net_sr": "-0.0116149102703651",
                "net_stuff": "0.039892503875504",
                "net_pass_sr": "0.0262459170600098",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.100496462787142
    },
    {
        "id": 525,
        "name": "Marcus West",
        "community": 36,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Charlotte",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Charlotte",
                "role": "OC",
                "net_ppa": "-0.0547978029160503",
                "net_sr": "0.00953943447801558",
                "net_stuff": "0.0295899192684219",
                "net_pass_sr": "0.0802655164449281",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0547978029160503
    },
    {
        "id": 526,
        "name": "Rick Smith",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000194893116307672
        },
        "positions": {
            "2000": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2014": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2015": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "0.0695121355697817",
                "net_sr": "-0.031219831651877",
                "net_stuff": "0.0662884208907565",
                "net_pass_sr": "-0.00265154038646087",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0695121355697817
    },
    {
        "id": 530,
        "name": "Hank Hughes",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000158548444378607
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2003": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2004": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2014": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.126057397527057",
                "net_sr": "-0.0641195743447527",
                "net_stuff": "0.0485083293395453",
                "net_pass_sr": "-0.026026002281574",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.126057397527057
    },
    {
        "id": 531,
        "name": "Robert Prunty",
        "community": 27,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000142286431875934
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2015": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2016": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "0.156552054789941",
                "net_sr": "0.0682529421326957",
                "net_stuff": "-0.0605720040614479",
                "net_pass_sr": "0.00885147579910384",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.156552054789941
    },
    {
        "id": 532,
        "name": "Steve Clinkscale",
        "community": 27,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000474288106253013
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.0306396755019022",
                "net_sr": "0.0312936017465555",
                "net_stuff": "-0.00919618566538707",
                "net_pass_sr": "0.0236983833663577",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0306396755019022
    },
    {
        "id": 533,
        "name": "Marcus Freeman",
        "community": 37,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000394926656976151
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Purdue",
                "position": "DC"
            },
            "2017": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2018": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2019": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2020": {
                "college": "Cincinnati",
                "position": "DC"
            },
            "2021": {
                "college": "Notre Dame",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "0.0841628116641085",
                "net_sr": "0.0626091992791473",
                "net_stuff": "-0.0322801656435012",
                "net_pass_sr": "0.0627791503583189",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.000961494311404618",
                "net_sr": "-0.00463450571524926",
                "net_stuff": "0.0319574693491812",
                "net_pass_sr": "-0.0272893834812094",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.0958432226037785",
                "net_sr": "0.0112955230319559",
                "net_stuff": "-0.0196456150511592",
                "net_pass_sr": "-0.00105617828323895",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.010718916628265393
    },
    {
        "id": 534,
        "name": "Mike Tressel",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000686796081079271
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2016": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2017": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2018": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2019": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2020": false,
            "2021": {
                "college": "Cincinnati",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.00932303041843224",
                "net_sr": "-0.0140070782901625",
                "net_stuff": "0.0130065108012903",
                "net_pass_sr": "-0.0123842969578616",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "-0.117157634771257",
                "net_sr": "-0.0618759358722931",
                "net_stuff": "0.0253165764704883",
                "net_pass_sr": "-0.0664881842862181",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.12648066518968923
    },
    {
        "id": 535,
        "name": "Brent Venables",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0191177468146845
        },
        "positions": {
            "2000": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2001": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2002": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2003": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2004": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2005": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2006": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2007": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2008": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2009": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2010": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2011": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2012": {
                "college": "Clemson",
                "position": "DC"
            },
            "2013": {
                "college": "Clemson",
                "position": "DC"
            },
            "2014": {
                "college": "Clemson",
                "position": "DC"
            },
            "2015": {
                "college": "Clemson",
                "position": "DC"
            },
            "2016": {
                "college": "Clemson",
                "position": "DC"
            },
            "2017": {
                "college": "Clemson",
                "position": "DC"
            },
            "2018": {
                "college": "Clemson",
                "position": "DC"
            },
            "2019": {
                "college": "Clemson",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Clemson",
                "role": "OC",
                "net_ppa": "0.000897521025358713",
                "net_sr": "0.0363330388705096",
                "net_stuff": "-0.0365158049892177",
                "net_pass_sr": "0.0279015013640543",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 20,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.000897521025358713
    },
    {
        "id": 536,
        "name": "Marion Hobby",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00197821221406843
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Duke",
                "position": "DC"
            },
            "2009": {
                "college": "Duke",
                "position": "DC"
            },
            "2010": {
                "college": "Duke",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Clemson",
                "position": "DC"
            },
            "2014": {
                "college": "Clemson",
                "position": "DC"
            },
            "2015": {
                "college": "Clemson",
                "position": "DC"
            },
            "2016": {
                "college": "Clemson",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Clemson",
                "role": "OC",
                "net_ppa": "0.013871931587526",
                "net_sr": "0.0467454016488732",
                "net_stuff": "-0.0511948491964127",
                "net_pass_sr": "0.0454393604556269",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "0.0188081075262791",
                "net_sr": "0.0174678452112905",
                "net_stuff": "0.0174153094942714",
                "net_pass_sr": "-0.00482492965037934",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0326800391138051
    },
    {
        "id": 538,
        "name": "Marvin Sanders",
        "community": 28,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000457668276249306
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2005": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2006": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Coastal Carolina",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Coastal Carolina",
                "role": "OC",
                "net_ppa": "-0.0551466429241644",
                "net_sr": "-0.0204300274045189",
                "net_stuff": "0.0318401748088029",
                "net_pass_sr": "0.0531646036635983",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "-0.00665708643538218",
                "net_sr": "0.0032326397340009",
                "net_stuff": "0.0414225018210833",
                "net_pass_sr": "0.036112613842036",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.06180372935954658
    },
    {
        "id": 539,
        "name": "Chad Staggs",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 1.30709584809338e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Coastal Carolina",
                "position": "DC"
            },
            "2020": {
                "college": "Coastal Carolina",
                "position": "DC"
            },
            "2021": {
                "college": "Coastal Carolina",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Coastal Carolina",
                "role": "OC",
                "net_ppa": "0.125249790476253",
                "net_sr": "0.0517688302353252",
                "net_stuff": "0.00584831554115869",
                "net_pass_sr": "0.0433111935305719",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.125249790476253
    },
    {
        "id": 541,
        "name": "Vince Okruch",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000466982458700007
        },
        "positions": {
            "2000": {
                "college": "Colorado",
                "position": "DC"
            },
            "2001": {
                "college": "Colorado",
                "position": "DC"
            },
            "2002": {
                "college": "Colorado",
                "position": "DC"
            },
            "2003": {
                "college": "Colorado",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Illinois",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "0.000240135502708433",
                "net_sr": "-0.0514362597156542",
                "net_stuff": "-0.00343195323478493",
                "net_pass_sr": "-0.0394872706497022",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.000240135502708433
    },
    {
        "id": 542,
        "name": "Mike Hankwitz",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000765409900929977
        },
        "positions": {
            "2000": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2001": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2002": {
                "college": "Texas A&M",
                "position": "DC"
            },
            "2003": false,
            "2004": {
                "college": "Colorado",
                "position": "DC"
            },
            "2005": {
                "college": "Colorado",
                "position": "DC"
            },
            "2006": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2007": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2008": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2009": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2010": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2011": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2012": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2013": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2014": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2015": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2016": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2017": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2018": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2019": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2020": {
                "college": "Northwestern",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Northwestern",
                "role": "OC",
                "net_ppa": "0.0184160998876662",
                "net_sr": "0.0242662911146703",
                "net_stuff": "-0.0486086296122245",
                "net_pass_sr": "0.029352246028062",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "0.128185210059276",
                "net_sr": "0.0534026307127826",
                "net_stuff": "-0.0669639673779066",
                "net_pass_sr": "0.0192463275297655",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 20,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1466013099469422
    },
    {
        "id": 543,
        "name": "Kent Baer",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00175577179538686
        },
        "positions": {
            "2000": {
                "college": "Stanford",
                "position": "DC"
            },
            "2001": {
                "college": "Stanford",
                "position": "DC"
            },
            "2002": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2003": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2004": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2005": {
                "college": "Washington",
                "position": "DC"
            },
            "2006": {
                "college": "Washington",
                "position": "DC"
            },
            "2007": {
                "college": "Washington",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": {
                "college": "San José State",
                "position": "DC"
            },
            "2011": {
                "college": "San José State",
                "position": "DC"
            },
            "2012": {
                "college": "San José State",
                "position": "DC"
            },
            "2013": {
                "college": "Colorado",
                "position": "DC"
            },
            "2014": {
                "college": "Colorado",
                "position": "DC"
            },
            "2015": {
                "college": "UNLV",
                "position": "DC"
            },
            "2016": {
                "college": "UNLV",
                "position": "DC"
            },
            "2017": {
                "college": "UNLV",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "-0.0387202745142009",
                "net_sr": "0.00582691627838189",
                "net_stuff": "0.0248225343286174",
                "net_pass_sr": "0.0271380202647846",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "0.00580378565379856",
                "net_sr": "-0.0113245276764741",
                "net_stuff": "-0.0172140946817318",
                "net_pass_sr": "-0.0546735359153337",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "-0.0889309740274133",
                "net_sr": "-0.0336027955161659",
                "net_stuff": "-0.00940353895812107",
                "net_pass_sr": "-0.0129786336935598",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.12184746288781564
    },
    {
        "id": 544,
        "name": "D.J. Eliot",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00222237902606123
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2014": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2015": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2016": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2017": {
                "college": "Colorado",
                "position": "DC"
            },
            "2018": {
                "college": "Colorado",
                "position": "DC"
            },
            "2019": {
                "college": "Kansas",
                "position": "DC"
            },
            "2020": {
                "college": "Kansas",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "-0.0206080527335897",
                "net_sr": "-0.00775163884543484",
                "net_stuff": "-0.0312134065925464",
                "net_pass_sr": "-0.0094349504181907",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.052368117157574",
                "net_sr": "-0.0199652228373632",
                "net_stuff": "0.0414775424008111",
                "net_pass_sr": "-0.0133877638032771",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "-0.105469628625198",
                "net_sr": "-0.0313813073216082",
                "net_stuff": "0.0332276205350231",
                "net_pass_sr": "-0.0441798016201245",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.17844579851636172
    },
    {
        "id": 545,
        "name": "Chris Wilson",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000439533822763681
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2010": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2011": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2012": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Colorado",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "-0.0535483762526088",
                "net_sr": "-0.026066814739525",
                "net_stuff": "0.0306347148431881",
                "net_pass_sr": "-0.00497754836055486",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "-0.0177457699799955",
                "net_sr": "-0.0390840816980562",
                "net_stuff": "0.00646366805484724",
                "net_pass_sr": "-0.0595017040876545",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0712941462326043
    },
    {
        "id": 547,
        "name": "Steve Stanard",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.0252805939246858
        },
        "positions": {
            "2000": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2001": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2002": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2003": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2004": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2005": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2006": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2007": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2008": false,
            "2009": {
                "college": "Tulane",
                "position": "DC"
            },
            "2010": {
                "college": "Tulane",
                "position": "DC"
            },
            "2011": {
                "college": "Tulane",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2015": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2016": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "0.163628718117417",
                "net_sr": "0.00453262414455902",
                "net_stuff": "0.000998655552723687",
                "net_pass_sr": "0.0419851747935916",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "0.172516342816977",
                "net_sr": "0.00535270170105623",
                "net_stuff": "0.0235818156475594",
                "net_pass_sr": "-0.00293957566843978",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.336145060934394
    },
    {
        "id": 548,
        "name": "Al Simmons",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000147471093364788
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2013": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2014": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "0.0305774812144161",
                "net_sr": "0.0504232738633529",
                "net_stuff": "0.0201913217966948",
                "net_pass_sr": "0.0459868027709703",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0305774812144161
    },
    {
        "id": 549,
        "name": "Marty English",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000278697378333096
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2010": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2011": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2012": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2013": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2014": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2015": false,
            "2016": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2017": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "-0.0321054268240104",
                "net_sr": "0.0389666063738025",
                "net_stuff": "0.00289375359977709",
                "net_pass_sr": "0.0370162049936867",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "-0.110760825397426",
                "net_sr": "-0.0773747240400597",
                "net_stuff": "0.0161304817898437",
                "net_pass_sr": "-0.0613528092125832",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1428662522214364
    },
    {
        "id": 550,
        "name": "Chuck Heater",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000548286661724641
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Florida",
                "position": "DC"
            },
            "2011": {
                "college": "Temple",
                "position": "DC"
            },
            "2012": {
                "college": "Temple",
                "position": "DC"
            },
            "2013": {
                "college": "Marshall",
                "position": "DC"
            },
            "2014": {
                "college": "Marshall",
                "position": "DC"
            },
            "2015": {
                "college": "Marshall",
                "position": "DC"
            },
            "2016": {
                "college": "Marshall",
                "position": "DC"
            },
            "2017": {
                "college": "Marshall",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Colorado State",
                "position": "DC"
            },
            "2021": {
                "college": "Colorado State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "0.0802794562672419",
                "net_sr": "0.0808749283891216",
                "net_stuff": "-0.0792912179042497",
                "net_pass_sr": "0.0423780744543832",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "0.0178578023269384",
                "net_sr": "-0.00203091969715175",
                "net_stuff": "-0.0100586086949328",
                "net_pass_sr": "-0.00183037716618278",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "-0.0566993486562906",
                "net_sr": "0.013102066482903",
                "net_stuff": "0.0250246977613369",
                "net_pass_sr": "0.0374187629707437",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "-0.0480617308512533",
                "net_sr": "0.012635423788891",
                "net_stuff": "0.0133218325205126",
                "net_pass_sr": "0.0399395146680628",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0066238209133636095
    },
    {
        "id": 551,
        "name": "Jerry Azzinaro",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000897408537915965
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Duke",
                "position": "DC"
            },
            "2005": {
                "college": "Duke",
                "position": "DC"
            },
            "2006": {
                "college": "Duke",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "UCLA",
                "position": "DC"
            },
            "2019": {
                "college": "UCLA",
                "position": "DC"
            },
            "2020": {
                "college": "UCLA",
                "position": "DC"
            },
            "2021": {
                "college": "UCLA",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "-0.0901690104806948",
                "net_sr": "-0.0236153624910942",
                "net_stuff": "-0.0339243497931901",
                "net_pass_sr": "-0.0533832629196759",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0901690104806948
    },
    {
        "id": 553,
        "name": "Jim Knowles",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00147640587434931
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2002": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Duke",
                "position": "DC"
            },
            "2011": {
                "college": "Duke",
                "position": "DC"
            },
            "2012": {
                "college": "Duke",
                "position": "DC"
            },
            "2013": {
                "college": "Duke",
                "position": "DC"
            },
            "2014": {
                "college": "Duke",
                "position": "DC"
            },
            "2015": {
                "college": "Duke",
                "position": "DC"
            },
            "2016": {
                "college": "Duke",
                "position": "DC"
            },
            "2017": {
                "college": "Duke",
                "position": "DC"
            },
            "2018": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2019": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2020": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2021": {
                "college": "Oklahoma State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "-0.0828726049574745",
                "net_sr": "-0.0205818634112809",
                "net_stuff": "0.0468128740908884",
                "net_pass_sr": "0.0211669616782759",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "0.0157284277683937",
                "net_sr": "0.0292512353435482",
                "net_stuff": "-0.00763487595677034",
                "net_pass_sr": "0.0352086453707506",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0671441771890808
    },
    {
        "id": 554,
        "name": "Ben Albert",
        "community": 5,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000350429813978149
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Duke",
                "position": "DC"
            },
            "2019": {
                "college": "Duke",
                "position": "DC"
            },
            "2020": {
                "college": "Duke",
                "position": "DC"
            },
            "2021": {
                "college": "Duke",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "-0.109932988212644",
                "net_sr": "-0.0266254943975152",
                "net_stuff": "0.0429367444423946",
                "net_pass_sr": "-0.0195735020644411",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.109932988212644
    },
    {
        "id": 555,
        "name": "Matt Guerrieri",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000350429813978132
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Duke",
                "position": "DC"
            },
            "2019": {
                "college": "Duke",
                "position": "DC"
            },
            "2020": {
                "college": "Duke",
                "position": "DC"
            },
            "2021": {
                "college": "Duke",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "-0.109932988212644",
                "net_sr": "-0.0266254943975152",
                "net_stuff": "0.0429367444423946",
                "net_pass_sr": "-0.0195735020644411",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.109932988212644
    },
    {
        "id": 557,
        "name": "Greg Hudson",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000108210935122782
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2004": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2005": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2006": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2007": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2008": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2009": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Purdue",
                "position": "DC"
            },
            "2014": {
                "college": "Purdue",
                "position": "DC"
            },
            "2015": {
                "college": "Purdue",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "0.063598910843539",
                "net_sr": "-0.00501922709204283",
                "net_stuff": "-0.0222118513468919",
                "net_pass_sr": "0.00235664056533469",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.0939744018428353",
                "net_sr": "-0.040276008814947",
                "net_stuff": "0.0383022065338376",
                "net_pass_sr": "-0.0267455293833017",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.030375490999296303
    },
    {
        "id": 558,
        "name": "Brian Mitchell",
        "community": 14,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000185797852687976
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2011": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2012": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "-0.0580248618191197",
                "net_sr": "-0.0295677377983296",
                "net_stuff": "0.0752648382038519",
                "net_pass_sr": "-0.0117278462671352",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0580248618191197
    },
    {
        "id": 559,
        "name": "Kenwick Thompson",
        "community": 20,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000392987295363005
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "San José State",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2017": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "-0.222935302968815",
                "net_sr": "-0.0869337842117858",
                "net_stuff": "0.01323213919029",
                "net_pass_sr": "-0.0721867280125072",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "-0.0680485601950431",
                "net_sr": "-0.00735740792000461",
                "net_stuff": "0.0502546596944083",
                "net_pass_sr": "0.0125566206516929",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.2909838631638581
    },
    {
        "id": 560,
        "name": "David Blackwell",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.0000867703283275348
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "South Florida",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2019": {
                "college": "Old Dominion",
                "position": "DC"
            },
            "2020": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2021": {
                "college": "Louisiana Tech",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "0.0613467273413401",
                "net_sr": "0.0636369804815675",
                "net_stuff": "-0.0746442866023133",
                "net_pass_sr": "0.0287592896483972",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "-0.141316689932145",
                "net_sr": "-0.0271061108304987",
                "net_stuff": "0.0227856813973905",
                "net_pass_sr": "-0.0621547471327141",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Old Dominion",
                "role": "OC",
                "net_ppa": "0.0119097416414526",
                "net_sr": "0.0133881431615918",
                "net_stuff": "-0.10357241261871",
                "net_pass_sr": "0.000711015762152434",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "-0.0241173832168502",
                "net_sr": "-0.0458309167176595",
                "net_stuff": "-0.0132922379058159",
                "net_pass_sr": "-0.049224209996972",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.09217760416620249
    },
    {
        "id": 561,
        "name": "Blake Harrell",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 5.63894001172522e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "East Carolina",
                "position": "DC"
            },
            "2021": {
                "college": "East Carolina",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "0.098209393868037",
                "net_sr": "0.0395932436597369",
                "net_stuff": "0.00741041639765569",
                "net_pass_sr": "0.0446503007307402",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.098209393868037
    },
    {
        "id": 563,
        "name": "Jay Peterson",
        "community": 37,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00469405866343354
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2005": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2006": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2007": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2008": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2013": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "-0.144677943063794",
                "net_sr": "-0.10457553579667",
                "net_stuff": "0.0679354471967797",
                "net_pass_sr": "-0.0875114259134195",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.144677943063794
    },
    {
        "id": 564,
        "name": "Brad McCaslin",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000461630491664933
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2015": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Eastern Michigan",
                "role": "OC",
                "net_ppa": "-0.239992169420166",
                "net_sr": "-0.037604070602362",
                "net_stuff": "-0.00298244844261783",
                "net_pass_sr": "-0.0177695407402786",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.239992169420166
    },
    {
        "id": 565,
        "name": "Neal Neathery",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000101879901533853
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2012": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2013": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2014": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2015": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2016": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2017": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2018": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2019": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2020": {
                "college": "Eastern Michigan",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Eastern Michigan",
                "role": "OC",
                "net_ppa": "0.085123319430025",
                "net_sr": "0.0274003204530275",
                "net_stuff": "0.0117140561871308",
                "net_pass_sr": "0.0415669102701418",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UT San Antonio",
                "role": "OC",
                "net_ppa": "0.0565309080054778",
                "net_sr": "-0.00244932258463132",
                "net_stuff": "0.0316161015537302",
                "net_pass_sr": "-0.0144022063807427",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1416542274355028
    },
    {
        "id": 567,
        "name": "Kurt Van Valkenburgh",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 9.06224434297161e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "0.00855000056587878",
                "net_sr": "0.018127135796577",
                "net_stuff": "-0.0113393349134025",
                "net_pass_sr": "-0.0297649913514372",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00855000056587878
    },
    {
        "id": 568,
        "name": "Pete Rekstis",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000501004699405925
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Kent State",
                "position": "DC"
            },
            "2010": false,
            "2011": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2012": false,
            "2013": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "-0.0966101996547561",
                "net_sr": "-0.0528408025590453",
                "net_stuff": "0.0949101343448518",
                "net_pass_sr": "-0.0270588792958023",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kent State",
                "role": "OC",
                "net_ppa": "-0.000135541118670306",
                "net_sr": "-0.0166179616494805",
                "net_stuff": "0.0427145893986352",
                "net_pass_sr": "-0.0191637989512912",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "0.27618777234578",
                "net_sr": "0.0603624121669082",
                "net_stuff": "-0.0786332790282686",
                "net_pass_sr": "0.0561575927746031",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1794420315723536
    },
    {
        "id": 569,
        "name": "Roc Bellantoni",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000275834071906462
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2015": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2016": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "0.198719248030634",
                "net_sr": "0.0327385501358869",
                "net_stuff": "-0.0187505098961893",
                "net_pass_sr": "0.0210227869990342",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.198719248030634
    },
    {
        "id": 570,
        "name": "Chris Kiffin",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000399014204098375
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "0.11693305218505",
                "net_sr": "0.059959732125627",
                "net_stuff": "-0.0228970737713435",
                "net_pass_sr": "0.0283918164671342",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.11693305218505
    },
    {
        "id": 571,
        "name": "Tony Pecoraro",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000525526930087065
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2017": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2018": {
                "college": "Florida Atlantic",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "-0.0900580102913523",
                "net_sr": "-0.0629881756818437",
                "net_stuff": "0.0778501578303892",
                "net_pass_sr": "-0.052381651505515",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.0468301022167311",
                "net_sr": "0.00732805621150506",
                "net_stuff": "-0.05476078175756",
                "net_pass_sr": "0.00381123554230478",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0432279080746212
    },
    {
        "id": 573,
        "name": "Kevin Fouquier",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000206257166104405
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Florida International",
                "position": "DC"
            },
            "2007": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2008": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2009": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2010": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.457399396253012",
                "net_sr": "0.201223581757508",
                "net_stuff": "-0.248877245508982",
                "net_pass_sr": "0.207142857142857",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "0.101590538971471",
                "net_sr": "0.0470904098493401",
                "net_stuff": "-0.0465606598052277",
                "net_pass_sr": "0.0237071333875712",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.558989935224483
    },
    {
        "id": 574,
        "name": "Phil Galiano",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000810328563141455
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Florida International",
                "position": "DC"
            },
            "2008": {
                "college": "Florida International",
                "position": "DC"
            },
            "2009": {
                "college": "Florida International",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "-0.109965219965019",
                "net_sr": "-0.067569359696011",
                "net_stuff": "-0.00536947940428803",
                "net_pass_sr": "-0.0880048216420648",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.109965219965019
    },
    {
        "id": 575,
        "name": "Todd Orlando",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.00111212421846182
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2006": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2007": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2008": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2009": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2010": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2011": {
                "college": "Florida International",
                "position": "DC"
            },
            "2012": {
                "college": "Florida International",
                "position": "DC"
            },
            "2013": {
                "college": "Utah State",
                "position": "DC"
            },
            "2014": {
                "college": "Utah State",
                "position": "DC"
            },
            "2015": {
                "college": "Houston",
                "position": "DC"
            },
            "2016": {
                "college": "Houston",
                "position": "DC"
            },
            "2017": {
                "college": "Texas",
                "position": "DC"
            },
            "2018": {
                "college": "Texas",
                "position": "DC"
            },
            "2019": {
                "college": "Texas",
                "position": "DC"
            },
            "2020": {
                "college": "USC",
                "position": "DC"
            },
            "2021": {
                "college": "USC",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.0532168600548347",
                "net_sr": "-0.0404903284323629",
                "net_stuff": "0.0162932840967172",
                "net_pass_sr": "-0.0194890887037178",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "0.0731758329267334",
                "net_sr": "-0.000918070013266992",
                "net_stuff": "0.0395175778717133",
                "net_pass_sr": "0.00173562846649689",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.0965877282167555",
                "net_sr": "0.0120053409667942",
                "net_stuff": "0.0126021134968214",
                "net_pass_sr": "0.0636354630088499",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "-0.143132354670766",
                "net_sr": "-0.0568219080154795",
                "net_stuff": "0.0426793221284558",
                "net_pass_sr": "-0.0361304982196878",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.0712086260432923",
                "net_sr": "-0.0250493323843579",
                "net_stuff": "0.0646166891794564",
                "net_pass_sr": "-0.0108716854520923",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 17,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.15105669257084986
    },
    {
        "id": 576,
        "name": "Josh Conklin",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000322921674913647
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Florida International",
                "position": "DC"
            },
            "2014": {
                "college": "Florida International",
                "position": "DC"
            },
            "2015": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2016": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2017": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "-0.0809030831606155",
                "net_sr": "-0.0238256040865389",
                "net_stuff": "0.0377890100255553",
                "net_pass_sr": "-0.01699336675026",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "-0.0751477565486626",
                "net_sr": "-0.0025102664835398",
                "net_stuff": "0.00811257688253847",
                "net_pass_sr": "-0.00101442542671343",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.15605083970927808
    },
    {
        "id": 577,
        "name": "Matt House",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000636612639868762
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2014": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2015": {
                "college": "Florida International",
                "position": "DC"
            },
            "2016": false,
            "2017": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2018": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "-0.0873018217125535",
                "net_sr": "-0.0817714000757097",
                "net_stuff": "0.0105294094390668",
                "net_pass_sr": "-0.0674571448323596",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "0.000233214326130438",
                "net_sr": "0.023953479307221",
                "net_stuff": "-0.0318062573166825",
                "net_pass_sr": "0.0197029900545164",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "-0.139964325986432",
                "net_sr": "-0.0146719350792839",
                "net_stuff": "0.00636931899643267",
                "net_pass_sr": "-0.00135048114287045",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.22703293337285507
    },
    {
        "id": 578,
        "name": "Ron Cooper",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000702537048812333
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Florida International",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "-0.100865636463968",
                "net_sr": "-0.0826558261492659",
                "net_stuff": "0.0866979557803032",
                "net_pass_sr": "-0.0589539328791665",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.100865636463968
    },
    {
        "id": 579,
        "name": "Jeff Copp",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000016848956160819
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Florida International",
                "position": "DC"
            },
            "2020": {
                "college": "Florida International",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.0418857080358804",
                "net_sr": "0.0379407946430562",
                "net_stuff": "-0.0269536664584231",
                "net_pass_sr": "0.0549556859522898",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0418857080358804
    },
    {
        "id": 580,
        "name": "Jerod Kruse",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000016848956160817
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Florida International",
                "position": "DC"
            },
            "2020": {
                "college": "Florida International",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.0418857080358804",
                "net_sr": "0.0379407946430562",
                "net_stuff": "-0.0269536664584231",
                "net_pass_sr": "0.0549556859522898",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0418857080358804
    },
    {
        "id": 581,
        "name": "Jon Hoke",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000911341762768023
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Florida",
                "position": "DC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Maryland",
                "position": "DC"
            },
            "2020": {
                "college": "Maryland",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.0351301159991062",
                "net_sr": "0.019071055085209",
                "net_stuff": "-0.0117287369933662",
                "net_pass_sr": "0.00106375173308781",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "-0.156539087429889",
                "net_sr": "-0.0425237756954986",
                "net_stuff": "0.0465660588465085",
                "net_pass_sr": "-0.0455093360509022",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1916692034289952
    },
    {
        "id": 582,
        "name": "Greg Mattison",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00141889747589146
        },
        "positions": {
            "2000": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2001": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Florida",
                "position": "DC"
            },
            "2006": {
                "college": "Florida",
                "position": "DC"
            },
            "2007": {
                "college": "Florida",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Michigan",
                "position": "DC"
            },
            "2012": {
                "college": "Michigan",
                "position": "DC"
            },
            "2013": {
                "college": "Michigan",
                "position": "DC"
            },
            "2014": {
                "college": "Michigan",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2020": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.00239164239361839",
                "net_sr": "-0.0200358102855862",
                "net_stuff": "0.0229500776294943",
                "net_pass_sr": "-0.0118803028762817",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "-0.0637156936064628",
                "net_sr": "-0.0202708487984576",
                "net_stuff": "0.00310627448513204",
                "net_pass_sr": "-0.022498746046646",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.06132405121284441
    },
    {
        "id": 583,
        "name": "Teryl Austin",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000274621817614703
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Florida",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "0.0517729589521318",
                "net_sr": "0.00714880485887087",
                "net_stuff": "0.0129488263293212",
                "net_pass_sr": "-0.00218943478095679",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0517729589521318
    },
    {
        "id": 584,
        "name": "Dan Quinn",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000131040347315475
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Florida",
                "position": "DC"
            },
            "2012": {
                "college": "Florida",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "-0.0197385282526657",
                "net_sr": "0.0183048491415724",
                "net_stuff": "-0.0354154019689243",
                "net_pass_sr": "-0.000368118307082943",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0197385282526657
    },
    {
        "id": 585,
        "name": "Chris Rumph",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000756863537039059
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Florida",
                "position": "DC"
            },
            "2018": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "-0.124083073060895",
                "net_sr": "-0.0230911615270964",
                "net_stuff": "0.00525856055736065",
                "net_pass_sr": "-0.0624046195987321",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.0171646534662467",
                "net_sr": "-0.0345456216789861",
                "net_stuff": "0.0149420550281912",
                "net_pass_sr": "-0.072280762141906",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.1412477265271417
    },
    {
        "id": 586,
        "name": "Todd Grantham",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000695766888450961
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Georgia",
                "position": "DC"
            },
            "2011": {
                "college": "Georgia",
                "position": "DC"
            },
            "2012": {
                "college": "Georgia",
                "position": "DC"
            },
            "2013": {
                "college": "Georgia",
                "position": "DC"
            },
            "2014": {
                "college": "Louisville",
                "position": "DC"
            },
            "2015": {
                "college": "Louisville",
                "position": "DC"
            },
            "2016": {
                "college": "Louisville",
                "position": "DC"
            },
            "2017": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2018": {
                "college": "Florida",
                "position": "DC"
            },
            "2019": {
                "college": "Florida",
                "position": "DC"
            },
            "2020": {
                "college": "Florida",
                "position": "DC"
            },
            "2021": {
                "college": "Florida",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "-0.0131725720443807",
                "net_sr": "0.000390244089982894",
                "net_stuff": "-0.0123344726855673",
                "net_pass_sr": "0.0292090786586746",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "0.0509249734985939",
                "net_sr": "-0.0293524979934534",
                "net_stuff": "-0.00818569195802768",
                "net_pass_sr": "-0.00788625306439311",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.0186098480344039",
                "net_sr": "-0.023880728005568",
                "net_stuff": "-0.0163394218863754",
                "net_pass_sr": "-0.0549726812082658",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "-0.121849881423373",
                "net_sr": "-0.0424961536821177",
                "net_stuff": "0.0175534618525304",
                "net_pass_sr": "-0.0350163779829787",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0654876319347559
    },
    {
        "id": 588,
        "name": "Harlon Barnett",
        "community": 37,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000440229709638642
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2016": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2017": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2018": {
                "college": "Florida State",
                "position": "DC"
            },
            "2019": {
                "college": "Florida State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "-0.0204640902817468",
                "net_sr": "-0.0330404690238131",
                "net_stuff": "0.0178879341534134",
                "net_pass_sr": "-0.0407219433274649",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "-0.143120855257529",
                "net_sr": "-0.0749540088126759",
                "net_stuff": "0.0402514732097959",
                "net_pass_sr": "-0.0742936135614168",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.1635849455392758
    },
    {
        "id": 589,
        "name": "Adam Fuller",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000917273719287007
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Marshall",
                "position": "DC"
            },
            "2019": {
                "college": "Memphis",
                "position": "DC"
            },
            "2020": {
                "college": "Florida State",
                "position": "DC"
            },
            "2021": {
                "college": "Florida State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "-0.105102981563486",
                "net_sr": "-0.0239099044946605",
                "net_stuff": "0.0188203844921414",
                "net_pass_sr": "-0.049515910308876",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "0.0520268766333736",
                "net_sr": "0.0501462428674029",
                "net_stuff": "-0.0299980165683076",
                "net_pass_sr": "0.05209225664943",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "0.0328298616189882",
                "net_sr": "0.0270748153466222",
                "net_stuff": "-0.0106222528589839",
                "net_pass_sr": "0.0539226098779288",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0202462433111242
    },
    {
        "id": 592,
        "name": "Randy Stewart",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000891320723220981
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2010": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2011": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "0.127997024513012",
                "net_sr": "0.0626816625467209",
                "net_stuff": "-0.0610601934114905",
                "net_pass_sr": "0.0521126528595358",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.127997024513012
    },
    {
        "id": 593,
        "name": "Nick Toth",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000438717387654058
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2013": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2014": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2015": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "-0.0134509347076659",
                "net_sr": "-0.0222371329087278",
                "net_stuff": "0.0474626182742564",
                "net_pass_sr": "0.00544443240563797",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0134509347076659
    },
    {
        "id": 594,
        "name": "Lorenzo Ward",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000123413021865276
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2013": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2014": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2015": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2016": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "-0.0314513363980382",
                "net_sr": "-0.0655707953951117",
                "net_stuff": "0.0501462327801723",
                "net_pass_sr": "0.0154056109439467",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "-0.138144014087601",
                "net_sr": "-0.0643718478085183",
                "net_stuff": "0.00859275324690861",
                "net_pass_sr": "-0.0785293966308446",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.1695953504856392
    },
    {
        "id": 595,
        "name": "Orlondo Steinauer",
        "community": 23,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000152758775254064
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "-0.256087472707566",
                "net_sr": "-0.0634923654590939",
                "net_stuff": "0.0697530938458787",
                "net_pass_sr": "-0.0298620253282163",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.256087472707566
    },
    {
        "id": 596,
        "name": "Bert Watts",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000305517550508143
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2019": {
                "college": "Fresno State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "0.0642721581416672",
                "net_sr": "0.0377953509046426",
                "net_stuff": "-0.000538343038264277",
                "net_pass_sr": "0.0184324892973096",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0642721581416672
    },
    {
        "id": 599,
        "name": "Dan Lanning",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000186705663594959
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Georgia",
                "position": "DC"
            },
            "2020": {
                "college": "Georgia",
                "position": "DC"
            },
            "2021": {
                "college": "Georgia",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "0.05300200941056",
                "net_sr": "0.0332516881250549",
                "net_stuff": "-0.0333729977316808",
                "net_pass_sr": "0.00297154714892234",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.05300200941056
    },
    {
        "id": 600,
        "name": "Glenn Schumann",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000186705663594962
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Georgia",
                "position": "DC"
            },
            "2020": {
                "college": "Georgia",
                "position": "DC"
            },
            "2021": {
                "college": "Georgia",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "0.05300200941056",
                "net_sr": "0.0332516881250549",
                "net_stuff": "-0.0333729977316808",
                "net_pass_sr": "0.00297154714892234",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.05300200941056
    },
    {
        "id": 601,
        "name": "Lorenzo Costantini",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000171016003871537
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Georgia Southern",
                "position": "DC"
            },
            "2017": {
                "college": "Georgia Southern",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "OC",
                "net_ppa": "-0.117485281757258",
                "net_sr": "-0.0266658192008142",
                "net_stuff": "-0.0284707718455712",
                "net_pass_sr": "-0.0708728408457129",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.117485281757258
    },
    {
        "id": 602,
        "name": "Jesse Minter",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000239218459587702
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Georgia State",
                "position": "DC"
            },
            "2014": {
                "college": "Georgia State",
                "position": "DC"
            },
            "2015": {
                "college": "Georgia State",
                "position": "DC"
            },
            "2016": {
                "college": "Georgia State",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Vanderbilt",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Georgia State",
                "role": "OC",
                "net_ppa": "0.101792838617973",
                "net_sr": "0.0903862608933923",
                "net_stuff": "0.0186279473036755",
                "net_pass_sr": "0.223589940472386",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.058905093422453",
                "net_sr": "-0.0061783036584005",
                "net_stuff": "0.0133575181916143",
                "net_pass_sr": "-0.0348339651451422",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.04288774519552
    },
    {
        "id": 603,
        "name": "Nate Fuqua",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000269459267995625
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Georgia State",
                "position": "DC"
            },
            "2018": {
                "college": "Georgia State",
                "position": "DC"
            },
            "2019": {
                "college": "Georgia State",
                "position": "DC"
            },
            "2020": {
                "college": "Georgia State",
                "position": "DC"
            },
            "2021": {
                "college": "Georgia State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Georgia State",
                "role": "OC",
                "net_ppa": "-0.0551190706351769",
                "net_sr": "0.040997191541648",
                "net_stuff": "-0.0254066867579273",
                "net_pass_sr": "-0.0169332643930148",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0551190706351769
    },
    {
        "id": 604,
        "name": "Jon Tenuta",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.0000375324116150237
        },
        "positions": {
            "2000": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2001": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2002": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2003": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2004": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2005": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2006": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2007": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2008": false,
            "2009": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Virginia",
                "position": "DC"
            },
            "2015": {
                "college": "Virginia",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "-0.0854555197796624",
                "net_sr": "-0.0212320824527524",
                "net_stuff": "-0.0491902588335337",
                "net_pass_sr": "-0.064022582072217",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "-0.129801004493691",
                "net_sr": "-0.0628730666264204",
                "net_stuff": "0.0149713546843142",
                "net_pass_sr": "-0.0558306392385676",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2152565242733534
    },
    {
        "id": 605,
        "name": "Andrew Thacker",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000821626689986696
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Temple",
                "position": "DC"
            },
            "2019": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2020": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2021": {
                "college": "Georgia Tech",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Georgia Tech",
                "role": "OC",
                "net_ppa": "-0.0476519189230533",
                "net_sr": "0.0275476690728161",
                "net_stuff": "-0.00578378735090773",
                "net_pass_sr": "0.022303259350841",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "-0.0130290572127097",
                "net_sr": "-0.0344841480850063",
                "net_stuff": "0.0250373807228332",
                "net_pass_sr": "-0.0134448877224802",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.060680976135763
    },
    {
        "id": 606,
        "name": "Nathan Burton",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00004108133449933
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Georgia Tech",
                "position": "DC"
            },
            "2021": {
                "college": "Georgia Tech",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Georgia Tech",
                "role": "OC",
                "net_ppa": "-0.0657182569381441",
                "net_sr": "0.0111840949437876",
                "net_stuff": "-0.00327948843210507",
                "net_pass_sr": "-0.0211774086398017",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0657182569381441
    },
    {
        "id": 610,
        "name": "Cal Lee",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000016806743063571
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2009": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "0.0383321182282142",
                "net_sr": "0.0154107916634119",
                "net_stuff": "-0.00882857986996646",
                "net_pass_sr": "0.033129346790667",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0383321182282142
    },
    {
        "id": 611,
        "name": "Thom Kaumeyer",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000212490313063198
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2003": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2004": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2005": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2006": false,
            "2007": {
                "college": "Tulane",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2013": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "0.0121657961851507",
                "net_sr": "-0.00506822930863537",
                "net_stuff": "0.035057559409891",
                "net_pass_sr": "-0.0271739590469867",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "0.00391316407461419",
                "net_sr": "0.0317441543629098",
                "net_stuff": "-0.0372061852662174",
                "net_pass_sr": "0.0529933335542345",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.01607896025976489
    },
    {
        "id": 612,
        "name": "Kevin Clune",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000167162211799002
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2015": {
                "college": "Utah State",
                "position": "DC"
            },
            "2016": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2017": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.12728280095011",
                "net_sr": "-0.015759089587457",
                "net_stuff": "0.0521386093979032",
                "net_pass_sr": "-0.0362977431540299",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon State",
                "role": "OC",
                "net_ppa": "-0.0376544416764115",
                "net_sr": "-0.0143363443713718",
                "net_stuff": "0.0335875117860906",
                "net_pass_sr": "-0.00475281309794556",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "-0.0950414530948538",
                "net_sr": "-0.0162097949120644",
                "net_stuff": "0.0092703213498592",
                "net_pass_sr": "-0.0187346809039333",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2599786957213753
    },
    {
        "id": 613,
        "name": "Tom Mason",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000603619615529356
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "SMU",
                "position": "DC"
            },
            "2009": {
                "college": "SMU",
                "position": "DC"
            },
            "2010": {
                "college": "SMU",
                "position": "DC"
            },
            "2011": {
                "college": "SMU",
                "position": "DC"
            },
            "2012": {
                "college": "SMU",
                "position": "DC"
            },
            "2013": {
                "college": "SMU",
                "position": "DC"
            },
            "2014": {
                "college": "SMU",
                "position": "DC"
            },
            "2015": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2016": {
                "college": "UTEP",
                "position": "DC"
            },
            "2017": {
                "college": "UTEP",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "0.00912312744347668",
                "net_sr": "0.0413875116567226",
                "net_stuff": "-0.0333746216558543",
                "net_pass_sr": "0.0530091195209669",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "0.184995526828481",
                "net_sr": "0.0465512637341721",
                "net_stuff": "-0.0605115005514969",
                "net_pass_sr": "0.0105813366181741",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "-0.0528868965417118",
                "net_sr": "0.0338082838313036",
                "net_stuff": "-0.0131066969692532",
                "net_pass_sr": "0.0760692061503691",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.14123175773024588
    },
    {
        "id": 614,
        "name": "Legi Suiaunoa",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000359699585370172
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.0582018247760497",
                "net_sr": "-0.0118215934092479",
                "net_stuff": "0.0159629802566385",
                "net_pass_sr": "-0.029595828940971",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.0582018247760497
    },
    {
        "id": 615,
        "name": "Corey Batoon",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000074767124131909
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2019": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2020": false,
            "2021": {
                "college": "South Alabama",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "0.00799418947323438",
                "net_sr": "0.0135280000348402",
                "net_stuff": "0.0224181901829098",
                "net_pass_sr": "0.0258488076537207",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "0.0684332591198545",
                "net_sr": "0.0698552799047863",
                "net_stuff": "-0.00808974330034379",
                "net_pass_sr": "0.0838106908959381",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.07642744859308888
    },
    {
        "id": 616,
        "name": "Jacob Yoro",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000059772528292372
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "0.0714966476632885",
                "net_sr": "0.0296297631281865",
                "net_stuff": "-0.0565213067911992",
                "net_pass_sr": "0.0643761200349836",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Other",
        "aggregate_xpa": 0.0714966476632885
    },
    {
        "id": 617,
        "name": "Victor Santa Cruz",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000597725282923695
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Hawai'i",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.144712152319018",
                "net_sr": "-0.0774999623004865",
                "net_stuff": "0.0285280340692673",
                "net_pass_sr": "-0.0548445109241318",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.144712152319018
    },
    {
        "id": 619,
        "name": "Bradley Dale Peveto",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000827152731766764
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Houston",
                "position": "DC"
            },
            "2002": {
                "college": "Houston",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "LSU",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "UTEP",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.0348671843057334",
                "net_sr": "-0.0346131835790141",
                "net_stuff": "-0.0450663224583663",
                "net_pass_sr": "-0.0487741660807534",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "0.121736920678855",
                "net_sr": "0.0587493361458245",
                "net_stuff": "-0.0397798071582495",
                "net_pass_sr": "0.0424155955274439",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.08686973637312159
    },
    {
        "id": 621,
        "name": "Alan Weddell",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000399903922661419
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Houston",
                "position": "DC"
            },
            "2007": {
                "college": "Houston",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.109611294669535",
                "net_sr": "-0.0439484846020141",
                "net_stuff": "0.0939705823364756",
                "net_pass_sr": "-0.0821692906648412",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.109611294669535
    },
    {
        "id": 622,
        "name": "John Skladany",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00206845464586298
        },
        "positions": {
            "2000": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2001": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2002": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2003": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2004": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2005": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2006": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2007": {
                "college": "UCF",
                "position": "DC"
            },
            "2008": {
                "college": "Houston",
                "position": "DC"
            },
            "2009": {
                "college": "Houston",
                "position": "DC"
            },
            "2010": false,
            "2011": {
                "college": "UCF",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0126434122222645",
                "net_sr": "-0.0638411123068043",
                "net_stuff": "0.041784792361705",
                "net_pass_sr": "-0.0846597453697833",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "0.117789513734926",
                "net_sr": "0.0547270712570704",
                "net_stuff": "-0.0349814958993621",
                "net_pass_sr": "0.0531999944041116",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.10514610151266149
    },
    {
        "id": 623,
        "name": "Brian Stewart",
        "community": 4,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000331125215390872
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Houston",
                "position": "DC"
            },
            "2011": {
                "college": "Houston",
                "position": "DC"
            },
            "2012": {
                "college": "Maryland",
                "position": "DC"
            },
            "2013": {
                "college": "Maryland",
                "position": "DC"
            },
            "2014": {
                "college": "Maryland",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Rice",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Maryland",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "0.0218735257508284",
                "net_sr": "0.00699873617776198",
                "net_stuff": "-0.0264436573728106",
                "net_pass_sr": "0.0167569280332139",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.108476420118833",
                "net_sr": "-0.0233577533911099",
                "net_stuff": "0.0398924695608846",
                "net_pass_sr": "0.0165238995810442",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "-0.0224080982753583",
                "net_sr": "-0.05918859256291",
                "net_stuff": "0.0390529457690795",
                "net_pass_sr": "-0.0701129936380674",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.10901099264336289
    },
    {
        "id": 624,
        "name": "Jamie Bryant",
        "community": 42,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00000145603554689431
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2010": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2011": false,
            "2012": {
                "college": "Houston",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "0.0336581359464059",
                "net_sr": "0.017686850829279",
                "net_stuff": "0.00182794283944773",
                "net_pass_sr": "0.0202192669078857",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.0196654093341852",
                "net_sr": "0.00996869905037018",
                "net_stuff": "0.0201352250177632",
                "net_pass_sr": "-0.00178724818565912",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.013992726612220698
    },
    {
        "id": 625,
        "name": "Craig Naivar",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000410038079892584
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Rice",
                "position": "DC"
            },
            "2009": {
                "college": "Rice",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Texas State",
                "position": "DC"
            },
            "2013": {
                "college": "Texas State",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Houston",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Texas",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "0.0317263785156976",
                "net_sr": "0.0497896104156126",
                "net_stuff": "-0.0481961470484067",
                "net_pass_sr": "0.0425721101713294",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.0474362438737994",
                "net_sr": "0.00535787556284822",
                "net_stuff": "0.0363998664807648",
                "net_pass_sr": "-0.0232348587806997",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "-0.0653289176294448",
                "net_sr": "-0.0298689578635849",
                "net_stuff": "0.0150709790073959",
                "net_pass_sr": "-0.0385600691887516",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas State",
                "role": "OC",
                "net_ppa": "0.0543679892585284",
                "net_sr": "0.0667456750451197",
                "net_stuff": "0.0288189769316997",
                "net_pass_sr": "0.112332076075707",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0682016940185806
    },
    {
        "id": 626,
        "name": "Clay Jennings",
        "community": 13,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000440748433764181
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Houston",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0257388254144661",
                "net_sr": "-0.0509381736712367",
                "net_stuff": "0.0131755391178023",
                "net_pass_sr": "-0.0448402703768307",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0257388254144661
    },
    {
        "id": 627,
        "name": "Mark D'Onofrio",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000213985919207115
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Temple",
                "position": "DC"
            },
            "2007": {
                "college": "Temple",
                "position": "DC"
            },
            "2008": {
                "college": "Temple",
                "position": "DC"
            },
            "2009": {
                "college": "Temple",
                "position": "DC"
            },
            "2010": {
                "college": "Temple",
                "position": "DC"
            },
            "2011": {
                "college": "Miami",
                "position": "DC"
            },
            "2012": {
                "college": "Miami",
                "position": "DC"
            },
            "2013": {
                "college": "Miami",
                "position": "DC"
            },
            "2014": {
                "college": "Miami",
                "position": "DC"
            },
            "2015": {
                "college": "Miami",
                "position": "DC"
            },
            "2016": false,
            "2017": {
                "college": "Houston",
                "position": "DC"
            },
            "2018": {
                "college": "Houston",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0862540221351307",
                "net_sr": "-0.0650843263086452",
                "net_stuff": "0.0350870056212265",
                "net_pass_sr": "-0.0503990386895465",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "-0.115671118136901",
                "net_sr": "-0.0803762961143317",
                "net_stuff": "0.100715288167585",
                "net_pass_sr": "-0.0743030938583903",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "0.0748540316526689",
                "net_sr": "0.0260741645280931",
                "net_stuff": "-0.0144650158199069",
                "net_pass_sr": "0.0253919923272811",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.12707110861936277
    },
    {
        "id": 628,
        "name": "Doug Belk",
        "community": 15,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000397740269371216
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Houston",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.152472716650068",
                "net_sr": "-0.0278421388356341",
                "net_stuff": "-0.0400211444626159",
                "net_pass_sr": "-0.0422647226445642",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.152472716650068
    },
    {
        "id": 629,
        "name": "Mike Cassity",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.0000765869092024269
        },
        "positions": {
            "2000": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2001": {
                "college": "Illinois",
                "position": "DC"
            },
            "2002": {
                "college": "Illinois",
                "position": "DC"
            },
            "2003": {
                "college": "Illinois",
                "position": "DC"
            },
            "2004": {
                "college": "Louisville",
                "position": "DC"
            },
            "2005": {
                "college": "Louisville",
                "position": "DC"
            },
            "2006": {
                "college": "Louisville",
                "position": "DC"
            },
            "2007": {
                "college": "Louisville",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "-0.0741276581000276",
                "net_sr": "-0.0493887275586004",
                "net_stuff": "0.00816147286042382",
                "net_pass_sr": "-0.102598433630939",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0741276581000276
    },
    {
        "id": 631,
        "name": "Dan Disch",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000444056702502733
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Illinois",
                "position": "DC"
            },
            "2008": {
                "college": "Illinois",
                "position": "DC"
            },
            "2009": {
                "college": "Illinois",
                "position": "DC"
            },
            "2010": false,
            "2011": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2012": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2013": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2014": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "0.00512228303046067",
                "net_sr": "0.0180832795124352",
                "net_stuff": "-0.0195590705225938",
                "net_pass_sr": "0.0280781699961286",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "-0.139102606325165",
                "net_sr": "-0.0391239373945435",
                "net_stuff": "0.0231710123931262",
                "net_pass_sr": "-0.0258697960713278",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.0601049000075198",
                "net_sr": "0.0105344027896038",
                "net_stuff": "-0.0192310870647851",
                "net_pass_sr": "0.00309139912567508",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.07387542328718452
    },
    {
        "id": 632,
        "name": "Mike Phair",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000133599129300918
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Illinois",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "0.068126176215327",
                "net_sr": "0.0556587997660635",
                "net_stuff": "-0.0617109905548309",
                "net_pass_sr": "0.0638351555929352",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.068126176215327
    },
    {
        "id": 633,
        "name": "Hardy Nickerson",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000317664285543656
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Illinois",
                "position": "DC"
            },
            "2017": {
                "college": "Illinois",
                "position": "DC"
            },
            "2018": {
                "college": "Illinois",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "-0.0967090509625623",
                "net_sr": "-0.0288794757342339",
                "net_stuff": "0.0220663706883887",
                "net_pass_sr": "-0.0252081571659524",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0967090509625623
    },
    {
        "id": 634,
        "name": "Ryan Walters",
        "community": 19,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000226658399421244
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Missouri",
                "position": "DC"
            },
            "2017": false,
            "2018": {
                "college": "Missouri",
                "position": "DC"
            },
            "2019": {
                "college": "Missouri",
                "position": "DC"
            },
            "2020": {
                "college": "Missouri",
                "position": "DC"
            },
            "2021": {
                "college": "Illinois",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "-0.100922641625032",
                "net_sr": "-0.0590019592983698",
                "net_stuff": "0.0288264800973883",
                "net_pass_sr": "-0.0793390411092286",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Missouri",
                "role": "OC",
                "net_ppa": "-0.0176604036489022",
                "net_sr": "-0.0155936721250952",
                "net_stuff": "0.00356870450814811",
                "net_pass_sr": "-0.0108169053061412",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.1185830452739342
    },
    {
        "id": 637,
        "name": "Brian George",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000119218169027207
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2005": {
                "college": "Indiana",
                "position": "DC"
            },
            "2006": {
                "college": "Indiana",
                "position": "DC"
            },
            "2007": {
                "college": "Indiana",
                "position": "DC"
            },
            "2008": {
                "college": "Indiana",
                "position": "DC"
            },
            "2009": {
                "college": "Indiana",
                "position": "DC"
            },
            "2010": {
                "college": "Indiana",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Kent State",
                "position": "DC"
            },
            "2014": {
                "college": "Kent State",
                "position": "DC"
            },
            "2015": {
                "college": "Kent State",
                "position": "DC"
            },
            "2016": {
                "college": "Toledo",
                "position": "DC"
            },
            "2017": {
                "college": "Toledo",
                "position": "DC"
            },
            "2018": {
                "college": "Toledo",
                "position": "DC"
            },
            "2019": {
                "college": "Toledo",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.0020102456060986",
                "net_sr": "-0.0122159056978403",
                "net_stuff": "-0.00919429660928625",
                "net_pass_sr": "-0.0117822369341036",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kent State",
                "role": "OC",
                "net_ppa": "-0.140984614012861",
                "net_sr": "-0.0402672188339884",
                "net_stuff": "0.105375153631393",
                "net_pass_sr": "0.00411158569869774",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "-0.0573444200117366",
                "net_sr": "0.0054094120060485",
                "net_stuff": "0.0210511517371011",
                "net_pass_sr": "0.031063981613051",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.19631878841849898
    },
    {
        "id": 638,
        "name": "Joe Palcic",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000496519743598749
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2005": {
                "college": "Indiana",
                "position": "DC"
            },
            "2006": {
                "college": "Indiana",
                "position": "DC"
            },
            "2007": {
                "college": "Indiana",
                "position": "DC"
            },
            "2008": {
                "college": "Indiana",
                "position": "DC"
            },
            "2009": {
                "college": "Indiana",
                "position": "DC"
            },
            "2010": {
                "college": "Indiana",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "-0.0364243192319213",
                "net_sr": "-0.0515975692069685",
                "net_stuff": "0.041861083671349",
                "net_pass_sr": "-0.0454319410306617",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0364243192319213
    },
    {
        "id": 639,
        "name": "Doug Mallory",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00266903850263006
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "LSU",
                "position": "DC"
            },
            "2009": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2010": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2011": {
                "college": "Indiana",
                "position": "DC"
            },
            "2012": {
                "college": "Indiana",
                "position": "DC"
            },
            "2013": {
                "college": "Indiana",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "-0.0244617998238773",
                "net_sr": "0.000471460716234606",
                "net_stuff": "0.00945918525756931",
                "net_pass_sr": "0.0290346186206505",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.0348671843057334",
                "net_sr": "-0.0346131835790141",
                "net_stuff": "-0.0450663224583663",
                "net_pass_sr": "-0.0487741660807534",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "-0.0807135136053155",
                "net_sr": "-0.060365789315048",
                "net_stuff": "0.0373797532229272",
                "net_pass_sr": "-0.0459051319443757",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.14004249773492622
    },
    {
        "id": 640,
        "name": "Mike Ekeler",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0015245051575957
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Indiana",
                "position": "DC"
            },
            "2012": {
                "college": "Indiana",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "North Texas",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "-0.00815924874853916",
                "net_sr": "0.0049443602799627",
                "net_stuff": "0.00673089549953237",
                "net_pass_sr": "0.0408753085048516",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "0.0261585118839744",
                "net_sr": "0.0138863897753232",
                "net_stuff": "0.0234557199795325",
                "net_pass_sr": "0.0568970984294679",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.01799926313543524
    },
    {
        "id": 641,
        "name": "Mark Hagen",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000356292518277793
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Indiana",
                "position": "DC"
            },
            "2018": {
                "college": "Indiana",
                "position": "DC"
            },
            "2019": {
                "college": "Indiana",
                "position": "DC"
            },
            "2020": false,
            "2021": {
                "college": "Purdue",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.0172747275308921",
                "net_sr": "0.0079818884474645",
                "net_stuff": "0.0183319339764598",
                "net_pass_sr": "0.0177294433524386",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "0.0441780658224482",
                "net_sr": "0.0472863084803918",
                "net_stuff": "0.0217338084325735",
                "net_pass_sr": "0.0628210720577133",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.06145279335334031
    },
    {
        "id": 642,
        "name": "Kevin Peoples",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000733100811896819
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Indiana",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.020745860748193",
                "net_sr": "0.00969380353876848",
                "net_stuff": "0.0544230477467386",
                "net_pass_sr": "-0.0160287095998181",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.020745860748193
    },
    {
        "id": 643,
        "name": "Charlton Warren",
        "community": 40,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000733100811896938
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Indiana",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "-0.051045064205736",
                "net_sr": "-0.0418256236883003",
                "net_stuff": "0.00532639528321324",
                "net_pass_sr": "-0.0645544841892752",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.051045064205736
    },
    {
        "id": 644,
        "name": "Norm Parker",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00164150006802555
        },
        "positions": {
            "2000": {
                "college": "Iowa",
                "position": "DC"
            },
            "2001": {
                "college": "Iowa",
                "position": "DC"
            },
            "2002": {
                "college": "Iowa",
                "position": "DC"
            },
            "2003": {
                "college": "Iowa",
                "position": "DC"
            },
            "2004": {
                "college": "Iowa",
                "position": "DC"
            },
            "2005": {
                "college": "Iowa",
                "position": "DC"
            },
            "2006": {
                "college": "Iowa",
                "position": "DC"
            },
            "2007": {
                "college": "Iowa",
                "position": "DC"
            },
            "2008": {
                "college": "Iowa",
                "position": "DC"
            },
            "2009": {
                "college": "Iowa",
                "position": "DC"
            },
            "2010": {
                "college": "Iowa",
                "position": "DC"
            },
            "2011": {
                "college": "Iowa",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Iowa",
                "role": "OC",
                "net_ppa": "0.0935738070311593",
                "net_sr": "0.0911558873187056",
                "net_stuff": "-0.0866843892902557",
                "net_pass_sr": "0.0350389829186286",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0935738070311593
    },
    {
        "id": 645,
        "name": "Phil Parker",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00136791672335459
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Iowa",
                "position": "DC"
            },
            "2013": {
                "college": "Iowa",
                "position": "DC"
            },
            "2014": {
                "college": "Iowa",
                "position": "DC"
            },
            "2015": {
                "college": "Iowa",
                "position": "DC"
            },
            "2016": {
                "college": "Iowa",
                "position": "DC"
            },
            "2017": {
                "college": "Iowa",
                "position": "DC"
            },
            "2018": {
                "college": "Iowa",
                "position": "DC"
            },
            "2019": {
                "college": "Iowa",
                "position": "DC"
            },
            "2020": {
                "college": "Iowa",
                "position": "DC"
            },
            "2021": {
                "college": "Iowa",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Iowa",
                "role": "OC",
                "net_ppa": "0.15868435226706",
                "net_sr": "0.0306107743341482",
                "net_stuff": "0.0243050028849522",
                "net_pass_sr": "0.025289291267777",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.15868435226706
    },
    {
        "id": 646,
        "name": "Wayne Bolt",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000175073183529828
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Troy",
                "position": "DC"
            },
            "2002": {
                "college": "Troy",
                "position": "DC"
            },
            "2003": {
                "college": "UAB",
                "position": "DC"
            },
            "2004": {
                "college": "UAB",
                "position": "DC"
            },
            "2005": {
                "college": "UAB",
                "position": "DC"
            },
            "2006": false,
            "2007": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2008": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Troy",
                "position": "DC"
            },
            "2014": {
                "college": "Troy",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Iowa State",
                "role": "OC",
                "net_ppa": "0.0044200780714504",
                "net_sr": "-0.00276263736325494",
                "net_stuff": "-0.00647700043541025",
                "net_pass_sr": "-0.0373832354765282",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0044200780714504
    },
    {
        "id": 647,
        "name": "Wally Burnham",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000635771240914705
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "South Florida",
                "position": "DC"
            },
            "2002": {
                "college": "South Florida",
                "position": "DC"
            },
            "2003": {
                "college": "South Florida",
                "position": "DC"
            },
            "2004": {
                "college": "South Florida",
                "position": "DC"
            },
            "2005": {
                "college": "South Florida",
                "position": "DC"
            },
            "2006": {
                "college": "South Florida",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2010": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2011": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2012": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2013": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2014": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2015": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Iowa State",
                "role": "OC",
                "net_ppa": "-0.0168090502116697",
                "net_sr": "0.00222328232390268",
                "net_stuff": "-0.00677162256998923",
                "net_pass_sr": "0.00712884570431005",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0168090502116697
    },
    {
        "id": 648,
        "name": "Jon Heacock",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000627474397356659
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Toledo",
                "position": "DC"
            },
            "2015": {
                "college": "Toledo",
                "position": "DC"
            },
            "2016": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2017": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2018": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2019": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2020": {
                "college": "Iowa State",
                "position": "DC"
            },
            "2021": {
                "college": "Iowa State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Iowa State",
                "role": "OC",
                "net_ppa": "0.0404513986201735",
                "net_sr": "0.0521303443609187",
                "net_stuff": "-0.0434294982200195",
                "net_pass_sr": "0.0348025940783689",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "-0.00717077081275894",
                "net_sr": "0.0339071704645718",
                "net_stuff": "-0.0542181350930775",
                "net_pass_sr": "0.0545341572163296",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.03328062780741456
    },
    {
        "id": 650,
        "name": "Tom Hayes",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000772631029781923
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Kansas",
                "position": "DC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Stanford",
                "position": "DC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2013": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2014": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2015": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2016": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2017": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "0.186814244725636",
                "net_sr": "0.0282402042447397",
                "net_stuff": "-0.0206996043635438",
                "net_pass_sr": "0.0352048579600196",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.186814244725636
    },
    {
        "id": 651,
        "name": "Bill Young",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.00197052282644898
        },
        "positions": {
            "2000": {
                "college": "USC",
                "position": "DC"
            },
            "2001": false,
            "2002": {
                "college": "Kansas",
                "position": "DC"
            },
            "2003": {
                "college": "Kansas",
                "position": "DC"
            },
            "2004": {
                "college": "Kansas",
                "position": "DC"
            },
            "2005": {
                "college": "Kansas",
                "position": "DC"
            },
            "2006": {
                "college": "Kansas",
                "position": "DC"
            },
            "2007": {
                "college": "Kansas",
                "position": "DC"
            },
            "2008": {
                "college": "Miami",
                "position": "DC"
            },
            "2009": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2010": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2011": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2012": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2016": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2017": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2018": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "-0.0342993532308797",
                "net_sr": "-0.0190190767893444",
                "net_stuff": "0.0294150796941015",
                "net_pass_sr": "0.0144873819863907",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "0.0583097709366766",
                "net_sr": "0.0188647394374332",
                "net_stuff": "0.0177717601841904",
                "net_pass_sr": "0.0242677686926027",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "-0.102220281476543",
                "net_sr": "-0.0397674215810019",
                "net_stuff": "0.054542424802087",
                "net_pass_sr": "-0.0265166196180269",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0782098637707461
    },
    {
        "id": 652,
        "name": "Clint Bowen",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 6,
            "eigen": 0.00103853162030962
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Kansas",
                "position": "DC"
            },
            "2008": {
                "college": "Kansas",
                "position": "DC"
            },
            "2009": {
                "college": "Kansas",
                "position": "DC"
            },
            "2010": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2011": {
                "college": "North Texas",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Kansas",
                "position": "DC"
            },
            "2015": {
                "college": "Kansas",
                "position": "DC"
            },
            "2016": {
                "college": "Kansas",
                "position": "DC"
            },
            "2017": {
                "college": "Kansas",
                "position": "DC"
            },
            "2018": {
                "college": "Kansas",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "North Texas",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.135242521206363",
                "net_sr": "-0.0824473711252436",
                "net_stuff": "0.0632871968452809",
                "net_pass_sr": "-0.10183450861748",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "-0.0999377964755529",
                "net_sr": "-0.00315443600588677",
                "net_stuff": "0.00350352045059751",
                "net_pass_sr": "0.000206733721901664",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "-0.043390391034167",
                "net_sr": "-0.011047094370248",
                "net_stuff": "-0.0484585787898957",
                "net_pass_sr": "-0.00602251100760826",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2785707087160829
    },
    {
        "id": 653,
        "name": "Buddy Wyatt",
        "community": 30,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000248605542254711
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Kansas",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.0697627042868992",
                "net_sr": "-0.0770258454353115",
                "net_stuff": "0.0321151954103412",
                "net_pass_sr": "-0.0773112193188357",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0697627042868992
    },
    {
        "id": 654,
        "name": "Vic Shealy",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000141508970755003
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "UNLV",
                "position": "DC"
            },
            "2006": {
                "college": "UNLV",
                "position": "DC"
            },
            "2007": {
                "college": "UNLV",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Kansas",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.0321944321946198",
                "net_sr": "0.0294142607866899",
                "net_stuff": "-0.102529325974848",
                "net_pass_sr": "-0.0361498075500125",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "0.11758442157255",
                "net_sr": "-0.0139030374682091",
                "net_stuff": "-0.0131515465828478",
                "net_pass_sr": "-0.0658024561827427",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0853899893779302
    },
    {
        "id": 655,
        "name": "Dave Campo",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000662884575752475
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Kansas",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.026394290233913",
                "net_sr": "-0.0115064617950812",
                "net_stuff": "-0.0321111484676728",
                "net_pass_sr": "-0.0535475853225921",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.026394290233913
    },
    {
        "id": 656,
        "name": "Kenny Perry",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000374320875624074
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Kansas",
                "position": "DC"
            },
            "2016": {
                "college": "Kansas",
                "position": "DC"
            },
            "2017": {
                "college": "Kansas",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.119585004275256",
                "net_sr": "-0.0447671678660163",
                "net_stuff": "-0.00959396672605525",
                "net_pass_sr": "-0.0286840685561904",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.119585004275256
    },
    {
        "id": 657,
        "name": "Bob Elliott",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00101010970376845
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2003": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2004": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2005": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2006": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2007": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "0.0413405499313172",
                "net_sr": "0.00635408775559887",
                "net_stuff": "0.022659043854696",
                "net_pass_sr": "0.0187633190274861",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0413405499313172
    },
    {
        "id": 658,
        "name": "Raheem Morris",
        "community": 16,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000113208912137697
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "-0.0457813714666797",
                "net_sr": "-0.0165611930442439",
                "net_stuff": "0.0150021710811984",
                "net_pass_sr": "-0.0601013488337432",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0457813714666797
    },
    {
        "id": 659,
        "name": "Tim Tibesar",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00158475754913
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2008": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Purdue",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2019": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2020": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2021": {
                "college": "Oregon State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "0.238073360822691",
                "net_sr": "0.0669796084208997",
                "net_stuff": "-0.0501386423946623",
                "net_pass_sr": "0.0547894498121933",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon State",
                "role": "OC",
                "net_ppa": "-0.177225284474414",
                "net_sr": "-0.0637246153741381",
                "net_stuff": "0.0845324920508355",
                "net_pass_sr": "-0.0593085475811536",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "0.00717439436580951",
                "net_sr": "-0.016735447916736",
                "net_stuff": "0.0207729705432764",
                "net_pass_sr": "-0.0434345796188059",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.06802247071408653
    },
    {
        "id": 660,
        "name": "Chris Cosh",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00070354207605496
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Maryland",
                "position": "DC"
            },
            "2007": {
                "college": "Maryland",
                "position": "DC"
            },
            "2008": {
                "college": "Maryland",
                "position": "DC"
            },
            "2009": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2010": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2011": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2012": {
                "college": "South Florida",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": {
                "college": "North Texas",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "-0.0153087589289566",
                "net_sr": "-0.016928313354564",
                "net_stuff": "0.0351108818910031",
                "net_pass_sr": "-0.00897997266549344",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "0.00949041392607786",
                "net_sr": "-0.0229233050421734",
                "net_stuff": "0.00777310877682852",
                "net_pass_sr": "0.00479032741181079",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "-0.226539525643761",
                "net_sr": "-0.0830964627758319",
                "net_stuff": "0.071360970221383",
                "net_pass_sr": "-0.0448959135465202",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "-0.115352576296699",
                "net_sr": "-0.04854183979877",
                "net_stuff": "0.0338708248137566",
                "net_pass_sr": "-0.0937953189840963",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.34771044694333875
    },
    {
        "id": 661,
        "name": "Blake Seiler",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000132682149709288
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "Old Dominion",
                "position": "DC"
            },
            "2021": {
                "college": "Old Dominion",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "0.0103589972548158",
                "net_sr": "-0.025597608094778",
                "net_stuff": "0.019531063575393",
                "net_pass_sr": "-0.00472999081701497",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Old Dominion",
                "role": "OC",
                "net_ppa": "0.0546656032474672",
                "net_sr": "0.0205846446191673",
                "net_stuff": "0.0690647912034639",
                "net_pass_sr": "0.03061433962211",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.065024600502283
    },
    {
        "id": 662,
        "name": "Scottie Hazelton",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.0132012614717452
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Nevada",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2018": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2019": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2020": {
                "college": "Michigan State",
                "position": "DC"
            },
            "2021": {
                "college": "Michigan State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "-0.0191700219663841",
                "net_sr": "-0.0294233490139327",
                "net_stuff": "-0.00213756135437715",
                "net_pass_sr": "-0.0245659865284004",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "0.135042073234704",
                "net_sr": "0.102605604144926",
                "net_stuff": "-0.0499143199149955",
                "net_pass_sr": "0.101643533828456",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "0.022557517354942",
                "net_sr": "0.00303006332605427",
                "net_stuff": "0.0206314557596618",
                "net_pass_sr": "0.00776960807385668",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "-0.0904401679850528",
                "net_sr": "-0.0343221116040772",
                "net_stuff": "-0.000551576425172673",
                "net_pass_sr": "-0.0419751644432672",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.047989400638209084
    },
    {
        "id": 663,
        "name": "Joe Klanderman",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000055751282085827
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Kansas State",
                "position": "DC"
            },
            "2021": {
                "college": "Kansas State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "-0.0311602124356047",
                "net_sr": "0.0246398389718362",
                "net_stuff": "0.00667079852544974",
                "net_pass_sr": "-0.00870684996563009",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0311602124356047
    },
    {
        "id": 664,
        "name": "Mike Dietzel",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 8.85364560182193e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Kent State",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "-0.178861134649712",
                "net_sr": "-0.0723033949658469",
                "net_stuff": "0.0263432223468874",
                "net_pass_sr": "-0.134631632324943",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.178861134649712
    },
    {
        "id": 665,
        "name": "Ben Needham",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000195802676474537
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Kent State",
                "position": "DC"
            },
            "2017": {
                "college": "Kent State",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kent State",
                "role": "OC",
                "net_ppa": "-0.0707115167785788",
                "net_sr": "0.00161117286905171",
                "net_stuff": "-0.0340689294896792",
                "net_pass_sr": "-0.00855429818466097",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0707115167785788
    },
    {
        "id": 666,
        "name": "Jeff Burrow",
        "community": 11,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000195802676474574
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Kent State",
                "position": "DC"
            },
            "2017": {
                "college": "Kent State",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kent State",
                "role": "OC",
                "net_ppa": "-0.0707115167785788",
                "net_sr": "0.00161117286905171",
                "net_stuff": "-0.0340689294896792",
                "net_pass_sr": "-0.00855429818466097",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0707115167785788
    },
    {
        "id": 667,
        "name": "Tom Kaufman",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000447028259108741
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Kent State",
                "position": "DC"
            },
            "2019": {
                "college": "Kent State",
                "position": "DC"
            },
            "2020": {
                "college": "Kent State",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Kent State",
                "role": "OC",
                "net_ppa": "0.0250983579229919",
                "net_sr": "0.0473935463179621",
                "net_stuff": "-0.000393714997711708",
                "net_pass_sr": "0.0767472801092429",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0250983579229919
    },
    {
        "id": 670,
        "name": "Mike Archer",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000437433219151997
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2004": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2005": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2006": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2007": {
                "college": "NC State",
                "position": "DC"
            },
            "2008": {
                "college": "NC State",
                "position": "DC"
            },
            "2009": {
                "college": "NC State",
                "position": "DC"
            },
            "2010": {
                "college": "NC State",
                "position": "DC"
            },
            "2011": {
                "college": "NC State",
                "position": "DC"
            },
            "2012": {
                "college": "NC State",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "-0.00456517002905853",
                "net_sr": "-0.037065777404547",
                "net_stuff": "0.0584405913524562",
                "net_pass_sr": "-0.0306011801387157",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00456517002905853
    },
    {
        "id": 671,
        "name": "Steve Brown",
        "community": 28,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000559752267490681
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2008": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2009": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2010": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2011": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "0.0413019881432401",
                "net_sr": "-0.0332424004751829",
                "net_stuff": "-0.0273213567115451",
                "net_pass_sr": "-0.0468106485254989",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0413019881432401
    },
    {
        "id": 672,
        "name": "Brad White",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000798929844665972
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2020": {
                "college": "Kentucky",
                "position": "DC"
            },
            "2021": {
                "college": "Kentucky",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "0.0475846902702964",
                "net_sr": "0.0133427572314481",
                "net_stuff": "-0.0302676043211111",
                "net_pass_sr": "-0.0299956199585495",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0475846902702964
    },
    {
        "id": 673,
        "name": "Jon Sumrall",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000271913294297714
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Tulane",
                "position": "DC"
            },
            "2013": {
                "college": "Tulane",
                "position": "DC"
            },
            "2014": {
                "college": "Tulane",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Kentucky",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "-0.0208288177777372",
                "net_sr": "-0.0193178839894561",
                "net_stuff": "-0.0112579215494648",
                "net_pass_sr": "-0.0754418527407033",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "0.00503981842986229",
                "net_sr": "0.0275608100445887",
                "net_stuff": "-0.098627126071284",
                "net_pass_sr": "0.00632050410105073",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.01578899934787491
    },
    {
        "id": 674,
        "name": "Robert Wimberly",
        "community": 38,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000257115956288354
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Liberty",
                "position": "DC"
            },
            "2019": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2020": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Liberty",
                "role": "OC",
                "net_ppa": "-0.0544115604914979",
                "net_sr": "-0.0145383375117602",
                "net_stuff": "0.0813554487222229",
                "net_pass_sr": "0.0428877005347594",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "0.152992546513254",
                "net_sr": "0.0850941730891104",
                "net_stuff": "-0.0832894700118402",
                "net_pass_sr": "0.0471140129393772",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0985809860217561
    },
    {
        "id": 675,
        "name": "Vantz Singletary",
        "community": 30,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000248605542254696
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Liberty",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Liberty",
                "role": "OC",
                "net_ppa": "-0.256603523642632",
                "net_sr": "-0.0482588976819031",
                "net_stuff": "0.0718915965031392",
                "net_pass_sr": "-0.0375485188968335",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.256603523642632
    },
    {
        "id": 676,
        "name": "Scott Symons",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000544419353373865
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Liberty",
                "position": "DC"
            },
            "2020": {
                "college": "Liberty",
                "position": "DC"
            },
            "2021": {
                "college": "Liberty",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Liberty",
                "role": "OC",
                "net_ppa": "-0.0338427966021058",
                "net_sr": "-0.0184293745650775",
                "net_stuff": "0.0254320207254716",
                "net_pass_sr": "0.001665628762403",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0338427966021058
    },
    {
        "id": 678,
        "name": "Brent Pry",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000294750468670735
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2003": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2012": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2013": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Penn State",
                "position": "DC"
            },
            "2017": {
                "college": "Penn State",
                "position": "DC"
            },
            "2018": {
                "college": "Penn State",
                "position": "DC"
            },
            "2019": {
                "college": "Penn State",
                "position": "DC"
            },
            "2020": {
                "college": "Penn State",
                "position": "DC"
            },
            "2021": {
                "college": "Penn State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "-0.0520601885539889",
                "net_sr": "-0.00164521309052151",
                "net_stuff": "0.0203610843372667",
                "net_pass_sr": "0.0179214097911972",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "0.0236855633516158",
                "net_sr": "0.00374190451355449",
                "net_stuff": "-0.0340571644688857",
                "net_pass_sr": "0.0103215915520619",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.028374625202373098
    },
    {
        "id": 679,
        "name": "Greg Stewart",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000379258778230358
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2012": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2019": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2020": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "0.0407891836573266",
                "net_sr": "0.0213152740633847",
                "net_stuff": "-0.051574327383562",
                "net_pass_sr": "-0.00608258498888026",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "-0.0378334269160908",
                "net_sr": "-0.0219857302143451",
                "net_stuff": "-0.0177462932846006",
                "net_pass_sr": "-0.0623056420354663",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0029557567412358057
    },
    {
        "id": 680,
        "name": "James Willis",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000078337265919935
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2014": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.093327810794647",
                "net_sr": "-0.00707157247259693",
                "net_stuff": "-0.00156596392918246",
                "net_pass_sr": "-0.0229127205966621",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "-0.0235151139389224",
                "net_sr": "0.00539906178941196",
                "net_stuff": "0.0121341774593372",
                "net_pass_sr": "-0.0378542278642619",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.1168429247335694
    },
    {
        "id": 681,
        "name": "Melvin Smith",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000309084552946377
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2016": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "0.16686385213936",
                "net_sr": "0.0451673138143083",
                "net_stuff": "-0.0380920389553422",
                "net_pass_sr": "0.0571655566154291",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.16686385213936
    },
    {
        "id": 682,
        "name": "Mike Lucas",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000154542276473107
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.127620262026677",
                "net_sr": "-0.0939628599683426",
                "net_stuff": "0.0632395806873433",
                "net_pass_sr": "-0.0578088197956776",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.127620262026677
    },
    {
        "id": 683,
        "name": "Patrick Toney",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000863228337603463
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Louisiana",
                "position": "DC"
            },
            "2021": {
                "college": "Louisiana",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.170340028328028",
                "net_sr": "-0.0659034095514323",
                "net_stuff": "0.0978608408080026",
                "net_pass_sr": "0.0130763710137574",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.170340028328028
    },
    {
        "id": 684,
        "name": "Mike Collins",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 4.22373464430593e-7
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2017": false,
            "2018": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2019": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2020": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "-0.197281239965664",
                "net_sr": "-0.0761680370019903",
                "net_stuff": "0.0483809159699816",
                "net_pass_sr": "-0.0708794927797011",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.197281239965664
    },
    {
        "id": 685,
        "name": "Kim Dameron",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000473996154376595
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2006": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2007": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "-0.203290923197778",
                "net_sr": "-0.181764143064057",
                "net_stuff": "0.474067796610169",
                "net_pass_sr": "-0.150911208389906",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "0.0410286933980547",
                "net_sr": "0.00609625345085135",
                "net_stuff": "-0.0419812149144416",
                "net_pass_sr": "-0.00616770967602842",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1622622297997233
    },
    {
        "id": 686,
        "name": "Manny Michel",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000105513094800021
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "-0.155151976234529",
                "net_sr": "-0.0830859911379566",
                "net_stuff": "0.178934721938602",
                "net_pass_sr": "-0.0921193475918592",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.155151976234529
    },
    {
        "id": 687,
        "name": "Phil Elmassian",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00029342488652104
        },
        "positions": {
            "2000": {
                "college": "LSU",
                "position": "DC"
            },
            "2001": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "UMass",
                "position": "DC"
            },
            "2013": {
                "college": "UMass",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "0.162131540530709",
                "net_sr": "0.0908018938440362",
                "net_stuff": "-0.175271679369052",
                "net_pass_sr": "0.149158457616399",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UMass",
                "role": "OC",
                "net_ppa": "-0.0345898476382118",
                "net_sr": "-0.0199291121644563",
                "net_stuff": "0.0309832735996306",
                "net_pass_sr": "-0.0645088302932853",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1275416928924972
    },
    {
        "id": 688,
        "name": "Troy Reffett",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.000753006851573598
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "UTEP",
                "position": "DC"
            },
            "2003": {
                "college": "UTEP",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2009": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2010": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2011": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2012": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2013": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2014": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2015": false,
            "2016": {
                "college": "North Texas",
                "position": "DC"
            },
            "2017": {
                "college": "North Texas",
                "position": "DC"
            },
            "2018": {
                "college": "North Texas",
                "position": "DC"
            },
            "2019": {
                "college": "North Texas",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "-0.0336807675491553",
                "net_sr": "-0.0498839573355953",
                "net_stuff": "0.000190908537663431",
                "net_pass_sr": "0.00873516011650793",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "0.206204573100667",
                "net_sr": "0.0525641081378993",
                "net_stuff": "-0.0763834482681691",
                "net_pass_sr": "0.0260391217671234",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "0.0290584138372776",
                "net_sr": "0.0378593412537063",
                "net_stuff": "-0.00691369051652227",
                "net_pass_sr": "0.0690549796959558",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.20158221938878929
    },
    {
        "id": 689,
        "name": "Adam Waugh",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000166973242712758
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "-0.0789886309332371",
                "net_sr": "0.00564747012691813",
                "net_stuff": "0.00313471645672031",
                "net_pass_sr": "0.000888652503957532",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0789886309332371
    },
    {
        "id": 690,
        "name": "Scott Stoker",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000296850795371547
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "UTEP",
                "position": "DC"
            },
            "2014": {
                "college": "UTEP",
                "position": "DC"
            },
            "2015": {
                "college": "UTEP",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Louisiana Monroe",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "-0.103579107117862",
                "net_sr": "-0.0535738369509398",
                "net_stuff": "0.0118584061504498",
                "net_pass_sr": "-0.0598639972889192",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "0.0423555098651794",
                "net_sr": "-0.0387106696183482",
                "net_stuff": "-0.00822941889033471",
                "net_pass_sr": "-0.0369775571839966",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0612235972526826
    },
    {
        "id": 691,
        "name": "Tom Masella",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000061232318662154
        },
        "positions": {
            "2000": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "UMass",
                "position": "DC"
            },
            "2015": {
                "college": "UMass",
                "position": "DC"
            },
            "2016": {
                "college": "UMass",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UMass",
                "role": "OC",
                "net_ppa": "0.131610127215776",
                "net_sr": "0.0209798831253983",
                "net_stuff": "-0.0131809198274686",
                "net_pass_sr": "0.0214372277917819",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.131610127215776
    },
    {
        "id": 692,
        "name": "Randy Bates",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000417544720138723
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2004": false,
            "2005": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2019": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2020": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2021": {
                "college": "Pittsburgh",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "-0.139569807862017",
                "net_sr": "-0.0424803522408093",
                "net_stuff": "0.0280298819839639",
                "net_pass_sr": "-0.0524399079272294",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.139569807862017
    },
    {
        "id": 693,
        "name": "George Darlington",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000243767668334658
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "-0.147345624013019",
                "net_sr": "-0.149051047120419",
                "net_stuff": "0.0762282441066314",
                "net_pass_sr": "0.0303030303030303",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.147345624013019
    },
    {
        "id": 694,
        "name": "Tommy Spangler",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000526968347601259
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2008": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2009": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2010": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2011": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2012": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "0.0405799755479823",
                "net_sr": "-0.0282593258786948",
                "net_stuff": "-0.00457919153073555",
                "net_pass_sr": "-0.0351075504809567",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0405799755479823
    },
    {
        "id": 695,
        "name": "Blake Baker",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000154422422817654
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2016": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2017": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2018": {
                "college": "Louisiana Tech",
                "position": "DC"
            },
            "2019": {
                "college": "Miami",
                "position": "DC"
            },
            "2020": {
                "college": "Miami",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "-0.0672898474072726",
                "net_sr": "-0.0380009871035823",
                "net_stuff": "-0.016308853721823",
                "net_pass_sr": "-0.0232197955709557",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "-0.0950140971131323",
                "net_sr": "-0.013279021609351",
                "net_stuff": "0.0167981413039513",
                "net_pass_sr": "-0.0120889178602933",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.16230394452040492
    },
    {
        "id": 697,
        "name": "Kevin Wolthausen",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000153766392844741
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Louisville",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.0375286953723835",
                "net_sr": "-0.00377833753148615",
                "net_stuff": "0.00124302212579386",
                "net_pass_sr": "-0.023680358219237",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0375286953723835
    },
    {
        "id": 698,
        "name": "Vance Bedford",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00135784037562095
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2006": {
                "college": "Oklahoma State",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Louisville",
                "position": "DC"
            },
            "2011": {
                "college": "Louisville",
                "position": "DC"
            },
            "2012": {
                "college": "Louisville",
                "position": "DC"
            },
            "2013": {
                "college": "Louisville",
                "position": "DC"
            },
            "2014": {
                "college": "Texas",
                "position": "DC"
            },
            "2015": {
                "college": "Texas",
                "position": "DC"
            },
            "2016": {
                "college": "Texas",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.0756408021249913",
                "net_sr": "0.0416045912539304",
                "net_stuff": "-0.0725060018098136",
                "net_pass_sr": "-0.0117208856669472",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.185163946774275",
                "net_sr": "0.0692254323552355",
                "net_stuff": "-0.0889934748570702",
                "net_pass_sr": "0.00891964174696624",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.2608047488992663
    },
    {
        "id": 699,
        "name": "Cort Dennison",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000987462964373716
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Louisville",
                "position": "DC"
            },
            "2020": {
                "college": "Louisville",
                "position": "DC"
            },
            "2021": {
                "college": "Louisville",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "-0.0312824798431238",
                "net_sr": "0.0200431720552101",
                "net_stuff": "0.0119407220447859",
                "net_pass_sr": "0.0283486169086525",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0312824798431238
    },
    {
        "id": 700,
        "name": "Daronte Jones",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000127762765239168
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "LSU",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.0205226668318753",
                "net_sr": "-0.00947957007542449",
                "net_stuff": "-0.0218747483730524",
                "net_pass_sr": "-0.0471841711954706",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0205226668318753
    },
    {
        "id": 703,
        "name": "Steve Dunlap",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00002829503578604
        },
        "positions": {
            "2000": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2005": {
                "college": "NC State",
                "position": "DC"
            },
            "2006": {
                "college": "NC State",
                "position": "DC"
            },
            "2007": {
                "college": "Marshall",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "0.0512838184456453",
                "net_sr": "0.0155535830536022",
                "net_stuff": "-0.0523457615943496",
                "net_pass_sr": "0.00908537873771698",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0512838184456453
    },
    {
        "id": 704,
        "name": "Chris Rippon",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000433721295439313
        },
        "positions": {
            "2000": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2001": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2002": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2003": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Marshall",
                "position": "DC"
            },
            "2011": {
                "college": "Marshall",
                "position": "DC"
            },
            "2012": {
                "college": "Marshall",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "0.0283394710020287",
                "net_sr": "0.00917812669902279",
                "net_stuff": "-0.0338273801148922",
                "net_pass_sr": "-0.0126169020588912",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0283394710020287
    },
    {
        "id": 705,
        "name": "J.C. Price",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00002135732258027
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Marshall",
                "position": "DC"
            },
            "2020": {
                "college": "Marshall",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "0.0464703895053216",
                "net_sr": "0.000272242524389676",
                "net_stuff": "0.059282281076408",
                "net_pass_sr": "-0.00883139596484017",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0464703895053216
    },
    {
        "id": 706,
        "name": "Lance Guidry",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000842211353033088
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2012": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Marshall",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "-0.0528990885056957",
                "net_sr": "-0.0273571764190397",
                "net_stuff": "0.0254599484162041",
                "net_pass_sr": "-0.00102550873766305",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "0.0899427852608654",
                "net_sr": "0.0879926888967691",
                "net_stuff": "-0.0898918289310961",
                "net_pass_sr": "0.0959239364724764",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0370436967551697
    },
    {
        "id": 708,
        "name": "Todd Bradford",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000514788653966042
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "BYU",
                "position": "OC"
            },
            "2004": {
                "college": "BYU",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2009": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2010": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2011": {
                "college": "Maryland",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "0.0246409642704447",
                "net_sr": "0.00698876525282743",
                "net_stuff": "0.0116865360978905",
                "net_pass_sr": "-0.0066443218305382",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.0838012622215897",
                "net_sr": "0.00282542894698623",
                "net_stuff": "-0.0313209237691575",
                "net_pass_sr": "-0.00854744751206576",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1084422264920344
    },
    {
        "id": 709,
        "name": "Keith Dudzinski",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000360951991116394
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Maryland",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.112265911576392",
                "net_sr": "-0.0348445989945017",
                "net_stuff": "-0.00706209096147073",
                "net_pass_sr": "-0.0389566302221439",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.112265911576392
    },
    {
        "id": 710,
        "name": "Jimmy Brumbaugh",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000447203392997581
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Maryland",
                "position": "DC"
            },
            "2018": {
                "college": "Maryland",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.012095489843312",
                "net_sr": "-0.0120787523062221",
                "net_stuff": "0.03128571121946",
                "net_pass_sr": "-0.00466360572521002",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.012095489843312
    },
    {
        "id": 711,
        "name": "Brian Williams",
        "community": 5,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000421336694004538
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Maryland",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "-0.0181412633756897",
                "net_sr": "0.00257636523003679",
                "net_stuff": "0.015013254831848",
                "net_pass_sr": "-0.0109001603227325",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0181412633756897
    },
    {
        "id": 712,
        "name": "Ed Pinkham",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000169932246663139
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2010": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2014": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2015": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2016": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2017": {
                "college": "UMass",
                "position": "DC"
            },
            "2018": {
                "college": "UMass",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "-0.00325615735511665",
                "net_sr": "-0.0185760995018808",
                "net_stuff": "-0.0343551869925557",
                "net_pass_sr": "-0.0454037034278936",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UMass",
                "role": "OC",
                "net_ppa": "-0.00653172242014183",
                "net_sr": "0.00813327123097535",
                "net_stuff": "-0.0316682855969742",
                "net_pass_sr": "0.0531179737497636",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "-0.0943313471659959",
                "net_sr": "-0.0253813274160785",
                "net_stuff": "0.0196642851667984",
                "net_pass_sr": "-0.00324053633807858",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.10411922694125438
    },
    {
        "id": 713,
        "name": "Aazaar Abdul-Rahim",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000512604600942232
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "UMass",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UMass",
                "role": "OC",
                "net_ppa": "-0.218420382466928",
                "net_sr": "-0.0861103940794925",
                "net_stuff": "0.112042832049176",
                "net_pass_sr": "-0.119286930826331",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.218420382466928
    },
    {
        "id": 714,
        "name": "Tommy Restivo",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000153781380282961
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "UMass",
                "position": "DC"
            },
            "2020": {
                "college": "UMass",
                "position": "DC"
            },
            "2021": {
                "college": "UMass",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "UMass",
                "role": "OC",
                "net_ppa": "-0.148237036333786",
                "net_sr": "-0.0357686618399399",
                "net_stuff": "-0.0508819103895484",
                "net_pass_sr": "-0.0294033001843665",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.148237036333786
    },
    {
        "id": 716,
        "name": "Joe Lee Dunn",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000420900289259442
        },
        "positions": {
            "2000": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2001": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2002": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2003": {
                "college": "Memphis",
                "position": "DC"
            },
            "2004": {
                "college": "Memphis",
                "position": "DC"
            },
            "2005": {
                "college": "Memphis",
                "position": "DC"
            },
            "2006": {
                "college": "Memphis",
                "position": "DC"
            },
            "2007": false,
            "2008": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.0336193234939513",
                "net_sr": "-0.0429231785163988",
                "net_stuff": "0.0225989697269071",
                "net_pass_sr": "-0.037033874623529",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "0.0409159089273393",
                "net_sr": "-0.01582638214727",
                "net_stuff": "-0.00348595857632003",
                "net_pass_sr": "0.0279590938850415",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.007296585433388
    },
    {
        "id": 717,
        "name": "Rick Kravitz",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000665446386005992
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Memphis",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.031231301968312",
                "net_sr": "0.00120342276715618",
                "net_stuff": "0.0192765649964477",
                "net_pass_sr": "-0.0101194294619629",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.031231301968312
    },
    {
        "id": 718,
        "name": "Tim Walton",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000059108319190099
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Miami",
                "position": "DC"
            },
            "2008": {
                "college": "Memphis",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.0816428687430106",
                "net_sr": "-0.0645522839037064",
                "net_stuff": "0.0956325521801885",
                "net_pass_sr": "-0.0651307342699856",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "0.162825866470686",
                "net_sr": "0.125634973070601",
                "net_stuff": "-0.145430593187818",
                "net_pass_sr": "0.134955413466603",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0811829977276754
    },
    {
        "id": 719,
        "name": "Kenny Ingram",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000665446386005945
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Memphis",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.0274278626827931",
                "net_sr": "-0.0102325762531784",
                "net_stuff": "0.00384472528098856",
                "net_pass_sr": "-0.00309450541272976",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0274278626827931
    },
    {
        "id": 720,
        "name": "Galen Scott",
        "community": 19,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000224206511969313
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Memphis",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.110315615960044",
                "net_sr": "-0.0285088716296532",
                "net_stuff": "0.0427422292458892",
                "net_pass_sr": "-0.0178079830803566",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.110315615960044
    },
    {
        "id": 721,
        "name": "Ephraim Banda",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000113923821013498
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Miami",
                "position": "DC"
            },
            "2020": {
                "college": "Miami",
                "position": "DC"
            },
            "2021": {
                "college": "Utah State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "-0.0950140971131323",
                "net_sr": "-0.013279021609351",
                "net_stuff": "0.0167981413039513",
                "net_pass_sr": "-0.0120889178602933",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.00336799047903563",
                "net_sr": "0.0129626214548502",
                "net_stuff": "-0.0406862309392952",
                "net_pass_sr": "-0.0170958057635936",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.09164610663409667
    },
    {
        "id": 723,
        "name": "Taver Johnson",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000225344562404333
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Temple",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "0.0441543604548684",
                "net_sr": "-0.00523958874247388",
                "net_stuff": "-0.0283589078617116",
                "net_pass_sr": "0.00539398593466489",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0441543604548684
    },
    {
        "id": 724,
        "name": "Carl Reese",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000615718104130787
        },
        "positions": {
            "2000": {
                "college": "Texas",
                "position": "DC"
            },
            "2001": {
                "college": "Texas",
                "position": "DC"
            },
            "2002": {
                "college": "Texas",
                "position": "DC"
            },
            "2003": {
                "college": "Texas",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2010": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "0.0749474586710845",
                "net_sr": "0.0264276768975414",
                "net_stuff": "-0.0336467481907341",
                "net_pass_sr": "0.011622238150159",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0749474586710845
    },
    {
        "id": 725,
        "name": "Matt Pawlowski",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000885493029336227
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2015": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2016": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2017": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "-0.0254868567095023",
                "net_sr": "0.0319521089379808",
                "net_stuff": "0.0203533942197992",
                "net_pass_sr": "0.0345714923861888",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0254868567095023
    },
    {
        "id": 726,
        "name": "John Hauser",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000132823954400537
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2017": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2018": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2019": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2020": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2021": {
                "college": "Miami (OH)",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "0.0178601671937663",
                "net_sr": "0.0535629014942975",
                "net_stuff": "0.0139815187665157",
                "net_pass_sr": "0.0509344553865314",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0178601671937663
    },
    {
        "id": 727,
        "name": "Spence Nowinsky",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000088549302933588
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2019": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2020": {
                "college": "Miami (OH)",
                "position": "DC"
            },
            "2021": {
                "college": "Miami (OH)",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "0.0340060485596365",
                "net_sr": "-0.0318180710353818",
                "net_stuff": "0.0101753558025586",
                "net_pass_sr": "-0.0258764371614307",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0340060485596365
    },
    {
        "id": 729,
        "name": "Mike Macdonald",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000290824037890488
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Michigan",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "-0.00152358864001739",
                "net_sr": "0.00691249895534202",
                "net_stuff": "0.0221382011417542",
                "net_pass_sr": "-0.0020381423634061",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00152358864001739
    },
    {
        "id": 732,
        "name": "Randall McCray",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000335227261002865
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "0.0736997682614798",
                "net_sr": "-0.00792414867402058",
                "net_stuff": "-0.0202866953658949",
                "net_pass_sr": "-0.0055389586637683",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0736997682614798
    },
    {
        "id": 733,
        "name": "Steve Ellis",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000134090904401197
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2012": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2013": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2014": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "0.0868329867047171",
                "net_sr": "0.0487549696166936",
                "net_stuff": "-0.0291762842782454",
                "net_pass_sr": "0.0739295231537224",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0868329867047171
    },
    {
        "id": 734,
        "name": "Tyrone Nix",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.000224056443312603
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2002": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2003": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2004": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2008": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2009": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2010": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2011": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2012": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2013": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2014": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2015": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2016": {
                "college": "Middle Tennessee",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "0.00446485454636849",
                "net_sr": "0.00197545803010951",
                "net_stuff": "0.0201743419797613",
                "net_pass_sr": "-0.000783875703713355",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0536594595032755",
                "net_sr": "-0.0242994743826192",
                "net_stuff": "-0.0362501645805045",
                "net_pass_sr": "-0.0528337391111813",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "0.107741284428063",
                "net_sr": "0.0142558801082314",
                "net_stuff": "-0.019285251740327",
                "net_pass_sr": "0.0114556628869731",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UT San Antonio",
                "role": "OC",
                "net_ppa": "0.0255331383369223",
                "net_sr": "0.00694190064843131",
                "net_stuff": "-0.0236956093749162",
                "net_pass_sr": "0.00758062146084199",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.08407981780807829
    },
    {
        "id": 736,
        "name": "David Lockwood",
        "community": 20,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000821722033652726
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2006": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "-0.0656776297322162",
                "net_sr": "-0.0927891685400856",
                "net_stuff": "0.0952565411839279",
                "net_pass_sr": "-0.0654893345778223",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0656776297322162
    },
    {
        "id": 737,
        "name": "Ronnie Lee",
        "community": 38,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000142184330409305
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "San José State",
                "position": "DC"
            },
            "2002": {
                "college": "San José State",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2010": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.136487761546644",
                "net_sr": "0.00661328442441206",
                "net_stuff": "0.00716627220143815",
                "net_pass_sr": "0.00198543557866199",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.136487761546644
    },
    {
        "id": 738,
        "name": "Jay Sawvel",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0133152501013978
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2017": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2018": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2021": {
                "college": "Wyoming",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.0370878177819053",
                "net_sr": "0.0510111736397795",
                "net_stuff": "-0.0360519459654954",
                "net_pass_sr": "0.043857749233787",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wake Forest",
                "role": "OC",
                "net_ppa": "-0.0460851565516632",
                "net_sr": "-0.0150114506781356",
                "net_stuff": "-0.0295011399339883",
                "net_pass_sr": "-0.0238855042876158",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "-0.0401539721552471",
                "net_sr": "-0.00277855304401409",
                "net_stuff": "0.0384196331447993",
                "net_pass_sr": "0.00469615392609479",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.04915131092500501
    },
    {
        "id": 739,
        "name": "Joe Rossi",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000570994927732752
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2015": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2019": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2020": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2021": {
                "college": "Minnesota",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "-0.0529368472486765",
                "net_sr": "-0.0229568680877191",
                "net_stuff": "0.0214241835875526",
                "net_pass_sr": "-0.0487832677954229",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "-0.239368181588341",
                "net_sr": "-0.0805048270313757",
                "net_stuff": "0.0355314965235189",
                "net_pass_sr": "-0.0694823150866433",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2923050288370175
    },
    {
        "id": 740,
        "name": "Joe Harasymiak",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000175280354209956
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Minnesota",
                "position": "DC"
            },
            "2021": {
                "college": "Minnesota",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "-0.0644570917849342",
                "net_sr": "-0.0186852062244089",
                "net_stuff": "0.0276006697283195",
                "net_pass_sr": "-0.0158906868654668",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0644570917849342
    },
    {
        "id": 741,
        "name": "Bob Shoop",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000208335938096221
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2012": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2013": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2014": {
                "college": "Penn State",
                "position": "DC"
            },
            "2015": {
                "college": "Penn State",
                "position": "DC"
            },
            "2016": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2017": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2018": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2019": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.0487931717241549",
                "net_sr": "0.00834134122236513",
                "net_stuff": "-0.0177120810117205",
                "net_pass_sr": "0.00689038723655871",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "-0.00404480540070734",
                "net_sr": "0.016908676823411",
                "net_stuff": "-0.0354511166351566",
                "net_pass_sr": "0.0278111660919433",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.0647846165615729",
                "net_sr": "-0.0592025098035371",
                "net_stuff": "0.00934609545976089",
                "net_pass_sr": "-0.0131108794524714",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "0.0236855633516158",
                "net_sr": "0.00374190451355449",
                "net_stuff": "-0.0340571644688857",
                "net_pass_sr": "0.0103215915520619",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0036493131134904544
    },
    {
        "id": 742,
        "name": "Zach Arnett",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000237728257495172
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2019": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2020": {
                "college": "Mississippi State",
                "position": "DC"
            },
            "2021": {
                "college": "Mississippi State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.0192386380911621",
                "net_sr": "-0.0112868609920477",
                "net_stuff": "0.00638738428934818",
                "net_pass_sr": "0.0226692105348274",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "0.25601339315207",
                "net_sr": "0.0927411404831409",
                "net_stuff": "-0.0405677116813282",
                "net_pass_sr": "0.138308163787818",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2752520312432321
    },
    {
        "id": 744,
        "name": "Dave Steckel",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000057032445221434
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Missouri",
                "position": "DC"
            },
            "2010": {
                "college": "Missouri",
                "position": "DC"
            },
            "2011": {
                "college": "Missouri",
                "position": "DC"
            },
            "2012": {
                "college": "Missouri",
                "position": "DC"
            },
            "2013": {
                "college": "Missouri",
                "position": "DC"
            },
            "2014": {
                "college": "Missouri",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Missouri",
                "role": "OC",
                "net_ppa": "-0.0165684842354092",
                "net_sr": "-0.0052019271602069",
                "net_stuff": "-0.00515991389040446",
                "net_pass_sr": "-0.000958715619771566",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0165684842354092
    },
    {
        "id": 745,
        "name": "DeMontie Cross",
        "community": 19,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000123519737974918
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "TCU",
                "position": "DC"
            },
            "2016": {
                "college": "Missouri",
                "position": "DC"
            },
            "2017": {
                "college": "Missouri",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Missouri",
                "role": "OC",
                "net_ppa": "-0.115368963788542",
                "net_sr": "-0.0158927399327846",
                "net_stuff": "0.00785153634984523",
                "net_pass_sr": "0.00921070232777754",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "TCU",
                "role": "OC",
                "net_ppa": "-0.102494260458527",
                "net_sr": "-0.0274841409492325",
                "net_stuff": "0.100921207684231",
                "net_pass_sr": "0.0280975623080886",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.217863224247069
    },
    {
        "id": 746,
        "name": "Steve Wilks",
        "community": 11,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000703828327128952
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Missouri",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Missouri",
                "role": "OC",
                "net_ppa": "0.0059243284814465",
                "net_sr": "0.00546474007187542",
                "net_stuff": "0.000227124394831635",
                "net_pass_sr": "0.0147043643457244",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0059243284814465
    },
    {
        "id": 748,
        "name": "Dale Pehrson",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000154601753199723
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Navy",
                "position": "DC"
            },
            "2017": {
                "college": "Navy",
                "position": "DC"
            },
            "2018": {
                "college": "Navy",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Navy",
                "role": "OC",
                "net_ppa": "-0.115113552475372",
                "net_sr": "0.00863567251315706",
                "net_stuff": "-0.0306097062170559",
                "net_pass_sr": "-0.0228085443200037",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.115113552475372
    },
    {
        "id": 749,
        "name": "Brian Newberry",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000154601753199735
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Navy",
                "position": "DC"
            },
            "2020": {
                "college": "Navy",
                "position": "DC"
            },
            "2021": {
                "college": "Navy",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Navy",
                "role": "OC",
                "net_ppa": "0.0973053079268633",
                "net_sr": "0.0454615622694091",
                "net_stuff": "-0.0193002845799786",
                "net_pass_sr": "0.0620407921217838",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0973053079268633
    },
    {
        "id": 750,
        "name": "Dave Huxtable",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000787193798042296
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2003": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "UCF",
                "position": "DC"
            },
            "2009": {
                "college": "UCF",
                "position": "DC"
            },
            "2010": {
                "college": "UCF",
                "position": "DC"
            },
            "2011": false,
            "2012": {
                "college": "Pittsburgh",
                "position": "DC"
            },
            "2013": {
                "college": "NC State",
                "position": "DC"
            },
            "2014": {
                "college": "NC State",
                "position": "DC"
            },
            "2015": {
                "college": "NC State",
                "position": "DC"
            },
            "2016": {
                "college": "NC State",
                "position": "DC"
            },
            "2017": {
                "college": "NC State",
                "position": "DC"
            },
            "2018": {
                "college": "NC State",
                "position": "DC"
            },
            "2019": {
                "college": "NC State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "-0.131289440038835",
                "net_sr": "-0.0202950867513665",
                "net_stuff": "0.0115448459309256",
                "net_pass_sr": "-0.022915025460894",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "0.0169701184060761",
                "net_sr": "0.00994190079937413",
                "net_stuff": "-0.0179168863438041",
                "net_pass_sr": "-0.0240168526246151",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "0.0727799003647201",
                "net_sr": "0.049664463691122",
                "net_stuff": "-0.0329770033648669",
                "net_pass_sr": "0.0286323286689265",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.041539421268038806
    },
    {
        "id": 751,
        "name": "Tony Gibson",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000477579621145055
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2015": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2016": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2017": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2018": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2019": {
                "college": "NC State",
                "position": "DC"
            },
            "2020": {
                "college": "NC State",
                "position": "DC"
            },
            "2021": {
                "college": "NC State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "-0.0112777661195911",
                "net_sr": "0.00228535818058823",
                "net_stuff": "-0.00427197066831081",
                "net_pass_sr": "-0.00687187409316348",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "0.129264470872373",
                "net_sr": "0.0223225651261141",
                "net_stuff": "-0.0048815466965561",
                "net_pass_sr": "0.024046512386425",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1179867047527819
    },
    {
        "id": 752,
        "name": "John Papuchis",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00735707563341214
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2013": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2014": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2018": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "-0.0488480221383889",
                "net_sr": "-0.0267791951300257",
                "net_stuff": "-0.00675520524843717",
                "net_pass_sr": "-0.028206808328565",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "0.0067967786273119",
                "net_sr": "0.038293127659942",
                "net_stuff": "-0.0319791644146863",
                "net_pass_sr": "0.0330490284909202",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.042051243511077
    },
    {
        "id": 753,
        "name": "Mark Banker",
        "community": 12,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000215952146109475
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Stanford",
                "position": "DC"
            },
            "2003": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2004": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2005": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2006": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2007": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2008": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2009": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2010": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2011": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2012": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2013": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2014": {
                "college": "Oregon State",
                "position": "DC"
            },
            "2015": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2016": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "-0.097990624659399",
                "net_sr": "-0.0185338644788769",
                "net_stuff": "-0.0337513486518406",
                "net_pass_sr": "-0.069636002072585",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon State",
                "role": "OC",
                "net_ppa": "-0.0620805416397989",
                "net_sr": "-0.0244149857572891",
                "net_stuff": "0.017528833308342",
                "net_pass_sr": "-0.0166785903599947",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1600711662991979
    },
    {
        "id": 754,
        "name": "Erik Chinander",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000841137004893365
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "UCF",
                "position": "DC"
            },
            "2017": {
                "college": "UCF",
                "position": "DC"
            },
            "2018": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2019": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2020": {
                "college": "Nebraska",
                "position": "DC"
            },
            "2021": {
                "college": "Nebraska",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "0.00782998835399273",
                "net_sr": "0.0109334976328593",
                "net_stuff": "0.0145552412680858",
                "net_pass_sr": "0.0340849127013651",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "0.0730730374425577",
                "net_sr": "0.0402905637238402",
                "net_stuff": "0.0547633431741716",
                "net_pass_sr": "0.0676365792196651",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.08090302579655044
    },
    {
        "id": 755,
        "name": "Jeff Mills",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000378612936032688
        },
        "positions": {
            "2000": {
                "college": "Nevada",
                "position": "DC"
            },
            "2001": {
                "college": "Nevada",
                "position": "DC"
            },
            "2002": {
                "college": "Nevada",
                "position": "DC"
            },
            "2003": {
                "college": "Nevada",
                "position": "DC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2013": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "-0.0790712037393126",
                "net_sr": "-0.0100691209038944",
                "net_stuff": "0.0133777656682812",
                "net_pass_sr": "-0.0179843334806302",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0790712037393126
    },
    {
        "id": 756,
        "name": "Barry Sacks",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000765624447962568
        },
        "positions": {
            "2000": {
                "college": "San José State",
                "position": "DC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Nevada",
                "position": "DC"
            },
            "2005": {
                "college": "Nevada",
                "position": "DC"
            },
            "2006": {
                "college": "Nevada",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "0.110070164716137",
                "net_sr": "0.0456879166556586",
                "net_stuff": "-0.0519251494072695",
                "net_pass_sr": "0.00522875816993462",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.110070164716137
    },
    {
        "id": 757,
        "name": "Dave Fipp",
        "community": 12,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000228350421867608
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Nevada",
                "position": "DC"
            },
            "2005": {
                "college": "San José State",
                "position": "DC"
            },
            "2006": {
                "college": "San José State",
                "position": "DC"
            },
            "2007": {
                "college": "San José State",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "0.0893854181404213",
                "net_sr": "-0.011954002014017",
                "net_stuff": "0.00723951896780178",
                "net_pass_sr": "0.035139459067858",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0893854181404213
    },
    {
        "id": 758,
        "name": "Ken Wilson",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000418976467636419
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Nevada",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Oregon",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "0.0490898844989284",
                "net_sr": "0.0135759201973124",
                "net_stuff": "0.0457929616071409",
                "net_pass_sr": "-0.00549372902314077",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "-0.0944744113522641",
                "net_sr": "-0.0360651302769072",
                "net_stuff": "0.00660955332612539",
                "net_pass_sr": "-0.0367786674173188",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0453845268533357
    },
    {
        "id": 759,
        "name": "Nigel Burton",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000297733893178803
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Nevada",
                "position": "DC"
            },
            "2009": {
                "college": "Nevada",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "0.121607114771562",
                "net_sr": "0.0247634213003675",
                "net_stuff": "-0.0399213741009214",
                "net_pass_sr": "0.0177630875290413",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.121607114771562
    },
    {
        "id": 760,
        "name": "Mike Bradeson",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000159080075531688
        },
        "positions": {
            "2000": {
                "college": "UNLV",
                "position": "DC"
            },
            "2001": {
                "college": "UNLV",
                "position": "DC"
            },
            "2002": {
                "college": "UNLV",
                "position": "DC"
            },
            "2003": {
                "college": "UNLV",
                "position": "DC"
            },
            "2004": {
                "college": "UNLV",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Nevada",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "-0.0679310359096906",
                "net_sr": "-0.0543333245780301",
                "net_stuff": "0.0922010301680012",
                "net_pass_sr": "-0.0191303034345235",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0679310359096906
    },
    {
        "id": 761,
        "name": "Bill Teerlinck",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000132494069113263
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Nevada",
                "position": "DC"
            },
            "2014": {
                "college": "Nevada",
                "position": "DC"
            },
            "2015": {
                "college": "Nevada",
                "position": "DC"
            },
            "2016": {
                "college": "Nevada",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "-0.155322194053194",
                "net_sr": "-0.0728507859858318",
                "net_stuff": "-0.00393783441318987",
                "net_pass_sr": "-0.09207324692842",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.155322194053194
    },
    {
        "id": 762,
        "name": "Scott Boone",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000993705518349384
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Nevada",
                "position": "DC"
            },
            "2015": {
                "college": "Nevada",
                "position": "DC"
            },
            "2016": {
                "college": "Nevada",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "-0.0988405887807316",
                "net_sr": "-0.0673040742176009",
                "net_stuff": "0.0825110953044711",
                "net_pass_sr": "-0.0350511087276131",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0988405887807316
    },
    {
        "id": 763,
        "name": "Osia Lewis",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000412931399216757
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2004": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2005": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2006": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2007": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2008": {
                "college": "UTEP",
                "position": "DC"
            },
            "2009": {
                "college": "UTEP",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "0.185615223061718",
                "net_sr": "0.147568099130599",
                "net_stuff": "-0.147676299846838",
                "net_pass_sr": "0.109480751442811",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "-0.0604920511150457",
                "net_sr": "-0.0732132910004905",
                "net_stuff": "0.0227489558198893",
                "net_pass_sr": "-0.0457868890772499",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.12512317194667227
    },
    {
        "id": 764,
        "name": "Jordan Peterson",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000143966420874046
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "New Mexico",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "-0.0578847504143294",
                "net_sr": "-0.00349533578422762",
                "net_stuff": "-0.0107621176585879",
                "net_pass_sr": "-0.00242426570391829",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0578847504143294
    },
    {
        "id": 765,
        "name": "Ross Els",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00250851864459018
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2004": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Purdue",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.113702892755831",
                "net_sr": "-0.0454916564945831",
                "net_stuff": "-0.0122441942554258",
                "net_pass_sr": "-0.0583131588972742",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.113702892755831
    },
    {
        "id": 766,
        "name": "Dale Lindsey",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000525901562015256
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "0.0185985851282563",
                "net_sr": "-0.00790204205479655",
                "net_stuff": "-0.0168430163269192",
                "net_pass_sr": "-0.00887072181676352",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0185985851282563
    },
    {
        "id": 767,
        "name": "Larry Coyer",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000277260873179282
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "New Mexico State",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "-0.174749294893331",
                "net_sr": "-0.0839841035836953",
                "net_stuff": "0.0538060476956537",
                "net_pass_sr": "-0.00661365402416175",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.174749294893331
    },
    {
        "id": 771,
        "name": "Chuck Pagano",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000842447808040147
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "0.0559491492559664",
                "net_sr": "0.0165899289677212",
                "net_stuff": "-0.0207946696301383",
                "net_pass_sr": "0.0226373129681325",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0559491492559664
    },
    {
        "id": 772,
        "name": "Tommy Thigpen",
        "community": 13,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000456057486761582
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2020": {
                "college": "North Carolina",
                "position": "DC"
            },
            "2021": {
                "college": "North Carolina",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "-0.0706214905121921",
                "net_sr": "0.0243972652155861",
                "net_stuff": "0.0218931697043775",
                "net_pass_sr": "0.055703538695527",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0706214905121921
    },
    {
        "id": 773,
        "name": "Gary DeLoach",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000132485207743944
        },
        "positions": {
            "2000": {
                "college": "North Texas",
                "position": "DC"
            },
            "2001": {
                "college": "North Texas",
                "position": "DC"
            },
            "2002": {
                "college": "North Texas",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "North Texas",
                "position": "DC"
            },
            "2009": {
                "college": "North Texas",
                "position": "DC"
            },
            "2010": {
                "college": "North Texas",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "0.0428676402405292",
                "net_sr": "-0.00557383787641347",
                "net_stuff": "-0.0240996833803161",
                "net_pass_sr": "0.0348356400697877",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0428676402405292
    },
    {
        "id": 775,
        "name": "Fred Bleil",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000433491468517129
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "North Texas",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "0.152442528633382",
                "net_sr": "-0.0147368421052632",
                "net_stuff": "-0.0424981522542498",
                "net_pass_sr": "0.143648763853367",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.152442528633382
    },
    {
        "id": 776,
        "name": "Ron Mendoza",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 8.12589062913841e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "North Texas",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "-0.0056105986906746",
                "net_sr": "-0.0564111557786875",
                "net_stuff": "0.0265477628031169",
                "net_pass_sr": "-0.102949168797954",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0056105986906746
    },
    {
        "id": 777,
        "name": "Jon Skladany",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000800765937732895
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "North Texas",
                "position": "DC"
            },
            "2013": {
                "college": "North Texas",
                "position": "DC"
            },
            "2014": {
                "college": "North Texas",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "-0.0252670386053222",
                "net_sr": "0.00335951072588514",
                "net_stuff": "-0.0185968855594176",
                "net_pass_sr": "-0.0118745456950316",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0252670386053222
    },
    {
        "id": 779,
        "name": "Jay Niemann",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000344783585920684
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2012": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2013": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2014": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2015": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2016": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2017": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2018": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "-0.0608388336422669",
                "net_sr": "-0.0292967130555247",
                "net_stuff": "0.0712161777941083",
                "net_pass_sr": "-0.0228070043774832",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.0299201558443649",
                "net_sr": "0.00558450138268313",
                "net_stuff": "0.0415409705982549",
                "net_pass_sr": "0.0514895167833575",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.030918677797902
    },
    {
        "id": 780,
        "name": "Kevin Kane",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000393842741344254
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2017": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2018": {
                "college": "SMU",
                "position": "DC"
            },
            "2019": {
                "college": "SMU",
                "position": "DC"
            },
            "2020": {
                "college": "SMU",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "-0.00758439014844466",
                "net_sr": "0.037051677575219",
                "net_stuff": "-0.0742804007014204",
                "net_pass_sr": "0.0318272514921301",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "0.0620805256636129",
                "net_sr": "0.0388054557212448",
                "net_stuff": "-0.0333684610685727",
                "net_pass_sr": "-0.000301619368801853",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.054496135515168236
    },
    {
        "id": 781,
        "name": "Jeff Knowles",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000360408843693738
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Northern Illinois",
                "position": "DC"
            },
            "2019": {
                "college": "Temple",
                "position": "DC"
            },
            "2020": {
                "college": "Temple",
                "position": "DC"
            },
            "2021": {
                "college": "Temple",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "0.0252025724931988",
                "net_sr": "0.0326987690114354",
                "net_stuff": "-0.0132923039606186",
                "net_pass_sr": "0.00123053415488583",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "-0.126035367125927",
                "net_sr": "-0.0445837395067383",
                "net_stuff": "0.0410506407901048",
                "net_pass_sr": "-0.0378059174008092",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1008327946327282
    },
    {
        "id": 783,
        "name": "Jim O'Neil",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000311083732410312
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Northwestern",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Northwestern",
                "role": "OC",
                "net_ppa": "-0.201711414121817",
                "net_sr": "-0.0663831254458426",
                "net_stuff": "0.11174148898766",
                "net_pass_sr": "-0.0577233773444134",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.201711414121817
    },
    {
        "id": 784,
        "name": "Corwin Brown",
        "community": 13,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000132576915150618
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2008": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.0497842287724357",
                "net_sr": "-0.00578959076920049",
                "net_stuff": "0.0466361844372645",
                "net_pass_sr": "0.00851196154621742",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0497842287724357
    },
    {
        "id": 785,
        "name": "Kerry Cooks",
        "community": 3,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000328103078612381
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2013": {
                "college": "Notre Dame",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "-0.000140505763166765",
                "net_sr": "-0.0213716644055164",
                "net_stuff": "0.0193085696579282",
                "net_pass_sr": "-0.00261031532488132",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.000140505763166765
    },
    {
        "id": 786,
        "name": "Jim Burrow",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.581888484524032
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Ohio",
                "position": "DC"
            },
            "2006": {
                "college": "Ohio",
                "position": "DC"
            },
            "2007": {
                "college": "Ohio",
                "position": "DC"
            },
            "2008": {
                "college": "Ohio",
                "position": "DC"
            },
            "2009": {
                "college": "Ohio",
                "position": "DC"
            },
            "2010": {
                "college": "Ohio",
                "position": "DC"
            },
            "2011": {
                "college": "Ohio",
                "position": "DC"
            },
            "2012": {
                "college": "Ohio",
                "position": "DC"
            },
            "2013": {
                "college": "Ohio",
                "position": "DC"
            },
            "2014": false,
            "2015": {
                "college": "Ohio",
                "position": "DC"
            },
            "2016": {
                "college": "Ohio",
                "position": "DC"
            },
            "2017": {
                "college": "Ohio",
                "position": "DC"
            },
            "2018": {
                "college": "Ohio",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ohio",
                "role": "OC",
                "net_ppa": "0.0160219021676293",
                "net_sr": "0.0148046365697712",
                "net_stuff": "0.0268274086720164",
                "net_pass_sr": "0.028635456276188",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0160219021676293
    },
    {
        "id": 787,
        "name": "Pete Germano",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.120820287346784
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Ohio",
                "position": "DC"
            },
            "2020": {
                "college": "Ohio",
                "position": "DC"
            },
            "2021": {
                "college": "Ohio",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Ohio",
                "role": "OC",
                "net_ppa": "-0.0823483834717664",
                "net_sr": "-0.0220285298164191",
                "net_stuff": "-0.0371652165946193",
                "net_pass_sr": "-0.0385750141824548",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0823483834717664
    },
    {
        "id": 789,
        "name": "Alex Grinch",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00111726899424503
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Washington State",
                "position": "DC"
            },
            "2016": {
                "college": "Washington State",
                "position": "DC"
            },
            "2017": {
                "college": "Washington State",
                "position": "DC"
            },
            "2018": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2019": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2020": {
                "college": "Oklahoma",
                "position": "DC"
            },
            "2021": {
                "college": "Oklahoma",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "-0.115780865655498",
                "net_sr": "-0.0460170143850883",
                "net_stuff": "-0.00661215700182974",
                "net_pass_sr": "-0.0397321998868316",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma",
                "role": "OC",
                "net_ppa": "0.0575478563518219",
                "net_sr": "0.0345746751586264",
                "net_stuff": "-0.0644407713847701",
                "net_pass_sr": "0.0245540823261522",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "-0.0113373501107823",
                "net_sr": "0.0188965222498812",
                "net_stuff": "-0.0295393533576748",
                "net_pass_sr": "0.0294496532157739",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0695703594144584
    },
    {
        "id": 790,
        "name": "Kerry Coombs",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000263754357310036
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Ohio State",
                "position": "DC"
            },
            "2021": {
                "college": "Ohio State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "-0.113841656941936",
                "net_sr": "-0.0700470906437594",
                "net_stuff": "0.0361733616741123",
                "net_pass_sr": "-0.104188551329431",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.113841656941936
    },
    {
        "id": 792,
        "name": "Rich Nagy",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000151205407386456
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2013": false,
            "2014": {
                "college": "Old Dominion",
                "position": "DC"
            },
            "2015": {
                "college": "Old Dominion",
                "position": "DC"
            },
            "2016": {
                "college": "Old Dominion",
                "position": "DC"
            },
            "2017": {
                "college": "Old Dominion",
                "position": "DC"
            },
            "2018": {
                "college": "Old Dominion",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Old Dominion",
                "role": "OC",
                "net_ppa": "-0.0890176193927007",
                "net_sr": "-0.0607325612712092",
                "net_stuff": "-0.00891861761426979",
                "net_pass_sr": "-0.111757535460993",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "-0.00485676557725995",
                "net_sr": "0.0137353552532124",
                "net_stuff": "-0.00870711607589075",
                "net_pass_sr": "0.0180059493336462",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.09387438496996064
    },
    {
        "id": 793,
        "name": "Grady Brown",
        "community": 20,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 3.52125561699676e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Old Dominion",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Old Dominion",
                "role": "OC",
                "net_ppa": "0.0119097416414526",
                "net_sr": "0.0133881431615918",
                "net_stuff": "-0.10357241261871",
                "net_pass_sr": "0.000711015762152434",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0119097416414526
    },
    {
        "id": 795,
        "name": "Chuck Driesbach",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000484290244090888
        },
        "positions": {
            "2000": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2001": {
                "college": "TCU",
                "position": "DC"
            },
            "2002": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2003": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2004": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Rice",
                "position": "DC"
            },
            "2008": {
                "college": "Rice",
                "position": "DC"
            },
            "2009": {
                "college": "Rice",
                "position": "DC"
            },
            "2010": {
                "college": "Rice",
                "position": "DC"
            },
            "2011": {
                "college": "Rice",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.0324955915087788",
                "net_sr": "0.00303454904209782",
                "net_stuff": "0.0357191683087152",
                "net_pass_sr": "-0.0116100922987824",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0324955915087788
    },
    {
        "id": 796,
        "name": "Wesley McGriff",
        "community": 6,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000104217383735409
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2018": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "0.0206551935014193",
                "net_sr": "0.0183502479195336",
                "net_stuff": "-0.0209426390627396",
                "net_pass_sr": "0.0314461120385734",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0206551935014193
    },
    {
        "id": 797,
        "name": "Jason Jones",
        "community": 6,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000158659319072808
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2014": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2015": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2016": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2017": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2018": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0769588495163578",
                "net_sr": "-0.0121916579943325",
                "net_stuff": "0.036599643968335",
                "net_pass_sr": "0.00222735451056139",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0769588495163578
    },
    {
        "id": 798,
        "name": "Chris Partridge",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000798028408196868
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Ole Miss",
                "position": "DC"
            },
            "2021": {
                "college": "Ole Miss",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0438309721263885",
                "net_sr": "-0.0259618122865871",
                "net_stuff": "0.0175756077726628",
                "net_pass_sr": "-0.0228990907472618",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0438309721263885
    },
    {
        "id": 800,
        "name": "Don Pellum",
        "community": 23,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00156656640635684
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Oregon",
                "position": "DC"
            },
            "2015": {
                "college": "Oregon",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "-0.18893404189847",
                "net_sr": "-0.0437278501262328",
                "net_stuff": "-0.0172099279667099",
                "net_pass_sr": "-0.0455710348982376",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.18893404189847
    },
    {
        "id": 801,
        "name": "Joe Salave'a",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000540219042094245
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Oregon",
                "position": "DC"
            },
            "2019": {
                "college": "Oregon",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "0.130879536118198",
                "net_sr": "0.0538379915754302",
                "net_stuff": "0.00345183809762645",
                "net_pass_sr": "0.0430252541014191",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Other",
        "aggregate_xpa": 0.130879536118198
    },
    {
        "id": 802,
        "name": "Keith Heyward",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000810328563141392
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Oregon",
                "position": "DC"
            },
            "2019": {
                "college": "Oregon",
                "position": "DC"
            },
            "2020": {
                "college": "Oregon",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "0.0916270682903248",
                "net_sr": "0.0371425515024842",
                "net_stuff": "-0.000181584873408025",
                "net_pass_sr": "0.0261046766407411",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0916270682903248
    },
    {
        "id": 803,
        "name": "Tom Bradley",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000282047426536689
        },
        "positions": {
            "2000": {
                "college": "Penn State",
                "position": "DC"
            },
            "2001": {
                "college": "Penn State",
                "position": "DC"
            },
            "2002": {
                "college": "Penn State",
                "position": "DC"
            },
            "2003": {
                "college": "Penn State",
                "position": "DC"
            },
            "2004": {
                "college": "Penn State",
                "position": "DC"
            },
            "2005": {
                "college": "Penn State",
                "position": "DC"
            },
            "2006": {
                "college": "Penn State",
                "position": "DC"
            },
            "2007": {
                "college": "Penn State",
                "position": "DC"
            },
            "2008": {
                "college": "Penn State",
                "position": "DC"
            },
            "2009": {
                "college": "Penn State",
                "position": "DC"
            },
            "2010": {
                "college": "Penn State",
                "position": "DC"
            },
            "2011": {
                "college": "Penn State",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "UCLA",
                "position": "DC"
            },
            "2016": {
                "college": "UCLA",
                "position": "DC"
            },
            "2017": {
                "college": "UCLA",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "0.0636027635618271",
                "net_sr": "0.0290810695758043",
                "net_stuff": "0.032587307432378",
                "net_pass_sr": "0.0394588474612366",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0636027635618271
    },
    {
        "id": 804,
        "name": "John Butler",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000149795481420638
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Penn State",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "-0.0318537446603119",
                "net_sr": "-0.00289341478292565",
                "net_stuff": "-0.035529548335389",
                "net_pass_sr": "-0.0640246370919621",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0318537446603119
    },
    {
        "id": 805,
        "name": "Anthony Poindexter",
        "community": 3,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000111234070458245
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2015": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2016": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2017": {
                "college": "Purdue",
                "position": "DC"
            },
            "2018": {
                "college": "Purdue",
                "position": "DC"
            },
            "2019": {
                "college": "Purdue",
                "position": "DC"
            },
            "2020": {
                "college": "Purdue",
                "position": "DC"
            },
            "2021": {
                "college": "Penn State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "-0.133635928109628",
                "net_sr": "-0.0650733945863759",
                "net_stuff": "0.073679829479474",
                "net_pass_sr": "-0.0404078198734669",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "0.028758445085854",
                "net_sr": "-0.0141985167904274",
                "net_stuff": "0.0243085300307146",
                "net_pass_sr": "-0.00333104400449158",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "0.0277740324677058",
                "net_sr": "0.0219856797909749",
                "net_stuff": "0.00671343071588884",
                "net_pass_sr": "0.00552622878401055",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0771034505560682
    },
    {
        "id": 806,
        "name": "Brock Spack",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000109456725777333
        },
        "positions": {
            "2000": {
                "college": "Purdue",
                "position": "DC"
            },
            "2001": {
                "college": "Purdue",
                "position": "DC"
            },
            "2002": {
                "college": "Purdue",
                "position": "DC"
            },
            "2003": {
                "college": "Purdue",
                "position": "DC"
            },
            "2004": {
                "college": "Purdue",
                "position": "DC"
            },
            "2005": {
                "college": "Purdue",
                "position": "DC"
            },
            "2006": {
                "college": "Purdue",
                "position": "DC"
            },
            "2007": {
                "college": "Purdue",
                "position": "DC"
            },
            "2008": {
                "college": "Purdue",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "0.0300751160618619",
                "net_sr": "0.00804607076018493",
                "net_stuff": "0.0329094754916333",
                "net_pass_sr": "0.00188902789431439",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0300751160618619
    },
    {
        "id": 807,
        "name": "Donn Landholm",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000119815125820683
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Purdue",
                "position": "DC"
            },
            "2010": {
                "college": "Purdue",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.00524888606636873",
                "net_sr": "0.0025163190271183",
                "net_stuff": "-0.0137268860868726",
                "net_pass_sr": "-0.00920114112037496",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00524888606636873
    },
    {
        "id": 808,
        "name": "Gary Emanuel",
        "community": 25,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000119815125820695
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Purdue",
                "position": "DC"
            },
            "2011": {
                "college": "Purdue",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.0547496005118485",
                "net_sr": "-0.0383415309770233",
                "net_stuff": "-0.00783900293307346",
                "net_pass_sr": "-0.0557351292163439",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0547496005118485
    },
    {
        "id": 809,
        "name": "Nick Holt",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000778856081116315
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "USC",
                "position": "DC"
            },
            "2007": {
                "college": "USC",
                "position": "DC"
            },
            "2008": {
                "college": "USC",
                "position": "DC"
            },
            "2009": {
                "college": "Washington",
                "position": "DC"
            },
            "2010": {
                "college": "Washington",
                "position": "DC"
            },
            "2011": {
                "college": "Washington",
                "position": "DC"
            },
            "2012": false,
            "2013": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2014": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2015": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2016": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2017": {
                "college": "Purdue",
                "position": "DC"
            },
            "2018": {
                "college": "Purdue",
                "position": "DC"
            },
            "2019": {
                "college": "Purdue",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.251069332985208",
                "net_sr": "-0.106958698695827",
                "net_stuff": "0.041340578727627",
                "net_pass_sr": "-0.0912766155501747",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "0.00820333201262956",
                "net_sr": "0.0215685858212861",
                "net_stuff": "-0.00139584760187636",
                "net_pass_sr": "0.0132911309872896",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "0.0677625883721073",
                "net_sr": "-0.0223156124650727",
                "net_stuff": "-0.000909712527681183",
                "net_pass_sr": "-0.00192536803287657",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "0.0187348077952285",
                "net_sr": "-0.0304124837811517",
                "net_stuff": "-0.0288859530286625",
                "net_pass_sr": "-0.0225013246415551",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.15636860480524262
    },
    {
        "id": 811,
        "name": "Chris Thurmond",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000150114087402948
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Rice",
                "position": "DC"
            },
            "2013": {
                "college": "Rice",
                "position": "DC"
            },
            "2014": {
                "college": "Rice",
                "position": "DC"
            },
            "2015": {
                "college": "Rice",
                "position": "DC"
            },
            "2016": {
                "college": "Rice",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "-0.0977061940491073",
                "net_sr": "0.0198788767472814",
                "net_stuff": "-0.00561823776568746",
                "net_pass_sr": "0.0329399425980622",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0977061940491073
    },
    {
        "id": 812,
        "name": "Brian Smith",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00029947737311699
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2017": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2018": {
                "college": "Rice",
                "position": "DC"
            },
            "2019": {
                "college": "Rice",
                "position": "DC"
            },
            "2020": {
                "college": "Rice",
                "position": "DC"
            },
            "2021": {
                "college": "Rice",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "0.17139324804897",
                "net_sr": "0.0846264150689247",
                "net_stuff": "-0.00348613153394314",
                "net_pass_sr": "0.0924594230825012",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "-0.0602435426229138",
                "net_sr": "-0.0257988250721948",
                "net_stuff": "0.0668637376785788",
                "net_pass_sr": "-0.0217433995151783",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.0949672417240257",
                "net_sr": "0.0274905120085054",
                "net_stuff": "-0.00986607689785901",
                "net_pass_sr": "0.000689665366525316",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC",
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2061169471500819
    },
    {
        "id": 815,
        "name": "Bob Fraser",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000143519621752921
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2010": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2011": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.015509719361035",
                "net_sr": "-0.0123859726504055",
                "net_stuff": "-0.0252454601759938",
                "net_pass_sr": "-0.0306491231154993",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.015509719361035
    },
    {
        "id": 816,
        "name": "Dave Cohen",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000377415368258273
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2011": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2012": false,
            "2013": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "-0.141094352605539",
                "net_sr": "-0.0353285507595852",
                "net_stuff": "0.043155008121391",
                "net_pass_sr": "-0.0653787966976188",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "-0.00041771670296635",
                "net_sr": "0.0153138144968122",
                "net_stuff": "-0.00330309154934469",
                "net_pass_sr": "0.0169824520663046",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.14151206930850532
    },
    {
        "id": 817,
        "name": "Bill Busch",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000169267468983608
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Utah State",
                "position": "DC"
            },
            "2010": {
                "college": "Utah State",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.0771016253103796",
                "net_sr": "0.0269007466883969",
                "net_stuff": "0.0568960970119327",
                "net_pass_sr": "0.0302757469165165",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.00685587372093913",
                "net_sr": "0.0496175665566924",
                "net_stuff": "0.0391197131996392",
                "net_pass_sr": "0.0974522400186769",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.08395749903131873
    },
    {
        "id": 818,
        "name": "Noah Joseph",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000879643988916002
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2019": {
                "college": "Rutgers",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "-0.189043802391858",
                "net_sr": "-0.049226959936609",
                "net_stuff": "0.00992839076102178",
                "net_pass_sr": "-0.0411582805366467",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.189043802391858
    },
    {
        "id": 820,
        "name": "Kurt Mattix",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00015124219078123
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "San Diego State",
                "position": "DC"
            },
            "2021": {
                "college": "San Diego State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "0.0532250969677633",
                "net_sr": "0.0457887645144809",
                "net_stuff": "-0.0606537768446987",
                "net_pass_sr": "0.0161371740698133",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0532250969677633
    },
    {
        "id": 824,
        "name": "Derrick Odum",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000308630368922666
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "San José State",
                "position": "DC"
            },
            "2018": {
                "college": "San José State",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "-0.0521681468998254",
                "net_sr": "-0.0180489690099888",
                "net_stuff": "0.00730711588233968",
                "net_pass_sr": "-0.0612354645158451",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0521681468998254
    },
    {
        "id": 825,
        "name": "Eric Schumann",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000674567812146014
        },
        "positions": {
            "2000": {
                "college": "SMU",
                "position": "DC"
            },
            "2001": {
                "college": "SMU",
                "position": "DC"
            },
            "2002": {
                "college": "Tulane",
                "position": "DC"
            },
            "2003": {
                "college": "Tulane",
                "position": "DC"
            },
            "2004": {
                "college": "Tulane",
                "position": "DC"
            },
            "2005": {
                "college": "Tulane",
                "position": "DC"
            },
            "2006": {
                "college": "Tulane",
                "position": "DC"
            },
            "2007": {
                "college": "UAB",
                "position": "DC"
            },
            "2008": {
                "college": "UAB",
                "position": "DC"
            },
            "2009": {
                "college": "UAB",
                "position": "DC"
            },
            "2010": {
                "college": "UAB",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "-0.0418581489147995",
                "net_sr": "-0.0034511903739492",
                "net_stuff": "-0.00831941875986672",
                "net_pass_sr": "0.000531216618998243",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0418581489147995
    },
    {
        "id": 827,
        "name": "Van Malone",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000323496905659415
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "SMU",
                "position": "DC"
            },
            "2016": {
                "college": "SMU",
                "position": "DC"
            },
            "2017": {
                "college": "SMU",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "-0.121326198877249",
                "net_sr": "-0.0610922962216842",
                "net_stuff": "0.0370394139598866",
                "net_pass_sr": "-0.0961849090198078",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.121326198877249
    },
    {
        "id": 828,
        "name": "Kevin Sherrer",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000270689679209217
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "-0.0899473483770308",
                "net_sr": "-0.0574575752641214",
                "net_stuff": "0.0366495857247544",
                "net_pass_sr": "-0.041300946891725",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.0171646534662467",
                "net_sr": "-0.0345456216789861",
                "net_stuff": "0.0149420550281912",
                "net_pass_sr": "-0.072280762141906",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1071120018432775
    },
    {
        "id": 829,
        "name": "Travis Pearson",
        "community": 40,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000107929067721755
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2015": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "0.111416711850488",
                "net_sr": "0.0133895711906445",
                "net_stuff": "-0.00929734567868218",
                "net_pass_sr": "-0.0302116163965338",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.111416711850488
    },
    {
        "id": 830,
        "name": "Brian Turner",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 5.3964533860052e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "South Alabama",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "0.0206209880278373",
                "net_sr": "-0.00579979430359012",
                "net_stuff": "0.0204346862151837",
                "net_pass_sr": "-0.0239854943161694",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0206209880278373
    },
    {
        "id": 831,
        "name": "Travaris Robinson",
        "community": 13,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00032760086828872
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2017": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2018": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2019": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2020": {
                "college": "South Carolina",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "0.0257019016019662",
                "net_sr": "-0.0223105419211993",
                "net_stuff": "0.00987483036455439",
                "net_pass_sr": "-0.0350224540309785",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0257019016019662
    },
    {
        "id": 832,
        "name": "Clayton White",
        "community": 3,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000716857211341401
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2018": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2019": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2020": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2021": {
                "college": "South Carolina",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "0.0689759901825451",
                "net_sr": "0.0408314675341016",
                "net_stuff": "0.0369634509272167",
                "net_pass_sr": "0.0512751728031302",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "0.052022005408166",
                "net_sr": "0.0139515257575528",
                "net_stuff": "0.0286330782404149",
                "net_pass_sr": "0.0382984399882052",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.1209979955907111
    },
    {
        "id": 833,
        "name": "Derek Nicholson",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000843418173258255
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2019": {
                "college": "Southern Mississippi",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.00947604337701444",
                "net_sr": "0.00884678414014234",
                "net_stuff": "0.0413784118383161",
                "net_pass_sr": "-0.014654115525493",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.00947604337701444
    },
    {
        "id": 834,
        "name": "Austin Armstrong",
        "community": 22,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 7.45586665549393e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Southern Mississippi",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "-0.0814896766095825",
                "net_sr": "-0.0103711626660207",
                "net_stuff": "0.0497672323342966",
                "net_pass_sr": "-0.0105190718262614",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0814896766095825
    },
    {
        "id": 835,
        "name": "Ron Lynn",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000058164807578115
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Stanford",
                "position": "DC"
            },
            "2009": {
                "college": "Stanford",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "-0.0768360176148427",
                "net_sr": "-0.00892056430306298",
                "net_stuff": "0.0358475737026897",
                "net_pass_sr": "-0.0153201842857015",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0768360176148427
    },
    {
        "id": 836,
        "name": "Vic Fangio",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000290824037890509
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Stanford",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "-0.0983511566060389",
                "net_sr": "-0.0580178948527323",
                "net_stuff": "0.00736974308518082",
                "net_pass_sr": "-0.0429061761643061",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0983511566060389
    },
    {
        "id": 837,
        "name": "Jason Tarver",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0013908148571521
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Stanford",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2019": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "0.0568304106955465",
                "net_sr": "0.0617933227609456",
                "net_stuff": "-0.0947443782177361",
                "net_pass_sr": "0.03822685212524",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.104264712614935",
                "net_sr": "-0.0625190620760983",
                "net_stuff": "0.04818459640174",
                "net_pass_sr": "-0.0524618464677376",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0474343019193885
    },
    {
        "id": 838,
        "name": "Lance Anderson",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000961833153326765
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Stanford",
                "position": "DC"
            },
            "2015": {
                "college": "Stanford",
                "position": "DC"
            },
            "2016": {
                "college": "Stanford",
                "position": "DC"
            },
            "2017": {
                "college": "Stanford",
                "position": "DC"
            },
            "2018": {
                "college": "Stanford",
                "position": "DC"
            },
            "2019": {
                "college": "Stanford",
                "position": "DC"
            },
            "2020": {
                "college": "Stanford",
                "position": "DC"
            },
            "2021": {
                "college": "Stanford",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "-0.168039821396194",
                "net_sr": "-0.0654124060724775",
                "net_stuff": "0.103101255702859",
                "net_pass_sr": "-0.030327370605752",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.168039821396194
    },
    {
        "id": 839,
        "name": "Steve Russ",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000210096975083024
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "0.0299248671412155",
                "net_sr": "0.0338384064732608",
                "net_stuff": "-0.023044254379017",
                "net_pass_sr": "0.02502011508644",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0299248671412155
    },
    {
        "id": 840,
        "name": "Derrick Jackson",
        "community": 20,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000021009697508304
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "-0.0275951369408452",
                "net_sr": "-0.0363804231935468",
                "net_stuff": "0.0237785624784051",
                "net_pass_sr": "0.00344434863115112",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0275951369408452
    },
    {
        "id": 841,
        "name": "Chuck Bullough",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000612801307701896
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "UCLA",
                "position": "DC"
            },
            "2010": {
                "college": "UCLA",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2014": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2015": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "-0.0860856727885212",
                "net_sr": "-0.0427921079959244",
                "net_stuff": "0.0104586567013126",
                "net_pass_sr": "-0.0370599045572625",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "-0.0622460235124148",
                "net_sr": "-0.0495778584828885",
                "net_stuff": "0.0106162153004754",
                "net_pass_sr": "-0.0468900880225803",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.148331696300936
    },
    {
        "id": 842,
        "name": "Tony White",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000208976933365707
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Syracuse",
                "position": "DC"
            },
            "2021": {
                "college": "Syracuse",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "-0.214375236023304",
                "net_sr": "-0.0866518532780122",
                "net_stuff": "0.0201389695178184",
                "net_pass_sr": "-0.0689359982986947",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.214375236023304
    },
    {
        "id": 843,
        "name": "Chad Glasgow",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000458246232170875
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "TCU",
                "position": "DC"
            },
            "2016": {
                "college": "TCU",
                "position": "DC"
            },
            "2017": {
                "college": "TCU",
                "position": "DC"
            },
            "2018": {
                "college": "TCU",
                "position": "DC"
            },
            "2019": {
                "college": "TCU",
                "position": "DC"
            },
            "2020": {
                "college": "TCU",
                "position": "DC"
            },
            "2021": {
                "college": "TCU",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "TCU",
                "role": "OC",
                "net_ppa": "-0.145174293941992",
                "net_sr": "-0.0329163826826257",
                "net_stuff": "0.0493947982430156",
                "net_pass_sr": "-0.0163784172471523",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "-0.058892274769985",
                "net_sr": "-0.0361547140747118",
                "net_stuff": "0.00265004196517529",
                "net_pass_sr": "-0.04101014414663",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.20406656871197698
    },
    {
        "id": 845,
        "name": "Monte Kiffin",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000399014204098381
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.00319144901674599",
                "net_sr": "-0.0183750239427096",
                "net_stuff": "0.00177746203919846",
                "net_pass_sr": "0.0221195949644226",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00319144901674599
    },
    {
        "id": 846,
        "name": "Sal Sunseri",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000503820161472154
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "0.0921622530177753",
                "net_sr": "-0.0160107404814783",
                "net_stuff": "-0.00619724920846579",
                "net_pass_sr": "-0.0161502073494012",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0921622530177753
    },
    {
        "id": 847,
        "name": "Derrick Ansley",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000530586451646541
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2020": {
                "college": "Tennessee",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "0.0350155671381713",
                "net_sr": "0.011531164775109",
                "net_stuff": "-0.00801836533049424",
                "net_pass_sr": "-0.0387850142140139",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0350155671381713
    },
    {
        "id": 848,
        "name": "Coleman Hutzler",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000142745611591884
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Texas",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.00508632270748108",
                "net_sr": "-0.00496812116235729",
                "net_stuff": "-0.00916642048913238",
                "net_pass_sr": "-0.0448319212693667",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00508632270748108
    },
    {
        "id": 849,
        "name": "Jeff Choate",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000198728418707677
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Texas",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "-0.110219562702031",
                "net_sr": "-0.0187053158399025",
                "net_stuff": "0.0380110292076184",
                "net_pass_sr": "-0.0197933663310644",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.110219562702031
    },
    {
        "id": 850,
        "name": "Archie McDaniel",
        "community": 15,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000196391116916912
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Texas State",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Texas State",
                "role": "OC",
                "net_ppa": "-0.0976718094723439",
                "net_sr": "-0.059675312996936",
                "net_stuff": "0.00962074436380672",
                "net_pass_sr": "-0.0667333274873552",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0976718094723439
    },
    {
        "id": 851,
        "name": "Zac Spavital",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000392782233833978
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Texas State",
                "position": "DC"
            },
            "2021": {
                "college": "Texas State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Texas State",
                "role": "OC",
                "net_ppa": "-0.0330287041404002",
                "net_sr": "-0.018169380634071",
                "net_stuff": "0.0211622342340205",
                "net_pass_sr": "-0.0213221052124021",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0330287041404002
    },
    {
        "id": 852,
        "name": "Mike Smith",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000704235197092924
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2014": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2015": {
                "college": "Texas Tech",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "-0.151871593309014",
                "net_sr": "-0.0381945257886678",
                "net_stuff": "0.00771132423291365",
                "net_pass_sr": "-0.00827819349578357",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.151871593309014
    },
    {
        "id": 854,
        "name": "Paul Nichols",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000255961266043235
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Toledo",
                "position": "DC"
            },
            "2010": {
                "college": "Toledo",
                "position": "DC"
            },
            "2011": {
                "college": "Toledo",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "0.000179773056715217",
                "net_sr": "-0.0788636691614886",
                "net_stuff": "0.0301916443627858",
                "net_pass_sr": "-0.0704402729868105",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.000179773056715217
    },
    {
        "id": 855,
        "name": "Tom Matukewicz",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000156868599339043
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Toledo",
                "position": "DC"
            },
            "2013": {
                "college": "Toledo",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "0.367929983753973",
                "net_sr": "0.0900712164605519",
                "net_stuff": "-0.0463877732125513",
                "net_pass_sr": "0.0901639075722965",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.367929983753973
    },
    {
        "id": 856,
        "name": "Vince Kehres",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000200978965980677
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Toledo",
                "position": "DC"
            },
            "2021": {
                "college": "Toledo",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "-0.235160114534608",
                "net_sr": "-0.0497087286577975",
                "net_stuff": "0.0225474294462955",
                "net_pass_sr": "-0.0289596639889824",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.235160114534608
    },
    {
        "id": 857,
        "name": "Jeremy Rowell",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000129402777315335
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Troy",
                "position": "DC"
            },
            "2006": {
                "college": "Troy",
                "position": "DC"
            },
            "2007": {
                "college": "Troy",
                "position": "DC"
            },
            "2008": {
                "college": "Troy",
                "position": "DC"
            },
            "2009": {
                "college": "Troy",
                "position": "DC"
            },
            "2010": {
                "college": "Troy",
                "position": "DC"
            },
            "2011": {
                "college": "Troy",
                "position": "DC"
            },
            "2012": {
                "college": "Troy",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "-0.0154441773346208",
                "net_sr": "-0.00203381152189186",
                "net_stuff": "0.0246136570325145",
                "net_pass_sr": "0.0210956296059315",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0154441773346208
    },
    {
        "id": 859,
        "name": "Mike Pelton",
        "community": 27,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000161753471644059
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Troy",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "0.00303250518253288",
                "net_sr": "0.036750936329588",
                "net_stuff": "-0.119117647058824",
                "net_pass_sr": "0.0283105022831051",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.00303250518253288
    },
    {
        "id": 860,
        "name": "Brandon Hall",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000317583253312479
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Troy",
                "position": "DC"
            },
            "2020": {
                "college": "Troy",
                "position": "DC"
            },
            "2021": {
                "college": "Troy",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "-0.081481403168733",
                "net_sr": "-0.0363087085347392",
                "net_stuff": "0.062747532819616",
                "net_pass_sr": "-0.0442369283749514",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.081481403168733
    },
    {
        "id": 862,
        "name": "O'Neill Gilbert",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000179603339209358
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Tulane",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "-0.00535248525278456",
                "net_sr": "0.00991974852707872",
                "net_stuff": "0.0047885724037999",
                "net_pass_sr": "0.0174584224504779",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.00535248525278456
    },
    {
        "id": 863,
        "name": "Lionel Washington",
        "community": 39,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000747112810098595
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Tulane",
                "position": "DC"
            },
            "2013": {
                "college": "Tulane",
                "position": "DC"
            },
            "2014": {
                "college": "Tulane",
                "position": "DC"
            },
            "2015": {
                "college": "Tulane",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "-0.028383435768457",
                "net_sr": "0.0201405385437607",
                "net_stuff": "-0.0910461124157583",
                "net_pass_sr": "-0.00551040714451295",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.028383435768457
    },
    {
        "id": 864,
        "name": "Jason Rollins",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000109371340470017
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Tulane",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2019": {
                "college": "UT San Antonio",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "-0.133693016793277",
                "net_sr": "-0.0296810860033118",
                "net_stuff": "0.0303240546221028",
                "net_pass_sr": "-0.0473236449822546",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UT San Antonio",
                "role": "OC",
                "net_ppa": "-0.0968536577984734",
                "net_sr": "-0.0399327408186202",
                "net_stuff": "-0.0477299312154622",
                "net_pass_sr": "-0.0799767225475593",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.23054667459175038
    },
    {
        "id": 865,
        "name": "Chris Hampton",
        "community": 22,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 5.84794365663418e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Tulane",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "-0.0683092876988144",
                "net_sr": "-0.0230594698792824",
                "net_stuff": "-0.0176641698808478",
                "net_pass_sr": "-0.0707530917803562",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0683092876988144
    },
    {
        "id": 867,
        "name": "Mark Nelson",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000586821321545464
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "-0.0123706838563882",
                "net_sr": "-0.0380994847403884",
                "net_stuff": "0.109935737738428",
                "net_pass_sr": "0.00364059997087518",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0123706838563882
    },
    {
        "id": 868,
        "name": "Joseph Gillespie",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000762256804529888
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2020": {
                "college": "Tulsa",
                "position": "DC"
            },
            "2021": {
                "college": "Tulsa",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "0.0795399838432586",
                "net_sr": "0.0448077694590028",
                "net_stuff": "-0.0338593479500318",
                "net_pass_sr": "0.0361028874305169",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0795399838432586
    },
    {
        "id": 869,
        "name": "Rick Christophel",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000175131383332956
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "UAB",
                "position": "DC"
            },
            "2002": {
                "college": "UAB",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "UAB",
                "position": "DC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "0.0306926331490183",
                "net_sr": "0.0301970316792158",
                "net_stuff": "-0.0311145510835913",
                "net_pass_sr": "0.0577046548956661",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0306926331490183
    },
    {
        "id": 870,
        "name": "Reggie Johnson",
        "community": 20,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000143382495597453
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "UAB",
                "position": "DC"
            },
            "2013": {
                "college": "UAB",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "0.103645327444146",
                "net_sr": "-0.0160963778129248",
                "net_stuff": "-0.0239467281214915",
                "net_pass_sr": "-0.0265359302223402",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.103645327444146
    },
    {
        "id": 871,
        "name": "Duwan Walker",
        "community": 40,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000027906548201696
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "UAB",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "0.00568316005714437",
                "net_sr": "0.0717622974112681",
                "net_stuff": "-0.0308081634697995",
                "net_pass_sr": "0.108907208205778",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.00568316005714437
    },
    {
        "id": 872,
        "name": "David Reeves",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000139532741009008
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "UAB",
                "position": "DC"
            },
            "2018": {
                "college": "UAB",
                "position": "DC"
            },
            "2019": {
                "college": "UAB",
                "position": "DC"
            },
            "2020": {
                "college": "UAB",
                "position": "DC"
            },
            "2021": {
                "college": "UAB",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "0.0775863028579019",
                "net_sr": "0.0404523834555102",
                "net_stuff": "-0.00992326981728037",
                "net_pass_sr": "0.0160794758921656",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0775863028579019
    },
    {
        "id": 875,
        "name": "Chuck Bresnahan",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000024658829988984
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "UCF",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.229092218476064",
                "net_sr": "-0.0478517936640212",
                "net_stuff": "0.0301245309814397",
                "net_pass_sr": "-0.0850484270591325",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.229092218476064
    },
    {
        "id": 876,
        "name": "Travis Williams",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000603304800749366
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "UCF",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.096755048827113",
                "net_sr": "-0.00573064984723409",
                "net_stuff": "-0.00684258531819826",
                "net_pass_sr": "-0.00219257067075207",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.096755048827113
    },
    {
        "id": 878,
        "name": "Lou Spanos",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000120628463935339
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "UCLA",
                "position": "DC"
            },
            "2013": {
                "college": "UCLA",
                "position": "DC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2020": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2021": {
                "college": "Connecticut",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "0.0452801479209973",
                "net_sr": "0.011167402384895",
                "net_stuff": "0.0412040268918009",
                "net_pass_sr": "0.0576899564482188",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "-0.0214899174437285",
                "net_sr": "0.00405386297661042",
                "net_stuff": "0.0268455922615777",
                "net_pass_sr": "-0.0204847697767196",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0237902304772688
    },
    {
        "id": 879,
        "name": "Jeff Ulbrich",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000617143330019123
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "UCLA",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "-0.0283907265426697",
                "net_sr": "0.0145815982920402",
                "net_stuff": "0.000916511957017807",
                "net_pass_sr": "0.0344386218070429",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0283907265426697
    },
    {
        "id": 882,
        "name": "Vincent Brown",
        "community": 3,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000250826626392549
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2015": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2016": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "0.11826122063952",
                "net_sr": "0.0999473489706842",
                "net_stuff": "-0.100757346318078",
                "net_pass_sr": "0.0485948768347196",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.11826122063952
    },
    {
        "id": 883,
        "name": "Billy Crocker",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000721903982232895
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2018": {
                "college": "Connecticut",
                "position": "DC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "-0.268733548000598",
                "net_sr": "-0.0881924278589553",
                "net_stuff": "0.0215798442343284",
                "net_pass_sr": "-0.102800715105782",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.268733548000598
    },
    {
        "id": 884,
        "name": "Zac Alley",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000010798727059197
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Louisiana Monroe",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "-0.000804823560945578",
                "net_sr": "0.00770705330998278",
                "net_stuff": "-0.0452619070242949",
                "net_pass_sr": "0.0370973236172746",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.000804823560945578
    },
    {
        "id": 885,
        "name": "Kurt Barber",
        "community": 25,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000388828055098332
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "UNLV",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "0.0150812831510334",
                "net_sr": "-0.00946587542616673",
                "net_stuff": "0.114193625464262",
                "net_pass_sr": "0.0683626200332292",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0150812831510334
    },
    {
        "id": 886,
        "name": "Kraig Paulson",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000931175464128404
        },
        "positions": {
            "2000": {
                "college": "Utah State",
                "position": "DC"
            },
            "2001": {
                "college": "Utah State",
                "position": "DC"
            },
            "2002": {
                "college": "Utah State",
                "position": "DC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "UNLV",
                "position": "DC"
            },
            "2011": {
                "college": "UNLV",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "-0.0189034072154724",
                "net_sr": "-0.0134184687112966",
                "net_stuff": "-0.0228644206148169",
                "net_pass_sr": "-0.0220522607195646",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0189034072154724
    },
    {
        "id": 887,
        "name": "J.D. Williams",
        "community": 11,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 6.00704736255955e-8
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "UNLV",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "0.0636345160946074",
                "net_sr": "0.0504815189914136",
                "net_stuff": "-0.00848870810316982",
                "net_pass_sr": "0.0394169546216201",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0636345160946074
    },
    {
        "id": 888,
        "name": "Tim Hauck",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 1.20140947260112e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "UNLV",
                "position": "DC"
            },
            "2014": {
                "college": "UNLV",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "-0.0318957089647784",
                "net_sr": "-0.00551457519828147",
                "net_stuff": "0.0274799623057219",
                "net_pass_sr": "0.00834391824646491",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0318957089647784
    },
    {
        "id": 889,
        "name": "Tim Skipper",
        "community": 18,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000779905935337684
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "UNLV",
                "position": "DC"
            },
            "2019": {
                "college": "UNLV",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "-0.174852632584929",
                "net_sr": "-0.0652677604197289",
                "net_stuff": "0.056531721525174",
                "net_pass_sr": "0.000694780394116634",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.174852632584929
    },
    {
        "id": 890,
        "name": "Peter Hansen",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000139271575735491
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "UNLV",
                "position": "DC"
            },
            "2021": {
                "college": "UNLV",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "-0.111905893977741",
                "net_sr": "-0.0183347174314257",
                "net_stuff": "-0.00969191649836004",
                "net_pass_sr": "0.00731332499470649",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.111905893977741
    },
    {
        "id": 891,
        "name": "Rocky Seto",
        "community": 39,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000028492273627853
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "USC",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "0.236631365146156",
                "net_sr": "0.0708081919188487",
                "net_stuff": "-0.0598534013707209",
                "net_pass_sr": "0.0851465618052887",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Other",
        "aggregate_xpa": 0.236631365146156
    },
    {
        "id": 892,
        "name": "Raymond Woodie",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000419335112290057
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "South Florida",
                "position": "DC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.0402436947739675",
                "net_sr": "0.00996857820349689",
                "net_stuff": "-0.0359763406554517",
                "net_pass_sr": "0.00730279607443485",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0402436947739675
    },
    {
        "id": 893,
        "name": "Brian Jean-Mary",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000421171219521803
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "South Florida",
                "position": "DC"
            },
            "2018": {
                "college": "South Florida",
                "position": "DC"
            },
            "2019": {
                "college": "South Florida",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.00431689677335836",
                "net_sr": "0.0300572656541063",
                "net_stuff": "-0.0259616782415275",
                "net_pass_sr": "0.0596095738549131",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.00431689677335836
    },
    {
        "id": 894,
        "name": "John Pease",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000131819866974549
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Utah",
                "position": "DC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "0.0261776755804905",
                "net_sr": "-0.00825945331933647",
                "net_stuff": "-0.0294758013235012",
                "net_pass_sr": "-0.0195330390862531",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0261776755804905
    },
    {
        "id": 895,
        "name": "Morgan Scalley",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000790919201847376
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Utah",
                "position": "DC"
            },
            "2017": {
                "college": "Utah",
                "position": "DC"
            },
            "2018": {
                "college": "Utah",
                "position": "DC"
            },
            "2019": {
                "college": "Utah",
                "position": "DC"
            },
            "2020": {
                "college": "Utah",
                "position": "DC"
            },
            "2021": {
                "college": "Utah",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "-0.00146118331489467",
                "net_sr": "0.0119290633991986",
                "net_stuff": "-0.0181332835109899",
                "net_pass_sr": "-5.57171769579035e-05",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00146118331489467
    },
    {
        "id": 896,
        "name": "David Kotulski",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000641420598955805
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Utah State",
                "position": "DC"
            },
            "2004": {
                "college": "Utah State",
                "position": "DC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Vanderbilt",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.167813263933569",
                "net_sr": "-0.0672079483331752",
                "net_stuff": "0.051888785497228",
                "net_pass_sr": "-0.0541092567113982",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.167813263933569
    },
    {
        "id": 898,
        "name": "Frank Maile",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000149160466714857
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Utah State",
                "position": "DC"
            },
            "2017": {
                "college": "Utah State",
                "position": "DC"
            },
            "2018": {
                "college": "Utah State",
                "position": "DC"
            },
            "2019": false,
            "2020": {
                "college": "Utah State",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "-0.102861247151848",
                "net_sr": "-0.060031310707525",
                "net_stuff": "0.0378333590200286",
                "net_pass_sr": "-0.0421396644544164",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.102861247151848
    },
    {
        "id": 899,
        "name": "Kendrick Shaver",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000576785546306327
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Utah State",
                "position": "DC"
            },
            "2017": {
                "college": "Utah State",
                "position": "DC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "-0.0752582062334785",
                "net_sr": "-0.04570977106164",
                "net_stuff": "0.0380514094253337",
                "net_pass_sr": "0.000796912397301008",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0752582062334785
    },
    {
        "id": 900,
        "name": "Justin Ena",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000626426347688999
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Utah State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "-0.102966355585098",
                "net_sr": "-0.058790586066036",
                "net_stuff": "0.020180259588887",
                "net_pass_sr": "-0.0761949914411788",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.102966355585098
    },
    {
        "id": 901,
        "name": "Stacy Collins",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000062642634768899
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Utah State",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "-0.056368257897134",
                "net_sr": "-0.012840286390792",
                "net_stuff": "0.024859170395878",
                "net_pass_sr": "-0.0154207451329712",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.056368257897134
    },
    {
        "id": 903,
        "name": "Tim Hundley",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000021590362548854
        },
        "positions": {
            "2000": {
                "college": "Washington",
                "position": "DC"
            },
            "2001": {
                "college": "Washington",
                "position": "DC"
            },
            "2002": {
                "college": "Washington",
                "position": "DC"
            },
            "2003": {
                "college": "Washington",
                "position": "DC"
            },
            "2004": {
                "college": "UTEP",
                "position": "DC"
            },
            "2005": {
                "college": "UTEP",
                "position": "DC"
            },
            "2006": {
                "college": "UTEP",
                "position": "DC"
            },
            "2007": {
                "college": "UTEP",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "0.0620430074744456",
                "net_sr": "0.0246112106246049",
                "net_stuff": "-0.037037279998445",
                "net_pass_sr": "0.0801880247734091",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0620430074744456
    },
    {
        "id": 904,
        "name": "Andre Patterson",
        "community": 39,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000790725632342882
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "UTEP",
                "position": "DC"
            },
            "2011": {
                "college": "UTEP",
                "position": "DC"
            },
            "2012": {
                "college": "UTEP",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "-0.00524068037095377",
                "net_sr": "0.0194167319033499",
                "net_stuff": "0.00812601652320663",
                "net_pass_sr": "0.017356773571079",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.00524068037095377
    },
    {
        "id": 905,
        "name": "Mike Cox",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000316130381290484
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "UTEP",
                "position": "DC"
            },
            "2019": {
                "college": "UTEP",
                "position": "DC"
            },
            "2020": {
                "college": "UTEP",
                "position": "DC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "0.00902613725473467",
                "net_sr": "0.0120608936441324",
                "net_stuff": "-0.00485320772448569",
                "net_pass_sr": "0.00794013291921442",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00902613725473467
    },
    {
        "id": 906,
        "name": "Jess Loepp",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 4.50706670978997e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "UT San Antonio",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "UT San Antonio",
                "role": "OC",
                "net_ppa": "0.0246848586229519",
                "net_sr": "0.026279791259584",
                "net_stuff": "0.0290394944441192",
                "net_pass_sr": "0.00788641497353215",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0246848586229519
    },
    {
        "id": 907,
        "name": "Rod Wright",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 4.50706670979199e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "UT San Antonio",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "UT San Antonio",
                "role": "OC",
                "net_ppa": "-0.169745752585269",
                "net_sr": "-0.0342580005802623",
                "net_stuff": "0.00457532547056447",
                "net_pass_sr": "-0.0120025368593615",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.169745752585269
    },
    {
        "id": 911,
        "name": "Kelly Poppinga",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000139873916435157
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Virginia",
                "position": "DC"
            },
            "2019": {
                "college": "Virginia",
                "position": "DC"
            },
            "2020": {
                "college": "Virginia",
                "position": "DC"
            },
            "2021": {
                "college": "Virginia",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "0.00122809694638895",
                "net_sr": "0.0213021954613452",
                "net_stuff": "0.0155433160857169",
                "net_pass_sr": "0.0161512078892405",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00122809694638895
    },
    {
        "id": 913,
        "name": "Justin Hamilton",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000448413023938757
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Virginia Tech",
                "position": "DC"
            },
            "2021": {
                "college": "Virginia Tech",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Virginia Tech",
                "role": "OC",
                "net_ppa": "-0.121007565312237",
                "net_sr": "-0.0745605519511682",
                "net_stuff": "0.0617997532288774",
                "net_pass_sr": "-0.0526147453895371",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.121007565312237
    },
    {
        "id": 917,
        "name": "Tim Billings",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000332568387363751
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Wake Forest",
                "role": "OC",
                "net_ppa": "0.159399766361825",
                "net_sr": "0.0340183884051906",
                "net_stuff": "0.0643692486229049",
                "net_pass_sr": "0.0693585924758794",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.159399766361825
    },
    {
        "id": 918,
        "name": "Lyle Hemphill",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000407203134622841
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2020": {
                "college": "Wake Forest",
                "position": "DC"
            },
            "2021": {
                "college": "Wake Forest",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Wake Forest",
                "role": "OC",
                "net_ppa": "-0.0481705234617265",
                "net_sr": "-0.0250455689084134",
                "net_stuff": "-0.000881551314535545",
                "net_pass_sr": "-0.0144685898670129",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0481705234617265
    },
    {
        "id": 919,
        "name": "Ed Donatell",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000031022070228566
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Washington",
                "position": "DC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "-0.0361837173079038",
                "net_sr": "-0.0711464780785759",
                "net_stuff": "0.0929792916493732",
                "net_pass_sr": "-0.0275700005874869",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0361837173079038
    },
    {
        "id": 920,
        "name": "Ikaika Malloe",
        "community": 23,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000846961521379808
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Washington",
                "position": "DC"
            },
            "2021": {
                "college": "Washington",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "-0.119127029492931",
                "net_sr": "-0.0549933984303699",
                "net_stuff": "0.0507461766262145",
                "net_pass_sr": "0.0041985959075258",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.119127029492931
    },
    {
        "id": 921,
        "name": "Jody Sears",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000681999967326096
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Washington State",
                "position": "DC"
            },
            "2009": {
                "college": "Washington State",
                "position": "DC"
            },
            "2010": {
                "college": "Washington State",
                "position": "DC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "-0.0513823162055302",
                "net_sr": "-0.0482893177400905",
                "net_stuff": "0.0184442241386766",
                "net_pass_sr": "-0.029139617745735",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0513823162055302
    },
    {
        "id": 922,
        "name": "Mike Breske",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000118717069353633
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2004": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2005": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2006": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2007": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Washington State",
                "position": "DC"
            },
            "2013": {
                "college": "Washington State",
                "position": "DC"
            },
            "2014": {
                "college": "Washington State",
                "position": "DC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "0.0094611850496872",
                "net_sr": "0.0291250599964815",
                "net_stuff": "-0.0222302205576489",
                "net_pass_sr": "0.0167674746780422",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0094611850496872
    },
    {
        "id": 923,
        "name": "Darcel McBath",
        "community": 14,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000373321497473527
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Washington State",
                "position": "DC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "-0.126027126425576",
                "net_sr": "-0.0483950544683417",
                "net_stuff": "0.0803342203294402",
                "net_pass_sr": "-0.0377710129954704",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.126027126425576
    },
    {
        "id": 924,
        "name": "Jake Dickert",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00657536993827388
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Wyoming",
                "position": "DC"
            },
            "2020": {
                "college": "Washington State",
                "position": "DC"
            },
            "2021": {
                "college": "Washington State",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "-0.12343969875588",
                "net_sr": "-0.0315829059565677",
                "net_stuff": "0.00756862293012012",
                "net_pass_sr": "-0.0545829252413573",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "0.0604829812031452",
                "net_sr": "0.019283959415944",
                "net_stuff": "-0.0282631632687404",
                "net_pass_sr": "-0.0154825350117919",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0629567175527348
    },
    {
        "id": 925,
        "name": "Joe DeForest",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000397740269371241
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "-0.122341971236766",
                "net_sr": "-0.0559584097275562",
                "net_stuff": "0.0118529834400322",
                "net_pass_sr": "-0.109871929340243",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.122341971236766
    },
    {
        "id": 926,
        "name": "Jordan Lesley",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000833237579147698
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "West Virginia",
                "position": "DC"
            },
            "2021": {
                "college": "West Virginia",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "0.0656182380860409",
                "net_sr": "0.0526212161902709",
                "net_stuff": "0.00804581627075154",
                "net_pass_sr": "0.0373716908396452",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0656182380860409
    },
    {
        "id": 927,
        "name": "ShaDon Brown",
        "community": 27,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000416618789573765
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "West Virginia",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "-0.0602141717446634",
                "net_sr": "-0.0291663122877544",
                "net_stuff": "-0.0750980701547791",
                "net_pass_sr": "-0.0233700062497253",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0602141717446634
    },
    {
        "id": 928,
        "name": "Maurice Crum Jr",
        "community": 3,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000555793035460235
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Western Kentucky",
                "position": "DC"
            },
            "2021": {
                "college": "Western Kentucky",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "-0.0561301435561378",
                "net_sr": "-0.0226280367622265",
                "net_stuff": "-0.0195730307439234",
                "net_pass_sr": "-0.0291259798002268",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0561301435561378
    },
    {
        "id": 930,
        "name": "Steve Morrison",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000267198258601886
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2009": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "0.0944886543301424",
                "net_sr": "0.0848329943798717",
                "net_stuff": "-0.039722581857686",
                "net_pass_sr": "0.120988475170635",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0944886543301424
    },
    {
        "id": 931,
        "name": "Lou Esposito",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000517425334210938
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2018": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2019": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2020": {
                "college": "Western Michigan",
                "position": "DC"
            },
            "2021": {
                "college": "Western Michigan",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "-0.0545859735064026",
                "net_sr": "-0.00721013716093277",
                "net_stuff": "-0.00777234169007832",
                "net_pass_sr": "-0.0183975229912202",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0545859735064026
    },
    {
        "id": 932,
        "name": "Jim Leonhard",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000243207296268666
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2018": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2019": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2020": {
                "college": "Wisconsin",
                "position": "DC"
            },
            "2021": {
                "college": "Wisconsin",
                "position": "DC"
            }
        },
        "performance": [
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "0.0174868875247945",
                "net_sr": "0.00650385861982811",
                "net_stuff": "-0.00913420326844547",
                "net_pass_sr": "-0.0105016733579076",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "DC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0174868875247945
    },
    {
        "id": 934,
        "name": "Clay Hendrix",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000634459078677798
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Air Force",
                "position": "OC"
            },
            "2008": {
                "college": "Air Force",
                "position": "OC"
            },
            "2009": {
                "college": "Air Force",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Air Force",
                "role": "OC",
                "net_ppa": "-0.00520177216314433",
                "net_sr": "-0.0194675983654827",
                "net_stuff": "-0.0115710095806077",
                "net_pass_sr": "-0.0358451985932384",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00520177216314433
    },
    {
        "id": 935,
        "name": "Tim Horton",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000021148635955918
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Air Force",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Air Force",
                "role": "OC",
                "net_ppa": "0.0404598118891819",
                "net_sr": "0.00396245782564447",
                "net_stuff": "-0.0164156731274468",
                "net_pass_sr": "0.00913183204939877",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0404598118891819
    },
    {
        "id": 936,
        "name": "Blane Morgan",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000126891815735565
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Air Force",
                "position": "OC"
            },
            "2009": {
                "college": "Air Force",
                "position": "OC"
            },
            "2010": {
                "college": "Air Force",
                "position": "OC"
            },
            "2011": {
                "college": "Air Force",
                "position": "OC"
            },
            "2012": {
                "college": "Air Force",
                "position": "OC"
            },
            "2013": {
                "college": "Air Force",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Air Force",
                "role": "OC",
                "net_ppa": "0.0202167801792201",
                "net_sr": "0.00692250595751037",
                "net_stuff": "-0.0185817515865614",
                "net_pass_sr": "-0.0134134091891162",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0202167801792201
    },
    {
        "id": 937,
        "name": "Mike Thiessen",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000211486359559298
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Air Force",
                "position": "OC"
            },
            "2010": {
                "college": "Air Force",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Air Force",
                "position": "OC"
            },
            "2015": {
                "college": "Air Force",
                "position": "OC"
            },
            "2016": {
                "college": "Air Force",
                "position": "OC"
            },
            "2017": {
                "college": "Air Force",
                "position": "OC"
            },
            "2018": {
                "college": "Air Force",
                "position": "OC"
            },
            "2019": {
                "college": "Air Force",
                "position": "OC"
            },
            "2020": {
                "college": "Air Force",
                "position": "OC"
            },
            "2021": {
                "college": "Air Force",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Air Force",
                "role": "OC",
                "net_ppa": "0.142212825042835",
                "net_sr": "0.0575191902128478",
                "net_stuff": "-0.0312913537733931",
                "net_pass_sr": "0.0369940858287641",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.142212825042835
    },
    {
        "id": 940,
        "name": "John Latina",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000443469076423917
        },
        "positions": {
            "2000": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2001": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2002": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2003": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2004": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Akron",
                "position": "OC"
            },
            "2011": {
                "college": "Akron",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "-0.0403541491104384",
                "net_sr": "0.0289010632892452",
                "net_stuff": "-0.019432906473255",
                "net_pass_sr": "0.0182230821081855",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0403541491104384
    },
    {
        "id": 941,
        "name": "A.J. Milwee",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000559862327000178
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Akron",
                "position": "OC"
            },
            "2014": {
                "college": "Akron",
                "position": "OC"
            },
            "2015": {
                "college": "Akron",
                "position": "OC"
            },
            "2016": {
                "college": "Akron",
                "position": "OC"
            },
            "2017": {
                "college": "Akron",
                "position": "OC"
            },
            "2018": {
                "college": "Akron",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "0.101398921597912",
                "net_sr": "0.0179047809271867",
                "net_stuff": "0.00937668047518736",
                "net_pass_sr": "0.039654674230633",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.101398921597912
    },
    {
        "id": 942,
        "name": "Tommy Zagorski",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 3.98519480724661e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Akron",
                "position": "OC"
            },
            "2020": {
                "college": "Akron",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "-0.123903069102042",
                "net_sr": "-0.0472662574972477",
                "net_stuff": "0.0096602688128905",
                "net_pass_sr": "-0.0522515040110435",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.123903069102042
    },
    {
        "id": 943,
        "name": "Bryan Gasser",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 1.99259740352845e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Akron",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Akron",
                "role": "OC",
                "net_ppa": "0.167423159701112",
                "net_sr": "0.077181694368028",
                "net_stuff": "-0.0836170362689633",
                "net_pass_sr": "0.0915846893565806",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.167423159701112
    },
    {
        "id": 944,
        "name": "Charlie Stubbs",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000302901913041416
        },
        "positions": {
            "2000": {
                "college": "Alabama",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2004": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2005": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2006": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2007": {
                "college": "Louisville",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "-0.0280417970911604",
                "net_sr": "-0.0215618182662053",
                "net_stuff": "0.00538224063693232",
                "net_pass_sr": "-0.0520811389583759",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0280417970911604
    },
    {
        "id": 945,
        "name": "Les Koenning",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00125713922083528
        },
        "positions": {
            "2000": {
                "college": "TCU",
                "position": "OC"
            },
            "2001": {
                "college": "Alabama",
                "position": "OC"
            },
            "2002": {
                "college": "Alabama",
                "position": "OC"
            },
            "2003": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2004": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2005": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2006": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2007": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2008": false,
            "2009": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2010": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2011": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2012": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2013": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "UAB",
                "position": "OC"
            },
            "2018": false,
            "2019": {
                "college": "Kansas",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "0.122849682534372",
                "net_sr": "0.0497075228839595",
                "net_stuff": "0.0312967006584976",
                "net_pass_sr": "0.057699607746084",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.103056208209603",
                "net_sr": "0.0859511328970313",
                "net_stuff": "-0.0318372596664455",
                "net_pass_sr": "0.0941137983729906",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "0.051461285439269",
                "net_sr": "-0.0151562179423106",
                "net_stuff": "-0.0203624135998506",
                "net_pass_sr": "0.00803418803418804",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.27736717618324397
    },
    {
        "id": 946,
        "name": "David Rader",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000858555639894814
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Alabama",
                "position": "OC"
            },
            "2004": {
                "college": "Alabama",
                "position": "OC"
            },
            "2005": {
                "college": "Alabama",
                "position": "OC"
            },
            "2006": {
                "college": "Alabama",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0179898842605212",
                "net_sr": "-0.0322612616167856",
                "net_stuff": "0.0598517860791514",
                "net_pass_sr": "-0.0213652558871668",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0179898842605212
    },
    {
        "id": 947,
        "name": "Joe Pendry",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000133067445112862
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Alabama",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "-0.167414817802081",
                "net_sr": "-0.0622367074044656",
                "net_stuff": "0.0314491396753847",
                "net_pass_sr": "-0.0622284189011497",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.167414817802081
    },
    {
        "id": 948,
        "name": "Doug Nussmeier",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.0011151230258782
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2009": {
                "college": "Washington",
                "position": "OC"
            },
            "2010": {
                "college": "Washington",
                "position": "OC"
            },
            "2011": {
                "college": "Washington",
                "position": "OC"
            },
            "2012": {
                "college": "Alabama",
                "position": "OC"
            },
            "2013": {
                "college": "Alabama",
                "position": "OC"
            },
            "2014": {
                "college": "Michigan",
                "position": "OC"
            },
            "2015": {
                "college": "Florida",
                "position": "OC"
            },
            "2016": {
                "college": "Florida",
                "position": "OC"
            },
            "2017": {
                "college": "Florida",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.0805902203334909",
                "net_sr": "0.0301222196788629",
                "net_stuff": "-0.0296740891633428",
                "net_pass_sr": "0.000695074659329331",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "0.0626534155108871",
                "net_sr": "0.00476219544575068",
                "net_stuff": "-0.0288529941083346",
                "net_pass_sr": "-0.00522022392272709",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "0.0271454537442586",
                "net_sr": "0.0443029767895489",
                "net_stuff": "0.0263341795008967",
                "net_pass_sr": "0.0373192642100031",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.0214032240754378",
                "net_sr": "0.00639184213659671",
                "net_stuff": "-0.0679971000521462",
                "net_pass_sr": "-0.0351312696818026",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "0.105057606392543",
                "net_sr": "0.0488908041845024",
                "net_stuff": "-0.0334277828938242",
                "net_pass_sr": "0.0590972685647507",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.29684992005661737
    },
    {
        "id": 949,
        "name": "Brian Daboll",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000133067445112861
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Alabama",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.0164225446164731",
                "net_sr": "0.00546363085379964",
                "net_stuff": "-0.03421205043052",
                "net_pass_sr": "-0.00914490872494012",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0164225446164731
    },
    {
        "id": 950,
        "name": "Josh Gattis",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000220314656480032
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Alabama",
                "position": "OC"
            },
            "2019": {
                "college": "Michigan",
                "position": "OC"
            },
            "2020": {
                "college": "Michigan",
                "position": "OC"
            },
            "2021": {
                "college": "Michigan",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Alabama",
                "role": "OC",
                "net_ppa": "0.0117714137169545",
                "net_sr": "0.0270778763794446",
                "net_stuff": "-0.00878510501718718",
                "net_pass_sr": "0.00292427702858034",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.0921423671126726",
                "net_sr": "0.0497016276615505",
                "net_stuff": "-0.0383292805135477",
                "net_pass_sr": "0.0525541956187894",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.1039137808296271
    },
    {
        "id": 951,
        "name": "Dwayne Ledford",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000013166172858338
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2015": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Louisville",
                "position": "OC"
            },
            "2020": {
                "college": "Louisville",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "OC",
                "net_ppa": "0.293200747881571",
                "net_sr": "0.113513028615928",
                "net_stuff": "-0.0771385546684994",
                "net_pass_sr": "0.0683633335782333",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.0199752672000756",
                "net_sr": "-0.0377901903214966",
                "net_stuff": "0.0623306207923883",
                "net_pass_sr": "0.00612352110624076",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.3131760150816466
    },
    {
        "id": 952,
        "name": "Frank Ponce",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000173205100704991
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2015": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2016": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2017": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2018": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Appalachian State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "OC",
                "net_ppa": "0.276857929813156",
                "net_sr": "0.104116022726089",
                "net_stuff": "-0.0894719629511273",
                "net_pass_sr": "0.061149307571213",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.276857929813156
    },
    {
        "id": 953,
        "name": "Tony Petersen",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.00018267555645441
        },
        "positions": {
            "2000": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2001": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2002": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2003": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2004": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2005": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2014": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2015": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2016": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2017": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2018": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2019": false,
            "2020": {
                "college": "Appalachian State",
                "position": "OC"
            },
            "2021": {
                "college": "Illinois",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Appalachian State",
                "role": "OC",
                "net_ppa": "-0.0115423085815967",
                "net_sr": "0.0293825038294255",
                "net_stuff": "-0.0171576203075505",
                "net_pass_sr": "0.00646393146393148",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "-0.0631116753066329",
                "net_sr": "-0.0655050194110472",
                "net_stuff": "0.0156353438082528",
                "net_pass_sr": "-0.0840418081272992",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "-0.022701728590275",
                "net_sr": "-0.00808493200492283",
                "net_stuff": "-0.0805535110901681",
                "net_pass_sr": "-0.00583479208074678",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "0.133208300403417",
                "net_sr": "0.00426612075767779",
                "net_stuff": "0.0380908119884273",
                "net_pass_sr": "0.00932427724546742",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.03585258792491239
    },
    {
        "id": 956,
        "name": "Mike Canales",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.0037518456748391
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Arizona",
                "position": "OC"
            },
            "2005": {
                "college": "Arizona",
                "position": "OC"
            },
            "2006": {
                "college": "Arizona",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": {
                "college": "South Florida",
                "position": "OC"
            },
            "2010": false,
            "2011": {
                "college": "North Texas",
                "position": "OC"
            },
            "2012": {
                "college": "North Texas",
                "position": "OC"
            },
            "2013": {
                "college": "North Texas",
                "position": "OC"
            },
            "2014": {
                "college": "North Texas",
                "position": "OC"
            },
            "2015": {
                "college": "North Texas",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": {
                "college": "UTEP",
                "position": "OC"
            },
            "2019": {
                "college": "UTEP",
                "position": "OC"
            },
            "2020": {
                "college": "UTEP",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "-0.0271214410294878",
                "net_sr": "-0.0262686762680459",
                "net_stuff": "0.0114818272906431",
                "net_pass_sr": "-0.0111745163747191",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.00968692106863273",
                "net_sr": "-0.0327717589695244",
                "net_stuff": "-0.000715382619884242",
                "net_pass_sr": "-0.056802220784775",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "0.0812803332886622",
                "net_sr": "0.00844871029703392",
                "net_stuff": "0.0124450749431575",
                "net_pass_sr": "0.0175258785545976",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.06384581332780713
    },
    {
        "id": 957,
        "name": "Bill Bedenbaugh",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00321271308035789
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Arizona",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2017": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2018": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2019": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2020": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2021": {
                "college": "Oklahoma",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "0.0857923897233149",
                "net_sr": "0.047617643002024",
                "net_stuff": "-0.0307381466601911",
                "net_pass_sr": "0.075171718093097",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma",
                "role": "OC",
                "net_ppa": "0.203274454713235",
                "net_sr": "0.0603116823133836",
                "net_stuff": "0.00275644889948723",
                "net_pass_sr": "0.096117600739795",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2890668444365499
    },
    {
        "id": 958,
        "name": "Calvin Magee",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00179853570938309
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2006": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2007": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2008": {
                "college": "Michigan",
                "position": "OC"
            },
            "2009": {
                "college": "Michigan",
                "position": "OC"
            },
            "2010": {
                "college": "Michigan",
                "position": "OC"
            },
            "2011": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2012": {
                "college": "Arizona",
                "position": "OC"
            },
            "2013": {
                "college": "Arizona",
                "position": "OC"
            },
            "2014": {
                "college": "Arizona",
                "position": "OC"
            },
            "2015": {
                "college": "Arizona",
                "position": "OC"
            },
            "2016": {
                "college": "Arizona",
                "position": "OC"
            },
            "2017": {
                "college": "Arizona",
                "position": "OC"
            },
            "2018": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "0.09244853148579",
                "net_sr": "0.00107053881145014",
                "net_stuff": "-0.0645136947856389",
                "net_pass_sr": "-0.0443630934289643",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.0036945266120986",
                "net_sr": "0.00382487950185972",
                "net_stuff": "0.00609981808981913",
                "net_pass_sr": "-0.0189852420812524",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "-0.11287528072068",
                "net_sr": "-0.0119419834291726",
                "net_stuff": "0.0300213080276657",
                "net_pass_sr": "0.0260122485665241",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "0.0126933554616749",
                "net_sr": "-0.00840867216131497",
                "net_stuff": "-0.00553824780077339",
                "net_pass_sr": "0.00322632005637641",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.004038867161116493
    },
    {
        "id": 959,
        "name": "Rod Smith",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00162316087258034
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "South Florida",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Indiana",
                "position": "OC"
            },
            "2012": {
                "college": "Arizona",
                "position": "OC"
            },
            "2013": {
                "college": "Arizona",
                "position": "OC"
            },
            "2014": {
                "college": "Arizona",
                "position": "OC"
            },
            "2015": {
                "college": "Arizona",
                "position": "OC"
            },
            "2016": {
                "college": "Arizona",
                "position": "OC"
            },
            "2017": {
                "college": "Arizona",
                "position": "OC"
            },
            "2018": {
                "college": "Illinois",
                "position": "OC"
            },
            "2019": {
                "college": "Illinois",
                "position": "OC"
            },
            "2020": {
                "college": "Illinois",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "0.09244853148579",
                "net_sr": "0.00107053881145014",
                "net_stuff": "-0.0645136947856389",
                "net_pass_sr": "-0.0443630934289643",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "0.0783493489665952",
                "net_sr": "0.0199005745771226",
                "net_stuff": "-0.0207376969533465",
                "net_pass_sr": "-0.0177361047012362",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.042228386370985",
                "net_sr": "-0.0242971323330824",
                "net_stuff": "-0.0687854415182977",
                "net_pass_sr": "-0.051984810464218",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.0249225651799082",
                "net_sr": "0.025927210111056",
                "net_stuff": "-0.0118214588634436",
                "net_pass_sr": "0.104074966813386",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2379488320032784
    },
    {
        "id": 960,
        "name": "Noel Mazzone",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 6,
            "eigen": 0.000655595789053545
        },
        "positions": {
            "2000": {
                "college": "Auburn",
                "position": "OC"
            },
            "2001": {
                "college": "Auburn",
                "position": "OC"
            },
            "2002": false,
            "2003": {
                "college": "NC State",
                "position": "OC"
            },
            "2004": {
                "college": "NC State",
                "position": "OC"
            },
            "2005": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2011": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2012": {
                "college": "UCLA",
                "position": "OC"
            },
            "2013": {
                "college": "UCLA",
                "position": "OC"
            },
            "2014": {
                "college": "UCLA",
                "position": "OC"
            },
            "2015": {
                "college": "UCLA",
                "position": "OC"
            },
            "2016": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2017": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2018": {
                "college": "Arizona",
                "position": "OC"
            },
            "2019": {
                "college": "Arizona",
                "position": "OC"
            },
            "2020": {
                "college": "Arizona",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "-0.0728875826194678",
                "net_sr": "-0.0267022909370692",
                "net_stuff": "0.0154129666243283",
                "net_pass_sr": "-0.0169730162701148",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.0605976619163019",
                "net_sr": "0.0479319188023338",
                "net_stuff": "-0.011269276679758",
                "net_pass_sr": "0.053801972454176",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "0.0145720276094058",
                "net_sr": "-0.0653604194619207",
                "net_stuff": "0.0175784601022104",
                "net_pass_sr": "-0.0562248248513925",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "0.133283336325817",
                "net_sr": "0.0456701717028544",
                "net_stuff": "-0.00689703945997541",
                "net_pass_sr": "0.0603171012721003",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.13556544323205688
    },
    {
        "id": 961,
        "name": "Brennan Carroll",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000214122717852419
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Arizona",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Arizona",
                "role": "OC",
                "net_ppa": "-0.138475547720855",
                "net_sr": "-0.039380643424098",
                "net_stuff": "0.000883986001020243",
                "net_pass_sr": "-0.0280719999442563",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.138475547720855
    },
    {
        "id": 963,
        "name": "Roy Wittke",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000093047529032133
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2007": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "-0.132365529114503",
                "net_sr": "-0.0360702917454467",
                "net_stuff": "-0.0426729885909023",
                "net_pass_sr": "-0.0671114208901723",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "-0.094870513511566",
                "net_sr": "-0.0119534952659231",
                "net_stuff": "-0.0581822233799311",
                "net_pass_sr": "-0.0321447297401496",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.227236042626069
    },
    {
        "id": 964,
        "name": "Rich Olson",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000517460911922759
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Miami",
                "position": "OC"
            },
            "2007": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2008": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2009": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "-0.12445897890484",
                "net_sr": "-0.0614145942266116",
                "net_stuff": "0.0209224664392159",
                "net_pass_sr": "-0.0574628749654112",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "-0.0188829284891103",
                "net_sr": "0.0105124835742444",
                "net_stuff": "0.017769635905656",
                "net_pass_sr": "0.0200810524149504",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1433419073939503
    },
    {
        "id": 965,
        "name": "Rob Likens",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000106422225222699
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Kansas",
                "position": "OC"
            },
            "2016": {
                "college": "Kansas",
                "position": "OC"
            },
            "2017": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2018": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2019": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.0286874479268933",
                "net_sr": "0.0161583623006759",
                "net_stuff": "0.00161666364063018",
                "net_pass_sr": "0.0105887632521597",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "0.0179017753744271",
                "net_sr": "-0.00536117109420642",
                "net_stuff": "-0.0191302919681417",
                "net_pass_sr": "0.0243156191567568",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0465892233013204
    },
    {
        "id": 966,
        "name": "Zak Hill",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00246651282829718
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Boise State",
                "position": "OC"
            },
            "2017": {
                "college": "Boise State",
                "position": "OC"
            },
            "2018": {
                "college": "Boise State",
                "position": "OC"
            },
            "2019": {
                "college": "Boise State",
                "position": "OC"
            },
            "2020": {
                "college": "Arizona State",
                "position": "OC"
            },
            "2021": {
                "college": "Arizona State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Arizona State",
                "role": "OC",
                "net_ppa": "0.115070732916375",
                "net_sr": "0.0488215133910445",
                "net_stuff": "-0.0532556924120724",
                "net_pass_sr": "-0.02131374815815",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "0.0465859872261842",
                "net_sr": "0.00642310031433835",
                "net_stuff": "-0.0204912166653748",
                "net_pass_sr": "-0.00775640547043049",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1616567201425592
    },
    {
        "id": 967,
        "name": "David Lee",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000153159123772332
        },
        "positions": {
            "2000": {
                "college": "Rice",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "0.00321206116878018",
                "net_sr": "0.0301690057468401",
                "net_stuff": "-0.0246632617166822",
                "net_pass_sr": "0.0369409084172556",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0830132305536807",
                "net_sr": "-0.0519767452945334",
                "net_stuff": "0.0319633617827713",
                "net_pass_sr": "-0.0747544876800122",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.07980116938490052
    },
    {
        "id": 968,
        "name": "Paul Petrino",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000166224599180158
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Louisville",
                "position": "OC"
            },
            "2004": {
                "college": "Louisville",
                "position": "OC"
            },
            "2005": {
                "college": "Louisville",
                "position": "OC"
            },
            "2006": {
                "college": "Louisville",
                "position": "OC"
            },
            "2007": false,
            "2008": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2009": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2010": {
                "college": "Illinois",
                "position": "OC"
            },
            "2011": {
                "college": "Illinois",
                "position": "OC"
            },
            "2012": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "0.0855101366420627",
                "net_sr": "0.0258879858752712",
                "net_stuff": "0.0269420657631815",
                "net_pass_sr": "0.0674227096164354",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "-0.0188743939669646",
                "net_sr": "-0.0288700195597233",
                "net_stuff": "-0.0374949048694134",
                "net_pass_sr": "-0.0196684886220355",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0666357426750981
    },
    {
        "id": 969,
        "name": "Jim Chaney",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 7,
            "eigen": 0.000725006798548041
        },
        "positions": {
            "2000": {
                "college": "Purdue",
                "position": "OC"
            },
            "2001": {
                "college": "Purdue",
                "position": "OC"
            },
            "2002": {
                "college": "Purdue",
                "position": "OC"
            },
            "2003": {
                "college": "Purdue",
                "position": "OC"
            },
            "2004": {
                "college": "Purdue",
                "position": "OC"
            },
            "2005": {
                "college": "Purdue",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2010": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2011": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2012": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2013": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2014": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2015": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2016": {
                "college": "Georgia",
                "position": "OC"
            },
            "2017": {
                "college": "Georgia",
                "position": "OC"
            },
            "2018": {
                "college": "Georgia",
                "position": "OC"
            },
            "2019": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2020": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "-0.143302096566552",
                "net_sr": "-0.0293093680446058",
                "net_stuff": "0.0165895648328767",
                "net_pass_sr": "-0.0203553680333405",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "0.0453659751473725",
                "net_sr": "0.00872488430593932",
                "net_stuff": "0.0105404735810054",
                "net_pass_sr": "0.0527141301967634",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "0.0647924905449577",
                "net_sr": "0.0441054464347226",
                "net_stuff": "-0.0255349866559557",
                "net_pass_sr": "0.0588906269522288",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.140939902240775",
                "net_sr": "-0.0406643816796101",
                "net_stuff": "0.0455234604104509",
                "net_pass_sr": "-0.00369006542437272",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 18,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.17408353311499677
    },
    {
        "id": 970,
        "name": "Joe Craddock",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000539161509432363
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "SMU",
                "position": "OC"
            },
            "2016": {
                "college": "SMU",
                "position": "OC"
            },
            "2017": {
                "college": "SMU",
                "position": "OC"
            },
            "2018": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2019": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "0.0772528598330024",
                "net_sr": "0.0436193414062758",
                "net_stuff": "0.00461792220338259",
                "net_pass_sr": "0.0486658904190476",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "-0.114515434377267",
                "net_sr": "-0.0302445732271772",
                "net_stuff": "0.032434560785439",
                "net_pass_sr": "-0.0246280084831839",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0372625745442646
    },
    {
        "id": 971,
        "name": "Kendal Briles",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 6,
            "eigen": 0.000183534569504821
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Baylor",
                "position": "OC"
            },
            "2016": {
                "college": "Baylor",
                "position": "OC"
            },
            "2017": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2018": {
                "college": "Houston",
                "position": "OC"
            },
            "2019": {
                "college": "Florida State",
                "position": "OC"
            },
            "2020": {
                "college": "Arkansas",
                "position": "OC"
            },
            "2021": {
                "college": "Arkansas",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Arkansas",
                "role": "OC",
                "net_ppa": "-0.17992274459324",
                "net_sr": "-0.0819195206256919",
                "net_stuff": "0.0669680188997334",
                "net_pass_sr": "-0.106872238399183",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "-0.105284143909031",
                "net_sr": "0.00525148950726767",
                "net_stuff": "-0.0050638908610558",
                "net_pass_sr": "0.0674112223062731",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "-0.0735765981764901",
                "net_sr": "0.0102742428149529",
                "net_stuff": "-0.0486206888260527",
                "net_pass_sr": "-0.0149961553415228",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "-0.145288637981621",
                "net_sr": "-0.0268593745026757",
                "net_stuff": "0.0482863934608175",
                "net_pass_sr": "-0.0267155213636152",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "0.0623802806030828",
                "net_sr": "0.0539869737696357",
                "net_stuff": "-0.0811454001107341",
                "net_pass_sr": "0.061197860512929",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.4416918440572993
    },
    {
        "id": 974,
        "name": "Doug Ruse",
        "community": 22,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000156532864779411
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2003": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2004": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2005": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2006": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2007": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2008": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2009": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Georgia Southern",
                "position": "OC"
            },
            "2015": {
                "college": "Georgia Southern",
                "position": "OC"
            },
            "2016": {
                "college": "Tulane",
                "position": "OC"
            },
            "2017": {
                "college": "Tulane",
                "position": "OC"
            },
            "2018": {
                "college": "Tulane",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Georgia Southern",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "OC",
                "net_ppa": "0.100235022461299",
                "net_sr": "-0.0104996958538363",
                "net_stuff": "0.0113056072235248",
                "net_pass_sr": "0.265620465276728",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "0.115500037914871",
                "net_sr": "0.0280445632904401",
                "net_stuff": "-0.0425909403966966",
                "net_pass_sr": "0.00826972540834647",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.21573506037617002
    },
    {
        "id": 975,
        "name": "Rhett Lashlee",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00067973547375321
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2013": {
                "college": "Auburn",
                "position": "OC"
            },
            "2014": {
                "college": "Auburn",
                "position": "OC"
            },
            "2015": {
                "college": "Auburn",
                "position": "OC"
            },
            "2016": {
                "college": "Auburn",
                "position": "OC"
            },
            "2017": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2018": {
                "college": "SMU",
                "position": "OC"
            },
            "2019": {
                "college": "SMU",
                "position": "OC"
            },
            "2020": {
                "college": "Miami",
                "position": "OC"
            },
            "2021": {
                "college": "Miami",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "0.0672339943560713",
                "net_sr": "0.0526773945143742",
                "net_stuff": "-0.0307647907647908",
                "net_pass_sr": "0.0273146810010471",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "0.139772942042584",
                "net_sr": "0.0456286523129277",
                "net_stuff": "-0.0379956974626872",
                "net_pass_sr": "0.038674227771818",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "0.0766550449363555",
                "net_sr": "0.0268043627068828",
                "net_stuff": "-0.0269088916191101",
                "net_pass_sr": "0.0507749124344106",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "0.0889096428397851",
                "net_sr": "0.0110937254377537",
                "net_stuff": "0.0117180688533957",
                "net_pass_sr": "0.0565607694857272",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "1.58667010624614e-06",
                "net_sr": "-0.00342045979030781",
                "net_stuff": "0.0342914367157456",
                "net_pass_sr": "0.0133198353806779",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.37257321084490214
    },
    {
        "id": 976,
        "name": "Bush Hamdan",
        "community": 23,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00463943046512975
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Washington",
                "position": "OC"
            },
            "2019": {
                "college": "Washington",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "-0.0111847991187055",
                "net_sr": "-0.0172296823679722",
                "net_stuff": "-0.00644078144078145",
                "net_pass_sr": "-0.00771308351853095",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "-0.0434913213277162",
                "net_sr": "-0.0143387551245183",
                "net_stuff": "0.0105264456549685",
                "net_pass_sr": "-0.0384190927392816",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.0546761204464217
    },
    {
        "id": 977,
        "name": "Glen Elarbee",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000449682923727757
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2015": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "0.072801166376604",
                "net_sr": "-0.0127609021179452",
                "net_stuff": "0.0262413196066341",
                "net_pass_sr": "-0.0390769395807717",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.072801166376604
    },
    {
        "id": 978,
        "name": "Buster Faulkner",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000205760433826634
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2013": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2014": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2015": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2016": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2017": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2018": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2019": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "0.0132753242155563",
                "net_sr": "-0.00819557441919161",
                "net_stuff": "-0.0212394003855763",
                "net_pass_sr": "0.00185808630760403",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "0.153848763022475",
                "net_sr": "0.038726815222393",
                "net_stuff": "-0.0416261151311833",
                "net_pass_sr": "0.0378427059673053",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "-0.0104192059092347",
                "net_sr": "-0.0202299098634177",
                "net_stuff": "0.0328232815994925",
                "net_pass_sr": "0.0331070742956204",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1567048813287966
    },
    {
        "id": 979,
        "name": "Keith Heckendorf",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000600802065227897
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2020": {
                "college": "Arkansas State",
                "position": "OC"
            },
            "2021": {
                "college": "Arkansas State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Arkansas State",
                "role": "OC",
                "net_ppa": "0.179709645795243",
                "net_sr": "0.0613183019255303",
                "net_stuff": "-0.0422891218401477",
                "net_pass_sr": "0.0923223560847342",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.179709645795243
    },
    {
        "id": 980,
        "name": "John Bond",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.0000934026458509551
        },
        "positions": {
            "2000": {
                "college": "Army",
                "position": "OC"
            },
            "2001": {
                "college": "Army",
                "position": "OC"
            },
            "2002": {
                "college": "Army",
                "position": "OC"
            },
            "2003": {
                "college": "Army",
                "position": "OC"
            },
            "2004": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2005": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2006": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2007": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "UMass",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Tech",
                "role": "OC",
                "net_ppa": "0.0478413125634634",
                "net_sr": "0.00856217449541591",
                "net_stuff": "-0.00539772712666053",
                "net_pass_sr": "-0.0301531939872551",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UMass",
                "role": "OC",
                "net_ppa": "0.00398098747098208",
                "net_sr": "0.0512651130503551",
                "net_stuff": "-0.0262915128781715",
                "net_pass_sr": "0.0723424132515041",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.051822300034445476
    },
    {
        "id": 982,
        "name": "Tim Walsh",
        "community": 26,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Army",
                "position": "OC"
            },
            "2008": {
                "college": "Army",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "-0.0544004633871521",
                "net_sr": "-0.0419415515277992",
                "net_stuff": "-0.0356654196989002",
                "net_pass_sr": "-0.106336936812486",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0544004633871521
    },
    {
        "id": 983,
        "name": "Ian Shields",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000029189068035647
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Army",
                "position": "OC"
            },
            "2010": {
                "college": "Army",
                "position": "OC"
            },
            "2011": {
                "college": "Army",
                "position": "OC"
            },
            "2012": {
                "college": "Army",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "0.136703704301973",
                "net_sr": "0.0585721666829275",
                "net_stuff": "-0.0737680900797726",
                "net_pass_sr": "0.0347532744384807",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.136703704301973
    },
    {
        "id": 984,
        "name": "Brent Davis",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000475130091586307
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Army",
                "position": "OC"
            },
            "2015": {
                "college": "Army",
                "position": "OC"
            },
            "2016": {
                "college": "Army",
                "position": "OC"
            },
            "2017": {
                "college": "Army",
                "position": "OC"
            },
            "2018": {
                "college": "Army",
                "position": "OC"
            },
            "2019": {
                "college": "Army",
                "position": "OC"
            },
            "2020": {
                "college": "Army",
                "position": "OC"
            },
            "2021": {
                "college": "Army",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Army",
                "role": "OC",
                "net_ppa": "0.117537297382142",
                "net_sr": "0.0166007226631075",
                "net_stuff": "0.00150694973822099",
                "net_pass_sr": "0.0182932832979109",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.117537297382142
    },
    {
        "id": 986,
        "name": "Al Borges",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 7,
            "eigen": 0.000788061890309553
        },
        "positions": {
            "2000": {
                "college": "UCLA",
                "position": "OC"
            },
            "2001": {
                "college": "California",
                "position": "OC"
            },
            "2002": {
                "college": "Indiana",
                "position": "OC"
            },
            "2003": {
                "college": "Indiana",
                "position": "OC"
            },
            "2004": {
                "college": "Auburn",
                "position": "OC"
            },
            "2005": {
                "college": "Auburn",
                "position": "OC"
            },
            "2006": {
                "college": "Auburn",
                "position": "OC"
            },
            "2007": {
                "college": "Auburn",
                "position": "OC"
            },
            "2008": false,
            "2009": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2010": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2011": {
                "college": "Michigan",
                "position": "OC"
            },
            "2012": {
                "college": "Michigan",
                "position": "OC"
            },
            "2013": {
                "college": "Michigan",
                "position": "OC"
            },
            "2014": false,
            "2015": {
                "college": "San José State",
                "position": "OC"
            },
            "2016": {
                "college": "San José State",
                "position": "OC"
            },
            "2017": false,
            "2018": {
                "college": "UT San Antonio",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.0354462408424928",
                "net_sr": "0.0165319738866345",
                "net_stuff": "0.0442795201644089",
                "net_pass_sr": "0.0532769980145097",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "0.0489753752044585",
                "net_sr": "0.027277416804169",
                "net_stuff": "-0.0140959583348832",
                "net_pass_sr": "0.0539552836600047",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "0.0251642046735404",
                "net_sr": "-0.00857004202892231",
                "net_stuff": "-0.00574704087720285",
                "net_pass_sr": "-0.04371701115633",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UT San Antonio",
                "role": "OC",
                "net_ppa": "-0.150722572713303",
                "net_sr": "-0.0512848112287878",
                "net_stuff": "0.00242798994062898",
                "net_pass_sr": "-0.0611299963814302",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.04113675199281129
    },
    {
        "id": 987,
        "name": "Steve Ensminger",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000941739456819113
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Auburn",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "LSU",
                "position": "OC"
            },
            "2017": false,
            "2018": {
                "college": "LSU",
                "position": "OC"
            },
            "2019": {
                "college": "LSU",
                "position": "OC"
            },
            "2020": {
                "college": "LSU",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.0939721701301996",
                "net_sr": "-0.0626443015363102",
                "net_stuff": "0.0639917967648716",
                "net_pass_sr": "-0.0675721460520899",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "0.0426121572919093",
                "net_sr": "0.0187274130353423",
                "net_stuff": "0.00804794300687484",
                "net_pass_sr": "0.0416868240615185",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.051360012838290295
    },
    {
        "id": 988,
        "name": "Tony Franklin",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.00104708599664831
        },
        "positions": {
            "2000": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Troy",
                "position": "OC"
            },
            "2007": {
                "college": "Troy",
                "position": "OC"
            },
            "2008": {
                "college": "Auburn",
                "position": "OC"
            },
            "2009": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2010": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2011": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2012": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2013": {
                "college": "California",
                "position": "OC"
            },
            "2014": {
                "college": "California",
                "position": "OC"
            },
            "2015": {
                "college": "California",
                "position": "OC"
            },
            "2016": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2017": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2018": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2019": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2020": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.0939721701301996",
                "net_sr": "-0.0626443015363102",
                "net_stuff": "0.0639917967648716",
                "net_pass_sr": "-0.0675721460520899",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "0.153763558103986",
                "net_sr": "0.0533757264103509",
                "net_stuff": "-0.027078479047736",
                "net_pass_sr": "0.0698142500050618",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "0.058371128366147",
                "net_sr": "0.0450692079143826",
                "net_stuff": "-0.0350247171936578",
                "net_pass_sr": "0.0811005090436273",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "0.156390743018065",
                "net_sr": "0.0691486101166573",
                "net_stuff": "-0.0680078235338596",
                "net_pass_sr": "0.0521803102272412",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "-0.00843148313823896",
                "net_sr": "0.015016886793861",
                "net_stuff": "-0.0434913935762744",
                "net_pass_sr": "0.0281175346392737",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.26612177621975946
    },
    {
        "id": 989,
        "name": "Dameyune Craig",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000180991440224838
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Auburn",
                "position": "OC"
            },
            "2014": {
                "college": "Auburn",
                "position": "OC"
            },
            "2015": {
                "college": "Auburn",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "0.136964296056775",
                "net_sr": "0.0484314264690823",
                "net_stuff": "-0.041929537224401",
                "net_pass_sr": "0.0460649029296",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.136964296056775
    },
    {
        "id": 990,
        "name": "Kodi Burns",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000180991440224836
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Auburn",
                "position": "OC"
            },
            "2017": {
                "college": "Auburn",
                "position": "OC"
            },
            "2018": {
                "college": "Auburn",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "-0.00582274525500298",
                "net_sr": "-0.0259999373362744",
                "net_stuff": "0.0181330004510788",
                "net_pass_sr": "-0.0187610137978345",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.00582274525500298
    },
    {
        "id": 991,
        "name": "Kenny Dillingham",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000141379190713511
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Memphis",
                "position": "OC"
            },
            "2019": {
                "college": "Auburn",
                "position": "OC"
            },
            "2020": {
                "college": "Florida State",
                "position": "OC"
            },
            "2021": {
                "college": "Florida State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Auburn",
                "role": "OC",
                "net_ppa": "0.00708587120590784",
                "net_sr": "0.0434306395490676",
                "net_stuff": "-0.00356467756188819",
                "net_pass_sr": "0.051277363857925",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "-0.166180523444732",
                "net_sr": "-0.0794912943152219",
                "net_stuff": "0.0458723897157472",
                "net_pass_sr": "-0.0567211380042773",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "0.191246038943788",
                "net_sr": "0.0822133183977928",
                "net_stuff": "0.00626410605371233",
                "net_pass_sr": "0.117012266816707",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.03215138670496384
    },
    {
        "id": 995,
        "name": "Eddie Faulkner",
        "community": 27,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000976680232573072
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Ball State",
                "position": "OC"
            },
            "2008": false,
            "2009": {
                "college": "Ball State",
                "position": "OC"
            },
            "2010": {
                "college": "Ball State",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "0.0415534610307701",
                "net_sr": "0.0651308666194295",
                "net_stuff": "-0.0410830413909613",
                "net_pass_sr": "0.0477535843142721",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0415534610307701
    },
    {
        "id": 996,
        "name": "Rich Skrosky",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000443411415877072
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Ball State",
                "position": "OC"
            },
            "2012": {
                "college": "Ball State",
                "position": "OC"
            },
            "2013": {
                "college": "Ball State",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Florida International",
                "position": "OC"
            },
            "2018": {
                "college": "Florida International",
                "position": "OC"
            },
            "2019": {
                "college": "Florida International",
                "position": "OC"
            },
            "2020": {
                "college": "Florida International",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "0.060292222257877",
                "net_sr": "0.0340788483425494",
                "net_stuff": "-0.0627180126797571",
                "net_pass_sr": "0.0844782052793109",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.0875285037382316",
                "net_sr": "0.00222882607131664",
                "net_stuff": "-0.0200727289572551",
                "net_pass_sr": "0.0250356890087209",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1478207259961086
    },
    {
        "id": 997,
        "name": "Joey Lynch",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00006471647121952
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Ball State",
                "position": "OC"
            },
            "2015": {
                "college": "Ball State",
                "position": "OC"
            },
            "2016": {
                "college": "Ball State",
                "position": "OC"
            },
            "2017": {
                "college": "Ball State",
                "position": "OC"
            },
            "2018": {
                "college": "Ball State",
                "position": "OC"
            },
            "2019": {
                "college": "Ball State",
                "position": "OC"
            },
            "2020": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "0.0634865336394811",
                "net_sr": "0.121618137232722",
                "net_stuff": "-0.113795080746259",
                "net_pass_sr": "0.144580292173153",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "0.190465008475293",
                "net_sr": "0.0491724413590342",
                "net_stuff": "0.00682769919894313",
                "net_pass_sr": "0.0934828234978433",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2539515421147741
    },
    {
        "id": 998,
        "name": "Kevin Lynch",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000127655031366764
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Ball State",
                "position": "OC"
            },
            "2021": {
                "college": "Ball State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Ball State",
                "role": "OC",
                "net_ppa": "0.0050137699866285",
                "net_sr": "-0.000540077406509687",
                "net_stuff": "-0.0234326174638675",
                "net_pass_sr": "0.0350625378830763",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0050137699866285
    },
    {
        "id": 1000,
        "name": "Brent Pease",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00228358481486604
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2002": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2003": {
                "college": "Baylor",
                "position": "OC"
            },
            "2004": {
                "college": "Baylor",
                "position": "OC"
            },
            "2005": {
                "college": "Baylor",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Boise State",
                "position": "OC"
            },
            "2012": {
                "college": "Florida",
                "position": "OC"
            },
            "2013": {
                "college": "Florida",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "UTEP",
                "position": "OC"
            },
            "2017": {
                "college": "UTEP",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "-0.0340798383083803",
                "net_sr": "-0.00183608449817524",
                "net_stuff": "-0.00175173437827336",
                "net_pass_sr": "0.0491417664967998",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "-0.0181374759674703",
                "net_sr": "-0.0184449012564032",
                "net_stuff": "-0.0168525552867548",
                "net_pass_sr": "-0.00364927938873616",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "-0.0177748015677495",
                "net_sr": "-0.0250307642811734",
                "net_stuff": "0.015806755001083",
                "net_pass_sr": "-0.0238934094513776",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0699921158436001
    },
    {
        "id": 1001,
        "name": "Lee Hays",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000474832492426579
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Baylor",
                "position": "OC"
            },
            "2007": {
                "college": "Baylor",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "-0.0211283139007268",
                "net_sr": "0.0273299832232917",
                "net_stuff": "0.0152758066042199",
                "net_pass_sr": "0.034178846260487",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0211283139007268
    },
    {
        "id": 1002,
        "name": "Randy Clements",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000999759806653785
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Houston",
                "position": "OC"
            },
            "2008": {
                "college": "Baylor",
                "position": "OC"
            },
            "2009": {
                "college": "Baylor",
                "position": "OC"
            },
            "2010": {
                "college": "Baylor",
                "position": "OC"
            },
            "2011": {
                "college": "Baylor",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "0.102821398724017",
                "net_sr": "0.0525328389715947",
                "net_stuff": "-0.0377244340569154",
                "net_pass_sr": "0.0369551855294732",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0152781653128474",
                "net_sr": "-0.00357971424955866",
                "net_stuff": "0.0306667119211667",
                "net_pass_sr": "0.0378309341011319",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0875432334111696
    },
    {
        "id": 1003,
        "name": "Glenn Thomas",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000408148924317835
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Temple",
                "position": "OC"
            },
            "2017": {
                "college": "Baylor",
                "position": "OC"
            },
            "2018": {
                "college": "Baylor",
                "position": "OC"
            },
            "2019": {
                "college": "Baylor",
                "position": "OC"
            },
            "2020": false,
            "2021": {
                "college": "UNLV",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "-0.1047666332865",
                "net_sr": "-0.0567415084839526",
                "net_stuff": "0.0869254150023514",
                "net_pass_sr": "-0.0659156495728496",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "0.0978207315148877",
                "net_sr": "0.0322187915174742",
                "net_stuff": "-0.0305720927803544",
                "net_pass_sr": "0.0175517120055094",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "-0.0207152530745158",
                "net_sr": "-0.0377942958663454",
                "net_stuff": "-0.0172653340408256",
                "net_pass_sr": "0.0138020941315882",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.027661154846128105
    },
    {
        "id": 1004,
        "name": "Jeff Nixon",
        "community": 16,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000253884852337567
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Baylor",
                "position": "OC"
            },
            "2018": {
                "college": "Baylor",
                "position": "OC"
            },
            "2019": {
                "college": "Baylor",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "0.211138530994793",
                "net_sr": "0.0528771415295147",
                "net_stuff": "-0.0676581957449482",
                "net_pass_sr": "0.0309289569004093",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.211138530994793
    },
    {
        "id": 1005,
        "name": "Jeff Grimes",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000187545380838213
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "BYU",
                "position": "OC"
            },
            "2019": {
                "college": "BYU",
                "position": "OC"
            },
            "2020": {
                "college": "BYU",
                "position": "OC"
            },
            "2021": {
                "college": "Baylor",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Baylor",
                "role": "OC",
                "net_ppa": "0.102477176025734",
                "net_sr": "0.0337271045201773",
                "net_stuff": "-0.0724870380248547",
                "net_pass_sr": "0.050392104661292",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "BYU",
                "role": "OC",
                "net_ppa": "0.110313684349463",
                "net_sr": "0.0498818241029438",
                "net_stuff": "0.00605918882286841",
                "net_pass_sr": "0.0720829796137418",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.212790860375197
    },
    {
        "id": 1006,
        "name": "Robert Prince",
        "community": 23,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00402822600102764
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Boise State",
                "position": "OC"
            },
            "2013": {
                "college": "Boise State",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "-0.0190467893028654",
                "net_sr": "-0.0310685392290361",
                "net_stuff": "0.00937998302949777",
                "net_pass_sr": "-0.0274729337263563",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.0190467893028654
    },
    {
        "id": 1007,
        "name": "Scott Huff",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000611204464102106
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Boise State",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "0.101270540728684",
                "net_sr": "0.0211865177197669",
                "net_stuff": "-0.0366179681188448",
                "net_pass_sr": "0.0151736325094414",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.101270540728684
    },
    {
        "id": 1008,
        "name": "Eric Kiesau",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.012262323622248
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Colorado",
                "position": "OC"
            },
            "2010": {
                "college": "Colorado",
                "position": "OC"
            },
            "2011": false,
            "2012": {
                "college": "Washington",
                "position": "OC"
            },
            "2013": {
                "college": "Washington",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Boise State",
                "position": "OC"
            },
            "2020": {
                "college": "Boise State",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "-0.020287149056545",
                "net_sr": "-0.0219794667584786",
                "net_stuff": "-0.00484927768780966",
                "net_pass_sr": "-0.0340371181969917",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "0.00895438422244799",
                "net_sr": "0.000878685077852326",
                "net_stuff": "-0.0160486321762442",
                "net_pass_sr": "0.023608913831978",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "-0.0251526895540863",
                "net_sr": "-0.0425211374263269",
                "net_stuff": "0.0505966637527584",
                "net_pass_sr": "-0.0234092815279328",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "0.0233591918171588",
                "net_sr": "0.00768107586963257",
                "net_stuff": "-0.00460317406473251",
                "net_pass_sr": "0.0289945585846526",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.013126262571024505
    },
    {
        "id": 1009,
        "name": "Tim Plough",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000874666260875253
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Boise State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Boise State",
                "role": "OC",
                "net_ppa": "-0.0500402723161622",
                "net_sr": "-0.0262011829594442",
                "net_stuff": "0.0106562773221041",
                "net_pass_sr": "-0.0110944798548256",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0500402723161622
    },
    {
        "id": 1010,
        "name": "Dana Bible",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000204977952631575
        },
        "positions": {
            "2000": {
                "college": "Boston College",
                "position": "OC"
            },
            "2001": {
                "college": "Boston College",
                "position": "OC"
            },
            "2002": {
                "college": "Boston College",
                "position": "OC"
            },
            "2003": {
                "college": "Boston College",
                "position": "OC"
            },
            "2004": {
                "college": "Boston College",
                "position": "OC"
            },
            "2005": {
                "college": "Boston College",
                "position": "OC"
            },
            "2006": {
                "college": "Boston College",
                "position": "OC"
            },
            "2007": {
                "college": "NC State",
                "position": "OC"
            },
            "2008": {
                "college": "NC State",
                "position": "OC"
            },
            "2009": {
                "college": "NC State",
                "position": "OC"
            },
            "2010": {
                "college": "NC State",
                "position": "OC"
            },
            "2011": {
                "college": "NC State",
                "position": "OC"
            },
            "2012": {
                "college": "NC State",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "0.0187766975700523",
                "net_sr": "0.0401984067824145",
                "net_stuff": "-0.0364950362581102",
                "net_pass_sr": "0.0427573288153098",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0187766975700523
    },
    {
        "id": 1011,
        "name": "Gary Tranquill",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000390128957547112
        },
        "positions": {
            "2000": {
                "college": "Virginia",
                "position": "OC"
            },
            "2001": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2002": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2003": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2004": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2005": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Boston College",
                "position": "OC"
            },
            "2010": {
                "college": "Boston College",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "-0.0530527227497311",
                "net_sr": "-0.0575820214694456",
                "net_stuff": "0.0366238782757029",
                "net_pass_sr": "-0.0677837777759117",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0530527227497311
    },
    {
        "id": 1012,
        "name": "Kevin Rogers",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000304434299265923
        },
        "positions": {
            "2000": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2001": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Boston College",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "0.0206175345887235",
                "net_sr": "0.00890756044566382",
                "net_stuff": "0.00259664628798692",
                "net_pass_sr": "0.0269899053572523",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0206175345887235
    },
    {
        "id": 1013,
        "name": "Todd Fitch",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000212524754391169
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2008": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2009": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2010": {
                "college": "South Florida",
                "position": "OC"
            },
            "2011": {
                "college": "South Florida",
                "position": "OC"
            },
            "2012": {
                "college": "South Florida",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Boston College",
                "position": "OC"
            },
            "2016": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2017": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2018": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2019": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "-0.0665703897567052",
                "net_sr": "-0.0593899564102645",
                "net_stuff": "0.0265776952838104",
                "net_pass_sr": "-0.0926992582017277",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "-0.0557743137409946",
                "net_sr": "-0.0186371351501011",
                "net_stuff": "-0.0337921858490382",
                "net_pass_sr": "-0.0214551098244617",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "0.0563561937346798",
                "net_sr": "0.0354054229497571",
                "net_stuff": "-0.0545078597618483",
                "net_pass_sr": "0.027005580148553",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "-0.026425819478155",
                "net_sr": "0.00306181849812487",
                "net_stuff": "-0.0158587270637668",
                "net_pass_sr": "-0.0402478862730083",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.092414329241175
    },
    {
        "id": 1014,
        "name": "Mike Bajakian",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000238179944097094
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2008": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2009": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2010": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2011": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2012": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2013": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2014": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Boston College",
                "position": "OC"
            },
            "2020": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2021": {
                "college": "Northwestern",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "0.15215497144845",
                "net_sr": "0.0583976830660273",
                "net_stuff": "-0.048169509686169",
                "net_pass_sr": "0.0325917091237168",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "0.0305915043258314",
                "net_sr": "0.0731929427019226",
                "net_stuff": "-0.070710370086983",
                "net_pass_sr": "0.083035338547487",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.0362685779521024",
                "net_sr": "-0.0216329382231794",
                "net_stuff": "-0.0055416572368121",
                "net_pass_sr": "-0.056554832238666",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Northwestern",
                "role": "OC",
                "net_ppa": "0.052127168607487",
                "net_sr": "-0.0130394873652231",
                "net_stuff": "-0.021187046287842",
                "net_pass_sr": "0.00412782459255917",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "0.0192600867453336",
                "net_sr": "0.031944845499763",
                "net_stuff": "-0.0526374729782979",
                "net_pass_sr": "-0.0186818242480198",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.21786515317499963
    },
    {
        "id": 1015,
        "name": "Frank Cignetti Jr",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 6,
            "eigen": 0.000370058705352065
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2003": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2004": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2005": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2006": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2007": false,
            "2008": {
                "college": "California",
                "position": "OC"
            },
            "2009": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2010": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2011": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Boston College",
                "position": "OC"
            },
            "2021": {
                "college": "Boston College",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Boston College",
                "role": "OC",
                "net_ppa": "0.0485875573231828",
                "net_sr": "0.0219723617464727",
                "net_stuff": "0.00984815095384708",
                "net_pass_sr": "0.0485745950950379",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "-0.122715881050925",
                "net_sr": "-0.0612034516040739",
                "net_stuff": "0.0504705851981496",
                "net_pass_sr": "-0.0703100242242457",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "-0.0161704132816689",
                "net_sr": "0.034447075565893",
                "net_stuff": "-0.0164413591523566",
                "net_pass_sr": "0.0471459260395887",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "0.0515101405224762",
                "net_sr": "0.0284509739732517",
                "net_stuff": "-0.0134917949955247",
                "net_pass_sr": "0.0207278028288727",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.00452280068630955",
                "net_sr": "-0.00763270625201845",
                "net_stuff": "0.0529850996651804",
                "net_pass_sr": "0.00649117500736268",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.03426579580062535
    },
    {
        "id": 1017,
        "name": "Greg Studrawa",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00105231000340941
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2004": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2005": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "LSU",
                "position": "OC"
            },
            "2012": {
                "college": "LSU",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "0.0211331792532965",
                "net_sr": "0.00791870355959073",
                "net_stuff": "-0.0334053462932397",
                "net_pass_sr": "0.0176908559937847",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0211331792532965
    },
    {
        "id": 1018,
        "name": "Mick McCall",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000383388913044768
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2008": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2009": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2010": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2011": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2012": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2013": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2014": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2015": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2016": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2017": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2018": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2019": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.0244459342545074",
                "net_sr": "0.0355192775445313",
                "net_stuff": "0.0771710889150886",
                "net_pass_sr": "0.0641469278929702",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Northwestern",
                "role": "OC",
                "net_ppa": "-0.0117284693479394",
                "net_sr": "0.00127186972504006",
                "net_stuff": "-0.0269277880456887",
                "net_pass_sr": "0.0125440061473044",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0361744036024468
    },
    {
        "id": 1019,
        "name": "Warren Ruggiero",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00176454691669905
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2010": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2011": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2012": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2013": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2014": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2015": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2016": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2017": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2018": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2019": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2020": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2021": {
                "college": "Wake Forest",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.0221659102084594",
                "net_sr": "-0.0131128071177483",
                "net_stuff": "0.031062789578203",
                "net_pass_sr": "0.00263323983084673",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wake Forest",
                "role": "OC",
                "net_ppa": "0.137005280521359",
                "net_sr": "0.0312848760010069",
                "net_stuff": "-0.0443777228965737",
                "net_pass_sr": "0.0474542826485212",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.11483937031289959
    },
    {
        "id": 1020,
        "name": "Matt Mattox",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000358574068192665
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2015": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "0.0739315005567998",
                "net_sr": "-0.0363505021366103",
                "net_stuff": "-0.0351262419814475",
                "net_pass_sr": "-0.0570059707949789",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "0.202816792776024",
                "net_sr": "0.0329030939469792",
                "net_stuff": "-0.0287447418192358",
                "net_pass_sr": "0.041743317212474",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2767482933328238
    },
    {
        "id": 1021,
        "name": "Sterlin Gilbert",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000477926319677662
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2015": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2016": {
                "college": "Texas",
                "position": "OC"
            },
            "2017": {
                "college": "South Florida",
                "position": "OC"
            },
            "2018": {
                "college": "South Florida",
                "position": "OC"
            },
            "2019": false,
            "2020": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2021": {
                "college": "Syracuse",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "0.0739315005567998",
                "net_sr": "-0.0363505021366103",
                "net_stuff": "-0.0351262419814475",
                "net_pass_sr": "-0.0570059707949789",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.0055085607733848",
                "net_sr": "0.0196798052981204",
                "net_stuff": "-0.00553897519994348",
                "net_pass_sr": "0.0115679190444574",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "-0.0441017088730004",
                "net_sr": "-0.0558599658946767",
                "net_stuff": "0.0555543034982607",
                "net_pass_sr": "-0.0691374084083041",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.0989957026746955",
                "net_sr": "0.0780848915771862",
                "net_stuff": "-0.0612450932073547",
                "net_pass_sr": "0.0764500844790575",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "0.202816792776024",
                "net_sr": "0.0329030939469792",
                "net_stuff": "-0.0287447418192358",
                "net_pass_sr": "0.041743317212474",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.3371508479079037
    },
    {
        "id": 1022,
        "name": "Mike Lynch",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000522442333414544
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2016": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2017": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2018": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2019": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "0.245066859155435",
                "net_sr": "0.0674815859863831",
                "net_stuff": "-0.0528658649836123",
                "net_pass_sr": "0.0886979016204008",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "0.0857681697926443",
                "net_sr": "0.0261000224837242",
                "net_stuff": "-0.0404116142662023",
                "net_pass_sr": "0.0355846101701329",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.3308350289480793
    },
    {
        "id": 1023,
        "name": "Andy Padron",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000061286042564112
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2017": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2018": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.123301987147004",
                "net_sr": "-0.0533193121428989",
                "net_stuff": "0.0134817598402725",
                "net_pass_sr": "-0.0615080030236024",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.123301987147004
    },
    {
        "id": 1024,
        "name": "Kevin Kilmer",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000612860425641094
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2017": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2018": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.123301987147004",
                "net_sr": "-0.0533193121428989",
                "net_stuff": "0.0134817598402725",
                "net_pass_sr": "-0.0615080030236024",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.123301987147004
    },
    {
        "id": 1025,
        "name": "Terry Malone",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000615761397223652
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Michigan",
                "position": "OC"
            },
            "2003": {
                "college": "Michigan",
                "position": "OC"
            },
            "2004": {
                "college": "Michigan",
                "position": "OC"
            },
            "2005": {
                "college": "Michigan",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Purdue",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2020": {
                "college": "Bowling Green",
                "position": "OC"
            },
            "2021": {
                "college": "Bowling Green",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Bowling Green",
                "role": "OC",
                "net_ppa": "-0.0556177184442486",
                "net_sr": "-0.0332327214851585",
                "net_stuff": "-0.0176541674707952",
                "net_pass_sr": "-0.0776152011806621",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "0.0304546151198038",
                "net_sr": "0.0188954748457915",
                "net_stuff": "0.00592906185107023",
                "net_pass_sr": "0.0235256929938553",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0251631033244448
    },
    {
        "id": 1027,
        "name": "Bill Lazor",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000156538519346943
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2002": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Virginia",
                "position": "OC"
            },
            "2011": {
                "college": "Virginia",
                "position": "OC"
            },
            "2012": {
                "college": "Virginia",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "0.0784990640084986",
                "net_sr": "0.0382734481377357",
                "net_stuff": "-0.0783735283101205",
                "net_pass_sr": "0.0338429988225485",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0784990640084986
    },
    {
        "id": 1029,
        "name": "Gerald Carr",
        "community": 30,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000248605542254724
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "-0.0374766264072104",
                "net_sr": "-0.00232865112572223",
                "net_stuff": "-0.034024988844266",
                "net_pass_sr": "0.0257409756660132",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0374766264072104
    },
    {
        "id": 1030,
        "name": "Greg Forest",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000112939532067657
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "-0.112850459427149",
                "net_sr": "-0.0684121110813612",
                "net_stuff": "-0.0320452588469449",
                "net_pass_sr": "-0.0912854052236531",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.112850459427149
    },
    {
        "id": 1031,
        "name": "Alex Wood",
        "community": 3,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000045175812827094
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2012": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2013": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2014": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "0.0695768965566909",
                "net_sr": "0.0151078608873236",
                "net_stuff": "0.00207604249333226",
                "net_pass_sr": "0.00706785379810243",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0695768965566909
    },
    {
        "id": 1032,
        "name": "Andy Kotelnicki",
        "community": 33,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 3.53370477579879e-17
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2016": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2017": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2018": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2019": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2020": {
                "college": "Buffalo",
                "position": "OC"
            },
            "2021": {
                "college": "Kansas",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Buffalo",
                "role": "OC",
                "net_ppa": "0.105668389043225",
                "net_sr": "0.0276649991715184",
                "net_stuff": "-0.0290462768045973",
                "net_pass_sr": "0.0256694501388097",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "0.0359074376548868",
                "net_sr": "0.0225389551748237",
                "net_stuff": "-0.0846797336747192",
                "net_pass_sr": "0.0458216925506645",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1415758266981118
    },
    {
        "id": 1037,
        "name": "Robert Anae",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000419621749305471
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "BYU",
                "position": "OC"
            },
            "2006": {
                "college": "BYU",
                "position": "OC"
            },
            "2007": {
                "college": "BYU",
                "position": "OC"
            },
            "2008": {
                "college": "BYU",
                "position": "OC"
            },
            "2009": {
                "college": "BYU",
                "position": "OC"
            },
            "2010": {
                "college": "BYU",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Virginia",
                "position": "OC"
            },
            "2017": {
                "college": "Virginia",
                "position": "OC"
            },
            "2018": {
                "college": "Virginia",
                "position": "OC"
            },
            "2019": {
                "college": "Virginia",
                "position": "OC"
            },
            "2020": {
                "college": "Virginia",
                "position": "OC"
            },
            "2021": {
                "college": "Virginia",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "BYU",
                "role": "OC",
                "net_ppa": "-0.0180903145360978",
                "net_sr": "0.00496819714878333",
                "net_stuff": "-0.021970184001927",
                "net_pass_sr": "0.0315373032346171",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "0.0977924047939911",
                "net_sr": "0.0435959325445859",
                "net_stuff": "-0.0423300801340377",
                "net_pass_sr": "0.0333945653231851",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0797020902578933
    },
    {
        "id": 1038,
        "name": "Brandon Doman",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000069936958217566
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "BYU",
                "position": "OC"
            },
            "2012": {
                "college": "BYU",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "BYU",
                "role": "OC",
                "net_ppa": "-0.0354232775918974",
                "net_sr": "-0.0332884947356973",
                "net_stuff": "0.0203051309803966",
                "net_pass_sr": "-0.0390957279292714",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0354232775918974
    },
    {
        "id": 1039,
        "name": "Ty Detmer",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000874332181348472
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "BYU",
                "position": "OC"
            },
            "2017": {
                "college": "BYU",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "BYU",
                "role": "OC",
                "net_ppa": "-0.0560476443616275",
                "net_sr": "-0.0123686037776838",
                "net_stuff": "-0.0125812976879777",
                "net_pass_sr": "-0.0424183504567693",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0560476443616275
    },
    {
        "id": 1040,
        "name": "Aaron Roderick",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000439176209991093
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Utah",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Utah",
                "position": "OC"
            },
            "2016": {
                "college": "Utah",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "BYU",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "BYU",
                "role": "OC",
                "net_ppa": "0.0854265950021735",
                "net_sr": "0.0178985705359522",
                "net_stuff": "-0.0240056195977084",
                "net_pass_sr": "0.00403790377183871",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "0.0391653596876316",
                "net_sr": "-0.0255092068513841",
                "net_stuff": "0.0037448477435805",
                "net_pass_sr": "-0.0344772211002299",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1245919546898051
    },
    {
        "id": 1041,
        "name": "Steve Hagen",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000318190048126509
        },
        "positions": {
            "2000": {
                "college": "California",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "-0.15425021941516",
                "net_sr": "-0.0879050042408821",
                "net_stuff": "-0.0603393521459033",
                "net_pass_sr": "-0.167249022164276",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.15425021941516
    },
    {
        "id": 1043,
        "name": "Mike Dunbar",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000180304064573373
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2003": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2004": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2005": {
                "college": "Northwestern",
                "position": "OC"
            },
            "2006": {
                "college": "California",
                "position": "OC"
            },
            "2007": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2008": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2009": false,
            "2010": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "-0.019392913836664",
                "net_sr": "0.0203703703703704",
                "net_stuff": "0.0639276649746193",
                "net_pass_sr": "0.0690825424216278",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "-0.0494385987642825",
                "net_sr": "-0.0290900918592148",
                "net_stuff": "0.0131483256171798",
                "net_pass_sr": "-0.0174904196813563",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "-0.0429380121314094",
                "net_sr": "-0.0207842311859794",
                "net_stuff": "-0.0375664267882081",
                "net_pass_sr": "-0.0283404367585681",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.11176952473235591
    },
    {
        "id": 1044,
        "name": "Jim Michalczik",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000458276325762223
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "California",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "California",
                "position": "OC"
            },
            "2012": {
                "college": "California",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "0.132941109609467",
                "net_sr": "0.0378962696189587",
                "net_stuff": "-0.0218652319784038",
                "net_pass_sr": "0.00887017711803739",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.132941109609467
    },
    {
        "id": 1045,
        "name": "Andy Ludwig",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 7,
            "eigen": 0.00445264382221406
        },
        "positions": {
            "2000": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2001": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2002": {
                "college": "Oregon",
                "position": "OC"
            },
            "2003": {
                "college": "Oregon",
                "position": "OC"
            },
            "2004": {
                "college": "Oregon",
                "position": "OC"
            },
            "2005": {
                "college": "Utah",
                "position": "OC"
            },
            "2006": {
                "college": "Utah",
                "position": "OC"
            },
            "2007": {
                "college": "Utah",
                "position": "OC"
            },
            "2008": {
                "college": "Utah",
                "position": "OC"
            },
            "2009": {
                "college": "California",
                "position": "OC"
            },
            "2010": {
                "college": "California",
                "position": "OC"
            },
            "2011": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2012": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2013": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2014": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2015": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2016": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2017": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2018": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2019": {
                "college": "Utah",
                "position": "OC"
            },
            "2020": {
                "college": "Utah",
                "position": "OC"
            },
            "2021": {
                "college": "Utah",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "-0.0286761167926351",
                "net_sr": "-0.0222436476347767",
                "net_stuff": "0.00855768620807754",
                "net_pass_sr": "-0.0105844622881994",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "0.0382289151439256",
                "net_sr": "0.0179793290052825",
                "net_stuff": "-0.0377551993955883",
                "net_pass_sr": "-0.0115726857168124",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "0.110194865422492",
                "net_sr": "0.0101521424424781",
                "net_stuff": "-0.00420583481523715",
                "net_pass_sr": "0.0106045005297485",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "0.0440806801920558",
                "net_sr": "0.00616592836808222",
                "net_stuff": "0.0114006455870733",
                "net_pass_sr": "-0.0471264687919938",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 22,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.16382834396583829
    },
    {
        "id": 1046,
        "name": "Beau Baldwin",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00129869270111145
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "California",
                "position": "OC"
            },
            "2018": {
                "college": "California",
                "position": "OC"
            },
            "2019": {
                "college": "California",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "-0.150399368602155",
                "net_sr": "-0.0751544306208128",
                "net_stuff": "0.0179660487684644",
                "net_pass_sr": "-0.0968397263868744",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.150399368602155
    },
    {
        "id": 1047,
        "name": "Bill Musgrave",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000889321460955183
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Virginia",
                "position": "OC"
            },
            "2002": {
                "college": "Virginia",
                "position": "OC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "California",
                "position": "OC"
            },
            "2021": {
                "college": "California",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "California",
                "role": "OC",
                "net_ppa": "0.0364876718509025",
                "net_sr": "-0.00382857280796395",
                "net_stuff": "-0.00968111141412747",
                "net_pass_sr": "-0.0144901337291797",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0364876718509025
    },
    {
        "id": 1048,
        "name": "Mike Cummings",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000909336200392496
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Western Michigan",
                "position": "Positional Coach"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2011": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2012": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2013": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2014": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2015": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2016": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "-0.0387759300165285",
                "net_sr": "-0.0272677115115584",
                "net_stuff": "0.0244035213081512",
                "net_pass_sr": "-0.0330860364335903",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "0.0454615349284098",
                "net_sr": "0.00744627737806941",
                "net_stuff": "0.00647563238178939",
                "net_pass_sr": "-0.0221721242193992",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "Positional Coach",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.006685604911881297
    },
    {
        "id": 1049,
        "name": "Morris Watts",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.0000213605381417104
        },
        "positions": {
            "2000": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2001": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2002": false,
            "2003": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2015": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2016": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "0.124917589322124",
                "net_sr": "0.0134800307132107",
                "net_stuff": "-0.00629201578294328",
                "net_pass_sr": "0.0169676764733137",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "-0.00941580885395951",
                "net_sr": "0.00398854681662253",
                "net_stuff": "0.0421975570922982",
                "net_pass_sr": "0.0341330114071974",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.11550178046816449
    },
    {
        "id": 1050,
        "name": "Chris Ostrowsky",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000136899602188477
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2018": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "-0.138283643952088",
                "net_sr": "-0.0750646830913481",
                "net_stuff": "-0.00927582949641775",
                "net_pass_sr": "-0.117306852005255",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.138283643952088
    },
    {
        "id": 1051,
        "name": "Charlie Frye",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000983140622431927
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2020": {
                "college": "Central Michigan",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "0.11804304484367",
                "net_sr": "0.0540923475368099",
                "net_stuff": "-0.0538143610039035",
                "net_pass_sr": "0.0638805165493226",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.11804304484367
    },
    {
        "id": 1052,
        "name": "Kevin Barbay",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000491570311215869
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Central Michigan",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Central Michigan",
                "role": "OC",
                "net_ppa": "0.0538756987591107",
                "net_sr": "-0.00912640796358805",
                "net_stuff": "-0.0612799523595673",
                "net_pass_sr": "-0.0395837272555785",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0538756987591107
    },
    {
        "id": 1053,
        "name": "Jeff Mullen",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000759090729916317
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2009": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2010": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Charlotte",
                "position": "OC"
            },
            "2016": {
                "college": "Charlotte",
                "position": "OC"
            },
            "2017": {
                "college": "Charlotte",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Charlotte",
                "role": "OC",
                "net_ppa": "-0.0184900197254992",
                "net_sr": "-0.042745895757299",
                "net_stuff": "-0.0158993921062014",
                "net_pass_sr": "-0.0598071165265547",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "-0.0828704866690718",
                "net_sr": "0.00228820453985934",
                "net_stuff": "-0.0134911935234173",
                "net_pass_sr": "0.00148627486988029",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.101360506394571
    },
    {
        "id": 1054,
        "name": "Alex Atkins",
        "community": 36,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Charlotte",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Charlotte",
                "role": "OC",
                "net_ppa": "0.171485347803589",
                "net_sr": "0.0716271146932651",
                "net_stuff": "-0.0199410349831941",
                "net_pass_sr": "0.101834158609813",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.171485347803589
    },
    {
        "id": 1055,
        "name": "Mark Carney",
        "community": 36,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Charlotte",
                "position": "OC"
            },
            "2021": {
                "college": "Charlotte",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Charlotte",
                "role": "OC",
                "net_ppa": "0.0661380475650064",
                "net_sr": "0.0218668819733749",
                "net_stuff": "-0.00638208326217746",
                "net_pass_sr": "0.0557509323048279",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0661380475650064
    },
    {
        "id": 1057,
        "name": "Rusty Burns",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000310835744834288
        },
        "positions": {
            "2000": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2001": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2002": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2003": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2004": {
                "college": "SMU",
                "position": "OC"
            },
            "2005": {
                "college": "SMU",
                "position": "OC"
            },
            "2006": {
                "college": "SMU",
                "position": "OC"
            },
            "2007": {
                "college": "SMU",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "TCU",
                "position": "OC"
            },
            "2013": {
                "college": "TCU",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "TCU",
                "role": "OC",
                "net_ppa": "-0.0980862623765952",
                "net_sr": "-0.0868105262897891",
                "net_stuff": "0.0617147382764403",
                "net_pass_sr": "-0.0558981265281425",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0980862623765952
    },
    {
        "id": 1058,
        "name": "Eddie Gran",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00120752622476391
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2014": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2015": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2016": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2017": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2018": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2019": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "0.146442299695927",
                "net_sr": "0.0604349147464722",
                "net_stuff": "-0.0324065188397921",
                "net_pass_sr": "0.0957067996701169",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "0.0869351494282813",
                "net_sr": "0.0320509609472688",
                "net_stuff": "-0.0588040276876169",
                "net_pass_sr": "0.018056093946379",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.23337744912420832
    },
    {
        "id": 1059,
        "name": "Zac Taylor",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000474288106253025
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "-0.144009235493829",
                "net_sr": "-0.0749324678796394",
                "net_stuff": "0.0315571359943314",
                "net_pass_sr": "-0.0721640166442201",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.144009235493829
    },
    {
        "id": 1060,
        "name": "Mike Denbrock",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000480696499488352
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2018": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2019": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2020": {
                "college": "Cincinnati",
                "position": "OC"
            },
            "2021": {
                "college": "Cincinnati",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Cincinnati",
                "role": "OC",
                "net_ppa": "0.0447398170965862",
                "net_sr": "-0.00397776800995348",
                "net_stuff": "0.0110579596332319",
                "net_pass_sr": "-0.0174048700118837",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.0971955931788864",
                "net_sr": "-0.00784161499881514",
                "net_stuff": "-0.0125294717713118",
                "net_pass_sr": "-0.00586259461655692",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1419354102754726
    },
    {
        "id": 1063,
        "name": "Rob Spence",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000294850487673354
        },
        "positions": {
            "2000": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Clemson",
                "position": "OC"
            },
            "2006": {
                "college": "Clemson",
                "position": "OC"
            },
            "2007": {
                "college": "Clemson",
                "position": "OC"
            },
            "2008": {
                "college": "Clemson",
                "position": "OC"
            },
            "2009": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "0.0262848177275814",
                "net_sr": "0.0649035126367386",
                "net_stuff": "-0.00265483431854471",
                "net_pass_sr": "0.1101491433625",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0262848177275814
    },
    {
        "id": 1064,
        "name": "Tony Elliott",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00257308478037728
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Clemson",
                "position": "OC"
            },
            "2016": {
                "college": "Clemson",
                "position": "OC"
            },
            "2017": {
                "college": "Clemson",
                "position": "OC"
            },
            "2018": {
                "college": "Clemson",
                "position": "OC"
            },
            "2019": {
                "college": "Clemson",
                "position": "OC"
            },
            "2020": {
                "college": "Clemson",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Clemson",
                "role": "OC",
                "net_ppa": "0.177299081873544",
                "net_sr": "0.0444070130493929",
                "net_stuff": "-0.0349328479764015",
                "net_pass_sr": "0.0261939741815799",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.177299081873544
    },
    {
        "id": 1065,
        "name": "Newland Isaac",
        "community": 28,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 1.30709584798567e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Coastal Carolina",
                "position": "OC"
            },
            "2020": {
                "college": "Coastal Carolina",
                "position": "OC"
            },
            "2021": {
                "college": "Coastal Carolina",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Coastal Carolina",
                "role": "OC",
                "net_ppa": "0.144673700828437",
                "net_sr": "0.0624012789601091",
                "net_stuff": "-0.0409241972786764",
                "net_pass_sr": "0.117298450915017",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.144673700828437
    },
    {
        "id": 1066,
        "name": "Willy Korn",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 1.30709584806411e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Coastal Carolina",
                "position": "OC"
            },
            "2020": {
                "college": "Coastal Carolina",
                "position": "OC"
            },
            "2021": {
                "college": "Coastal Carolina",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Coastal Carolina",
                "role": "OC",
                "net_ppa": "0.144673700828437",
                "net_sr": "0.0624012789601091",
                "net_stuff": "-0.0409241972786764",
                "net_pass_sr": "0.117298450915017",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.144673700828437
    },
    {
        "id": 1067,
        "name": "Shawn Watson",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.00838019228149131
        },
        "positions": {
            "2000": {
                "college": "Colorado",
                "position": "OC"
            },
            "2001": {
                "college": "Colorado",
                "position": "OC"
            },
            "2002": {
                "college": "Colorado",
                "position": "OC"
            },
            "2003": {
                "college": "Colorado",
                "position": "OC"
            },
            "2004": {
                "college": "Colorado",
                "position": "OC"
            },
            "2005": {
                "college": "Colorado",
                "position": "OC"
            },
            "2006": false,
            "2007": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2008": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2009": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2010": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2011": false,
            "2012": {
                "college": "Louisville",
                "position": "OC"
            },
            "2013": {
                "college": "Louisville",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2018": {
                "college": "Pittsburgh",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.133061329299987",
                "net_sr": "0.0741306766338177",
                "net_stuff": "0.0069797630215476",
                "net_pass_sr": "0.126683907721055",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "0.0402793388770013",
                "net_sr": "0.0499328499662353",
                "net_stuff": "-0.00109549781715829",
                "net_pass_sr": "0.0530525351808379",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Pittsburgh",
                "role": "OC",
                "net_ppa": "-0.061580278889607",
                "net_sr": "-0.0428446316264416",
                "net_stuff": "0.0160037261749953",
                "net_pass_sr": "-0.0701480875090644",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.11176038928738131
    },
    {
        "id": 1068,
        "name": "Eric Bieniemy",
        "community": 14,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000560116515612379
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Colorado",
                "position": "OC"
            },
            "2012": {
                "college": "Colorado",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "-0.0130757154559414",
                "net_sr": "-0.0139087195707971",
                "net_stuff": "-0.0237199727076912",
                "net_pass_sr": "-0.00353319320641249",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0130757154559414
    },
    {
        "id": 1069,
        "name": "Brian Lindgren",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00196140860707349
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "San José State",
                "position": "OC"
            },
            "2013": {
                "college": "Colorado",
                "position": "OC"
            },
            "2014": {
                "college": "Colorado",
                "position": "OC"
            },
            "2015": {
                "college": "Colorado",
                "position": "OC"
            },
            "2016": {
                "college": "Colorado",
                "position": "OC"
            },
            "2017": {
                "college": "Colorado",
                "position": "OC"
            },
            "2018": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2019": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2020": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2021": {
                "college": "Oregon State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "0.0980948601735432",
                "net_sr": "0.0581941182128268",
                "net_stuff": "-0.0224412740642444",
                "net_pass_sr": "0.0679390962880587",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon State",
                "role": "OC",
                "net_ppa": "0.128683901318876",
                "net_sr": "0.0468253506569178",
                "net_stuff": "-0.00508018193621262",
                "net_pass_sr": "0.0706480357807564",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "0.0991825290678848",
                "net_sr": "0.0670074352358461",
                "net_stuff": "-0.0372543755098899",
                "net_pass_sr": "0.0985050656528536",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.325961290560304
    },
    {
        "id": 1070,
        "name": "Darrin Chiaverini",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000305195318181182
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Colorado",
                "position": "OC"
            },
            "2017": {
                "college": "Colorado",
                "position": "OC"
            },
            "2018": {
                "college": "Colorado",
                "position": "OC"
            },
            "2019": false,
            "2020": {
                "college": "Colorado",
                "position": "OC"
            },
            "2021": {
                "college": "Colorado",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "0.0446761746762869",
                "net_sr": "-0.0143102170699755",
                "net_stuff": "0.0198774063863458",
                "net_pass_sr": "-0.0369971162498592",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0446761746762869
    },
    {
        "id": 1071,
        "name": "Klayton Adams",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000675472661103328
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Colorado",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "-0.0385384068450059",
                "net_sr": "-0.0505645499132015",
                "net_stuff": "0.0773076752415693",
                "net_pass_sr": "-0.0151359785241827",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0385384068450059
    },
    {
        "id": 1072,
        "name": "Jay Johnson",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000330794781877295
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2006": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2007": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2012": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2013": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2014": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2015": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2016": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Colorado",
                "position": "OC"
            },
            "2020": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2021": {
                "college": "Michigan State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Colorado",
                "role": "OC",
                "net_ppa": "0.0326058805921046",
                "net_sr": "0.0152766115637616",
                "net_stuff": "-0.0360285347335847",
                "net_pass_sr": "-0.00596265768424448",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "0.0902257242220328",
                "net_sr": "0.0446589879556433",
                "net_stuff": "-0.0203925063130314",
                "net_pass_sr": "0.0468826535918799",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "0.0575977023524823",
                "net_sr": "-0.0235460588834822",
                "net_stuff": "-0.00522092501356891",
                "net_pass_sr": "-0.00323163970609835",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "-0.00380899055255533",
                "net_sr": "0.0146740910411329",
                "net_stuff": "0.0277546552462054",
                "net_pass_sr": "0.00437346419188478",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.17662031661406438
    },
    {
        "id": 1075,
        "name": "Dave Lay",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000298279445723788
        },
        "positions": {
            "2000": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2001": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "-0.0671876159175422",
                "net_sr": "0.00991894886177397",
                "net_stuff": "-0.0871831591812428",
                "net_pass_sr": "-0.0337579576710011",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0671876159175422
    },
    {
        "id": 1076,
        "name": "Greg Peterson",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000304613560807508
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2004": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2009": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2010": false,
            "2011": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2012": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2013": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "0.007466243571397",
                "net_sr": "0.0260190875734232",
                "net_stuff": "-0.0378993201295184",
                "net_pass_sr": "0.00703873167641283",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "-0.0723563329029509",
                "net_sr": "-0.0440749610388144",
                "net_stuff": "0.0273326495213212",
                "net_pass_sr": "-0.0498234948064279",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.06489008933155391
    },
    {
        "id": 1077,
        "name": "Pat Meyer",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000439823560330322
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2011": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "-0.0193857841685759",
                "net_sr": "-0.00905855184357318",
                "net_stuff": "0.0328201212103539",
                "net_pass_sr": "-0.0208697063030756",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0193857841685759
    },
    {
        "id": 1078,
        "name": "Will Friend",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000137153872400399
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2016": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2017": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "0.0819072035362576",
                "net_sr": "0.0394650311181648",
                "net_stuff": "-0.0207151797854624",
                "net_pass_sr": "0.0182586529543254",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0819072035362576
    },
    {
        "id": 1079,
        "name": "Dave Johnson",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000914359149335925
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2019": {
                "college": "Colorado State",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "-0.0634856680440179",
                "net_sr": "-0.0425476496801171",
                "net_stuff": "0.0305337024570979",
                "net_pass_sr": "-0.0118568998109672",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0634856680440179
    },
    {
        "id": 1080,
        "name": "Jon Budmayr",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000550678844148025
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Colorado State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Colorado State",
                "role": "OC",
                "net_ppa": "-0.120275762526061",
                "net_sr": "-0.0677442111041953",
                "net_stuff": "0.044716057583389",
                "net_pass_sr": "-0.050998313728351",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.120275762526061
    },
    {
        "id": 1082,
        "name": "Peter Vaas",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000618329712586345
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Duke",
                "position": "OC"
            },
            "2008": false,
            "2009": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "-0.0411973038721932",
                "net_sr": "-0.0175208025549726",
                "net_stuff": "-0.0190808806255335",
                "net_pass_sr": "-0.031995764442076",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "0.108736740355521",
                "net_sr": "0.025421169412695",
                "net_stuff": "-0.0386460373541621",
                "net_pass_sr": "0.0475034136580323",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0675394364833278
    },
    {
        "id": 1083,
        "name": "Kurt Roper",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000634597788445907
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Duke",
                "position": "OC"
            },
            "2009": {
                "college": "Duke",
                "position": "OC"
            },
            "2010": {
                "college": "Duke",
                "position": "OC"
            },
            "2011": {
                "college": "Duke",
                "position": "OC"
            },
            "2012": {
                "college": "Duke",
                "position": "OC"
            },
            "2013": false,
            "2014": {
                "college": "Florida",
                "position": "OC"
            },
            "2015": false,
            "2016": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2017": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "0.0534921283567947",
                "net_sr": "0.0602026408035178",
                "net_stuff": "-0.0177572483118303",
                "net_pass_sr": "0.0955465284378153",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "0.0653817664042469",
                "net_sr": "-0.00999109652763142",
                "net_stuff": "-0.0348255275022621",
                "net_pass_sr": "-0.0395001212582811",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "-0.0520400139202339",
                "net_sr": "-0.0214508597763598",
                "net_stuff": "0.0175280067907178",
                "net_pass_sr": "-0.0280100879053594",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0668338808408077
    },
    {
        "id": 1084,
        "name": "Zac Roper",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00043803726747269
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Duke",
                "position": "OC"
            },
            "2017": {
                "college": "Duke",
                "position": "OC"
            },
            "2018": {
                "college": "Duke",
                "position": "OC"
            },
            "2019": {
                "college": "Duke",
                "position": "OC"
            },
            "2020": {
                "college": "Duke",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "-0.0118432805999709",
                "net_sr": "-0.0300422387070787",
                "net_stuff": "0.0382101699774521",
                "net_pass_sr": "-0.00837573860421803",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0118432805999709
    },
    {
        "id": 1085,
        "name": "Jeff Faris",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000876074534945236
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Duke",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "0.0556080129205096",
                "net_sr": "0.0244352842609749",
                "net_stuff": "-0.0604560057781879",
                "net_pass_sr": "0.0604602256377921",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0556080129205096
    },
    {
        "id": 1086,
        "name": "Re'quan Boyette",
        "community": 5,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000876074534945252
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Duke",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Duke",
                "role": "OC",
                "net_ppa": "0.0556080129205096",
                "net_sr": "0.0244352842609749",
                "net_stuff": "-0.0604560057781879",
                "net_pass_sr": "0.0604602256377921",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0556080129205096
    },
    {
        "id": 1088,
        "name": "Steve Shankweiler",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000988579097401422
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2006": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2007": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2008": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2009": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Florida International",
                "position": "OC"
            },
            "2015": {
                "college": "Florida International",
                "position": "OC"
            },
            "2016": {
                "college": "Florida International",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.0649020219619589",
                "net_sr": "0.0197449549929444",
                "net_stuff": "-0.0407093388067539",
                "net_pass_sr": "0.0128450534888097",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0649020219619589
    },
    {
        "id": 1089,
        "name": "Dave Nichol",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000619326175626481
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "0.00935034015763825",
                "net_sr": "-0.0257556611107786",
                "net_stuff": "0.0665562105516948",
                "net_pass_sr": "-0.0285501203675099",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00935034015763825
    },
    {
        "id": 1090,
        "name": "Donnie Kirkpatrick",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 8.45841001765656e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2020": {
                "college": "East Carolina",
                "position": "OC"
            },
            "2021": {
                "college": "East Carolina",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "East Carolina",
                "role": "OC",
                "net_ppa": "0.0199861655396861",
                "net_sr": "0.00484915255540569",
                "net_stuff": "-0.0163659260228078",
                "net_pass_sr": "0.0135187823468064",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0199861655396861
    },
    {
        "id": 1092,
        "name": "Tim Salem",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000123563038712373
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2004": {
                "college": "UCF",
                "position": "OC"
            },
            "2005": {
                "college": "UCF",
                "position": "OC"
            },
            "2006": {
                "college": "UCF",
                "position": "OC"
            },
            "2007": {
                "college": "UCF",
                "position": "OC"
            },
            "2008": {
                "college": "UCF",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.0955393952962108",
                "net_sr": "-0.0363607573151213",
                "net_stuff": "0.0479039799844492",
                "net_pass_sr": "-0.0553731495386031",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0955393952962108
    },
    {
        "id": 1094,
        "name": "Scott Isphording",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.212168924022979
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2008": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Ohio",
                "position": "OC"
            },
            "2015": {
                "college": "Ohio",
                "position": "OC"
            },
            "2016": {
                "college": "Ohio",
                "position": "OC"
            },
            "2017": {
                "college": "Ohio",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Ohio",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Eastern Michigan",
                "role": "OC",
                "net_ppa": "0.022095450840743",
                "net_sr": "0.024646580978078",
                "net_stuff": "-0.0431904760154339",
                "net_pass_sr": "0.0324693965729861",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ohio",
                "role": "OC",
                "net_ppa": "0.145673785166592",
                "net_sr": "0.0413055391763325",
                "net_stuff": "-0.0157499259196538",
                "net_pass_sr": "-0.00137667494771254",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.16776923600733498
    },
    {
        "id": 1095,
        "name": "Ken Karcher",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000185743951570326
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2010": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2011": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2012": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Eastern Michigan",
                "role": "OC",
                "net_ppa": "-0.0599821423853735",
                "net_sr": "-0.0241558676949985",
                "net_stuff": "0.0389602114880839",
                "net_pass_sr": "-0.0733156739363354",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0599821423853735
    },
    {
        "id": 1096,
        "name": "Aaron Keen",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000692445737498314
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2018": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2019": {
                "college": "Eastern Michigan",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Eastern Michigan",
                "role": "OC",
                "net_ppa": "0.0392756624960593",
                "net_sr": "0.0118130246344849",
                "net_stuff": "-0.00327662197156914",
                "net_pass_sr": "0.0437350633479567",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0392756624960593
    },
    {
        "id": 1097,
        "name": "Darryl Jackson",
        "community": 25,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000271867330292383
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2010": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2011": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "-0.00441355429236212",
                "net_sr": "0.000767332650856378",
                "net_stuff": "-0.0233157124871655",
                "net_pass_sr": "-0.00952852499843487",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.00441355429236212
    },
    {
        "id": 1098,
        "name": "Brian Wright",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00092931866125242
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2013": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2014": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2015": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2016": {
                "college": "Toledo",
                "position": "OC"
            },
            "2017": {
                "college": "Toledo",
                "position": "OC"
            },
            "2018": {
                "college": "Toledo",
                "position": "OC"
            },
            "2019": {
                "college": "Toledo",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "0.073515834556419",
                "net_sr": "0.0288187460423355",
                "net_stuff": "-0.0365991193174151",
                "net_pass_sr": "0.0124608973218555",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "0.0590852006840783",
                "net_sr": "-0.0177269024104535",
                "net_stuff": "0.00965066556928296",
                "net_pass_sr": "0.0060661488968351",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1326010352404973
    },
    {
        "id": 1099,
        "name": "Travis Trickett",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000010272306135513
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2017": {
                "college": "Georgia State",
                "position": "OC"
            },
            "2018": {
                "college": "Georgia State",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "0.0602473588393847",
                "net_sr": "0.0164149816437884",
                "net_stuff": "0.00404613425623612",
                "net_pass_sr": "-0.0164011795452861",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia State",
                "role": "OC",
                "net_ppa": "0.0350434244843265",
                "net_sr": "0.029449475837274",
                "net_stuff": "-0.00854015076946277",
                "net_pass_sr": "0.0290929969068284",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0952907833237112
    },
    {
        "id": 1100,
        "name": "Charlie Weis Jr",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000286638907834884
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2019": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2020": {
                "college": "South Florida",
                "position": "OC"
            },
            "2021": {
                "college": "South Florida",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "0.0401228590345697",
                "net_sr": "0.000586387450573822",
                "net_stuff": "0.0393385472263298",
                "net_pass_sr": "0.0343922547594773",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "-0.0732418136843964",
                "net_sr": "-0.0117886546244063",
                "net_stuff": "0.0146014795812253",
                "net_pass_sr": "0.00942271705115511",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.033118954649826705
    },
    {
        "id": 1101,
        "name": "Clint Trickett",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000419335112290109
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "-0.14165115519227",
                "net_sr": "-0.0554620581765863",
                "net_stuff": "0.00298088246238135",
                "net_pass_sr": "-0.0666082027065808",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.14165115519227
    },
    {
        "id": 1102,
        "name": "Drew Mehringer",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000859157106747927
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Florida Atlantic",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "-0.14165115519227",
                "net_sr": "-0.0554620581765863",
                "net_stuff": "0.00298088246238135",
                "net_pass_sr": "-0.0666082027065808",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "-0.148899124748482",
                "net_sr": "-0.0724428241673475",
                "net_stuff": "0.00570460446202128",
                "net_pass_sr": "-0.101593333381723",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.290550279940752
    },
    {
        "id": 1104,
        "name": "James Coley",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000691337550909314
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Florida International",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Florida State",
                "position": "OC"
            },
            "2011": {
                "college": "Florida State",
                "position": "OC"
            },
            "2012": {
                "college": "Florida State",
                "position": "OC"
            },
            "2013": {
                "college": "Miami",
                "position": "OC"
            },
            "2014": {
                "college": "Miami",
                "position": "OC"
            },
            "2015": {
                "college": "Miami",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Georgia",
                "position": "OC"
            },
            "2019": {
                "college": "Georgia",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "-0.0690717527755875",
                "net_sr": "-0.0267690828273047",
                "net_stuff": "-0.0165815798347166",
                "net_pass_sr": "-0.0831053351573188",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "0.0322378921994788",
                "net_sr": "0.0147717233664876",
                "net_stuff": "-0.0211909767860621",
                "net_pass_sr": "0.0214412860660234",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "0.0844791440379709",
                "net_sr": "0.0484167078197709",
                "net_stuff": "-0.006816193134073",
                "net_pass_sr": "0.0749150403186742",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "0.114138712469166",
                "net_sr": "0.0178252372289556",
                "net_stuff": "0.0020119778765523",
                "net_pass_sr": "0.0136761650713584",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1617839959310282
    },
    {
        "id": 1105,
        "name": "Bill Legg",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000164160499118417
        },
        "positions": {
            "2000": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Purdue",
                "position": "OC"
            },
            "2007": {
                "college": "Purdue",
                "position": "OC"
            },
            "2008": {
                "college": "Florida International",
                "position": "OC"
            },
            "2009": {
                "college": "Florida International",
                "position": "OC"
            },
            "2010": {
                "college": "Marshall",
                "position": "OC"
            },
            "2011": {
                "college": "Marshall",
                "position": "OC"
            },
            "2012": {
                "college": "Marshall",
                "position": "OC"
            },
            "2013": {
                "college": "Marshall",
                "position": "OC"
            },
            "2014": {
                "college": "Marshall",
                "position": "OC"
            },
            "2015": {
                "college": "Marshall",
                "position": "OC"
            },
            "2016": {
                "college": "Marshall",
                "position": "OC"
            },
            "2017": {
                "college": "Marshall",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.0416384201113549",
                "net_sr": "0.0485443626874881",
                "net_stuff": "-0.0697713405608558",
                "net_pass_sr": "0.0454830273703076",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "0.108015804641116",
                "net_sr": "0.0290489054674491",
                "net_stuff": "-0.0208730638800634",
                "net_pass_sr": "0.0704536295767052",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.0367471661377882",
                "net_sr": "0.00421594699873162",
                "net_stuff": "0.0335753553196032",
                "net_pass_sr": "0.00300097229697321",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1129070586146827
    },
    {
        "id": 1106,
        "name": "Tim Cramsey",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.0000925245660987097
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Florida International",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Nevada",
                "position": "OC"
            },
            "2017": false,
            "2018": {
                "college": "Marshall",
                "position": "OC"
            },
            "2019": {
                "college": "Marshall",
                "position": "OC"
            },
            "2020": {
                "college": "Marshall",
                "position": "OC"
            },
            "2021": {
                "college": "Marshall",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "0.0754822204162268",
                "net_sr": "-0.00490273175130013",
                "net_stuff": "-0.0285830952281671",
                "net_pass_sr": "-0.0023672421411941",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "0.0532025225346961",
                "net_sr": "0.0343741615045564",
                "net_stuff": "-0.0627405686260815",
                "net_pass_sr": "-0.00166354869691349",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "0.0514999633949779",
                "net_sr": "0.0258693903375263",
                "net_stuff": "-0.0234158128642986",
                "net_pass_sr": "-0.0342511812725671",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1801847063459008
    },
    {
        "id": 1107,
        "name": "Ed Zaunbrecher",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.000135595098675636
        },
        "positions": {
            "2000": {
                "college": "Marshall",
                "position": "OC"
            },
            "2001": {
                "college": "Marshall",
                "position": "OC"
            },
            "2002": {
                "college": "Florida",
                "position": "OC"
            },
            "2003": {
                "college": "Florida",
                "position": "OC"
            },
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Purdue",
                "position": "OC"
            },
            "2007": {
                "college": "Purdue",
                "position": "OC"
            },
            "2008": {
                "college": "Purdue",
                "position": "OC"
            },
            "2009": {
                "college": "Rice",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.0613520508090805",
                "net_sr": "-0.0115004589086787",
                "net_stuff": "0.0460821689516502",
                "net_pass_sr": "-0.0092390349341982",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "-0.0572686364252864",
                "net_sr": "-0.0460696643745115",
                "net_stuff": "0.0269643793102134",
                "net_pass_sr": "-0.0592562972854184",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1186206872343669
    },
    {
        "id": 1108,
        "name": "Billy Gonzales",
        "community": 8,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000633577847466571
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Illinois",
                "position": "OC"
            },
            "2013": false,
            "2014": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2015": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2016": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2017": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2018": {
                "college": "Florida",
                "position": "OC"
            },
            "2019": {
                "college": "Florida",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "0.142842371916927",
                "net_sr": "0.0642487478092554",
                "net_stuff": "0.00422717803053232",
                "net_pass_sr": "0.0952016716036536",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "-0.0764893410394392",
                "net_sr": "-0.0145447852664807",
                "net_stuff": "0.0385802428096332",
                "net_pass_sr": "-0.0064108175297819",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.170017004051816",
                "net_sr": "0.0471808967576262",
                "net_stuff": "-0.0494191991392862",
                "net_pass_sr": "0.0203658274197989",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "Other",
        "aggregate_xpa": 0.2363700349293038
    },
    {
        "id": 1109,
        "name": "John Hevesy",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000582385594257936
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2015": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2016": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2017": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2018": {
                "college": "Florida",
                "position": "OC"
            },
            "2019": {
                "college": "Florida",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "-0.104738208319534",
                "net_sr": "-0.0709228143048733",
                "net_stuff": "0.0107805728106658",
                "net_pass_sr": "-0.068928889469267",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.017018593429734",
                "net_sr": "0.0441328593867331",
                "net_stuff": "0.00630770580576312",
                "net_pass_sr": "0.0305957435543348",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0877196148898
    },
    {
        "id": 1110,
        "name": "Brian Johnson",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00040477884303518
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Utah",
                "position": "OC"
            },
            "2013": {
                "college": "Utah",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Houston",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Florida",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Florida",
                "role": "OC",
                "net_ppa": "0.115003883899112",
                "net_sr": "0.0622306145464795",
                "net_stuff": "-0.0221522811848291",
                "net_pass_sr": "0.113114669259527",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0136046080949492",
                "net_sr": "0.00163226149662793",
                "net_stuff": "-0.0106283624358893",
                "net_pass_sr": "0.00967084106618987",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "0.0211723938977488",
                "net_sr": "-0.0371057914256789",
                "net_stuff": "0.0174878300020644",
                "net_pass_sr": "0.0018439033437051",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.12257166970191159
    },
    {
        "id": 1111,
        "name": "Michael Johnson",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000448684382147261
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "UCLA",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Florida Atlantic",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Florida Atlantic",
                "role": "OC",
                "net_ppa": "0.014594297091504",
                "net_sr": "-0.00620849180788036",
                "net_stuff": "-0.0418558353111891",
                "net_pass_sr": "0.0145466218860328",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "0.0746737195410812",
                "net_sr": "0.00946006863333387",
                "net_stuff": "-0.00807953766722344",
                "net_pass_sr": "0.0169496878878436",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0892680166325852
    },
    {
        "id": 1112,
        "name": "Andrew Breiner",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000842447808040189
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Florida International",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Florida International",
                "role": "OC",
                "net_ppa": "-0.0433882820417433",
                "net_sr": "-0.0260151379440982",
                "net_stuff": "-0.0166138781960786",
                "net_pass_sr": "-0.0337865006100547",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0433882820417433
    },
    {
        "id": 1114,
        "name": "Lawrence Dawsey",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000669473715562296
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Florida State",
                "position": "OC"
            },
            "2015": {
                "college": "Florida State",
                "position": "OC"
            },
            "2016": {
                "college": "Florida State",
                "position": "OC"
            },
            "2017": {
                "college": "Florida State",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "0.0554690639418818",
                "net_sr": "-0.0194003554453715",
                "net_stuff": "0.0300747941333165",
                "net_pass_sr": "-0.0375987006758989",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0554690639418818
    },
    {
        "id": 1115,
        "name": "Randy Sanders",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.00143435518842026
        },
        "positions": {
            "2000": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2001": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2002": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2003": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2004": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2005": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2010": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2011": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2012": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2013": false,
            "2014": {
                "college": "Florida State",
                "position": "OC"
            },
            "2015": {
                "college": "Florida State",
                "position": "OC"
            },
            "2016": {
                "college": "Florida State",
                "position": "OC"
            },
            "2017": {
                "college": "Florida State",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Florida State",
                "role": "OC",
                "net_ppa": "0.0554690639418818",
                "net_sr": "-0.0194003554453715",
                "net_stuff": "0.0300747941333165",
                "net_pass_sr": "-0.0375987006758989",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "-0.0127355961291699",
                "net_sr": "-0.0226924124955122",
                "net_stuff": "0.00226738954052072",
                "net_pass_sr": "-0.038895980527759",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0427334678127119
    },
    {
        "id": 1116,
        "name": "Dave Schramm",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000702357121603155
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Utah",
                "position": "OC"
            },
            "2010": {
                "college": "Utah",
                "position": "OC"
            },
            "2011": false,
            "2012": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2013": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2014": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2015": {
                "college": "Fresno State",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Fresno State",
                "role": "OC",
                "net_ppa": "-0.00468856244834363",
                "net_sr": "-0.00310243469266563",
                "net_stuff": "0.00243961775370308",
                "net_pass_sr": "-0.0417778511216579",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "-0.0255604963536386",
                "net_sr": "-0.0111302082213416",
                "net_stuff": "0.00924155700452711",
                "net_pass_sr": "-0.0313005787407483",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.03024905880198223
    },
    {
        "id": 1117,
        "name": "Brian Schottenheimer",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000410789105123046
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Georgia",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia",
                "role": "OC",
                "net_ppa": "-0.0317924825607796",
                "net_sr": "-0.0160218303200627",
                "net_stuff": "0.00831773180420581",
                "net_pass_sr": "-0.0346531571303957",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0317924825607796
    },
    {
        "id": 1118,
        "name": "David Dean",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000855080019356863
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Georgia Southern",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "OC",
                "net_ppa": "-0.100423565960547",
                "net_sr": "-0.0281839573128711",
                "net_stuff": "0.0498220337235272",
                "net_pass_sr": "0.171832884097035",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.100423565960547
    },
    {
        "id": 1119,
        "name": "Rance Gillespie",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000855080019356955
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Georgia Southern",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "OC",
                "net_ppa": "-0.100423565960547",
                "net_sr": "-0.0281839573128711",
                "net_stuff": "0.0498220337235272",
                "net_pass_sr": "0.171832884097035",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.100423565960547
    },
    {
        "id": 1120,
        "name": "Bryan Cook",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000855080019356814
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Georgia Southern",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "OC",
                "net_ppa": "-0.173701428925687",
                "net_sr": "-0.0786744370566287",
                "net_stuff": "0.0193061690313819",
                "net_pass_sr": "-0.079177216913066",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.173701428925687
    },
    {
        "id": 1121,
        "name": "Bob DeBesse",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000882019069198182
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2013": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2014": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2015": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2016": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2017": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2018": {
                "college": "Georgia Southern",
                "position": "OC"
            },
            "2019": {
                "college": "Georgia Southern",
                "position": "OC"
            },
            "2020": {
                "college": "Georgia Southern",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia Southern",
                "role": "OC",
                "net_ppa": "0.0126298307909677",
                "net_sr": "0.0101808022221493",
                "net_stuff": "-0.0158858911468933",
                "net_pass_sr": "-0.0226116279307769",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "0.192755017692577",
                "net_sr": "0.058980267785388",
                "net_stuff": "-0.0398755037832054",
                "net_pass_sr": "-0.00978969743318475",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.20538484848354469
    },
    {
        "id": 1122,
        "name": "Luke Huard",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 4.50108912761354e-8
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Georgia State",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Georgia State",
                "role": "OC",
                "net_ppa": "-0.00599257284324732",
                "net_sr": "-0.0213284678288174",
                "net_stuff": "-0.0169130922515394",
                "net_pass_sr": "-0.0320057059651747",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00599257284324732
    },
    {
        "id": 1123,
        "name": "Brad Glenn",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000161675560797158
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Georgia State",
                "position": "OC"
            },
            "2020": {
                "college": "Georgia State",
                "position": "OC"
            },
            "2021": {
                "college": "Georgia State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Georgia State",
                "role": "OC",
                "net_ppa": "0.0630819841535428",
                "net_sr": "0.0450840882251226",
                "net_stuff": "-0.0519881015280393",
                "net_pass_sr": "0.0206266281884004",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0630819841535428
    },
    {
        "id": 1125,
        "name": "Patrick Nix",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000113809072608594
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2004": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2005": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2006": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2007": {
                "college": "Miami",
                "position": "OC"
            },
            "2008": {
                "college": "Miami",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "-0.0571217272376057",
                "net_sr": "-0.0215254325064424",
                "net_stuff": "0.00601069223436407",
                "net_pass_sr": "-0.0351978006656783",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0571217272376057
    },
    {
        "id": 1126,
        "name": "Dave Patenaude",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00010270333624834
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Temple",
                "position": "OC"
            },
            "2018": {
                "college": "Temple",
                "position": "OC"
            },
            "2019": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2020": {
                "college": "Georgia Tech",
                "position": "OC"
            },
            "2021": {
                "college": "Georgia Tech",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Georgia Tech",
                "role": "OC",
                "net_ppa": "-0.116964361596592",
                "net_sr": "-0.0679823479121458",
                "net_stuff": "0.0579488389892719",
                "net_pass_sr": "0.022109931315113",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "0.0659504745630147",
                "net_sr": "0.0177487959771574",
                "net_stuff": "-0.0164984822079161",
                "net_pass_sr": "0.0451147953643425",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0510138870335773
    },
    {
        "id": 1127,
        "name": "Ron Lee",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000168067430635696
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2009": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.108284864650179",
                "net_sr": "-0.0717682126267805",
                "net_stuff": "0.00663867854308858",
                "net_pass_sr": "-0.0842230208134682",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.108284864650179
    },
    {
        "id": 1128,
        "name": "Tommy Lee",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000257586901965131
        },
        "positions": {
            "2000": {
                "college": "Utah",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.159766950776744",
                "net_sr": "-0.123249920044827",
                "net_stuff": "-0.010617309358477",
                "net_pass_sr": "-0.112974829963828",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.159766950776744
    },
    {
        "id": 1129,
        "name": "Aaron Price",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000262164254849306
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "UTEP",
                "position": "OC"
            },
            "2009": {
                "college": "UTEP",
                "position": "OC"
            },
            "2010": {
                "college": "UTEP",
                "position": "OC"
            },
            "2011": {
                "college": "UTEP",
                "position": "OC"
            },
            "2012": {
                "college": "UTEP",
                "position": "OC"
            },
            "2013": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.0408810297879417",
                "net_sr": "-0.0219551408502431",
                "net_stuff": "-0.0471472751832665",
                "net_pass_sr": "-0.0138862814694291",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "-0.0429035860663559",
                "net_sr": "0.00100543488166471",
                "net_stuff": "-0.0570923410710783",
                "net_pass_sr": "-0.0227042299829343",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0837846158542976
    },
    {
        "id": 1130,
        "name": "Don Bailey",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000130376649458793
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "0.0413828733666498",
                "net_sr": "0.00123778774665873",
                "net_stuff": "-0.0175779051634412",
                "net_pass_sr": "-0.020817629364006",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0413828733666498
    },
    {
        "id": 1131,
        "name": "G.J. Kinne",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000120103008367308
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2021": {
                "college": "UCF",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.00936332943533696",
                "net_sr": "-0.0602153679092002",
                "net_stuff": "0.00601957364726155",
                "net_pass_sr": "-0.0624195717611903",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.0764290015068179",
                "net_sr": "-0.0283001239012969",
                "net_stuff": "-0.0133489316075757",
                "net_pass_sr": "-0.0470069436845211",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.08579233094215487
    },
    {
        "id": 1132,
        "name": "Tony Hull",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000597725282923715
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Hawai'i",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.00936332943533696",
                "net_sr": "-0.0602153679092002",
                "net_stuff": "0.00601957364726155",
                "net_pass_sr": "-0.0624195717611903",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.00936332943533696
    },
    {
        "id": 1133,
        "name": "Bo Graham",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000597725282923734
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Hawai'i",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Hawai'i",
                "role": "OC",
                "net_ppa": "-0.0492544907831619",
                "net_sr": "-0.0325152221320709",
                "net_stuff": "0.00491010292391697",
                "net_pass_sr": "-0.0566671961873211",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0492544907831619
    },
    {
        "id": 1135,
        "name": "Dave Warner",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00106495095476341
        },
        "positions": {
            "2000": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2001": {
                "college": "Houston",
                "position": "OC"
            },
            "2002": {
                "college": "Houston",
                "position": "OC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2014": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2015": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2016": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2017": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2018": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": {
                "college": "UTEP",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "0.0825780453399829",
                "net_sr": "0.0253897288979088",
                "net_stuff": "-0.00278952103842453",
                "net_pass_sr": "0.000299929814272404",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "0.105389315746064",
                "net_sr": "-0.00442916359834022",
                "net_stuff": "-0.00997675521076469",
                "net_pass_sr": "0.0698911603134513",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1879673610860469
    },
    {
        "id": 1136,
        "name": "Jason Phillips",
        "community": 15,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000313195242068488
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Houston",
                "position": "OC"
            },
            "2009": {
                "college": "Houston",
                "position": "OC"
            },
            "2010": {
                "college": "Houston",
                "position": "OC"
            },
            "2011": {
                "college": "Houston",
                "position": "OC"
            },
            "2012": {
                "college": "SMU",
                "position": "OC"
            },
            "2013": {
                "college": "SMU",
                "position": "OC"
            },
            "2014": {
                "college": "SMU",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "0.0684620471422449",
                "net_sr": "0.067255644300374",
                "net_stuff": "-0.0419061588439943",
                "net_pass_sr": "0.097133396158225",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "-0.0500239655531308",
                "net_sr": "-0.040508854783165",
                "net_stuff": "-0.00856005795062259",
                "net_pass_sr": "-0.0530757828145052",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.018438081589114093
    },
    {
        "id": 1137,
        "name": "Mike Nesbitt",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000144918917169912
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Houston",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.106212133259063",
                "net_sr": "-0.0937730457640557",
                "net_stuff": "0.0518885110517367",
                "net_pass_sr": "-0.113628483246356",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.106212133259063
    },
    {
        "id": 1138,
        "name": "Doug Meacham",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000261156726525024
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Houston",
                "position": "OC"
            },
            "2014": {
                "college": "TCU",
                "position": "OC"
            },
            "2015": {
                "college": "TCU",
                "position": "OC"
            },
            "2016": {
                "college": "TCU",
                "position": "OC"
            },
            "2017": {
                "college": "Kansas",
                "position": "OC"
            },
            "2018": {
                "college": "Kansas",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": {
                "college": "TCU",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0639358702961445",
                "net_sr": "-0.0440193568149555",
                "net_stuff": "0.0610552789247834",
                "net_pass_sr": "-0.0633875257353952",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "0.0564277078190539",
                "net_sr": "0.0107989631347446",
                "net_stuff": "0.0200974035401837",
                "net_pass_sr": "-0.00216345801739182",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "TCU",
                "role": "OC",
                "net_ppa": "0.151124238406516",
                "net_sr": "0.0312727788101962",
                "net_stuff": "-0.0111147149278578",
                "net_pass_sr": "-0.00556830859428437",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1436160759294254
    },
    {
        "id": 1139,
        "name": "Travis Bush",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000209662061918417
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "UT San Antonio",
                "position": "OC"
            },
            "2012": false,
            "2013": {
                "college": "Houston",
                "position": "OC"
            },
            "2014": {
                "college": "Houston",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "0.00734423989522894",
                "net_sr": "-0.0257002500241155",
                "net_stuff": "0.0515256209917412",
                "net_pass_sr": "-0.045994705177866",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00734423989522894
    },
    {
        "id": 1140,
        "name": "Brandon Jones",
        "community": 15,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000795480538742591
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Houston",
                "position": "OC"
            },
            "2020": {
                "college": "Houston",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0515442308854887",
                "net_sr": "-0.0172119907886833",
                "net_stuff": "-0.00307199030050304",
                "net_pass_sr": "-0.044690875480791",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0515442308854887
    },
    {
        "id": 1141,
        "name": "Marquel Blackwell",
        "community": 15,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000397740269371231
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Houston",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0548100707592059",
                "net_sr": "-0.0188107428140565",
                "net_stuff": "0.00307699959848687",
                "net_pass_sr": "-0.0709360262457608",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0548100707592059
    },
    {
        "id": 1142,
        "name": "Shannon Dawson",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000438057328569391
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2013": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2014": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2015": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2016": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2017": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2018": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2019": false,
            "2020": {
                "college": "Houston",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Houston",
                "role": "OC",
                "net_ppa": "-0.0508749282580195",
                "net_sr": "-0.0111917343418057",
                "net_stuff": "-0.00391471239322513",
                "net_pass_sr": "0.00579090129071635",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "0.113526184269776",
                "net_sr": "0.00193905804597427",
                "net_stuff": "0.011331151183447",
                "net_pass_sr": "-0.0127351557377103",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.0611211101108884",
                "net_sr": "0.0236733121772988",
                "net_stuff": "-0.0407284327954695",
                "net_pass_sr": "0.0067430678036306",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "0.0364964807556077",
                "net_sr": "0.00739268292343137",
                "net_stuff": "-0.0131181926844998",
                "net_pass_sr": "0.0216407491762363",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1602688468782526
    },
    {
        "id": 1143,
        "name": "Mike Schultz",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00053634116672381
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "TCU",
                "position": "OC"
            },
            "2002": {
                "college": "TCU",
                "position": "OC"
            },
            "2003": {
                "college": "TCU",
                "position": "OC"
            },
            "2004": {
                "college": "TCU",
                "position": "OC"
            },
            "2005": {
                "college": "TCU",
                "position": "OC"
            },
            "2006": {
                "college": "TCU",
                "position": "OC"
            },
            "2007": {
                "college": "TCU",
                "position": "OC"
            },
            "2008": {
                "college": "TCU",
                "position": "OC"
            },
            "2009": {
                "college": "Illinois",
                "position": "OC"
            },
            "2010": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "0.00937744799948824",
                "net_sr": "-0.00743280179654321",
                "net_stuff": "0.0016229866971787",
                "net_pass_sr": "0.00221760409922234",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "0.035438617873738",
                "net_sr": "0.0380219939784742",
                "net_stuff": "-0.0695062352544658",
                "net_pass_sr": "0.0291390063624622",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "TCU",
                "role": "OC",
                "net_ppa": "-0.0460263869159244",
                "net_sr": "-0.00776399762439767",
                "net_stuff": "-0.0148162124888467",
                "net_pass_sr": "0.0262898997406577",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0012103210426981648
    },
    {
        "id": 1144,
        "name": "Chris Beatty",
        "community": 24,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000833648164153545
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Illinois",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Maryland",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Illinois",
                "role": "OC",
                "net_ppa": "-0.0764893410394392",
                "net_sr": "-0.0145447852664807",
                "net_stuff": "0.0385802428096332",
                "net_pass_sr": "-0.0064108175297819",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Maryland",
                "role": "OC",
                "net_ppa": "0.100535358509824",
                "net_sr": "0.0207342135091212",
                "net_stuff": "-0.00267712858327773",
                "net_pass_sr": "-0.0300904753564268",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.02404601747038479
    },
    {
        "id": 1146,
        "name": "Kevin Johns",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 5,
            "eigen": 0.00425452676594123
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Indiana",
                "position": "OC"
            },
            "2012": {
                "college": "Indiana",
                "position": "OC"
            },
            "2013": {
                "college": "Indiana",
                "position": "OC"
            },
            "2014": {
                "college": "Indiana",
                "position": "OC"
            },
            "2015": {
                "college": "Indiana",
                "position": "OC"
            },
            "2016": {
                "college": "Indiana",
                "position": "OC"
            },
            "2017": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2018": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2019": {
                "college": "Memphis",
                "position": "OC"
            },
            "2020": {
                "college": "Memphis",
                "position": "OC"
            },
            "2021": {
                "college": "Memphis",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.114724505717893",
                "net_sr": "0.0198671394441973",
                "net_stuff": "-0.0351864617161934",
                "net_pass_sr": "0.00232315929266624",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.0432678531505876",
                "net_sr": "-0.0220646115964251",
                "net_stuff": "-0.0117818117415108",
                "net_pass_sr": "-0.0192731944007467",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "-0.0545834685064448",
                "net_sr": "0.0193595619358633",
                "net_stuff": "0.053343267697024",
                "net_pass_sr": "0.0627588908690461",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "-0.159009306101085",
                "net_sr": "-0.0422944420972416",
                "net_stuff": "0.00919702826441868",
                "net_pass_sr": "-0.0631323986389462",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.14213612204022438
    },
    {
        "id": 1147,
        "name": "Greg Frey",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00207374881335286
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Indiana",
                "position": "OC"
            },
            "2015": {
                "college": "Indiana",
                "position": "OC"
            },
            "2016": {
                "college": "Indiana",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "0.100303934854804",
                "net_sr": "0.0197128013685379",
                "net_stuff": "0.0187070999212269",
                "net_pass_sr": "0.00991729620975151",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.100303934854804
    },
    {
        "id": 1148,
        "name": "Grant Heard",
        "community": 40,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000146620162379494
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Indiana",
                "position": "OC"
            },
            "2021": {
                "college": "Indiana",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "-0.076741121758869",
                "net_sr": "-0.059930315400414",
                "net_stuff": "-0.00646758412178688",
                "net_pass_sr": "-0.0761933258553537",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.076741121758869
    },
    {
        "id": 1149,
        "name": "Nick Sheridan",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000146620162379491
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Indiana",
                "position": "OC"
            },
            "2021": {
                "college": "Indiana",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Indiana",
                "role": "OC",
                "net_ppa": "-0.076741121758869",
                "net_sr": "-0.059930315400414",
                "net_stuff": "-0.00646758412178688",
                "net_pass_sr": "-0.0761933258553537",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.076741121758869
    },
    {
        "id": 1151,
        "name": "Greg Davis",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00235616914646983
        },
        "positions": {
            "2000": {
                "college": "Texas",
                "position": "OC"
            },
            "2001": {
                "college": "Texas",
                "position": "OC"
            },
            "2002": {
                "college": "Texas",
                "position": "OC"
            },
            "2003": {
                "college": "Texas",
                "position": "OC"
            },
            "2004": {
                "college": "Texas",
                "position": "OC"
            },
            "2005": {
                "college": "Texas",
                "position": "OC"
            },
            "2006": {
                "college": "Texas",
                "position": "OC"
            },
            "2007": {
                "college": "Texas",
                "position": "OC"
            },
            "2008": {
                "college": "Texas",
                "position": "OC"
            },
            "2009": {
                "college": "Texas",
                "position": "OC"
            },
            "2010": {
                "college": "Texas",
                "position": "OC"
            },
            "2011": false,
            "2012": {
                "college": "Iowa",
                "position": "OC"
            },
            "2013": {
                "college": "Iowa",
                "position": "OC"
            },
            "2014": {
                "college": "Iowa",
                "position": "OC"
            },
            "2015": {
                "college": "Iowa",
                "position": "OC"
            },
            "2016": {
                "college": "Iowa",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Iowa",
                "role": "OC",
                "net_ppa": "0.0323941936579291",
                "net_sr": "0.000154254686789645",
                "net_stuff": "-0.024847023990807",
                "net_pass_sr": "-0.0329395066545591",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 16,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0323941936579291
    },
    {
        "id": 1152,
        "name": "Brian Ferentz",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000683958361677296
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Iowa",
                "position": "OC"
            },
            "2018": {
                "college": "Iowa",
                "position": "OC"
            },
            "2019": {
                "college": "Iowa",
                "position": "OC"
            },
            "2020": {
                "college": "Iowa",
                "position": "OC"
            },
            "2021": {
                "college": "Iowa",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Iowa",
                "role": "OC",
                "net_ppa": "-0.0239116897743319",
                "net_sr": "-0.00978705671335028",
                "net_stuff": "0.0209045662413244",
                "net_pass_sr": "0.0174694332629773",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0239116897743319
    },
    {
        "id": 1155,
        "name": "Barney Cotton",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0468543084848587
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2004": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2005": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "UNLV",
                "position": "OC"
            },
            "2016": {
                "college": "UNLV",
                "position": "OC"
            },
            "2017": {
                "college": "UNLV",
                "position": "OC"
            },
            "2018": {
                "college": "UNLV",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "0.137220213420862",
                "net_sr": "0.0260100719805406",
                "net_stuff": "0.00303999704714344",
                "net_pass_sr": "-0.0097689899587593",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.137220213420862
    },
    {
        "id": 1156,
        "name": "Robert McFarland",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000107801652827495
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "UCF",
                "position": "OC"
            },
            "2002": {
                "college": "UCF",
                "position": "OC"
            },
            "2003": {
                "college": "UCF",
                "position": "OC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2008": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Iowa State",
                "role": "OC",
                "net_ppa": "-0.0254237434397818",
                "net_sr": "-0.0145233332783928",
                "net_stuff": "0.0181132520340411",
                "net_pass_sr": "2.77303466890144e-05",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0254237434397818
    },
    {
        "id": 1157,
        "name": "Courtney Messingham",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000200198871835615
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2013": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2020": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2021": {
                "college": "Kansas State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Iowa State",
                "role": "OC",
                "net_ppa": "-0.00677633580768558",
                "net_sr": "-0.00882009401906431",
                "net_stuff": "0.00294033390164611",
                "net_pass_sr": "-0.00900160585611126",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "0.0260937199114059",
                "net_sr": "-0.015239532724735",
                "net_stuff": "-0.00637916480343503",
                "net_pass_sr": "0.0143134703458731",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.01931738410372032
    },
    {
        "id": 1158,
        "name": "Tom Manning",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000392171498347834
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2017": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2018": false,
            "2019": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2020": {
                "college": "Iowa State",
                "position": "OC"
            },
            "2021": {
                "college": "Iowa State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Iowa State",
                "role": "OC",
                "net_ppa": "0.142305406326671",
                "net_sr": "0.0372614853129403",
                "net_stuff": "0.00389692084485393",
                "net_pass_sr": "0.0786028195278522",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.142305406326671
    },
    {
        "id": 1161,
        "name": "Ed Warinner",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00190424097337534
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Kansas",
                "position": "OC"
            },
            "2008": {
                "college": "Kansas",
                "position": "OC"
            },
            "2009": {
                "college": "Kansas",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2013": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2014": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2015": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2016": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "0.110446955058494",
                "net_sr": "0.0802264227949395",
                "net_stuff": "-0.0639047742217662",
                "net_pass_sr": "0.0997510991644671",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "0.166736677108331",
                "net_sr": "0.0844589316334963",
                "net_stuff": "-0.035387769088671",
                "net_pass_sr": "0.0744068902626709",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.277183632166825
    },
    {
        "id": 1162,
        "name": "John Reagan",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000096697298199287
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Rice",
                "position": "OC"
            },
            "2012": {
                "college": "Rice",
                "position": "OC"
            },
            "2013": {
                "college": "Rice",
                "position": "OC"
            },
            "2014": {
                "college": "Kansas",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.0717112557085696",
                "net_sr": "-0.0421784783581961",
                "net_stuff": "0.00963700370682738",
                "net_pass_sr": "-0.0415099362787036",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.0484034248376115",
                "net_sr": "0.041493672931428",
                "net_stuff": "0.00587255362925093",
                "net_pass_sr": "0.0630096979192495",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.023307830870958092
    },
    {
        "id": 1163,
        "name": "Brent Dearmon",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000544545076576571
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Kansas",
                "position": "OC"
            },
            "2021": {
                "college": "Middle Tennessee",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Kansas",
                "role": "OC",
                "net_ppa": "-0.153171458598538",
                "net_sr": "-0.0738086956643233",
                "net_stuff": "0.0605650320115046",
                "net_pass_sr": "-0.0695437355038473",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "-0.0658737196605189",
                "net_sr": "-0.0449805282635625",
                "net_stuff": "-0.0205336171583311",
                "net_pass_sr": "-0.0433495214329475",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2190451782590569
    },
    {
        "id": 1164,
        "name": "Ron Hudson",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0004051502145253
        },
        "positions": {
            "2000": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2001": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2002": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2003": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2004": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2010": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.0344884477095108",
                "net_sr": "-0.0337026106582475",
                "net_stuff": "0.04966628106012",
                "net_pass_sr": "0.00631963429572935",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0344884477095108
    },
    {
        "id": 1165,
        "name": "Del Miller",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00214915190363787
        },
        "positions": {
            "2000": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2004": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2005": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2006": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2007": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2008": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2009": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2010": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2011": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2012": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2013": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2014": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2015": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2016": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "-0.0888009640761917",
                "net_sr": "-0.0406908283900479",
                "net_stuff": "0.033825781128387",
                "net_pass_sr": "-0.0214839647874231",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 15,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0888009640761917
    },
    {
        "id": 1166,
        "name": "Dave Brock",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000227903272510273
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Temple",
                "position": "OC"
            },
            "2003": {
                "college": "Temple",
                "position": "OC"
            },
            "2004": {
                "college": "Temple",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "0.0351761112418194",
                "net_sr": "0.0193703714479987",
                "net_stuff": "-0.0174172556470781",
                "net_pass_sr": "0.00760464950193113",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.00800379636256351",
                "net_sr": "-0.00464051297169527",
                "net_stuff": "0.00323277758988028",
                "net_pass_sr": "0.0443236181970652",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.04317990760438291
    },
    {
        "id": 1167,
        "name": "Andre Coleman",
        "community": 30,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000125905608920709
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "-0.0688353378163953",
                "net_sr": "-0.0365431731841931",
                "net_stuff": "-0.00165524201446765",
                "net_pass_sr": "0.00741444604372954",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0688353378163953
    },
    {
        "id": 1168,
        "name": "Charlie Dickey",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000125905608920709
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "-0.0688353378163953",
                "net_sr": "-0.0365431731841931",
                "net_stuff": "-0.00165524201446765",
                "net_pass_sr": "0.00741444604372954",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0688353378163953
    },
    {
        "id": 1169,
        "name": "Collin Klein",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00012590560892071
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Kansas State",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kansas State",
                "role": "OC",
                "net_ppa": "-0.0688353378163953",
                "net_sr": "-0.0365431731841931",
                "net_stuff": "-0.00165524201446765",
                "net_pass_sr": "0.00741444604372954",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0688353378163953
    },
    {
        "id": 1171,
        "name": "A.J. Pratt",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000277260873179163
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Kent State",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kent State",
                "role": "OC",
                "net_ppa": "-0.0935906225575941",
                "net_sr": "-0.0287774520125171",
                "net_stuff": "0.0870568280559534",
                "net_pass_sr": "-0.00579457999017519",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0935906225575941
    },
    {
        "id": 1172,
        "name": "Brian Rock",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000731180994559027
        },
        "positions": {
            "2000": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2001": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2002": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2003": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2004": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Kent State",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Kent State",
                "role": "OC",
                "net_ppa": "0.094747215519357",
                "net_sr": "0.0395310465852846",
                "net_stuff": "-0.0599279142954657",
                "net_pass_sr": "0.072269714578197",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.094747215519357
    },
    {
        "id": 1173,
        "name": "Andrew Sowder",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000750352863273007
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "San José State",
                "position": "OC"
            },
            "2018": {
                "college": "Kent State",
                "position": "OC"
            },
            "2019": {
                "college": "Kent State",
                "position": "OC"
            },
            "2020": {
                "college": "Kent State",
                "position": "OC"
            },
            "2021": {
                "college": "Kent State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Kent State",
                "role": "OC",
                "net_ppa": "0.276816251868524",
                "net_sr": "0.134080427823956",
                "net_stuff": "-0.0573052677761537",
                "net_pass_sr": "0.15870720851599",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "-0.147471274077108",
                "net_sr": "-0.0495130972510684",
                "net_stuff": "0.00284495824557596",
                "net_pass_sr": "-0.0642698783163163",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.129344977791416
    },
    {
        "id": 1174,
        "name": "Darin Hinshaw",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0013872903014404
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2004": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2005": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2017": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2018": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2019": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2020": {
                "college": "Kentucky",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "0.0755001869044701",
                "net_sr": "0.0319037413797466",
                "net_stuff": "-0.0612102449606071",
                "net_pass_sr": "0.0110376815317154",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0755001869044701
    },
    {
        "id": 1175,
        "name": "Liam Coen",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000266309948221983
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Kentucky",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Kentucky",
                "role": "OC",
                "net_ppa": "0.0851165597335722",
                "net_sr": "0.0656823503746548",
                "net_stuff": "-0.0273181267243212",
                "net_pass_sr": "0.112223008344248",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0851165597335722
    },
    {
        "id": 1176,
        "name": "Joe Dailey",
        "community": 30,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000392571963128871
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Liberty",
                "position": "OC"
            },
            "2019": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Liberty",
                "role": "OC",
                "net_ppa": "-0.00323415679968883",
                "net_sr": "-0.0155273453242077",
                "net_stuff": "0.0406507916767835",
                "net_pass_sr": "0.0539379468321714",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "0.178673596614223",
                "net_sr": "0.0159599420207739",
                "net_stuff": "-0.0177947593764287",
                "net_pass_sr": "-0.0271771946504027",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.17543943981453416
    },
    {
        "id": 1177,
        "name": "Kent Austin",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000697219893576315
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2009": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Liberty",
                "position": "OC"
            },
            "2020": {
                "college": "Liberty",
                "position": "OC"
            },
            "2021": {
                "college": "Liberty",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Liberty",
                "role": "OC",
                "net_ppa": "0.0264590264631642",
                "net_sr": "-0.0373182498411949",
                "net_stuff": "-0.0156366305577384",
                "net_pass_sr": "-0.0424235913788152",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.134770092977212",
                "net_sr": "-0.019123780702566",
                "net_stuff": "0.0782276103030464",
                "net_pass_sr": "0.0229571021205442",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.10831106651404782
    },
    {
        "id": 1178,
        "name": "Maurice Harris",
        "community": 6,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000544419353373845
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Liberty",
                "position": "OC"
            },
            "2020": {
                "college": "Liberty",
                "position": "OC"
            },
            "2021": {
                "college": "Liberty",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Liberty",
                "role": "OC",
                "net_ppa": "0.217880879892335",
                "net_sr": "0.0797681895171022",
                "net_stuff": "-0.0442960855142751",
                "net_pass_sr": "0.0527633954640522",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.217880879892335
    },
    {
        "id": 1179,
        "name": "Larry Edmondson",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000158848756527958
        },
        "positions": {
            "2000": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2001": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2002": {
                "college": "SMU",
                "position": "OC"
            },
            "2003": {
                "college": "SMU",
                "position": "OC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Rice",
                "position": "OC"
            },
            "2015": {
                "college": "Rice",
                "position": "OC"
            },
            "2016": {
                "college": "Rice",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.0899785640958609",
                "net_sr": "0.00758686340757841",
                "net_stuff": "-0.0141978288394567",
                "net_pass_sr": "0.0274078384736217",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0899785640958609
    },
    {
        "id": 1182,
        "name": "Jorge Munoz",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000154542276473126
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.25284214053913",
                "net_sr": "-0.0943212926509334",
                "net_stuff": "0.00296933371451349",
                "net_pass_sr": "-0.135709831206872",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.25284214053913
    },
    {
        "id": 1183,
        "name": "Rob Sale",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000129484250640532
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2019": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2020": {
                "college": "Louisiana",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "0.156506031007599",
                "net_sr": "0.0591306348299086",
                "net_stuff": "-0.0347993743501634",
                "net_pass_sr": "0.0764411188995409",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.156506031007599
    },
    {
        "id": 1184,
        "name": "Michael Desormeaux",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000431614168801665
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Louisiana",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.0485903362067929",
                "net_sr": "-0.0276950923932314",
                "net_stuff": "0.0305387478254437",
                "net_pass_sr": "-0.00649387681243041",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0485903362067929
    },
    {
        "id": 1185,
        "name": "Tim Leger",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000431614168801652
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Louisiana",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Louisiana",
                "role": "OC",
                "net_ppa": "-0.0485903362067929",
                "net_sr": "-0.0276950923932314",
                "net_stuff": "0.0305387478254437",
                "net_pass_sr": "-0.00649387681243041",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0485903362067929
    },
    {
        "id": 1189,
        "name": "Nate Kaczor",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000105513094800025
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "0.245854821301933",
                "net_sr": "0.123433109886949",
                "net_stuff": "-0.242400419287212",
                "net_pass_sr": "0.13500666000666",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.245854821301933
    },
    {
        "id": 1190,
        "name": "Steve Farmer",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000104589240316443
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2008": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2009": false,
            "2010": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2011": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2012": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2013": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2014": false,
            "2015": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "0.234807969380533",
                "net_sr": "0.101338603297493",
                "net_stuff": "-0.240071357351528",
                "net_pass_sr": "0.132271588836678",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.234807969380533
    },
    {
        "id": 1191,
        "name": "Matt Kubik",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000461734543441466
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2017": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2018": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2019": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2020": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "0.153126651786067",
                "net_sr": "0.0666273442032966",
                "net_stuff": "-0.0440324611841577",
                "net_pass_sr": "0.040445995892574",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.0381845873700836",
                "net_sr": "0.00150653679621326",
                "net_stuff": "-0.0293425463241171",
                "net_pass_sr": "-0.010495305186655",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1913112391561506
    },
    {
        "id": 1192,
        "name": "Eman Naghavi",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 1.0006364202402e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Louisiana Monroe",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Monroe",
                "role": "OC",
                "net_ppa": "-0.185220042940022",
                "net_sr": "-0.08269212100154",
                "net_stuff": "0.0446639838954257",
                "net_pass_sr": "-0.0685600772924292",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.185220042940022
    },
    {
        "id": 1193,
        "name": "Conroy Hines",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000146260601001606
        },
        "positions": {
            "2000": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2001": false,
            "2002": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2003": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2004": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2005": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2006": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "-0.111047989008116",
                "net_sr": "-0.0294498448385704",
                "net_stuff": "-0.0456140350877193",
                "net_pass_sr": "-0.037888436067244",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.111047989008116
    },
    {
        "id": 1194,
        "name": "Frank Scelfo",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000183104945689367
        },
        "positions": {
            "2000": {
                "college": "Tulane",
                "position": "OC"
            },
            "2001": {
                "college": "Tulane",
                "position": "OC"
            },
            "2002": {
                "college": "Tulane",
                "position": "OC"
            },
            "2003": {
                "college": "Tulane",
                "position": "OC"
            },
            "2004": {
                "college": "Tulane",
                "position": "OC"
            },
            "2005": {
                "college": "Tulane",
                "position": "OC"
            },
            "2006": {
                "college": "Tulane",
                "position": "OC"
            },
            "2007": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2008": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2009": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "UT San Antonio",
                "position": "OC"
            },
            "2017": {
                "college": "UT San Antonio",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "0.0489703430501857",
                "net_sr": "0.0371584020362262",
                "net_stuff": "-0.0139252603052346",
                "net_pass_sr": "-0.0079385529029723",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UT San Antonio",
                "role": "OC",
                "net_ppa": "0.0863150813700349",
                "net_sr": "0.00976327651792419",
                "net_stuff": "-0.00283532010361501",
                "net_pass_sr": "0.000356742176856273",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1352854244202206
    },
    {
        "id": 1195,
        "name": "Joe Sloan",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000314913739952577
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Louisiana Tech",
                "position": "OC"
            },
            "2021": {
                "college": "Louisiana Tech",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Louisiana Tech",
                "role": "OC",
                "net_ppa": "0.161928637821166",
                "net_sr": "0.0407817547421124",
                "net_stuff": "-0.0166087755761154",
                "net_pass_sr": "0.0218326269027728",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.161928637821166
    },
    {
        "id": 1197,
        "name": "Chris Klenakis",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000121195741759396
        },
        "positions": {
            "2000": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2001": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2002": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2003": false,
            "2004": {
                "college": "Nevada",
                "position": "OC"
            },
            "2005": {
                "college": "Nevada",
                "position": "OC"
            },
            "2006": {
                "college": "Nevada",
                "position": "OC"
            },
            "2007": {
                "college": "Nevada",
                "position": "OC"
            },
            "2008": {
                "college": "Nevada",
                "position": "OC"
            },
            "2009": {
                "college": "Nevada",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Louisville",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.160338442123311",
                "net_sr": "0.049611607438708",
                "net_stuff": "-0.0681203367916027",
                "net_pass_sr": "0.00324567002024811",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.160338442123311
    },
    {
        "id": 1198,
        "name": "Lonnie Galloway",
        "community": 20,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00004612991785344
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Louisville",
                "position": "OC"
            },
            "2017": {
                "college": "Louisville",
                "position": "OC"
            },
            "2018": {
                "college": "Louisville",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "0.0717089878884501",
                "net_sr": "0.01837282138424",
                "net_stuff": "-0.0424365950850924",
                "net_pass_sr": "-0.0222255234550112",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0717089878884501
    },
    {
        "id": 1199,
        "name": "Mike Summers",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000307532785689564
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Louisville",
                "position": "OC"
            },
            "2018": {
                "college": "Louisville",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Louisville",
                "role": "OC",
                "net_ppa": "-0.0181901008503838",
                "net_sr": "0.00548273584763703",
                "net_stuff": "-0.00958885863773637",
                "net_pass_sr": "-0.00190235213096068",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0181901008503838
    },
    {
        "id": 1200,
        "name": "Jake Peetz",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00012776276523917
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "LSU",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "LSU",
                "role": "OC",
                "net_ppa": "-0.0708909175524954",
                "net_sr": "-0.0679114262456929",
                "net_stuff": "-0.0197503421036128",
                "net_pass_sr": "-0.0461127592379212",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0708909175524954
    },
    {
        "id": 1202,
        "name": "John Shannon",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000065611481334055
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Troy",
                "position": "OC"
            },
            "2002": {
                "college": "Troy",
                "position": "OC"
            },
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Toledo",
                "position": "OC"
            },
            "2006": {
                "college": "Toledo",
                "position": "OC"
            },
            "2007": {
                "college": "Toledo",
                "position": "OC"
            },
            "2008": {
                "college": "Marshall",
                "position": "OC"
            },
            "2009": {
                "college": "Marshall",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "0.00905629975656338",
                "net_sr": "0.00862174182250547",
                "net_stuff": "0.00985649749144443",
                "net_pass_sr": "0.0173731230399279",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00905629975656338
    },
    {
        "id": 1203,
        "name": "Todd Goebbel",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000106786612901287
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Marshall",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Marshall",
                "role": "OC",
                "net_ppa": "-0.0441311632950844",
                "net_sr": "-0.0359429293154065",
                "net_stuff": "0.0490218543133084",
                "net_pass_sr": "0.0192185844441247",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0441311632950844
    },
    {
        "id": 1206,
        "name": "Charlie Taaffe",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000219882285018822
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Maryland",
                "position": "OC"
            },
            "2002": {
                "college": "Maryland",
                "position": "OC"
            },
            "2003": {
                "college": "Maryland",
                "position": "OC"
            },
            "2004": {
                "college": "Maryland",
                "position": "OC"
            },
            "2005": {
                "college": "Maryland",
                "position": "OC"
            },
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "UCF",
                "position": "OC"
            },
            "2010": {
                "college": "UCF",
                "position": "OC"
            },
            "2011": {
                "college": "UCF",
                "position": "OC"
            },
            "2012": {
                "college": "UCF",
                "position": "OC"
            },
            "2013": {
                "college": "UCF",
                "position": "OC"
            },
            "2014": {
                "college": "UCF",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "0.0965032778623038",
                "net_sr": "0.0504422582142279",
                "net_stuff": "-0.0211900749716036",
                "net_pass_sr": "0.0671638781812903",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0965032778623038
    },
    {
        "id": 1207,
        "name": "Angelo Mirando",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000512604600942288
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "UMass",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "UMass",
                "role": "OC",
                "net_ppa": "-0.242012292476169",
                "net_sr": "-0.138769769186682",
                "net_stuff": "0.0232798281485202",
                "net_pass_sr": "-0.11666098648775",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.242012292476169
    },
    {
        "id": 1208,
        "name": "Eric Price",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000189226429711649
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "UTEP",
                "position": "OC"
            },
            "2005": {
                "college": "UTEP",
                "position": "OC"
            },
            "2006": {
                "college": "UTEP",
                "position": "OC"
            },
            "2007": {
                "college": "UTEP",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Memphis",
                "position": "OC"
            },
            "2011": {
                "college": "Memphis",
                "position": "OC"
            },
            "2012": {
                "college": "Tulane",
                "position": "OC"
            },
            "2013": {
                "college": "Tulane",
                "position": "OC"
            },
            "2014": {
                "college": "Tulane",
                "position": "OC"
            },
            "2015": {
                "college": "Tulane",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.0555899179435207",
                "net_sr": "-0.0772496670152533",
                "net_stuff": "0.0657171328115847",
                "net_pass_sr": "-0.0342608781614742",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "0.00384162474606551",
                "net_sr": "-0.0288068476747178",
                "net_stuff": "0.0295620702876063",
                "net_pass_sr": "-0.0427019453366157",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "-0.000264646856131256",
                "net_sr": "0.0283739485339222",
                "net_stuff": "-0.114563532951681",
                "net_pass_sr": "-0.00719306074636022",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.05201294005358645
    },
    {
        "id": 1209,
        "name": "Blake Miller",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 4.5425321945981e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Memphis",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "-0.0841185846487023",
                "net_sr": "-0.0889686853427943",
                "net_stuff": "0.0669324376780052",
                "net_pass_sr": "-0.0252637594419818",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0841185846487023
    },
    {
        "id": 1210,
        "name": "Brad Cornelsen",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000156944558378603
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Memphis",
                "position": "OC"
            },
            "2016": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2017": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2018": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2019": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2020": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2021": {
                "college": "Virginia Tech",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "0.173639520297554",
                "net_sr": "0.0613609513859883",
                "net_stuff": "-0.0152451256576232",
                "net_pass_sr": "0.0761252420041499",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Virginia Tech",
                "role": "OC",
                "net_ppa": "0.106093610730551",
                "net_sr": "0.0363249584343968",
                "net_stuff": "-0.0321533832503327",
                "net_pass_sr": "0.02319686836201",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.279733131028105
    },
    {
        "id": 1211,
        "name": "Chip Long",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000768164930370395
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Memphis",
                "position": "OC"
            },
            "2017": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2018": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2019": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2020": false,
            "2021": {
                "college": "Tulane",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Memphis",
                "role": "OC",
                "net_ppa": "0.101957709454797",
                "net_sr": "0.024536936849155",
                "net_stuff": "0.0166899441260589",
                "net_pass_sr": "0.0175865069157119",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.00555457547699012",
                "net_sr": "-0.019326868718602",
                "net_stuff": "0.0308030390868278",
                "net_pass_sr": "-0.0267205751109544",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "0.00161549280775344",
                "net_sr": "-0.0362227832262805",
                "net_stuff": "0.00643853411803189",
                "net_pass_sr": "-0.00216972774991758",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.10912777773954056
    },
    {
        "id": 1213,
        "name": "Dan Werner",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000382397745070865
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Miami",
                "position": "OC"
            },
            "2005": {
                "college": "Miami",
                "position": "OC"
            },
            "2006": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2007": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2013": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2014": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2015": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2016": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "0.080859349684",
                "net_sr": "0.0983025661836627",
                "net_stuff": "-0.162375096957789",
                "net_pass_sr": "0.0574238489280164",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.080859349684
    },
    {
        "id": 1214,
        "name": "Thomas Brown",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000123236731536946
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Miami",
                "position": "OC"
            },
            "2017": {
                "college": "Miami",
                "position": "OC"
            },
            "2018": {
                "college": "Miami",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "0.00717007229416255",
                "net_sr": "-0.0197284916372992",
                "net_stuff": "0.0115854859433406",
                "net_pass_sr": "-0.0328958691549893",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.00717007229416255
    },
    {
        "id": 1215,
        "name": "Ron Dugans",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000410789105123068
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Miami",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami",
                "role": "OC",
                "net_ppa": "-0.03321776455355",
                "net_sr": "-0.0315786949426263",
                "net_stuff": "-0.0300994497037211",
                "net_pass_sr": "-0.0758334763260265",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.03321776455355
    },
    {
        "id": 1217,
        "name": "John Klacik",
        "community": 37,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000188595377128698
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2012": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2013": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "0.0752036313876305",
                "net_sr": "0.067293548435269",
                "net_stuff": "-0.000653349192197838",
                "net_pass_sr": "0.104613127057679",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0752036313876305
    },
    {
        "id": 1218,
        "name": "Eric Koehler",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000177098605867414
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2015": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2016": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2017": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2018": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2019": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2020": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2021": {
                "college": "Miami (OH)",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "0.107811734055143",
                "net_sr": "0.00605645591974208",
                "net_stuff": "-0.0129209345933897",
                "net_pass_sr": "0.0102444042711611",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.107811734055143
    },
    {
        "id": 1219,
        "name": "George Barnett",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000132823954400514
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2015": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2016": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2017": false,
            "2018": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2019": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2020": {
                "college": "Miami (OH)",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Miami (OH)",
                "role": "OC",
                "net_ppa": "0.0954170573614155",
                "net_sr": "0.00399209146558793",
                "net_stuff": "-0.00748135485906454",
                "net_pass_sr": "0.0074020224063831",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0954170573614155
    },
    {
        "id": 1220,
        "name": "Tim Drevno",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000872472113671793
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Michigan",
                "position": "OC"
            },
            "2016": {
                "college": "Michigan",
                "position": "OC"
            },
            "2017": {
                "college": "Michigan",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.075646207849286",
                "net_sr": "0.00557655516453476",
                "net_stuff": "-0.065616370524561",
                "net_pass_sr": "-0.0246116332652971",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.075646207849286
    },
    {
        "id": 1221,
        "name": "Sherrone Moore",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000290824037890495
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Michigan",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Michigan",
                "role": "OC",
                "net_ppa": "0.0524809342146228",
                "net_sr": "0.0145330417961378",
                "net_stuff": "-0.0548521010375925",
                "net_pass_sr": "-0.0118239548917387",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0524809342146228
    },
    {
        "id": 1222,
        "name": "Dan Roushar",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000258908672893495
        },
        "positions": {
            "2000": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2001": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2002": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2012": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "-0.0165005378740611",
                "net_sr": "-0.00986923984816868",
                "net_stuff": "-0.0679136062103883",
                "net_pass_sr": "-0.0224994306957302",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0165005378740611
    },
    {
        "id": 1223,
        "name": "Jim Bollman",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00128650493268083
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2005": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2006": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2007": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2008": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2009": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2010": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2011": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2012": false,
            "2013": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2014": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2015": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2016": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2017": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2018": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "-0.102819269190386",
                "net_sr": "-0.0392724400843862",
                "net_stuff": "0.0134752460856149",
                "net_pass_sr": "0.0249016218110072",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.102819269190386
    },
    {
        "id": 1224,
        "name": "Brad Salem",
        "community": 37,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000118787562393532
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Michigan State",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Michigan State",
                "role": "OC",
                "net_ppa": "-0.0343195966996998",
                "net_sr": "-0.00470509501082589",
                "net_stuff": "0.026177211642652",
                "net_pass_sr": "0.00403916808760862",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0343195966996998
    },
    {
        "id": 1225,
        "name": "G.A. Mangus",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000100568178300889
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2007": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2008": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "-0.00328745112780128",
                "net_sr": "0.0843782832166203",
                "net_stuff": "0.0160455486542443",
                "net_pass_sr": "0.0839329477306461",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00328745112780128
    },
    {
        "id": 1226,
        "name": "Willie Simmons",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000033522726100285
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Middle Tennessee",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Middle Tennessee",
                "role": "OC",
                "net_ppa": "-0.0409667754705038",
                "net_sr": "-0.00121966655462075",
                "net_stuff": "-0.0362014896855693",
                "net_pass_sr": "-0.00987363722902157",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0409667754705038
    },
    {
        "id": 1227,
        "name": "Mitch Browning",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000707796661944947
        },
        "positions": {
            "2000": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2001": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2002": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2003": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2004": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2005": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2006": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2007": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2008": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "0.00712132125322432",
                "net_sr": "0.0161995880194933",
                "net_stuff": "-0.0582259651421643",
                "net_pass_sr": "-0.00810735433081261",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00712132125322432
    },
    {
        "id": 1228,
        "name": "Jeff Horton",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000414693319611684
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2016": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2017": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2018": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2019": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.00657201088093955",
                "net_sr": "-0.00580551034117482",
                "net_stuff": "0.00436590108284929",
                "net_pass_sr": "-0.00851592361111803",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "0.0809552954960993",
                "net_sr": "0.00457026668963278",
                "net_stuff": "0.0234308785421343",
                "net_pass_sr": "0.0208688497514841",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.08752730637703884
    },
    {
        "id": 1229,
        "name": "Matt Limegrover",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000139390509742698
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2009": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2010": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2011": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2012": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2013": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2014": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2015": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.0545436735410225",
                "net_sr": "0.0170234003340345",
                "net_stuff": "-0.0268493175435883",
                "net_pass_sr": "0.0113800442379454",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "0.0125727364051041",
                "net_sr": "0.016506085045084",
                "net_stuff": "-0.0435569466838788",
                "net_pass_sr": "0.0360747848923171",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0671164099461266
    },
    {
        "id": 1230,
        "name": "Kirk Ciarrocca",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000188634369744319
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2010": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2014": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2015": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2016": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2017": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2018": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2019": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2020": {
                "college": "Penn State",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.0591187024637575",
                "net_sr": "0.0230798585932151",
                "net_stuff": "-0.0143859399698282",
                "net_pass_sr": "0.0145164096361316",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "-0.0460667339430821",
                "net_sr": "-0.0172087094384781",
                "net_stuff": "-0.00896234460721931",
                "net_pass_sr": "0.0163852441250026",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "-0.0932286425539683",
                "net_sr": "-0.0840415108522748",
                "net_stuff": "0.0537631212607055",
                "net_pass_sr": "-0.111981506310314",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "0.154861193333045",
                "net_sr": "0.0198127453478779",
                "net_stuff": "-0.0320121981434535",
                "net_pass_sr": "-0.000781070201732681",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.07468451929975209
    },
    {
        "id": 1231,
        "name": "Matt Simon",
        "community": 11,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000175280354209974
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Minnesota",
                "position": "OC"
            },
            "2021": {
                "college": "Minnesota",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Minnesota",
                "role": "OC",
                "net_ppa": "0.0184477232500561",
                "net_sr": "-0.0113304366693777",
                "net_stuff": "-0.0117956245214732",
                "net_pass_sr": "0.0127280370325501",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0184477232500561
    },
    {
        "id": 1234,
        "name": "Luke Getsy",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000100398100592369
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Mississippi State",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Mississippi State",
                "role": "OC",
                "net_ppa": "0.0251210604936522",
                "net_sr": "0.00778877877455192",
                "net_stuff": "-0.0119791092773744",
                "net_pass_sr": "-0.0733427334522625",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0251210604936522
    },
    {
        "id": 1236,
        "name": "David Yost",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000153378739408895
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Missouri",
                "position": "OC"
            },
            "2010": {
                "college": "Missouri",
                "position": "OC"
            },
            "2011": {
                "college": "Missouri",
                "position": "OC"
            },
            "2012": {
                "college": "Missouri",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Utah State",
                "position": "OC"
            },
            "2018": {
                "college": "Utah State",
                "position": "OC"
            },
            "2019": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2020": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Missouri",
                "role": "OC",
                "net_ppa": "-0.0714724843892384",
                "net_sr": "-0.0472796174659734",
                "net_stuff": "-0.000571841393648326",
                "net_pass_sr": "-0.0841355797355605",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "-0.0636863049836816",
                "net_sr": "-0.0289756862868291",
                "net_stuff": "-0.018504150126701",
                "net_pass_sr": "-0.0535028064854344",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.0789235886667213",
                "net_sr": "0.0463876188474266",
                "net_stuff": "-0.0523816511828297",
                "net_pass_sr": "0.0698701631742737",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.05623520070619867
    },
    {
        "id": 1237,
        "name": "Josh Henson",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000285162226107118
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Missouri",
                "position": "OC"
            },
            "2014": {
                "college": "Missouri",
                "position": "OC"
            },
            "2015": {
                "college": "Missouri",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Missouri",
                "role": "OC",
                "net_ppa": "-0.221856964010571",
                "net_sr": "-0.0269968736430523",
                "net_stuff": "0.0830636116913783",
                "net_pass_sr": "-0.0236437818887646",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.221856964010571
    },
    {
        "id": 1240,
        "name": "Ivin Jasper",
        "community": 6,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000072147484826576
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Navy",
                "position": "OC"
            },
            "2009": {
                "college": "Navy",
                "position": "OC"
            },
            "2010": {
                "college": "Navy",
                "position": "OC"
            },
            "2011": {
                "college": "Navy",
                "position": "OC"
            },
            "2012": {
                "college": "Navy",
                "position": "OC"
            },
            "2013": {
                "college": "Navy",
                "position": "OC"
            },
            "2014": {
                "college": "Navy",
                "position": "OC"
            },
            "2015": {
                "college": "Navy",
                "position": "OC"
            },
            "2016": {
                "college": "Navy",
                "position": "OC"
            },
            "2017": {
                "college": "Navy",
                "position": "OC"
            },
            "2018": {
                "college": "Navy",
                "position": "OC"
            },
            "2019": {
                "college": "Navy",
                "position": "OC"
            },
            "2020": {
                "college": "Navy",
                "position": "OC"
            },
            "2021": {
                "college": "Navy",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Navy",
                "role": "OC",
                "net_ppa": "0.0502435664005254",
                "net_sr": "-0.0108926351098507",
                "net_stuff": "0.0108230039572594",
                "net_pass_sr": "0.0153898524460806",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 14,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0502435664005254
    },
    {
        "id": 1242,
        "name": "Des Kitchings",
        "community": 11,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000929031621531185
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "NC State",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "-0.0926958325480924",
                "net_sr": "-0.0320770935797727",
                "net_stuff": "0.0114200950123657",
                "net_pass_sr": "-0.105422879868152",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0926958325480924
    },
    {
        "id": 1243,
        "name": "George McDonald",
        "community": 35,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00015656317254579
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2006": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2014": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "NC State",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "-0.0926958325480924",
                "net_sr": "-0.0320770935797727",
                "net_stuff": "0.0114200950123657",
                "net_pass_sr": "-0.105422879868152",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "-0.0320067484030652",
                "net_sr": "-0.0308890322188992",
                "net_stuff": "0.0325910157219162",
                "net_pass_sr": "-0.0254244412833273",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.1247025809511576
    },
    {
        "id": 1244,
        "name": "Tim Beck",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 4,
            "eigen": 0.0107433088025258
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2012": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2013": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2014": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2015": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2016": {
                "college": "Ohio State",
                "position": "OC"
            },
            "2017": {
                "college": "Texas",
                "position": "OC"
            },
            "2018": {
                "college": "Texas",
                "position": "OC"
            },
            "2019": {
                "college": "Texas",
                "position": "OC"
            },
            "2020": {
                "college": "NC State",
                "position": "OC"
            },
            "2021": {
                "college": "NC State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "NC State",
                "role": "OC",
                "net_ppa": "0.0153587617394392",
                "net_sr": "-0.0348509437022242",
                "net_stuff": "-0.0526946666426123",
                "net_pass_sr": "-0.0254495164278445",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "0.0495171026008904",
                "net_sr": "0.0217863297405256",
                "net_stuff": "-0.0221943957767471",
                "net_pass_sr": "-0.0104005646673535",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ohio State",
                "role": "OC",
                "net_ppa": "0.0315038889158942",
                "net_sr": "-0.0044579174265621",
                "net_stuff": "0.00482887385728725",
                "net_pass_sr": "0.00775334494187185",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.0589874518654123",
                "net_sr": "0.0244651736791163",
                "net_stuff": "0.00650124215344516",
                "net_pass_sr": "0.0575895361810735",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1553672051216361
    },
    {
        "id": 1245,
        "name": "Danny Langsdorf",
        "community": 12,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000182429179740799
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2006": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2007": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2008": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2009": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2010": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2011": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2012": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2013": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2014": false,
            "2015": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2016": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2017": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "-0.00208114286898686",
                "net_sr": "-0.0317276519339874",
                "net_stuff": "0.0219117475258925",
                "net_pass_sr": "-0.00195776063093667",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 12,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.00208114286898686
    },
    {
        "id": 1246,
        "name": "Troy Walters",
        "community": 23,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000560758003262241
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "UCF",
                "position": "OC"
            },
            "2017": {
                "college": "UCF",
                "position": "OC"
            },
            "2018": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2019": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "0.0472940249302117",
                "net_sr": "0.0298793730623165",
                "net_stuff": "-0.00328483024773391",
                "net_pass_sr": "0.0130605630434463",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "0.150469196745125",
                "net_sr": "0.0112469542017325",
                "net_stuff": "-0.00429944455796172",
                "net_pass_sr": "0.0166380317112976",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.1977632216753367
    },
    {
        "id": 1247,
        "name": "Matt Lubick",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00509188820583716
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Oregon",
                "position": "OC"
            },
            "2017": {
                "college": "Washington",
                "position": "OC"
            },
            "2018": {
                "college": "Washington",
                "position": "OC"
            },
            "2019": false,
            "2020": {
                "college": "Nebraska",
                "position": "OC"
            },
            "2021": {
                "college": "Nebraska",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Nebraska",
                "role": "OC",
                "net_ppa": "0.0774042244609711",
                "net_sr": "0.0279632869460876",
                "net_stuff": "-0.0210043506673565",
                "net_pass_sr": "0.0436808172807692",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oregon",
                "role": "OC",
                "net_ppa": "-0.0158917921979679",
                "net_sr": "-0.0477785266910693",
                "net_stuff": "0.0289045731132293",
                "net_pass_sr": "-0.0640506000810369",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "0.00177735329091724",
                "net_sr": "0.0194062479761459",
                "net_stuff": "-0.00238764457082241",
                "net_pass_sr": "0.0420237039960775",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.06328978555392044
    },
    {
        "id": 1249,
        "name": "Matt Mumme",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00146681727856077
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2006": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2007": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2008": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Nevada",
                "position": "OC"
            },
            "2018": {
                "college": "Nevada",
                "position": "OC"
            },
            "2019": {
                "college": "Nevada",
                "position": "OC"
            },
            "2020": {
                "college": "Nevada",
                "position": "OC"
            },
            "2021": {
                "college": "Nevada",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Nevada",
                "role": "OC",
                "net_ppa": "0.024929031692027",
                "net_sr": "-0.019578900645333",
                "net_stuff": "0.02840329372071",
                "net_pass_sr": "0.00219862204194987",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.024929031692027
    },
    {
        "id": 1250,
        "name": "Dan Dodd",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00120760523083884
        },
        "positions": {
            "2000": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2001": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2002": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2003": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2004": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2005": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2006": false,
            "2007": {
                "college": "Tulane",
                "position": "OC"
            },
            "2008": {
                "college": "Tulane",
                "position": "OC"
            },
            "2009": {
                "college": "Tulane",
                "position": "OC"
            },
            "2010": {
                "college": "Tulane",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "-0.0269231484527661",
                "net_sr": "0.028721863243227",
                "net_stuff": "-0.0316729155284417",
                "net_pass_sr": "0.0258174998880689",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 10,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0269231484527661
    },
    {
        "id": 1252,
        "name": "David Reaves",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000840671806294537
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "South Florida",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "0.0114911470534984",
                "net_sr": "0.000955802982836484",
                "net_stuff": "0.00553768009530939",
                "net_pass_sr": "0.0144817630957063",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.201378379712127",
                "net_sr": "0.0813899851069499",
                "net_stuff": "-0.0444677117676695",
                "net_pass_sr": "0.0417107943878762",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2128695267656254
    },
    {
        "id": 1253,
        "name": "Derek Warehime",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000023052371840879
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "New Mexico",
                "position": "OC"
            },
            "2021": {
                "college": "New Mexico",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "New Mexico",
                "role": "OC",
                "net_ppa": "-0.0933002245472443",
                "net_sr": "-0.0458789230695498",
                "net_stuff": "-0.00483237298787989",
                "net_pass_sr": "-0.0436240833189725",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0933002245472443
    },
    {
        "id": 1254,
        "name": "Gerry Gdowski",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.271700639008653
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2004": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Ohio",
                "position": "OC"
            },
            "2009": {
                "college": "Ohio",
                "position": "OC"
            },
            "2010": {
                "college": "Ohio",
                "position": "OC"
            },
            "2011": {
                "college": "Ohio",
                "position": "OC"
            },
            "2012": {
                "college": "Ohio",
                "position": "OC"
            },
            "2013": {
                "college": "Ohio",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ohio",
                "role": "OC",
                "net_ppa": "0.0532576151312828",
                "net_sr": "0.0253542808669094",
                "net_stuff": "-0.0403403601288663",
                "net_pass_sr": "0.0621928165414159",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.134418381872756",
                "net_sr": "-0.0719512840148667",
                "net_stuff": "0.0291093336117676",
                "net_pass_sr": "-0.134664179376948",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.08116076674147321
    },
    {
        "id": 1256,
        "name": "Timm Rosenbach",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00000537915656741865
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "UNLV",
                "position": "OC"
            },
            "2014": {
                "college": "UNLV",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "-0.119076573297992",
                "net_sr": "-0.0690249465757541",
                "net_stuff": "-0.0282563558917349",
                "net_pass_sr": "-0.117721679423807",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "0.0766597729187415",
                "net_sr": "0.0413819873929949",
                "net_stuff": "-0.0115373365342952",
                "net_pass_sr": "0.052392843983314",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0424168003792505
    },
    {
        "id": 1257,
        "name": "Jerry McManus",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000525901562015389
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "New Mexico State",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "New Mexico State",
                "role": "OC",
                "net_ppa": "-0.141627296120352",
                "net_sr": "-0.0774360928768523",
                "net_stuff": "0.0295541772032271",
                "net_pass_sr": "-0.0703027669978921",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.141627296120352
    },
    {
        "id": 1258,
        "name": "John Shoop",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000827797380803847
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2008": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2009": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2010": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2011": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2012": false,
            "2013": {
                "college": "Purdue",
                "position": "OC"
            },
            "2014": {
                "college": "Purdue",
                "position": "OC"
            },
            "2015": {
                "college": "Purdue",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "-0.0459865359654705",
                "net_sr": "-0.0174431945467376",
                "net_stuff": "0.0670682363082484",
                "net_pass_sr": "-0.0113259892171748",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "-0.118317920947969",
                "net_sr": "0.0182887797077478",
                "net_stuff": "-0.00102923253096029",
                "net_pass_sr": "0.0225435984181996",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1643044569134395
    },
    {
        "id": 1259,
        "name": "Chris Kapilovic",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000430147818128307
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2015": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2016": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2017": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2018": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "0.109220100352296",
                "net_sr": "0.00272010170372722",
                "net_stuff": "0.0127308400304758",
                "net_pass_sr": "-0.0204816657195039",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.109220100352296
    },
    {
        "id": 1260,
        "name": "Gunter Brewer",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000719225784572698
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2009": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2015": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2016": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2017": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "0.128193763321035",
                "net_sr": "0.0081721323197631",
                "net_stuff": "0.00880836604105029",
                "net_pass_sr": "-0.0116810928765311",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "-0.0134412350153312",
                "net_sr": "0.0125664733794105",
                "net_stuff": "0.0261083172438536",
                "net_pass_sr": "0.0716692502769977",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.11475252830570379
    },
    {
        "id": 1261,
        "name": "Phil Longo",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000542127558717881
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2018": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2019": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2020": {
                "college": "North Carolina",
                "position": "OC"
            },
            "2021": {
                "college": "North Carolina",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "North Carolina",
                "role": "OC",
                "net_ppa": "0.137907989501091",
                "net_sr": "0.0486869318218052",
                "net_stuff": "-0.0278606002221097",
                "net_pass_sr": "0.0496246988836593",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "0.0600183189696121",
                "net_sr": "0.0187931608001907",
                "net_stuff": "-0.019569611260688",
                "net_pass_sr": "0.00298661455722149",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1979263084707031
    },
    {
        "id": 1263,
        "name": "Todd Ford",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000243776718877157
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "North Texas",
                "position": "OC"
            },
            "2008": {
                "college": "North Texas",
                "position": "OC"
            },
            "2009": {
                "college": "North Texas",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "0.129590222406961",
                "net_sr": "0.158460200830195",
                "net_stuff": "-0.0646397230937795",
                "net_pass_sr": "0.139892192747736",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.129590222406961
    },
    {
        "id": 1264,
        "name": "Graham Harrell",
        "community": 9,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000531204606569983
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "North Texas",
                "position": "OC"
            },
            "2017": {
                "college": "North Texas",
                "position": "OC"
            },
            "2018": {
                "college": "North Texas",
                "position": "OC"
            },
            "2019": {
                "college": "USC",
                "position": "OC"
            },
            "2020": {
                "college": "USC",
                "position": "OC"
            },
            "2021": {
                "college": "USC",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "0.140908349745411",
                "net_sr": "0.0372723752740447",
                "net_stuff": "0.020066423452779",
                "net_pass_sr": "0.0537412558623039",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "-0.00811539068396497",
                "net_sr": "0.0124778368137305",
                "net_stuff": "0.00284655591200997",
                "net_pass_sr": "0.0202840522813171",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.13279295906144603
    },
    {
        "id": 1265,
        "name": "Bodie Reeder",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000204648583462696
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "North Texas",
                "position": "OC"
            },
            "2020": {
                "college": "Utah State",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "-0.0157020246146678",
                "net_sr": "-0.0129428747392178",
                "net_stuff": "-0.00728767894307014",
                "net_pass_sr": "0.00910001718021775",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "-0.16278566451616",
                "net_sr": "-0.107105817984845",
                "net_stuff": "0.0474021197487358",
                "net_pass_sr": "-0.139100528065502",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1784876891308278
    },
    {
        "id": 1266,
        "name": "Tommy Mainord",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000284011897387601
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "North Texas",
                "position": "OC"
            },
            "2020": {
                "college": "North Texas",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "0.0488125020960046",
                "net_sr": "0.00788821354220987",
                "net_stuff": "-0.0531497168845642",
                "net_pass_sr": "0.006389861227384",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0488125020960046
    },
    {
        "id": 1267,
        "name": "Mike Bloesch",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000284011897387609
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "North Texas",
                "position": "OC"
            },
            "2021": {
                "college": "North Texas",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "North Texas",
                "role": "OC",
                "net_ppa": "0.026404206808589",
                "net_sr": "-0.0114341656866511",
                "net_stuff": "-0.0922019929578418",
                "net_pass_sr": "-0.0544170856903586",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.026404206808589
    },
    {
        "id": 1268,
        "name": "Bob Cole",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.0000436941434560432
        },
        "positions": {
            "2000": {
                "college": "Utah State",
                "position": "OC"
            },
            "2001": {
                "college": "Utah State",
                "position": "OC"
            },
            "2002": {
                "college": "Utah State",
                "position": "OC"
            },
            "2003": {
                "college": "Utah State",
                "position": "OC"
            },
            "2004": {
                "college": "Utah State",
                "position": "OC"
            },
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2014": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2015": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "0.0785935904495382",
                "net_sr": "0.0130678460163553",
                "net_stuff": "-0.0194983084597452",
                "net_pass_sr": "-0.0065363763368701",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "-0.0247032913448584",
                "net_sr": "0.00916721837741996",
                "net_stuff": "-0.118606758163327",
                "net_pass_sr": "-0.019651206288762",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0538902991046798
    },
    {
        "id": 1269,
        "name": "Mike Uremovich",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000540613265540636
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2017": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2018": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2019": {
                "college": "Temple",
                "position": "OC"
            },
            "2020": {
                "college": "Temple",
                "position": "OC"
            },
            "2021": {
                "college": "Temple",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "-0.0578573563792252",
                "net_sr": "-0.0390435747264496",
                "net_stuff": "0.00529353342002856",
                "net_pass_sr": "-0.0398204439273951",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "-0.0741368356919341",
                "net_sr": "-0.0426467187940561",
                "net_stuff": "-0.0327870552298653",
                "net_pass_sr": "-0.041737988009709",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1319941920711593
    },
    {
        "id": 1270,
        "name": "Eric Eidsness",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000127656210504225
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2020": {
                "college": "Northern Illinois",
                "position": "OC"
            },
            "2021": {
                "college": "Northern Illinois",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Northern Illinois",
                "role": "OC",
                "net_ppa": "0.0581982211696672",
                "net_sr": "0.00506079625266936",
                "net_stuff": "0.00691684513616486",
                "net_pass_sr": "0.0242135272767056",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0581982211696672
    },
    {
        "id": 1272,
        "name": "Tommy Rees",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000328103078612339
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Notre Dame",
                "position": "OC"
            },
            "2021": {
                "college": "Notre Dame",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Notre Dame",
                "role": "OC",
                "net_ppa": "0.015843600133465",
                "net_sr": "0.000606748786452838",
                "net_stuff": "-0.0238566650661044",
                "net_pass_sr": "0.0344973317537627",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.015843600133465
    },
    {
        "id": 1273,
        "name": "Greg Gregory",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000339289872777274
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Ohio",
                "position": "OC"
            },
            "2002": {
                "college": "Ohio",
                "position": "OC"
            },
            "2003": {
                "college": "Ohio",
                "position": "OC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "South Florida",
                "position": "OC"
            },
            "2008": {
                "college": "South Florida",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.0203637828475534",
                "net_sr": "0.0435051395738931",
                "net_stuff": "-0.00896237585983728",
                "net_pass_sr": "0.060424859008372",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0203637828475534
    },
    {
        "id": 1274,
        "name": "Allen Rudolph",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0312989820353944
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Ohio",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Ohio",
                "role": "OC",
                "net_ppa": "-0.0781850916345002",
                "net_sr": "-0.0350457556487493",
                "net_stuff": "-0.00116243245928785",
                "net_pass_sr": "-0.0226616740996814",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0781850916345002
    },
    {
        "id": 1276,
        "name": "Cale Gundy",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00121775121231377
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2018": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2019": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2020": {
                "college": "Oklahoma",
                "position": "OC"
            },
            "2021": {
                "college": "Oklahoma",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Oklahoma",
                "role": "OC",
                "net_ppa": "0.100640516331357",
                "net_sr": "0.0354630330162495",
                "net_stuff": "0.0129037596324449",
                "net_pass_sr": "0.0412309509481371",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.100640516331357
    },
    {
        "id": 1277,
        "name": "Trooper Taylor",
        "community": 5,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000187553765035027
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "0.0501458700105544",
                "net_sr": "0.0469890876536688",
                "net_stuff": "0.0261119319063707",
                "net_pass_sr": "0.108989445089547",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0501458700105544
    },
    {
        "id": 1278,
        "name": "Joe Wickline",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000508108604986676
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Texas",
                "position": "OC"
            },
            "2015": {
                "college": "Texas",
                "position": "OC"
            },
            "2016": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "0.00196579023116319",
                "net_sr": "0.0652472048494976",
                "net_stuff": "-0.00496477682678872",
                "net_pass_sr": "0.0676754904689966",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "-0.180688801642588",
                "net_sr": "-0.0360404156546864",
                "net_stuff": "-0.00682188772815986",
                "net_pass_sr": "-0.0505754259609305",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "-0.00998352840470554",
                "net_sr": "-0.0122140791552837",
                "net_stuff": "0.01345004200036",
                "net_pass_sr": "0.00397424458585471",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.18870653981613034
    },
    {
        "id": 1279,
        "name": "Mike Yurcich",
        "community": 5,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00129967469973762
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2014": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2015": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2016": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2017": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2018": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2019": false,
            "2020": {
                "college": "Texas",
                "position": "OC"
            },
            "2021": {
                "college": "Penn State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "0.0737386020008613",
                "net_sr": "-0.039806058147725",
                "net_stuff": "0.0149345249812779",
                "net_pass_sr": "-0.0356371168968347",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "-0.0771509077352812",
                "net_sr": "-0.0492893094152954",
                "net_stuff": "0.0582416580162384",
                "net_pass_sr": "-0.0129671621735114",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.0488390498272991",
                "net_sr": "-0.0178017415486364",
                "net_stuff": "0.0128468790029708",
                "net_pass_sr": "-0.0278559505799708",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.045426744092879195
    },
    {
        "id": 1280,
        "name": "Sean Gleeson",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000283233512870299
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Oklahoma State",
                "position": "OC"
            },
            "2020": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2021": {
                "college": "Rutgers",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Oklahoma State",
                "role": "OC",
                "net_ppa": "-0.0635053800400274",
                "net_sr": "-0.0219880268232585",
                "net_stuff": "-0.0139756803307948",
                "net_pass_sr": "-0.0396697936623385",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.0643438377580295",
                "net_sr": "0.0323963127762939",
                "net_stuff": "-0.0379847011855049",
                "net_pass_sr": "0.0636240162956468",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.000838457718002103
    },
    {
        "id": 1281,
        "name": "Brian Scott",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000211275337027688
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Old Dominion",
                "position": "OC"
            },
            "2015": {
                "college": "Old Dominion",
                "position": "OC"
            },
            "2016": {
                "college": "Old Dominion",
                "position": "OC"
            },
            "2017": {
                "college": "Old Dominion",
                "position": "OC"
            },
            "2018": {
                "college": "Old Dominion",
                "position": "OC"
            },
            "2019": {
                "college": "Old Dominion",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Old Dominion",
                "role": "OC",
                "net_ppa": "-0.0355944668083694",
                "net_sr": "-0.096004006697492",
                "net_stuff": "0.0345584693393927",
                "net_pass_sr": "-0.106152550732879",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0355944668083694
    },
    {
        "id": 1282,
        "name": "Ron Whitcomb",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 3.521255617004e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Old Dominion",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Old Dominion",
                "role": "OC",
                "net_ppa": "0.0964214119266909",
                "net_sr": "-0.0406491425670639",
                "net_stuff": "-0.00690326912347394",
                "net_pass_sr": "-0.0471227204401713",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0964214119266909
    },
    {
        "id": 1283,
        "name": "Kirk Campbell",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000677654078858071
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Old Dominion",
                "position": "OC"
            },
            "2021": {
                "college": "Old Dominion",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Old Dominion",
                "role": "OC",
                "net_ppa": "0.0948002673839766",
                "net_sr": "0.0287191322576639",
                "net_stuff": "-0.0489849513502239",
                "net_pass_sr": "0.0130164422283055",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0948002673839766
    },
    {
        "id": 1284,
        "name": "Mike Markuson",
        "community": 6,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000764002701011933
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "-0.0179898842605212",
                "net_sr": "-0.0322612616167856",
                "net_stuff": "0.0598517860791514",
                "net_pass_sr": "-0.0213652558871668",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0179898842605212
    },
    {
        "id": 1285,
        "name": "Jeff Lebby",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0005827935823021
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "UCF",
                "position": "OC"
            },
            "2019": {
                "college": "UCF",
                "position": "OC"
            },
            "2020": {
                "college": "Ole Miss",
                "position": "OC"
            },
            "2021": {
                "college": "Ole Miss",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Ole Miss",
                "role": "OC",
                "net_ppa": "0.0410789541393419",
                "net_sr": "0.0041357269685573",
                "net_stuff": "-0.0255566761055654",
                "net_pass_sr": "0.0293756386491004",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "0.188114696897513",
                "net_sr": "0.060620842297236",
                "net_stuff": "-0.0624733070880119",
                "net_pass_sr": "0.0416912359696402",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.2291936510368549
    },
    {
        "id": 1287,
        "name": "John Garrett",
        "community": 12,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000152024316450554
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Oregon State",
                "role": "OC",
                "net_ppa": "-0.180008507402989",
                "net_sr": "-0.0348694365429916",
                "net_stuff": "0.0380173534208451",
                "net_pass_sr": "-0.0415672174588465",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.180008507402989
    },
    {
        "id": 1288,
        "name": "Kevin McGiven",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000189136431546914
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Utah State",
                "position": "OC"
            },
            "2014": {
                "college": "Utah State",
                "position": "OC"
            },
            "2015": false,
            "2016": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2017": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2018": {
                "college": "San José State",
                "position": "OC"
            },
            "2019": {
                "college": "San José State",
                "position": "OC"
            },
            "2020": {
                "college": "San José State",
                "position": "OC"
            },
            "2021": {
                "college": "San José State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Oregon State",
                "role": "OC",
                "net_ppa": "0.0269370668970166",
                "net_sr": "-0.000796194320166088",
                "net_stuff": "-0.0400763989913644",
                "net_pass_sr": "-0.0305406055229931",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "0.0223708540589322",
                "net_sr": "-0.00296718216453346",
                "net_stuff": "0.0527087926173655",
                "net_pass_sr": "0.0287195005195781",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.026972177165528",
                "net_sr": "-0.022524122646617",
                "net_stuff": "0.0587686143138695",
                "net_pass_sr": "-0.0261053563913582",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.07628009812147679
    },
    {
        "id": 1289,
        "name": "T.J. Woods",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000062642634768899
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Oregon State",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Oregon State",
                "role": "OC",
                "net_ppa": "0.0130504663908427",
                "net_sr": "-0.0144005484773836",
                "net_stuff": "-0.0476145821969075",
                "net_pass_sr": "-0.0764295319430798",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0130504663908427
    },
    {
        "id": 1292,
        "name": "John Donovan",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.00100499401105749
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2012": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2013": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2014": {
                "college": "Penn State",
                "position": "OC"
            },
            "2015": {
                "college": "Penn State",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Washington",
                "position": "OC"
            },
            "2021": {
                "college": "Washington",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "0.0253026585659467",
                "net_sr": "0.0157968336262729",
                "net_stuff": "-0.0581226776743535",
                "net_pass_sr": "0.00148661180107512",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.264439316611099",
                "net_sr": "-0.0938959084592326",
                "net_stuff": "0.0887441422026561",
                "net_pass_sr": "-0.126635357902597",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Washington",
                "role": "OC",
                "net_ppa": "0.115939745208257",
                "net_sr": "0.0559870090459857",
                "net_stuff": "-0.0138336729566867",
                "net_pass_sr": "0.0642911819768388",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.12319691283689527
    },
    {
        "id": 1293,
        "name": "Tyler Bowen",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000632129958710688
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Penn State",
                "position": "OC"
            },
            "2020": {
                "college": "Penn State",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Penn State",
                "role": "OC",
                "net_ppa": "-0.0195627186733018",
                "net_sr": "-0.00596187542118876",
                "net_stuff": "-0.0496801442797158",
                "net_pass_sr": "0.00627747933623934",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0195627186733018
    },
    {
        "id": 1295,
        "name": "Brian Brohm",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000818173648252011
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2017": {
                "college": "Purdue",
                "position": "OC"
            },
            "2018": {
                "college": "Purdue",
                "position": "OC"
            },
            "2019": {
                "college": "Purdue",
                "position": "OC"
            },
            "2020": {
                "college": "Purdue",
                "position": "OC"
            },
            "2021": {
                "college": "Purdue",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "0.0875648960257925",
                "net_sr": "0.0378239210637506",
                "net_stuff": "0.0255631162225245",
                "net_pass_sr": "0.0663439498080647",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "0.0888155647021989",
                "net_sr": "0.00876685408498057",
                "net_stuff": "0.00996304584326685",
                "net_pass_sr": "-0.0195361535207574",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1763804607279914
    },
    {
        "id": 1296,
        "name": "JaMarcus Shephard",
        "community": 3,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000545449098834685
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Purdue",
                "position": "OC"
            },
            "2019": {
                "college": "Purdue",
                "position": "OC"
            },
            "2020": {
                "college": "Purdue",
                "position": "OC"
            },
            "2021": {
                "college": "Purdue",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Purdue",
                "role": "OC",
                "net_ppa": "0.0960310911787368",
                "net_sr": "0.0332727655445109",
                "net_stuff": "0.0462807808667957",
                "net_pass_sr": "0.0658198767832551",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0960310911787368
    },
    {
        "id": 1298,
        "name": "Billy Lynch",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000120091269922355
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Rice",
                "position": "OC"
            },
            "2015": {
                "college": "Rice",
                "position": "OC"
            },
            "2016": {
                "college": "Rice",
                "position": "OC"
            },
            "2017": {
                "college": "Rice",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.0818393472975821",
                "net_sr": "0.00356234679743189",
                "net_stuff": "-0.00888451758057274",
                "net_pass_sr": "0.00535123092821077",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0818393472975821
    },
    {
        "id": 1299,
        "name": "Jerry Mack",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000897206853238842
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Rice",
                "position": "OC"
            },
            "2019": {
                "college": "Rice",
                "position": "OC"
            },
            "2020": {
                "college": "Rice",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.128163622585501",
                "net_sr": "0.0287819994379078",
                "net_stuff": "-0.0349958583393503",
                "net_pass_sr": "-0.00439437600201886",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.128163622585501
    },
    {
        "id": 1300,
        "name": "Marques Tuiasosopo",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000299068951079534
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Rice",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Rice",
                "role": "OC",
                "net_ppa": "0.0693295343367265",
                "net_sr": "0.0217674100505081",
                "net_stuff": "-0.0607596367603659",
                "net_pass_sr": "0.040710080325769",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Other",
        "aggregate_xpa": 0.0693295343367265
    },
    {
        "id": 1301,
        "name": "Craig Ver Steeg",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000216801546171852
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "Utah",
                "position": "OC"
            },
            "2002": {
                "college": "Utah",
                "position": "OC"
            },
            "2003": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2004": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2005": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2006": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "-0.0690102770237015",
                "net_sr": "0.00251797610235799",
                "net_stuff": "-0.0383214391911983",
                "net_pass_sr": "-0.0179080179080179",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 6,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0690102770237015
    },
    {
        "id": 1302,
        "name": "John McNulty",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000183644146726879
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2007": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2019": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.0130679703937055",
                "net_sr": "-0.00245887328315353",
                "net_stuff": "0.0502882216295276",
                "net_pass_sr": "0.000954639698095228",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0130679703937055
    },
    {
        "id": 1303,
        "name": "Ben McDaniels",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000110217109656285
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Rutgers",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Rutgers",
                "role": "OC",
                "net_ppa": "0.119837386420855",
                "net_sr": "0.0357370191170909",
                "net_stuff": "-0.0470588006287549",
                "net_pass_sr": "-0.00373886895121373",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.119837386420855
    },
    {
        "id": 1304,
        "name": "Jeff Hecklinski",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000151242190781233
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "San Diego State",
                "position": "OC"
            },
            "2021": {
                "college": "San Diego State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "San Diego State",
                "role": "OC",
                "net_ppa": "-0.0363037193757891",
                "net_sr": "-0.0209919076980048",
                "net_stuff": "-0.00697077127952239",
                "net_pass_sr": "-0.0107021306400319",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0363037193757891
    },
    {
        "id": 1309,
        "name": "Ken Margerum",
        "community": 12,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000529889835188547
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": {
                "college": "San José State",
                "position": "OC"
            },
            "2006": {
                "college": "San José State",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "-0.32020406744231",
                "net_sr": "-0.166437468858993",
                "net_stuff": "0.105714263706016",
                "net_pass_sr": "-0.215726713392248",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.32020406744231
    },
    {
        "id": 1310,
        "name": "Steve Morton",
        "community": 12,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000794834752782847
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "San José State",
                "position": "OC"
            },
            "2008": {
                "college": "San José State",
                "position": "OC"
            },
            "2009": {
                "college": "San José State",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "-0.0328638927326539",
                "net_sr": "0.000936085465418901",
                "net_stuff": "0.0755477352454382",
                "net_pass_sr": "0.015782073948485",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0328638927326539
    },
    {
        "id": 1311,
        "name": "Tim Landis",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000067547266110332
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "San José State",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "0.0544891616917904",
                "net_sr": "0.0306807448819189",
                "net_stuff": "-0.0794699850075933",
                "net_pass_sr": "0.0517903820794293",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0544891616917904
    },
    {
        "id": 1312,
        "name": "John DeFilippo",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000067547266110333
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "San José State",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "-0.0784842650311231",
                "net_sr": "-0.025085515876897",
                "net_stuff": "0.0331390646720418",
                "net_pass_sr": "-0.00921851980012894",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0784842650311231
    },
    {
        "id": 1313,
        "name": "Jimmie Dougherty",
        "community": 20,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000107364242839854
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "San José State",
                "position": "OC"
            },
            "2014": {
                "college": "San José State",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "San José State",
                "role": "OC",
                "net_ppa": "0.13649056540676",
                "net_sr": "0.0735406685809191",
                "net_stuff": "-0.0189298932874093",
                "net_pass_sr": "0.142018273535745",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.13649056540676
    },
    {
        "id": 1314,
        "name": "Dan Morrison",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000197149661978336
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "SMU",
                "position": "OC"
            },
            "2009": {
                "college": "SMU",
                "position": "OC"
            },
            "2010": {
                "college": "SMU",
                "position": "OC"
            },
            "2011": {
                "college": "SMU",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": {
                "college": "SMU",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "0.0216327529974735",
                "net_sr": "-0.000341296348774889",
                "net_stuff": "-0.0206629841736418",
                "net_pass_sr": "0.00306612468163109",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0216327529974735
    },
    {
        "id": 1315,
        "name": "A.J. Ricker",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000250548199439719
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "SMU",
                "position": "OC"
            },
            "2021": {
                "college": "SMU",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "0.109110504857284",
                "net_sr": "0.0383666393333357",
                "net_stuff": "-0.0201028093835616",
                "net_pass_sr": "0.0393128050724057",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.109110504857284
    },
    {
        "id": 1316,
        "name": "Garrett Riley",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000250548199439717
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "SMU",
                "position": "OC"
            },
            "2021": {
                "college": "SMU",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "SMU",
                "role": "OC",
                "net_ppa": "0.109110504857284",
                "net_sr": "0.0383666393333357",
                "net_stuff": "-0.0201028093835616",
                "net_pass_sr": "0.0393128050724057",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.109110504857284
    },
    {
        "id": 1317,
        "name": "Robert Matthews",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000161893601582794
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2013": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2014": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "0.0589823937147865",
                "net_sr": "-0.0173343854047336",
                "net_stuff": "-0.0296665349003879",
                "net_pass_sr": "0.0145765724477833",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0589823937147865
    },
    {
        "id": 1318,
        "name": "Bryant Vincent",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000127815552965376
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "UAB",
                "position": "OC"
            },
            "2015": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2016": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2017": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2018": {
                "college": "UAB",
                "position": "OC"
            },
            "2019": {
                "college": "UAB",
                "position": "OC"
            },
            "2020": {
                "college": "UAB",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "0.0426861107411403",
                "net_sr": "0.00342031717429075",
                "net_stuff": "0.011239533201075",
                "net_pass_sr": "-0.00845326446870015",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "0.0992369757697066",
                "net_sr": "0.0171066973170024",
                "net_stuff": "0.00775988759321863",
                "net_pass_sr": "0.0148799307252152",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1419230865108469
    },
    {
        "id": 1319,
        "name": "Richard Owens",
        "community": 40,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000077087701184722
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "UAB",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "-0.048873833910374",
                "net_sr": "-0.0137273241554563",
                "net_stuff": "0.0918613451336564",
                "net_pass_sr": "0.022052344615366",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "0.0810741229642009",
                "net_sr": "-0.0158055332368141",
                "net_stuff": "-0.0194463372908938",
                "net_pass_sr": "-0.0469635434835566",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0322002890538269
    },
    {
        "id": 1320,
        "name": "Kenny Edenfield",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000212879795222639
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Troy",
                "position": "OC"
            },
            "2011": {
                "college": "Troy",
                "position": "OC"
            },
            "2012": {
                "college": "Troy",
                "position": "OC"
            },
            "2013": {
                "college": "Troy",
                "position": "OC"
            },
            "2014": {
                "college": "Troy",
                "position": "OC"
            },
            "2015": {
                "college": "Troy",
                "position": "OC"
            },
            "2016": {
                "college": "Troy",
                "position": "OC"
            },
            "2017": {
                "college": "Troy",
                "position": "OC"
            },
            "2018": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2019": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2020": {
                "college": "South Alabama",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "South Alabama",
                "role": "OC",
                "net_ppa": "-0.0301935444406269",
                "net_sr": "-0.0251756853642672",
                "net_stuff": "0.0319636465792205",
                "net_pass_sr": "-0.0445380493858255",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "-0.225553278433628",
                "net_sr": "-0.0295087360462403",
                "net_stuff": "0.0459080886035954",
                "net_pass_sr": "-0.0103819946017821",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.2557468228742549
    },
    {
        "id": 1321,
        "name": "Steve Spurrier Jr",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000137336749517651
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2013": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2014": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2015": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "0.0754518733771863",
                "net_sr": "0.00619851586576098",
                "net_stuff": "0.00479909490573943",
                "net_pass_sr": "0.0156298198881714",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0754518733771863
    },
    {
        "id": 1322,
        "name": "Bryan McClendon",
        "community": 13,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000262080694630971
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2017": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2018": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2019": {
                "college": "South Carolina",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "-0.0410189537473521",
                "net_sr": "-0.017251538052422",
                "net_stuff": "-0.00614945139924514",
                "net_pass_sr": "-0.0204278411552603",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0410189537473521
    },
    {
        "id": 1323,
        "name": "Marcus Satterfield",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000255833654074684
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Temple",
                "position": "OC"
            },
            "2014": {
                "college": "Temple",
                "position": "OC"
            },
            "2015": {
                "college": "Temple",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "South Carolina",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "South Carolina",
                "role": "OC",
                "net_ppa": "-0.000484105848042871",
                "net_sr": "-0.0238525427697932",
                "net_stuff": "-0.00741167732581077",
                "net_pass_sr": "-0.0118847913567017",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "0.0551459928031501",
                "net_sr": "0.0119632942716544",
                "net_stuff": "0.0196374479563471",
                "net_pass_sr": "0.0153036115325148",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.05466188695510723
    },
    {
        "id": 1324,
        "name": "Steve Buckley",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000730721423215187
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "-0.0543558933649205",
                "net_sr": "-0.0576891950311897",
                "net_stuff": "0.0219822504798241",
                "net_pass_sr": "-0.0878292269156983",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0543558933649205
    },
    {
        "id": 1325,
        "name": "Scotty Walden",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000843418173257856
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2020": {
                "college": "Southern Mississippi",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Southern Mississippi",
                "role": "OC",
                "net_ppa": "0.00341758351889127",
                "net_sr": "-0.0151766831386964",
                "net_stuff": "0.0103640133648603",
                "net_pass_sr": "0.0146473725341211",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00341758351889127
    },
    {
        "id": 1327,
        "name": "Pep Hamilton",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00024045828833168
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Stanford",
                "position": "OC"
            },
            "2012": {
                "college": "Stanford",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "0.0050936642391205",
                "net_sr": "-0.00884048667935833",
                "net_stuff": "-0.0367292449819324",
                "net_pass_sr": "0.00504919679379268",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0050936642391205
    },
    {
        "id": 1328,
        "name": "Tavita Pritchard",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000480916576663366
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Stanford",
                "position": "OC"
            },
            "2019": {
                "college": "Stanford",
                "position": "OC"
            },
            "2020": {
                "college": "Stanford",
                "position": "OC"
            },
            "2021": {
                "college": "Stanford",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Stanford",
                "role": "OC",
                "net_ppa": "-0.0474411760765816",
                "net_sr": "-0.0152632692091561",
                "net_stuff": "0.0108519629152753",
                "net_pass_sr": "0.0161086177791106",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0474411760765816
    },
    {
        "id": 1329,
        "name": "George DeLeone",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000450055014195131
        },
        "positions": {
            "2000": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2001": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2002": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2003": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2004": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2005": false,
            "2006": {
                "college": "Temple",
                "position": "OC"
            },
            "2007": {
                "college": "Temple",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2012": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "-0.0431528995157493",
                "net_sr": "-0.0400042886423614",
                "net_stuff": "0.030676814758762",
                "net_pass_sr": "0.0171712221121409",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Temple",
                "role": "OC",
                "net_ppa": "0.0138431072741348",
                "net_sr": "0.0425932876969016",
                "net_stuff": "0.0279467562356161",
                "net_pass_sr": "0.0419086646844654",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0293097922416145
    },
    {
        "id": 1331,
        "name": "Brian White",
        "community": 38,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000112500495883613
        },
        "positions": {
            "2000": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2001": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2002": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2003": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2004": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2005": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2006": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2007": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "0.043994366603743",
                "net_sr": "0.0777191289282577",
                "net_stuff": "-0.0770108676381945",
                "net_pass_sr": "0.0549294731566592",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 8,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.043994366603743
    },
    {
        "id": 1332,
        "name": "Nathaniel Hackett",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000067864012658481
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2012": {
                "college": "Syracuse",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Syracuse",
                "role": "OC",
                "net_ppa": "0.0703271087163754",
                "net_sr": "0.0461085546008237",
                "net_stuff": "-0.00428453074001162",
                "net_pass_sr": "0.0701254786341794",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0703271087163754
    },
    {
        "id": 1333,
        "name": "Jarrett Anderson",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000293441015389645
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "TCU",
                "position": "OC"
            },
            "2010": {
                "college": "TCU",
                "position": "OC"
            },
            "2011": {
                "college": "TCU",
                "position": "OC"
            },
            "2012": {
                "college": "TCU",
                "position": "OC"
            },
            "2013": {
                "college": "TCU",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "TCU",
                "role": "OC",
                "net_ppa": "0.0108950296279099",
                "net_sr": "0.00502126254851565",
                "net_stuff": "0.00699334559840462",
                "net_pass_sr": "-0.000580048309754722",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0108950296279099
    },
    {
        "id": 1334,
        "name": "Sonny Cumbie",
        "community": 19,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.000463131205430639
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2014": {
                "college": "TCU",
                "position": "OC"
            },
            "2015": {
                "college": "TCU",
                "position": "OC"
            },
            "2016": {
                "college": "TCU",
                "position": "OC"
            },
            "2017": {
                "college": "TCU",
                "position": "OC"
            },
            "2018": {
                "college": "TCU",
                "position": "OC"
            },
            "2019": {
                "college": "TCU",
                "position": "OC"
            },
            "2020": {
                "college": "TCU",
                "position": "OC"
            },
            "2021": {
                "college": "Texas Tech",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "TCU",
                "role": "OC",
                "net_ppa": "0.135814045353274",
                "net_sr": "0.0315846804581186",
                "net_stuff": "-0.00760789165185555",
                "net_pass_sr": "-0.00969059739979661",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "0.109279328456464",
                "net_sr": "-0.0108842373498539",
                "net_stuff": "-0.0113480700936932",
                "net_pass_sr": "-0.025804616080188",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 9,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.245093373809738
    },
    {
        "id": 1335,
        "name": "Curtis Luper",
        "community": 19,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000176064609233793
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "TCU",
                "position": "OC"
            },
            "2018": {
                "college": "TCU",
                "position": "OC"
            },
            "2019": {
                "college": "TCU",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "TCU",
                "role": "OC",
                "net_ppa": "-0.101625944066838",
                "net_sr": "-0.0431982089325113",
                "net_stuff": "0.0208245707110973",
                "net_pass_sr": "-0.058020880560162",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.101625944066838
    },
    {
        "id": 1338,
        "name": "Larry Scott",
        "community": 24,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000151119141500149
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Tennessee",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "-0.111864618164867",
                "net_sr": "-0.0562463357307287",
                "net_stuff": "0.0621672729576851",
                "net_pass_sr": "-0.0560405919486244",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.111864618164867
    },
    {
        "id": 1339,
        "name": "Alex Golesh",
        "community": 14,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000502990741482418
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "UCF",
                "position": "OC"
            },
            "2021": {
                "college": "Tennessee",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Tennessee",
                "role": "OC",
                "net_ppa": "0.168157642137173",
                "net_sr": "0.0779817352400377",
                "net_stuff": "-0.117033606438161",
                "net_pass_sr": "0.040451539816192",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.016468079006486",
                "net_sr": "0.0023737150601596",
                "net_stuff": "-0.0262744849765153",
                "net_pass_sr": "-0.0236817090685666",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.151689563130687
    },
    {
        "id": 1340,
        "name": "Darrell Wyatt",
        "community": 13,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000152019162253855
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Texas",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "-0.0654554422803404",
                "net_sr": "-0.0333353983371721",
                "net_stuff": "0.00597050802933463",
                "net_pass_sr": "-0.0130800855749393",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0654554422803404
    },
    {
        "id": 1341,
        "name": "Herb Hand",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 3,
            "eigen": 0.00152951021744683
        },
        "positions": {
            "2000": false,
            "2001": {
                "college": "West Virginia",
                "position": "Positional Coach"
            },
            "2002": {
                "college": "West Virginia",
                "position": "Positional Coach"
            },
            "2003": {
                "college": "West Virginia",
                "position": "Positional Coach"
            },
            "2004": {
                "college": "West Virginia",
                "position": "Positional Coach"
            },
            "2005": {
                "college": "West Virginia",
                "position": "Positional Coach"
            },
            "2006": {
                "college": "West Virginia",
                "position": "Positional Coach"
            },
            "2007": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2008": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2009": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2010": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Texas",
                "position": "OC"
            },
            "2019": {
                "college": "Texas",
                "position": "OC"
            },
            "2020": {
                "college": "Texas",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Texas",
                "role": "OC",
                "net_ppa": "0.113550877185228",
                "net_sr": "0.0307363282486987",
                "net_stuff": "0.00628434893452354",
                "net_pass_sr": "0.0622627124943352",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "0.0192742257491586",
                "net_sr": "0.0418072045837082",
                "net_stuff": "-0.02923674073307",
                "net_pass_sr": "0.0213482647377869",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 13,
        "positionsCoached": [
            "Positional Coach",
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.1328251029343866
    },
    {
        "id": 1342,
        "name": "Nolan Cromwell",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000199648533653444
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2009": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "-0.0361506165389682",
                "net_sr": "-0.0304063684205049",
                "net_stuff": "0.0259385605112868",
                "net_pass_sr": "0.0283781392834315",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0361506165389682
    },
    {
        "id": 1343,
        "name": "Clarence McKinney",
        "community": 15,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000753415655874418
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "0.101286067544233",
                "net_sr": "0.0739773378649793",
                "net_stuff": "-0.0126371626189609",
                "net_pass_sr": "0.0931030221457651",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.101286067544233
    },
    {
        "id": 1344,
        "name": "Jay Graham",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000167368428890567
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Texas A&M",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas A&M",
                "role": "OC",
                "net_ppa": "-0.0178110271336855",
                "net_sr": "0.0360023209497532",
                "net_stuff": "-0.00818109155778485",
                "net_pass_sr": "0.0182557339434659",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0178110271336855
    },
    {
        "id": 1345,
        "name": "Jeff Conway",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000161253054369103
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Texas State",
                "position": "OC"
            },
            "2013": {
                "college": "Texas State",
                "position": "OC"
            },
            "2014": {
                "college": "Texas State",
                "position": "OC"
            },
            "2015": {
                "college": "Texas State",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas State",
                "role": "OC",
                "net_ppa": "0.143416286392173",
                "net_sr": "0.0358814148993086",
                "net_stuff": "-0.0436139839696366",
                "net_pass_sr": "0.000976770571102126",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.143416286392173
    },
    {
        "id": 1347,
        "name": "Brett Elliott",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000556330919213008
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Texas State",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas State",
                "role": "OC",
                "net_ppa": "-0.108702543914637",
                "net_sr": "-0.0588711019824255",
                "net_stuff": "0.0512567518190188",
                "net_pass_sr": "-0.0127098529710705",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.108702543914637
    },
    {
        "id": 1348,
        "name": "Jacob Peeler",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000392782233833983
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Texas State",
                "position": "OC"
            },
            "2021": {
                "college": "Texas State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Texas State",
                "role": "OC",
                "net_ppa": "0.0978023486074166",
                "net_sr": "0.0342718715162482",
                "net_stuff": "-0.0934765049224467",
                "net_pass_sr": "0.0225198178650601",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0978023486074166
    },
    {
        "id": 1349,
        "name": "Eric Morris",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000117372532848825
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2014": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2015": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2016": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2017": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "0.119634215772974",
                "net_sr": "-0.0113871953826271",
                "net_stuff": "-0.00882414717430455",
                "net_pass_sr": "-0.0299648370159205",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.119634215772974
    },
    {
        "id": 1350,
        "name": "Clay McGuire",
        "community": 15,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000234745065697502
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Texas Tech",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Texas Tech",
                "role": "OC",
                "net_ppa": "-0.0545834685064448",
                "net_sr": "0.0193595619358633",
                "net_stuff": "0.053343267697024",
                "net_pass_sr": "0.0627588908690461",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0545834685064448
    },
    {
        "id": 1351,
        "name": "Chris Hedden",
        "community": 28,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 4.56431831026226e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Toledo",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "-0.0462420175982487",
                "net_sr": "-0.01001374731763",
                "net_stuff": "-0.0219160827473575",
                "net_pass_sr": "0.00440191659607603",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0462420175982487
    },
    {
        "id": 1352,
        "name": "Mike Hallett",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000200978965980691
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Toledo",
                "position": "OC"
            },
            "2021": {
                "college": "Toledo",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "0.0475447357300857",
                "net_sr": "-0.0134455734990058",
                "net_stuff": "0.0116129579275942",
                "net_pass_sr": "-0.000169146234035467",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0475447357300857
    },
    {
        "id": 1353,
        "name": "Robert Weiner",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000200978965980685
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Toledo",
                "position": "OC"
            },
            "2021": {
                "college": "Toledo",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Toledo",
                "role": "OC",
                "net_ppa": "0.0475447357300857",
                "net_sr": "-0.0134455734990058",
                "net_stuff": "0.0116129579275942",
                "net_pass_sr": "-0.000169146234035467",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0475447357300857
    },
    {
        "id": 1355,
        "name": "Matt Moore",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000208309394786944
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Troy",
                "position": "OC"
            },
            "2016": {
                "college": "Troy",
                "position": "OC"
            },
            "2017": {
                "college": "Troy",
                "position": "OC"
            },
            "2018": {
                "college": "Troy",
                "position": "OC"
            },
            "2019": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "0.0278669699326936",
                "net_sr": "-0.00509076503428829",
                "net_stuff": "0.0180219930611028",
                "net_pass_sr": "0.00370093464948162",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "-0.186152802066922",
                "net_sr": "-0.0913497430269147",
                "net_stuff": "0.0997841182961294",
                "net_pass_sr": "-0.0831285055929895",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.1582858321342284
    },
    {
        "id": 1356,
        "name": "Sean Reagan",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000416618789573763
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": {
                "college": "Troy",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "0.0570648366307883",
                "net_sr": "-0.0130778295169919",
                "net_stuff": "-0.020975905868791",
                "net_pass_sr": "-0.00348215564401017",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0570648366307883
    },
    {
        "id": 1357,
        "name": "Ryan Pugh",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000211722168874942
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Troy",
                "position": "OC"
            },
            "2020": {
                "college": "Troy",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "0.0115274892472348",
                "net_sr": "0.0165737539173328",
                "net_stuff": "-0.00555520430298162",
                "net_pass_sr": "0.0223532295255604",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0115274892472348
    },
    {
        "id": 1358,
        "name": "Luke Meadows",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000105861084437437
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Troy",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Troy",
                "role": "OC",
                "net_ppa": "-0.065247629936629",
                "net_sr": "-0.042639372396228",
                "net_stuff": "0.0150803171851362",
                "net_pass_sr": "-0.0440365979227606",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.065247629936629
    },
    {
        "id": 1359,
        "name": "Greg Davis Jr",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000179603339209356
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Tulane",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulane",
                "role": "OC",
                "net_ppa": "-0.0100886460694971",
                "net_sr": "-0.0102078468415015",
                "net_stuff": "-0.0302166904540507",
                "net_pass_sr": "-0.0219199280833618",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0100886460694971
    },
    {
        "id": 1362,
        "name": "Denver Johnson",
        "community": 17,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000293999564433692
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Tulsa",
                "position": "OC"
            },
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Tulsa",
                "role": "OC",
                "net_ppa": "0.0637744255651172",
                "net_sr": "0.00612500359734552",
                "net_stuff": "-0.0398794393830179",
                "net_pass_sr": "0.0146922882639119",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0637744255651172
    },
    {
        "id": 1364,
        "name": "Kim Helton",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000627071581502259
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": {
                "college": "UAB",
                "position": "OC"
            },
            "2008": {
                "college": "UAB",
                "position": "OC"
            },
            "2009": {
                "college": "UAB",
                "position": "OC"
            },
            "2010": {
                "college": "UAB",
                "position": "OC"
            },
            "2011": {
                "college": "UAB",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UAB",
                "role": "OC",
                "net_ppa": "-0.0314475660453751",
                "net_sr": "-0.0182495961382048",
                "net_stuff": "0.00723328924158112",
                "net_pass_sr": "-0.0288608134110004",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0314475660453751
    },
    {
        "id": 1365,
        "name": "Brent Key",
        "community": 30,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000246588299889879
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "UCF",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.138796617865843",
                "net_sr": "-0.0827070401988846",
                "net_stuff": "0.0203946309294125",
                "net_pass_sr": "-0.0943000598680112",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.138796617865843
    },
    {
        "id": 1366,
        "name": "Anthony Tucker",
        "community": 14,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000273979516927585
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "UCF",
                "position": "OC"
            },
            "2021": {
                "college": "Utah State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.016468079006486",
                "net_sr": "0.0023737150601596",
                "net_stuff": "-0.0262744849765153",
                "net_pass_sr": "-0.0236817090685666",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.0454392601075139",
                "net_sr": "-0.0227518411997185",
                "net_stuff": "-0.00669423224235302",
                "net_pass_sr": "0.0417082163066194",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.028971181101027903
    },
    {
        "id": 1367,
        "name": "Tim Harris Jr",
        "community": 7,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000603304800749347
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "UCF",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "UCF",
                "role": "OC",
                "net_ppa": "-0.0764290015068179",
                "net_sr": "-0.0283001239012969",
                "net_stuff": "-0.0133489316075757",
                "net_pass_sr": "-0.0470069436845211",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0764290015068179
    },
    {
        "id": 1372,
        "name": "Jim Svoboda",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000512767599250686
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "UCLA",
                "position": "OC"
            },
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "0.0361508355598229",
                "net_sr": "-0.0201585663866097",
                "net_stuff": "0.00270943541445365",
                "net_pass_sr": "-0.0185741526263722",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0361508355598229
    },
    {
        "id": 1373,
        "name": "Kennedy Polamalu",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000125875694529736
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "USC",
                "position": "OC"
            },
            "2011": {
                "college": "USC",
                "position": "OC"
            },
            "2012": {
                "college": "USC",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "UCLA",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "0.108810460213816",
                "net_sr": "0.0172008150188804",
                "net_stuff": "0.0113370721535501",
                "net_pass_sr": "0.0254443289094883",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "-0.111620555246569",
                "net_sr": "-0.00848337697537188",
                "net_stuff": "0.0350490705341344",
                "net_pass_sr": "0.00890368203359015",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.0028100950327529933
    },
    {
        "id": 1374,
        "name": "Justin Frye",
        "community": 23,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000542528855109766
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "UCLA",
                "position": "OC"
            },
            "2020": {
                "college": "UCLA",
                "position": "OC"
            },
            "2021": {
                "college": "UCLA",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "UCLA",
                "role": "OC",
                "net_ppa": "0.0635568417774781",
                "net_sr": "-0.0584659467523575",
                "net_stuff": "0.0745600262189012",
                "net_pass_sr": "-0.0461527290084321",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0635568417774781
    },
    {
        "id": 1377,
        "name": "Rob Ambrose",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000108285597334936
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2007": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2008": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "0.0102310517981427",
                "net_sr": "0.0224238273156173",
                "net_stuff": "-0.0373581654129448",
                "net_pass_sr": "0.0408342674875369",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0102310517981427
    },
    {
        "id": 1378,
        "name": "T.J. Weist",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000447675476473776
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": {
                "college": "South Florida",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "0.0351644209571808",
                "net_sr": "0.0203671655418687",
                "net_stuff": "0.0534820545152323",
                "net_pass_sr": "0.0416604679280296",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.289713947180442",
                "net_sr": "0.0965633046139286",
                "net_stuff": "-0.0498140569252582",
                "net_pass_sr": "0.0794489166392727",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.32487836813762283
    },
    {
        "id": 1379,
        "name": "Frank Verducci",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000167217750928335
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2016": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "0.0592480693260557",
                "net_sr": "0.0246388575522556",
                "net_stuff": "-0.025992716966662",
                "net_pass_sr": "0.00249032809359895",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0592480693260557
    },
    {
        "id": 1380,
        "name": "Frank Giufre",
        "community": 4,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000108285597334936
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2020": {
                "college": "Connecticut",
                "position": "OC"
            },
            "2021": {
                "college": "Connecticut",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Connecticut",
                "role": "OC",
                "net_ppa": "-0.0820032393582479",
                "net_sr": "-0.0573734738498246",
                "net_stuff": "0.00856442062261331",
                "net_pass_sr": "-0.0747752778049179",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0820032393582479
    },
    {
        "id": 1383,
        "name": "Rob Phenicie",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 1.20140947262785e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "UNLV",
                "position": "OC"
            },
            "2011": {
                "college": "UNLV",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "-0.0717793781880874",
                "net_sr": "-0.0408976738094826",
                "net_stuff": "-0.0102562615306897",
                "net_pass_sr": "-0.0793261092050062",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0717793781880874
    },
    {
        "id": 1384,
        "name": "Brent Myers",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 6.00704736252179e-8
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "UNLV",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "0.0197040525929006",
                "net_sr": "0.000128543572926243",
                "net_stuff": "-0.0444685555030414",
                "net_pass_sr": "-0.0195566618278176",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0197040525929006
    },
    {
        "id": 1385,
        "name": "Garin Justice",
        "community": 18,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000389952967668835
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "UNLV",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UNLV",
                "role": "OC",
                "net_ppa": "-0.0561415139330597",
                "net_sr": "-0.0237453036402916",
                "net_stuff": "-0.0195637760126024",
                "net_pass_sr": "-0.00868324607617282",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0561415139330597
    },
    {
        "id": 1387,
        "name": "John Morton",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000284922736278524
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "USC",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "0.0676115307262366",
                "net_sr": "0.0994508186302223",
                "net_stuff": "-0.0358852864738874",
                "net_pass_sr": "0.0879635613889685",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0676115307262366
    },
    {
        "id": 1388,
        "name": "Tee Martin",
        "community": 9,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000303915179196248
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "USC",
                "position": "OC"
            },
            "2016": {
                "college": "USC",
                "position": "OC"
            },
            "2017": {
                "college": "USC",
                "position": "OC"
            },
            "2018": {
                "college": "USC",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "USC",
                "role": "OC",
                "net_ppa": "0.100293168957449",
                "net_sr": "0.0366565380866872",
                "net_stuff": "-0.0159690730157856",
                "net_pass_sr": "0.0230338812988326",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.100293168957449
    },
    {
        "id": 1390,
        "name": "Darren Hiller",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000419335112290037
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "South Florida",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.289713947180442",
                "net_sr": "0.0965633046139286",
                "net_stuff": "-0.0498140569252582",
                "net_pass_sr": "0.0794489166392727",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.289713947180442
    },
    {
        "id": 1391,
        "name": "Kerwin Bell",
        "community": 8,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000140390406507259
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "South Florida",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "South Florida",
                "role": "OC",
                "net_ppa": "0.0391573681117536",
                "net_sr": "0.00859917181605946",
                "net_stuff": "0.00717360823244356",
                "net_pass_sr": "-0.0153500715975053",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0391573681117536
    },
    {
        "id": 1392,
        "name": "Jim Harding",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.000276903190627345
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2014": false,
            "2015": {
                "college": "Utah",
                "position": "OC"
            },
            "2016": {
                "college": "Utah",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "-0.0485421844773932",
                "net_sr": "-0.0320151873146947",
                "net_stuff": "0.0265328344551833",
                "net_pass_sr": "-0.0550258981656713",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            },
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "-0.0800538141898718",
                "net_sr": "-0.0765654529595341",
                "net_stuff": "0.07653472197597",
                "net_pass_sr": "-0.0629912934401306",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.128595998667265
    },
    {
        "id": 1393,
        "name": "Troy Taylor",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00026363973394911
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Utah",
                "position": "OC"
            },
            "2018": {
                "college": "Utah",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Utah",
                "role": "OC",
                "net_ppa": "0.0158432383096546",
                "net_sr": "0.0277335207528279",
                "net_stuff": "0.0121538822631531",
                "net_pass_sr": "0.0325669707477298",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0158432383096546
    },
    {
        "id": 1395,
        "name": "Luke Wells",
        "community": 21,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000115357109261277
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "Utah State",
                "position": "OC"
            },
            "2014": {
                "college": "Utah State",
                "position": "OC"
            },
            "2015": {
                "college": "Utah State",
                "position": "OC"
            },
            "2016": {
                "college": "Utah State",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "0.0422496382162257",
                "net_sr": "-0.0206866512480619",
                "net_stuff": "0.0489101333126214",
                "net_pass_sr": "-0.0240318424367763",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0422496382162257
    },
    {
        "id": 1396,
        "name": "Jovon Bouknight",
        "community": 21,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000288392773153112
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": {
                "college": "Utah State",
                "position": "OC"
            },
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Utah State",
                "role": "OC",
                "net_ppa": "-0.0193803391307444",
                "net_sr": "-0.0343336490299136",
                "net_stuff": "0.0393839159972881",
                "net_pass_sr": "-0.0311306058639161",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0193803391307444
    },
    {
        "id": 1397,
        "name": "Patrick Higgins",
        "community": 25,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 2,
            "eigen": 0.0000484113098185985
        },
        "positions": {
            "2000": {
                "college": "UTEP",
                "position": "OC"
            },
            "2001": {
                "college": "UTEP",
                "position": "OC"
            },
            "2002": {
                "college": "UTEP",
                "position": "OC"
            },
            "2003": {
                "college": "UTEP",
                "position": "OC"
            },
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": {
                "college": "UTEP",
                "position": "OC"
            },
            "2014": {
                "college": "UTEP",
                "position": "OC"
            },
            "2015": {
                "college": "UTEP",
                "position": "OC"
            },
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "0.0128497317262099",
                "net_sr": "-0.0051041911118711",
                "net_stuff": "-0.028830950710867",
                "net_pass_sr": "-0.0245080532677366",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 7,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0128497317262099
    },
    {
        "id": 1398,
        "name": "Bob Connelly",
        "community": 39,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000790725632342457
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "UTEP",
                "position": "OC"
            },
            "2009": {
                "college": "UTEP",
                "position": "OC"
            },
            "2010": {
                "college": "UTEP",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UTEP",
                "role": "OC",
                "net_ppa": "-0.0409359711110535",
                "net_sr": "0.0106727987523167",
                "net_stuff": "-0.0645466130916039",
                "net_pass_sr": "-0.0200027549566619",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0409359711110535
    },
    {
        "id": 1401,
        "name": "Jeff Kastl",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000453467601087525
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "UT San Antonio",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "UT San Antonio",
                "role": "OC",
                "net_ppa": "0.0198543701080781",
                "net_sr": "-0.00784295829634812",
                "net_stuff": "-0.0313698547267809",
                "net_pass_sr": "-0.0077456309551262",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0198543701080781
    },
    {
        "id": 1404,
        "name": "Jimmy Kiser",
        "community": 42,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 2.92304689660757e-9
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": {
                "college": "Vanderbilt",
                "position": "OC"
            },
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.135277061847966",
                "net_sr": "-0.0219093475463372",
                "net_stuff": "0.0289078017203142",
                "net_pass_sr": "-0.00928602392797306",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.135277061847966
    },
    {
        "id": 1405,
        "name": "David Raih",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000221214103073348
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Vanderbilt",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Vanderbilt",
                "role": "OC",
                "net_ppa": "-0.035048252170554",
                "net_sr": "-0.0635403979687799",
                "net_stuff": "-0.00723194726551638",
                "net_pass_sr": "-0.0396767350255722",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.035048252170554
    },
    {
        "id": 1406,
        "name": "Mike Groh",
        "community": 16,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000352894903213328
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": {
                "college": "Virginia",
                "position": "OC"
            },
            "2007": {
                "college": "Virginia",
                "position": "OC"
            },
            "2008": {
                "college": "Virginia",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Virginia",
                "role": "OC",
                "net_ppa": "-0.0895257737848481",
                "net_sr": "-0.0398220718767443",
                "net_stuff": "0.0171239556787411",
                "net_pass_sr": "-0.0288657548640771",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0895257737848481
    },
    {
        "id": 1407,
        "name": "Bryan Stinespring",
        "community": 24,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000110797848435889
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2003": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2004": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2005": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2006": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2007": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2008": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2009": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2010": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2011": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2012": {
                "college": "Virginia Tech",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Virginia Tech",
                "role": "OC",
                "net_ppa": "-0.0736852610990372",
                "net_sr": "-0.0370994445458184",
                "net_stuff": "0.00984837123559656",
                "net_pass_sr": "-0.0406350482326452",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0736852610990372
    },
    {
        "id": 1409,
        "name": "Steed Lobotzke",
        "community": 2,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000365825226100257
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2004": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2005": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2006": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2007": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2008": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2009": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2010": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2011": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2012": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2013": {
                "college": "Wake Forest",
                "position": "OC"
            },
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Wake Forest",
                "role": "OC",
                "net_ppa": "-0.053222671518291",
                "net_sr": "-0.0287574991247219",
                "net_stuff": "-0.0176929313874963",
                "net_pass_sr": "-0.0206934821315874",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 11,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.053222671518291
    },
    {
        "id": 1412,
        "name": "Todd Sturdy",
        "community": 7,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000909333289768686
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Washington State",
                "position": "OC"
            },
            "2009": {
                "college": "Washington State",
                "position": "OC"
            },
            "2010": {
                "college": "Washington State",
                "position": "OC"
            },
            "2011": {
                "college": "Washington State",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "-0.106077366229792",
                "net_sr": "-0.0417355426050641",
                "net_stuff": "0.0211587769655793",
                "net_pass_sr": "-0.0251433600127701",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.106077366229792
    },
    {
        "id": 1413,
        "name": "Craig Stutzmann",
        "community": 29,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000719399170740506
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Washington State",
                "position": "OC"
            },
            "2021": {
                "college": "Washington State",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Washington State",
                "role": "OC",
                "net_ppa": "-0.0399647385743238",
                "net_sr": "-0.0400523643257341",
                "net_stuff": "0.0498869041256583",
                "net_pass_sr": "-0.0393983140450929",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Other",
        "aggregate_xpa": -0.0399647385743238
    },
    {
        "id": 1414,
        "name": "Chad Scott",
        "community": 27,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000124985636872164
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2020": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2021": {
                "college": "West Virginia",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "-0.12088861932714",
                "net_sr": "-0.0528634706342603",
                "net_stuff": "0.030005503105011",
                "net_pass_sr": "-0.0539054552134527",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.12088861932714
    },
    {
        "id": 1415,
        "name": "Gerad Parker",
        "community": 27,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000833237579147716
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "West Virginia",
                "position": "OC"
            },
            "2021": {
                "college": "West Virginia",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "West Virginia",
                "role": "OC",
                "net_ppa": "-0.0355035985173696",
                "net_sr": "-0.0053435854732658",
                "net_stuff": "-0.0371535530530454",
                "net_pass_sr": "-0.0150273457329755",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0355035985173696
    },
    {
        "id": 1416,
        "name": "Kevin Wright",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 4.48781710429998e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "-0.0844979486581198",
                "net_sr": "0.00750770517661359",
                "net_stuff": "-0.0351979607416962",
                "net_pass_sr": "-0.0575011881310245",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0844979486581198
    },
    {
        "id": 1417,
        "name": "Walt Wells",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 4.48781710431514e-7
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "0.0297406994287745",
                "net_sr": "0.0230021338785895",
                "net_stuff": "-0.000112718908751419",
                "net_pass_sr": "-0.0219729559451943",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0297406994287745
    },
    {
        "id": 1418,
        "name": "Zach Azzanni",
        "community": 29,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000419335112290026
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "0.0181806304293871",
                "net_sr": "0.0247537749675495",
                "net_stuff": "-0.0330924983059181",
                "net_pass_sr": "0.0542336372044584",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0181806304293871
    },
    {
        "id": 1419,
        "name": "Junior Adams",
        "community": 3,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000659329106058201
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2018": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "0.0997309535300659",
                "net_sr": "0.0484956336729588",
                "net_stuff": "-0.00215113613192205",
                "net_pass_sr": "0.0630348358249115",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 2,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": 0.0997309535300659
    },
    {
        "id": 1420,
        "name": "Bryan Ellis",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000833689553190746
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2020": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2021": {
                "college": "Western Kentucky",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "-0.0188001039623059",
                "net_sr": "-0.00389884215894204",
                "net_stuff": "-0.016404744724564",
                "net_pass_sr": "0.00696689298482073",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 3,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0188001039623059
    },
    {
        "id": 1421,
        "name": "Ryan Aplin",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000277896517729416
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": {
                "college": "Western Kentucky",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "-0.0712553115511352",
                "net_sr": "-0.0376441881188057",
                "net_stuff": "0.00627524415174735",
                "net_pass_sr": "-0.0593776926804276",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": -0.0712553115511352
    },
    {
        "id": 1422,
        "name": "Zach Kittley",
        "community": 3,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00000277896517729261
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Western Kentucky",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Western Kentucky",
                "role": "OC",
                "net_ppa": "0.262927454377173",
                "net_sr": "0.100959830650344",
                "net_stuff": "-0.0648047571976516",
                "net_pass_sr": "0.135356799077683",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.262927454377173
    },
    {
        "id": 1423,
        "name": "Ryan Cubit",
        "community": 35,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000133599129300925
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "0.00400229793686824",
                "net_sr": "0.0106199886728794",
                "net_stuff": "-0.0396595351142143",
                "net_pass_sr": "-0.00457117578611094",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.00400229793686824
    },
    {
        "id": 1424,
        "name": "Jake Moreland",
        "community": 10,
        "isWhite": false,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000413940267368755
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2018": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2019": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2020": {
                "college": "Western Michigan",
                "position": "OC"
            },
            "2021": false
        },
        "performance": [
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "-0.0690071067270829",
                "net_sr": "-0.024217608496353",
                "net_stuff": "0.0157697290915491",
                "net_pass_sr": "-0.0361519739104996",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "Black",
        "aggregate_xpa": -0.0690071067270829
    },
    {
        "id": 1426,
        "name": "Eric Evans",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000103485066842115
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Western Michigan",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "0.0274744725776234",
                "net_sr": "0.0223601049306954",
                "net_stuff": "-0.0604708091641673",
                "net_pass_sr": "0.00978754072216115",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0274744725776234
    },
    {
        "id": 1427,
        "name": "Mike Bath",
        "community": 10,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0000103485066842091
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Western Michigan",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Western Michigan",
                "role": "OC",
                "net_ppa": "0.0274744725776234",
                "net_sr": "0.0223601049306954",
                "net_stuff": "-0.0604708091641673",
                "net_pass_sr": "0.00978754072216115",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0274744725776234
    },
    {
        "id": 1428,
        "name": "Joe Rudolph",
        "community": 11,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.000243207296268665
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2016": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2017": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2018": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2019": {
                "college": "Wisconsin",
                "position": "OC"
            },
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Wisconsin",
                "role": "OC",
                "net_ppa": "0.0152008152410497",
                "net_sr": "0.0516086683335365",
                "net_stuff": "0.0013523752476991",
                "net_pass_sr": "0.000850126843601917",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 5,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0152008152410497
    },
    {
        "id": 1430,
        "name": "Brent Vigen",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.0260137200847993
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2015": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2016": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2017": false,
            "2018": {
                "college": "Wyoming",
                "position": "OC"
            },
            "2019": false,
            "2020": false,
            "2021": false
        },
        "performance": [
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "0.0544906479671958",
                "net_sr": "-0.0157604553032317",
                "net_stuff": "0.00305486306418787",
                "net_pass_sr": "-0.0370821246692803",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 4,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0544906479671958
    },
    {
        "id": 1431,
        "name": "Tim Polasek",
        "community": 32,
        "isWhite": true,
        "centrality": {
            "betweenness": 0,
            "closeness": 4.88679733962753e-7,
            "degree": 1,
            "eigen": 0.00650343002119983
        },
        "positions": {
            "2000": false,
            "2001": false,
            "2002": false,
            "2003": false,
            "2004": false,
            "2005": false,
            "2006": false,
            "2007": false,
            "2008": false,
            "2009": false,
            "2010": false,
            "2011": false,
            "2012": false,
            "2013": false,
            "2014": false,
            "2015": false,
            "2016": false,
            "2017": false,
            "2018": false,
            "2019": false,
            "2020": false,
            "2021": {
                "college": "Wyoming",
                "position": "OC"
            }
        },
        "performance": [
            {
                "college": "Wyoming",
                "role": "OC",
                "net_ppa": "0.0725691342628308",
                "net_sr": "0.0497903376864938",
                "net_stuff": "-0.0263284623805439",
                "net_pass_sr": "0.0776856093905574",
                "net_fpi": false,
                "total_sr": false,
                "total_stuffs": false,
                "total_pass_sr": false,
                "total_ppa": false
            }
        ],
        "yearsCoached": 1,
        "positionsCoached": [
            "OC"
        ],
        "race": "White",
        "aggregate_xpa": 0.0725691342628308
    }
]

/**
 * Helper function to return the centrality values
 * @param {*} array - Array of coach performance objects
 * @returns Object with keys for each centrality measures max, min, q75, q25 & median value
 */
export const getCentralityMeasureAttributes = (array) => {
  return {
    "betweenness": {
      max: dsc(array.map(item => item['centrality']['betweenness']))[0],
      min: asc(array.map(item => item['centrality']['betweenness']))[0],
      q75: q75(array.map(item => item['centrality']['betweenness'])),
      q25: q25(array.map(item => item['centrality']['betweenness'])),
      median: median(array.map(item => item['centrality']['betweenness']))
    },
    "closeness": {
      max: dsc(array.map(item => item['centrality']['closeness']))[0],
      min: asc(array.map(item => item['centrality']['closeness']))[0],
      q75: q75(array.map(item => item['centrality']['closeness'])),
      q25: q25(array.map(item => item['centrality']['closeness'])),
      median: median(array.map(item => item['centrality']['closeness']))
    },
    "degree": {
      max: dsc(array.map(item => item['centrality']['degree']))[0],
      min: asc(array.map(item => item['centrality']['degree']))[0],
      q75: q75(array.map(item => item['centrality']['degree'])),
      q25: q25(array.map(item => item['centrality']['degree'])),
      median: median(array.map(item => item['centrality']['degree']))
    },
    "eigen": {
      max: dsc(array.map(item => item['centrality']['eigen']))[0],
      min: asc(array.map(item => item['centrality']['eigen']))[0],
      q75: q75(array.map(item => item['centrality']['eigen'])),
      q25: q25(array.map(item => item['centrality']['eigen'])),
      median: median(array.map(item => item['centrality']['eigen']))
    }
  }
}

export const getYearsCoachedMetrics = (array) => {
    return {
        max: dsc(array.map(item => item['yearsCoached']))[0],
        min: asc(array.map(item => item['yearsCoached']))[0],
        q75: q75(array.map(item => item['yearsCoached'])),
        q25: q25(array.map(item => item['yearsCoached'])),
        median: median(array.map(item => item['yearsCoached']))
    }
}

export const getPerformanceNetMetrics = (array) => {
    return {
        net_ppa: {
            max: dsc(array.map(item => Number(item['net_ppa'])))[0],
            min: asc(array.map(item => Number(item['net_ppa'])))[0],
            q75: q75(array.map(item => Number(item['net_ppa']))),
            q25: q25(array.map(item => Number(item['net_ppa']))),
            median: median(array.map(item => Number(item['net_ppa'])))
        },
        net_sr: {
            max: dsc(array.map(item => Number(item['net_sr'])))[0],
            min: asc(array.map(item => Number(item['net_sr'])))[0],
            q75: q75(array.map(item => Number(item['net_sr']))),
            q25: q25(array.map(item => Number(item['net_sr']))),
            median: median(array.map(item => Number(item['net_sr'])))
        },
        net_stuff: {
            max: dsc(array.map(item => Number(item['net_stuff'])))[0],
            min: asc(array.map(item => Number(item['net_stuff'])))[0],
            q75: q75(array.map(item => Number(item['net_stuff']))),
            q25: q25(array.map(item => Number(item['net_stuff']))),
            median: median(array.map(item => Number(item['net_stuff'])))
        },
        net_pass_sr: {
            max: dsc(array.map(item => Number(item['net_pass_sr'])))[0],
            min: asc(array.map(item => Number(item['net_pass_sr'])))[0],
            q75: q75(array.map(item => Number(item['net_pass_sr']))),
            q25: q25(array.map(item => Number(item['net_pass_sr']))),
            median: median(array.map(item => Number(item['net_pass_sr'])))
        }
    }
}

export const getPerformanceTotalMetrics = (array) => {
    return {
        total_sr: {
            max: dsc(array.map(item => Number(item['total_sr'])))[0],
            min: asc(array.map(item => Number(item['total_sr'])))[0],
            q75: q75(array.map(item => Number(item['total_sr']))),
            q25: q25(array.map(item => Number(item['total_sr']))),
            median: median(array.map(item => Number(item['total_sr'])))
        },
        total_stuffs: {
            max: dsc(array.map(item => Number(item['total_stuffs'])))[0],
            min: asc(array.map(item => Number(item['total_stuffs'])))[0],
            q75: q75(array.map(item => Number(item['total_stuffs']))),
            q25: q25(array.map(item => Number(item['total_stuffs']))),
            median: median(array.map(item => Number(item['total_stuffs'])))
        },
        total_pass_sr: {
            max: dsc(array.map(item => Number(item['total_pass_sr'])))[0],
            min: asc(array.map(item => Number(item['total_pass_sr'])))[0],
            q75: q75(array.map(item => Number(item['total_pass_sr']))),
            q25: q25(array.map(item => Number(item['total_pass_sr']))),
            median: median(array.map(item => Number(item['total_pass_sr'])))
        },
        total_ppa: {
            max: dsc(array.map(item => Number(item['total_ppa'])))[0],
            min: asc(array.map(item => Number(item['total_ppa'])))[0],
            q75: q75(array.map(item => Number(item['total_ppa']))),
            q25: q25(array.map(item => Number(item['total_ppa']))),
            median: median(array.map(item => Number(item['total_ppa'])))
        }
    }
}

export const getYearsAggregatedXPA = (array) => {
    return {
        max: dsc(array.map(item => item["aggregate_xpa"]))[0],
        min: asc(array.map(item => item["aggregate_xpa"]))[0],
        q75: q75(array.map(item => item["aggregate_xpa"])),
        q25: q25(array.map(item => item["aggregate_xpa"])),
        median: median(array.map(item => item["aggregate_xpa"]))
    }
}


