export const coachNameIdTranslation = {
  "Troy Calhoun": 2,
  "Rob Ianello": 5,
  "Terry Bowden": 6,
  "Tom Arth": 7,
  "Dennis Franchione": 9,
  "Nick Saban": 11,
  "Scott Satterfield": 12,
  "Eliah Drinkwitz": 13,
  "Shawn Clark": 14,
  "Mike Stoops": 17,
  "Rich Rodriguez": 18,
  "Kevin Sumlin": 19,
  "Jedd Fisch": 20,
  "Dirk Koetter": 22,
  "Dennis Erickson": 23,
  "Todd Graham": 24,
  "Herm Edwards": 25,
  "Houston Nutt": 26,
  "Bobby Petrino": 27,
  "John L. Smith": 28,
  "Bret Bielema": 29,
  "Chad Morris": 30,
  "Sam Pittman": 31,
  "Hugh Freeze": 34,
  "Gus Malzahn": 35,
  "Bryan Harsin": 36,
  "Blake Anderson": 37,
  "Butch Jones": 38,
  "Todd Berry": 39,
  "Stan Brock": 41,
  "Rich Ellerson": 42,
  "Jeff Monken": 43,
  "Tommy Tuberville": 44,
  "Gene Chizik": 45,
  "Bill Lynch": 46,
  "Brady Hoke": 47,
  "Stan Parrish": 48,
  "Pete Lembo": 49,
  "Mike Neu": 50,
  "Kevin Steele": 51,
  "Guy Morriss": 52,
  "Art Briles": 53,
  "Jim Grobe": 54,
  "Matt Rhule": 55,
  "Dave Aranda": 56,
  "Dan Hawkins": 57,
  "Chris Petersen": 58,
  "Andy Avalos": 59,
  "Tom O'Brien": 60,
  "Jeff Jagodzinski": 61,
  "Frank Spaziani": 62,
  "Steve Addazio": 63,
  "Jeff Hafley": 64,
  "Urban Meyer": 66,
  "Gregg Brandon": 67,
  "Dave Clawson": 68,
  "Dino Babers": 69,
  "Mike Jinks": 70,
  "Scot Loeffler": 71,
  "Turner Gill": 74,
  "Jeff Quinn": 75,
  "Lance Leipold": 76,
  "Maurice Linguist": 77,
  "Gary Crowton": 79,
  "Bronco Mendenhall": 80,
  "Kalani Sitake": 81,
  "Jeff Tedford": 83,
  "Sonny Dykes": 84,
  "Justin Wilcox": 85,
  "Mike DeBord": 86,
  "Brian Kelly": 87,
  "Dan Enos": 88,
  "John Bonamego": 89,
  "Jim McElwain": 90,
  "Brad Lambert": 91,
  "Will Healy": 92,
  "Rick Minter": 93,
  "Mark Dantonio": 94,
  "Luke Fickell": 95,
  "Dabo Swinney": 97,
  "Jamey Chadwell": 99,
  "Jon Embree": 101,
  "Mike MacIntyre": 102,
  "Mel Tucker": 103,
  "Karl Dorrell": 104,
  "Steve Fairchild": 106,
  "Mike Bobo": 107,
  "Ted Roof": 109,
  "David Cutcliffe": 110,
  "Steve Logan": 111,
  "John Thompson": 112,
  "Skip Holtz": 113,
  "Ruffin McNeill": 114,
  "Scottie Montgomery": 115,
  "Mike Houston": 116,
  "Ron English": 119,
  "Chris Creighton": 120,
  "Carl Pelini": 122,
  "Charlie Partridge": 123,
  "Lane Kiffin": 124,
  "Willie Taggart": 125,
  "Mario Cristobal": 127,
  "Ron Turner": 128,
  "Butch Davis": 129,
  "Steve Spurrier": 130,
  "Will Muschamp": 132,
  "Dan Mullen": 133,
  "Jimbo Fisher": 135,
  "Mike Norvell": 136,
  "Tim DeRuyter": 138,
  "Kalen DeBoer": 139,
  "Mark Richt": 141,
  "Kirby Smart": 142,
  "Willie Fritz": 143,
  "Tyson Summers": 144,
  "Chad Lunsford": 145,
  "Trent Miles": 146,
  "Shawn Elliott": 147,
  "Paul Johnson": 150,
  "Geoff Collins": 151,
  "June Jones": 152,
  "Greg McMackin": 153,
  "Norm Chow": 154,
  "Nick Rolovich": 155,
  "Dana Dimel": 156,
  "Tony Levine": 157,
  "Tom Herman": 158,
  "Major Applewhite": 159,
  "Dana Holgorsen": 160,
  "Tim Beckman": 161,
  "Bill Cubit": 162,
  "Lovie Smith": 163,
  "Cam Cameron": 164,
  "Kevin Wilson": 167,
  "Tom Allen": 168,
  "Dan McCarney": 170,
  "Paul Rhoads": 171,
  "Matt Campbell": 172,
  "Mark Mangino": 174,
  "Charlie Weis": 175,
  "David Beaty": 176,
  "Les Miles": 177,
  "Ron Prince": 179,
  "Chris Klieman": 180,
  "Doug Martin": 182,
  "Darrell Hazell": 183,
  "Paul Haynes": 184,
  "Sean Lewis": 185,
  "Hal Mumme": 186,
  "Joker Phillips": 188,
  "Mark Stoops": 189,
  "Mark Hudspeth": 192,
  "Billy Napier": 193,
  "Matt Viator": 196,
  "Derek Dooley": 198,
  "Steve Kragthorpe": 199,
  "Charlie Strong": 200,
  "Ed Orgeron": 201,
  "Bob Pruett": 202,
  "Mark Snyder": 203,
  "Doc Holliday": 204,
  "Charles Huff": 205,
  "Ralph Friedgen": 207,
  "Randy Edsall": 208,
  "D.J. Durkin": 209,
  "Matt Canada": 210,
  "Mike Locksley": 211,
  "Charley Molnar": 212,
  "Mark Whipple": 213,
  "Walt Bell": 214,
  "Tommy West": 216,
  "Larry Porter": 217,
  "Justin Fuente": 218,
  "Ryan Silverfield": 219,
  "Randy Shannon": 221,
  "Al Golden": 222,
  "Manny Diaz": 223,
  "Shane Montgomery": 224,
  "Michael Haywood": 225,
  "Don Treadwell": 226,
  "Chuck Martin": 227,
  "Jim Harbaugh": 229,
  "Rick Stockstill": 232,
  "Tim Brewster": 234,
  "Jerry Kill": 235,
  "Tracy Claeys": 236,
  "P.J. Fleck": 237,
  "Joe Moorhead": 240,
  "Mike Leach": 241,
  "Barry Odom": 244,
  "Ken Niumatalolo": 245,
  "Chuck Amato": 246,
  "Dave Doeren": 247,
  "Bo Pelini": 250,
  "Mike Riley": 251,
  "Scott Frost": 252,
  "Chris Tormey": 253,
  "Brian Polian": 255,
  "Jay Norvell": 256,
  "Rocky Long": 257,
  "Bob Davie": 258,
  "Danny Gonzales": 259,
  "DeWayne Walker": 261,
  "Carl Torbush": 262,
  "Everett Withers": 264,
  "Larry Fedora": 265,
  "Mack Brown": 266,
  "Darrell Dickey": 267,
  "Todd Dodge": 268,
  "Seth Littrell": 269,
  "Rod Carey": 271,
  "Thomas Hammock": 272,
  "Pat Fitzgerald": 274,
  "Brian Knorr": 276,
  "Tim Albin": 277,
  "Ryan Day": 280,
  "Lincoln Riley": 282,
  "Bobby Wilder": 285,
  "Ricky Rahne": 286,
  "Matt Luke": 287,
  "Chip Kelly": 289,
  "Mark Helfrich": 290,
  "Gary Andersen": 291,
  "Jonathan Smith": 292,
  "Bill O'Brien": 294,
  "James Franklin": 295,
  "Paul Chryst": 298,
  "Pat Narduzzi": 299,
  "Danny Hope": 301,
  "Jeff Brohm": 302,
  "David Bailiff": 304,
  "Mike Bloomgren": 305,
  "Greg Schiano": 307,
  "Kyle Flood": 308,
  "Chris Ash": 309,
  "Chuck Long": 312,
  "Dave Baldwin": 313,
  "Ron Caragher": 315,
  "Brent Brennan": 316,
  "Phil Bennett": 318,
  "Joey Jones": 319,
  "Steve Campbell": 320,
  "Kane Wommack": 321,
  "Shane Beamer": 323,
  "Jeff Scott": 324,
  "Ellis Johnson": 326,
  "Todd Monken": 327,
  "Jay Hopson": 328,
  "Will Hall": 329,
  "David Shaw": 331,
  "Paul Pasqualoni": 332,
  "Greg Robinson": 333,
  "Doug Marrone": 334,
  "Scott Shafer": 335,
  "Jeremy Pruitt": 339,
  "Josh Heupel": 340,
  "Steve Sarkisian": 341,
  "Mike Sherman": 343,
  "Jake Spavital": 344,
  "Kliff Kingsbury": 345,
  "Matt Wells": 346,
  "Jason Candle": 348,
  "Neal Brown": 350,
  "Chip Lindsey": 351,
  "Bob Toledo": 353,
  "Curtis Johnson": 354,
  "Keith Burns": 355,
  "Bill Blankenship": 356,
  "Philip Montgomery": 357,
  "Neil Callaway": 359,
  "Garrick McGee": 360,
  "Bill Clark": 361,
  "Mike Kruczek": 362,
  "Rick Neuheisel": 363,
  "Jim L. Mora": 364,
  "Bob Diaco": 365,
  "Mike Sanford Sr": 367,
  "Bobby Hauck": 368,
  "Tony Sanchez": 369,
  "Marcus Arroyo": 370,
  "Clay Helton": 373,
  "Jim Leavitt": 374,
  "Brent Guy": 378,
  "Gary Nord": 379,
  "Sean Kugler": 381,
  "Frank Wilson": 382,
  "Jeff Traylor": 383,
  "Robbie Caldwell": 386,
  "Derek Mason": 387,
  "Clark Lea": 388,
  "Al Groh": 390,
  "Mike London": 391,
  "Jimmy Lake": 395,
  "Bill Doba": 396,
  "Paul Wulff": 397,
  "Bill Stewart": 399,
  "David Elson": 400,
  "Mike Sanford Jr": 401,
  "Tyson Helton": 402,
  "Gary Darnell": 403,
  "Tim Lester": 404,
  "Vic Koenning": 406,
  "Dave Christensen": 408,
  "Craig Bohl": 409,
  "John Rudzinski": 411,
  "Joe Tresey": 413,
  "Jim Fleming": 414,
  "Curt Mallory": 415,
  "Kevin Cosgrove": 416,
  "Matt Feeney": 417,
  "Joe Kines": 418,
  "Tosh Lupoi": 419,
  "Pete Golding": 420,
  "Charles Kelly": 421,
  "Nate Woody": 422,
  "Scot Sloan": 423,
  "Bryan Brown": 424,
  "Dale Jones": 425,
  "Larry Mac Duff": 427,
  "Greg Brown": 428,
  "Tim Kish": 429,
  "Jeff Casteel": 430,
  "Marcel Yates": 431,
  "Don Brown": 432,
  "Phil Snow": 433,
  "Bill Miller": 434,
  "Craig Bray": 435,
  "Paul Randolph": 436,
  "Ron West": 437,
  "Chris Ball": 438,
  "Keith Patterson": 439,
  "Marvin Lewis": 440,
  "Dave Wommack": 442,
  "Willy Robinson": 444,
  "Robb Smith": 445,
  "John Chavis": 446,
  "Jack Curtis": 448,
  "Joe Cauthen": 450,
  "David Duggan": 451,
  "Dennis Therrell": 452,
  "Chris Smeland": 454,
  "Payam Saadat": 455,
  "Jay Bateman": 456,
  "John Loose": 457,
  "Greg Gasparato": 458,
  "Shiel Wood": 459,
  "John Lovett": 460,
  "David Gibbs": 461,
  "Brian VanGorder": 462,
  "Charlie Harbison": 463,
  "Doug Graber": 466,
  "Jay Hood": 467,
  "Kevin Kelly": 468,
  "Tim Daoust": 469,
  "Tyler Stockton": 470,
  "Bob Trott": 472,
  "Larry Hoefer": 474,
  "Brian Norwood": 475,
  "Ron Roberts": 476,
  "Bob Gregory": 477,
  "Ron Collins": 478,
  "Pete Kwiatkowski": 479,
  "Jeff Schmedding": 480,
  "Spencer Danielson": 481,
  "Kane Ioane": 482,
  "Bill McGovern": 483,
  "Jim Reid": 484,
  "Anthony Campanile": 485,
  "Tem Lukabu": 486,
  "Mike Ward": 487,
  "Mike Elko": 488,
  "Shannon Morrison": 489,
  "Kim McCloud": 490,
  "Brian Ward": 491,
  "Marcus White": 492,
  "Perry Eliano": 493,
  "Eric Lewis": 494,
  "Jimmy Williams": 498,
  "Fred Reed": 499,
  "William Inge": 500,
  "Lou Tepper": 501,
  "Brian Borland": 502,
  "Chris Simpson": 503,
  "Jaime Hill": 505,
  "Nick Howell": 506,
  "Ilaisa Tuiaki": 507,
  "Clancy Pendergast": 509,
  "Andy Buh": 510,
  "Art Kaufman": 511,
  "Peter Sirmon": 512,
  "John Jancek": 515,
  "Tim Banks": 517,
  "Joe Tumpkin": 518,
  "Greg Colby": 519,
  "Robb Akey": 520,
  "Matt Wallerstedt": 521,
  "Chip West": 522,
  "Glenn Spencer": 523,
  "Brandon Cooper": 524,
  "Marcus West": 525,
  "Rick Smith": 526,
  "Hank Hughes": 530,
  "Robert Prunty": 531,
  "Steve Clinkscale": 532,
  "Marcus Freeman": 533,
  "Mike Tressel": 534,
  "Brent Venables": 535,
  "Marion Hobby": 536,
  "Marvin Sanders": 538,
  "Chad Staggs": 539,
  "Vince Okruch": 541,
  "Mike Hankwitz": 542,
  "Kent Baer": 543,
  "D.J. Eliot": 544,
  "Chris Wilson": 545,
  "Steve Stanard": 547,
  "Al Simmons": 548,
  "Marty English": 549,
  "Chuck Heater": 550,
  "Jerry Azzinaro": 551,
  "Jim Knowles": 553,
  "Ben Albert": 554,
  "Matt Guerrieri": 555,
  "Greg Hudson": 557,
  "Brian Mitchell": 558,
  "Kenwick Thompson": 559,
  "David Blackwell": 560,
  "Blake Harrell": 561,
  "Jay Peterson": 563,
  "Brad McCaslin": 564,
  "Neal Neathery": 565,
  "Kurt Van Valkenburgh": 567,
  "Pete Rekstis": 568,
  "Roc Bellantoni": 569,
  "Chris Kiffin": 570,
  "Tony Pecoraro": 571,
  "Kevin Fouquier": 573,
  "Phil Galiano": 574,
  "Todd Orlando": 575,
  "Josh Conklin": 576,
  "Matt House": 577,
  "Ron Cooper": 578,
  "Jeff Copp": 579,
  "Jerod Kruse": 580,
  "Jon Hoke": 581,
  "Greg Mattison": 582,
  "Teryl Austin": 583,
  "Dan Quinn": 584,
  "Chris Rumph": 585,
  "Todd Grantham": 586,
  "Harlon Barnett": 588,
  "Adam Fuller": 589,
  "Randy Stewart": 592,
  "Nick Toth": 593,
  "Lorenzo Ward": 594,
  "Orlondo Steinauer": 595,
  "Bert Watts": 596,
  "Dan Lanning": 599,
  "Glenn Schumann": 600,
  "Lorenzo Costantini": 601,
  "Jesse Minter": 602,
  "Nate Fuqua": 603,
  "Jon Tenuta": 604,
  "Andrew Thacker": 605,
  "Nathan Burton": 606,
  "Cal Lee": 610,
  "Thom Kaumeyer": 611,
  "Kevin Clune": 612,
  "Tom Mason": 613,
  "Legi Suiaunoa": 614,
  "Corey Batoon": 615,
  "Jacob Yoro": 616,
  "Victor Santa Cruz": 617,
  "Bradley Dale Peveto": 619,
  "Alan Weddell": 621,
  "John Skladany": 622,
  "Brian Stewart": 623,
  "Jamie Bryant": 624,
  "Craig Naivar": 625,
  "Clay Jennings": 626,
  "Mark D'Onofrio": 627,
  "Doug Belk": 628,
  "Mike Cassity": 629,
  "Dan Disch": 631,
  "Mike Phair": 632,
  "Hardy Nickerson": 633,
  "Ryan Walters": 634,
  "Brian George": 637,
  "Joe Palcic": 638,
  "Doug Mallory": 639,
  "Mike Ekeler": 640,
  "Mark Hagen": 641,
  "Kevin Peoples": 642,
  "Charlton Warren": 643,
  "Norm Parker": 644,
  "Phil Parker": 645,
  "Wayne Bolt": 646,
  "Wally Burnham": 647,
  "Jon Heacock": 648,
  "Tom Hayes": 650,
  "Bill Young": 651,
  "Clint Bowen": 652,
  "Buddy Wyatt": 653,
  "Vic Shealy": 654,
  "Dave Campo": 655,
  "Kenny Perry": 656,
  "Bob Elliott": 657,
  "Raheem Morris": 658,
  "Tim Tibesar": 659,
  "Chris Cosh": 660,
  "Blake Seiler": 661,
  "Scottie Hazelton": 662,
  "Joe Klanderman": 663,
  "Mike Dietzel": 664,
  "Ben Needham": 665,
  "Jeff Burrow": 666,
  "Tom Kaufman": 667,
  "Mike Archer": 670,
  "Steve Brown": 671,
  "Brad White": 672,
  "Jon Sumrall": 673,
  "Robert Wimberly": 674,
  "Vantz Singletary": 675,
  "Scott Symons": 676,
  "Brent Pry": 678,
  "Greg Stewart": 679,
  "James Willis": 680,
  "Melvin Smith": 681,
  "Mike Lucas": 682,
  "Patrick Toney": 683,
  "Mike Collins": 684,
  "Kim Dameron": 685,
  "Manny Michel": 686,
  "Phil Elmassian": 687,
  "Troy Reffett": 688,
  "Adam Waugh": 689,
  "Scott Stoker": 690,
  "Tom Masella": 691,
  "Randy Bates": 692,
  "George Darlington": 693,
  "Tommy Spangler": 694,
  "Blake Baker": 695,
  "Kevin Wolthausen": 697,
  "Vance Bedford": 698,
  "Cort Dennison": 699,
  "Daronte Jones": 700,
  "Steve Dunlap": 703,
  "Chris Rippon": 704,
  "J.C. Price": 705,
  "Lance Guidry": 706,
  "Todd Bradford": 708,
  "Keith Dudzinski": 709,
  "Jimmy Brumbaugh": 710,
  "Brian Williams": 711,
  "Ed Pinkham": 712,
  "Aazaar Abdul-Rahim": 713,
  "Tommy Restivo": 714,
  "Joe Lee Dunn": 716,
  "Rick Kravitz": 717,
  "Tim Walton": 718,
  "Kenny Ingram": 719,
  "Galen Scott": 720,
  "Ephraim Banda": 721,
  "Taver Johnson": 723,
  "Carl Reese": 724,
  "Matt Pawlowski": 725,
  "John Hauser": 726,
  "Spence Nowinsky": 727,
  "Mike Macdonald": 729,
  "Randall McCray": 732,
  "Steve Ellis": 733,
  "Tyrone Nix": 734,
  "David Lockwood": 736,
  "Ronnie Lee": 737,
  "Jay Sawvel": 738,
  "Joe Rossi": 739,
  "Joe Harasymiak": 740,
  "Bob Shoop": 741,
  "Zach Arnett": 742,
  "Dave Steckel": 744,
  "DeMontie Cross": 745,
  "Steve Wilks": 746,
  "Dale Pehrson": 748,
  "Brian Newberry": 749,
  "Dave Huxtable": 750,
  "Tony Gibson": 751,
  "John Papuchis": 752,
  "Mark Banker": 753,
  "Erik Chinander": 754,
  "Jeff Mills": 755,
  "Barry Sacks": 756,
  "Dave Fipp": 757,
  "Ken Wilson": 758,
  "Nigel Burton": 759,
  "Mike Bradeson": 760,
  "Bill Teerlinck": 761,
  "Scott Boone": 762,
  "Osia Lewis": 763,
  "Jordan Peterson": 764,
  "Ross Els": 765,
  "Dale Lindsey": 766,
  "Larry Coyer": 767,
  "Chuck Pagano": 771,
  "Tommy Thigpen": 772,
  "Gary DeLoach": 773,
  "Fred Bleil": 775,
  "Ron Mendoza": 776,
  "Jon Skladany": 777,
  "Jay Niemann": 779,
  "Kevin Kane": 780,
  "Jeff Knowles": 781,
  "Jim O'Neil": 783,
  "Corwin Brown": 784,
  "Kerry Cooks": 785,
  "Jim Burrow": 786,
  "Pete Germano": 787,
  "Alex Grinch": 789,
  "Kerry Coombs": 790,
  "Rich Nagy": 792,
  "Grady Brown": 793,
  "Chuck Driesbach": 795,
  "Wesley McGriff": 796,
  "Jason Jones": 797,
  "Chris Partridge": 798,
  "Don Pellum": 800,
  "Joe Salave'a": 801,
  "Keith Heyward": 802,
  "Tom Bradley": 803,
  "John Butler": 804,
  "Anthony Poindexter": 805,
  "Brock Spack": 806,
  "Donn Landholm": 807,
  "Gary Emanuel": 808,
  "Nick Holt": 809,
  "Chris Thurmond": 811,
  "Brian Smith": 812,
  "Bob Fraser": 815,
  "Dave Cohen": 816,
  "Bill Busch": 817,
  "Noah Joseph": 818,
  "Kurt Mattix": 820,
  "Derrick Odum": 824,
  "Eric Schumann": 825,
  "Van Malone": 827,
  "Kevin Sherrer": 828,
  "Travis Pearson": 829,
  "Brian Turner": 830,
  "Travaris Robinson": 831,
  "Clayton White": 832,
  "Derek Nicholson": 833,
  "Austin Armstrong": 834,
  "Ron Lynn": 835,
  "Vic Fangio": 836,
  "Jason Tarver": 837,
  "Lance Anderson": 838,
  "Steve Russ": 839,
  "Derrick Jackson": 840,
  "Chuck Bullough": 841,
  "Tony White": 842,
  "Chad Glasgow": 843,
  "Monte Kiffin": 845,
  "Sal Sunseri": 846,
  "Derrick Ansley": 847,
  "Coleman Hutzler": 848,
  "Jeff Choate": 849,
  "Archie McDaniel": 850,
  "Zac Spavital": 851,
  "Mike Smith": 852,
  "Paul Nichols": 854,
  "Tom Matukewicz": 855,
  "Vince Kehres": 856,
  "Jeremy Rowell": 857,
  "Mike Pelton": 859,
  "Brandon Hall": 860,
  "O'Neill Gilbert": 862,
  "Lionel Washington": 863,
  "Jason Rollins": 864,
  "Chris Hampton": 865,
  "Mark Nelson": 867,
  "Joseph Gillespie": 868,
  "Rick Christophel": 869,
  "Reggie Johnson": 870,
  "Duwan Walker": 871,
  "David Reeves": 872,
  "Chuck Bresnahan": 875,
  "Travis Williams": 876,
  "Lou Spanos": 878,
  "Jeff Ulbrich": 879,
  "Vincent Brown": 882,
  "Billy Crocker": 883,
  "Zac Alley": 884,
  "Kurt Barber": 885,
  "Kraig Paulson": 886,
  "J.D. Williams": 887,
  "Tim Hauck": 888,
  "Tim Skipper": 889,
  "Peter Hansen": 890,
  "Rocky Seto": 891,
  "Raymond Woodie": 892,
  "Brian Jean-Mary": 893,
  "John Pease": 894,
  "Morgan Scalley": 895,
  "David Kotulski": 896,
  "Frank Maile": 898,
  "Kendrick Shaver": 899,
  "Justin Ena": 900,
  "Stacy Collins": 901,
  "Tim Hundley": 903,
  "Andre Patterson": 904,
  "Mike Cox": 905,
  "Jess Loepp": 906,
  "Rod Wright": 907,
  "Kelly Poppinga": 911,
  "Justin Hamilton": 913,
  "Tim Billings": 917,
  "Lyle Hemphill": 918,
  "Ed Donatell": 919,
  "Ikaika Malloe": 920,
  "Jody Sears": 921,
  "Mike Breske": 922,
  "Darcel McBath": 923,
  "Jake Dickert": 924,
  "Joe DeForest": 925,
  "Jordan Lesley": 926,
  "ShaDon Brown": 927,
  "Maurice Crum Jr": 928,
  "Steve Morrison": 930,
  "Lou Esposito": 931,
  "Jim Leonhard": 932,
  "Clay Hendrix": 934,
  "Tim Horton": 935,
  "Blane Morgan": 936,
  "Mike Thiessen": 937,
  "John Latina": 940,
  "A.J. Milwee": 941,
  "Tommy Zagorski": 942,
  "Bryan Gasser": 943,
  "Charlie Stubbs": 944,
  "Les Koenning": 945,
  "David Rader": 946,
  "Joe Pendry": 947,
  "Doug Nussmeier": 948,
  "Brian Daboll": 949,
  "Josh Gattis": 950,
  "Dwayne Ledford": 951,
  "Frank Ponce": 952,
  "Tony Petersen": 953,
  "Mike Canales": 956,
  "Bill Bedenbaugh": 957,
  "Calvin Magee": 958,
  "Rod Smith": 959,
  "Noel Mazzone": 960,
  "Brennan Carroll": 961,
  "Roy Wittke": 963,
  "Rich Olson": 964,
  "Rob Likens": 965,
  "Zak Hill": 966,
  "David Lee": 967,
  "Paul Petrino": 968,
  "Jim Chaney": 969,
  "Joe Craddock": 970,
  "Kendal Briles": 971,
  "Doug Ruse": 974,
  "Rhett Lashlee": 975,
  "Bush Hamdan": 976,
  "Glen Elarbee": 977,
  "Buster Faulkner": 978,
  "Keith Heckendorf": 979,
  "John Bond": 980,
  "Tim Walsh": 982,
  "Ian Shields": 983,
  "Brent Davis": 984,
  "Al Borges": 986,
  "Steve Ensminger": 987,
  "Tony Franklin": 988,
  "Dameyune Craig": 989,
  "Kodi Burns": 990,
  "Kenny Dillingham": 991,
  "Eddie Faulkner": 995,
  "Rich Skrosky": 996,
  "Joey Lynch": 997,
  "Kevin Lynch": 998,
  "Brent Pease": 1000,
  "Lee Hays": 1001,
  "Randy Clements": 1002,
  "Glenn Thomas": 1003,
  "Jeff Nixon": 1004,
  "Jeff Grimes": 1005,
  "Robert Prince": 1006,
  "Scott Huff": 1007,
  "Eric Kiesau": 1008,
  "Tim Plough": 1009,
  "Dana Bible": 1010,
  "Gary Tranquill": 1011,
  "Kevin Rogers": 1012,
  "Todd Fitch": 1013,
  "Mike Bajakian": 1014,
  "Frank Cignetti Jr": 1015,
  "Greg Studrawa": 1017,
  "Mick McCall": 1018,
  "Warren Ruggiero": 1019,
  "Matt Mattox": 1020,
  "Sterlin Gilbert": 1021,
  "Mike Lynch": 1022,
  "Andy Padron": 1023,
  "Kevin Kilmer": 1024,
  "Terry Malone": 1025,
  "Bill Lazor": 1027,
  "Gerald Carr": 1029,
  "Greg Forest": 1030,
  "Alex Wood": 1031,
  "Andy Kotelnicki": 1032,
  "Robert Anae": 1037,
  "Brandon Doman": 1038,
  "Ty Detmer": 1039,
  "Aaron Roderick": 1040,
  "Steve Hagen": 1041,
  "Mike Dunbar": 1043,
  "Jim Michalczik": 1044,
  "Andy Ludwig": 1045,
  "Beau Baldwin": 1046,
  "Bill Musgrave": 1047,
  "Mike Cummings": 1048,
  "Morris Watts": 1049,
  "Chris Ostrowsky": 1050,
  "Charlie Frye": 1051,
  "Kevin Barbay": 1052,
  "Jeff Mullen": 1053,
  "Alex Atkins": 1054,
  "Mark Carney": 1055,
  "Rusty Burns": 1057,
  "Eddie Gran": 1058,
  "Zac Taylor": 1059,
  "Mike Denbrock": 1060,
  "Rob Spence": 1063,
  "Tony Elliott": 1064,
  "Newland Isaac": 1065,
  "Willy Korn": 1066,
  "Shawn Watson": 1067,
  "Eric Bieniemy": 1068,
  "Brian Lindgren": 1069,
  "Darrin Chiaverini": 1070,
  "Klayton Adams": 1071,
  "Jay Johnson": 1072,
  "Dave Lay": 1075,
  "Greg Peterson": 1076,
  "Pat Meyer": 1077,
  "Will Friend": 1078,
  "Dave Johnson": 1079,
  "Jon Budmayr": 1080,
  "Peter Vaas": 1082,
  "Kurt Roper": 1083,
  "Zac Roper": 1084,
  "Jeff Faris": 1085,
  "Re'quan Boyette": 1086,
  "Steve Shankweiler": 1088,
  "Dave Nichol": 1089,
  "Donnie Kirkpatrick": 1090,
  "Tim Salem": 1092,
  "Scott Isphording": 1094,
  "Ken Karcher": 1095,
  "Aaron Keen": 1096,
  "Darryl Jackson": 1097,
  "Brian Wright": 1098,
  "Travis Trickett": 1099,
  "Charlie Weis Jr": 1100,
  "Clint Trickett": 1101,
  "Drew Mehringer": 1102,
  "James Coley": 1104,
  "Bill Legg": 1105,
  "Tim Cramsey": 1106,
  "Ed Zaunbrecher": 1107,
  "Billy Gonzales": 1108,
  "John Hevesy": 1109,
  "Brian Johnson": 1110,
  "Michael Johnson": 1111,
  "Andrew Breiner": 1112,
  "Lawrence Dawsey": 1114,
  "Randy Sanders": 1115,
  "Dave Schramm": 1116,
  "Brian Schottenheimer": 1117,
  "David Dean": 1118,
  "Rance Gillespie": 1119,
  "Bryan Cook": 1120,
  "Bob DeBesse": 1121,
  "Luke Huard": 1122,
  "Brad Glenn": 1123,
  "Patrick Nix": 1125,
  "Dave Patenaude": 1126,
  "Ron Lee": 1127,
  "Tommy Lee": 1128,
  "Aaron Price": 1129,
  "Don Bailey": 1130,
  "G.J. Kinne": 1131,
  "Tony Hull": 1132,
  "Bo Graham": 1133,
  "Dave Warner": 1135,
  "Jason Phillips": 1136,
  "Mike Nesbitt": 1137,
  "Doug Meacham": 1138,
  "Travis Bush": 1139,
  "Brandon Jones": 1140,
  "Marquel Blackwell": 1141,
  "Shannon Dawson": 1142,
  "Mike Schultz": 1143,
  "Chris Beatty": 1144,
  "Kevin Johns": 1146,
  "Greg Frey": 1147,
  "Grant Heard": 1148,
  "Nick Sheridan": 1149,
  "Greg Davis": 1151,
  "Brian Ferentz": 1152,
  "Barney Cotton": 1155,
  "Robert McFarland": 1156,
  "Courtney Messingham": 1157,
  "Tom Manning": 1158,
  "Ed Warinner": 1161,
  "John Reagan": 1162,
  "Brent Dearmon": 1163,
  "Ron Hudson": 1164,
  "Del Miller": 1165,
  "Dave Brock": 1166,
  "Andre Coleman": 1167,
  "Charlie Dickey": 1168,
  "Collin Klein": 1169,
  "A.J. Pratt": 1171,
  "Brian Rock": 1172,
  "Andrew Sowder": 1173,
  "Darin Hinshaw": 1174,
  "Liam Coen": 1175,
  "Joe Dailey": 1176,
  "Kent Austin": 1177,
  "Maurice Harris": 1178,
  "Larry Edmondson": 1179,
  "Troy Wingerter": 1181,
  "Jorge Munoz": 1182,
  "Rob Sale": 1183,
  "Michael Desormeaux": 1184,
  "Tim Leger": 1185,
  "Nate Kaczor": 1189,
  "Steve Farmer": 1190,
  "Matt Kubik": 1191,
  "Eman Naghavi": 1192,
  "Conroy Hines": 1193,
  "Frank Scelfo": 1194,
  "Joe Sloan": 1195,
  "Chris Klenakis": 1197,
  "Lonnie Galloway": 1198,
  "Mike Summers": 1199,
  "Jake Peetz": 1200,
  "John Shannon": 1202,
  "Todd Goebbel": 1203,
  "Greg Adkins": 1204,
  "Charlie Taaffe": 1206,
  "Angelo Mirando": 1207,
  "Eric Price": 1208,
  "Blake Miller": 1209,
  "Brad Cornelsen": 1210,
  "Chip Long": 1211,
  "Dan Werner": 1213,
  "Thomas Brown": 1214,
  "Ron Dugans": 1215,
  "John Klacik": 1217,
  "Eric Koehler": 1218,
  "George Barnett": 1219,
  "Tim Drevno": 1220,
  "Sherrone Moore": 1221,
  "Dan Roushar": 1222,
  "Jim Bollman": 1223,
  "Brad Salem": 1224,
  "G.A. Mangus": 1225,
  "Willie Simmons": 1226,
  "Mitch Browning": 1227,
  "Jeff Horton": 1228,
  "Matt Limegrover": 1229,
  "Kirk Ciarrocca": 1230,
  "Matt Simon": 1231,
  "Luke Getsy": 1234,
  "David Yost": 1236,
  "Josh Henson": 1237,
  "Ivin Jasper": 1240,
  "Des Kitchings": 1242,
  "George McDonald": 1243,
  "Tim Beck": 1244,
  "Danny Langsdorf": 1245,
  "Troy Walters": 1246,
  "Matt Lubick": 1247,
  "Matt Mumme": 1249,
  "Dan Dodd": 1250,
  "Mike Degory": 1251,
  "David Reaves": 1252,
  "Derek Warehime": 1253,
  "Gerry Gdowski": 1254,
  "Timm Rosenbach": 1256,
  "Jerry McManus": 1257,
  "John Shoop": 1258,
  "Chris Kapilovic": 1259,
  "Gunter Brewer": 1260,
  "Phil Longo": 1261,
  "Todd Ford": 1263,
  "Graham Harrell": 1264,
  "Bodie Reeder": 1265,
  "Tommy Mainord": 1266,
  "Mike Bloesch": 1267,
  "Bob Cole": 1268,
  "Mike Uremovich": 1269,
  "Eric Eidsness": 1270,
  "Tommy Rees": 1272,
  "Greg Gregory": 1273,
  "Allen Rudolph": 1274,
  "Cale Gundy": 1276,
  "Trooper Taylor": 1277,
  "Joe Wickline": 1278,
  "Mike Yurcich": 1279,
  "Sean Gleeson": 1280,
  "Brian Scott": 1281,
  "Ron Whitcomb": 1282,
  "Kirk Campbell": 1283,
  "Mike Markuson": 1284,
  "Jeff Lebby": 1285,
  "John Garrett": 1287,
  "Kevin McGiven": 1288,
  "T.J. Woods": 1289,
  "John Donovan": 1292,
  "Tyler Bowen": 1293,
  "Brian Brohm": 1295,
  "JaMarcus Shephard": 1296,
  "Billy Lynch": 1298,
  "Jerry Mack": 1299,
  "Marques Tuiasosopo": 1300,
  "Craig Ver Steeg": 1301,
  "John McNulty": 1302,
  "Ben McDaniels": 1303,
  "Jeff Hecklinski": 1304,
  "Ken Margerum": 1309,
  "Steve Morton": 1310,
  "Tim Landis": 1311,
  "John DeFilippo": 1312,
  "Jimmie Dougherty": 1313,
  "Dan Morrison": 1314,
  "A.J. Ricker": 1315,
  "Garrett Riley": 1316,
  "Robert Matthews": 1317,
  "Bryant Vincent": 1318,
  "Richard Owens": 1319,
  "Kenny Edenfield": 1320,
  "Steve Spurrier Jr": 1321,
  "Bryan McClendon": 1322,
  "Marcus Satterfield": 1323,
  "Steve Buckley": 1324,
  "Scotty Walden": 1325,
  "Pep Hamilton": 1327,
  "Tavita Pritchard": 1328,
  "George DeLeone": 1329,
  "Brian White": 1331,
  "Nathaniel Hackett": 1332,
  "Jarrett Anderson": 1333,
  "Sonny Cumbie": 1334,
  "Curtis Luper": 1335,
  "Larry Scott": 1338,
  "Alex Golesh": 1339,
  "Darrell Wyatt": 1340,
  "Herb Hand": 1341,
  "Nolan Cromwell": 1342,
  "Clarence McKinney": 1343,
  "Jay Graham": 1344,
  "Jeff Conway": 1345,
  "Adrian Mayes": 1346,
  "Brett Elliott": 1347,
  "Jacob Peeler": 1348,
  "Eric Morris": 1349,
  "Clay McGuire": 1350,
  "Chris Hedden": 1351,
  "Mike Hallett": 1352,
  "Robert Weiner": 1353,
  "Matt Moore": 1355,
  "Sean Reagan": 1356,
  "Ryan Pugh": 1357,
  "Luke Meadows": 1358,
  "Greg Davis Jr": 1359,
  "Denver Johnson": 1362,
  "Kim Helton": 1364,
  "Brent Key": 1365,
  "Anthony Tucker": 1366,
  "Tim Harris Jr": 1367,
  "Jim Svoboda": 1372,
  "Kennedy Polamalu": 1373,
  "Justin Frye": 1374,
  "Rob Ambrose": 1377,
  "T.J. Weist": 1378,
  "Frank Verducci": 1379,
  "Frank Giufre": 1380,
  "Rob Phenicie": 1383,
  "Brent Myers": 1384,
  "Garin Justice": 1385,
  "John Morton": 1387,
  "Tee Martin": 1388,
  "Darren Hiller": 1390,
  "Kerwin Bell": 1391,
  "Jim Harding": 1392,
  "Troy Taylor": 1393,
  "Luke Wells": 1395,
  "Jovon Bouknight": 1396,
  "Patrick Higgins": 1397,
  "Bob Connelly": 1398,
  "Brian Natkin": 1399,
  "Jeff Kastl": 1401,
  "Jimmy Kiser": 1404,
  "David Raih": 1405,
  "Mike Groh": 1406,
  "Bryan Stinespring": 1407,
  "Steed Lobotzke": 1409,
  "Todd Sturdy": 1412,
  "Craig Stutzmann": 1413,
  "Chad Scott": 1414,
  "Gerad Parker": 1415,
  "Kevin Wright": 1416,
  "Walt Wells": 1417,
  "Zach Azzanni": 1418,
  "Junior Adams": 1419,
  "Bryan Ellis": 1420,
  "Ryan Aplin": 1421,
  "Zach Kittley": 1422,
  "Ryan Cubit": 1423,
  "Jake Moreland": 1424,
  "Bill Kenney": 1425,
  "Eric Evans": 1426,
  "Mike Bath": 1427,
  "Joe Rudolph": 1428,
  "Brent Vigen": 1430,
  "Tim Polasek": 1431
}

export const coachIdNameTranslator = {
  "2": "Troy Calhoun",
  "5": "Rob Ianello",
  "6": "Terry Bowden",
  "7": "Tom Arth",
  "9": "Dennis Franchione",
  "11": "Nick Saban",
  "12": "Scott Satterfield",
  "13": "Eliah Drinkwitz",
  "14": "Shawn Clark",
  "17": "Mike Stoops",
  "18": "Rich Rodriguez",
  "19": "Kevin Sumlin",
  "20": "Jedd Fisch",
  "22": "Dirk Koetter",
  "23": "Dennis Erickson",
  "24": "Todd Graham",
  "25": "Herm Edwards",
  "26": "Houston Nutt",
  "27": "Bobby Petrino",
  "28": "John L. Smith",
  "29": "Bret Bielema",
  "30": "Chad Morris",
  "31": "Sam Pittman",
  "34": "Hugh Freeze",
  "35": "Gus Malzahn",
  "36": "Bryan Harsin",
  "37": "Blake Anderson",
  "38": "Butch Jones",
  "39": "Todd Berry",
  "41": "Stan Brock",
  "42": "Rich Ellerson",
  "43": "Jeff Monken",
  "44": "Tommy Tuberville",
  "45": "Gene Chizik",
  "46": "Bill Lynch",
  "47": "Brady Hoke",
  "48": "Stan Parrish",
  "49": "Pete Lembo",
  "50": "Mike Neu",
  "51": "Kevin Steele",
  "52": "Guy Morriss",
  "53": "Art Briles",
  "54": "Jim Grobe",
  "55": "Matt Rhule",
  "56": "Dave Aranda",
  "57": "Dan Hawkins",
  "58": "Chris Petersen",
  "59": "Andy Avalos",
  "60": "Tom O'Brien",
  "61": "Jeff Jagodzinski",
  "62": "Frank Spaziani",
  "63": "Steve Addazio",
  "64": "Jeff Hafley",
  "66": "Urban Meyer",
  "67": "Gregg Brandon",
  "68": "Dave Clawson",
  "69": "Dino Babers",
  "70": "Mike Jinks",
  "71": "Scot Loeffler",
  "74": "Turner Gill",
  "75": "Jeff Quinn",
  "76": "Lance Leipold",
  "77": "Maurice Linguist",
  "79": "Gary Crowton",
  "80": "Bronco Mendenhall",
  "81": "Kalani Sitake",
  "83": "Jeff Tedford",
  "84": "Sonny Dykes",
  "85": "Justin Wilcox",
  "86": "Mike DeBord",
  "87": "Brian Kelly",
  "88": "Dan Enos",
  "89": "John Bonamego",
  "90": "Jim McElwain",
  "91": "Brad Lambert",
  "92": "Will Healy",
  "93": "Rick Minter",
  "94": "Mark Dantonio",
  "95": "Luke Fickell",
  "97": "Dabo Swinney",
  "99": "Jamey Chadwell",
  "101": "Jon Embree",
  "102": "Mike MacIntyre",
  "103": "Mel Tucker",
  "104": "Karl Dorrell",
  "106": "Steve Fairchild",
  "107": "Mike Bobo",
  "109": "Ted Roof",
  "110": "David Cutcliffe",
  "111": "Steve Logan",
  "112": "John Thompson",
  "113": "Skip Holtz",
  "114": "Ruffin McNeill",
  "115": "Scottie Montgomery",
  "116": "Mike Houston",
  "119": "Ron English",
  "120": "Chris Creighton",
  "122": "Carl Pelini",
  "123": "Charlie Partridge",
  "124": "Lane Kiffin",
  "125": "Willie Taggart",
  "127": "Mario Cristobal",
  "128": "Ron Turner",
  "129": "Butch Davis",
  "130": "Steve Spurrier",
  "132": "Will Muschamp",
  "133": "Dan Mullen",
  "135": "Jimbo Fisher",
  "136": "Mike Norvell",
  "138": "Tim DeRuyter",
  "139": "Kalen DeBoer",
  "141": "Mark Richt",
  "142": "Kirby Smart",
  "143": "Willie Fritz",
  "144": "Tyson Summers",
  "145": "Chad Lunsford",
  "146": "Trent Miles",
  "147": "Shawn Elliott",
  "150": "Paul Johnson",
  "151": "Geoff Collins",
  "152": "June Jones",
  "153": "Greg McMackin",
  "154": "Norm Chow",
  "155": "Nick Rolovich",
  "156": "Dana Dimel",
  "157": "Tony Levine",
  "158": "Tom Herman",
  "159": "Major Applewhite",
  "160": "Dana Holgorsen",
  "161": "Tim Beckman",
  "162": "Bill Cubit",
  "163": "Lovie Smith",
  "164": "Cam Cameron",
  "167": "Kevin Wilson",
  "168": "Tom Allen",
  "170": "Dan McCarney",
  "171": "Paul Rhoads",
  "172": "Matt Campbell",
  "174": "Mark Mangino",
  "175": "Charlie Weis",
  "176": "David Beaty",
  "177": "Les Miles",
  "179": "Ron Prince",
  "180": "Chris Klieman",
  "182": "Doug Martin",
  "183": "Darrell Hazell",
  "184": "Paul Haynes",
  "185": "Sean Lewis",
  "186": "Hal Mumme",
  "188": "Joker Phillips",
  "189": "Mark Stoops",
  "192": "Mark Hudspeth",
  "193": "Billy Napier",
  "196": "Matt Viator",
  "198": "Derek Dooley",
  "199": "Steve Kragthorpe",
  "200": "Charlie Strong",
  "201": "Ed Orgeron",
  "202": "Bob Pruett",
  "203": "Mark Snyder",
  "204": "Doc Holliday",
  "205": "Charles Huff",
  "207": "Ralph Friedgen",
  "208": "Randy Edsall",
  "209": "D.J. Durkin",
  "210": "Matt Canada",
  "211": "Mike Locksley",
  "212": "Charley Molnar",
  "213": "Mark Whipple",
  "214": "Walt Bell",
  "216": "Tommy West",
  "217": "Larry Porter",
  "218": "Justin Fuente",
  "219": "Ryan Silverfield",
  "221": "Randy Shannon",
  "222": "Al Golden",
  "223": "Manny Diaz",
  "224": "Shane Montgomery",
  "225": "Michael Haywood",
  "226": "Don Treadwell",
  "227": "Chuck Martin",
  "229": "Jim Harbaugh",
  "232": "Rick Stockstill",
  "234": "Tim Brewster",
  "235": "Jerry Kill",
  "236": "Tracy Claeys",
  "237": "P.J. Fleck",
  "240": "Joe Moorhead",
  "241": "Mike Leach",
  "244": "Barry Odom",
  "245": "Ken Niumatalolo",
  "246": "Chuck Amato",
  "247": "Dave Doeren",
  "250": "Bo Pelini",
  "251": "Mike Riley",
  "252": "Scott Frost",
  "253": "Chris Tormey",
  "255": "Brian Polian",
  "256": "Jay Norvell",
  "257": "Rocky Long",
  "258": "Bob Davie",
  "259": "Danny Gonzales",
  "261": "DeWayne Walker",
  "262": "Carl Torbush",
  "264": "Everett Withers",
  "265": "Larry Fedora",
  "266": "Mack Brown",
  "267": "Darrell Dickey",
  "268": "Todd Dodge",
  "269": "Seth Littrell",
  "271": "Rod Carey",
  "272": "Thomas Hammock",
  "274": "Pat Fitzgerald",
  "276": "Brian Knorr",
  "277": "Tim Albin",
  "280": "Ryan Day",
  "282": "Lincoln Riley",
  "285": "Bobby Wilder",
  "286": "Ricky Rahne",
  "287": "Matt Luke",
  "289": "Chip Kelly",
  "290": "Mark Helfrich",
  "291": "Gary Andersen",
  "292": "Jonathan Smith",
  "294": "Bill O'Brien",
  "295": "James Franklin",
  "298": "Paul Chryst",
  "299": "Pat Narduzzi",
  "301": "Danny Hope",
  "302": "Jeff Brohm",
  "304": "David Bailiff",
  "305": "Mike Bloomgren",
  "307": "Greg Schiano",
  "308": "Kyle Flood",
  "309": "Chris Ash",
  "312": "Chuck Long",
  "313": "Dave Baldwin",
  "315": "Ron Caragher",
  "316": "Brent Brennan",
  "318": "Phil Bennett",
  "319": "Joey Jones",
  "320": "Steve Campbell",
  "321": "Kane Wommack",
  "323": "Shane Beamer",
  "324": "Jeff Scott",
  "326": "Ellis Johnson",
  "327": "Todd Monken",
  "328": "Jay Hopson",
  "329": "Will Hall",
  "331": "David Shaw",
  "332": "Paul Pasqualoni",
  "333": "Greg Robinson",
  "334": "Doug Marrone",
  "335": "Scott Shafer",
  "339": "Jeremy Pruitt",
  "340": "Josh Heupel",
  "341": "Steve Sarkisian",
  "343": "Mike Sherman",
  "344": "Jake Spavital",
  "345": "Kliff Kingsbury",
  "346": "Matt Wells",
  "348": "Jason Candle",
  "350": "Neal Brown",
  "351": "Chip Lindsey",
  "353": "Bob Toledo",
  "354": "Curtis Johnson",
  "355": "Keith Burns",
  "356": "Bill Blankenship",
  "357": "Philip Montgomery",
  "359": "Neil Callaway",
  "360": "Garrick McGee",
  "361": "Bill Clark",
  "362": "Mike Kruczek",
  "363": "Rick Neuheisel",
  "364": "Jim L. Mora",
  "365": "Bob Diaco",
  "367": "Mike Sanford Sr",
  "368": "Bobby Hauck",
  "369": "Tony Sanchez",
  "370": "Marcus Arroyo",
  "373": "Clay Helton",
  "374": "Jim Leavitt",
  "378": "Brent Guy",
  "379": "Gary Nord",
  "381": "Sean Kugler",
  "382": "Frank Wilson",
  "383": "Jeff Traylor",
  "386": "Robbie Caldwell",
  "387": "Derek Mason",
  "388": "Clark Lea",
  "390": "Al Groh",
  "391": "Mike London",
  "395": "Jimmy Lake",
  "396": "Bill Doba",
  "397": "Paul Wulff",
  "399": "Bill Stewart",
  "400": "David Elson",
  "401": "Mike Sanford Jr",
  "402": "Tyson Helton",
  "403": "Gary Darnell",
  "404": "Tim Lester",
  "406": "Vic Koenning",
  "408": "Dave Christensen",
  "409": "Craig Bohl",
  "411": "John Rudzinski",
  "413": "Joe Tresey",
  "414": "Jim Fleming",
  "415": "Curt Mallory",
  "416": "Kevin Cosgrove",
  "417": "Matt Feeney",
  "418": "Joe Kines",
  "419": "Tosh Lupoi",
  "420": "Pete Golding",
  "421": "Charles Kelly",
  "422": "Nate Woody",
  "423": "Scot Sloan",
  "424": "Bryan Brown",
  "425": "Dale Jones",
  "427": "Larry Mac Duff",
  "428": "Greg Brown",
  "429": "Tim Kish",
  "430": "Jeff Casteel",
  "431": "Marcel Yates",
  "432": "Don Brown",
  "433": "Phil Snow",
  "434": "Bill Miller",
  "435": "Craig Bray",
  "436": "Paul Randolph",
  "437": "Ron West",
  "438": "Chris Ball",
  "439": "Keith Patterson",
  "440": "Marvin Lewis",
  "442": "Dave Wommack",
  "444": "Willy Robinson",
  "445": "Robb Smith",
  "446": "John Chavis",
  "448": "Jack Curtis",
  "450": "Joe Cauthen",
  "451": "David Duggan",
  "452": "Dennis Therrell",
  "454": "Chris Smeland",
  "455": "Payam Saadat",
  "456": "Jay Bateman",
  "457": "John Loose",
  "458": "Greg Gasparato",
  "459": "Shiel Wood",
  "460": "John Lovett",
  "461": "David Gibbs",
  "462": "Brian VanGorder",
  "463": "Charlie Harbison",
  "466": "Doug Graber",
  "467": "Jay Hood",
  "468": "Kevin Kelly",
  "469": "Tim Daoust",
  "470": "Tyler Stockton",
  "472": "Bob Trott",
  "474": "Larry Hoefer",
  "475": "Brian Norwood",
  "476": "Ron Roberts",
  "477": "Bob Gregory",
  "478": "Ron Collins",
  "479": "Pete Kwiatkowski",
  "480": "Jeff Schmedding",
  "481": "Spencer Danielson",
  "482": "Kane Ioane",
  "483": "Bill McGovern",
  "484": "Jim Reid",
  "485": "Anthony Campanile",
  "486": "Tem Lukabu",
  "487": "Mike Ward",
  "488": "Mike Elko",
  "489": "Shannon Morrison",
  "490": "Kim McCloud",
  "491": "Brian Ward",
  "492": "Marcus White",
  "493": "Perry Eliano",
  "494": "Eric Lewis",
  "498": "Jimmy Williams",
  "499": "Fred Reed",
  "500": "William Inge",
  "501": "Lou Tepper",
  "502": "Brian Borland",
  "503": "Chris Simpson",
  "505": "Jaime Hill",
  "506": "Nick Howell",
  "507": "Ilaisa Tuiaki",
  "509": "Clancy Pendergast",
  "510": "Andy Buh",
  "511": "Art Kaufman",
  "512": "Peter Sirmon",
  "515": "John Jancek",
  "517": "Tim Banks",
  "518": "Joe Tumpkin",
  "519": "Greg Colby",
  "520": "Robb Akey",
  "521": "Matt Wallerstedt",
  "522": "Chip West",
  "523": "Glenn Spencer",
  "524": "Brandon Cooper",
  "525": "Marcus West",
  "526": "Rick Smith",
  "530": "Hank Hughes",
  "531": "Robert Prunty",
  "532": "Steve Clinkscale",
  "533": "Marcus Freeman",
  "534": "Mike Tressel",
  "535": "Brent Venables",
  "536": "Marion Hobby",
  "538": "Marvin Sanders",
  "539": "Chad Staggs",
  "541": "Vince Okruch",
  "542": "Mike Hankwitz",
  "543": "Kent Baer",
  "544": "D.J. Eliot",
  "545": "Chris Wilson",
  "547": "Steve Stanard",
  "548": "Al Simmons",
  "549": "Marty English",
  "550": "Chuck Heater",
  "551": "Jerry Azzinaro",
  "553": "Jim Knowles",
  "554": "Ben Albert",
  "555": "Matt Guerrieri",
  "557": "Greg Hudson",
  "558": "Brian Mitchell",
  "559": "Kenwick Thompson",
  "560": "David Blackwell",
  "561": "Blake Harrell",
  "563": "Jay Peterson",
  "564": "Brad McCaslin",
  "565": "Neal Neathery",
  "567": "Kurt Van Valkenburgh",
  "568": "Pete Rekstis",
  "569": "Roc Bellantoni",
  "570": "Chris Kiffin",
  "571": "Tony Pecoraro",
  "573": "Kevin Fouquier",
  "574": "Phil Galiano",
  "575": "Todd Orlando",
  "576": "Josh Conklin",
  "577": "Matt House",
  "578": "Ron Cooper",
  "579": "Jeff Copp",
  "580": "Jerod Kruse",
  "581": "Jon Hoke",
  "582": "Greg Mattison",
  "583": "Teryl Austin",
  "584": "Dan Quinn",
  "585": "Chris Rumph",
  "586": "Todd Grantham",
  "588": "Harlon Barnett",
  "589": "Adam Fuller",
  "592": "Randy Stewart",
  "593": "Nick Toth",
  "594": "Lorenzo Ward",
  "595": "Orlondo Steinauer",
  "596": "Bert Watts",
  "599": "Dan Lanning",
  "600": "Glenn Schumann",
  "601": "Lorenzo Costantini",
  "602": "Jesse Minter",
  "603": "Nate Fuqua",
  "604": "Jon Tenuta",
  "605": "Andrew Thacker",
  "606": "Nathan Burton",
  "610": "Cal Lee",
  "611": "Thom Kaumeyer",
  "612": "Kevin Clune",
  "613": "Tom Mason",
  "614": "Legi Suiaunoa",
  "615": "Corey Batoon",
  "616": "Jacob Yoro",
  "617": "Victor Santa Cruz",
  "619": "Bradley Dale Peveto",
  "621": "Alan Weddell",
  "622": "John Skladany",
  "623": "Brian Stewart",
  "624": "Jamie Bryant",
  "625": "Craig Naivar",
  "626": "Clay Jennings",
  "627": "Mark D'Onofrio",
  "628": "Doug Belk",
  "629": "Mike Cassity",
  "631": "Dan Disch",
  "632": "Mike Phair",
  "633": "Hardy Nickerson",
  "634": "Ryan Walters",
  "637": "Brian George",
  "638": "Joe Palcic",
  "639": "Doug Mallory",
  "640": "Mike Ekeler",
  "641": "Mark Hagen",
  "642": "Kevin Peoples",
  "643": "Charlton Warren",
  "644": "Norm Parker",
  "645": "Phil Parker",
  "646": "Wayne Bolt",
  "647": "Wally Burnham",
  "648": "Jon Heacock",
  "650": "Tom Hayes",
  "651": "Bill Young",
  "652": "Clint Bowen",
  "653": "Buddy Wyatt",
  "654": "Vic Shealy",
  "655": "Dave Campo",
  "656": "Kenny Perry",
  "657": "Bob Elliott",
  "658": "Raheem Morris",
  "659": "Tim Tibesar",
  "660": "Chris Cosh",
  "661": "Blake Seiler",
  "662": "Scottie Hazelton",
  "663": "Joe Klanderman",
  "664": "Mike Dietzel",
  "665": "Ben Needham",
  "666": "Jeff Burrow",
  "667": "Tom Kaufman",
  "670": "Mike Archer",
  "671": "Steve Brown",
  "672": "Brad White",
  "673": "Jon Sumrall",
  "674": "Robert Wimberly",
  "675": "Vantz Singletary",
  "676": "Scott Symons",
  "678": "Brent Pry",
  "679": "Greg Stewart",
  "680": "James Willis",
  "681": "Melvin Smith",
  "682": "Mike Lucas",
  "683": "Patrick Toney",
  "684": "Mike Collins",
  "685": "Kim Dameron",
  "686": "Manny Michel",
  "687": "Phil Elmassian",
  "688": "Troy Reffett",
  "689": "Adam Waugh",
  "690": "Scott Stoker",
  "691": "Tom Masella",
  "692": "Randy Bates",
  "693": "George Darlington",
  "694": "Tommy Spangler",
  "695": "Blake Baker",
  "697": "Kevin Wolthausen",
  "698": "Vance Bedford",
  "699": "Cort Dennison",
  "700": "Daronte Jones",
  "703": "Steve Dunlap",
  "704": "Chris Rippon",
  "705": "J.C. Price",
  "706": "Lance Guidry",
  "708": "Todd Bradford",
  "709": "Keith Dudzinski",
  "710": "Jimmy Brumbaugh",
  "711": "Brian Williams",
  "712": "Ed Pinkham",
  "713": "Aazaar Abdul-Rahim",
  "714": "Tommy Restivo",
  "716": "Joe Lee Dunn",
  "717": "Rick Kravitz",
  "718": "Tim Walton",
  "719": "Kenny Ingram",
  "720": "Galen Scott",
  "721": "Ephraim Banda",
  "723": "Taver Johnson",
  "724": "Carl Reese",
  "725": "Matt Pawlowski",
  "726": "John Hauser",
  "727": "Spence Nowinsky",
  "729": "Mike Macdonald",
  "732": "Randall McCray",
  "733": "Steve Ellis",
  "734": "Tyrone Nix",
  "736": "David Lockwood",
  "737": "Ronnie Lee",
  "738": "Jay Sawvel",
  "739": "Joe Rossi",
  "740": "Joe Harasymiak",
  "741": "Bob Shoop",
  "742": "Zach Arnett",
  "744": "Dave Steckel",
  "745": "DeMontie Cross",
  "746": "Steve Wilks",
  "748": "Dale Pehrson",
  "749": "Brian Newberry",
  "750": "Dave Huxtable",
  "751": "Tony Gibson",
  "752": "John Papuchis",
  "753": "Mark Banker",
  "754": "Erik Chinander",
  "755": "Jeff Mills",
  "756": "Barry Sacks",
  "757": "Dave Fipp",
  "758": "Ken Wilson",
  "759": "Nigel Burton",
  "760": "Mike Bradeson",
  "761": "Bill Teerlinck",
  "762": "Scott Boone",
  "763": "Osia Lewis",
  "764": "Jordan Peterson",
  "765": "Ross Els",
  "766": "Dale Lindsey",
  "767": "Larry Coyer",
  "771": "Chuck Pagano",
  "772": "Tommy Thigpen",
  "773": "Gary DeLoach",
  "775": "Fred Bleil",
  "776": "Ron Mendoza",
  "777": "Jon Skladany",
  "779": "Jay Niemann",
  "780": "Kevin Kane",
  "781": "Jeff Knowles",
  "783": "Jim O'Neil",
  "784": "Corwin Brown",
  "785": "Kerry Cooks",
  "786": "Jim Burrow",
  "787": "Pete Germano",
  "789": "Alex Grinch",
  "790": "Kerry Coombs",
  "792": "Rich Nagy",
  "793": "Grady Brown",
  "795": "Chuck Driesbach",
  "796": "Wesley McGriff",
  "797": "Jason Jones",
  "798": "Chris Partridge",
  "800": "Don Pellum",
  "801": "Joe Salave'a",
  "802": "Keith Heyward",
  "803": "Tom Bradley",
  "804": "John Butler",
  "805": "Anthony Poindexter",
  "806": "Brock Spack",
  "807": "Donn Landholm",
  "808": "Gary Emanuel",
  "809": "Nick Holt",
  "811": "Chris Thurmond",
  "812": "Brian Smith",
  "815": "Bob Fraser",
  "816": "Dave Cohen",
  "817": "Bill Busch",
  "818": "Noah Joseph",
  "820": "Kurt Mattix",
  "824": "Derrick Odum",
  "825": "Eric Schumann",
  "827": "Van Malone",
  "828": "Kevin Sherrer",
  "829": "Travis Pearson",
  "830": "Brian Turner",
  "831": "Travaris Robinson",
  "832": "Clayton White",
  "833": "Derek Nicholson",
  "834": "Austin Armstrong",
  "835": "Ron Lynn",
  "836": "Vic Fangio",
  "837": "Jason Tarver",
  "838": "Lance Anderson",
  "839": "Steve Russ",
  "840": "Derrick Jackson",
  "841": "Chuck Bullough",
  "842": "Tony White",
  "843": "Chad Glasgow",
  "845": "Monte Kiffin",
  "846": "Sal Sunseri",
  "847": "Derrick Ansley",
  "848": "Coleman Hutzler",
  "849": "Jeff Choate",
  "850": "Archie McDaniel",
  "851": "Zac Spavital",
  "852": "Mike Smith",
  "854": "Paul Nichols",
  "855": "Tom Matukewicz",
  "856": "Vince Kehres",
  "857": "Jeremy Rowell",
  "859": "Mike Pelton",
  "860": "Brandon Hall",
  "862": "O'Neill Gilbert",
  "863": "Lionel Washington",
  "864": "Jason Rollins",
  "865": "Chris Hampton",
  "867": "Mark Nelson",
  "868": "Joseph Gillespie",
  "869": "Rick Christophel",
  "870": "Reggie Johnson",
  "871": "Duwan Walker",
  "872": "David Reeves",
  "875": "Chuck Bresnahan",
  "876": "Travis Williams",
  "878": "Lou Spanos",
  "879": "Jeff Ulbrich",
  "882": "Vincent Brown",
  "883": "Billy Crocker",
  "884": "Zac Alley",
  "885": "Kurt Barber",
  "886": "Kraig Paulson",
  "887": "J.D. Williams",
  "888": "Tim Hauck",
  "889": "Tim Skipper",
  "890": "Peter Hansen",
  "891": "Rocky Seto",
  "892": "Raymond Woodie",
  "893": "Brian Jean-Mary",
  "894": "John Pease",
  "895": "Morgan Scalley",
  "896": "David Kotulski",
  "898": "Frank Maile",
  "899": "Kendrick Shaver",
  "900": "Justin Ena",
  "901": "Stacy Collins",
  "903": "Tim Hundley",
  "904": "Andre Patterson",
  "905": "Mike Cox",
  "906": "Jess Loepp",
  "907": "Rod Wright",
  "911": "Kelly Poppinga",
  "913": "Justin Hamilton",
  "917": "Tim Billings",
  "918": "Lyle Hemphill",
  "919": "Ed Donatell",
  "920": "Ikaika Malloe",
  "921": "Jody Sears",
  "922": "Mike Breske",
  "923": "Darcel McBath",
  "924": "Jake Dickert",
  "925": "Joe DeForest",
  "926": "Jordan Lesley",
  "927": "ShaDon Brown",
  "928": "Maurice Crum Jr",
  "930": "Steve Morrison",
  "931": "Lou Esposito",
  "932": "Jim Leonhard",
  "934": "Clay Hendrix",
  "935": "Tim Horton",
  "936": "Blane Morgan",
  "937": "Mike Thiessen",
  "940": "John Latina",
  "941": "A.J. Milwee",
  "942": "Tommy Zagorski",
  "943": "Bryan Gasser",
  "944": "Charlie Stubbs",
  "945": "Les Koenning",
  "946": "David Rader",
  "947": "Joe Pendry",
  "948": "Doug Nussmeier",
  "949": "Brian Daboll",
  "950": "Josh Gattis",
  "951": "Dwayne Ledford",
  "952": "Frank Ponce",
  "953": "Tony Petersen",
  "956": "Mike Canales",
  "957": "Bill Bedenbaugh",
  "958": "Calvin Magee",
  "959": "Rod Smith",
  "960": "Noel Mazzone",
  "961": "Brennan Carroll",
  "963": "Roy Wittke",
  "964": "Rich Olson",
  "965": "Rob Likens",
  "966": "Zak Hill",
  "967": "David Lee",
  "968": "Paul Petrino",
  "969": "Jim Chaney",
  "970": "Joe Craddock",
  "971": "Kendal Briles",
  "974": "Doug Ruse",
  "975": "Rhett Lashlee",
  "976": "Bush Hamdan",
  "977": "Glen Elarbee",
  "978": "Buster Faulkner",
  "979": "Keith Heckendorf",
  "980": "John Bond",
  "982": "Tim Walsh",
  "983": "Ian Shields",
  "984": "Brent Davis",
  "986": "Al Borges",
  "987": "Steve Ensminger",
  "988": "Tony Franklin",
  "989": "Dameyune Craig",
  "990": "Kodi Burns",
  "991": "Kenny Dillingham",
  "995": "Eddie Faulkner",
  "996": "Rich Skrosky",
  "997": "Joey Lynch",
  "998": "Kevin Lynch",
  "1000": "Brent Pease",
  "1001": "Lee Hays",
  "1002": "Randy Clements",
  "1003": "Glenn Thomas",
  "1004": "Jeff Nixon",
  "1005": "Jeff Grimes",
  "1006": "Robert Prince",
  "1007": "Scott Huff",
  "1008": "Eric Kiesau",
  "1009": "Tim Plough",
  "1010": "Dana Bible",
  "1011": "Gary Tranquill",
  "1012": "Kevin Rogers",
  "1013": "Todd Fitch",
  "1014": "Mike Bajakian",
  "1015": "Frank Cignetti Jr",
  "1017": "Greg Studrawa",
  "1018": "Mick McCall",
  "1019": "Warren Ruggiero",
  "1020": "Matt Mattox",
  "1021": "Sterlin Gilbert",
  "1022": "Mike Lynch",
  "1023": "Andy Padron",
  "1024": "Kevin Kilmer",
  "1025": "Terry Malone",
  "1027": "Bill Lazor",
  "1029": "Gerald Carr",
  "1030": "Greg Forest",
  "1031": "Alex Wood",
  "1032": "Andy Kotelnicki",
  "1037": "Robert Anae",
  "1038": "Brandon Doman",
  "1039": "Ty Detmer",
  "1040": "Aaron Roderick",
  "1041": "Steve Hagen",
  "1043": "Mike Dunbar",
  "1044": "Jim Michalczik",
  "1045": "Andy Ludwig",
  "1046": "Beau Baldwin",
  "1047": "Bill Musgrave",
  "1048": "Mike Cummings",
  "1049": "Morris Watts",
  "1050": "Chris Ostrowsky",
  "1051": "Charlie Frye",
  "1052": "Kevin Barbay",
  "1053": "Jeff Mullen",
  "1054": "Alex Atkins",
  "1055": "Mark Carney",
  "1057": "Rusty Burns",
  "1058": "Eddie Gran",
  "1059": "Zac Taylor",
  "1060": "Mike Denbrock",
  "1063": "Rob Spence",
  "1064": "Tony Elliott",
  "1065": "Newland Isaac",
  "1066": "Willy Korn",
  "1067": "Shawn Watson",
  "1068": "Eric Bieniemy",
  "1069": "Brian Lindgren",
  "1070": "Darrin Chiaverini",
  "1071": "Klayton Adams",
  "1072": "Jay Johnson",
  "1075": "Dave Lay",
  "1076": "Greg Peterson",
  "1077": "Pat Meyer",
  "1078": "Will Friend",
  "1079": "Dave Johnson",
  "1080": "Jon Budmayr",
  "1082": "Peter Vaas",
  "1083": "Kurt Roper",
  "1084": "Zac Roper",
  "1085": "Jeff Faris",
  "1086": "Re'quan Boyette",
  "1088": "Steve Shankweiler",
  "1089": "Dave Nichol",
  "1090": "Donnie Kirkpatrick",
  "1092": "Tim Salem",
  "1094": "Scott Isphording",
  "1095": "Ken Karcher",
  "1096": "Aaron Keen",
  "1097": "Darryl Jackson",
  "1098": "Brian Wright",
  "1099": "Travis Trickett",
  "1100": "Charlie Weis Jr",
  "1101": "Clint Trickett",
  "1102": "Drew Mehringer",
  "1104": "James Coley",
  "1105": "Bill Legg",
  "1106": "Tim Cramsey",
  "1107": "Ed Zaunbrecher",
  "1108": "Billy Gonzales",
  "1109": "John Hevesy",
  "1110": "Brian Johnson",
  "1111": "Michael Johnson",
  "1112": "Andrew Breiner",
  "1114": "Lawrence Dawsey",
  "1115": "Randy Sanders",
  "1116": "Dave Schramm",
  "1117": "Brian Schottenheimer",
  "1118": "David Dean",
  "1119": "Rance Gillespie",
  "1120": "Bryan Cook",
  "1121": "Bob DeBesse",
  "1122": "Luke Huard",
  "1123": "Brad Glenn",
  "1125": "Patrick Nix",
  "1126": "Dave Patenaude",
  "1127": "Ron Lee",
  "1128": "Tommy Lee",
  "1129": "Aaron Price",
  "1130": "Don Bailey",
  "1131": "G.J. Kinne",
  "1132": "Tony Hull",
  "1133": "Bo Graham",
  "1135": "Dave Warner",
  "1136": "Jason Phillips",
  "1137": "Mike Nesbitt",
  "1138": "Doug Meacham",
  "1139": "Travis Bush",
  "1140": "Brandon Jones",
  "1141": "Marquel Blackwell",
  "1142": "Shannon Dawson",
  "1143": "Mike Schultz",
  "1144": "Chris Beatty",
  "1146": "Kevin Johns",
  "1147": "Greg Frey",
  "1148": "Grant Heard",
  "1149": "Nick Sheridan",
  "1151": "Greg Davis",
  "1152": "Brian Ferentz",
  "1155": "Barney Cotton",
  "1156": "Robert McFarland",
  "1157": "Courtney Messingham",
  "1158": "Tom Manning",
  "1161": "Ed Warinner",
  "1162": "John Reagan",
  "1163": "Brent Dearmon",
  "1164": "Ron Hudson",
  "1165": "Del Miller",
  "1166": "Dave Brock",
  "1167": "Andre Coleman",
  "1168": "Charlie Dickey",
  "1169": "Collin Klein",
  "1171": "A.J. Pratt",
  "1172": "Brian Rock",
  "1173": "Andrew Sowder",
  "1174": "Darin Hinshaw",
  "1175": "Liam Coen",
  "1176": "Joe Dailey",
  "1177": "Kent Austin",
  "1178": "Maurice Harris",
  "1179": "Larry Edmondson",
  "1181": "Troy Wingerter",
  "1182": "Jorge Munoz",
  "1183": "Rob Sale",
  "1184": "Michael Desormeaux",
  "1185": "Tim Leger",
  "1189": "Nate Kaczor",
  "1190": "Steve Farmer",
  "1191": "Matt Kubik",
  "1192": "Eman Naghavi",
  "1193": "Conroy Hines",
  "1194": "Frank Scelfo",
  "1195": "Joe Sloan",
  "1197": "Chris Klenakis",
  "1198": "Lonnie Galloway",
  "1199": "Mike Summers",
  "1200": "Jake Peetz",
  "1202": "John Shannon",
  "1203": "Todd Goebbel",
  "1204": "Greg Adkins",
  "1206": "Charlie Taaffe",
  "1207": "Angelo Mirando",
  "1208": "Eric Price",
  "1209": "Blake Miller",
  "1210": "Brad Cornelsen",
  "1211": "Chip Long",
  "1213": "Dan Werner",
  "1214": "Thomas Brown",
  "1215": "Ron Dugans",
  "1217": "John Klacik",
  "1218": "Eric Koehler",
  "1219": "George Barnett",
  "1220": "Tim Drevno",
  "1221": "Sherrone Moore",
  "1222": "Dan Roushar",
  "1223": "Jim Bollman",
  "1224": "Brad Salem",
  "1225": "G.A. Mangus",
  "1226": "Willie Simmons",
  "1227": "Mitch Browning",
  "1228": "Jeff Horton",
  "1229": "Matt Limegrover",
  "1230": "Kirk Ciarrocca",
  "1231": "Matt Simon",
  "1234": "Luke Getsy",
  "1236": "David Yost",
  "1237": "Josh Henson",
  "1240": "Ivin Jasper",
  "1242": "Des Kitchings",
  "1243": "George McDonald",
  "1244": "Tim Beck",
  "1245": "Danny Langsdorf",
  "1246": "Troy Walters",
  "1247": "Matt Lubick",
  "1249": "Matt Mumme",
  "1250": "Dan Dodd",
  "1251": "Mike Degory",
  "1252": "David Reaves",
  "1253": "Derek Warehime",
  "1254": "Gerry Gdowski",
  "1256": "Timm Rosenbach",
  "1257": "Jerry McManus",
  "1258": "John Shoop",
  "1259": "Chris Kapilovic",
  "1260": "Gunter Brewer",
  "1261": "Phil Longo",
  "1263": "Todd Ford",
  "1264": "Graham Harrell",
  "1265": "Bodie Reeder",
  "1266": "Tommy Mainord",
  "1267": "Mike Bloesch",
  "1268": "Bob Cole",
  "1269": "Mike Uremovich",
  "1270": "Eric Eidsness",
  "1272": "Tommy Rees",
  "1273": "Greg Gregory",
  "1274": "Allen Rudolph",
  "1276": "Cale Gundy",
  "1277": "Trooper Taylor",
  "1278": "Joe Wickline",
  "1279": "Mike Yurcich",
  "1280": "Sean Gleeson",
  "1281": "Brian Scott",
  "1282": "Ron Whitcomb",
  "1283": "Kirk Campbell",
  "1284": "Mike Markuson",
  "1285": "Jeff Lebby",
  "1287": "John Garrett",
  "1288": "Kevin McGiven",
  "1289": "T.J. Woods",
  "1292": "John Donovan",
  "1293": "Tyler Bowen",
  "1295": "Brian Brohm",
  "1296": "JaMarcus Shephard",
  "1298": "Billy Lynch",
  "1299": "Jerry Mack",
  "1300": "Marques Tuiasosopo",
  "1301": "Craig Ver Steeg",
  "1302": "John McNulty",
  "1303": "Ben McDaniels",
  "1304": "Jeff Hecklinski",
  "1309": "Ken Margerum",
  "1310": "Steve Morton",
  "1311": "Tim Landis",
  "1312": "John DeFilippo",
  "1313": "Jimmie Dougherty",
  "1314": "Dan Morrison",
  "1315": "A.J. Ricker",
  "1316": "Garrett Riley",
  "1317": "Robert Matthews",
  "1318": "Bryant Vincent",
  "1319": "Richard Owens",
  "1320": "Kenny Edenfield",
  "1321": "Steve Spurrier Jr",
  "1322": "Bryan McClendon",
  "1323": "Marcus Satterfield",
  "1324": "Steve Buckley",
  "1325": "Scotty Walden",
  "1327": "Pep Hamilton",
  "1328": "Tavita Pritchard",
  "1329": "George DeLeone",
  "1331": "Brian White",
  "1332": "Nathaniel Hackett",
  "1333": "Jarrett Anderson",
  "1334": "Sonny Cumbie",
  "1335": "Curtis Luper",
  "1338": "Larry Scott",
  "1339": "Alex Golesh",
  "1340": "Darrell Wyatt",
  "1341": "Herb Hand",
  "1342": "Nolan Cromwell",
  "1343": "Clarence McKinney",
  "1344": "Jay Graham",
  "1345": "Jeff Conway",
  "1346": "Adrian Mayes",
  "1347": "Brett Elliott",
  "1348": "Jacob Peeler",
  "1349": "Eric Morris",
  "1350": "Clay McGuire",
  "1351": "Chris Hedden",
  "1352": "Mike Hallett",
  "1353": "Robert Weiner",
  "1355": "Matt Moore",
  "1356": "Sean Reagan",
  "1357": "Ryan Pugh",
  "1358": "Luke Meadows",
  "1359": "Greg Davis Jr",
  "1362": "Denver Johnson",
  "1364": "Kim Helton",
  "1365": "Brent Key",
  "1366": "Anthony Tucker",
  "1367": "Tim Harris Jr",
  "1372": "Jim Svoboda",
  "1373": "Kennedy Polamalu",
  "1374": "Justin Frye",
  "1377": "Rob Ambrose",
  "1378": "T.J. Weist",
  "1379": "Frank Verducci",
  "1380": "Frank Giufre",
  "1383": "Rob Phenicie",
  "1384": "Brent Myers",
  "1385": "Garin Justice",
  "1387": "John Morton",
  "1388": "Tee Martin",
  "1390": "Darren Hiller",
  "1391": "Kerwin Bell",
  "1392": "Jim Harding",
  "1393": "Troy Taylor",
  "1395": "Luke Wells",
  "1396": "Jovon Bouknight",
  "1397": "Patrick Higgins",
  "1398": "Bob Connelly",
  "1399": "Brian Natkin",
  "1401": "Jeff Kastl",
  "1404": "Jimmy Kiser",
  "1405": "David Raih",
  "1406": "Mike Groh",
  "1407": "Bryan Stinespring",
  "1409": "Steed Lobotzke",
  "1412": "Todd Sturdy",
  "1413": "Craig Stutzmann",
  "1414": "Chad Scott",
  "1415": "Gerad Parker",
  "1416": "Kevin Wright",
  "1417": "Walt Wells",
  "1418": "Zach Azzanni",
  "1419": "Junior Adams",
  "1420": "Bryan Ellis",
  "1421": "Ryan Aplin",
  "1422": "Zach Kittley",
  "1423": "Ryan Cubit",
  "1424": "Jake Moreland",
  "1425": "Bill Kenney",
  "1426": "Eric Evans",
  "1427": "Mike Bath",
  "1428": "Joe Rudolph",
  "1430": "Brent Vigen",
  "1431": "Tim Polasek"
}

export const coachIdRaceTranslator = {
  "2": "White",
  "5": "White",
  "6": "White",
  "7": "White",
  "9": "White",
  "11": "White",
  "12": "White",
  "13": "White",
  "14": "White",
  "17": "White",
  "18": "White",
  "19": "Black",
  "20": "White",
  "22": "White",
  "23": "White",
  "24": "White",
  "25": "Black",
  "26": "White",
  "27": "White",
  "28": "White",
  "29": "White",
  "30": "White",
  "31": "White",
  "34": "White",
  "35": "White",
  "36": "White",
  "37": "White",
  "38": "White",
  "39": "White",
  "41": "White",
  "42": "White",
  "43": "White",
  "44": "White",
  "45": "White",
  "46": "White",
  "47": "White",
  "48": "White",
  "49": "White",
  "50": "White",
  "51": "White",
  "52": "White",
  "53": "White",
  "54": "White",
  "55": "White",
  "56": "Other",
  "57": "White",
  "58": "White",
  "59": "White",
  "60": "White",
  "61": "White",
  "62": "White",
  "63": "White",
  "64": "White",
  "66": "White",
  "67": "White",
  "68": "White",
  "69": "Black",
  "70": "Black",
  "71": "White",
  "74": "Black",
  "75": "White",
  "76": "White",
  "77": "Black",
  "79": "White",
  "80": "White",
  "81": "Other",
  "83": "White",
  "84": "White",
  "85": "White",
  "86": "White",
  "87": "White",
  "88": "White",
  "89": "White",
  "90": "White",
  "91": "White",
  "92": "White",
  "93": "White",
  "94": "White",
  "95": "White",
  "97": "White",
  "99": "White",
  "101": "Black",
  "102": "White",
  "103": "Black",
  "104": "Black",
  "106": "White",
  "107": "White",
  "109": "White",
  "110": "White",
  "111": "White",
  "112": "White",
  "113": "White",
  "114": "Black",
  "115": "Black",
  "116": "White",
  "119": "Black",
  "120": "White",
  "122": "White",
  "123": "White",
  "124": "White",
  "125": "Black",
  "127": "Other",
  "128": "White",
  "129": "White",
  "130": "White",
  "132": "White",
  "133": "White",
  "135": "White",
  "136": "White",
  "138": "White",
  "139": "White",
  "141": "White",
  "142": "White",
  "143": "White",
  "144": "White",
  "145": "White",
  "146": "Black",
  "147": "White",
  "150": "White",
  "151": "White",
  "152": "White",
  "153": "White",
  "154": "Other",
  "155": "White",
  "156": "White",
  "157": "White",
  "158": "White",
  "159": "White",
  "160": "White",
  "161": "White",
  "162": "White",
  "163": "Black",
  "164": "White",
  "167": "White",
  "168": "White",
  "170": "White",
  "171": "White",
  "172": "White",
  "174": "White",
  "175": "White",
  "176": "White",
  "177": "White",
  "179": "Black",
  "180": "White",
  "182": "White",
  "183": "Black",
  "184": "Black",
  "185": "White",
  "186": "White",
  "188": "Black",
  "189": "White",
  "192": "White",
  "193": "White",
  "196": "White",
  "198": "White",
  "199": "White",
  "200": "Black",
  "201": "White",
  "202": "White",
  "203": "White",
  "204": "White",
  "205": "Black",
  "207": "White",
  "208": "White",
  "209": "White",
  "210": "White",
  "211": "Black",
  "212": "White",
  "213": "White",
  "214": "White",
  "216": "White",
  "217": "Black",
  "218": "White",
  "219": "White",
  "221": "Black",
  "222": "White",
  "223": "Other",
  "224": "White",
  "225": "Black",
  "226": "White",
  "227": "White",
  "229": "White",
  "232": "White",
  "234": "White",
  "235": "White",
  "236": "White",
  "237": "White",
  "240": "White",
  "241": "White",
  "244": "White",
  "245": "Other",
  "246": "White",
  "247": "White",
  "250": "White",
  "251": "White",
  "252": "White",
  "253": "White",
  "255": "White",
  "256": "Black",
  "257": "White",
  "258": "White",
  "259": "White",
  "261": "Black",
  "262": "White",
  "264": "Black",
  "265": "White",
  "266": "White",
  "267": "White",
  "268": "White",
  "269": "White",
  "271": "White",
  "272": "Black",
  "274": "White",
  "276": "White",
  "277": "White",
  "280": "White",
  "282": "White",
  "285": "White",
  "286": "White",
  "287": "White",
  "289": "White",
  "290": "White",
  "291": "White",
  "292": "White",
  "294": "White",
  "295": "Black",
  "298": "White",
  "299": "White",
  "301": "White",
  "302": "White",
  "304": "White",
  "305": "White",
  "307": "White",
  "308": "White",
  "309": "White",
  "312": "White",
  "313": "White",
  "315": "White",
  "316": "White",
  "318": "White",
  "319": "White",
  "320": "White",
  "321": "White",
  "323": "White",
  "324": "White",
  "326": "White",
  "327": "White",
  "328": "White",
  "329": "White",
  "331": "Black",
  "332": "White",
  "333": "White",
  "334": "White",
  "335": "White",
  "339": "White",
  "340": "White",
  "341": "White",
  "343": "White",
  "344": "White",
  "345": "White",
  "346": "White",
  "348": "White",
  "350": "White",
  "351": "White",
  "353": "White",
  "354": "Black",
  "355": "White",
  "356": "White",
  "357": "White",
  "359": "White",
  "360": "Black",
  "361": "White",
  "362": "White",
  "363": "White",
  "364": "White",
  "365": "White",
  "367": "White",
  "368": "White",
  "369": "Other",
  "370": "White",
  "373": "White",
  "374": "White",
  "378": "White",
  "379": "White",
  "381": "White",
  "382": "Black",
  "383": "White",
  "386": "White",
  "387": "Black",
  "388": "White",
  "390": "White",
  "391": "Black",
  "395": "Black",
  "396": "White",
  "397": "White",
  "399": "White",
  "400": "White",
  "401": "White",
  "402": "White",
  "403": "White",
  "404": "White",
  "406": "White",
  "408": "White",
  "409": "White",
  "411": "White",
  "413": "White",
  "414": "White",
  "415": "White",
  "416": "White",
  "417": "White",
  "418": "White",
  "419": "White",
  "420": "White",
  "421": "White",
  "422": "White",
  "423": "White",
  "424": "Black",
  "425": "White",
  "427": "White",
  "428": "White",
  "429": "White",
  "430": "White",
  "431": "Black",
  "432": "White",
  "433": "White",
  "434": "White",
  "435": "White",
  "436": "Black",
  "437": "White",
  "438": "White",
  "439": "White",
  "440": "Black",
  "442": "White",
  "444": "White",
  "445": "White",
  "446": "White",
  "448": "White",
  "450": "White",
  "451": "White",
  "452": "White",
  "454": "White",
  "455": "White",
  "456": "White",
  "457": "White",
  "458": "White",
  "459": "White",
  "460": "White",
  "461": "White",
  "462": "White",
  "463": "Black",
  "466": "White",
  "467": "White",
  "468": "White",
  "469": "White",
  "470": "Black",
  "472": "White",
  "474": "White",
  "475": "Black",
  "476": "White",
  "477": "White",
  "478": "White",
  "479": "White",
  "480": "White",
  "481": "White",
  "482": "White",
  "483": "White",
  "484": "White",
  "485": "White",
  "486": "Black",
  "487": "White",
  "488": "White",
  "489": "Black",
  "490": "Black",
  "491": "White",
  "492": "Black",
  "493": "Black",
  "494": "Black",
  "498": "Black",
  "499": "Black",
  "500": "Black",
  "501": "White",
  "502": "White",
  "503": "Black",
  "505": "Black",
  "506": "White",
  "507": "Black",
  "509": "White",
  "510": "White",
  "511": "White",
  "512": "White",
  "515": "White",
  "517": "Black",
  "518": "Black",
  "519": "White",
  "520": "White",
  "521": "White",
  "522": "Black",
  "523": "White",
  "524": "Black",
  "525": "Black",
  "526": "White",
  "530": "White",
  "531": "Black",
  "532": "Black",
  "533": "Black",
  "534": "White",
  "535": "White",
  "536": "Black",
  "538": "Black",
  "539": "White",
  "541": "White",
  "542": "White",
  "543": "White",
  "544": "White",
  "545": "Black",
  "547": "White",
  "548": "Black",
  "549": "White",
  "550": "White",
  "551": "White",
  "553": "White",
  "554": "Black",
  "555": "White",
  "557": "White",
  "558": "Black",
  "559": "Black",
  "560": "White",
  "561": "White",
  "563": "Black",
  "564": "White",
  "565": "White",
  "567": "White",
  "568": "White",
  "569": "White",
  "570": "White",
  "571": "White",
  "573": "White",
  "574": "White",
  "575": "White",
  "576": "White",
  "577": "White",
  "578": "Black",
  "579": "White",
  "580": "White",
  "581": "White",
  "582": "White",
  "583": "Black",
  "584": "White",
  "585": "Black",
  "586": "White",
  "588": "Black",
  "589": "White",
  "592": "White",
  "593": "White",
  "594": "Black",
  "595": "Black",
  "596": "White",
  "599": "White",
  "600": "White",
  "601": "White",
  "602": "White",
  "603": "White",
  "604": "White",
  "605": "White",
  "606": "White",
  "610": "White",
  "611": "White",
  "612": "White",
  "613": "White",
  "614": "Other",
  "615": "White",
  "616": "Other",
  "617": "Other",
  "619": "White",
  "621": "White",
  "622": "White",
  "623": "Black",
  "624": "White",
  "625": "White",
  "626": "Black",
  "627": "White",
  "628": "Black",
  "629": "White",
  "631": "White",
  "632": "White",
  "633": "Black",
  "634": "Black",
  "637": "White",
  "638": "White",
  "639": "White",
  "640": "White",
  "641": "White",
  "642": "White",
  "643": "Black",
  "644": "White",
  "645": "White",
  "646": "White",
  "647": "White",
  "648": "White",
  "650": "White",
  "651": "White",
  "652": "White",
  "653": "Black",
  "654": "White",
  "655": "White",
  "656": "White",
  "657": "White",
  "658": "Black",
  "659": "White",
  "660": "White",
  "661": "White",
  "662": "White",
  "663": "White",
  "664": "White",
  "665": "White",
  "666": "Black",
  "667": "White",
  "670": "White",
  "671": "Black",
  "672": "White",
  "673": "White",
  "674": "Black",
  "675": "Black",
  "676": "White",
  "678": "White",
  "679": "White",
  "680": "Black",
  "681": "Black",
  "682": "White",
  "683": "White",
  "684": "White",
  "685": "White",
  "686": "White",
  "687": "White",
  "688": "White",
  "689": "White",
  "690": "White",
  "691": "White",
  "692": "White",
  "693": "White",
  "694": "White",
  "695": "White",
  "697": "White",
  "698": "Black",
  "699": "White",
  "700": "Black",
  "703": "White",
  "704": "White",
  "705": "White",
  "706": "White",
  "708": "White",
  "709": "White",
  "710": "Black",
  "711": "Black",
  "712": "White",
  "713": "Black",
  "714": "White",
  "716": "White",
  "717": "White",
  "718": "Black",
  "719": "Black",
  "720": "Black",
  "721": "White",
  "723": "Black",
  "724": "White",
  "725": "White",
  "726": "White",
  "727": "White",
  "729": "White",
  "732": "White",
  "733": "Black",
  "734": "Black",
  "736": "Black",
  "737": "Black",
  "738": "White",
  "739": "White",
  "740": "White",
  "741": "White",
  "742": "White",
  "744": "White",
  "745": "Black",
  "746": "Black",
  "748": "White",
  "749": "White",
  "750": "White",
  "751": "White",
  "752": "White",
  "753": "White",
  "754": "White",
  "755": "White",
  "756": "White",
  "757": "White",
  "758": "White",
  "759": "Black",
  "760": "White",
  "761": "White",
  "762": "White",
  "763": "Black",
  "764": "White",
  "765": "White",
  "766": "White",
  "767": "White",
  "771": "White",
  "772": "Black",
  "773": "White",
  "775": "White",
  "776": "White",
  "777": "White",
  "779": "White",
  "780": "White",
  "781": "White",
  "783": "White",
  "784": "Black",
  "785": "Black",
  "786": "White",
  "787": "White",
  "789": "White",
  "790": "White",
  "792": "White",
  "793": "Black",
  "795": "White",
  "796": "Black",
  "797": "Black",
  "798": "White",
  "800": "Black",
  "801": "Other",
  "802": "Black",
  "803": "White",
  "804": "White",
  "805": "Black",
  "806": "White",
  "807": "White",
  "808": "Black",
  "809": "White",
  "811": "White",
  "812": "White",
  "815": "White",
  "816": "White",
  "817": "White",
  "818": "White",
  "820": "White",
  "824": "Black",
  "825": "White",
  "827": "Black",
  "828": "White",
  "829": "Black",
  "830": "White",
  "831": "Black",
  "832": "Black",
  "833": "Black",
  "834": "White",
  "835": "White",
  "836": "White",
  "837": "White",
  "838": "White",
  "839": "White",
  "840": "Black",
  "841": "White",
  "842": "Black",
  "843": "White",
  "845": "White",
  "846": "White",
  "847": "Black",
  "848": "White",
  "849": "White",
  "850": "Black",
  "851": "White",
  "852": "White",
  "854": "White",
  "855": "White",
  "856": "White",
  "857": "White",
  "859": "Black",
  "860": "White",
  "862": "Black",
  "863": "Black",
  "864": "Black",
  "865": "Black",
  "867": "White",
  "868": "White",
  "869": "White",
  "870": "Black",
  "871": "Black",
  "872": "White",
  "875": "White",
  "876": "Black",
  "878": "White",
  "879": "White",
  "882": "Black",
  "883": "White",
  "884": "White",
  "885": "Black",
  "886": "White",
  "887": "Black",
  "888": "White",
  "889": "Black",
  "890": "White",
  "891": "Other",
  "892": "Black",
  "893": "Black",
  "894": "White",
  "895": "White",
  "896": "White",
  "898": "Other",
  "899": "Black",
  "900": "White",
  "901": "White",
  "903": "White",
  "904": "Black",
  "905": "White",
  "906": "White",
  "907": "Black",
  "911": "White",
  "913": "White",
  "917": "White",
  "918": "White",
  "919": "White",
  "920": "Other",
  "921": "White",
  "922": "White",
  "923": "Black",
  "924": "White",
  "925": "White",
  "926": "White",
  "927": "Black",
  "928": "Black",
  "930": "White",
  "931": "White",
  "932": "White",
  "934": "White",
  "935": "White",
  "936": "White",
  "937": "White",
  "940": "White",
  "941": "White",
  "942": "White",
  "943": "White",
  "944": "White",
  "945": "White",
  "946": "White",
  "947": "White",
  "948": "White",
  "949": "White",
  "950": "Black",
  "951": "White",
  "952": "White",
  "953": "White",
  "956": "White",
  "957": "White",
  "958": "Black",
  "959": "White",
  "960": "White",
  "961": "White",
  "963": "White",
  "964": "White",
  "965": "White",
  "966": "White",
  "967": "White",
  "968": "White",
  "969": "White",
  "970": "White",
  "971": "White",
  "974": "White",
  "975": "White",
  "976": "Other",
  "977": "White",
  "978": "White",
  "979": "White",
  "980": "White",
  "982": "White",
  "983": "White",
  "984": "White",
  "986": "White",
  "987": "White",
  "988": "White",
  "989": "Black",
  "990": "Black",
  "991": "White",
  "995": "Black",
  "996": "White",
  "997": "White",
  "998": "White",
  "1000": "White",
  "1001": "White",
  "1002": "White",
  "1003": "White",
  "1004": "Black",
  "1005": "White",
  "1006": "Other",
  "1007": "White",
  "1008": "White",
  "1009": "White",
  "1010": "White",
  "1011": "White",
  "1012": "White",
  "1013": "White",
  "1014": "White",
  "1015": "White",
  "1017": "White",
  "1018": "White",
  "1019": "White",
  "1020": "White",
  "1021": "White",
  "1022": "White",
  "1023": "White",
  "1024": "White",
  "1025": "White",
  "1027": "White",
  "1029": "Black",
  "1030": "White",
  "1031": "Black",
  "1032": "White",
  "1037": "White",
  "1038": "White",
  "1039": "White",
  "1040": "White",
  "1041": "White",
  "1043": "White",
  "1044": "White",
  "1045": "White",
  "1046": "White",
  "1047": "White",
  "1048": "White",
  "1049": "White",
  "1050": "White",
  "1051": "White",
  "1052": "White",
  "1053": "White",
  "1054": "Black",
  "1055": "White",
  "1057": "White",
  "1058": "White",
  "1059": "White",
  "1060": "White",
  "1063": "White",
  "1064": "Black",
  "1065": "Black",
  "1066": "White",
  "1067": "White",
  "1068": "Black",
  "1069": "White",
  "1070": "White",
  "1071": "White",
  "1072": "White",
  "1075": "White",
  "1076": "White",
  "1077": "White",
  "1078": "White",
  "1079": "White",
  "1080": "White",
  "1082": "White",
  "1083": "White",
  "1084": "White",
  "1085": "White",
  "1086": "Black",
  "1088": "White",
  "1089": "White",
  "1090": "White",
  "1092": "White",
  "1094": "White",
  "1095": "White",
  "1096": "White",
  "1097": "Black",
  "1098": "White",
  "1099": "White",
  "1100": "White",
  "1101": "White",
  "1102": "White",
  "1104": "White",
  "1105": "White",
  "1106": "White",
  "1107": "White",
  "1108": "Other",
  "1109": "White",
  "1110": "Black",
  "1111": "Black",
  "1112": "White",
  "1114": "Black",
  "1115": "White",
  "1116": "White",
  "1117": "White",
  "1118": "White",
  "1119": "White",
  "1120": "White",
  "1121": "White",
  "1122": "White",
  "1123": "White",
  "1125": "White",
  "1126": "White",
  "1127": "Other",
  "1128": "White",
  "1129": "White",
  "1130": "White",
  "1131": "White",
  "1132": "Black",
  "1133": "White",
  "1135": "White",
  "1136": "Black",
  "1137": "White",
  "1138": "White",
  "1139": "White",
  "1140": "Black",
  "1141": "Black",
  "1142": "White",
  "1143": "White",
  "1144": "Black",
  "1146": "White",
  "1147": "White",
  "1148": "Black",
  "1149": "White",
  "1151": "White",
  "1152": "White",
  "1155": "White",
  "1156": "White",
  "1157": "White",
  "1158": "White",
  "1161": "White",
  "1162": "White",
  "1163": "White",
  "1164": "White",
  "1165": "White",
  "1166": "White",
  "1167": "Black",
  "1168": "White",
  "1169": "White",
  "1171": "White",
  "1172": "White",
  "1173": "White",
  "1174": "White",
  "1175": "White",
  "1176": "Black",
  "1177": "White",
  "1178": "Black",
  "1179": "White",
  "1182": "White",
  "1183": "White",
  "1184": "White",
  "1185": "White",
  "1189": "White",
  "1190": "White",
  "1191": "White",
  "1192": "White",
  "1193": "White",
  "1194": "White",
  "1195": "White",
  "1197": "White",
  "1198": "Black",
  "1199": "White",
  "1200": "White",
  "1202": "White",
  "1203": "White",
  "1206": "White",
  "1207": "White",
  "1208": "White",
  "1209": "White",
  "1210": "White",
  "1211": "White",
  "1213": "White",
  "1214": "Black",
  "1215": "Black",
  "1217": "White",
  "1218": "White",
  "1219": "White",
  "1220": "White",
  "1221": "Black",
  "1222": "White",
  "1223": "White",
  "1224": "White",
  "1225": "White",
  "1226": "Black",
  "1227": "White",
  "1228": "White",
  "1229": "White",
  "1230": "White",
  "1231": "Black",
  "1234": "White",
  "1236": "White",
  "1237": "White",
  "1240": "Black",
  "1242": "Black",
  "1243": "Black",
  "1244": "White",
  "1245": "White",
  "1246": "Black",
  "1247": "White",
  "1249": "White",
  "1250": "White",
  "1252": "White",
  "1253": "White",
  "1254": "White",
  "1256": "White",
  "1257": "White",
  "1258": "White",
  "1259": "White",
  "1260": "White",
  "1261": "White",
  "1263": "White",
  "1264": "White",
  "1265": "White",
  "1266": "White",
  "1267": "White",
  "1268": "White",
  "1269": "White",
  "1270": "White",
  "1272": "White",
  "1273": "White",
  "1274": "White",
  "1276": "White",
  "1277": "Black",
  "1278": "White",
  "1279": "White",
  "1280": "White",
  "1281": "White",
  "1282": "White",
  "1283": "White",
  "1284": "White",
  "1285": "White",
  "1287": "White",
  "1288": "White",
  "1289": "White",
  "1292": "White",
  "1293": "White",
  "1295": "White",
  "1296": "Black",
  "1298": "White",
  "1299": "Black",
  "1300": "Other",
  "1301": "White",
  "1302": "White",
  "1303": "White",
  "1304": "White",
  "1309": "White",
  "1310": "White",
  "1311": "White",
  "1312": "White",
  "1313": "White",
  "1314": "White",
  "1315": "White",
  "1316": "White",
  "1317": "White",
  "1318": "White",
  "1319": "White",
  "1320": "White",
  "1321": "White",
  "1322": "Black",
  "1323": "White",
  "1324": "White",
  "1325": "White",
  "1327": "Black",
  "1328": "White",
  "1329": "White",
  "1331": "White",
  "1332": "White",
  "1333": "White",
  "1334": "White",
  "1335": "Black",
  "1338": "Black",
  "1339": "White",
  "1340": "White",
  "1341": "White",
  "1342": "White",
  "1343": "Black",
  "1344": "Black",
  "1345": "White",
  "1347": "White",
  "1348": "White",
  "1349": "White",
  "1350": "White",
  "1351": "White",
  "1352": "White",
  "1353": "White",
  "1355": "White",
  "1356": "White",
  "1357": "White",
  "1358": "White",
  "1359": "White",
  "1362": "White",
  "1364": "White",
  "1365": "White",
  "1366": "Black",
  "1367": "Black",
  "1372": "White",
  "1373": "Other",
  "1374": "White",
  "1377": "White",
  "1378": "White",
  "1379": "White",
  "1380": "White",
  "1383": "White",
  "1384": "White",
  "1385": "White",
  "1387": "White",
  "1388": "Black",
  "1390": "White",
  "1391": "White",
  "1392": "White",
  "1393": "White",
  "1395": "White",
  "1396": "Black",
  "1397": "White",
  "1398": "White",
  "1401": "White",
  "1404": "White",
  "1405": "White",
  "1406": "White",
  "1407": "White",
  "1409": "White",
  "1412": "White",
  "1413": "Other",
  "1414": "Black",
  "1415": "White",
  "1416": "White",
  "1417": "White",
  "1418": "White",
  "1419": "Black",
  "1420": "White",
  "1421": "White",
  "1422": "White",
  "1423": "White",
  "1424": "Black",
  "1426": "White",
  "1427": "White",
  "1428": "White",
  "1430": "White",
  "1431": "White"
}