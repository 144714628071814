import { renderLegendNode, renderLegendEdge } from '../lib/iconHelper'
import { getNodeColor, getEdgeColor } from '../lib/networkHelper'
import { useState } from 'react'

const NetworkLegend = () => {

  const [isVisible, setVisible] = useState(true)

  const handleLegendToggle = (e) => {
    e.preventDefault()
    setVisible(!isVisible)
  }

  return (
    <div className={`network-legend${isVisible ? '' : ' hidden'}`}>
      <div className="legend-container">
        <h2>Legend</h2>
        <h4>Nodes</h4>
        <div className="legend-item">
          <i>{renderLegendNode(getNodeColor("White"))}</i>
          <span>White</span>
        </div>
        <div className="legend-item">
          <i>{renderLegendNode(getNodeColor("Black"))}</i>
          <span>Black</span>
        </div>
        <div className="legend-item">
          <i>{renderLegendNode(getNodeColor("Other"))}</i>
          <span>Other</span>
        </div>
        <h4>Edges</h4>
        <div className="legend-item">
          <i>{renderLegendEdge(getEdgeColor("White","White"))}</i>
          <span>White to White</span>
        </div>
        <div className="legend-item">
          <i>{renderLegendEdge(getEdgeColor("Black", "White"))}</i>
          <span>White to Minority</span>
        </div>
        <div className="legend-item">
          <i>{renderLegendEdge(getEdgeColor("Other", "Black"))}</i>
          <span>Minority to Minority</span>
        </div>
        <i id="legend-toggle" onClick={e => handleLegendToggle(e)}>&nbsp;</i>
      </div>
    </div>
  )
}

export default NetworkLegend