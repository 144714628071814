//Render close icon
export const renderCloseIcon = (fillColour = '#000') => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z" fill={fillColour}></path></svg>
  )
}

//Render information icon
export const renderInformationIcon = (size = '18', fillColour = '#000') => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 24 24`} width={`${size}`} height={`${size}`}><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11V17H13V11H11ZM11 7V9H13V7H11Z" fill={fillColour}></path></svg>
  )
}

//Render circle for node
export const renderLegendNode = (fillColor) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill={fillColor}></path></svg>
  )
}

export const renderLegendEdge = (fillColor) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16"><rect x="0" y="8" width="24" height="4" fill={fillColor}></rect></svg>
  )
}

export const getPerformanceSquare = (fillColor) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16"><rect x="0" y="0" width="24" height="24" fill={fillColor}></rect></svg>
  )
}