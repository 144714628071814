import React from 'react'
import styles from '../../Modal.module.scss'
import { motion } from 'framer-motion'


const LoadingOverlay = ({ modalSize = 'viewbox'}) => {

  return (
    <motion.div
     className={`modal-overlay overlay-size-${modalSize}`}
     initial={{opacity:0}}
     animate={{opacity:1}}
     exit={{opacity: 0, transition : {delay: 0.125}}}
    >
      <div className={`${styles.loadingContainer}`}>
        <div className={`${styles.ldsRing}`}><div></div><div></div><div></div><div></div></div>
      </div>
    </motion.div>
  )
}

export default LoadingOverlay