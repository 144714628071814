import { motion } from 'framer-motion'
import { getCentralityTooltip } from '../lib/tableHelper'
import { getCentralityStatColor, getStatColorLegend } from '../data/summaryData'
import { getPerformanceSquare } from '../lib/iconHelper'
import InformationTooltip from './InformationTooltip'
import { coachEdges } from '../data/coachEdges'
import { coachIdNameTranslator } from '../data/coachTranslator'

const CoachNetwork = ({coachObject, handleCoachEvent}) => {

  const addRelatedCoach = (e, coachID) => {
    e.preventDefault()
    handleCoachEvent(coachID, 'add')
  }

  return (
    <motion.div
     inherit={{opacity:0}}
     animate={{opacity:1}}
     exit={{opacity:0}}
    >
      <div className="stat-table network-table">
        <div className="stat-heading">
          <span>
            Betweenness
            <InformationTooltip tooltipContent={getCentralityTooltip('betweenness')} size={12} />
          </span>
          <span>
            Closeness
            <InformationTooltip tooltipContent={getCentralityTooltip('closeness')} size={12} />
          </span>
          <span>
            Degree
            <InformationTooltip tooltipContent={getCentralityTooltip('degree')} size={12} />
          </span>
          <span>
            Eigen
            <InformationTooltip tooltipContent={getCentralityTooltip('eigen')} size={12} />
          </span>
        </div>
        <div className="stat-values">
          <div className="stat-row">
          {
            Object.keys(coachObject['centrality']).map(metric => {
              return (
                <span key={`network-metric-${coachObject['id']}-${metric}`} style={{background: getCentralityStatColor(metric, Number(coachObject['centrality'][metric]))}}>{ coachObject['centrality'][metric] }</span>
              )
            })
          }
          </div>
        </div>
        <div className="stat-colors">
          <h6>The colors of the values represent the related quartile of all performance stats.</h6>
          <div className="colors">
            <div className="item">
              <i>{getPerformanceSquare(getStatColorLegend('bottom'))}</i>
              <span>
                Bottom Quartile
                <InformationTooltip tooltipContent={`Bottom 25%`} size={`12`}/>
              </span>
            </div>
            <div className="item">
              <i>{getPerformanceSquare(getStatColorLegend('midBottom'))}</i>
              <span>
                Lower Middle Quartile
                <InformationTooltip tooltipContent={`Middle 25% and 50%`} size={`12`}/>
              </span>
            </div>
            <div className="item">
              <i>{getPerformanceSquare(getStatColorLegend('midTop'))}</i>
              <span>
                Upper Middle Quartile
                <InformationTooltip tooltipContent={`Middle 50% and 75%`}  size={`12`}/>
                </span>
            </div>
            <div className="item">
                <i>{getPerformanceSquare(getStatColorLegend('top'))}</i>
                <span>
                  Top Quartile
                  <InformationTooltip tooltipContent={`Top 25%`} size={`12`}/>
                </span>
            </div>
          </div>
        </div>
        <div className="coaching-connections">
          <div className="stat-heading">
            <span>
              Coaching Connections
              <InformationTooltip tooltipContent={`These are the related edges to a the coach, with the number of years spend together wrapped in brackets. Double click on the names to show their coaching profile.`} size={`12`}/>
            </span>
          </div>
          <div className="related-connections">
            { //Add related coaches
              coachEdges.filter(edge => edge.from === coachObject['id'] || edge.to === coachObject['id'])
              .map(edge => { //Return the ID of the non-related coach
                if(edge.from !== coachObject['id']){
                  return {
                    id: edge.from,
                    years: edge.value
                  }
                }
                else {
                  return {
                    id: edge.to,
                    years: edge.value
                  }
                }
              })
              .map((relatedCoach,index) => {
                return (  //Return the span element for the related coach
                  <span key={`coach-${coachObject['id']}-related-${index}`}><strong onClick={e => addRelatedCoach(e, relatedCoach['id'])}>{coachIdNameTranslator[relatedCoach['id']]}</strong> ({relatedCoach['years']})</span>
                )
              })
            }
            { //No related coaches
              coachEdges.filter(edge => edge.from === coachObject['id'] || edge.to === coachObject['id']).length === 0 &&
              <span className="no-related-coaches">
                <strong>No related coaches</strong>
                <InformationTooltip tooltipContent={`This indicates that there were no coaching connections in the visualised data.`} size={`12`} />
              </span>
            }
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default CoachNetwork