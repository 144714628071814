import { useState } from 'react'
import { renderCloseIcon } from '../lib/iconHelper'
import { AnimatePresence, motion } from 'framer-motion'
import CoachPerformance from './CoachPerformance'
import CoachPosition from './CoachPosition' 
import CoachNetwork from './CoachNetwork' 


const CoachStats = ({coachID, coachObject, handleCoachEvent}) => {

  const handleCoachRemoval = (e) => {
    e.preventDefault()
    //Handle removal of coach
    handleCoachEvent(coachID, 'remove')
  }

  const [activeTab, setActiveTab] = useState('position')

  return (
    <motion.div
     className="individual-coach-stat"
     initial={{ opacity: 0 }}
     animate={{ opacity: 1 }}
     exit={{ opacity: 0 }}
    >
      <div className="container">
        <div className="heading-wrapper">
            <h4>{coachObject['name']}</h4>
            <i className="coach-close" onClick={e => handleCoachRemoval(e)}>{ renderCloseIcon() }</i>
        </div>
        <div className="coach-information">
          <p>Race: {coachObject['race']}</p>
        </div>
        <div className="tabs-wrap">
          <ul>
            <li onClick={ () => setActiveTab("position") } className={`stat-tab${activeTab === 'position' ? ' active' : ''}`}>Position</li>
            <li onClick={ () => setActiveTab("performance") } className={`stat-tab${activeTab === 'performance' ? ' active' : ''}`}>Performance</li>
            <li onClick={ () => setActiveTab("centrality") } className={`stat-tab${activeTab === 'centrality' ? ' active' : ''}`}>Network</li>
          </ul>
        </div>
        <div className="stat-wrapper">
          <AnimatePresence>
            { activeTab === "position" &&
              <CoachPosition coachObject={coachObject} />
            }
            { activeTab === "performance" &&
              <CoachPerformance coachObject={coachObject} />
            }
            { activeTab === "centrality" &&
              <CoachNetwork coachObject={coachObject} handleCoachEvent={handleCoachEvent}/>
            }
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  )
}

export default CoachStats