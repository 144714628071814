import { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion';
import Graph from 'react-vis-network-graph'
import LoadingOverlay from './modals/LoadingOverlay'
import NetworkLegend from './NetworkLegend'
import { coachEdges } from '../data/coachEdges';
import { coachPerformanceList } from '../data/coachPerformanceList';
import { isDebugMode } from '../lib/debugHelper'
import { getNodeColor, getEdgeColor } from '../lib/networkHelper'
import { coachIdRaceTranslator, coachIdNameTranslator } from '../data/coachTranslator'
import { centralityMeasureAttributes } from '../data/summaryData'

const NetworkGraph = ({ minYearsCoached, maxYearsCoached, minCoachConnections, maxCoachConnections, minCoachXpa, maxCoachXpa, coachedPositions, individualCoachNetwork, handleCoachEvent, graphNodeOption, network, setNetwork, isGraphLoading, graphLoaded}) => {

  //Track initial load
  const [initialLoad, setInitialLoad] = useState(true)

  //Network graph constants
  const maxNodeSize = 30
  const minNodeSize = 10


  //Node State

  const edges = coachEdges.map(edge => {
    return {
      ... edge,
      color: getEdgeColor( coachIdRaceTranslator[edge.from.toString()], coachIdRaceTranslator[edge.to.toString()] ),
      title: `${coachIdNameTranslator[edge.from.toString()]} & ${coachIdNameTranslator[edge.to.toString()]}`
    }
  })
  
  //Option State
  const options = {
    autoResize: true,
    nodes:{
      shape: "dot",
      scaling: {
        min: minNodeSize,
        max: maxNodeSize,
      },
    },
    edges: {
      length: 400,
      arrows: {
        to: false,
        from: false
      },
      scaling: {
        min: 1,
        max: 20,
      },
      color: {inherit: "from"},
      smooth: {
          type: "continuous"
      }
    },
    height: "700px",
    interaction: {
      navigationButtons: true,
      hover : true
    },
    layout: {
     randomSeed: 'cse6242',
     improvedLayout: false
    },
    physics: {
      enabled: false,
      solver: "repulsion",
      repulsion: {
        nodeDistance: 350,
        springLength: 50,
        springConstant: 0.95, 
        centralGravity: 25
      },
      maxVelocity: 40,
      minVelocity: 30
    },
  }


  //Object to handle event functionality from the network graph
  const events = {
    doubleClick: (e) => {
      if(e.nodes.length !== 1) {
        if(isDebugMode()) console.error(`Node selection: Invalid number of nodes returned in click event.`, e.nodes)
      }
      else { //Handle event
        handleCoachEvent(e.nodes[0], 'add')
      }
    },
    afterDrawing: (e) => {
      if(!initialLoad) {
        graphLoaded()
      }
    }
  }

  //Cursor event listener
  const makeCursorPointer = () => {
    network.canvas.body.container.style.cursor = 'pointer'
  }

  const makeCursorDefault = () => {
    network.canvas.body.container.style.cursor = 'default'
  }

  
  //Use effect for network node cursor
  useEffect(() => {
    if(network !== null) {
      //Add event listeners
      network.addEventListener("hoverNode", makeCursorPointer);
      network.addEventListener("blurNode", makeCursorDefault);
      network.addEventListener("hoverEdge", makeCursorPointer);
      network.addEventListener("blurEdge", makeCursorDefault);
      return () => {
        network.removeEventListener("hoverNode", makeCursorPointer);
        network.removeEventListener("blurNode", makeCursorDefault);
        network.removeEventListener("hoverEdge", makeCursorPointer);
        network.removeEventListener("blurEdge", makeCursorDefault);
      }
    }
  }, [network])


  //Update nodes based on filters & display options
  useEffect(() => {
    if(!initialLoad) {
        //Generate node list
        const filteredNodes = coachPerformanceList
          .filter(item => {
            return item['yearsCoached'] >= minYearsCoached && item['yearsCoached'] <= maxYearsCoached //Years coached filter
          })
          .filter(item => {
            return item["centrality"]['degree'] >= minCoachConnections && item['centrality']['degree'] <= maxCoachConnections //Coaching connections
          })
          .filter(item => {
            return item["aggregate_xpa"] >= minCoachXpa && item["aggregate_xpa"] <= maxCoachXpa //XPA
          })
          .filter(item => {
            if( individualCoachNetwork.length === 0 ) return true
            else return individualCoachNetwork.includes(item['id'])
          })
          .filter(item => { //Positions coached
            //Set variable
            let includeCoach = false
            //Itterate through positions
            item['positionsCoached'].forEach((pos) => {
              if(coachedPositions.includes(pos)) includeCoach = true
            })
            //Return result
            return includeCoach
          })
          .map((item) => {
            return {
              id : item['id'],
              title: item['name'],
              color: getNodeColor(item['race']),
              value: item['centrality'][graphNodeOption],
              group: `community_${item['community']}`,
            }
        })
        //Set data
        network.setData({ nodes:filteredNodes , edges: edges})
        //Stabilize Network
        network.stabilize(Math.round(filteredNodes.length/10))
      }
  }, [minYearsCoached, maxYearsCoached, minCoachConnections, maxCoachConnections, coachedPositions, graphNodeOption, minCoachXpa, maxCoachXpa, individualCoachNetwork])


  //Disable initialLoad
  useEffect(() => {
    if(network !== null && initialLoad) {
      //Construct nodes
      const initialNodes = coachPerformanceList.map((item, index) => {
        return {
          id : item['id'],
          title: item['name'],
          color: getNodeColor(item['race']),
          value: item['centrality'][graphNodeOption],
          group: `community_${item['community']}`,
        }
      })
      //Set data
      network.setData({ nodes:initialNodes , edges: edges})
      //Stabilize network
      network.stabilize(Math.round(initialNodes.length/10))
      //Set initial load to false
      setTimeout(() => {setInitialLoad(false)},1)
    }
  },[network])

  return (
    <div className="graph-container">
      <Graph 
       graph={{nodes: [], edges: []}}
       options={options}
       events={events}
       getNetwork={(networkObj) => { setNetwork(networkObj) }}
      />
      <NetworkLegend />
      <AnimatePresence>
        { isGraphLoading &&
          <LoadingOverlay modalSize={`container`} />
        }
      </AnimatePresence>
    </div>
  )
}

export default NetworkGraph