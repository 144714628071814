export const netPerformanceSummary = {
  "net_ppa": {
      "max": 0.457399396253012,
      "min": -0.32020406744231,
      "q75": 0.06231621310148875,
      "q25": -0.057306528218511496,
      "median": 0.0036945266120986
  },
  "net_sr": {
      "max": 0.201223581757508,
      "min": -0.18836276,
      "q75": 0.028636501279033,
      "q25": -0.0286530472396898,
      "median": -0.000293407445547511
  },
  "net_stuff": {
      "max": 0.474067796610169,
      "min": -0.248877245508982,
      "q75": 0.0248408523622477,
      "q25": -0.0273030938621606,
      "median": -0.00127404530262948
  },
  "net_pass_sr": {
      "max": 0.368151784,
      "min": -0.215726713392248,
      "q75": 0.03466238341233475,
      "q25": -0.033412907747196005,
      "median": 0.000489018591794455
  }
}

export const totalPerformanceSummary = {
  "total_sr": {
      "max": 2.540534794,
      "min": -0.604940621,
      "q75": 0.1421561885,
      "q25": -0.10287973675,
      "median": -0.0007352549999999998
  },
  "total_stuffs": {
      "max": 1.754922161,
      "min": -1.226528491,
      "q75": 0.08768475025,
      "q25": -0.09463903925,
      "median": -0.005063288
  },
  "total_pass_sr": {
      "max": 3.165387688,
      "min": -0.807009513,
      "q75": 0.17263176275,
      "q25": -0.12639770549999998,
      "median": 0.0256417265
  },
  "total_ppa": {
      "max": 2.794984701,
      "min": -1.043009215,
      "q75": 0.28151198525,
      "q25": -0.1768650335,
      "median": -0.005204671
  }
}

/**
 * Object that contains the calculated values for centrality measures.
 */
export const centralityMeasureAttributes = {
  "betweenness": {
      "max": 2853.5,
      "min": 0,
      "q75": 0,
      "q25": 0,
      "median": 0
  },
  "closeness": {
      "max": 6.30574674229359e-7,
      "min": 4.88679733962753e-7,
      "q75": 4.89363440929673e-7,
      "q25": 4.88679733962753e-7,
      "median": 4.88679733962753e-7
  },
  "degree": {
      "max": 21,
      "min": 1,
      "q75": 4,
      "q25": 1,
      "median": 2
  },
  "eigen": {
      "max": 0.699252137276695,
      "min": 0,
      "q75": 0.0004877981310138655,
      "q25": 0.00002958506415224505,
      "median": 0.000125875694529736
  }
}

export const yearsCoachedMetrics = {
    "max": 22,
    "min": 1,
    "q75": 9,
    "q25": 2,
    "median": 5
}

export const coachXpaMetrics = {
  "max": 0.6113396640169263,
  "min": -0.47931167413145315,
  "q75": 0.0828370224300596,
  "q25": -0.0770545658427784,
  "median": 0.000240135502708433
}

//Quartile performance colors
const bottomQ = '#c2e699'
const midBottomQ = "#78c679"
const midTopQ = "#31a354"
const topQ = "#006837"

export const getStatColorLegend = (quartile) => {
  switch(quartile){
    case 'bottom':
      return bottomQ
    case 'midBottom':
      return midBottomQ
    case 'midTop':
      return midTopQ
    case 'top':
      return topQ
    default: 
    return bottomQ
  }
}

export const getPerformanceStatColor = (attr, value) => {
  switch(attr) {
    case "net_ppa": //Net stats
    case "net_sr":
    case "net_stuff":
    case "net_pass_sr":
      //Return color
      if(value <= netPerformanceSummary[attr]['q25']) { //Lowest quartile 
        return getStatColorLegend('bottom')
      }
      else if ( value > netPerformanceSummary[attr]['q25'] && value <= netPerformanceSummary[attr]['median']) { //Middle Bottom
        return getStatColorLegend('midBottom')
      }
      else if ( value > netPerformanceSummary[attr]['median'] && value <= netPerformanceSummary[attr]['q75']) { //Middle Top
        return getStatColorLegend('midTop')
      }
      else {  //Top Quantile of individual performances
        return getStatColorLegend('top')
      }
    case "total_sr":
    case "total_stuffs":
    case "total_pass_sr":
    case "total_ppa":
      //Return color
      if(value <= totalPerformanceSummary[attr]['q25']) { //Lowest quartile 
        return getStatColorLegend('bottom')
      }
      else if ( value > totalPerformanceSummary[attr]['q25'] && value <= totalPerformanceSummary[attr]['median']) { //Middle Bottom
        return getStatColorLegend('midBottom')
      }
      else if ( value > totalPerformanceSummary[attr]['median'] && value <= totalPerformanceSummary[attr]['q75']) { //Middle Top
        return getStatColorLegend('midTop')
      }
      else {  //Top Quantile of individual performances
        return getStatColorLegend('top')
      }
    default:
      return 'inherit'
  }
}

export const getCentralityStatColor = (attr, value) => {
  if(value <= centralityMeasureAttributes[attr]['q25']) { //Lowest quartile 
    return getStatColorLegend('bottom')
  }
  else if ( value > centralityMeasureAttributes[attr]['q25'] && value <= centralityMeasureAttributes[attr]['median']) { //Middle Bottom
    return getStatColorLegend('midBottom')
  }
  else if ( value > centralityMeasureAttributes[attr]['median'] && value <= centralityMeasureAttributes[attr]['q75']) { //Middle Top
    return getStatColorLegend('midTop')
  }
  else {  //Top Quantile of individual performances
    return getStatColorLegend('top')
  }
}