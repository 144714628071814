

const ProjectContent = () => {
  return (
    <div className="content-container">
      <h1>Racial Inequities and Social Networks in College Football Coaching</h1>
      <h3>Team 173</h3>
      <h4>R. Binion, M.Ismail, T. Mijat, F. Vojinovic & M. Wood</h4>
      <p className="project-description">This visualisation illustrates the coaching relationships between Football Bowl Subdivision (FBS) College Football teams' coaching staff throughout their careers. The data used in the visualisation consists of all Head Coaches, Offensive Coordinators & Defensive Coordinators in the year range of 2000 to 2021.</p>
      <p className="project-description">In the visualisation below, each node represents a coach from the data where we have related team statistics before the coach's arrival at a program, which we can then compare to the effect the coaching staff member had with the program. These nodes can be filtered based on a number of filters related to the coach's influence on the network and/or coach's career progression.</p>
      <p className="project-description">Edges between nodes represent coaching connections between two coaches, with the thickness of the edge denoting the number of years/seasons that the pair worked together. To find the name of the coaches that are connected, hover over an edge and a label will display the names of the connected coaches. This functionality can also be used to find the coach's name on all nodes in the network.</p>
      <p className="project-description">To investigate individual coaching impact, <strong>double click</strong> on a node and it will show the individual data and career progression of the coach throughout their coaching career.</p>
    </div>
  )
}

export default ProjectContent