export const expandPosition = (acronym) => {
  switch(acronym){
    case 'HC':
      return 'Head Coach'
    case 'OC':
      return 'Offensive Coordinator'
    case 'DC':
      return 'Defensive Coordinator'
    case "Positional Coach":
      return "Positional Coach"
    default:
      return 'Unknown'
  }
}

export const expandPerformanceHeading = (heading) => {
  switch(heading){
    case "college":
      return "College"
    case "role":
      return "Role"
    case "net_ppa":
      return "Net EPA"
    case "net_sr":
      return "Net SR"
    case "net_stuff":
      return "Net Stuff"
    case "net_pass_sr":
      return "Net PSR"
    case "net_fpi":
      return "Net FPI"
    case "total_sr":
      return "Total SR"
    case "total_stuffs":
      return "Total Stuffs"
    case "total_pass_sr":
      return "Total PSR"
    case "total_ppa":
      return "Total EPA"
    default:
      return "Unknown"
  }
}