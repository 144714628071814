
import Tooltip from 'rc-tooltip'
import { tooltipConstants } from '../lib/tooltipHelper'
import { renderInformationIcon } from '../lib/iconHelper'

const InformationTooltip = ({tooltipContent, size = `18`}) => {
  return (
    <Tooltip { ... tooltipConstants} overlay={<>{tooltipContent}</>}>
      <i className="information-icon">{ renderInformationIcon(size) }</i>
    </Tooltip>
  )
}

export default InformationTooltip
